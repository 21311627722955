import { createReducer } from "deox";

import {
  LoadedRolls,
  LoadedRollsError,
  LoadedUnavailableDates,
  LoadedUnavailableDatesError,
  LoadedUnavailableDatesReset,
  LoadingRolls,
  LoadingUnavailableDates,
  SelectStartDate,
} from "./sessionStateAction";
import { ISessionAvailability, Roll } from "../../../types/types";
import dayjs, { Dayjs } from "dayjs";

export interface SessionState {
  rolls: Roll[] | null;
  serviceId: number | null;
  isInitialised: boolean;
  isErrorState: boolean;
  isLoading: boolean;
  startDate: Dayjs | null;
}

export const initialState: SessionState = {
  rolls: null,
  serviceId: null,
  isInitialised: false,
  isErrorState: false,
  isLoading: false,
  startDate: dayjs(dayjs().format("YYYY-MM-DD"))
};

const sessionReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadedRolls, (state, { payload }) => ({
    ...state,
    rolls: payload.rolls,
    serviceId: payload.serviceId,
    isInitialised: true,
    isErrorState: false,
    isLoading: false,
  })),
  handleAction(LoadedRollsError, (state) => ({
    ...state,
    rolls: null,
    serviceId: null,
    isInitialised: false,
    isErrorState: true,
    isLoading: false,
  })),

  handleAction(LoadingRolls, (state) => ({
    ...state,
    rolls: null,
    serviceId: null,
    isInitialised: false,
    isErrorState: false,
    isLoading: true,
  })),
  handleAction(SelectStartDate, (state, { payload }) => ({
    ...state,
    payload
  }))
]);

export interface UnAvailableDatesState {
  dates: ISessionAvailability[] | null;
  isInitialised: boolean;
  isErrorState: boolean;
  isLoading: boolean;
  isReset: boolean;
}

export const UnAvailableDatesInitialState: UnAvailableDatesState = {
  dates: null,
  isInitialised: false,
  isErrorState: false,
  isLoading: false,
  isReset: false,
};

export const unAvailableDatesReducer = createReducer(UnAvailableDatesInitialState, (handleAction) => [
  handleAction(LoadedUnavailableDates, (state, { payload }) => ({
    ...state,
    dates: payload,
    isInitialised: true,
    isErrorState: false,
    isLoading: false,
    isReset: false,
  })),
  handleAction(LoadedUnavailableDatesError, (state) => ({
    ...state,
    dates: null,
    isInitialised: false,
    isErrorState: true,
    isLoading: false,
    isReset: false,
  })),
  handleAction(LoadedUnavailableDatesReset, (state) => ({
    ...state,
    dates: null,
    isInitialised: false,
    isErrorState: false,
    isLoading: false,
    isReset: true,
  })),
  handleAction(LoadingUnavailableDates, (state) => ({
    ...state,
    dates: null,
    isInitialised: false,
    isErrorState: false,
    isLoading: true,
    isReset: false,
  })),
]);


export default sessionReducer;
