import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";
import { ConditionTypes, IRegistrationMedicalPlanAnswer } from "../../../types/types";
import { OtherMedicalConditionQuestionId } from "../../../utils/medicalConditionsHelper";

interface Props {
  id: string;
  label: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onOtherTextChange: (id: number, text: string) => void;
  value?: number[];
  planAnswers?: IRegistrationMedicalPlanAnswer[];
  helperText: any;
  error: any;
}

export const MedicalConditionsSelector = (props: Props) => {
  const medicalConditions = useSelector((state: RootState) => state?.commonData?.data?.medicalPlanTemplate?.conditions);
  const otherMedicalConditionsIds =
    medicalConditions
      ?.filter((x) => x.conditionTypeId === ConditionTypes.Other)
      .sort((a, b) => a.conditionId - b.conditionId)
      .map((x) => x.conditionId) ?? [];
  const firstOtherMedicalConditionId = otherMedicalConditionsIds[0];

  const selectedMedicalConditionIds = medicalConditions
    ? medicalConditions.filter((x) => props.value?.includes(x.conditionId)).map((x) => x.conditionId)
    : [];

  return (
    <FormGroup>
      {medicalConditions
        ?.filter((x) => x.conditionTypeId === ConditionTypes.General || x.conditionId === firstOtherMedicalConditionId)
        ?.map((medicalCondition) => {
          const medicalConditionId = medicalCondition.conditionId;
          var isChecked = selectedMedicalConditionIds?.map((x) => x).includes(medicalConditionId);

          return (
            <Grid container key={"MedicalConditionsSelector_" + medicalConditionId}>
              <FormControlLabel
                key={medicalConditionId}
                control={
                  <Checkbox
                    name={medicalCondition.name}
                    key={medicalConditionId}
                    value={medicalConditionId}
                    checked={isChecked}
                    onClick={props.onChange}
                  />
                }
                label={medicalCondition.name}
              />
              {isChecked &&
                medicalConditionId === firstOtherMedicalConditionId &&
                otherMedicalConditionsIds.map((value) => {
                  var previousOtherMedicalConditionAnswer = props.planAnswers?.find(
                    (x) =>
                      x !== undefined && x.conditionId === value - 1 && x.questionId === OtherMedicalConditionQuestionId
                  );

                  if (
                    value === firstOtherMedicalConditionId ||
                    (previousOtherMedicalConditionAnswer !== undefined &&
                      previousOtherMedicalConditionAnswer.value !== undefined &&
                      previousOtherMedicalConditionAnswer.value.length > 2)
                  ) {
                    return (
                      <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                          <TextField
                            type="text"
                            placeholder="Name of Condition"
                            value={
                              props.planAnswers?.find(
                                (p) =>
                                  p.conditionId === value &&
                                  p.questionId === OtherMedicalConditionQuestionId &&
                                  p.multipleAnswerIndex === 0
                              )?.value
                            }
                            onChange={(evt) => {
                              props.onOtherTextChange(value, evt.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                  return null;
                })}
            </Grid>
          );
        })}
    </FormGroup>
  );
};
