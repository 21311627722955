import React, { useEffect, useRef } from "react";

import { userSuccess } from "../store/modules/auth/authStateActions";
import { persistor } from "../store/store";
export default function AuthProvider({ userManager: manager, store, children }) {
  let userManager = useRef();

  useEffect(() => {
    userManager.current = manager;

    const onUserLoaded = (user) => {
      store.dispatch(userSuccess(user));
    };

    const onUserUnloaded = async () => {
      await persistor.purge();
    };

    const onAccessTokenExpiring = () => {};

    const onAccessTokenExpired = async () => {
      await persistor.purge();
    };

    const onUserSignedOut = async () => {
      await persistor.purge();
    };

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded);
    userManager.current.events.addUserUnloaded(onUserUnloaded);
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.current.events.addUserSignedOut(onUserSignedOut);

    userManager.current.events.addSilentRenewError((e) => {
      console.log("[oidc] silent renew error", e.message);
    });

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager.current.events.removeUserLoaded(onUserLoaded);
      userManager.current.events.removeUserUnloaded(onUserUnloaded);
      userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring);
      userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager.current.events.removeUserSignedOut(onUserSignedOut);
    };
  });

  return React.Children.only(children);
}
