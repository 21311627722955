import { createReducer } from "deox";

import { FileResponse } from "../../parentApi";
import {
  DocumentProcessing,
  DocumentDownloaded,
  DocumentInit,
  DocumentErrorAction,
  RemoveRegistrationDocumentAction,
  SaveRegistrationDocumentAction,
  RemoveDocumentAction,
  SaveDocumentAction,
  DocumentUploading,
  DocumentResetAction,
} from "./documentStateActions";

export interface DocumentState {
  stateId: string;
  uniqueId?: string;
  fileName?: string;
  isProcessing: boolean;
  isUploading?: boolean | null;
  fileResponse?: FileResponse | null;
  isErrorState?: boolean;
  errorMessage?: string;
}

export interface DocumentStates {
  documentStates: DocumentState[];
}

export const initialState: DocumentStates = {
  documentStates: [],
};

function setNewDocumentState(state: DocumentStates, newDocumentState: DocumentState) {
  var documentState = state.documentStates.find((x) => x.stateId === newDocumentState.stateId);
  if (!documentState) {
    state.documentStates.push(newDocumentState);
  } else {
    var documentStateIndex = state.documentStates.indexOf(documentState);
    state.documentStates[documentStateIndex] = newDocumentState;
  }
}

const documentReducer = createReducer(initialState, (handleAction) => [
  handleAction(DocumentProcessing, (state, { payload }) => {
    if (state.documentStates === null) {
      state.documentStates = [];
    }

    setNewDocumentState(state, {
      stateId: payload,
      isProcessing: true,
      isErrorState: false,
      errorMessage: "",
    });

    return {
      ...state,
    };
  }),
  handleAction(DocumentDownloaded, (state, { payload }) => ({
    ...state,
    documentStates: state.documentStates.map((state) =>
      state.stateId === payload.stateId ? { ...state, ...payload } : state
    ),
  })),

  handleAction(DocumentErrorAction, (state, action) => {
    setNewDocumentState(state, {
      stateId: action.payload.stateId,
      isProcessing: false,
      isErrorState: true,
      errorMessage: action.payload.error,
    });

    return {
      ...state,
    };
  }),

  handleAction(DocumentResetAction, (state, action) => {
    setNewDocumentState(state, {
      stateId: action.payload,
      isProcessing: false,
      isErrorState: false,
      errorMessage: "",
    });

    return {
      ...state,
    };
  }),

  handleAction(DocumentUploading, (state, action) => {
    setNewDocumentState(state, {
      stateId: action.payload,
      isProcessing: true,
      isErrorState: false,
      isUploading: true,
    });

    return {
      ...state,
    };
  }),

  handleAction(SaveRegistrationDocumentAction, (state, action) => {
    var stateId = action.payload.stateId;
    return documentProcessed(stateId, state);
  }),
  handleAction(RemoveRegistrationDocumentAction, (state, action) => {
    var stateId = action.payload.stateId;
    return documentProcessed(stateId, state);
  }),
  handleAction(SaveDocumentAction, (state, action) => {
    var stateId = action.payload.stateId;
    return documentProcessed(stateId, state);
  }),
  handleAction(RemoveDocumentAction, (state, action) => {
    var stateId = action.payload.stateId;
    return documentProcessed(stateId, state);
  }),
  handleAction(DocumentInit, () => {
    return initialState;
  }),
]);

export default documentReducer;
function documentProcessed(stateId: string | undefined, state: DocumentStates) {
  if (stateId) {
    setNewDocumentState(state, {
      stateId: stateId,
      isProcessing: false,
      isErrorState: false,
      isUploading: false,
    });
  }

  return {
    ...state,
  };
}
