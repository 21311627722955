import { Contact } from "../types/types";
import { isNullOrEmpty } from "./stringUtils";

enum ContactRegistrationStep {
  contactStep1,
  contactStep2,
  contactStep3,
}

type Step = [step: ContactRegistrationStep, count: number];

const steps: Step[] = [
  [ContactRegistrationStep.contactStep1, 10], // General Info
  [ContactRegistrationStep.contactStep3, 1], // Authorization
];

export class ContactHelper {
  static percentCompleted(contact: Contact) {
    var complete = 0;

    for (var stepWithRequiredFields in steps) {
      complete += ContactHelper.getStepCompletion(contact, steps[stepWithRequiredFields]);
    }

    return (complete * 100) / 11;
  }

  static getStepCompletion(contact: Contact, step: Step) {
    var completion = 0;

    switch (step[0]) {
      case ContactRegistrationStep.contactStep1:
        completion += isNullOrEmpty(contact.firstName) ? 0 : 1;
        completion += isNullOrEmpty(contact.lastName) ? 0 : 1;
        completion += contact.contactTypeId == null ? 0 : 1;
        completion += contact.birthday == null ? 0 : 1;
        completion += contact.genderId == null ? 0 : 1;
        completion += isNullOrEmpty(contact.mobilePhone) ? 0 : 1;

        if (contact.sameAddressDetailsAsPrimary != null && contact.sameAddressDetailsAsPrimary) {
          completion += 4;
        } else {
          completion += isNullOrEmpty(contact.residentialAddress?.streetLine1) ? 0 : 1;
          completion += isNullOrEmpty(contact.residentialAddress?.postCode) ? 0 : 1;
          completion += isNullOrEmpty(contact.residentialAddress?.suburb) ? 0 : 1;
          completion += isNullOrEmpty(contact.residentialAddress?.state) ? 0 : 1;
        }
        break;
      case ContactRegistrationStep.contactStep3:
        completion += contact.authorisation == null ? 0 : 1;
        break;
    }

    return completion;
  }

  static getFirstUncompletedStep(contact: Contact, index: number) {
    for (var stepWithRequiredFields in steps) {
      var stepCompletion = ContactHelper.getStepCompletion(contact, steps[stepWithRequiredFields]);
      if (stepCompletion < steps[stepWithRequiredFields][1]) {
        return `/registration-contact-step/${steps[stepWithRequiredFields][0] + 1}/${index}`;
      }
    }
    return null;
  }
}
