import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListMUI from "@mui/material/List";
import dayjs from "dayjs";
import { List } from "linqts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import { Egg2 } from "../../components/Common/Shapes/Shapes";
import ChildSelection from "../../components/Forms/Bookings/ChildSelection";
import ServiceProgramSelection from "../../components/Forms/Bookings/ServiceProgramSelection";
import { CardLayout } from "../../layouts/Layouts";
import {
  LoadCreateCasualBookingsPreviewInitial,
  SaveBookingInitial,
} from "../../store/modules/booking/bookingStateActions";
import { getChildren } from "../../store/modules/child/childActions";
import { getServiceUpCommingPrograms } from "../../store/modules/program/programActions";
import { getUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { LoadedUnavailableDatesReset } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/reducers";
import { ChildAgeConstraint, RollDetails, SelectedChild, SelectedServiceProgram } from "../../types/models";
import { ISelectedChild, IServiceDistance } from "../../types/types";
import dateOnlyToJson from "../../utils/dateOnlyToJson";
import { getStyleColor } from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import { GetPageState } from "../../utils/pageUtil";

dayjs.extend(dateOnlyToJson);

export interface VacBookingDetails {
  serviceDistance: IServiceDistance | undefined;
  children: ISelectedChild[];
  dates: SelectedServiceProgram[];
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  selectMinHeight: {
    minHeight: 0,
    "@media (min-width: 1280px)": {
      minHeight: 180,
    },
  },
  logo: {
    height: 40,
    "@media screen and (min-width: 720px)": {
      height: 80,
    },
  },
}));

function NewVAVBookingSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = GetPageState<VacBookingDetails>()!;
  const srv = state.serviceDistance!.service;
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customerChildren = useSelector((state: RootState) => state.child.childrenByCustomer);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);
  const servicePrograms = useSelector((state: RootState) => state.servicePrograms.servicePrograms);
  const customerAccount = useSelector((state: RootState) => state.customer);
  const { classes } = useStyles();
  const { width } = useViewport();

  const [values, setValues] = useState<VacBookingDetails>({
    serviceDistance: state.serviceDistance,
    children: new Array<SelectedChild>(),
    dates: new Array<SelectedServiceProgram>(),
  });

  useEffect(() => {
    if (customer_account_id && srv) {
      dispatch(SaveBookingInitial());
      dispatch(LoadedUnavailableDatesReset());
      if (customerChildren === null) {
        getChildren(dispatch, customer_account_id);
      }
      getServiceUpCommingPrograms(dispatch, customer_account_id, srv.serviceId);
    }
  }, [customer_account_id]);

  useEffect(() => {
    if (servicePrograms) {
      if (!unAvailableDates) {
        loadUnAvailableDates(values);
      }
    }
  }, [servicePrograms]);

  useEffect(() => {
    if (servicePrograms) {
      loadUnAvailableDates(values);
    }
  }, []);

  function loadChildren(values: VacBookingDetails) {
    if (values.children.length === 0 && customerChildren && customerChildren.length) {
      customerChildren.forEach((c) => {
        var current = state?.children?.find((ch) => ch.child.childId === c.childId);
        values.children.push(
          new SelectedChild({
            child: c,
            isSelected: current !== undefined,
            reason: current?.reason,
            reasonRequired: current?.reasonRequired,
          })
        );
      });
    }
  }

  function loadPrograms(values: VacBookingDetails) {
    if (values.dates.length === 0 && servicePrograms && servicePrograms.length) {
      servicePrograms.forEach((p) => {
        values.dates.push(
          new SelectedServiceProgram({
            program: p,
            isSelected:
              state !== undefined &&
              state !== null &&
              state.dates?.findIndex((ch) => ch.program.programDateId === p.programDateId) > -1,
          })
        );
      });
    }
  }

  function loadUnAvailableDates(values: VacBookingDetails) {
    if (values.children.findIndex((x) => x.isSelected) >= 0) {
      var rollIds = new List(values.dates.map((d) => d.program.rollId)).Distinct();
      var childrenIds = values.children.filter((c) => c.isSelected).map((c) => c.child.childId as number);
      var from = dayjs();
      var to = from.add(4, "month");
      if (rollIds.Count() > 0 && childrenIds.length > 0)
        getUnAvailableSessionDays(dispatch, customer_account_id as number, from, to, rollIds.ToArray(), childrenIds);
    }
  }

  loadChildren(values);
  loadPrograms(values);
  if (servicePrograms && unAvailableDates) {
    unAvailableDates.forEach((ud) => {
      var dt = values.dates.find(
        (d) =>
          ud.date.isSame(d.program.date, "date") && ud.rollId === d.program.rollId && !ud.isAvailableForCasualBooking
      );
      if (dt) {
        dt.isSelected = false;
      }
    });
  }
  var programs = new List(values.dates);
  var selectedPrograms = programs.Where((p) => p !== undefined && p.isSelected);
  var selectedChildren = new List(values.children).Where((c) => c?.isSelected === true);
  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--rocketeers-color-accent-2")}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h1 className="h3">New Booking</h1>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end">
              <img src={RocketeersLogo} alt="Rocketeers logo" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <Egg2
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            display: width <= 1280 ? "none" : "block",
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <div className={classes.selectMinHeight}>
              <legend className={classes.formLegend}>Select Children</legend>
              {values?.children?.map((child, index) => {
                return (
                  <ChildSelection
                    key={"childSelection_" + child.child.childId}
                    constraints={selectedPrograms.Select(
                      (d) =>
                        new ChildAgeConstraint({
                          rollDate: d.program.date,
                          session: new RollDetails(d.program.session),
                          activityCode: d.program.activityCode,
                        })
                    )}
                    child={new SelectedChild(child)}
                    serviceId={srv.serviceId}
                    onSelectionChanged={(isAgeLess, isAgeGreater, checked) => {
                      values.children[index].isSelected = checked;
                      if (isAgeGreater) {
                        values.children[index].reasonRequired =
                          values.children[index].reason === null ||
                          values.children[index].reason === undefined ||
                          (values.children[index].reason?.trim()?.length as number) < 4;
                      }
                      loadUnAvailableDates(values);
                      setValues({ ...values, children: values.children.splice(0) });
                    }}
                    onReasonChanged={(reason) => {
                      values.children[index].reason = reason;
                      values.children[index].reasonRequired = reason === null || reason.trim().length < 4;
                      setValues({ ...values, children: values.children.splice(0) });
                    }}
                  ></ChildSelection>
                );
              })}
            </div>
          </Grid>
          {selectedChildren.Count() > 0 && (
            <>
              <Grid item xs={12}>
                <Box paddingTop={4}>
                  <legend className={classes.formLegend}>Select Days</legend>
                  <ListMUI className={classes.root}>
                    {programs
                      .Select((p, index) => {
                        return (
                          <React.Fragment key={index}>
                            <ServiceProgramSelection
                              program={p}
                              isFriendlyFee={customerAccount.customer?.isFriendlyFeeEligible === true}
                              unAvailableDates={unAvailableDates ?? []}
                              onSelectedDateChanged={(prg, checked) => {
                                values.dates[index].isSelected = checked;
                                var constraints = values.dates
                                  .filter((p) => p !== undefined && p.isSelected)
                                  .map(
                                    (d) =>
                                      new ChildAgeConstraint({
                                        rollDate: d.program.date,
                                        session: new RollDetails(d.program.session),
                                        activityCode: d.program.activityCode,
                                      })
                                  );
                                for (var ch of values.children) {
                                  const selectedChild = new SelectedChild(ch);
                                  // eslint-disable-next-line no-loop-func
                                  ch.reasonRequired =
                                    constraints.find((c) => c.isAgeGreaterThanMax(selectedChild)) !== undefined;
                                  // eslint-disable-next-line no-loop-func
                                  if (constraints.findIndex((c) => c.isAgeLessThanMin(selectedChild)) >= 0) {
                                    ch.isSelected = false;
                                  }
                                }
                                var current = values.dates[index].program;
                                for (var d of values.dates) {
                                  if (
                                    d.isSelected &&
                                    d.program.rollId !== current.rollId &&
                                    d.program.session.sessionType === current.session.sessionType
                                  ) {
                                    d.isSelected = false;
                                  }
                                }
                                setValues({
                                  ...values,
                                  children: values.children.splice(0),
                                  dates: values.dates.splice(0),
                                });
                                loadUnAvailableDates(values);
                              }}
                            />
                          </React.Fragment>
                        );
                      })
                      .ToArray()}
                  </ListMUI>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <span className="small">* Prices exclude available Child Care Subsidy (CCS)</span>
              </Grid>
            </>
          )}
        </Grid>
      </CardLayout.Body>
      <CardLayout.Footer>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <ButtonSecondary
                onClick={() => {
                  navigate("/new-vac");
                }}
              >
                <strong>Back</strong>
              </ButtonSecondary>
            </Grid>
            <Grid item>
              <ButtonPrimary
                style={{ backgroundColor: getStyleColor("--rocketeers-color-accent-2") }}
                disabled={CannotGoNext(values)}
                onClick={() => {
                  dispatch(LoadedUnavailableDatesReset());
                  dispatch(LoadCreateCasualBookingsPreviewInitial());
                  navigate("/vac-confirm", {
                    state: JSON.stringify({
                      children: new List(values.children).Where((c) => c?.isSelected === true).ToArray(),
                      dates: new List(values.dates)
                        .Where((p) => p?.isSelected === true)
                        .Select((p) => p.program)
                        .ToArray(),
                      serviceDistance: state.serviceDistance,
                    }),
                  });
                }}
              >
                <strong>Next</strong>
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Footer>
    </CardLayout>
  );
}

function CannotGoNext(values: VacBookingDetails) {
  return (
    values.children.findIndex((c) => c.isSelected) < 0 ||
    values.dates.findIndex((d) => d.isSelected) < 0 ||
    values.children.findIndex(
      (c) => c.isSelected && c.reasonRequired && (c.reason === null || (c.reason?.trim().length ?? 0) < 3)
    ) >= 0
  );
}
export default NewVAVBookingSelection;
