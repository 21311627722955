import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { DocumentTypes } from "../../types/types";
import { DocumentManager } from "../Common/Controls/DocumentManager";

interface Props {
  classes: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  immunisationDocuments: any;
  customerAccountId?: any;
}

function Immunisation({
  classes,
  setFieldValue,
  touched,
  values,
  errors,
  immunisationDocuments,
  customerAccountId,
}: Props) {
  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={12}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Immunisation</legend>
          <FormControl fullWidth>
            <FormLabel component="legend" className={classes.formLabel}>
              Has the child been immunised?
            </FormLabel>
          </FormControl>
          <FormControl error={Boolean(touched.hasBeenImmunised && errors.hasBeenImmunised)} fullWidth>
            <RadioGroup row={true}>
              <FormControlLabel
                value="true"
                label="Yes"
                control={<Radio />}
                checked={values.hasBeenImmunised === true}
                onChange={() => setFieldValue("hasBeenImmunised", true)}
              />
              <FormControlLabel
                value="false"
                label="No"
                control={<Radio />}
                checked={values.hasBeenImmunised === false}
                onChange={() => setFieldValue("hasBeenImmunised", false)}
              />
            </RadioGroup>
            {touched.hasBeenImmunised && errors.hasBeenImmunised && (
              <FormHelperText error required>
                {errors.hasBeenImmunised}
              </FormHelperText>
            )}
          </FormControl>

          {values.hasBeenImmunised === true ? (
            <Container className={classes.container}>
              <Box pt={3} />
              <em>Please upload a copy of the child's immunisation certificate.</em>
              <DocumentManager
                controlId={"immunizationDocument"}
                documentTypeId={DocumentTypes.ImmunizationRecords}
                customerAccountId={customerAccountId}
                childId={values.childId}
                registrationChildUniqueId={values.uniqueId}
                isRegistration={values.uniqueId ? true : false}
                isTemp={true}
                documents={immunisationDocuments}
                maxFiles={5}
              />
            </Container>
          ) : null}
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default Immunisation;
