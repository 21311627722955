import { AvatarDocument } from "../../../types/models";
import { Document } from "../../../types/types";
import { FileResponse } from "../../parentApi";
import documentApi from "./documentApi";
import {
  DocumentDownloaded,
  DocumentErrorAction,
  DocumentInit,
  DocumentProcessing,
  DocumentUploading,
  RemoveRegistrationDocumentAction,
  SaveDocumentAction,
  SaveRegistrationDocumentAction,
} from "./documentStateActions";
import registrationApi from "./registrationDocumentApi";

export const createRegistrationDocument = (controlStateId: string, document: Document) => (dispatch: any) => {
  dispatch(DocumentUploading(controlStateId));

  registrationApi
    .createDocument(
      document.registrationChildUniqueId,
      document.registrationContactUniqueId,
      document.documentTypeId,
      document.file,
      document.medicalPlanConditionId,
      document.medicalPlanQuestionId,
      document.medicalPlanAnswerIndex,
      document.expiresOn
    )
    .then(
      (uniqueId) => {
        document.uniqueId = uniqueId;
        dispatch(SaveRegistrationDocumentAction(controlStateId, document));
      },
      (error: any) => {
        dispatch(
          DocumentErrorAction(
            controlStateId,
            "An error ocurred while trying to upload document. Please try again later"
          )
        );
      }
    );
};

export const deleteRegistrationDocument =
  (dispatch: any, controlStateId: string, uniqueId: string) => (dispatch: any) => {
    dispatch(DocumentProcessing(controlStateId));
    registrationApi.deleteDocument(uniqueId).then(
      () => {
        dispatch(RemoveRegistrationDocumentAction(controlStateId, uniqueId));
      },
      (error: any) => {
        dispatch(
          DocumentErrorAction(controlStateId, "An error ocurred while trying delete document. Please try again later")
        );
      }
    );
  };

export const downloadRegistrationDocument = (
  dispatch: any,
  controlStateId: string,
  fileName: string,
  uniqueId: string
) => {
  dispatch(DocumentProcessing(controlStateId));
  const errorMessage = "An error ocurred while trying download document. Please try again later";
  registrationApi
    .downloadDocument(uniqueId)
    .then(
      function (value: FileResponse | null) {
        if (value && value.status === 200) {
          dispatch(DocumentDownloaded(controlStateId, value, fileName, false));
        } else {
          dispatch(DocumentErrorAction(controlStateId, errorMessage));
        }
      },
      function (error: any) {
        DocumentErrorAction(controlStateId, errorMessage);
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(DocumentInit());
      }, 500);
    });
};

export const createDocument =
  (controlStateId: string, customerAccountId: number, document: Document) => (dispatch: any) => {
    dispatch(DocumentUploading(controlStateId));
    documentApi
      .createDocument(
        customerAccountId,
        document.childId,
        document.customerContactId,
        document.documentTypeId,
        document.isTemp,
        document.file,
        document.medicalPlanConditionId,
        document.medicalPlanQuestionId,
        document.medicalPlanAnswerIndex,
        document.expiresOn
      )
      .then(
        (uniqueId) => {
          document.uniqueId = uniqueId;
          dispatch(SaveDocumentAction(controlStateId, document));
        },
        (error: any) => {
          dispatch(
            DocumentErrorAction(
              controlStateId,
              "An error ocurred while trying to upload document. Please try again later"
            )
          );
        }
      );
  };

export const download = (
  dispatch: any,
  controlStateId: string,
  customerAccountId: number,
  fileName: string,
  uniqueId: string
) => {
  dispatch(DocumentProcessing(controlStateId));
  const errorMessage = "An error ocurred while trying download document. Please try again later";
  documentApi
    .downloadDocument(customerAccountId, uniqueId)
    .then(
      function (value: FileResponse | null) {
        if (value && value.status === 200) {
          dispatch(DocumentDownloaded(controlStateId, value, fileName, false));
        } else {
          dispatch(DocumentErrorAction(controlStateId, errorMessage));
        }
      },
      function (error: any) {
        DocumentErrorAction(controlStateId, errorMessage);
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(DocumentInit());
      }, 500);
    });
};

export const uploadAvatarDocument = (dispatch: any, document: AvatarDocument) => {
  if (document.isRegistration) {
    registrationApi
      .createDocument(
        document.registrationChildUniqueId,
        document.registrationContactUniqueId,
        document.documentTypeId,
        document.file,
        null,
        null,
        null,
        null
      )
      .then(
        (uniqueId) => {
          document.uniqueId = uniqueId;
          dispatch(SaveRegistrationDocumentAction(undefined, document));
        },
        (error: any) => {}
      );
  } else {
    if (document.customerAccountId) {
      documentApi
        .createDocument(
          document.customerAccountId,
          document.childId,
          document.customerContactId,
          document.documentTypeId,
          true,
          document.file,
          null,
          null,
          null,
          null
        )
        .then(
          (uniqueId) => {
            document.uniqueId = uniqueId;
            dispatch(SaveDocumentAction(undefined, document));
          },
          (error: any) => {}
        );
    }
  }
};
