import Yup from "../../utils/yupExtensions";

const postCodeRe = /^[0-9]{4}$/;

const requiredIfNotSameCultural = Yup.string().when("sameCulturalDetailsAsPrimary", {
  is: (val: Boolean) => val === false,
  then: Yup.string().required("This field is required").lengthCheck(3, 255),
  otherwise: Yup.string().notRequired(),
});

const EditChildSchema = Yup.object().shape({
  firstName: Yup.string().required("Required").lengthCheck(2, 100),
  lastName: Yup.string().required("Required").lengthCheck(2, 100),
  birthday: Yup.date()
    .min(new Date(new Date().getFullYear() - 20, 1, 1), "Invalid date")
    .required("Required")
    .typeError("Invalid date"),
  sameAddressDetailsAsPrimary: Yup.boolean(),
  residentialAddress: Yup.mixed().when("sameAddressDetailsAsPrimary", (val: Boolean) => {
    if (val === false) {
      return Yup.object().shape({
        streetLine1: Yup.string().required("Required").lengthCheck(3, 200),
        suburb: Yup.string().required("Required").lengthCheck(3, 200),
        postCode: Yup.string()
          .required("Required")
          .matches(postCodeRe, "Please enter a valid postcode")
          .lengthCheck(4, 50),
        state: Yup.string().required("Required"),
      });
    }

    return Yup.object().shape({
      streetLine1: Yup.string().notRequired(),
      suburb: Yup.string().notRequired(),
      postCode: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
    });
  }),
  genderId: Yup.number().positive("Please select").required("Required"),
  sameCulturalDetailsAsPrimary: Yup.boolean(),
  mainLanguage: requiredIfNotSameCultural,
  culturalBackground: requiredIfNotSameCultural,
  culturalNeeds: Yup.string().lengthCheck(3, 500),
  schoolId: Yup.number().when("sameSchoolDetailsAsFirstChild", {
    is: false,
    then: Yup.number().test("required", "Required", (value) => {
      return value !== undefined && value !== null && value > 0;
    }),
  }),
  medicalAnswersValid: Yup.boolean().isTrue("Required"),
  hasMedicalConditions: Yup.bool().required("Required"),
  medicalConditions: Yup.array().when("hasMedicalConditions", {
    is: (val: Boolean) => val === true,
    then: Yup.array().min(1, "You must select at least one medical condition"),
    otherwise: Yup.array().notRequired(),
  }),
  hasPhysicalNeeds: Yup.bool().required("Required"),
  physicalNeeds: Yup.string().when("hasPhysicalNeeds", {
    is: (val: Boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasBehaviouralNeeds: Yup.bool().required("Required"),
  hasBeenImmunised: Yup.bool().required("Required"),
  behaviouralNeeds: Yup.string().when("hasBehaviouralNeeds", {
    is: (val: Boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasSpecialNeeds: Yup.bool().required("Required"),
  specialNeeds: Yup.string().when("hasSpecialNeeds", {
    is: (val: Boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasDietaryRequirements: Yup.bool().required("Required"),
  dietaryRequirements: Yup.string().when("hasDietaryRequirements", {
    is: (val: Boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  medicalServiceName: Yup.string().when("sameMedicalContactAsFirstChild", {
    is: (val: Boolean) => val === false,
    then: Yup.string().required("This field is required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  doctorName: Yup.string().when("sameMedicalContactAsFirstChild", {
    is: (val: Boolean) => val === false,
    then: Yup.string().required("This field is required").lengthCheck(2, 250),
    otherwise: Yup.string().notRequired(),
  }),
  doctorPhone: Yup.string().when("sameMedicalContactAsFirstChild", {
    is: (val: Boolean) => val === false,
    then: Yup.string().required("This field is required").lengthCheck(10, 10),
    otherwise: Yup.string().notRequired(),
  }),
  doctorAddress: Yup.string().when("sameMedicalContactAsFirstChild", {
    is: (val: Boolean) => val === false,
    then: Yup.string().required("This field is required").lengthCheck(10, 250),
    otherwise: Yup.string().notRequired(),
  }),
  priorityAccessId: Yup.number().required("Required"),
  medicareNumber: Yup.string()
    .test("blank", "Required", function (value) {
      return value !== null && value?.trim().length! > 0;
    })
    .test("not N/A", "Minimum of 10 Characters and Maximum of 255 Characters", function (value) {
      return (value?.length! >= 10 && value?.length! <= 255) || value === "N/A";
    }),
});

export default EditChildSchema;
