import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { FeeType, IBookingInfo3 } from "../../../types/types";

interface IProps {
  cancelledInTimeByFee: {
    [key: string]: IBookingInfo3[];
  };
}
export default function CancellableInTimeBookings(props: IProps) {
  const serviceRolls = useSelector((state: RootState) => state.session.rolls);
  const { cancelledInTimeByFee } = props;
  return (
    <List disablePadding={true} sx={{ p: 1, pt: 0, backgroundColor: "#5DE5BB4A", width: "100%" }}>
      {Object.keys(cancelledInTimeByFee).map((feeType, index) => {
        const allAvBookings = cancelledInTimeByFee[feeType];
        return (
          <ListItem
            disableGutters
            alignItems="center"
            key={index}
            secondaryAction={
              <span className="small" style={{ textDecoration: "line-through" }}>
                <NumericFormat
                  displayType="text"
                  prefix="$"
                  value={allAvBookings.reduce((acc, curr) => acc + curr.fee, 0)}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix="*"
                />
              </span>
            }
          >
            <ListItemAvatar sx={{ minWidth: "35px" }}>
              <CheckCircleIcon fontSize="small" htmlColor="var(--custom-color-green)" />
            </ListItemAvatar>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography variant="subtitle2" fontWeight="bold">
                  {`${serviceRolls?.find((r) => r.rollId === allAvBookings[0].rollId)?.rollName} - Cancelled Sessions`}
                  {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                </Typography>
              }
              secondary={
                <Stack direction="column">
                  {feeType !== FeeType.Casual && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: {
                          xl: "1rem",
                          lg: "0.9rem",
                          md: "0.9rem",
                          sm: "0.7rem",
                          xs: "0.7rem",
                        },
                      }}
                    >
                      {allAvBookings.length > 1 &&
                        `${allAvBookings[0].date.format("ddd DD MMM, YYYY")} to ${allAvBookings[
                          allAvBookings.length - 1
                        ].date.format("ddd DD MMM, YYYY")}`}
                      {allAvBookings.length === 1 && allAvBookings[0].date.format("ddd DD MMM")}
                    </Typography>
                  )}

                  <Typography variant="subtitle2">
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        prefix={`${allAvBookings.length} x $`}
                        value={allAvBookings[0].fee}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="*"
                      />
                    </span>
                  </Typography>
                </Stack>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
