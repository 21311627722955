import { forwardRef, useImperativeHandle } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { ErrorMessage, useFormikContext } from "formik";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";

import BankAccountSchema from "../../components/Payment/BankAccountSchema";

export interface IBankAccountProps {
  accountName: string;
  bsb?: string;
  accountNumber?: string;
  path: string;
  isOneOffPayment: boolean;
}

export interface IBankAccoutRef {
  validate(): boolean;
}

const useStyles = makeStyles()((theme) => ({
  textField: { marginBottom: theme.spacing(2) },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
}));

const BankAccount = forwardRef<IBankAccoutRef, IBankAccountProps>((account: IBankAccountProps, ref) => {
  const { handleChange } = useFormikContext<any>();
  useImperativeHandle(ref, () => ({
    validate() {
      return BankAccountSchema.isValidSync(account);
    },
  }));
  const { classes } = useStyles();
  return (
    <FormGroup className={classes.formGroup}>
      <TextField
        id={`${account.path}.accountName`}
        name={"account.accountName"}
        value={account.accountName}
        fullWidth
        required
        type="text"
        onChange={handleChange}
        label="Account Name"
        className={classes.textField}
        inputProps={{
          maxLength: 60,
        }}
      ></TextField>
      <ErrorMessage name={`${account.path}accountName`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
      <NumericFormat
        id={`${account.path}bsb`}
        fullWidth
        required
        name={`${account.path}bsb`}
        onChange={handleChange}
        value={account.bsb}
        customInput={TextField}
        label="BSB"
        className={classes.textField}
        allowLeadingZeros={true}
        allowNegative={false}
        inputProps={{
          maxLength: 6,
        }}
      />
      <ErrorMessage name={`${account.path}bsb`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
      <NumericFormat
        id={`${account.path}accountNumber`}
        name={`${account.path}accountNumber`}
        onChange={handleChange}
        fullWidth
        required
        value={account.accountNumber}
        customInput={TextField}
        allowLeadingZeros={true}
        minLength={5}
        maxLength={50}
        label="Account Number"
        allowNegative={false}
        className={classes.textField}
        inputProps={{
          maxLength: 50,
        }}
      />
      <ErrorMessage name={`${account.path}accountNumber`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
    </FormGroup>
  );
});

export default BankAccount;
