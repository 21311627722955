import { createReducer } from "deox";

import { BillingCycleSummary } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import {
  failed,
  morePastChargesLoading,
  pastChargesLoaded,
  pastChargesLoading,
  pastChargesMoreLoaded,
} from "./pastChargesStateActions";

export interface PastChargesState {
  payload: BillingCycleSummary[];
  total: number;
  isError: boolean;
  isLoading: boolean;
  isMoreLoading: boolean;
  page: number;
  isLastPage: boolean;
  initLoaded: boolean;
}

export const initialState: PastChargesState = {
  isError: false,
  isLoading: false,
  payload: [],
  total: 0,
  isMoreLoading: false,
  initLoaded: false,
  page: 1,
  isLastPage: false,
};

const pastChargesReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    pastChargesLoaded,
    (state, action): PastChargesState => ({
      ...state,
      isLoading: false,
      isError: false,
      isMoreLoading: false,
      payload: [...state.payload, ...action.payload.records],
      total: action.payload.total,
      page: action.meta,
      initLoaded: true,
      isLastPage: action?.payload?.records.length === 0 || action?.payload?.records.length >= action.payload.total,
    })
  ),
  handleAction(
    pastChargesMoreLoaded,
    (state, action): PastChargesState => ({
      ...state,
      isLoading: false,
      isError: false,
      isMoreLoading: false,
      payload: [...state.payload, ...action.payload.records],
      total: action.payload.total,
      page: action.meta,
      isLastPage: action?.payload?.records.length === 0 || action?.payload?.records.length >= action.payload.total,
    })
  ),
  /*handleAction(
    moreBillingCyclesLoaded,
    (state, action): PastChargesState => ({
      ...state,
      isLoading: false,
      isError: false,
      total: action.payload.total,
      payload: [...state.payload, action.payload.records],
    })
  ),*/
  handleAction(
    pastChargesLoading,
    (state): PastChargesState => ({
      ...state,
      isLoading: true,
      isError: false,
      isMoreLoading: false,
    })
  ),
  handleAction(
    morePastChargesLoading,
    (state): PastChargesState => ({
      ...state,
      isLoading: false,
      isMoreLoading: true,
      isError: false,
    })
  ),
  handleAction(
    failed,
    (state): PastChargesState => ({
      ...state,
      isError: true,
      isMoreLoading: false,
      isLoading: false,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default pastChargesReducer;
