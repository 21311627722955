import dayjs from "dayjs";

import { isNullOrEmpty } from "../../utils/stringUtils";
import Yup from "../../utils/yupExtensions";

const mobileRe = /^(0?[4-5])[0-9]{8}$/;
const homeRe = /^(0?[2-57-8])[0-9]{8}$/;
const workRe = /^[0-9]{10}$/;
const postCodeRe = /^[0-9]{4}$/;
var crnRe = /^(?=(?:.*?[0-9]){9})(?=(?:.*?[A-Za-z]){1})[A-Za-z0-9#,.\-_]{10,}$/;

const EditContactSchema = Yup.object().shape({
  firstName: Yup.string().required("Required").lengthCheck(2, 100),
  lastName: Yup.string().required("Required").lengthCheck(2, 100),
  email: Yup.string()
    .validEmail()
    .required("Required")
    .lengthCheck(3, 400)
    .test("sameEmail", "Required", function (value) {
      if (!this.options?.context?.isPrimary) {
        var message = "";

        if (value === this.options?.context?.primaryContactEmail) {
          message = "Contact email cannot be the same as the primary email";
        }

        if (message !== "") {
          return this.createError({
            path: this.path,
            message: message,
          });
        }
      }

      return true;
    }),
  birthday: Yup.date()
    .min(new Date(new Date().getFullYear() - 120, 1, 1), "Invalid date")
    .required("Required")
    .typeError("Invalid date"),
  genderId: Yup.number().positive("Please select").required("Required"),
  mobilePhone: Yup.string()
    .required("Required")
    .matches(mobileRe, "Please enter a valid mobile number")
    .lengthCheck(10, 10),
  homePhone: Yup.string().matches(homeRe, "Please enter a valid home number").lengthCheck(10, 10),
  workPhone: Yup.string().matches(workRe, "Please enter a valid work number").lengthCheck(10, 10),
  contactTypeId: Yup.number().positive("Please select").required("Required"),
  culturalBackground: Yup.string().when("sameCulturalDetailsAsPrimary", {
    is: false,
    then: Yup.string().required("Required").lengthCheck(3, 255),
  }),
  culturalNeeds: Yup.string().lengthCheck(3, 255),
  mainLanguage: Yup.string().when("sameCulturalDetailsAsPrimary", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  sameAddressDetailsAsPrimary: Yup.boolean(),
  sameCulturalDetailsAsPrimary: Yup.boolean(),
  residentialAddress: Yup.object().when("sameAddressDetailsAsPrimary", {
    is: false,
    then: Yup.object({
      streetLine1: Yup.string().required("Required").lengthCheck(3, 200),
      suburb: Yup.string().required("Required").lengthCheck(3, 200),
      postCode: Yup.string()
        .required("Required")
        .matches(postCodeRe, "Please enter a valid postcode")
        .lengthCheck(4, 50),
      state: Yup.string().required("Required"),
    }),
  }),
  crn: Yup.string().test("required", "Required", function (value) {
    if (this.options?.context?.isPrimary && this.options?.context?.planToClaimChildCareSubsidy) {
      var message = "";

      if (value === undefined || isNullOrEmpty(value)) {
        message = "Required";
      } else {
        if (!crnRe.test(value)) {
          message = "You must enter a valid Customer Reference Number";
        }
      }

      if (message !== "") {
        return this.createError({
          path: this.path,
          message: message,
        });
      }
    }

    return true;
  }),
  authorisation: Yup.object().shape({
    consentForTransportation: Yup.boolean().nullable().required("Required"),
    canManageAccount: Yup.boolean().nullable().required("Required"),
    canMakeAndChangeBookings: Yup.boolean().nullable().required("Required"),
    consentToAdministerMedicine: Yup.boolean().nullable().required("Required"),
    consentToMedicalTreatment: Yup.boolean().nullable().required("Required"),
    canApproveAmbulance: Yup.boolean().nullable().required("Required"),
    canPickUpChild: Yup.boolean().nullable().required("Required"),
    consentToTakeOutsidePremises: Yup.boolean().nullable().required("Required"),
  }),
  children: Yup.array().of(
    Yup.object().shape({
      crn: Yup.string().test("required", "Required", function (value) {
        if (this.options?.context?.isPrimary && this.options?.context?.planToClaimChildCareSubsidy) {
          var message = "";

          if (value === undefined || isNullOrEmpty(value)) {
            message = "Required";
          } else {
            if (!crnRe.test(value)) {
              message = "You must enter a valid Customer Reference Number";
            }
          }

          if (message !== "") {
            return this.createError({
              path: this.path,
              message: message,
            });
          }
        }

        return true;
      }),
      birthday: Yup.date()
        .test("required", "Required", function (value) {
          if (this.options?.context?.isPrimary && this.options?.context?.planToClaimChildCareSubsidy) {
            var message = "";

            if (value === undefined) {
              message = "Required";
            } else {
              if (
                !(value instanceof Date) ||
                value < dayjs().subtract(20, "year").toDate() ||
                value > dayjs().subtract(3, "year").toDate()
              ) {
                message = "Invalid date";
              }
            }

            if (message !== "") {
              return this.createError({
                path: this.path,
                message: message,
              });
            }
          }

          return true;
        })
        .typeError("Invalid date"),
    })
  ),
});

export default EditContactSchema;
