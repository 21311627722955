import React from "react";

import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack/Stack";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { makeStyles } from "tss-react/mui";

import CAToggleButton from "../Buttons/CAToggleButton";
interface ICalendarNvaigationProps {
  date: Dayjs;
  onBackClick(): any;
  onForwardClick(): any;
  onViewChange?(view: ToggleButtonGroupEnum): void;
  onDateChange?(date: Dayjs): void;
  isWeekView?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  calPicker: {
    "& .MuiOutlinedInput-notchedOutline": { borderWidth: 0 },
    "& .Mui-focused": { borderWidth: 0 },
    "& .MuiOutlinedInput-input": { fontWeight: "bold" },
    width: "50%",
  },
}));
const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export default function CalendarNavigation(props: ICalendarNvaigationProps) {
  const { classes } = useStyles();
  const { isWeekView = false } = props;
  const startOfWeek = props.date.startOf("isoWeek");
  const endOfWeek = startOfWeek.add(4, "day");
  const verySmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.grid}>
      <IconButton onClick={props.onBackClick}>
        <ArrowBackIosOutlinedIcon />
      </IconButton>
      <span style={{ fontSize: verySmallScreen ? "0.8rem" : "1rem", fontWeight: "bold" }}>
        {!isWeekView && `${MONTHS[props.date.month()]} ${props.date.year()}`}
        {isWeekView && `${startOfWeek.format("MMM DD, YYYY")} to ${endOfWeek.format("MMM DD, YYYY")}`}
      </span>
      <IconButton onClick={props.onForwardClick}>
        <ArrowForwardIosOutlinedIcon />
      </IconButton>
    </Grid>
  );
}

export enum ToggleButtonGroupEnum {
  Week = "week",
  Month = "month",
}

export function CalendarNavigationPicker(props: ICalendarNvaigationProps) {
  const { onDateChange, date } = props;
  const { classes } = useStyles();
  const [view, setView] = React.useState<ToggleButtonGroupEnum>(ToggleButtonGroupEnum.Month);
  const { onViewChange } = props;
  const [openPicker, setOpenPicker] = React.useState(false);

  const startOfWeek = date.startOf("isoWeek");
  const endOfWeek = date.endOf("isoWeek");

  const handleClickPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = (value: Dayjs | undefined) => {
    setOpenPicker(false);
    onDateChange && onDateChange(value ?? date);
  };
  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    const newView = nextView === ToggleButtonGroupEnum.Month ? ToggleButtonGroupEnum.Month : ToggleButtonGroupEnum.Week;
    setView(newView);

    if (onViewChange) {
      onViewChange(newView);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.grid}>
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Button
          variant="outlined"
          sx={{ borderColor: "black", color: "black", fontWeight: "bold" }}
          onClick={() => {
            onDateChange && onDateChange(dayjs());
          }}
        >
          Today
        </Button>
        <Button onClick={props.onBackClick}>
          <ArrowBackIosOutlinedIcon fontSize="small" sx={{ width: "1.3rem", height: "1.3rem" }} />
        </Button>

        <Button onClick={props.onForwardClick}>
          <ArrowForwardIosOutlinedIcon fontSize="small" sx={{ width: "1.3rem", height: "1.3rem" }} />
        </Button>

        {view === ToggleButtonGroupEnum.Month && (
          <DatePicker
            label=""
            views={["month", "year"]}
            value={date}
            onClose={() => setOpenPicker(false)}
            open={openPicker}
            onAccept={(newValue) => handleClosePicker(newValue!)}
            className={classes.calPicker}
            slots={{
              openPickerIcon: ExpandMoreIcon,
            }}
            slotProps={{
              textField: { onClick: handleClickPicker, inputProps: { readOnly: true } },
            }}
          />
        )}

        {view === ToggleButtonGroupEnum.Week && (
          <Typography sx={{ color: "black", fontWeight: "bold" }}>
            {MONTHS[startOfWeek.month()]} {startOfWeek.date()} - {MONTHS[endOfWeek.month()]} {endOfWeek.date()},{" "}
            {endOfWeek.year()}
          </Typography>
        )}
      </Stack>
      <ToggleButtonGroup orientation="horizontal" value={view} color="primary" exclusive onChange={handleChange}>
        <CAToggleButton value={ToggleButtonGroupEnum.Week} aria-label="list">
          Week
        </CAToggleButton>
        <CAToggleButton value={ToggleButtonGroupEnum.Month} aria-label="module">
          Month
        </CAToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}

export interface ConfirmationDialogRawProps {
  value: Dayjs;
  open: boolean;
  onClose: (value?: Dayjs) => void;
}
