import { useRef, useState } from "react";

import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import AvatarEditor from "react-avatar-editor";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AvatarDisplay } from "./AvatarDisplay";
import { setAvatar } from "../../../store/modules/avatar/avatarStateActions";
import { uploadAvatarDocument } from "../../../store/modules/document/documentActions";
import { AvatarDocument, AvatarInfo, EntityType } from "../../../types/models";
import { DocumentTypes } from "../../../types/types";
import { Avatars } from "../../../utils/helpers";
import { ButtonPrimary } from "../Buttons/Buttons";
import { AnimatedDialog } from "../Dialogs/AnimatedDialog";
import Emoji from "../Emoji/Emoji";

interface Props {
  avatarInfo: AvatarInfo;
  avatarsInUse?: number[];
  onAvatarChange: (avatarInfo: AvatarInfo) => void;
}

const useStyles = makeStyles()((theme) => ({
  avatarEditor: {
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
  },
  editAvatarLink: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "5px",
      paddingRight: "10px",
    },
  },
  linkButtonIcon: {
    verticalAlign: "middle",
  },
  linkButtonText: {
    paddingLeft: "4px",
    verticalAlign: "middle",
  },
  fab: {
    backgroundColor: "white",
  },
  avatarList: {
    display: "flex",
    flexWrap: "wrap",
    listStyleType: "none",
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  avatarListTile: {
    width: "44px",
    margin: 0,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const Avatar = (props: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const fileInput = useRef<HTMLInputElement>(null);
  const imageCropper = useRef<AvatarEditor>(null);

  const [controlState, setControlState] = useState({
    showAvatarDialog: false,
    showImageCropper: false,
    showUpload: false,
    selectedAvatarIndex: props.avatarInfo.index,
    selectedImageDataUrl: "",
  });

  const [cropperState, setCropperState] = useState({
    image: "",
    scale: 1.2,
    allowZoomOut: false,
    imageSelected: false,
    rotate: 0,
  });

  const showUpload = (showUpload: boolean) => {
    setControlState({
      ...controlState,
      showUpload: showUpload,
    });
  };

  const showAvatarDialog = (index: number | undefined) => {
    setControlState({
      ...controlState,
      showAvatarDialog: true,
    });
  };

  const hideAvatarDialog = () => {
    setControlState({
      ...controlState,
      showAvatarDialog: false,
    });
  };

  const setAvatarIndex = (avatar: string) => {
    let selectedAvatarIndex = Avatars.list.indexOf(avatar);
    setControlState({
      ...controlState,
      selectedAvatarIndex: selectedAvatarIndex,
    });
  };

  const saveAvatarIndex = () => {
    var avatarInfo = {
      index: controlState.selectedAvatarIndex,
      entityType: props.avatarInfo.entityType,
      entityId: props.avatarInfo.entityId,
    };
    props.onAvatarChange(avatarInfo);
    dispatch(setAvatar(avatarInfo));
    hideAvatarDialog();
  };

  const handleScale = (event: any, value: any) => {
    setCropperState({
      ...cropperState,
      scale: value,
    });
  };

  const handleNewImage = (event: any) => {
    showUpload(true);
    setCropperState({
      ...cropperState,
      image: event.target.files[0],
      imageSelected: true,
    });
  };

  const onImageSave = () => {
    if (imageCropper) {
      const canvas = imageCropper.current?.getImage();

      if (canvas) {
        var smallCanvasDataUrl = resizeCanvas(canvas, 150);
        if (smallCanvasDataUrl !== null) {
          var avatarInfo = {
            imageDataUrl: smallCanvasDataUrl,
            entityType: props.avatarInfo.entityType,
            entityId: props.avatarInfo.entityId,
          };
          props.onAvatarChange(avatarInfo);
          dispatch(setAvatar(avatarInfo));

          hideAvatarDialog();
          var smallAvatarDocumentType =
            props.avatarInfo.entityType === EntityType.RegistrationChild ||
            props.avatarInfo.entityType === EntityType.Child
              ? DocumentTypes.ChildAvatarSmallSize
              : DocumentTypes.ContactAvatarSmallSize;
          saveAvatarDocument(smallCanvasDataUrl, smallAvatarDocumentType, "Small avatar.jpg");
        }

        var bigCanvasDataUrl = resizeCanvas(canvas, 250);
        if (bigCanvasDataUrl !== null) {
          var bigAvatarDocumentType =
            props.avatarInfo.entityType === EntityType.RegistrationChild ||
            props.avatarInfo.entityType === EntityType.Child
              ? DocumentTypes.ChildAvatarBigSize
              : DocumentTypes.ContactAvatarBigSize;
          saveAvatarDocument(bigCanvasDataUrl, bigAvatarDocumentType, "Big avatar.jpg");
        }
      }
    }
  };

  function saveAvatarDocument(canvasDataUrl: string, documentType: number, fileName: string) {
    var avatarBlob = dataURLtoBlob(canvasDataUrl);
    if (avatarBlob !== null) {
      var avatarDocument = createDocument(documentType, avatarBlob, fileName);
      uploadAvatarDocument(dispatch, avatarDocument);
    }
  }

  function createDocument(documentTypeId: number, blob: Blob, fileName: string) {
    return new AvatarDocument(
      documentTypeId,
      props.avatarInfo.entityType === EntityType.RegistrationChild ||
        props.avatarInfo.entityType === EntityType.RegistrationContact,
      { data: blob, fileName: fileName },
      props.avatarInfo.entityType === EntityType.RegistrationChild ? props.avatarInfo.entityId : undefined,
      props.avatarInfo.entityType === EntityType.RegistrationContact ? props.avatarInfo.entityId : undefined,
      props.avatarInfo.entityType === EntityType.Child && props.avatarInfo.entityId
        ? parseInt(props.avatarInfo.entityId)
        : undefined,
      props.avatarInfo.entityType === EntityType.Contact && props.avatarInfo.entityId
        ? parseInt(props.avatarInfo.entityId)
        : undefined,
      props.avatarInfo.customerAccountId
    );
  }

  function dataURLtoBlob(dataUrl: string) {
    var arr = dataUrl.split(","),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "image/jpeg" });
  }

  function resizeCanvas(canvas: HTMLCanvasElement, targetWidth: number) {
    var resizedCanvas = document.createElement("canvas");
    var resizedContext = resizedCanvas.getContext("2d");

    resizedCanvas.width = targetWidth;
    resizedCanvas.height = (canvas.height * targetWidth) / canvas.width;

    if (resizedContext !== null) {
      resizedContext.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
      return resizedCanvas.toDataURL();
    }
    return null;
  }

  return (
    <>
      <Grid
        container
        mt={0}
        spacing={1}
        direction="row"
        alignItems="center"
        justifyItems={"center"}
        justifyContent="center"
      >
        <Grid item mt={0}>
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              showAvatarDialog(props.avatarInfo.index);
            }}
          >
            <AvatarDisplay info={props.avatarInfo} />
          </Link>
        </Grid>
        <Grid item mt={0}>
          <Link
            className={classes.editAvatarLink}
            to="#"
            onClick={(event) => {
              event.preventDefault();
              showAvatarDialog(props.avatarInfo.index);
            }}
          >
            <span>Edit Avatar</span>
          </Link>
        </Grid>
      </Grid>
      <AnimatedDialog open={controlState.showAvatarDialog} onClose={hideAvatarDialog} fullWidth={true}>
        <DialogContent>
          {!controlState.showUpload && (
            <>
              <ul className={classes.avatarList}>
                {Avatars.list.map((value, index) => {
                  return (
                    <li key={value} className={classes.avatarListTile}>
                      {!props.avatarsInUse?.includes(index) ? (
                        <Link
                          to="#"
                          onClick={() => {
                            setAvatarIndex(Avatars.get(index));
                          }}
                        >
                          {
                            <AvatarDisplay
                              info={{
                                entityType: props.avatarInfo.entityType,
                                entityId: props.avatarInfo.entityId,
                                index: index,
                              }}
                              selected={controlState.selectedAvatarIndex === index}
                              alt={controlState.selectedAvatarIndex === index ? "Avatar selected" : "Avatar available"}
                            />
                          }
                        </Link>
                      ) : (
                        <AvatarDisplay
                          info={{
                            entityType: props.avatarInfo.entityType,
                            entityId: props.avatarInfo.entityId,
                            index: index,
                          }}
                          selected={controlState.selectedAvatarIndex === index}
                          disabled={true}
                          alt={controlState.selectedAvatarIndex === index ? "Avatar selected" : "Avatar unavailable"}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
              <Box paddingTop={3} />
              <strong className="color-accent-2">OR</strong>
              <Box paddingTop={3} />
              <input hidden type="file" ref={fileInput} onChange={handleNewImage} accept="image/*" />
              <Link
                to="#"
                onClick={() => {
                  fileInput?.current?.click();
                }}
              >
                <Fab className={classes.fab}>
                  <AddPhotoAlternateOutlinedIcon color="primary" />
                </Fab>
                &nbsp;&nbsp;
                <span className={classes.linkButtonText}>Upload photo</span>
              </Link>
              <Box paddingTop={2} />
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <ButtonPrimary
                  disabled={!controlState.selectedAvatarIndex || controlState.selectedAvatarIndex < 0}
                  onClick={() => saveAvatarIndex()}
                >
                  <strong>Save</strong>
                </ButtonPrimary>
              </Grid>
            </>
          )}
          {controlState.showUpload && (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.avatarEditor}>
                    <AvatarEditor
                      image={cropperState.image}
                      width={280}
                      height={280}
                      border={0}
                      color={[0, 0, 0, 0.5]} // RGBA
                      scale={cropperState.scale}
                      ref={imageCropper}
                      borderRadius={280}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box paddingTop={2} />
                  <Slider
                    value={cropperState.scale}
                    min={cropperState.allowZoomOut ? 0 : 1}
                    step={0.01}
                    max={2}
                    scale={(x) => x ** 10}
                    onChange={handleScale}
                    disabled={cropperState.image === ""}
                    aria-labelledby="non-linear-slider"
                  />
                </Grid>
              </Grid>
              <Box paddingTop={2} />
              <input hidden type="file" ref={fileInput} onChange={handleNewImage} accept="image/*" />
              <Link
                to="#"
                onClick={() => {
                  fileInput?.current?.click();
                }}
              >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <AddPhotoAlternateOutlinedIcon color="primary" />
                  &nbsp;&nbsp;
                  <span className={classes.linkButtonText}>Change photo</span>
                </Grid>
              </Link>
              <Box paddingTop={3} />
              <strong className="color-accent-2">OR</strong>
              <Box paddingTop={3} />
              <Link to="#" onClick={() => showUpload(false)}>
                <Fab className={classes.fab}>
                  <Emoji symbol={Avatars.get(0)} label="avatar" />
                </Fab>
                &nbsp;&nbsp;
                <span className={classes.linkButtonText}>Select emoji</span>
              </Link>
              <Box paddingTop={2} />
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <ButtonPrimary disabled={!cropperState.imageSelected} onClick={() => onImageSave()}>
                  <strong>Save</strong>
                </ButtonPrimary>
              </Grid>
            </>
          )}
        </DialogContent>
      </AnimatedDialog>
    </>
  );
};
