import { forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import valid from "card-validator";
import { ErrorMessage, useFormikContext } from "formik";
import { PatternFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";

export type CardType = "Mastercard" | "Visa";

export interface ICreditCardProps {
  fullName: string;
  cardNumber: string;
  expiry: string;
  cvcNumber: string;
  cardType: CardType;
  path: string;
}

export interface ICardRef {
  validate(): boolean;
}

const useStyles = makeStyles()((theme) => ({
  textField: { marginBottom: theme.spacing(2) },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const CreditCard = forwardRef<ICardRef, ICreditCardProps>((card: ICreditCardProps, ref) => {
  const { setFieldError, handleChange } = useFormikContext();
  const { classes } = useStyles();

  useImperativeHandle(ref, () => ({
    validate() {
      let isValid = false;
      let isCardTypeValid = false;
      var cardValidation = valid.number(card.cardNumber);
      var ccvValidation = valid.cvv(card.cvcNumber);
      var expiryValidation = valid.expirationDate(card.expiry);

      var nameValidation = valid.cardholderName(card.fullName);
      if (!nameValidation.isValid) {
        setFieldError(`${card.path}fullName`, "Name on Card is not valid");
      }
      if (!expiryValidation.isValid) {
        setFieldError(`${card.path}expiry`, "Expiry date is not valid");
      }
      if (!cardValidation.isValid || !cardValidation.card) {
        setFieldError(`${card.path}cardNumber`, "Card number is not valid");
      }
      if (!ccvValidation.isValid) {
        setFieldError(`${card.path}cvcNumber`, "Cvc number is not valid");
      }
      if (cardValidation.card && cardValidation.card.type.toLowerCase() !== card.cardType.toLowerCase()) {
        setFieldError(`${card.path}cardType`, "Your card number does not match the card type selected");
      } else {
        isCardTypeValid = true;
      }

      isValid =
        isCardTypeValid &&
        expiryValidation.isValid &&
        cardValidation.isValid &&
        cardValidation.card !== null &&
        nameValidation.isValid &&
        ccvValidation.isValid;
      return isValid;
    },
  }));

  return (
    <FormGroup className={classes.formGroup}>
      <FormLabel component="legend" className={classes.formLabel}>
        Card Type
      </FormLabel>
      <RadioGroup name={`${card.path}cardType`} row value={card.cardType} onChange={handleChange}>
        <FormControlLabel value="Mastercard" control={<Radio />} label="Mastercard" />
        <FormControlLabel value="Visa" control={<Radio />} label="Visa" />
      </RadioGroup>
      <ErrorMessage name={`${card.path}cardType`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
      <Box paddingBottom={2} />
      <TextField
        id={`${card.path}fullName`}
        name={`${card.path}fullName`}
        value={card.fullName}
        required
        label="Name on Card"
        fullWidth
        type="text"
        onChange={handleChange}
        className={classes.textField}
      ></TextField>
      <ErrorMessage name={`${card.path}fullName`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
      <PatternFormat
        id={`${card.path}expiry`}
        name={`${card.path}expiry`}
        onChange={handleChange}
        required
        value={card.expiry}
        customInput={TextField}
        label="Expiry"
        fullWidth
        format="##/####"
        placeholder="MM/YYYY"
        mask={["M", "M", "Y", "Y", "Y", "Y"]}
        className={classes.textField}
      />
      <ErrorMessage name={`${card.path}expiry`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>

      <PatternFormat
        id={`${card.path}cardNumber`}
        name={`${card.path}cardNumber`}
        onChange={handleChange}
        required
        value={card.cardNumber}
        customInput={TextField}
        format="#### #### #### ####"
        placeholder="____ ____ ____ ____"
        label="Card Number"
        mask="_"
        fullWidth
        className={classes.textField}
      />
      <FormHelperText>
        <ErrorMessage name={`${card.path}cardNumber`}>
          {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
        </ErrorMessage>
      </FormHelperText>

      <PatternFormat
        id={`${card.path}cvcNumber`}
        name={`${card.path}cvcNumber`}
        onChange={handleChange}
        required
        value={card.cvcNumber}
        customInput={TextField}
        label="Cvc"
        fullWidth
        format="###"
        placeholder="123"
        className={classes.textField}
      />
      <ErrorMessage name={`${card.path}cvcNumber`}>
        {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
      </ErrorMessage>
    </FormGroup>
  );
});

export default CreditCard;
