import { createActionCreator } from "deox";

import { ICreatePaymentMethodHttpResponse, IPaymentMethodHttpResponse } from "../../../types/types";
export const paymentMethodLoading = createActionCreator("paymentMethodLoading");
export const paymentMethodError = createActionCreator("paymentMethodError");
export const paymentMethodSaveInit = createActionCreator("paymentMethodSaveInit");
export const paymentMethodSaved = createActionCreator(
  "paymentMethodSaved",
  (resolve) => (result: ICreatePaymentMethodHttpResponse) => resolve(result)
);
export const paymentMethodSaving = createActionCreator("paymentMethodSaving");
export const paymentMethodSuccess = createActionCreator(
  "paymentMethodSuccess",
  (resolve) => (method: IPaymentMethodHttpResponse | null) => resolve(method)
);

export const paymentMethodSaveError = createActionCreator(
  "paymentMethodSaveError",
  (resolve) => (message: string) => resolve(message)
);
