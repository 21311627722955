import { useState } from "react";

import Alert from "@mui/material/Alert/Alert";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";

import Spacer from "../Common/Spacers/Spacers";
interface IChildBookingReasonProps {
  selected: string;
  chidlName: string;
  rollName: string;
  onChange: (value: string) => void;
  initialReason: string | undefined;
}
export default function ChildBookingReason(props: IChildBookingReasonProps) {
  const { selected, chidlName, rollName, initialReason, onChange } = props;
  const [reason, setReason] = useState(initialReason);
  return (
    <Grid container direction={"column"}>
      <Alert
        severity="info"
        className={selected}
        sx={{
          "& .MuiAlert-icon": {
            color: "black",
          },
        }}
      >
        <strong>{chidlName}</strong> is over the maximum age accepted for booking into <strong>{rollName}</strong>{" "}
        session.
      </Alert>
      <Spacer margin={5} />
      <Grid item lg={6} xs={12}>
        <TextField
          required
          inputMode="text"
          label={"Reason for attendance"}
          placeholder={chidlName}
          value={reason ?? ""}
          onChange={(evt) => {
            setReason(evt.target.value);
            onChange(evt.target.value);
          }}
        ></TextField>
      </Grid>
    </Grid>
  );
}
