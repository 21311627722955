import { Dayjs } from "dayjs";
import { createReducer } from "deox";

import { LoadedBookings, LoadedBookingsError, LoadingBookings, RecurrenceChange } from "./bookingStateActions";
import { BookingInfo } from "../../../types/models";

export interface BookingState {
  bookings: BookingInfo[] | null;
  from: Dayjs | null;
  to: Dayjs | null;
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
  isEditingCasual: boolean;
}

export const initialState: BookingState = {
  bookings: null,
  from: null,
  to: null,
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
  isEditingCasual: true
};

const bookingReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadedBookings, (state, { payload }) => ({
    ...state,
    from: payload.from,
    to: payload.to,
    bookings: payload.bookings,
    isErrorState: false,
    isInitialised: true,
    isLoading: false
  })),
  handleAction(LoadingBookings, (state, { payload }) => ({
    ...state,
    from: payload.from,
    to: payload.to,
    bookings: [],
    isErrorState: false,
    isInitialised: false,
    isLoading: true,
  })),
  handleAction(LoadedBookingsError, (state) => ({
    ...state,
    from: null,
    to: null,
    bookings: null,
    isErrorState: true,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(RecurrenceChange,(state, { payload }) => ({
    ...state,
    isEditingCasual: payload
  })),
]);

export default bookingReducer;
