import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import {
  AgeCalculationMethod,
  AgeViolationReason,
  Booking,
  BookingDate,
  BookingDays,
  BookingPreview,
  BookingScheduleType,
  BookingStatus,
  ChildBookingStatus,
  DayOfWeek,
  Document,
  EditModelEnum,
  FeeType,
  GetCreateRecurringPreviewRequest,
  IAnswer,
  IBookingEvent,
  ICancellableBooking,
  IChangeRecurringBookingRequest,
  IChild,
  IChildAgeConstraint,
  IChildService,
  ICreateCasualBookingsHttpRequest,
  ICreateRecurringBookingsRequest,
  IDigitalProgramDetailsModel,
  IGetChangeRecurringBookingPreviewRequest,
  IHolidayClubProgramInfo,
  IInlineBookingEditorModel,
  ILearningOutcomeSimpleModel,
  ILookupItemModel,
  IProgramAreaExperienceSummaryDayModel,
  IProgramAreaExperienceSummaryModel,
  IProgramVersionExtendedModel,
  IProgramZoneExperienceSummaryModel,
  IQuestionTemplate,
  IRegistrationMedicalPlanAnswer,
  IRollDetails,
  IRollInfo,
  IScheduleInfo,
  ISelectedBookingEvent,
  ISelectedChild,
  ISelectedRollDate,
  ISelectedRollDay,
  ISelectedServiceProgram,
  IServiceInfo,
  IServiceProgram,
  ISessionAvailability,
  ISessionInfo,
  ITemplateQuestionDocument,
  ITemplateQuestionOption,
  ResidentialAddress,
  Roll,
} from "./types";
import { FileParameter } from "../store/parentApi";
import { getActivityType, getBookableDates } from "../utils/helpers";

dayjs.extend(isSameOrAfter);

export enum EntityType {
  RegistrationChild,
  RegistrationContact,
  Child,
  Contact,
}

export class TempDocument {
  childId?: number | undefined;
  customerContactId?: number | undefined;
  uniqueId?: string;
  created?: boolean;
  deleted?: boolean;
}

export class Child implements IChild {
  childId?: number;
  uniqueId?: string;
  createdAt?: dayjs.Dayjs;
  crn?: string;
  firstName?: string;
  lastName?: string;
  birthday?: dayjs.Dayjs;
  genderId?: number;
  otherGender?: string;
  schoolId?: number;
  homePhone?: string;
  culturalBackground?: string;
  mainLanguage?: string;
  secondaryLanguage?: string;
  culturalNeeds?: string;
  sameAddressDetailsAsPrimary?: boolean;
  sameCulturalDetailsAsPrimary?: boolean;
  sameSchoolDetailsAsFirstChild?: boolean;
  residentialAddress?: ResidentialAddress;
  indigenousStatus?: string;
  dietaryRequirements?: string;
  specialNeeds?: string;
  educationNeeds?: string;
  behaviouralNeeds?: string;
  physicalNeeds?: string;
  medicalNeeds?: string;
  disability?: string;
  allergies?: string;
  anaphylaxisProblem?: string;
  anaphylaxisSeverity?: string;
  anaphylaxisType?: string;
  anaphylaxisTreatment?: string;
  medicalServiceName?: string;
  doctorName?: string;
  doctorAddress?: string;
  doctorPhone?: string;
  doctorSuburb?: string;
  doctorState?: string;
  doctorPostcode?: string;
  ambulanceNumber?: string;
  hasBeenImmunised?: boolean;
  hasMedicalConditions?: boolean;
  hasPhysicalNeeds?: boolean;
  hasBehaviouralNeeds?: boolean;
  hasSpecialNeeds?: boolean;
  hasDietaryRequirements?: boolean;
  medicalConditions?: number[];
  medicalConditionDetails?: number[];
  noMedicalConditionDetails?: number[];
  childSituations?: number[];
  allowAdministerMedicine?: boolean;
  allowAmbulanceTransportation?: boolean;
  allowMedicalTreatment?: boolean;
  allowTakeOutside?: boolean;
  priorityAccessId?: number;
  avatarIndex?: number;
  medicareNumber?: string;
  sameLivingCareAsFirstChild?: boolean;
  sameMedicalContactAsFirstChild?: boolean;
  otherMedicalCondition?: string;
  schoolName?: string;
  firstChildHasMedicalContact?: boolean;
  medicalPlanAnswers?: IRegistrationMedicalPlanAnswer[];
  answers?: IAnswer[];
  childSuspensionEndDate?: dayjs.Dayjs | undefined;
  childSuspensionReasonId?: number | undefined;
  isSuspended?: boolean;
  acknowledgedStatus?: boolean | undefined;
  ageExemptionExpiryDate?: dayjs.Dayjs | undefined;
  serviceId?: number | undefined;
  serviceName?: string | undefined;
  childBookingStatus?: ChildBookingStatus | undefined;
  childServices?: IChildService[];
  authorize?: boolean;
  informationIsTrue?: boolean;
  signature?: string;
  signatureDocumentId?: string;
  __type: string = "Child";
  constructor(data?: IChild) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (this as any)[property] = (data as any)[property];
      }
      this.ageExemptionExpiryDate = this.ageExemptionExpiryDate ? dayjs(this.ageExemptionExpiryDate) : undefined;
      this.childSuspensionEndDate = this.childSuspensionEndDate ? dayjs(this.childSuspensionEndDate) : undefined;
      this.birthday = this.birthday ? dayjs(this.birthday) : undefined;
    }
  }

  get displayName(): string {
    return `${this.firstName} ${this.lastName?.substring(0, 1)}`;
  }
  getAgeMonthsByDate(targetDate: dayjs.Dayjs): number {
    return targetDate.diff(this.birthday as dayjs.Dayjs, "month");
  }

  getAgeMonthsByStartOfYear(targetDate: dayjs.Dayjs): number {
    return dayjs(new Date(targetDate.year(), 0, 1)).diff(this.birthday as dayjs.Dayjs, "month");
  }
}

export class NewPaymentMethod {
  paymentTypeId: number;
  financialInstitution: string | undefined;
  branch: string | undefined;
  bsb: string | undefined;
  accountNumber: string | undefined;
  accountName: string | undefined;
  cardType: string | undefined;
  cardNumber: string | undefined;
  expireDateMonth: number | undefined;
  expireDateYear: number | undefined;
  holdersName: string | undefined;
  acceptedTermConditions: boolean;

  constructor(
    paymentTypeId: number,
    financialInstitution: string | undefined,
    branch: string | undefined,
    bsb: string | undefined,
    accountNumber: string | undefined,
    accountName: string | undefined,
    cardType: string | undefined,
    cardNumber: string | undefined,
    expireDateMonth: number | undefined,
    expireDateYear: number | undefined,
    holdersName: string | undefined,
    acceptedTermConditions: boolean
  ) {
    this.paymentTypeId = paymentTypeId;
    this.financialInstitution = financialInstitution;
    this.branch = branch;
    this.bsb = bsb;
    this.accountNumber = accountNumber;
    this.accountName = accountName;
    this.cardType = cardType;
    this.cardNumber = cardNumber;
    this.expireDateMonth = expireDateMonth;
    this.expireDateYear = expireDateYear;
    this.holdersName = holdersName;
    this.acceptedTermConditions = acceptedTermConditions;
  }
}

export class AvatarInfo {
  index?: number | undefined;
  imageDataUrl?: string | undefined;
  isLoading?: boolean;
  setDefault?: boolean;
  entityType?: EntityType;
  entityId?: string;
  customerAccountId?: number;

  constructor(
    entityType?: EntityType,
    entityId?: string,
    index?: number | undefined,
    imageDataUrl?: string | undefined,
    isLoading?: boolean,
    setDefault?: boolean,
    customerAccountId?: number
  ) {
    this.entityType = entityType;
    this.entityId = entityId;
    this.index = index;
    this.imageDataUrl = imageDataUrl;
    this.isLoading = isLoading;
    this.setDefault = setDefault;
    this.customerAccountId = customerAccountId;
  }
}

export class AvatarDocument implements Document {
  documentId?: number;
  documentTypeId?: number;
  isRegistration: boolean = false;
  isTemp?: boolean = true;
  uniqueId?: string;
  registrationChildUniqueId?: string;
  registrationContactUniqueId?: string;
  fileName?: string;
  medicalPlanConditionId?: number;
  medicalConditionDetailId?: number;
  childId?: number;
  customerContactId?: number;
  customerAccountId?: number;
  file?: FileParameter;
  expiresOn: dayjs.Dayjs | undefined;

  constructor(
    documentTypeId: number,
    isRegistration: boolean,
    file: FileParameter,
    registrationChildUniqueId?: string,
    registrationContactUniqueId?: string,
    childId?: number,
    customerContactId?: number,
    customerAccountId?: number
  ) {
    this.documentTypeId = documentTypeId;
    this.isRegistration = isRegistration;
    this.registrationChildUniqueId = registrationChildUniqueId;
    this.registrationContactUniqueId = registrationContactUniqueId;
    this.childId = childId;
    this.customerContactId = customerContactId;
    this.customerAccountId = customerAccountId;
    this.fileName = file.fileName;
    this.file = file;
  }
}

export class RollDetails implements IRollDetails {
  rollId: number;
  serviceId: number;
  rollName?: string | undefined;
  startTime?: Dayjs | undefined;
  endTime?: Dayjs | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  rollClassificationId?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
  __type?: string = "RollDetails";

  constructor(session: ISessionInfo | Roll) {
    this.rollId = session.rollId;
    this.serviceId = session.serviceId;
    this.rollName = session.rollName;
    this.startTime = session.startTime;
    this.endTime = session.endTime;
    this.primaryFeeAmount = session.primaryFeeAmount;
    this.primaryFeeName = session.primaryFeeName;
    this.casualFeeName = session.casualFeeName;
    this.casualFeeAmount = session.casualFeeAmount;
    this.sessionType = session.sessionType;
    this.sessionSubType = session.sessionSubType;
    this.minAgeMths = session.minAgeMths;
    this.maxAgeMths = session.maxAgeMths;
    this.ageCalculationMethod = session.ageCalculationMethod;
    this.ageCalculation = session.ageCalculation;
    this.defaultMinAgeMths = session.defaultMinAgeMths;
    this.defaultMaxAgeMths = session.defaultMaxAgeMths;
  }

  get isHolidayClub(): boolean {
    return this.sessionType?.toLowerCase() === "vac";
  }
  get isOhsc(): boolean {
    return this.sessionType?.toLowerCase() !== "vac";
  }
}

export enum BookingToggleType {
  Calendar,
  Schedule,
}

export class HolidayClubProgramInfo implements IHolidayClubProgramInfo {
  programDateId: number;
  sessionId: number;
  season: string;
  year: number;
  supplierId?: number | undefined;
  provider?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  activityCode?: string | undefined;
  programDescription: string;
  programName: string;
  imageUrl?: string | undefined;
  date: dayjs.Dayjs;
  __type: string = "HolidayClubProgramInfo";

  constructor(prg: IHolidayClubProgramInfo) {
    this.programDateId = prg.programDateId;
    this.sessionId = prg.sessionId;
    this.season = prg.season;
    this.year = prg.year;
    this.supplierId = prg.supplierId;
    this.provider = prg.provider;
    this.startTime = prg.startTime;
    this.endTime = prg.endTime;
    this.activityCode = prg.activityCode;
    this.programDescription = prg.programDescription;
    this.programName = prg.programName;
    this.imageUrl = prg.imageUrl;
    this.date = prg.date;
  }
}

export class BookingInfo implements Booking {
  uniqueId!: string;
  childId!: number;
  rollId!: number;
  scheduleId?: string | undefined;
  feeAmount!: number;
  discountAmount!: number;
  cancellationStatusId!: number;
  cancelledAt?: dayjs.Dayjs | undefined;
  cancelledBy?: string | undefined;
  attendedTimeIn?: dayjs.Dayjs | undefined;
  attendedTimeOut?: dayjs.Dayjs | undefined;
  feeName!: string;
  hasAttended?: boolean | undefined;
  isAttendanceMarked?: boolean | undefined;
  isBookingCreated?: boolean | undefined;
  nonAttendanceReason?: string | undefined;
  date!: dayjs.Dayjs;
  isCancelled!: boolean;
  netAmount!: number;
  createdAt!: dayjs.Dayjs;
  updatedAt?: dayjs.Dayjs | undefined;
  roll?: IRollInfo | undefined;
  schedule?: ScheduleInfo | undefined;
  program?: HolidayClubProgramInfo | undefined;
  __type?: string = "BookingInfo";

  constructor(data?: Booking) {
    if (data) {
      this.uniqueId = data.uniqueId;
      this.childId = data.childId;
      this.rollId = data.rollId;
      this.scheduleId = data.scheduleId;
      this.discountAmount = data.discountAmount;
      this.cancellationStatusId = data.cancellationStatusId;
      this.cancelledAt = data.cancelledAt;
      this.cancelledBy = data.cancelledBy;
      this.attendedTimeIn = data.attendedTimeIn;
      this.attendedTimeOut = data.attendedTimeOut;
      this.hasAttended = data.hasAttended;
      this.isAttendanceMarked = data.isAttendanceMarked;
      this.isBookingCreated = data.isBookingCreated;
      this.nonAttendanceReason = data.nonAttendanceReason;
      this.date = data.date;
      this.isCancelled = data.isCancelled;
      this.netAmount = data.netAmount;
      this.createdAt = data.createdAt;
      this.roll = data.roll ? new RollInfo(data.roll) : undefined;
      this.schedule = data.schedule ? new ScheduleInfo(data.schedule) : undefined;
      this.program = data.program ? new HolidayClubProgramInfo(data.program) : undefined;
    }
  }
  get bookingDateStart(): Dayjs {
    let start = this.date.add(this.roll?.startTime?.hour() ?? 0, "h");
    return start.add(this.roll?.startTime?.minute() ?? 0, "m");
  }
  get isEditable(): boolean {
    return this.bookingDateStart.isAfter(dayjs()) && !this.isCancelled;
  }
  get isChargeable(): boolean {
    return this.cancellationStatusId % 100 !== 1;
  }
  get isSchedule(): boolean {
    return this.scheduleId !== undefined;
  }
  get isHolidayClub(): boolean {
    return this.roll !== undefined && this.roll.sessionType?.toLocaleUpperCase() === "VAC";
  }

  get minimumDaysToAvoidCancellationFee(): number {
    if (this.isHolidayClub) {
      return this.roll!.service!.minimumDaysToAvoidCancellationFeeVacBooking;
    } else if (this.isSchedule) {
      return this.roll!.service!.minimumDaysToAvoidCancellationFeeRecurringBooking ?? 7;
    }
    return this.roll!.service!.minimumDaysToAvoidCancellationFeeCasualBooking ?? 7;
  }
}

export type EndDateOptions = "TermEnd" | "YearEnd" | "Custom" | "Casual";
export type WeekDaysOptions = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday";

export class BookingPreviewInfo implements BookingPreview {
  rollId: number;
  childId: number;
  date: dayjs.Dayjs;
  status: BookingStatus;
  fee: number;
  feeType?: FeeType | undefined;
  multiChildrenDiscount: number;
  __type?: string = "BookingPreviewInfo";

  constructor(prev: BookingPreview) {
    this.rollId = prev.rollId;
    this.childId = prev.childId;
    this.date = prev.date;
    this.status = prev.status;
    this.fee = prev.fee;
    this.feeType = prev.feeType;
    this.multiChildrenDiscount = prev.multiChildrenDiscount;
  }
}

export class ChangeRecurringBookingPreviewRequest implements IGetChangeRecurringBookingPreviewRequest {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  days: BookingDays;
  excludedDates?: dayjs.Dayjs[] | undefined;

  constructor(from: dayjs.Dayjs, to: dayjs.Dayjs, days: BookingDays, excludedDates?: dayjs.Dayjs[] | undefined) {
    this.from = from;
    this.to = to;
    this.days = days;
    this.excludedDates = excludedDates;
  }
}

export class CreateRecurringPreviewRequest implements GetCreateRecurringPreviewRequest {
  childrenIds: number[];
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  scheduleType: BookingScheduleType;
  days: BookingDaysInfo[];
  excludedDates?: BookingDate[] | undefined;
  ageViolationReasons?: AgeViolationReason[] | undefined;

  constructor(
    childrenIds: number[],
    from: dayjs.Dayjs,
    to: dayjs.Dayjs,
    scheduleType: BookingScheduleType,
    days: BookingDaysInfo[],
    excludedDates?: BookingDate[] | undefined,
    ageViolationReasons?: AgeViolationReason[] | undefined
  ) {
    this.childrenIds = childrenIds;
    this.from = from;
    this.to = to;
    this.scheduleType = scheduleType;
    this.days = days;
    this.excludedDates = excludedDates;
    this.ageViolationReasons = ageViolationReasons;
  }
}

export class BookingDaysInfo implements BookingDays {
  rollId: number;
  week1: DayOfWeek[];
  week2: DayOfWeek[] | undefined;

  constructor(rollId: number, week1: DayOfWeek[], week2: DayOfWeek[] | undefined) {
    this.rollId = rollId;
    this.week1 = week1;
    this.week2 = week2;
  }
}

export class BookingDateInfo implements BookingDate {
  rollId: number;
  date: dayjs.Dayjs;
  constructor(rollId: number, date: dayjs.Dayjs) {
    this.rollId = rollId;
    this.date = date;
  }
}

export class AgeViolationReasonInfo implements AgeViolationReason {
  childId: number;
  rollId: number;
  reason: string;
  constructor(childId: number, rollId: number, reason: string) {
    this.childId = childId;
    this.rollId = rollId;
    this.reason = reason;
  }
}

export class CreateRecurringBookingsRequest implements ICreateRecurringBookingsRequest {
  childrenIds: number[];
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  scheduleType: BookingScheduleType;
  days: BookingDaysInfo[];
  excludedDates: BookingDate[] | undefined;
  ageViolationReasons: AgeViolationReason[] | undefined;

  constructor(
    childrenIds: number[],
    from: dayjs.Dayjs,
    to: dayjs.Dayjs,
    scheduleType: BookingScheduleType,
    days: BookingDaysInfo[],
    excludedDates?: BookingDate[] | undefined,
    ageViolationReasons?: AgeViolationReason[] | undefined
  ) {
    this.childrenIds = childrenIds;
    this.from = from;
    this.to = to;
    this.scheduleType = scheduleType;
    this.days = days;
    this.excludedDates = excludedDates;
    this.ageViolationReasons = ageViolationReasons;
  }
}

export class SessionAvailability implements ISessionAvailability {
  date: dayjs.Dayjs;
  rollId: number;
  availableCapacity: number;
  reasonForRecurringBooking?: String | undefined;
  reasonForCasualBooking?: String | undefined;
  isAvailableForRecurringBooking: boolean;
  isAvailableForCasualBooking: boolean;
  __type?: string = "SessionAvailability";

  constructor(sess: ISessionAvailability) {
    this.date = sess.date;
    this.rollId = sess.rollId;
    this.availableCapacity = sess.availableCapacity;
    this.reasonForCasualBooking = sess.reasonForCasualBooking;
    this.reasonForRecurringBooking = sess.reasonForRecurringBooking;
    this.isAvailableForCasualBooking = sess.isAvailableForCasualBooking;
    this.isAvailableForRecurringBooking = sess.isAvailableForRecurringBooking;
  }

  static fromJson(data: any): SessionAvailability {
    return new SessionAvailability({
      date: dayjs(data["date"]),
      rollId: data["rollId"],
      availableCapacity: data["availableCapacity"],
      isAvailableForRecurringBooking: data["isAvailableForRecurringBooking"],
      isAvailableForCasualBooking: data["isAvailableForCasualBooking"],
      reasonForRecurringBooking: data["reasonForRecurringBooking"],
      reasonForCasualBooking: data["reasonForCasualBooking"],
    });
  }
}

export class CreateCasualBookingsHttpRequest implements ICreateCasualBookingsHttpRequest {
  childrenIds!: number[];
  dates!: BookingDate[];
  excludedDates?: BookingDate[] | undefined;
  ageViolationReasons?: AgeViolationReason[] | undefined;

  constructor(
    childrenIds: number[],
    dates: BookingDate[],
    excludedDates?: BookingDate[] | undefined,
    ageViolationReasons?: AgeViolationReason[] | undefined
  ) {
    this.childrenIds = childrenIds;
    this.dates = dates;
    this.excludedDates = excludedDates;
    this.ageViolationReasons = ageViolationReasons;
  }
}
export class InlineBooking implements Booking {
  uniqueId!: string;
  childId!: number;
  rollId!: number;
  scheduleId?: string | undefined;
  feeAmount!: number;
  discountAmount!: number;
  cancellationStatusId!: number;
  cancelledAt?: dayjs.Dayjs | undefined;
  cancelledBy?: string | undefined;
  attendedTimeIn?: dayjs.Dayjs | undefined;
  attendedTimeOut?: dayjs.Dayjs | undefined;
  feeName!: string;
  hasAttended?: boolean | undefined;
  isAttendanceMarked?: boolean | undefined;
  isBookingCreated?: boolean | undefined;
  nonAttendanceReason?: string | undefined;
  date!: dayjs.Dayjs;
  isCancelled!: boolean;
  netAmount!: number;
  createdAt!: dayjs.Dayjs;
  roll?: IRollInfo | undefined;
  schedule?: ScheduleInfo | undefined;
  originalBooking: Booking;
  __type?: string = "InlineBooking";

  constructor(bkg: Booking) {
    this.uniqueId = bkg.uniqueId;
    this.childId = bkg.childId;
    this.rollId = bkg.rollId;
    this.scheduleId = bkg.scheduleId;
    this.feeAmount = bkg.feeAmount;
    this.discountAmount = bkg.discountAmount;
    this.cancellationStatusId = bkg.cancellationStatusId;
    this.cancelledAt = bkg.cancelledAt;
    this.cancelledBy = bkg.cancelledBy;
    this.attendedTimeIn = bkg.attendedTimeIn;
    this.attendedTimeOut = bkg.attendedTimeOut;
    this.feeName = bkg.feeName;
    this.hasAttended = bkg.hasAttended;
    this.isAttendanceMarked = bkg.isAttendanceMarked;
    this.isBookingCreated = bkg.isBookingCreated;
    this.nonAttendanceReason = bkg.nonAttendanceReason;
    this.date = bkg.date;
    this.netAmount = bkg.netAmount;
    this.isCancelled = bkg.isCancelled;
    this.createdAt = bkg.createdAt;
    this.roll = bkg.roll ? new RollInfo(bkg.roll) : undefined;
    this.schedule = bkg.schedule ? new ScheduleInfo(bkg.schedule) : undefined;
    this.originalBooking = bkg;
  }
}

export class RollInfo implements IRollInfo {
  rollId: number;
  serviceId: number;
  rollName?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  primaryFeeId?: number | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  casualFeeId?: number | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  rollClassificationId?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  ageCalculationMethodId: number;
  defaultMinAgeMths!: number;
  defaultMaxAgeMths!: number;
  service?: IServiceInfo | undefined;
  __type?: string = "RollInfo";

  constructor(roll: IRollInfo) {
    this.rollId = roll.rollId;
    this.serviceId = roll.serviceId;
    this.rollName = roll.rollName;
    this.startTime = roll.startTime;
    this.endTime = roll.endTime;
    this.primaryFeeId = roll.primaryFeeId;
    this.primaryFeeAmount = roll.primaryFeeAmount;
    this.primaryFeeName = roll.primaryFeeName;
    this.casualFeeId = roll.casualFeeId;
    this.casualFeeName = roll?.casualFeeName;
    this.casualFeeAmount = roll.casualFeeAmount;
    this.sessionType = roll.sessionType;
    this.sessionSubType = roll.sessionSubType;
    this.rollClassificationId = roll.rollClassificationId;
    this.minAgeMths = roll.minAgeMths;
    this.maxAgeMths = roll.maxAgeMths;
    this.defaultMinAgeMths = roll.defaultMinAgeMths;
    this.defaultMaxAgeMths = roll.defaultMaxAgeMths;
    this.ageCalculationMethod = roll.ageCalculationMethod;
    this.ageCalculation = roll.ageCalculation;
    this.ageCalculationMethodId = roll.ageCalculationMethodId;
    this.service = roll.service as ServiceInfo;
  }

  get isHolidayClub(): boolean {
    return this.sessionType?.toLowerCase() === "vac";
  }
  get isOhsc(): boolean {
    return this.sessionType?.toLowerCase() !== "vac";
  }
  get nextAvailableSession(): dayjs.Dayjs {
    return dayjs();
  }
}

export class ServiceInfo implements IServiceInfo {
  serviceId: number;
  serviceName?: string | undefined;
  minimumDaysToAvoidCancellationFeeRecurringBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeCasualBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeVacBooking: number;
  __type?: string = "ServiceInfo";

  constructor(srv: IServiceInfo) {
    this.serviceId = srv.serviceId;
    this.serviceName = srv.serviceName;
    this.minimumDaysToAvoidCancellationFeeCasualBooking = srv.minimumDaysToAvoidCancellationFeeCasualBooking;
    this.minimumDaysToAvoidCancellationFeeRecurringBooking = srv.minimumDaysToAvoidCancellationFeeRecurringBooking;
    this.minimumDaysToAvoidCancellationFeeVacBooking = srv.minimumDaysToAvoidCancellationFeeVacBooking;
  }
}

export class ScheduleInfo implements IScheduleInfo {
  uniqueId: string;
  createdAt: dayjs.Dayjs;
  customerAccountId: number;
  rollId: number;
  childId: number;
  scheduleType: BookingScheduleType;
  effectiveFromDate: dayjs.Dayjs;
  effectiveToDate?: dayjs.Dayjs | undefined;
  firstMonday: boolean;
  firstTuesday: boolean;
  firstWednesday: boolean;
  firstThursday: boolean;
  firstFriday: boolean;
  secondMonday: boolean;
  secondTuesday: boolean;
  secondWednesday: boolean;
  secondThursday: boolean;
  secondFriday: boolean;
  numberOfUpcomingBookings: number;
  minBookingsRequired: number;
  __type?: string = "ScheduleInfo";

  constructor(sch: IScheduleInfo) {
    this.uniqueId = sch.uniqueId;
    this.createdAt = sch.createdAt;
    this.customerAccountId = sch.customerAccountId;
    this.rollId = sch.rollId;
    this.childId = sch.childId;
    this.scheduleType = sch.scheduleType;
    this.effectiveFromDate = sch.effectiveFromDate;
    this.effectiveToDate = sch.effectiveToDate;
    this.firstMonday = sch.firstMonday;
    this.firstTuesday = sch.firstTuesday;
    this.firstWednesday = sch.firstWednesday;
    this.firstThursday = sch.firstThursday;
    this.firstFriday = sch.firstFriday;
    this.secondMonday = sch.secondMonday;
    this.secondTuesday = sch.secondTuesday;
    this.secondWednesday = sch.secondWednesday;
    this.secondThursday = sch.secondThursday;
    this.secondFriday = sch.secondFriday;
    this.numberOfUpcomingBookings = sch.numberOfUpcomingBookings;
    this.minBookingsRequired = sch.minBookingsRequired;
  }

  get hasDaySelected(): boolean {
    return this.firstMonday || this.firstTuesday || this.firstWednesday || this.firstThursday || this.firstFriday;
  }
}

export class ChangeRecurringBookingRequest implements IChangeRecurringBookingRequest {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  days: BookingDaysInfo;
  excludedDates?: dayjs.Dayjs[] | undefined;
  ageViolationReason?: string | undefined;

  constructor(
    from: dayjs.Dayjs,
    to: dayjs.Dayjs,
    days: BookingDaysInfo,
    excludedDates?: dayjs.Dayjs[] | undefined,
    ageViolationReason?: string | undefined
  ) {
    this.from = from;
    this.to = to;
    this.days = days;
    this.excludedDates = excludedDates;
    this.ageViolationReason = ageViolationReason;
  }
}

export class SelectedChild {
  child: Child;
  isSelected: boolean;
  reasonRequired?: boolean;
  reason?: string | null;
  __type?: string = "SelectedChild";

  constructor(child: ISelectedChild) {
    this.child = new Child(child.child);
    this.isSelected = child.isSelected;
    this.reasonRequired = child.reasonRequired;
    this.reason = child.reason;
  }

  get hasReason(): boolean {
    return this.reason !== null && this.reason !== undefined && this.reason?.trim().length > 3;
  }

  getAgeMonthsByDate(targetDate: dayjs.Dayjs): number {
    return targetDate.diff(this.child.birthday as dayjs.Dayjs, "month");
  }

  getAgeMonthsByStartOfYear(targetDate: dayjs.Dayjs): number {
    return dayjs(new Date(targetDate.year(), 0, 1)).diff(this.child.birthday as dayjs.Dayjs, "month");
  }
}

export class ChildAgeConstraint {
  rollDate: dayjs.Dayjs;
  session: RollDetails;
  activityCode?: string | null;
  __type?: string = "ChildAgeConstraint";

  constructor(constr: IChildAgeConstraint) {
    this.rollDate = constr.rollDate;
    this.session = constr.session;
    this.activityCode = constr.activityCode;
  }

  get activityType(): string {
    return getActivityType(this.activityCode) as string;
  }

  isAgeLessThanMin(child: SelectedChild): boolean {
    if (
      child.child.ageExemptionExpiryDate !== undefined &&
      child.child.ageExemptionExpiryDate.isSameOrAfter(this.rollDate, "date")
    )
      return false;

    if (this.session.isHolidayClub) {
      return (
        (this.activityType === "Excursion" &&
          child.getAgeMonthsByDate(this.rollDate) < this.session.defaultMinAgeMths) ||
        (this.activityType !== "Excursion" && child.getAgeMonthsByDate(this.rollDate) < this.session.minAgeMths)
      );
    }
    return (
      (this.session.ageCalculation.toLowerCase() === "normal" &&
        child.getAgeMonthsByDate(this.rollDate) < this.session.minAgeMths) ||
      (this.session.ageCalculation.toLowerCase() === "tillstartofyear".toLowerCase() &&
        child.getAgeMonthsByStartOfYear(this.rollDate) < this.session.minAgeMths)
    );
  }

  isAgeGreaterThanMax(child: SelectedChild): boolean {
    if (
      child.child.ageExemptionExpiryDate !== undefined &&
      child.child.ageExemptionExpiryDate.isSameOrAfter(this.rollDate, "date")
    )
      return false;

    if (this.session.isHolidayClub) {
      return child.getAgeMonthsByDate(this.rollDate) > this.session.maxAgeMths;
    }
    return (
      (this.session.ageCalculation.toLowerCase() === "normal" &&
        child.getAgeMonthsByDate(this.rollDate) > this.session.maxAgeMths) ||
      (this.session.ageCalculation.toLowerCase() === "tillstartofyear".toLowerCase() &&
        child.getAgeMonthsByStartOfYear(this.rollDate) > this.session.maxAgeMths)
    );
  }
}

export interface SelectedRoll {
  rollId: number;
  sessionType: string | undefined;
}

export class SelectedRollDay implements ISelectedRollDay {
  isSameSessionType(rollId: number, sessionType: string | undefined) {
    return this.roll.rollId !== rollId && this.roll.sessionType === sessionType;
  }
  roll: Roll;
  isMonday: boolean;
  isTuesday: boolean;
  isWednesday: boolean;
  isThursday: boolean;
  isFriday: boolean;
  isWeekly: boolean = true;
  isSecondMonday: boolean = false;
  isSecondTuesday: boolean = false;
  isSecondWednesday: boolean = false;
  isSecondThursday: boolean = false;
  isSecondFriday: boolean = false;
  __type?: string = "SelectedRollDay";

  constructor(roll: ISelectedRollDay) {
    this.roll = roll.roll;
    this.isMonday = roll.isMonday;
    this.isTuesday = roll.isTuesday;
    this.isWednesday = roll.isWednesday;
    this.isThursday = roll.isThursday;
    this.isFriday = roll.isFriday;
  }

  get isAnyDaySeleced(): boolean {
    return this.isMonday || this.isTuesday || this.isWednesday || this.isThursday || this.isFriday;
  }

  get Week1Days(): DayOfWeek[] {
    var days = new Array<DayOfWeek>();
    if (this.isMonday) {
      days.push(DayOfWeek.Monday);
    }
    if (this.isTuesday) {
      days.push(DayOfWeek.Tuesday);
    }
    if (this.isWednesday) {
      days.push(DayOfWeek.Wednesday);
    }
    if (this.isThursday) {
      days.push(DayOfWeek.Thursday);
    }
    if (this.isFriday) {
      days.push(DayOfWeek.Friday);
    }
    return days;
  }
}

export class SelectedRollDate implements ISelectedRollDate {
  roll: Roll;
  date: dayjs.Dayjs;
  __type?: string = "SelectedRollDate";

  constructor(roll: ISelectedRollDate) {
    this.roll = roll.roll;
    this.date = roll.date;
  }
}

export class CancellableBooking implements ICancellableBooking {
  bookingDate: dayjs.Dayjs;
  bookingFee: number;
  schedule: ScheduleInfo | null;
  scheduleId: string | null;
  bookingId: string | null;
  bookingRoll: string | null;
  cancellationStatusId: number;
  cancellationNoticePeriod: number | null;

  constructor(model: ICancellableBooking) {
    this.bookingDate = model.bookingDate;
    this.bookingFee = model.bookingFee;
    this.schedule = model.schedule;
    this.scheduleId = model.scheduleId;
    this.bookingId = model.bookingId;
    this.bookingRoll = model.bookingRoll;
    this.cancellationStatusId = model.cancellationStatusId;
    this.cancellationNoticePeriod = model.cancellationNoticePeriod;
  }
}

export class InlineBookingEditorModel implements IInlineBookingEditorModel {
  booking: InlineBooking;
  child: Child;
  editMode: EditModelEnum;
  reason?: string | null;
  cancellationReasonId: number;
  cancellationReasonOtherText?: string;
  __type?: string = "InlineBookingEditorModel";

  constructor(model: IInlineBookingEditorModel) {
    this.booking = new InlineBooking(model.booking);
    this.child = new Child(model.child);
    this.editMode = model.editMode;
    this.reason = model.reason;
    this.cancellationReasonId = model.cancellationReasonId;
    this.cancellationReasonOtherText = model.cancellationReasonOtherText;
  }

  get hasReason(): boolean {
    return this.reason !== null && this.reason !== undefined && this.reason?.trim().length > 3;
  }

  getAgeMonthsByDate(targetDate: dayjs.Dayjs): number {
    return targetDate.diff(this.child.birthday as dayjs.Dayjs, "month");
  }

  getAgeMonthsByStartOfYear(targetDate: dayjs.Dayjs): number {
    return dayjs(new Date(targetDate.year(), 0, 1)).diff(this.child.birthday as dayjs.Dayjs, "month");
  }

  get isAgeGreater(): boolean {
    var bkg = this.booking;
    if (bkg.schedule) {
      var dates = getBookableDates(
        bkg.schedule?.effectiveFromDate,
        bkg.schedule?.effectiveToDate as Dayjs,
        new SelectedRollDay({
          roll: new RollInfo(bkg.roll as IRollInfo),
          isWeekly: true,
          isMonday: bkg.schedule?.firstFriday,
          isTuesday: bkg.schedule?.firstTuesday,
          isWednesday: bkg.schedule?.firstWednesday,
          isThursday: bkg.schedule?.firstThursday,
          isFriday: bkg.schedule?.firstFriday,
        }),
        []
      );
      var constraints = dates.Select(
        (d) => new ChildAgeConstraint({ rollDate: d, session: new RollInfo(bkg.roll as IRollInfo) })
      );
      var chld = new SelectedChild({ child: this.child, isSelected: true });
      return constraints.Any((c) => c !== undefined && c.isAgeGreaterThanMax(chld));
    }
    return false;
  }

  get reasonRequired(): boolean {
    return this.isAgeGreater && !this.hasReason;
  }

  get minimumDaysToAvoidCancellationFee(): number {
    var d = new RollInfo(this.booking.roll!);
    if (d.isHolidayClub) {
      return d.service!.minimumDaysToAvoidCancellationFeeVacBooking;
    } else if (this.booking.schedule) {
      return d.service!.minimumDaysToAvoidCancellationFeeRecurringBooking ?? 7;
    }
    return d.service!.minimumDaysToAvoidCancellationFeeCasualBooking ?? 7;
  }
}

export interface IMedicalPlanAnswer {
  questionId: number;
  conditionId: number;
  multipleAnswerIndex: number;
  value?: string | undefined;
  freeText?: string | undefined;
  isRequired: boolean;
  isFreeTextRequired: boolean;
}
export class MedicalPlanAnswer implements IMedicalPlanAnswer {
  questionId: number;
  conditionId: number;
  multipleAnswerIndex: number;
  value?: string | undefined;
  freeText?: string | undefined;
  isRequired: boolean;
  isFreeTextRequired: boolean;
  __type?: string = "MedicalPlanAnswer";

  constructor(model: IMedicalPlanAnswer) {
    this.questionId = model.questionId;
    this.conditionId = model.conditionId;
    this.multipleAnswerIndex = model.multipleAnswerIndex;
    this.value = model.value;
    this.freeText = model.freeText;
    this.isRequired = model.isRequired;
    this.isFreeTextRequired = model.isFreeTextRequired;
  }

  get isValid(): boolean {
    return this.isValueValid && this.isFreeTextValid;
  }

  get isValueValid(): boolean {
    return !this.isRequired || (this.value !== undefined && this.value.trim().length > 0);
  }

  get isFreeTextValid(): boolean {
    return (
      !this.isFreeTextRequired || this.value === "0" || (this.freeText !== undefined && this.freeText.trim().length > 3)
    );
  }
}

export class QuestionTemplate implements IQuestionTemplate {
  questionId: number;
  questionCategoryId: number;
  questionTypeId: number;
  parentQuestionId?: number | undefined;
  parentQuestionOptionId?: number | undefined;
  text?: string | undefined;
  defaultValue?: string | undefined;
  allowMultipleAnswers: boolean;
  allowFreeText: boolean;
  visible: boolean;
  required: boolean;
  addAnswerQuestion?: string | undefined;
  freeTextHint?: string | undefined;
  forMedicalConditions?: number[] | undefined;
  notForMedicalConditions?: number[] | undefined;
  allowedFileTypes?: string | undefined;
  allowedFileSizeInKb?: number | undefined;
  note?: string | undefined;
  freeTextRequired?: boolean | undefined;
  uploadExpirationRequired?: boolean | undefined;
  link?: string | undefined;
  questionDocuments?: ITemplateQuestionDocument[] | undefined;
  questionOptions?: ITemplateQuestionOption[] | undefined;
  multipleQuestionIndex: number = 0;

  constructor(q: IQuestionTemplate) {
    this.questionId = q.questionId;
    this.questionCategoryId = q.questionCategoryId;
    this.questionTypeId = q.questionTypeId;
    this.parentQuestionId = q.parentQuestionId;
    this.parentQuestionOptionId = q.parentQuestionOptionId;
    this.text = q.text;
    this.defaultValue = q.defaultValue;
    this.allowMultipleAnswers = q.allowMultipleAnswers;
    this.allowFreeText = q.allowFreeText;
    this.visible = q.visible;
    this.required = q.required;
    this.addAnswerQuestion = q.addAnswerQuestion;
    this.freeTextHint = q.freeTextHint;
    this.forMedicalConditions = q.forMedicalConditions;
    this.notForMedicalConditions = q.notForMedicalConditions;
    this.allowedFileTypes = q.allowedFileTypes;
    this.allowedFileSizeInKb = q.allowedFileSizeInKb;
    this.note = q.note;
    this.freeTextRequired = q.freeTextRequired;
    this.uploadExpirationRequired = q.uploadExpirationRequired;
    this.link = q.link;
    this.questionDocuments = q.questionDocuments;
    this.questionOptions = q.questionOptions;
    this.multipleQuestionIndex = q.multipleQuestionIndex ?? 0;
  }
}

export class MedicalPlanQuestionTypes {
  static readonly FreeText = 1;
  static readonly YesNo = 2;
  static readonly Document = 3;
  static readonly Radio = 4;
  static readonly Link = 5;
  static readonly Date = 6;
}

export class SessionInfo implements ISessionInfo {
  date: dayjs.Dayjs;
  sessionId: number;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  serviceId: number;
  sessionSubType?: string | undefined;
  sessionType?: string | undefined;
  rollId: number;
  rollName?: string | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  programActivityType?: string | undefined;
  programActivityTypeId?: number | undefined;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
  __type: string = "SessionInfo";

  constructor(sess: ISessionInfo) {
    this.date = sess.date;
    this.sessionId = sess.sessionId;
    this.startTime = sess.startTime;
    this.endTime = sess.endTime;
    this.primaryFeeAmount = sess.primaryFeeAmount;
    this.primaryFeeName = sess.primaryFeeName;
    this.serviceId = sess.serviceId;
    this.sessionSubType = sess.sessionSubType;
    this.sessionType = sess.sessionType;
    this.rollId = sess.rollId;
    this.rollName = sess.rollName;
    this.casualFeeName = sess.casualFeeName;
    this.casualFeeAmount = sess.casualFeeAmount;
    this.minAgeMths = sess.minAgeMths;
    this.maxAgeMths = sess.maxAgeMths;
    this.programActivityType = sess.programActivityType;
    this.programActivityTypeId = sess.programActivityTypeId;
    this.ageCalculationMethod = sess.ageCalculationMethod;
    this.ageCalculation = sess.ageCalculation;
    this.defaultMinAgeMths = sess.defaultMinAgeMths;
    this.defaultMaxAgeMths = sess.defaultMaxAgeMths;
  }
}
export class ServiceProgram implements IServiceProgram {
  programDateId: number;
  sessionId: number;
  season: string;
  year: number;
  serviceId: number;
  serviceName: string;
  rollId: number;
  rollName?: string | undefined;
  isELC: boolean;
  supplierId?: number | undefined;
  provider?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  activityCode?: string | undefined;
  programDescription?: string | undefined;
  programName: string;
  date: dayjs.Dayjs;
  imageUrl?: string | undefined;
  session: ISessionInfo;
  isCasualFee: boolean;
  programPrivateDescription?: string | undefined;
  __type: string = "ServiceProgram";

  constructor(prg: IServiceProgram) {
    this.programDateId = prg.programDateId;
    this.sessionId = prg.sessionId;
    this.season = prg.season;
    this.year = prg.year;
    this.serviceId = prg.serviceId;
    this.serviceName = prg.serviceName;
    this.rollId = prg.rollId;
    this.rollName = prg.rollName;
    this.isELC = prg.isELC;
    this.supplierId = prg.supplierId;
    this.provider = prg.provider;
    this.startTime = prg.startTime;
    this.endTime = prg.endTime;
    this.activityCode = prg.activityCode;
    this.programDescription = prg.programDescription;
    this.programName = prg.programName;
    this.date = prg.date;
    this.imageUrl = prg.imageUrl;
    this.session = new SessionInfo(prg.session);
    this.isCasualFee = prg.isCasualFee;
    this.programPrivateDescription = prg.programPrivateDescription;
  }
}

export class SelectedServiceProgram {
  program: ServiceProgram;
  isSelected: boolean;
  __type: string = "SelectedServiceProgram";

  constructor(program: ISelectedServiceProgram) {
    this.program = new ServiceProgram(program.program);
    this.isSelected = program.isSelected;
  }
}

export class BookingEvent implements IBookingEvent {
  onlineSessionId: number;
  name: string;
  shortDescription?: string | undefined;
  description: string;
  workshopRequirements?: string | undefined;
  dateAest: dayjs.Dayjs;
  dateAcst?: dayjs.Dayjs | undefined;
  dateAwst?: dayjs.Dayjs | undefined;
  duration: dayjs.Dayjs;
  cost: number;
  imageUrl: string;
  allowBooking: boolean;
  allowCancel: boolean;
  datesText?: string | undefined;
  timeText?: string | undefined;
  durationText?: string | undefined;
  __type?: string = "BookingEvent";

  constructor(bkgEvent: IBookingEvent) {
    this.onlineSessionId = bkgEvent.onlineSessionId;
    this.name = bkgEvent.name;
    this.description = bkgEvent.description;
    this.shortDescription = bkgEvent.shortDescription;
    this.workshopRequirements = bkgEvent.workshopRequirements;
    this.dateAest = bkgEvent.dateAest;
    this.duration = bkgEvent.duration;
    this.cost = bkgEvent.cost;
    this.imageUrl = bkgEvent.imageUrl;
    this.allowBooking = bkgEvent.allowBooking;
    this.allowCancel = bkgEvent.allowCancel;
    this.datesText = bkgEvent.datesText;
    this.timeText = bkgEvent.timeText;
    this.durationText = bkgEvent.durationText;
  }
}

export class SelectedBookingEvent implements ISelectedBookingEvent {
  bookingEvent: BookingEvent;
  bookedChildren: Child[];
  addChildren: Child[];
  removeChildren: Child[];
  __type: string = "SelectedBookingEvent";

  constructor(bkgEvent: ISelectedBookingEvent) {
    this.bookingEvent = new BookingEvent(bkgEvent.bookingEvent);
    this.addChildren = bkgEvent.addChildren.map((x) => new Child(x));
    this.removeChildren = bkgEvent.removeChildren.map((x) => new Child(x));
    this.bookedChildren = bkgEvent.bookedChildren.map((x) => new Child(x));
  }
}

export interface OshcBookingDetails {
  serviceId?: number | undefined;
  children: ISelectedChild[];
  casualDates: SelectedRollDate[];
  isRecurring: boolean;
  currentDate: dayjs.Dayjs | undefined;
  recurringDays: SelectedRollDay[];
  recurringStartDate: dayjs.Dayjs | null;
  recurringEndDate: dayjs.Dayjs | null;
  recurringDateType: EndDateOptions | null;
  minBookings: number;
  scheduleId?: string | undefined;
  originalRecurringEndDate: dayjs.Dayjs | undefined;
  originalRollId?: number;
  orginalWeekDays?: DayOfWeek[];
}

export enum ProgramCareGroup {
  Rocketeers = "Rocketeers",
  YourOSHC = "Your OSHC",
}

export enum ProgramCareTypeGroupId {
  Rocketeers = 2,
  YourOSHC = 1,
}

export class ProgramZoneExperienceSummaryModel implements IProgramZoneExperienceSummaryModel {
  slotNumber!: number;
  zoneId?: number | undefined;
  zoneName?: string | undefined;
  content?: string | undefined;

  constructor(data?: IProgramZoneExperienceSummaryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (this as any)[property] = (data as any)[property];
      }
    }
  }
}
export class ProgramAreaExperienceSummaryDayModel implements IProgramAreaExperienceSummaryDayModel {
  dayOfWeek!: number;
  date?: dayjs.Dayjs | undefined;
  content?: string | undefined;
  experienceId?: number | undefined;
  experienceVersionId?: number | undefined;
  experienceName?: string | undefined;
  experienceSummary?: string | undefined;
  theme?: string | undefined;
  duration?: string | undefined;
  experienceSourceId?: number | undefined;
  experienceSource?: string | undefined;
  experiencePublishStatusId?: number | undefined;
  learningOutcomes!: ILearningOutcomeSimpleModel[];
  skills!: ILookupItemModel[];
  categories!: ILookupItemModel[];

  constructor(data?: IProgramAreaExperienceSummaryDayModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (this as any)[property] = (data as any)[property];
      }
    }
    if (!data) {
      this.learningOutcomes = [];
      this.skills = [];
      this.categories = [];
    }
  }
}
export class ProgramAreaExperienceSummaryModel implements IProgramAreaExperienceSummaryModel {
  programAreaId!: number;
  careTypeGroupId!: number;
  name!: string;
  description?: string | undefined;
  sortOrder!: number;
  programId!: number;
  serviceId?: number | undefined;
  programVersionId!: number;
  isAllWeek!: boolean;
  allowEdit?: boolean | undefined;
  canModifyBeforeWeek!: boolean;
  canModifyAfterWeek!: boolean;
  weekOf?: dayjs.Dayjs | undefined;
  programAreaTypeId!: number;
  days!: ProgramAreaExperienceSummaryDayModel[];

  constructor(data?: IProgramAreaExperienceSummaryModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (this as any)[property] = (data as any)[property];
      }
    }
  }

  get mondayExperience(): ProgramAreaExperienceSummaryDayModel | undefined {
    return this.days.find((x) => x.dayOfWeek === 1);
  }

  get tuesdayExperience(): ProgramAreaExperienceSummaryDayModel | undefined {
    return this.days.find((x) => x.dayOfWeek === 2);
  }

  get wednesdayExperience(): ProgramAreaExperienceSummaryDayModel | undefined {
    return this.days.find((x) => x.dayOfWeek === 3);
  }

  get thursdayExperience(): ProgramAreaExperienceSummaryDayModel | undefined {
    return this.days.find((x) => x.dayOfWeek === 4);
  }

  get fridayExperience(): ProgramAreaExperienceSummaryDayModel | undefined {
    return this.days.find((x) => x.dayOfWeek === 5);
  }
}

export class DigitalProgramDetailsModel implements IDigitalProgramDetailsModel {
  programVersion?: IProgramVersionExtendedModel | undefined;
  areas!: ProgramAreaExperienceSummaryModel[];
  zones!: ProgramZoneExperienceSummaryModel[];

  constructor(data?: IDigitalProgramDetailsModel) {
    if (data) {
      this.programVersion = data.programVersion;
      this.areas = data.areas.map((a) => new ProgramAreaExperienceSummaryModel(a));
      this.zones = data.zones.map((z) => new ProgramZoneExperienceSummaryModel(z));
    }
    if (!data) {
      this.areas = [];
      this.zones = [];
    }
  }
}
