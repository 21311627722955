import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { List } from "linqts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import ChildOSHCBookingCard from "../../components/Bookings/ChildOSHCBookingCard";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import { CloudOshc3 } from "../../components/Common/Shapes/Shapes";
import { CardLayout } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import {
  createCasualBookings,
  createRecurringBooking,
  getCreateCasualBookingsPreview,
  getCreateRecurringPreview,
} from "../../store/modules/booking/bookingActions";
import { getUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { LoadedUnavailableDatesReset } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/store";
import theme from "../../theme";
import {
  AgeViolationReasonInfo,
  BookingDateInfo,
  BookingDaysInfo,
  BookingPreviewInfo,
  ChildAgeConstraint,
  CreateCasualBookingsHttpRequest,
  CreateRecurringBookingsRequest,
  CreateRecurringPreviewRequest,
  EndDateOptions,
  SelectedChild,
  SelectedRollDate,
  SelectedRollDay,
  SessionAvailability,
} from "../../types/models";
import {
  BookingPreview,
  BookingScheduleType,
  BookingStatus,
  FeeType,
  IGetCreateCasualBookingsPreviewRequest,
  ISessionAvailability,
  Roll,
  Service,
} from "../../types/types";
import dateOnlyToJson from "../../utils/dateOnlyToJson";
import {
  creditCardIsExpired,
  getBookableRollDates,
  getStyleColor,
  hasMinRequiredBookingsAcrossWeeks,
} from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import { GetPageState } from "../../utils/pageUtil";

dayjs.extend(isBetween);
dayjs.extend(dateOnlyToJson);

export interface ConfirmOSHCBookingState {
  children: SelectedChild[];
  isSchedule: boolean;
  casualDates: SelectedRollDate[];
  recurringDays: SelectedRollDay[];
  recurringStartDate: dayjs.Dayjs | null;
  recurringEndDate: dayjs.Dayjs | null;
  recurringDateType: EndDateOptions;
  service: Service;
}

function getMatchingUnAvailableDates(state: ConfirmOSHCBookingState, unAvailableDates: ISessionAvailability[] | null) {
  var dates = new Array<ISessionAvailability>();
  if (!unAvailableDates) {
    return dates;
  }
  if (state.isSchedule) {
    var recDays = new List(state.recurringDays);
    dates = new List(unAvailableDates)
      .Where(
        (rd) =>
          rd !== undefined &&
          !rd.isAvailableForRecurringBooking &&
          rd.date.isBetween(
            state.recurringStartDate as dayjs.Dayjs,
            state.recurringEndDate as dayjs.Dayjs,
            null,
            "[]"
          ) &&
          recDays.Any(
            (sess) =>
              sess !== undefined &&
              sess.roll.rollId === rd.rollId &&
              ((sess.isMonday && rd.date.isoWeekday() === 1) ||
                (sess.isTuesday && rd.date.isoWeekday() === 2) ||
                (sess.isWednesday && rd.date.isoWeekday() === 3) ||
                (sess.isThursday && rd.date.isoWeekday() === 4) ||
                (sess.isFriday && rd.date.isoWeekday() === 5))
          )
      )
      .ToArray();
  } else {
    var casualDates = new List(state.casualDates);
    dates = new List(unAvailableDates)
      .Where(
        (ud) =>
          ud !== undefined &&
          !ud.isAvailableForCasualBooking &&
          casualDates.Any((cd) => cd !== undefined && cd.roll.rollId === ud.rollId && cd.date.isSame(ud.date, "date"))
      )
      .ToArray();
  }

  return dates;
}

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: 40,
    "@media screen and (min-width: 720px)": {
      height: 80,
    },
  },
}));

function ConfirmOSHCBooking() {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);
  const previews = useSelector((state: RootState) => state.recurringBookingPreview.bookings);
  const casualPreviews = useSelector((state: RootState) => state.bookingSaveCasualPreview.previewResult);
  const saveState = useSelector((state: RootState) => state.createBooking);
  const navigate = useNavigate();
  const { width } = useViewport();

  const [pageState, setPageState] = useState({
    hasConflict: false,
    hasError: false,
    unAvailableDates: new Array<ISessionAvailability>(),
    newlyAddedUnAvailableDates: new Array<ISessionAvailability>(),
  });
  const [ccExpired, setCCExpired] = React.useState(false);

  let isSubmitting = saveState !== undefined && saveState.isSaving;
  var rolls = new List<Roll>([]);
  var state = GetPageState<ConfirmOSHCBookingState>()!;

  let bookingsPreview = new List(
    (state.isSchedule ? previews.slice() : casualPreviews?.slice()) ?? new Array<BookingPreview>()
  );

  if (!state.isSchedule) {
    var casualDates = new List(state.casualDates);
    var grp = casualDates.GroupBy((cd) => cd.roll.rollId);
    for (var rollId in grp) {
      // eslint-disable-next-line no-loop-func
      rolls.Add(casualDates.First((cd) => cd !== undefined && cd?.roll.rollId === parseInt(rollId)).roll);
    }
  } else {
    rolls.AddRange(new List(state.recurringDays).Select((x) => x.roll).ToArray());
  }

  var orderedCasualDates = new List(state.casualDates).OrderBy((d) => d.date);
  var from = state.isSchedule ? state.recurringStartDate : orderedCasualDates.First().date;
  var to = state.isSchedule ? state.recurringEndDate : orderedCasualDates.Last().date;

  useEffect(() => {
    if (customer_account_id) {
      if (state.isSchedule) {
        getUnAvailableSessionDays(
          dispatch,
          customer_account_id as number,
          state.recurringStartDate as dayjs.Dayjs,
          state.recurringEndDate as dayjs.Dayjs,
          rolls.Select((r) => r.rollId).ToArray(),
          state.children.map((c) => c.child.childId as number)
        );
      } else {
        getUnAvailableSessionDays(
          dispatch,
          customer_account_id as number,
          from as dayjs.Dayjs,
          to as dayjs.Dayjs,
          rolls.Select((r) => r.rollId).ToArray(),
          state.children.map((c) => c.child.childId as number)
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentPaymentMethod) {
      setCCExpired(creditCardIsExpired(currentPaymentMethod));
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (unAvailableDates) {
      var unvDates = getMatchingUnAvailableDates(state, unAvailableDates);
      setPageState({
        ...pageState,
        unAvailableDates: unvDates,
      });
      if (state.isSchedule) {
        getCreateRecurringPreview(
          dispatch,
          customer_account_id as number,
          new CreateRecurringPreviewRequest(
            state.children.map((c) => c.child.childId as number),
            state.recurringStartDate as dayjs.Dayjs,
            state.recurringEndDate as dayjs.Dayjs,
            BookingScheduleType.Weekly,
            state.recurringDays.map((rd) => new BookingDaysInfo(rd.roll.rollId, rd.Week1Days, [])),
            unvDates.map((un) => new BookingDateInfo(un.rollId, un.date))
          )
        );
      } else {
        if (state.casualDates.length > 0) {
          getCreateCasualBookingsPreview(
            dispatch,
            customer_account_id as number,
            {
              childrenIds: state.children.map((c) => c.child.childId as number),
              dates: state.casualDates
                .map((x) => new BookingDateInfo(x.roll.rollId, x.date))
                .filter((x) => unvDates.findIndex((y) => y.rollId === x.rollId && y.date.isSame(x.date)) < 0),
            } as IGetCreateCasualBookingsPreviewRequest
          );
        } else {
          setPageState({
            hasConflict: true,
            hasError: false,
            unAvailableDates: getMatchingUnAvailableDates(state, unAvailableDates),
            newlyAddedUnAvailableDates: [],
          });
        }
      }
    }
  }, [unAvailableDates]);

  useEffect(() => {
    if (saveState && saveState.isErrorState) {
      if (saveState.errorResponse && saveState.errorResponse.info && saveState.errorResponse.errorCode === 100) {
        const updatedUnAvailableDates = saveState.errorResponse.info.map((d: any) =>
          SessionAvailability.fromJson(d)
        ) as SessionAvailability[];

        const newUnAvailableDates = updatedUnAvailableDates.filter(
          (sess: SessionAvailability) =>
            pageState.unAvailableDates.findIndex((d) => d.rollId === sess.rollId && d.date.isSame(sess.date)) < 0
        ) as SessionAvailability[];

        setPageState({
          hasConflict: true,
          hasError: false,
          unAvailableDates: getMatchingUnAvailableDates(state, updatedUnAvailableDates),
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      } else {
        setPageState({
          ...pageState,
          hasError: true,
        });
      }
    } else if (saveState && saveState.isSuccess) {
      navigate("/oshc-complete");
    }
  }, [saveState]);

  const handleClickClose = () => {
    setPageState({
      ...pageState,
      hasConflict: false,
      hasError: false,
    });
  };
  if (unAvailableDates && pageState.unAvailableDates.length) {
    for (var ch of state.children) {
      bookingsPreview.AddRange(
        pageState.unAvailableDates.map(
          // eslint-disable-next-line no-loop-func
          (unv) =>
            new BookingPreviewInfo({
              rollId: unv.rollId,
              childId: ch.child.childId as number,
              date: unv.date,
              status: BookingStatus.New,
              fee: 0,
              feeType: FeeType.Recurring,
              multiChildrenDiscount: 0,
            })
        )
      );
    }
  }

  var ageViolationReasons = new Array<AgeViolationReasonInfo>();
  state.children.forEach((c) => {
    var dates = new Array<SelectedRollDate>();
    if (state.isSchedule) {
      dates = getBookableRollDates(from, to, state.recurringDays, pageState.unAvailableDates);
    } else {
      dates = state.casualDates;
    }
    var rollViolations = new List(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })))
      .Where((d) => d !== undefined && d.isAgeGreaterThanMax(c))
      .Select((d) => d.session.rollId)
      .Distinct();
    rollViolations.ForEach((rId) => {
      ageViolationReasons.push(
        new AgeViolationReasonInfo(c.child.childId as number, rId as number, c.reason as string)
      );
    });
  });

  return (
    <>
      <CardLayout>
        <CardLayout.Header bgColor={getStyleColor("--youroshc-color-primary")}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1 className="h3">Confirm Booking</h1>
            </Grid>
            <Grid item>
              <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end">
                <img src={YourOSHCLogo} alt="Your OSHC Logo" className={classes.logo} />
              </Grid>
            </Grid>
          </Grid>
        </CardLayout.Header>
        <CardLayout.Body>
          <CloudOshc3
            style={{ position: "absolute", top: -80, right: -80, display: width <= 1280 ? "none" : "block" }}
          />
          <Grid container>
            <Grid item xs={12} lg={7}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box pb={2} pt={2}>
                      <strong>
                        {from?.format ? from?.format("MMM D") : ""} - {to?.format ? to?.format("MMM D") : ""}
                      </strong>
                    </Box>
                    <br />
                    {state &&
                      new List(state.children)
                        .Select((c) => (
                          <ChildOSHCBookingCard
                            key={"ChildCard_" + c.child.childId}
                            child={c.child}
                            rolls={rolls}
                            datesUnAvailable={new List(pageState.unAvailableDates)}
                            isSchedule={state.isSchedule}
                            service={state.service}
                            previews={bookingsPreview.Where((bp) => bp?.childId === c.child.childId)}
                          />
                        ))
                        .ToArray()}
                  </Grid>
                  <Grid item xs={12}>
                    {!HasMinimumBookingsAcrossWeeks(state, pageState.unAvailableDates) && (
                      <label style={{ color: theme.palette.error.main }}>
                        {`You must make a minimum of ${state.service.firstMinimumBookingsRequired} bookings, recurring on the same days over ${state.service.firstMinimumBookingsRequired} or more weeks.`}
                      </label>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <span className="small">* &nbsp;&nbsp;Prices exclude available Child Care Subsidy (CCS)</span>
                  </Grid>
                  {rolls &&
                    rolls
                      .Select((r) => {
                        var hasDiscount =
                          bookingsPreview.Where((b) => b?.rollId === r.rollId).Sum((x) => x!.multiChildrenDiscount) > 0;
                        return (
                          <Grid item xs={12}>
                            {hasDiscount && (
                              <span className="small">
                                ** {r.rollName} bookings are eligible for multi children’s discount. The discount for
                                eligible bookings gets applied to the fortnightly statement
                              </span>
                            )}
                          </Grid>
                        );
                      })
                      .ToArray()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box paddingBottom={4} />
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ButtonSecondary
                      onClick={() => {
                        dispatch(LoadedUnavailableDatesReset());
                        navigate("/new-oshc", { state: JSON.stringify(state) });
                      }}
                    >
                      <strong>Back</strong>
                    </ButtonSecondary>
                  </Grid>
                  <Grid item>
                    <ButtonPrimary
                      style={{ backgroundColor: getStyleColor("--youroshc-color-primary") }}
                      disabled={CannotConfirm(isSubmitting, state, pageState.unAvailableDates) || ccExpired}
                      onClick={() => {
                        setPageState({
                          hasConflict: false,
                          hasError: false,
                          unAvailableDates: pageState.unAvailableDates,
                          newlyAddedUnAvailableDates: [],
                        });
                        if (state.isSchedule) {
                          var req = new CreateRecurringBookingsRequest(
                            state.children.map((c) => c.child.childId as number),
                            from as dayjs.Dayjs,
                            to as dayjs.Dayjs,
                            BookingScheduleType.Weekly,
                            state.recurringDays.map((rd) => new BookingDaysInfo(rd.roll.rollId, rd.Week1Days, [])),
                            pageState.unAvailableDates,
                            ageViolationReasons
                          );
                          createRecurringBooking(dispatch, customer_account_id as number, req);
                        } else {
                          var casReq = new CreateCasualBookingsHttpRequest(
                            state.children.map((c) => c.child.childId as number),
                            state.casualDates.map((cd) => new BookingDateInfo(cd.roll.rollId, cd.date)),
                            pageState.unAvailableDates,
                            ageViolationReasons
                          );
                          createCasualBookings(dispatch, customer_account_id as number, casReq);
                        }
                      }}
                    >
                      <strong>Confirm</strong>
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardLayout.Body>
      </CardLayout>
      <AnimatedDialog open={pageState.hasError} title="Sorry!" onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>{saveState.errorResponse?.message}</strong>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <strong style={{ color: "black" }}>Please update your booking!</strong>
            </Grid>
          </Grid>
        </DialogContent>
      </AnimatedDialog>
      <AnimatedDialog open={pageState.hasConflict} title="Sorry!" onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>Some sessions are no longer available:</strong>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {pageState.newlyAddedUnAvailableDates.map((d) => {
              return (
                <Grid item xs={12}>
                  <strong style={{ color: theme.palette.error.main }}>
                    {d.date.format("DD-MMM-YYYY")} - {rolls.FirstOrDefault((r) => r?.rollId === d.rollId)?.sessionType}
                  </strong>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <strong style={{ color: "black" }}>Please update your booking!</strong>
            </Grid>
          </Grid>
        </DialogContent>
      </AnimatedDialog>
      <LoadingDialog open={isSubmitting} message="Confirming booking" />
    </>
  );
}

function CannotConfirm(
  isSubmitting: boolean,
  state: ConfirmOSHCBookingState,
  unAvailableDates: ISessionAvailability[] | null
): boolean {
  var schDays = new List(state.recurringDays);
  return (
    isSubmitting ||
    unAvailableDates == null ||
    state.service == null ||
    state.children == null ||
    state.children.length < 0 ||
    (!state.isSchedule && state.casualDates.length === 0) ||
    (state.isSchedule &&
      (schDays.Count(
        (r) =>
          r?.isMonday === true ||
          r?.isTuesday === true ||
          r?.isWednesday === true ||
          r?.isThursday === true ||
          r?.isFriday === true
      ) === 0 ||
        state.recurringStartDate === null ||
        state.recurringEndDate === null ||
        !HasMinimumBookingsAcrossWeeks(state, unAvailableDates)))
  );
}

function HasMinimumBookingsAcrossWeeks(
  state: ConfirmOSHCBookingState,
  unAvailableDates: ISessionAvailability[]
): boolean {
  var hasMinBookings = true;
  for (let d of state.recurringDays) {
    if (
      !hasMinRequiredBookingsAcrossWeeks(
        state.recurringStartDate ?? dayjs(),
        state.recurringEndDate ?? dayjs(),
        d,
        unAvailableDates,
        state.service.firstMinimumBookingsRequired ?? 2
      )
    ) {
      hasMinBookings = false;
      break;
    }
  }
  return hasMinBookings;
}
export default ConfirmOSHCBooking;
