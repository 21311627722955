import { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { FileSaverOptions, saveAs } from "file-saver";
import { IoSettingsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import Balances from "../../components/Billing/Balances";
import DueCharges from "../../components/Billing/DueCharges";
import NotificationAlert from "../../components/Billing/NotificationAlert";
import PastCharges from "../../components/Billing/PastCharges";
import UpcomingCharges from "../../components/Billing/UpcomingCharges";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { CardLayout } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import { getAccountBalance } from "../../store/modules/accountBalance/accountBalanceActions";
import { initReport } from "../../store/modules/statementReport/statementReportActions";
import { RootState } from "../../store/store";
import { getStyleColor } from "../../utils/helpers";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },

  divider: {
    marginTop: theme.spacing(3),
  },
}));

function Billing() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const balance = useSelector((state: RootState) => state.balance.payload);
  const file = useSelector((state: RootState) => state.statementReport.payload);
  const isDownloaded = useSelector((state: RootState) => state.statementReport.isDownloaded);

  useEffect(() => {
    if (customer_account_id && !balance) {
      getAccountBalance(dispatch, customer_account_id);
    }
  }, [balance, customer_account_id]);

  useEffect(() => {
    if (isDownloaded && file) {
      const options: FileSaverOptions = {
        autoBom: false,
      };

      saveAs(file, "report.pdf", options);
      initReport(dispatch);
    }
  }, [file, isDownloaded]);

  return (
    <CardLayout>
      <CardLayout.Header bgColor="white">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <h1 className={`h2`}>Billing</h1>
          </Grid>
          <Grid item>
            <Balances />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Box marginRight={1}>
                <IconButton onClick={() => navigate("/payment-method")}>
                  <IoSettingsOutline size={32} color={getStyleColor("--custom-color-dark-grey")} />
                </IconButton>
              </Box>
              <ButtonPrimary onClick={() => navigate("/pay-now")}>
                <strong>Pay Now</strong>
              </ButtonPrimary>
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        {/* <Divider className={classes.divider} style={{ marginTop: 0 }} /> */}
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <NotificationAlert />
          </Grid>
          <Grid item xs={12} md={6}>
            <DueCharges />
            <UpcomingCharges />
          </Grid>
          <Grid item xs={12} md={6}>
            <PastCharges />
          </Grid>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default Billing;
