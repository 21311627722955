import apiService from "./programApi";
import {
  serviceLocationsError,
  serviceLocationsLoading,
  serviceLocationsSuccess,
  serviceProgramsError,
  serviceProgramsLoading,
  serviceProgramsSuccess,
  serviceProgramSummaryError,
  serviceProgramSummaryLoading,
  serviceProgramSummarySuccess,
} from "./programStateActions";
import { ServiceProgram } from "../../../types/models";
import { FileResponse, IProgramVersionSummaryModel, IServiceWithUpcomingProgram } from "../../../types/types";
import { CustomerProgramHttpResponse, ServiceWithUpcomingProgram } from "../../parentApi";
import { Dayjs } from "dayjs";
export function getServicesByLocation({
  dispatch,
  customerAccountId,
  address,
  take,
  skip,
}: {
  dispatch: any;
  customerAccountId: number;
  address: string;
  take: number;
  skip: number;
}) {
  if (!address.includes("Australia")) {
    address += ", Australia";
  }
  dispatch(serviceLocationsLoading());
  apiService.getServicesWithUpcomingPrograms(customerAccountId, address, take, skip).then(
    (services: ServiceWithUpcomingProgram[] | null) =>
      dispatch(serviceLocationsSuccess(services as IServiceWithUpcomingProgram[])),
    (error: any) => dispatch(serviceLocationsError())
  );
}

export function getServiceUpCommingPrograms(dispatch: any, customerAccountId: number, serviceId: number) {
  dispatch(serviceProgramsLoading());
  apiService.getUpcomingProgramsByCustomerAccountIdAndServiceId(customerAccountId, serviceId).then(
    (progarms: CustomerProgramHttpResponse[] | null) =>
      dispatch(serviceProgramsSuccess(progarms?.map((p) => new ServiceProgram(p)) ?? new Array<ServiceProgram>())),
    (error: any) => dispatch(serviceProgramsError())
  );
}

export function getProgramDateImage(programDateId: number) {
  return apiService.getProgramDateImage(programDateId).then((resp: FileResponse | null) => resp?.data);
}

export function getServicesEventsSummary(dispatch: any, customerAccountId: number) {
  dispatch(serviceProgramSummaryLoading());
  return apiService.getCustomerServicesEventsSummary(customerAccountId).then(
    (summary) => dispatch(serviceProgramSummarySuccess(summary)),
    (error: any) => dispatch(serviceProgramSummaryError())
  );
}

