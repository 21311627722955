import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { ChangeLoginStatus } from "./changeLoginActions";
import { changeLoginError, changeLoginInit, changeLoginPending, changeLoginSuccess } from "./changeLoginStateActions";

export interface ChangeLoginState {
  status: ChangeLoginStatus;
  payload: String | null;
}

export const initialState: ChangeLoginState = {
  status: "Initial",
  payload: null,
};

const changeLoginReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    changeLoginSuccess,
    (state, action): ChangeLoginState => ({
      ...state,
      status: "Success",
      payload: null,
    })
  ),
  handleAction(
    changeLoginPending,
    (state, action): ChangeLoginState => ({
      ...state,
      status: "Pending",
      payload: null,
    })
  ),
  handleAction(
    changeLoginError,
    (state, action): ChangeLoginState => ({
      ...state,
      status: "Errored",
      payload: action.payload,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(changeLoginInit, () => initialState),
]);

export default changeLoginReducer;
