import { useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import * as actions from "../../../store/modules/register/registerActions";
import { logRegistrationEvent } from "../../../store/modules/register/registerActions";
import { RegistrationErrorClearAction } from "../../../store/modules/register/registerStateActions";
import { RootState } from "../../../store/reducers";
import { IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import PriorityAccess from "../../Children/PriorityAccess";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import ChildStep8Schema from "./ChildStep8Schema";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  radio: {
    padding: 0,
    paddingTop: "3px",
    paddingRight: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControlLabel: {
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep8({ child, id }: Props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { isRegistrationError, registrationError } = useSelector((state: RootState) => state.register);

  function updateChild(values: IChild) {
    actions.saveChild(dispatch, values);
    navigate(`/registration-child-step/9/${id}`);
  }

  useEffect(() => {
    if (isRegistrationError) {
      logRegistrationEvent(registrationError, "saveChild");
      dispatch(RegistrationErrorClearAction());
    }
  }, [isRegistrationError]);

  const formikValues = {
    priorityAccessId: undefined,
    childSituations: [],
  };
  const initialValues = merge(formikValues, child);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChildStep8Schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild(values);
        }, 500);
      }}
    >
      {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
        <Form>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={12}>
              <PriorityAccess
                classes={classes}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                dispatch={dispatch}
              />
            </Grid>
          </Grid>
          <Pager>
            <ButtonSecondary href={`/registration-child-step/7/${id}`}>
              <strong>Back</strong>
            </ButtonSecondary>
            <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
              <strong>Next</strong>
            </ButtonPrimary>
          </Pager>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}

export default FormChildStep8;
