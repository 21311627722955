import React from "react";

import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

import { AutocompleteBase } from "./Autocomplete";
import { searchAddress } from "../../../store/modules/common/commonActions";
import { AddressSearchResultHttpResponse } from "../../../store/parentApi";

interface Props {
  id: string;
  label: string;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: AddressSearchResultHttpResponse | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<AddressSearchResultHttpResponse>
  ) => void;
  helperText?: any;
  error?: any;
}

export function AddressAutocomplete(props: Props) {
  let defaultValue;

  return (
    <AutocompleteBase<AddressSearchResultHttpResponse>
      id={props.id}
      label={props.label}
      onChange={props.onChange}
      getOptionSelected={(option, value) => option.formattedAddress === value.formattedAddress}
      getOptionLabel={(option) => option.formattedAddress ?? ""}
      onPatternChange={async (pattern: string) => await searchAddress(pattern)}
      defaultValue={defaultValue}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Grid container alignItems="center">
            {option.formattedAddress}
          </Grid>
        </li>
      )}
      helperText={props.helperText}
      error={props.error}
      minCharacters={2}
      maxCharacters={30}
    />
  );
}
