import React, { useRef } from "react";

import Alert from "@mui/lab/Alert";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import Fab from "@mui/material/Fab";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  createDocument,
  createRegistrationDocument,
  deleteRegistrationDocument,
  download,
  downloadRegistrationDocument,
} from "../../../store/modules/document/documentActions";
import {
  DocumentErrorAction,
  DocumentResetAction,
  RemoveDocumentAction,
} from "../../../store/modules/document/documentStateActions";
import { RootState } from "../../../store/store";
import theme from "../../../theme";
import { Document } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonAccent2OutlinedRound, ButtonPrimary } from "../../Common/Buttons/Buttons";
import { AnimatedDialog } from "../Dialogs/AnimatedDialog";

export enum DocumentManagerMode {
  standardList,
  medicalPlanAction,
}

interface Props {
  documentTypeId: number;
  documents: Document[];
  customerAccountId?: number | undefined;
  registrationChildUniqueId?: string | undefined;
  registrationContactUniqueId?: string | undefined;
  childId?: number | undefined;
  customerContactId?: number | undefined;
  medicalPlanConditionId?: number | undefined;
  medicalConditionDetailId?: number | undefined;
  medicalPlanQuestionId?: number | undefined;
  medicalPlanAnswerIndex?: number | undefined;
  isRegistration: boolean;
  isTemp: boolean;
  maxFiles?: number | null;
  allowedExtensions?: string | null;
  controlId: string;
  documentManagerMode?: DocumentManagerMode;
}

export const DocumentManager = (props: Props) => {
  const documentManagerMode = props.documentManagerMode ?? DocumentManagerMode.standardList;
  const maxFiles = props.maxFiles ?? 1;
  let remainingFiles = maxFiles - props.documents.length;
  let uploadDisabled = remainingFiles < 1;

  const useStyles = makeStyles()((theme) => ({
    addIcon: {
      verticalAlign: "middle",
      display: "inline-block",
    },
    addText: {
      verticalAlign: "middle",
      display: "inline-block",
    },
    avatar: {
      backgroundColor: "white",
    },
    documentManager: {
      position: "relative",
      zIndex: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    documentManagerError: {
      marginTop: "10px",
    },
    fileName: {
      cursor: "pointer",
      fontSize: 18,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    fileExpired: {
      color: getStyleColor("--custom-color-red"),
    },
    fileValid: {
      color: getStyleColor("--custom-color-green"),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
      position: "absolute",
    },
    root: {
      display: "flex",
      flexDirection: "column",
    },
    removeButton: {
      marginLeft: 20,
      width: 36,
      height: 36,
      backgroundColor: "white",
    },
    listUi: {
      paddingBottom: theme.spacing(1),
    },
    listItem: {
      padding: 0,
      paddingLeft: 0,
      paddingBottom: theme.spacing(2),
      margin: 0,
    },
    circularProgress: {
      marginLeft: theme.spacing(1),
      position: "absolute",
      top: "13px",
      right: "13px",
      color: getStyleColor("--color-accent-2"),
    },
    textField: { marginBottom: theme.spacing(2) },
  }));

  const { classes } = useStyles();

  const dispatch = useDispatch();

  const controlId = props.controlId;

  const documentStates = useSelector((state: RootState) => state.document.documentStates);
  var currentDocumentState = documentStates?.find((x) => x.stateId === controlId);

  const [state, setState] = React.useState<{
    showUploadDialog: boolean;
    selectedFileName: string;
    selectedFileExpiration: dayjs.Dayjs | undefined;
    selectedDocument: Document | null;
    submitted: boolean;
  }>({
    showUploadDialog: false,
    selectedFileName: "",
    selectedFileExpiration: undefined,
    selectedDocument: null,
    submitted: false,
  });

  const fileInput = useRef<HTMLInputElement>(null);

  const uploadFile = (event: any, expiresOn: dayjs.Dayjs | undefined, upload: boolean) => {
    if (event.target.files[0] == null) {
      return;
    }

    var allowedExtensions = props.allowedExtensions ?? "jpg,png,pdf,tiff,docx,pct,pic";
    var allowedExtensionsArr = allowedExtensions.split(",");
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      const fileName = file.name;
      if (fileName.length > 0) {
        var validExtension = false;
        for (var j = 0; j < allowedExtensionsArr.length; j++) {
          var allowedExtension = "." + allowedExtensionsArr[j];
          if (
            fileName.substr(fileName.length - allowedExtension.length, allowedExtension.length).toLowerCase() ===
            allowedExtension.toLowerCase()
          ) {
            validExtension = true;
            break;
          }
        }

        if (!validExtension) {
          setState({
            ...state,
            selectedFileName: "",
            selectedDocument: null,
          });

          dispatch(
            DocumentErrorAction(controlId, `${fileName} is invalid, allowed extensions are: ${allowedExtensions}`)
          );
          return;
        }
      }
    }

    if (event.target.files.length > remainingFiles) {
      dispatch(DocumentErrorAction(controlId, `Maximum allowed files are: ${maxFiles}`));
      return;
    }

    var selectedFileName = "";
    if (!upload) {
      const file = event.target.files[0];
      selectedFileName = file.name;
    }

    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      if (props.isRegistration) {
        const document = {
          registrationChildUniqueId: props.registrationChildUniqueId,
          registrationContactUniqueId: props.registrationContactUniqueId,
          documentTypeId: props.documentTypeId,
          medicalPlanConditionId: props.medicalPlanConditionId,
          medicalPlanAnswerIndex: props.medicalPlanAnswerIndex,
          medicalPlanQuestionId: props.medicalPlanQuestionId,
          file: { data: file, fileName: file.name },
          fileName: file.name,
          isTemp: props.isTemp,
          isRegistration: props.isRegistration,
          expiresOn: expiresOn,
        };
        var registrationDocument = createRegistrationDocument(controlId, document);

        if (upload) {
          registrationDocument(dispatch);
        } else {
          setState({
            ...state,
            selectedDocument: document,
            selectedFileName: selectedFileName,
          });
        }
      } else {
        if (props.customerAccountId) {
          const document = {
            childId: props.childId,
            customerContactId: props.customerContactId,
            documentTypeId: props.documentTypeId,
            medicalPlanConditionId: props.medicalPlanConditionId,
            medicalPlanAnswerIndex: props.medicalPlanAnswerIndex,
            medicalPlanQuestionId: props.medicalPlanQuestionId,
            file: { data: file, fileName: file.name },
            fileName: file.name,
            isTemp: props.isTemp,
            isRegistration: props.isRegistration,
            expiresOn: expiresOn,
          };
          var registeredDocument = createDocument(controlId, props.customerAccountId, document);
          if (upload) {
            registeredDocument(dispatch);
          } else {
            setState({
              ...state,
              selectedDocument: document,
              selectedFileName: selectedFileName,
            });
          }
        }
      }
    }
  };

  const deleteDocument = (document: Document) => {
    if (document.isRegistration) {
      if (document.uniqueId) {
        deleteRegistrationDocument(dispatch, controlId, document.uniqueId);
      }
    } else {
      dispatch(RemoveDocumentAction(controlId, document));
    }
  };

  const downloadDocument = (uniqueId: string, fileName: string, isRegistration: boolean) => {
    if (isRegistration) {
      downloadRegistrationDocument(dispatch, controlId, fileName, uniqueId);
    } else {
      if (props.customerAccountId) {
        download(dispatch, controlId, props.customerAccountId, fileName, uniqueId);
      }
    }
  };

  const openUploadDialog = () => {
    dispatch(DocumentResetAction(controlId));

    setState({
      ...state,
      showUploadDialog: true,
      selectedFileName: "",
      selectedFileExpiration: dayjs(),
      selectedDocument: null,
    });
  };

  var fileResponse = currentDocumentState?.fileResponse;
  var fileName = currentDocumentState?.fileName;

  if (fileResponse && fileName) {
    // Create blob link to download

    const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    if (link.parentNode !== null) {
      link.parentNode.removeChild(link);
    }
  }

  const saveDocumentWithExpiration = () => {
    if (
      state.selectedDocument != null &&
      state.selectedFileExpiration !== undefined &&
      state.selectedFileExpiration.isValid()
    ) {
      var selectedDocument = state.selectedDocument;
      selectedDocument.expiresOn = state.selectedFileExpiration;

      if (props.isRegistration) {
        var registrationDocument = createRegistrationDocument(controlId, selectedDocument);
        registrationDocument(dispatch);
      } else {
        if (props.customerAccountId) {
          var registeredDocument = createDocument(controlId, props.customerAccountId, selectedDocument);
          registeredDocument(dispatch);
        }
      }
      setState({
        ...state,
        submitted: false,
        showUploadDialog: false,
      });
    } else {
      setState({
        ...state,
        submitted: true,
      });
    }
  };

  const closeUploadDialog = () => {
    setState({
      ...state,
      showUploadDialog: false,
    });
  };

  const getExpirationLabel = (expiresOn: dayjs.Dayjs | undefined) => {
    var isExpired = expiresOn?.isBefore(dayjs(new Date()));
    var expirationLabel = `${isExpired ? "Expired" : "Expires"} on ${expiresOn?.format("D/MM/YYYY")}`;
    return <span className={`${isExpired ? classes.fileExpired : classes.fileValid}`}>{expirationLabel}</span>;
  };

  return (
    <>
      {documentManagerMode === DocumentManagerMode.standardList && (
        <>
          <List className={classes.listUi}>
            {props.documents &&
              props.documents.map((document) => (
                <ListItem key={document.uniqueId} className={`${classes.listItem} color-accent-2`}>
                  <span
                    className={classes.fileName}
                    onClick={() => {
                      if (document.uniqueId != null) {
                        downloadDocument(document.uniqueId, document.fileName!, document.isRegistration);
                      }
                    }}
                  >
                    {decodeURI(document.fileName ?? "")}
                  </span>
                  <Fab
                    aria-label="remove"
                    className={classes.removeButton}
                    onClick={() => {
                      if (document.uniqueId != null) {
                        deleteDocument(document);
                      }
                    }}
                  >
                    <IoRemoveOutline size={28} color={theme.palette.primary.dark} />
                  </Fab>
                </ListItem>
              ))}
          </List>

          <input
            hidden
            type="file"
            ref={fileInput}
            onChange={(event) => {
              uploadFile(event, undefined, true);
            }}
            multiple
          />
          <ButtonAccent2OutlinedRound onClick={() => fileInput?.current?.click()} disabled={uploadDisabled}>
            <strong>Upload</strong>
            {currentDocumentState?.isProcessing && <CircularProgress className={classes.circularProgress} size={24} />}
          </ButtonAccent2OutlinedRound>
          {currentDocumentState?.isErrorState && (
            <Alert className={classes.documentManagerError} severity="error">
              {currentDocumentState?.errorMessage}
            </Alert>
          )}
        </>
      )}

      {documentManagerMode === DocumentManagerMode.medicalPlanAction && (
        <>
          {props.documents && props.documents.length > 0 && (
            <Box padding={2} bgcolor={getStyleColor("--custom-color-light-grey")} borderRadius={3}>
              {props.documents.map((document, index) => (
                <Grid container key={index} alignItems="center" spacing={2}>
                  <Grid item xs>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <Link
                          to="#"
                          className={classes.fileName}
                          onClick={(event: any) => {
                            event.preventDefault();
                            if (document.uniqueId != null) {
                              downloadDocument(document.uniqueId, document.fileName!, document.isRegistration);
                            }
                          }}
                        >
                          {decodeURI(document.fileName ?? "")}
                        </Link>
                      </Grid>
                      <Grid item>{getExpirationLabel(document.expiresOn)}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Fab
                      aria-label="remove"
                      className={classes.removeButton}
                      onClick={() => {
                        if (document.uniqueId != null) {
                          deleteDocument(document);
                        }
                      }}
                    >
                      <IoRemoveOutline size={28} color={theme.palette.primary.dark} />
                    </Fab>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}

          <Box paddingTop={2} paddingBottom={2} marginLeft="-12px">
            <Grid container alignItems="center" justifyContent="flex-start" spacing={1} direction="row">
              <IconButton
                aria-label="add"
                component={Link}
                to={"#"}
                onClick={(event: any) => {
                  event.preventDefault();
                  openUploadDialog();
                }}
                disabled={uploadDisabled}
              >
                <Avatar className={classes.avatar} component={Paper} elevation={2}>
                  <IoAddOutline size={28} color={theme.palette.primary.dark} />
                </Avatar>
              </IconButton>
              <Button
                component={Link}
                to={"#"}
                onClick={(event: any) => {
                  event.preventDefault();
                  openUploadDialog();
                }}
                style={{ textTransform: "none" }}
                disabled={uploadDisabled}
              >
                <span>Add Action Plan</span>
              </Button>
            </Grid>
          </Box>
          {(state.showUploadDialog || currentDocumentState?.isUploading) && (
            <AnimatedDialog open={true} onClose={closeUploadDialog} title="Add Action Plan">
              <DialogContent>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <p>
                      Please provide an action plan endorsed by your child's medical practitioner. Please upload before
                      your child's first session.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      hidden
                      type="file"
                      ref={fileInput}
                      onChange={(event) => {
                        dispatch(DocumentResetAction(controlId));
                        uploadFile(event, state.selectedFileExpiration, false);
                      }}
                    />
                    <ButtonAccent2OutlinedRound onClick={() => fileInput?.current?.click()}>
                      <strong>Upload</strong>
                    </ButtonAccent2OutlinedRound>
                    {state.submitted && state.selectedDocument === null && (
                      <>
                        <FormHelperText error required>
                          Required
                        </FormHelperText>
                      </>
                    )}
                    {currentDocumentState?.isErrorState && (
                      <Alert className={classes.documentManagerError} severity="error">
                        {currentDocumentState?.errorMessage}
                      </Alert>
                    )}
                    <p>
                      <span>{state.selectedFileName}</span>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        format="DD/MM/YYYY"
                        label="Action Plan Expiry Date"
                        value={state.selectedFileExpiration || null}
                        onChange={(value) => {
                          setState({
                            ...state,
                            selectedFileExpiration: value?.clone(),
                          });
                        }}
                        slotProps={{
                          textField: {
                            "aria-label": "Change Action Plan Expiry Date",
                            required: true,
                            id: "dateOfBirth",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    {/* <KeyboardDatePicker
                      className={classes.textField}
                      disableToolbar
                      format="DD/MM/YYYY"
                      margin="normal"
                      id="actionPlanExpiration"
                      label="Action Plan Expiry Date"
                      value={state.selectedFileExpiration || null}
                      onChange={(value) => {
                        setState({
                          ...state,
                          selectedFileExpiration: value?.clone(),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "Change Action Plan Expiry Date",
                      }}
                      required
                    /> */}
                    {state.submitted && state.selectedFileExpiration === undefined && (
                      <>
                        <FormHelperText error required>
                          Required
                        </FormHelperText>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <ButtonPrimary onClick={saveDocumentWithExpiration}>
                        <strong>Save</strong>
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Grid>
                <Backdrop className={classes.backdrop} open={currentDocumentState?.isUploading ?? false}>
                  <CircularProgress color="primary" />
                </Backdrop>
              </DialogContent>
            </AnimatedDialog>
          )}
        </>
      )}
    </>
  );
};
