import { useState } from "react";

import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  IoCalendarOutline,
  IoCallOutline,
  IoHomeOutline,
  IoImagesOutline,
  IoMenuOutline,
  IoReceiptOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => {
  return {
    paper: {},
    list: {
      width: 250,
      backgroundColor: "transparent",
    },
    listUi: {
      padding: 0,
    },
    listItem: {
      padding: "1rem",
    },
    listItemIcon: {
      marginRight: "1rem",
    },
    icon: {
      fontSize: "24px",
    },
    fab: {
      backgroundColor: "transparent",
      boxShadow: "none",
      marginRight: theme.spacing(1),
      "&:hover": {
        boxShadow: "none",
      },
    },
    fabMenu: {
      backgroundColor: "white",
      border: "2px solid black",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  };
});

function HeaderDrawer() {
  const { classes } = useStyles();
  const [isToggled, setToggled] = useState(false);

  const toggleTrueFalse = () => {
    setToggled(!isToggled);
  };

  const list = () => (
    <div className={classes.list} role="presentation" onClick={toggleTrueFalse}>
      <List className={classes.listUi}>
        <ListItem button className={classes.listItem} component={Link} to="/dashboard">
          <ListItemIcon className={classes.listItemIcon}>
            <Fab className={classes.fabMenu} aria-label="Dashboard">
              <IoHomeOutline size={24} />
            </Fab>
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/bookings">
          <ListItemIcon className={classes.listItemIcon}>
            <Fab className={classes.fabMenu} aria-label="Bookings">
              <IoCalendarOutline size={24} />
            </Fab>
          </ListItemIcon>
          <ListItemText primary="Bookings" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/gallery">
          <ListItemIcon className={classes.listItemIcon}>
            <Fab className={classes.fabMenu} aria-label="Gallery">
              <IoImagesOutline size={24} />
            </Fab>
          </ListItemIcon>
          <ListItemText primary="Gallery" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/billing">
          <ListItemIcon className={classes.listItemIcon}>
            <Fab className={classes.fabMenu} aria-label="Billing">
              <IoReceiptOutline size={24} />
            </Fab>
          </ListItemIcon>
          <ListItemText primary="Billing" />
        </ListItem>
        <ListItem button className={classes.listItem} component={Link} to="/your-services">
          <ListItemIcon className={classes.listItemIcon}>
            <Fab className={classes.fabMenu} aria-label="Your services">
              <IoCallOutline size={24} />
            </Fab>
          </ListItemIcon>
          <ListItemText primary="Your Services" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Fab onClick={toggleTrueFalse} className={classes.fab} id="fabDrawer">
        <IoMenuOutline size={32} />
      </Fab>
      <Drawer
        anchor="left"
        open={isToggled}
        id="toggleDrawer"
        onClose={toggleTrueFalse}
        classes={{ paper: classes.paper }}
      >
        {list()}
      </Drawer>
    </>
  );
}

export default HeaderDrawer;
