import { createReducer } from "deox";

import { AccountStatement } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { balanceError, balanceLoading, balanceSuccess } from "./accountBalanceStateActions";

export interface BalanceState {
  payload: AccountStatement | null;
  isError: boolean;
  isLoading: boolean;
}

export const initialState: BalanceState = {
  payload: null,
  isError: false,
  isLoading: false,
};

const balance = createReducer(initialState, (handleAction) => [
  handleAction(
    balanceSuccess,
    (state, action): BalanceState => ({
      ...state,
      isLoading: false,
      isError: false,
      payload: action.payload,
    })
  ),
  handleAction(
    balanceLoading,
    (state): BalanceState => ({
      ...state,
      isLoading: true,
      isError: false,
    })
  ),
  handleAction(
    balanceError,
    (state): BalanceState => ({
      ...state,
      isError: true,
      isLoading: false,
      payload: null,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default balance;
