import { PrivacySettingType } from "../../../types/types";
import { TogglePrivacySettingHttpRequest } from "../../parentApi";
import api from "./privacyApi";
import {
  loadPrivacyError,
  loadPrivacyPending,
  loadPrivacySuccess,
  savePrivacyError,
  savePrivacyInit,
  savePrivacyPending,
  savePrivacySuccess,
} from "./privacyStateActions";
export type PrivacySettingsStatus = "Initial" | "Pending" | "Success" | "Errored";
export function getPrivacySettings(dispatch: any, customerAccountId: number) {
  dispatch(loadPrivacyPending());
  api.getPrivacySettings(customerAccountId).then(
    (settings: any) => {
      dispatch(loadPrivacySuccess(settings));
    },
    function (error: any) {
      return dispatch(loadPrivacyError());
    }
  );
}

export function togglePrivacySetting(
  dispatch: any,
  customerAccountId: number,
  setting: PrivacySettingType,
  value: boolean
) {
  dispatch(savePrivacyPending());
  api
    .togglePrivacySetting(
      customerAccountId,
      new TogglePrivacySettingHttpRequest({
        privacySetting: setting,
        value: value,
      })
    )
    .then(
      function () {
        dispatch(savePrivacySuccess(setting));
      },
      function (error: any) {
        dispatch(savePrivacyError());
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(savePrivacyInit());
      }, 500);
    });
}
