import React, { useEffect } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import styles from "./Home.module.scss";
import HomeSchema from "./HomeSchema";
import { appStatusReset } from "../../../store/modules/appStatus/appStatusStateActions";
import { signinRedirect } from "../../../store/modules/auth/authServices";
import { registerCustomer } from "../../../store/modules/signup/signupActions";
import { RootState } from "../../../store/store";
import { RegistrationStatus } from "../../../types/types";
import { defaultPasswordSettings, getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";

const useStyles = makeStyles()((theme) => ({
  notchedOutline: {
    borderColor: "black",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  inputContainer: {
    maxWidth: "400px",
    width: "100%",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(4),
    maxHeight: "53.95px",
    width: "100% !important",
    fontSize: 3,
    [theme.breakpoints.between("xs", "sm")]: {
      width: 300,
      fontSize: 10,
      marginBottom: theme.spacing(3),
    },

    maxWidth: "70vw",
  },

  lastTextField: {
    marginTop: theme.spacing(2),
    maxHeight: "53.95px",
    width: "100%",
    fontSize: 3,
    [theme.breakpoints.between("xs", "sm")]: {
      width: 300,
      fontSize: 10,
      marginBottom: theme.spacing(3),
    },

    maxWidth: "70vw",
  },

  passwordTitle: {
    margin: 0,
    fontSize: 15,
    fontFamily: "Lato, Medium",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 13,
    },
  },
  passwordHints: {
    fontSize: 13,
    paddingBottom: 1,
    fontFamily: "Lato, Book",
    display: "list-item",
    maxWidth: "70vw",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 11,
    },
  },

  labelRoot: {
    color: "black",
    "&$labelFocused": {
      color: "black",
    },
  },
  primary: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: getStyleColor("--color-accent-1"),
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
  secondary: {
    borderColor: "black",
    borderWidth: 2,
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
  headline: {
    fontfamily: "Lato, Medium",
    fontWeight: "bold",
    fontSize: 20,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 20,
    },
  },
  headlineSubtitle: {
    fontfamily: "Lato, Medium",
    padding: 15,
    fontSize: 16,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 12,
      padding: 5,
    },
  },
  registrationTitle: {
    fontfamily: "Lato, Book",
    paddingBottom: 5,
    fontSize: 15,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 12,
    },
    maxWidth: "70vw",
  },
}));

interface HomeState {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  referralCode?: string | undefined;
}

function FormHome() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registrationStatus } = useSelector((state: RootState) => state.signup);
  const passwordSettings = useSelector((state: RootState) => state.passwordSettings.payload) ?? defaultPasswordSettings;
  const [showPassword, setShowPassword] = React.useState(false);
  const [localStatus, setLocalStatus] = React.useState("");
  useEffect(() => {
    const status = RegistrationStatus.NeedVerification;
    if (registrationStatus && registrationStatus.toLowerCase() === status.toLowerCase()) {
      navigate("/account/confirm-email");
    }
  }, [registrationStatus, history]);

  useEffect(() => {
    if (registrationStatus) {
      const status = registrationStatus?.toLowerCase();
      setLocalStatus(status);
    }
  }, [registrationStatus]);

  function register(values: HomeState): void {
    registerCustomer(
      dispatch,
      values.firstName.trim(),
      values.lastName.trim(),
      values.email.trim(),
      values.password,
      undefined,
      values.referralCode
    );
  }

  const handleClickShowPassword = (event: any) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    referralCode: "",
    passwordRequireUppercase: passwordSettings.passwordRequireUppercase,
    passwordRequireLowercase: passwordSettings.passwordRequireLowercase,
    PasswordRequireDigit: passwordSettings.passwordRequireDigit,
    passwordRequireNonAlphanumeric: passwordSettings.passwordRequireNonAlphanumeric,
    passwordRequiredUniqueChars: passwordSettings.passwordRequiredUniqueChars,
  };

  const theme = useTheme();
  const textFieldSize = useMediaQuery(theme.breakpoints.down("sm")) ? "small" : "medium";
  const globalPasswordLength = passwordSettings.passwordRequiredLength;

  return (
    <>
      <Box
        id="topHome"
        className={styles["Home"]}
        sx={{
          borderRadius: 5,
          maxWidth: { xs: "70vw", sm: 550 },
          mx: "auto",
          padding: "2rem",
          marginBottom: "10px",
        }}
      >
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          {/* REGISTRATION PAGE */}
          <Grid item sx={{ width: "100%" }}>
            <Typography align="center" variant="h5" className={classes.headline}>
              Create Your Account at Parental Portal
            </Typography>
            <Typography align="center" className={classes.headlineSubtitle}>
              Let's get started
            </Typography>
          </Grid>
          <Grid item xs={11} sx={{ marginTop: 3 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={HomeSchema(passwordSettings)}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  register(values);
                }, 500);
              }}
            >
              {({ handleChange, handleBlur, submitForm, setSubmitting, isSubmitting, values, touched, errors }) => (
                <Form>
                  <Grid container direction="column" spacing={3} className={classes.inputContainer} sx={{ mx: "auto" }}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      size={textFieldSize}
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      onBlur={handleBlur("firstName")}
                      helperText={touched.firstName ? errors.firstName : ""}
                      error={touched.firstName && Boolean(errors.firstName)}
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold", width: "100%" },
                      }}
                    />

                    <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      size={textFieldSize}
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      onBlur={handleBlur("lastName")}
                      helperText={touched.lastName ? errors.lastName : ""}
                      error={touched.lastName && Boolean(errors.lastName)}
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold" },
                      }}
                    />

                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      size={textFieldSize}
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold" },
                      }}
                    />

                    <TextField
                      id="password"
                      label="Password"
                      size={textFieldSize}
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      helperText={touched.password ? errors.password : ""}
                      error={touched.password && Boolean(errors.password)}
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },

                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold" },
                      }}
                    />

                    {/* PASSWORD REQUIREMENTS */}
                    <Grid container spacing={0} direction="column" sx={{ paddingBottom: 1 }}>
                      <p className={classes.passwordTitle}>Password must contain: </p>
                      <List
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "1rem",
                        }}
                      >
                        <li className={classes.passwordHints}>
                          <div
                            style={{
                              textDecoration: values.password.length >= globalPasswordLength ? "line-through" : "none",
                            }}
                          >
                            At least {globalPasswordLength} characters
                          </div>
                        </li>
                        <li
                          className={classes.passwordHints}
                          style={{ textDecoration: /[A-Z]/.test(values.password) ? "line-through" : "none" }}
                        >
                          An uppercase letter
                        </li>
                        <li
                          className={classes.passwordHints}
                          style={{ textDecoration: /[a-z]/.test(values.password) ? "line-through" : "none" }}
                        >
                          A lower case letter
                        </li>
                        <li
                          className={classes.passwordHints}
                          style={{
                            textDecoration: /^(?=.*[\d])(?=.*[^\w\s]).+$/.test(values.password)
                              ? "line-through"
                              : "none",
                          }}
                        >
                          A number AND A special symbol. (Eg. !?#)
                        </li>
                      </List>
                    </Grid>

                    <TextField
                      id="passwordConfirm"
                      label="Re-type Password"
                      size={textFieldSize}
                      type={showPassword ? "text" : "password"}
                      value={values.passwordConfirm}
                      onChange={handleChange("passwordConfirm")}
                      onBlur={handleBlur("passwordConfirm")}
                      helperText={touched.passwordConfirm ? errors.passwordConfirm : ""}
                      error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                      fullWidth
                      className={classes.textField}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: { color: "black", fontWeight: "bold" },
                      }}
                    />

                    <TextField
                      id="referralCode"
                      label="Referral Code (Optional)"
                      value={values.referralCode}
                      onChange={handleChange("referralCode")}
                      onBlur={handleBlur("referralCode")}
                      helperText={touched.referralCode ? errors.referralCode : ""}
                      error={touched.referralCode && Boolean(errors.referralCode)}
                      fullWidth
                      className={classes.textField}
                    />
                    {localStatus === RegistrationStatus.AlreadyRegistered.toLowerCase() && (
                      <Alert severity="error" sx={{ marginBottom: 2 }}>
                        The email address or username is not valid. If you are already registered,{" "}
                        <Link
                          href="#"
                          onClick={() => {
                            setSubmitting(true);
                            signinRedirect();
                          }}
                        >
                          please log in
                        </Link>
                      </Alert>
                    )}
                    {localStatus === RegistrationStatus.InvalidReferralCode.toLowerCase() && (
                      <Alert severity="error">Invalid Referral code</Alert>
                    )}
                    {localStatus === RegistrationStatus.UnknownError.toLowerCase() && (
                      <Alert severity="error" sx={{ marginBottom: 10 }}>
                        An error has been encountered, please try again.
                      </Alert>
                    )}

                    {/* REGISTER BUTTON */}
                    <ButtonPrimary
                      disabled={isSubmitting}
                      onClick={submitForm}
                      sx={{
                        width: "100%",
                        maxWidth: "70vw",
                        fontSize: { xs: 10, md: 328 },
                        margin: "0 !important",
                      }}
                    >
                      <strong>Register</strong>
                    </ButtonPrimary>

                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.registrationTitle}
                      sx={{ mt: 1, width: { xs: 328 } }}
                    >
                      You already have an Account? &nbsp;
                      <Link
                        className={classes.registrationTitle}
                        href="#"
                        onClick={() => {
                          dispatch(appStatusReset());
                          setSubmitting(true);
                          signinRedirect();
                        }}
                      >
                        Sign In Now
                      </Link>
                    </Typography>

                    <Backdrop className={classes.backdrop} open={isSubmitting}>
                      <CircularProgress color="primary" />
                    </Backdrop>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default FormHome;
