import React, { useEffect, useState } from "react";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import WarningIcon from "@mui/icons-material/Warning";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Button, ListSubheader } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box/Box";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid/Grid";
import Link from "@mui/material/Link/Link";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell/TableCell";
import TableHead from "@mui/material/TableHead/TableHead";
import TableRow from "@mui/material/TableRow/TableRow";
import Typography from "@mui/material/Typography/Typography";
import dayjs, { Dayjs } from "dayjs";
import { uniqBy } from "lodash";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ButtonAccent1, ButtonWhite } from "../../components/Common/Buttons/Buttons";
import { CalendarNavigationPicker, ToggleButtonGroupEnum } from "../../components/Common/Calendars/CalendarNavigator";
import styles from "../../components/Common/Calendars/UpcomingBookings.module.scss";
import { AvatarDisplay } from "../../components/Common/Controls/AvatarDisplay";
import Spacer from "../../components/Common/Spacers/Spacers";
import { useAppDispatch } from "../../store/hooks";
import { listBookings } from "../../store/modules/booking/bookingActions";
import { CancelBookingInitial } from "../../store/modules/booking/bookingStateActions";
import { RootState } from "../../store/store";
import { BookingInfo, EntityType } from "../../types/models";
import { ICommonDatesModel, IEditRecurringBookingState } from "../../types/types";
import {
  getCareTypeColor,
  getCareTypeColorVariable,
  getMonthViewDatesGrouped,
  getStyleColor,
  getWeekViewDates,
  isContactAllowedToBook,
} from "../../utils/helpers";
import BookNowButton from "../Bookings/BookNowButton";
import { CancellationDialog } from "../Common/Calendars/CancellationDialog";

const trimColor = "#D08DFF";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      width: "100%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    error: {
      border: "2px solid red !important",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      maxWidth: 350,
    },
    list: {
      "& .MuiListSubheader-root": {
        marginLeft: "8px",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "30px",
      },

      "& .MuiListItem-root": {
        height: "35px",
      },
    },
    calDayToday: {
      backgroundColor: getStyleColor("--color-primary"),
      borderRadius: 32,
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
      justifyContent: "center",

      minWidth: 30,
      width: 30,
      height: 30,
      "@media screen and (min-width: 1280px)": {},
      "&:hover": {
        backgroundColor: getStyleColor("--color-accent-1"),
      },
    },
    table: {
      minWidth: "900px",
      maxWidth: "100%",
      padding: "5px",
      borderWidth: "0px",
      "& .MuiTableRow-head": {
        "& .MuiTableCell-root": { border: "0px solid black", verticalAlign: "bottom", alignContent: "flex-end" },
        "& .MuiTableCell-head": {
          height: "30px",
        },
      },

      "& .MuiTableCell-root": {
        border: "1px solid black",
        minHeight: "10px",
        height: "100px",
        minWidth: "150px",
        verticalAlign: "top",
        padding: "5px",
      },
    },
    button: {
      pointerEvents: "auto",
    },
    childName: {
      maxWidth: "150px",
      //wordWrap: "none",
      wordBreak: "break-all",
      overflowWrap: "anywhere",
    },
    white: {
      color: "white",
    },
    headerCell: {
      borderBottom: "1px solid black",
    },
    bodyCell: {
      border: "1px solid black",
      height: "150px",
      width: "180px",
      maxWidth: "180px",
      padding: "0",
      fontSize: "10pt",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "& .header": {
        height: "35%",
      },
      "& .content": {
        height: "50%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& .titleLink": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        maxHeight: "1.5rem",
        lineHeight: "1.5rem",
      },
      "& .summary": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
      },
      "& .footer": {
        height: "15%",
        fontSize: "8pt",
      },
    },
    logo: {
      height: 20,
      "@media screen and (min-width: 720px)": {
        height: 20,
      },
    },
    icon: {
      width: "1rem",
      height: "1rem",
    },
    areaHeader: {
      padding: "5px",
      borderRadius: "8px",
      width: "auto",
      fontSize: "8pt",
    },
    rocketeersAreaHeader: {
      backgroundColor: getStyleColor("--rocketeers-color-primary"),
      color: "white",
    },
    oshcAreaHeader: {
      backgroundColor: getStyleColor("--youroshc-color-primary"),
      color: "black",
    },

    cancelScheduleButton: {
      background: trimColor,
      fontWeight: "bold",
      border: 1,
      margin: 20,
    },
    exclaimationIcon: {
      color: trimColor,
    },

    closeIcon: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
      padding: "8",
    },
    lateCancellation: {
      textDecoration: "line-through",
      backgroundColor: "#b42931",
    },
  };
});

interface ISchedulerProps {
  commonDates: ICommonDatesModel;
  date: Dayjs;
  onDateChanged: (date: Dayjs) => void;
}
interface IEventProps {
  careType: string;
  name: string;
}

export default function Scheduler(props: ISchedulerProps) {
  const { classes } = useStyles();
  const { commonDates, date, onDateChanged } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id) ?? 0;
  const [isSchedule, setIsSchedule] = React.useState(false);

  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);

  const bookings = useSelector((state: RootState) =>
    state.booking.bookings == null ? null : state.booking.bookings.filter((b) => b.cancellationStatusId < 100)
  );

  const [filteredBookings, setFilteredBookings] = useState<BookingInfo[]>();

  const uniqueCareTypes = uniqBy(bookings, (bk) =>
    bk.roll?.rollClassificationId === 9 ? "Pfd" : bk.roll?.sessionType ?? ""
  ).map((bk) => (bk.roll?.rollClassificationId === 9 ? "Pfd" : bk.roll?.sessionType ?? ""));

  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  const auth = useSelector((state: RootState) => state.auth);
  const { userId } = auth;
  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const commonData = useSelector((state: RootState) => state.commonData);
  const { isLoading, isInitialised } = useSelector((state: RootState) => state.booking);

  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const [expandMore, setExpandeMore] = React.useState<{ date: string; expand: boolean }[]>([]);
  const [view, setView] = React.useState<ToggleButtonGroupEnum>(ToggleButtonGroupEnum.Month);
  const [refreshBookings, setRefreshBookings] = useState(false);
  const [expandChildren, setExpandChildren] = React.useState<boolean>(false);

  const [unSelectedChidlren, setUnSelectedChidlren] = useState<number[]>([]);
  const [unSelectedCareTypes, setUnSelectedCareTypes] = useState<string[]>([]);
  const [showPaymentDueDate, setShowPaymentDueDate] = useState<boolean>(true);
  const [showHolidays, setShowHolidays] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  var daysToView = view === ToggleButtonGroupEnum.Week ? [getWeekViewDates(date)] : getMonthViewDatesGrouped(date);

  const [selectedBooking, setSelectedBooking] = useState<BookingInfo | undefined>();

  const handleClick = (event: any, bk: BookingInfo) => {
    setSelectedBooking(bk);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const [cancellationPopUpOpen, setCancellationPopUpOpen] = React.useState(false);

  const handleCancellationPopUpOpen = () => {
    setAnchorEl(null);
    setCancellationPopUpOpen(true);
  };

  const handleCancellationPopUpClose = () => {
    setAnchorEl(null);
    setSelectedBooking(undefined);
    setCancellationPopUpOpen(false);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!isLoading) {
      setFilteredBookings(bookings ?? []);
    } else {
      setFilteredBookings([]);
    }
  }, [isLoading]);
  useEffect(() => {
    if (date && (view === ToggleButtonGroupEnum.Month || refreshBookings)) {
      listBookings(
        dispatch,
        customer_account_id,
        date.startOf("month").startOf("isoWeek"),
        date.endOf("month").endOf("isoWeek")
      );

      setRefreshBookings(false);
    }
  }, [date]);

  useEffect(() => {
    setFilteredBookings(
      bookings?.filter(
        (bk) =>
          !unSelectedChidlren.includes(bk.childId) &&
          !unSelectedCareTypes.includes(bk.roll?.rollClassificationId === 9 ? "Pfd" : bk.roll?.sessionType ?? "")
      ) ?? []
    );
  }, [unSelectedChidlren, unSelectedCareTypes, isInitialised]);

  const isPaymentDueDate = (calDay: dayjs.Dayjs) => {
    return commonDates.paymentDueDates.findIndex((d) => d.isSame(calDay, "date")) >= 0;
  };

  function RenderEvent(props: IEventProps) {
    const { careType, name } = props;

    return (
      <Grid container direction={"row"} p={0} sx={{ position: "relative" }}>
        <Box
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
          justifyItems={"center"}
          className={`${styles[getCareTypeColor(careType, "Bg")]}`}
          sx={{
            width: "100%",
            borderBottomWidth: 25,
            alignContent: "center",
            margin: "2px",
            height: "25px",
            alignItems: "center",

            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
          }}
        >
          <Typography fontSize="13px" sx={{ ml: "15px" }} className={`${classes.childName} ${classes.white}`}>
            {name}
          </Typography>
        </Box>
      </Grid>
    );
  }
  function renderShowMore(bookingsCount: number, calDay: Dayjs) {
    return (
      <Link
        visibility={bookingsCount <= 3 ? "hidden" : "visible"}
        component="button"
        onClick={() =>
          setExpandeMore([
            ...expandMore.filter((v) => v.date !== calDay.format("DD/MM/YYYY")),
            {
              date: calDay.format("DD/MM/YYYY"),
              expand: !expandMore.find((d) => d.date === calDay.format("DD/MM/YYYY"))?.expand ?? true,
            },
          ])
        }
        sx={{
          color: "#000000",
          fontWeight: "bold",
          textDecorationColor: "#707070",
          fontSize: 10,
          display: "block",
          paddingTop: "5px",
        }}
      >
        {expandMore.find((d) => d.date === calDay.format("DD/MM/YYYY"))?.expand
          ? "Show less"
          : `+ ${bookingsCount - 3} more`}
      </Link>
    );
  }

  function RenderDay(todaysBookings: BookingInfo[], calDay: Dayjs) {
    const isToday = calDay.isSame(dayjs(), "date");

    const holiday = commonDates.publicHolidays.find((d) => d.date.isSame(calDay, "date"));

    const isPaymentDate = isPaymentDueDate(calDay);

    return (
      <Grid container direction={"column"} p={0}>
        <Grid item xs={12} p={0}>
          <Button
            sx={{ justifyContent: "flex-end", ":hover": { cursor: "default" } }}
            className={isToday ? classes.calDayToday : ""}
          >
            {calDay.date()}
          </Button>
        </Grid>
        {showPaymentDueDate && isPaymentDate && <RenderEvent careType="PD" name="Payment Due Date" />}
        {showHolidays && holiday != null && (
          <RenderEvent careType="HL" name={holiday.description ?? "Public Holiday"} />
        )}
        {todaysBookings.map((bk, bIdx) => {
          const careType = bk.roll?.rollClassificationId === 9 ? "Pfd" : bk.roll?.sessionType ?? "";
          const child = children?.find((x) => x.childId === bk.childId) ?? null;
          const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
          const isLateCancellation = bk.cancellationStatusId === 2;
          return (
            <Grid
              onClick={(event) => {
                handleClick(event, bk);
              }}
              container
              direction={"row"}
              key={bIdx}
              p={0}
              sx={{ position: "relative" }}
            >
              {/* CHILD BOOKING TAB */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <AvatarDisplay info={avatar || { index: child?.avatarIndex }} size={25} />
              </Box>
              <Box
                flexDirection="row"
                display="flex"
                justifyContent="space-between"
                justifyItems={"center"}
                className={`${
                  isLateCancellation ? classes.lateCancellation : styles[getCareTypeColor(careType.toUpperCase(), "Bg")]
                }`}
                sx={{
                  width: "100%",
                  borderBottomWidth: 25,
                  alignContent: "center",
                  margin: "2px",
                  marginLeft: "15px",
                  height: "25px",
                  alignItems: "center",
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                  borderTopLeftRadius: -5,
                  borderBottomLeftRadius: -5,
                  pr: 1,
                }}
              >
                <Typography
                  fontSize="13px"
                  sx={{ ml: "15px", textDecoration: bk.cancellationStatusId === 2 ? "line-through" : "none" }}
                  className={`${classes.childName} ${classes.white}`}
                >
                  {`${child?.firstName?.substring(0, 15)} ${child?.lastName?.substring(0, 1)}`}
                </Typography>
                {bk.scheduleId && !isLateCancellation && <RepeatIcon htmlColor="white" style={{ fontSize: 15 }} />}
                {isLateCancellation && <WarningIcon htmlColor="white" style={{ fontSize: 15 }} />}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  function getIsAllowedToBook() {
    return isContactAllowedToBook(customerAccount!, userId!, parseInt(customer_contact_id!.toString()));
  }

  const isAllowedToBook = getIsAllowedToBook();
  return (
    <Grid container spacing={1} sx={{ bgcolor: "background.paper" }}>
      <Grid item md={2} lg={2}>
        <Grid container direction="column" spacing={2} pl={0}>
          {isAllowedToBook && (
            <Grid item xs={12} sx={{ paddingLeft: "0" }}>
              <BookNowButton />
            </Grid>
          )}
          {/* DISPLAYS LIST OF KIDS WHO ARE BOOKING IN THAT DAY */}
          <ChildrenList />
          <Link
            visibility={(children?.length ?? 0) <= 5 ? "hidden" : "visible"}
            onClick={() => setExpandChildren(!expandChildren)}
            component="button"
            sx={{
              color: "#000000",
              fontWeight: "bold",
              textDecorationColor: "#707070",
              fontSize: 10,
              display: "block",
              textAlign: "left",
              paddingLeft: "15px",
              paddingTop: "5px",
            }}
          >
            {expandChildren ? "Show less" : `+ ${(children?.length ?? 0) - 5} more`}
          </Link>
          <ServicesList />

          {/* DISPLAYS OTHER NOTES */}
          <OtherList />
        </Grid>
      </Grid>
      <Grid item lg={10}>
        <CalendarNavigationPicker
          onDateChange={(date) => {
            onDateChanged(date);
          }}
          date={date}
          onViewChange={(view) => {
            setView(view);
            var day = dayjs(new Date(date.year(), date.month(), 1));
            var now = dayjs();
            var isCurrentMonthAndYear = day.month() === now.month() && day.year() === now.year();
            if(view === ToggleButtonGroupEnum.Week && isCurrentMonthAndYear){
                day = now.startOf("isoWeek");
            }
            onDateChanged(day);
          }}
          onBackClick={() => {
            if (view === ToggleButtonGroupEnum.Week) {
              const newDate = date.add(-7, "day");
              if (newDate.month() !== date.month()) {
                setRefreshBookings(true);
              }
              onDateChanged(newDate);
            } else onDateChanged(dayjs(new Date(date.year(), date.month() - 1, 1)));
          }}
          onForwardClick={() => {
            if (view === ToggleButtonGroupEnum.Week) {
              const newDate = date.add(+7, "day");
              if (newDate.month() !== date.month()) {
                setRefreshBookings(true);
              }
              onDateChanged(newDate);
            } else onDateChanged(dayjs(new Date(date.year(), date.month() + 1, 1)));
          }}
        />
        <Backdrop className={classes.backdrop} open={isLoading || commonData.isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell} align="right">
                <strong>Mon</strong>
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                <strong>Tue</strong>
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                <strong>Wed</strong>
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                <strong>Thu</strong>
              </TableCell>
              <TableCell className={classes.headerCell} align="right">
                <strong>Fri</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {daysToView.map((currentWeek, index) => {
              return (
                <TableRow key={index} sx={{ border: 1 }}>
                  {currentWeek.map((currentDay, dIndex) => {
                    if (currentDay.isoWeekday() > 5) return null;
                    const todaysBookings =
                      filteredBookings?.filter(
                        (b) => b.cancellationStatusId !== 1 && b.date.isSame(currentDay, "date")
                      ) ?? [];

                    return (
                      <TableCell align="right" key={dIndex}>
                        {RenderDay(
                          todaysBookings.slice(
                            0,
                            expandMore.find((d) => d.date === currentDay.format("DD/MM/YYYY"))?.expand ?? false
                              ? undefined
                              : 3
                          ),
                          currentDay
                        )}
                        {renderShowMore(todaysBookings.length, currentDay)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>

      <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
        >
          {selectedBooking && <GetBookingDetails />}
        </Popover>
      </ClickAwayListener>

      {/* CANCELLATION DIALOG WINDOW */}
      {selectedBooking && cancellationPopUpOpen && (
        <CancellationDialog
          childName={children?.find((x) => x.childId === selectedBooking.childId)?.firstName ?? ""}
          minimumDaysToAvoidCancellationFee={selectedBooking.minimumDaysToAvoidCancellationFee ?? 0}
          className={`${styles[getCareTypeColor(selectedBooking.roll?.sessionType ?? "", "Bg")]}`}
          careTypeColor={getCareTypeColor(selectedBooking.roll?.sessionType ?? "")}
          customer_account_id={customer_account_id}
          date={selectedBooking.date}
          handleClose={handleCancellationPopUpClose}
          open={cancellationPopUpOpen}
          serviceId={selectedBooking.roll!.serviceId!}
          rollName={selectedBooking.roll!.rollName!}
          schedule={
            isSchedule
              ? {
                  endDate: selectedBooking.schedule!.effectiveToDate!,
                  startDate: selectedBooking.schedule!.effectiveFromDate!,
                  scheduleId: selectedBooking.schedule!.uniqueId,
                }
              : undefined
          }
          session={
            !isSchedule
              ? {
                  bookingId: selectedBooking.uniqueId,
                  sessionDate: selectedBooking.date,
                }
              : undefined
          }
        />
      )}

      {/* CANCELLATION DIALOG WINDOW END */}
    </Grid>
  );

  // ADDS DUE DATE, OR PUBLIC HOLIDAY NOTE
  function OtherList() {
    return (
      <List className={classes.list}>
        <ListSubheader>Other</ListSubheader>

        {/* DUE DATE NOTE */}
        <ListItem key="pdd" disablePadding>
          <ListItemButton
            role={undefined}
            onClick={() => {
              setShowPaymentDueDate(!showPaymentDueDate);
            }}
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                size="small"
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: getStyleColor("--color-accent-5"),
                  },
                }}
                checked={showPaymentDueDate}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              id={`lbl-pdd`}
              primary={
                <Typography fontSize="13px" className={classes.childName} sx={{ color: "black" }}>
                  Payment Due Date
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>

        {/* PUBLIC HOLIDAY NOTE */}
        <ListItem key="ph" disablePadding>
          <ListItemButton
            role={undefined}
            onClick={() => {
              setShowHolidays(!showHolidays);
            }}
            dense
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#ffc233",
                  },
                }}
                checked={showHolidays}
                size="small"
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              id={`lbl-ph`}
              primary={
                <Typography fontSize="13px" className={classes.childName} sx={{ color: "black" }}>
                  Public Holiday
                </Typography>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }

  function ServicesList() {
    return (
      <List className={classes.list}>
        <ListSubheader>My services</ListSubheader>
        {uniqueCareTypes.map((value) => {
          return (
            <ListItem key={value} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={(e) => {
                  if (unSelectedCareTypes.indexOf(value) === -1) {
                    setUnSelectedCareTypes([...unSelectedCareTypes, value]);
                  } else {
                    setUnSelectedCareTypes(unSelectedCareTypes.filter((x) => x !== value));
                  }
                }}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    size="small"
                    checked={unSelectedCareTypes.indexOf(value) === -1}
                    //
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: getCareTypeColorVariable(value),
                      },
                    }}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  //id={`lbl-${value}`}
                  primary={
                    <Typography fontSize="13px" className={classes.childName} sx={{ color: "black" }}>
                      {value}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}{" "}
      </List>
    );
  }

  function ChildrenList() {
    return (
      <List className={classes.list}>
        <ListSubheader>My children</ListSubheader>
        {children?.slice(0, expandChildren ? children?.length ?? 0 : 5).map((value) => {
          return (
            <ListItem key={value.childId} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={(e) => {
                  if (unSelectedChidlren.indexOf(value.childId!) === -1) {
                    setUnSelectedChidlren([...unSelectedChidlren, value.childId!]);
                  } else {
                    setUnSelectedChidlren(unSelectedChidlren.filter((x) => x !== value.childId!));
                  }
                }}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    size="small"
                    checked={unSelectedChidlren.indexOf(value.childId!) === -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  id={`lbl-${value.childId ?? 1}`}
                  primary={
                    <Typography fontSize="13px" className={classes.childName} sx={{ color: "black" }}>
                      {`${value?.firstName} ${value?.lastName?.substring(0, 1)}`}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  function GetBookingDetails() {
    if (!selectedBooking) return null;
    const careType =
      selectedBooking!.roll?.rollClassificationId === 9 ? "Pfd" : selectedBooking!.roll?.sessionType ?? "";
    const child = children?.find((x) => x.childId === selectedBooking!.childId)!;
    const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
    const isLateCancellation = selectedBooking.cancellationStatusId === 2;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Divider
              orientation="vertical"
              flexItem
              color="primary"
              className={`${
                isLateCancellation ? classes.lateCancellation : styles[getCareTypeColor(careType.toUpperCase(), "Bg")]
              }`}
              sx={{ width: 4, borderRightWidth: 5, alignContent: "center", margin: 1, borderRadius: 5 }}
            />
            <Grid item xs={10}>
              <Grid container>
                <Typography variant="subtitle2">{selectedBooking.roll?.rollName ?? careType}</Typography>
                {/* AVATAR AND CHILD NAME */}
                <Grid container direction="row" alignItems="center">
                  <AvatarDisplay info={avatar || { index: child.avatarIndex }} size={40} />
                  <Box width={5} />
                  <Typography fontSize="15px" className={classes.childName} sx={{ fontWeight: "bold" }}>
                    {`${child.firstName} ${child.lastName?.substring(0, 1)}`}
                  </Typography>
                </Grid>
                <Typography
                  component="span"
                  justifyItems={"center"}
                  alignContent={"center"}
                  variant="caption"
                  color="text.primary"
                >
                  {/* BOOKING DATE */}
                  {selectedBooking.date.format("dddd, DD MMMM YYYY")}{" "}
                  {!selectedBooking.isHolidayClub &&
                    selectedBooking.roll &&
                    `${dayjs(selectedBooking.roll.startTime).format("hh:mm a")} - ${dayjs(
                      selectedBooking.roll.endTime
                    ).format("hh:mm a")}`}
                  {selectedBooking.isHolidayClub &&
                    selectedBooking.program &&
                    selectedBooking.roll &&
                    `${dayjs(selectedBooking.roll.startTime).format("hh:mm a")} - ${dayjs(
                      selectedBooking.roll.endTime
                    ).format("hh:mm a")}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Spacer margin={10} />

        <Grid item xs={12}>
          {selectedBooking.isEditable && isAllowedToBook && (
            <Grid container direction={"row"} justifyContent="space-between" rowGap={1}>
              {/* EDIT REOCCURING */}
              {selectedBooking.isSchedule && (
                <>
                  <ButtonAccent1
                    className={classes.button}
                    sx={{ backgroundColor: getStyleColor("--youroshc-color-primary"), color: "black" }}
                    onClick={(event: any) => {
                      navigate("/edit-booking", {
                        state: JSON.stringify({
                          bookingScheduleId: Number(selectedBooking.scheduleId),
                          childId: selectedBooking.childId,
                          effectiveFromDate: selectedBooking.schedule?.effectiveFromDate,
                          effectiveToDate: selectedBooking.schedule?.effectiveToDate,
                          uniqueId: selectedBooking.schedule?.uniqueId,
                          rollId: selectedBooking.rollId,
                          serviceId: selectedBooking.roll?.serviceId,
                          sessionType: selectedBooking.roll?.sessionType,
                          firstFriday: selectedBooking.schedule?.firstFriday,
                          firstThursday: selectedBooking.schedule?.firstThursday,
                          firstWednesday: selectedBooking.schedule?.firstWednesday,
                          firstTuesday: selectedBooking.schedule?.firstTuesday,
                          firstMonday: selectedBooking.schedule?.firstMonday,
                          originalEffectiveFromDate: selectedBooking.schedule?.effectiveFromDate,
                        } as IEditRecurringBookingState),
                      });
                    }}
                  >
                    Edit Recurring
                  </ButtonAccent1>

                  {/* CANCEL SCHEDULE */}
                  <ButtonWhite
                    className={classes.button}
                    onClick={(event: any) => {
                      setIsSchedule(true);
                      handleCancelSession();
                    }}
                  >
                    Cancel Schedule
                  </ButtonWhite>
                </>
              )}

              {/* CANCEL BUTTON - brings up CANCELATION DIALOG */}
              <ButtonWhite
                className={classes.button}
                onClick={(event: any) => {
                  setIsSchedule(false);
                  handleCancelSession();
                }}
              >
                Cancel Session
              </ButtonWhite>
            </Grid>
          )}
        </Grid>
        <Spacer margin={5} />
        <Grid item xs={12}>
          <List className={classes.list}>
            <ListItem>
              <ListItemIcon>
                <LocationOnOutlinedIcon fontSize="small" sx={{ width: "1rem", height: "1rem" }} />
              </ListItemIcon>
              <ListItemText primary={selectedBooking.roll?.service?.serviceName} />
            </ListItem>

            {isLateCancellation && (
              <ListItem>
                <ListItemIcon>
                  <WarningAmber fontSize="small" htmlColor="black" sx={{ width: "1rem", height: "1rem" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Late Cancellation"}
                  secondary={
                    <Typography variant="body1" fontSize={"12px"} paddingTop={0} marginTop={0}>
                      {selectedBooking.cancelledAt?.format("dddd, DD MMMM YYYY h:mm A")}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <Divider component="li" sx={{ pt: 2 }} />

            <ListItem>
              <ListItemIcon>
                <PaymentOutlinedIcon fontSize="small" sx={{ width: "1rem", height: "1rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <NumericFormat
                    displayType="text"
                    prefix="$"
                    value={
                      isFriendlyFee() || selectedBooking.isSchedule
                        ? selectedBooking.roll?.primaryFeeAmount
                        : selectedBooking.roll?.casualFeeAmount
                    }
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix=""
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" fontSize={"12px"} paddingTop={0} marginTop={0}>
                    * Prices exclude available Child Care Subsidy (CCS)
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );

    function handleCancelSession() {
      dispatch(CancelBookingInitial());

      handleClickAway();

      handleCancellationPopUpOpen();
    }
  }

  function isFriendlyFee() {
    return customerAccount?.isFriendlyFeeEligible === true;
  }
}
