import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import { List } from "linqts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChildBookingCancelCard from "../../components/Bookings/ChildBookingCancelCard";
import ChildBookingEditCard from "../../components/Bookings/ChildBookingEditCard";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import { CardLayout } from "../../layouts/Layouts";
import {
  cancelBooking,
  cancelSchedule,
  getCancellableBooking,
  getSaveRecurringPreview,
  getScheduleCancellableBookings,
  saveRecurringBooking,
} from "../../store/modules/booking/bookingActions";
import { CancelBookingInitial, SaveBookingInitial } from "../../store/modules/booking/bookingStateActions";
import { getScheduleUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { RootState } from "../../store/reducers";
import theme from "../../theme";
import {
  BookingDaysInfo,
  ChangeRecurringBookingPreviewRequest,
  ChangeRecurringBookingRequest,
  InlineBookingEditorModel,
  SessionAvailability,
} from "../../types/models";
import { BookingStatus3, EditModelEnum, IBookingToCancel, IRollInfo, ISessionAvailability } from "../../types/types";
import dateOnlyToJson from "../../utils/dateOnlyToJson";
import { getSelectedDays } from "../../utils/helpers";
import { GetPageState } from "../../utils/pageUtil";

dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(dateOnlyToJson);

export interface EditBookingConfirmState {
  booking: InlineBookingEditorModel;
}

function getMatchingUnAvailableDates(state: EditBookingConfirmState, unAvailableDates: ISessionAvailability[]) {
  var sch = state.booking.booking.schedule!;
  var roll = state.booking.booking.roll!;
  return new List(unAvailableDates)
    .Where(
      (rd) =>
        rd !== undefined &&
        !rd.isAvailableForRecurringBooking &&
        rd.date.isBetween(sch.effectiveFromDate as dayjs.Dayjs, sch.effectiveToDate as dayjs.Dayjs, null, "[]") &&
        roll.rollId === rd.rollId &&
        ((sch.firstMonday && rd.date.isoWeekday() === 1) ||
          (sch.firstTuesday && rd.date.isoWeekday() === 2) ||
          (sch.firstWednesday && rd.date.isoWeekday() === 3) ||
          (sch.firstThursday && rd.date.isoWeekday() === 4) ||
          (sch.firstFriday && rd.date.isoWeekday() === 5))
    )
    .ToArray();
}

function EditBookingConfirm() {
  const dispatch = useDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);
  const previewResult = useSelector((state: RootState) => state.bookingSaveRecurringPreview.previewResult);
  const saveState = useSelector((state: RootState) => state.bookingSaveRecurring);
  const cancelState = useSelector((state: RootState) => state.bookingCancel);
  const [bookingsToCancel, setBookingsToCancel] = useState(new Array<IBookingToCancel>());
  const cancellationReasons = useSelector((state: RootState) => state.bookingCancellationReason.reasons);

  var navigate = useNavigate();
  const [pageState, setPageState] = useState({
    hasConflict: false,
    hasError: false,
    unAvailableDates: new Array<ISessionAvailability>(),
    newlyAddedUnAvailableDates: new Array<ISessionAvailability>(),
  });
  let isSubmitting =
    (saveState !== undefined && saveState.isSaving) || (cancelState !== undefined && cancelState.isSaving);
  var state = GetPageState<EditBookingConfirmState>()!;
  var bkg = state.booking.booking;

  useEffect(() => {
    if (customer_account_id) {
      switch (state.booking.editMode) {
        case EditModelEnum.Cancel:
          getCancellableBooking(customer_account_id, bkg.uniqueId, state.booking.cancellationReasonId).then(
            (result) => {
              if (result) {
                setBookingsToCancel([result]);
              }
            }
          );
          break;
        case EditModelEnum.CancelSchedule:
          getScheduleCancellableBookings(customer_account_id, bkg.scheduleId!, state.booking.cancellationReasonId).then(
            (result) => {
              if (result) {
                setBookingsToCancel(result);
              }
            }
          );
          break;
        case EditModelEnum.ModifySchedule:
          var sch = bkg.schedule!;

          getScheduleUnAvailableSessionDays(
            dispatch,
            customer_account_id as number,
            sch.uniqueId,
            sch.effectiveFromDate as dayjs.Dayjs,
            sch.effectiveToDate as dayjs.Dayjs
          );
          break;
      }
    }
  }, [customer_account_id]);

  useEffect(() => {
    if (unAvailableDates) {
      var unvDates = getMatchingUnAvailableDates(state, unAvailableDates);
      setPageState({
        ...pageState,
        unAvailableDates: unvDates,
      });
      if (bkg.schedule) {
        var sch = bkg.schedule;

        getSaveRecurringPreview(
          dispatch,
          customer_account_id as number,
          bkg.scheduleId as string,
          new ChangeRecurringBookingPreviewRequest(
            bkg.schedule.effectiveFromDate as dayjs.Dayjs,
            bkg.schedule.effectiveToDate as dayjs.Dayjs,
            new BookingDaysInfo(
              bkg.rollId,
              getSelectedDays(
                sch.firstMonday,
                sch.firstTuesday,
                sch.firstWednesday,
                sch.firstThursday,
                sch.firstFriday
              ),
              []
            ),
            []
          )
        );
      }
    }
  }, [unAvailableDates]);

  useEffect(() => {
    if (saveState && saveState.isErrorState) {
      if (saveState.errorResponse && saveState.errorResponse.info) {
        var updatedUnAvailableDates = saveState.errorResponse.info.map((d: any) =>
          SessionAvailability.fromJson(d)
        ) as SessionAvailability[];
        var newUnAvailableDates = new Array<SessionAvailability>();
        for (var sess of updatedUnAvailableDates) {
          // eslint-disable-next-line no-loop-func
          if (pageState.unAvailableDates.findIndex((d) => d.rollId === sess.rollId && d.date.isSame(sess.date)) < 0) {
            newUnAvailableDates.push(sess);
          }
        }
        setPageState({
          ...pageState,
          hasConflict: true,
          unAvailableDates: getMatchingUnAvailableDates(state, updatedUnAvailableDates),
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      } else {
        setPageState({
          ...pageState,
          hasError: true,
        });
      }
    } else if (saveState && saveState.isSuccess) {
      dispatch(SaveBookingInitial());
      dispatch(CancelBookingInitial());
      navigate("oshc-complete");
    }
  }, [saveState]);

  useEffect(() => {
    if (cancelState) {
      if (cancelState.isSuccess) {
        dispatch(SaveBookingInitial());
        dispatch(CancelBookingInitial());
        navigate("/", {
          state: JSON.stringify({
            date: bkg.date,
          }),
        });
      } else if (cancelState.isErrorState) {
        setPageState({
          ...pageState,
          hasError: true,
        });
      }
    }
  }, [cancelState]);

  var canConfirm = false;
  if (state.booking.editMode === EditModelEnum.ModifySchedule) {
    if (previewResult) {
      var hasChanges = previewResult.bookings.findIndex((b) => b.status !== BookingStatus3.Existing) > -1;
      var editBookingsCount = previewResult.bookings.filter(
        (b) => b.status !== BookingStatus3.CancelledInNoticePeriod && b.status !== BookingStatus3.CancelledInTime
      ).length;
      var hasMinBookings = editBookingsCount + previewResult.chargedBookingsCount >= previewResult.minBookingsRequired;
      canConfirm = hasChanges && hasMinBookings;
    }
  } else {
    canConfirm = bookingsToCancel.length > 0;
  }
  const handleClickClose = () => {
    setPageState({
      ...pageState,
      hasConflict: false,
      hasError: false,
    });
  };
  var isHotCancellation = bookingsToCancel.length === 1 && bookingsToCancel[0].isHotCancellation;
  var days = state.booking.minimumDaysToAvoidCancellationFee;

  return (
    <>
      <CardLayout>
        <CardLayout.Header>
          <Grid container>
            <Grid item xs={12}>
              <h1 className="h3" style={{ marginBottom: 0 }}>
                Confirm Change
              </h1>
              {state.booking.booking.date.format("ddd D MMM")}
            </Grid>
          </Grid>
        </CardLayout.Header>
        <CardLayout.Body>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {previewResult && (
                    <ChildBookingEditCard
                      child={state.booking.child}
                      datesUnAvailable={new List(pageState.unAvailableDates)}
                      isSchedule={true}
                      previewResult={previewResult}
                      roll={state.booking.booking.roll as IRollInfo}
                    />
                  )}
                  {bookingsToCancel && bookingsToCancel.length > 0 && (
                    <ChildBookingCancelCard
                      key={"ChildBookingCancelCard_" + state.booking.child.childId}
                      child={state.booking.child}
                      roll={state.booking.booking.roll!}
                      bookings={new List(bookingsToCancel)}
                      cancellationReasonId={state.booking.cancellationReasonId}
                      cancellationReasonOtherText={state.booking.cancellationReasonOtherText}
                      cancellationReasons={cancellationReasons}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isHotCancellation
                    ? "We noticed you only just made this booking, so there will be no charge for the cancellation."
                    : "If a booking is not cancelled with at least " +
                      (days > 2 ? days : days * 24) +
                      " " +
                      (days > 2 ? "days" : "hours") +
                      "’ notice, the full session fee will be charged (less any applicable Child Care Subsidy)."}
                </Grid>
                <Grid item xs={12}>
                  <Box paddingTop={2} />
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <ButtonSecondary
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <strong> Back</strong>
                      </ButtonSecondary>
                    </Grid>
                    <Grid item>
                      <ButtonPrimary
                        disabled={!canConfirm}
                        onClick={() => {
                          setPageState({
                            ...pageState,
                            hasConflict: false,
                            hasError: false,
                            unAvailableDates: pageState.unAvailableDates,
                            newlyAddedUnAvailableDates: [],
                          });
                          if (state.booking.editMode === EditModelEnum.ModifySchedule) {
                            var sch = bkg.schedule!;
                            var req = new ChangeRecurringBookingRequest(
                              sch.effectiveFromDate,
                              sch.effectiveToDate!,
                              new BookingDaysInfo(
                                bkg.rollId,
                                getSelectedDays(
                                  sch.firstMonday,
                                  sch.firstTuesday,
                                  sch.firstWednesday,
                                  sch.firstThursday,
                                  sch.firstFriday
                                ),
                                []
                              ),
                              pageState.unAvailableDates.map((un) => un.date),
                              state.booking.reason ?? undefined
                            );
                            saveRecurringBooking(dispatch, customer_account_id as number, sch.uniqueId, req);
                          } else if (state.booking.editMode === EditModelEnum.CancelSchedule) {
                            cancelSchedule(
                              dispatch,
                              customer_account_id!,
                              bkg.scheduleId!,
                              state.booking.cancellationReasonId,
                              state.booking.cancellationReasonOtherText,
                              bkg.date
                            );
                          } else {
                            cancelBooking(
                              dispatch,
                              customer_account_id!,
                              bkg.uniqueId,
                              state.booking.cancellationReasonId,
                              state.booking.cancellationReasonOtherText,
                              bkg.date
                            );
                          }
                        }}
                      >
                        <strong>Confirm</strong>
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardLayout.Body>
      </CardLayout>
      <AnimatedDialog open={pageState.hasError} title="Sorry!" onClose={handleClickClose}>
        <DialogContent>An error has occurred!</DialogContent>
      </AnimatedDialog>
      <AnimatedDialog open={pageState.hasConflict} title="Sorry!" onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>Some sessions are no longer available:</strong>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {pageState.newlyAddedUnAvailableDates.map((d) => {
              return (
                <Grid item xs={12} key={"roll_" + d.rollId + "_date_" + d.date.unix()}>
                  <strong style={{ color: theme.palette.error.main }}>
                    {d.date.format("DD-MMM-YYYY")} - {bkg.roll?.sessionType}
                  </strong>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <strong style={{ color: "black" }}>Please update your booking!</strong>
            </Grid>
          </Grid>
        </DialogContent>
      </AnimatedDialog>
      <LoadingDialog open={isSubmitting} message="Confirming booking" />
    </>
  );
}

export default EditBookingConfirm;
