import * as Yup from "yup";

const intValidBSBs = ["000000", "111111", "222222", "333333", "444444", "555555", "666666", "888888", "999999"];

const BankAccountSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required("Account Number is required")
    .test("len", "Account Number should be at least 5 characters long", (val) => (val ?? 0).toString().length > 4),
  bsb: Yup.string()
    .test("len", "BSB should be 6 characters long", (val) => val?.toString().length === 6)
    .test("notAccepted1", "Invalid BSB", (val) => intValidBSBs.findIndex((x) => x === val) < 0)
    .required(),
  accountName: Yup.string()
    .required("Account Name is required")
    .min(2, "Minimum of 2 characters")
    .max(60, "Maximum of 60 characters"),
});

export default BankAccountSchema;
