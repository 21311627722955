import BookingEditor from "../../components/Bookings/BookingEditor";
import { LayoutAccent1 } from "../../layouts/Layouts";

export default function BookingPage() {
  return (
    <LayoutAccent1>
      <BookingEditor />
    </LayoutAccent1>
  );
}
