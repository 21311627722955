import { RegistrationStatus } from "../../../types/types";
import {
  RegisterHttpRequest,
  RegisterHttpResponse,
  ResendCodeHttpRequest,
  VerifyEmailHttpRequest,
  VerifyEmailHttpResponse,
} from "../../parentApi";
import { signinRedirect } from "../auth/authServices";
import { sendOtpEmailError, sendOtpEmailSuccess, signupUpdateStatus, signupVerifyError } from "./signupActionTypes";
import apiCustomers from "./signupApi";

export function registerCustomer(
  dispatch: any,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  customerContactId?: number | undefined,
  referralCode?: string | undefined
) {
  const request = new RegisterHttpRequest();
  request.init({ firstName, lastName, email, password, customerContactId, referralCode });
  return apiCustomers.register(request).then(
    (response: RegisterHttpResponse | null) => {
      if (response == null) dispatch(signupUpdateStatus(RegistrationStatus.UnknownError, email));
      else {
        if (response.status === RegistrationStatus.Success) signinRedirect();
        else dispatch(signupUpdateStatus(response.status, email));
      }
    },
    () => dispatch(signupUpdateStatus(RegistrationStatus.UnknownError, email))
  );
}

export function verifyEmail(dispatch: any, email: string, token: string) {
  const request = new VerifyEmailHttpRequest();
  request.init({ email, token });
  return apiCustomers.verifyEmail(request).then(
    (response: VerifyEmailHttpResponse | null) => {
      if (response == null) dispatch(signupUpdateStatus(RegistrationStatus.UnknownError, email));
      else {
        if (response.isValid) dispatch(signinRedirect());
        else dispatch(signupVerifyError());
      }
    },
    () => dispatch(signupVerifyError())
  );
}

export function sendOtpEmail(dispatch: any, email: string) {
  const request = new ResendCodeHttpRequest();
  request.init({ email });
  return apiCustomers.sendOtpEmail(request).then(
    () => dispatch(sendOtpEmailSuccess()),
    () => dispatch(sendOtpEmailError())
  );
}
