import { useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RepeatIcon from "@mui/icons-material/Repeat";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { List as QList } from "linqts";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";

import CancellableChargedBookings from "./Schedule/CancellableChargedBookings";
import CancellableInTimeBookings from "./Schedule/CancellableInTimeBookings";
import EditScheduleExistingBookings from "./Schedule/ExistingBookings";
import EditScheduleNewBookings from "./Schedule/NewBookings";
import { RootState } from "../../store/store";
import { OshcBookingDetails, SelectedRollDate, SelectedRollDay } from "../../types/models";
import { BookingPreview, BookingStatus, BookingStatus3, FeeType, ISessionAvailability } from "../../types/types";

interface IProps {
  formState: OshcBookingDetails;
  isFriendlyFee: boolean;
  availableBookings: QList<BookingPreview>;
  unAvailableDates: ISessionAvailability[];
}
export default function BookingDetails(props: IProps) {
  const previewResult = useSelector((state: RootState) => state.bookingSaveRecurringPreview.previewResult);

  const [expanded, setExpanded] = useState(true);
  const { formState, isFriendlyFee, availableBookings, unAvailableDates } = props;
  const casualDates = new QList<SelectedRollDate>(formState.casualDates);
  const casualDatesByRoll = casualDates.GroupBy((t) => t.roll.rollName ?? "");
  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      sx={{ backgroundColor: "#F4F4F6" }}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary sx={{ backgroundColor: "#F4F4F6" }} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1" sx={{ textDecoration: "underline" }}>
          {expanded ? "Hide booking details" : "Show booking details"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ bgcolor: "#F4F4F6" }}>
        <>
          {!formState.isRecurring && formState.casualDates.length > 0 && casualBookingBreakDown()}

          {formState.isRecurring && formState.scheduleId && showRecurringSummaryEditMode()}

          {formState.isRecurring &&
            formState.scheduleId === undefined &&
            formState.recurringDays.length > 0 &&
            formState.recurringDays.map((roll: SelectedRollDay, index) => {
              const weekDays = roll.Week1Days.map((x) => x.toString().substring(0, 3)).join(", ");
              const bookings = availableBookings.Where(
                (x) => x?.rollId === roll.roll.rollId && x.status === BookingStatus.New.toLowerCase()
              );

              var bookingsGroupedByFee = bookings.GroupBy((b) => b.feeType as FeeType);
              return (
                <Grid container spacing={2} direction="column">
                  <List disablePadding={true}>
                    {Object.keys(bookingsGroupedByFee).map((feeType, index) => {
                      const allAvBookings = bookingsGroupedByFee[feeType];

                      return (
                        <ListItem
                          alignItems="center"
                          key={index}
                          secondaryAction={
                            <span className="small">
                              <NumericFormat
                                displayType="text"
                                prefix="$"
                                value={
                                  (isFriendlyFee || (formState.isRecurring && feeType !== FeeType.Casual)
                                    ? roll.roll.primaryFeeAmount ?? 0
                                    : roll.roll.casualFeeAmount ?? 0) * allAvBookings.length
                                }
                                decimalScale={2}
                                fixedDecimalScale={true}
                                suffix="*"
                              />
                            </span>
                          }
                        >
                          <ListItemAvatar>
                            <RepeatIcon fontSize="small" />
                          </ListItemAvatar>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="subtitle2" fontWeight="bold">
                                {roll.roll.rollName}
                                {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                              </Typography>
                            }
                            secondary={
                              <Stack direction="column">
                                {feeType !== FeeType.Casual && (
                                  <Typography
                                    sx={{
                                      wordBreak: "break-word",
                                      fontSize: {
                                        xl: "1rem",
                                        lg: "0.9rem",
                                        md: "0.9rem",
                                        sm: "0.7rem",
                                        xs: "0.7rem",
                                      },
                                    }}
                                    variant="subtitle2"
                                  >
                                    Occurs every {weekDays}
                                  </Typography>
                                )}

                                <Typography variant="subtitle2">
                                  <span className="small">
                                    <NumericFormat
                                      displayType="text"
                                      prefix={`${allAvBookings.length} x $`}
                                      //value={firstItem?.fee ?? 0}
                                      value={
                                        isFriendlyFee || (formState.isRecurring && feeType !== FeeType.Casual)
                                          ? roll.roll.primaryFeeAmount ?? 0
                                          : roll.roll.casualFeeAmount ?? 0
                                      }
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      suffix="*"
                                    />
                                  </span>
                                </Typography>
                              </Stack>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              );
            })}
        </>
      </AccordionDetails>
    </Accordion>
  );

  function casualBookingBreakDown() {
    return (
      <List disablePadding={true}>
        {Object.keys(casualDatesByRoll).map((rollName, index) => {
          return (
            <ListItem
              disableGutters
              sx={{ padding: 0 }}
              alignItems="center"
              key={index}
              disablePadding={true}
              secondaryAction={
                <span className="small">
                  <NumericFormat
                    displayType="text"
                    prefix="$"
                    value={(casualDatesByRoll[rollName][0].roll?.casualFeeAmount ?? 0) * formState.children.length}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix="*"
                  />
                </span>
              }
            >
              <ListItemAvatar>
                <CalendarTodayIcon fontSize="small" />
              </ListItemAvatar>
              <ListItemText
                primary={rollName}
                secondary={
                  <Typography variant="caption">
                    {formState.children.length * casualDatesByRoll[rollName].length} session(s) x
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        prefix="$"
                        value={casualDatesByRoll[rollName][0].roll.casualFeeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="*"
                      />
                    </span>
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  function showRecurringSummaryEditMode() {
    var allBookings = new QList(previewResult?.bookings ?? []);

    var chargedCancelled = allBookings
      .Where((b) => b !== undefined && b.status === BookingStatus3.CancelledInNoticePeriod)
      .ToList();

    var chargedCancelledByFee = chargedCancelled.GroupBy((b) => b.feeType as FeeType);
    var cancelledInTime = allBookings
      .Where((b) => b !== undefined && (b.status === BookingStatus3.CancelledInTime || b.status === BookingStatus3.CancelledInTimeAsHot))
      .ToList();

    var cancelledInTimeByFee = cancelledInTime.GroupBy((b) => b.feeType as FeeType);

    return (
      <Grid container spacing={2} direction="column" pt={0}>
        {formState.recurringDays.map((roll: SelectedRollDay, index) => {
          const weekDays = roll.Week1Days.map((x) => x.toString().substring(0, 3)).join(", ");

          var rollBookings = allBookings.Where((x) => x !== undefined && x?.rollId === roll.roll.rollId);
          const newBookings = rollBookings
            .Where((x) => x?.rollId === roll.roll.rollId && x.status === BookingStatus3.New)
            .Where(
              (p) =>
                !unAvailableDates.some(
                  (du) =>
                    du?.rollId === p?.rollId &&
                    ((formState.isRecurring && du.isAvailableForRecurringBooking === false) ||
                      (!formState.isRecurring && du.isAvailableForCasualBooking === false)) &&
                    du?.date.isSame(p?.date as dayjs.Dayjs, "date")
                )
            );

          var existingBookings = rollBookings
            .Where((b) => b?.status === BookingStatus3.Existing)
            .Where((b) => b!.date.isSameOrAfter(dayjs(), "date"));

          var existingBookingsByFee = existingBookings.GroupBy((b) => b?.feeType as FeeType);

          var bookingsGroupedByFee = newBookings.GroupBy((b) => b?.feeType as FeeType);

          return (
            <>
              {newBookings.Count() > 0 && (
                <EditScheduleNewBookings
                  bookingsGroupedByFee={bookingsGroupedByFee}
                  formState={formState}
                  isFriendlyFee={isFriendlyFee}
                  roll={roll}
                  weekDays={weekDays}
                />
              )}
              {chargedCancelled.Count() > 0 && (
                <CancellableChargedBookings chargedCancelledByFee={chargedCancelledByFee} />
              )}
              {existingBookings.Count() > 0 && (
                <EditScheduleExistingBookings
                  existingBookings={existingBookingsByFee}
                  isFriendlyFee={isFriendlyFee}
                  roll={roll}
                  weekDays={formState.orginalWeekDays?.map((x) => x.toString().substring(0, 3)).join(", ") ?? ""}
                />
              )}
            </>
          );
        })}
        {/* <Divider variant="middle" /> */}
        {cancelledInTime.Count() > 0 && <CancellableInTimeBookings cancelledInTimeByFee={cancelledInTimeByFee} />}
      </Grid>
    );
  }
}
