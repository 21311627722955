import { createActionCreator } from "deox";

import { IBillingUpcomingCharges } from "../../../types/types";
export const upcomingLoading = createActionCreator("upcomingLoading");
export const upcomingError = createActionCreator("upcomingError");

export const upcomingSuccess = createActionCreator(
  "upcomingSuccess",
  (resolve) => (charges: IBillingUpcomingCharges) => resolve(charges)
);
