import * as Yup from "yup";

import { IBankAccountProps } from "../../components/Payment/BankAccount";
import BankAccountSchema from "../../components/Payment/BankAccountSchema";
import { ICreditCardProps } from "../../components/Payment/CreditCard";
import CreditCardSchema from "../../components/Payment/CreditCardSchema";
export type PaymentMethod = "Default" | "CreditCard" | "BankAccount";
export type PaymentAmountOption = "Current" | "Statment" | "Custom";

export interface IPayNowState {
  amount: number;
  amountOption: PaymentAmountOption;
  card: ICreditCardProps;
  account: IBankAccountProps;
  method: PaymentMethod;
}

const PayNowSchema = Yup.object().shape({
  amount: Yup.number().when("amountOption", {
    is: "Custom",
    then: Yup.number()
      .min(1, "Please enter an amount greater than $1")
      .max(1000000, `Please enter an amount less than $1000000`)
      .required(),
  }),
  account: Yup.object().when("method", {
    is: "BankAccount",
    then: BankAccountSchema,
  }),
  card: Yup.object().when("method", {
    is: "CreditCard",
    then: CreditCardSchema,
  }),
});

export default PayNowSchema;
