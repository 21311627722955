import * as Yup from "yup";

const ChildStep4Schema = Yup.object().shape({
  hasMedicalConditions: Yup.bool().required("Required"),
  medicalConditions: Yup.array().when("hasMedicalConditions", {
    is: (val: boolean) => val === true,
    then: Yup.array().min(1, "You must select at least one medical condition"),
    otherwise: Yup.array().notRequired(),
  }),
});

export default ChildStep4Schema;
