import { useEffect } from "react";

import { List } from "linqts";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "../store/reducers";
import { IVACBookingComplete } from "../types/types";

const GtmEvents = () => {
  let location = useLocation();
  const customerAccountId = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customerAccount = useSelector((state: RootState) => state.customer);
  const isNewUser = useSelector((state: RootState) => state.newUser?.isNewUser);
  const saveState = useSelector((state: RootState) => state.createBooking);

  useEffect(() => {
    if (saveState.isSuccess) {
      var isVac = saveState.data?.isVac;
      if (!isVac) {
        TagManager.dataLayer({
          dataLayer: {
            customerAccountId,
            event: "oshc_booking_success",
          },
          dataLayerName: "dataLayer",
        });
        console.log("GTM Event: OSHC Booking Success");
      } else {
      
        var st = saveState.data as unknown as IVACBookingComplete;
        TagManager.dataLayer({
          dataLayer: {
            event: "holiday_club_checkout",
            customerAccountId: customerAccountId,
            ord: st.reference,
            cost: new List(st.programs).Sum(
              (x) =>
                (customerAccount?.customer?.isFriendlyFeeEligible === true
                  ? x!.session.primaryFeeAmount!
                  : x!.session.casualFeeAmount!) * st.children.length
            ),
            selected_program_names: "'" + st.programs.map((x) => x.programName).join(",") + "'",
            service_id: st.programs[0].serviceId,
            quantity: st.programs.length * st.children.length,
          },
          dataLayerName: "dataLayer",
        });
        console.log("GTM Event: Holiday Club Checkout");
        if (isNewUser) {
          const tagManagerArgs = {
            dataLayer: {
              event: "holiday_club_checkout_first_new",
            },
            dataLayerName: "dataLayer",
          };
          TagManager.dataLayer(tagManagerArgs);
          console.log("GTM Event: Holiday Club Checkout - New User");
        }
      }
    }
  }, [saveState.isSuccess]);
  useEffect(() => {
    switch (location.pathname) {
      case "/registration-complete":
        TagManager.dataLayer({
          dataLayer: {
            event: "pp_register_success",
            customerAccountId,
          },
          dataLayerName: "dataLayer",
        });
        console.log("GTM Event: Registration Complete");
        break;

      default:
        return;
    }
  }, [location]);

  return null;
};

export default GtmEvents;
