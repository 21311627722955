import React, { useEffect } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Alert from "@mui/lab/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import dayjs from "dayjs";
import { Form, Formik, validateYupSchema } from "formik";
import { List } from "linqts";
import { merge } from "lodash";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import styles from "./EditChild.module.scss";
import EditChildSchema from "./EditChildSchema";
import GeneralInformation from "../../components/Children/GeneralInformation";
import Immunisation from "../../components/Children/Immunisation";
import MedicalContactInformation from "../../components/Children/MedicalContactInformation";
import PhysicalBehaviouralAndOtherNeeds from "../../components/Children/PhysicalBehaviouralAndOtherNeeds";
import PriorityAccess from "../../components/Children/PriorityAccess";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import MedicalConditionQuestionnaire from "../../components/Common/Controls/MedicalConditionQuestionnaire";
import ParentAuthorizationControl from "../../components/Common/Controls/ParentAuthorizationControl";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import NswVoucherRedeem from "../../components/NswVoucher/NswVoucherRedeem";
import { LayoutAccent1 } from "../../layouts/Layouts";
import * as actions from "../../store/modules/child/childActions";
import { childReset } from "../../store/modules/child/childStateActions";
import { removeTemporaryAvatars } from "../../store/modules/customer/customerActionTypes";
import { DocumentInit } from "../../store/modules/document/documentStateActions";
import { voucherInit } from "../../store/modules/nswVoucher/nswVoucherStateActions";
import { getServices } from "../../store/modules/service/servicesActions";
import { RootState } from "../../store/store";
import theme from "../../theme";
import { Child, EntityType, MedicalPlanAnswer } from "../../types/models";
import { ConditionTypes, DocumentTypes, IAnswer } from "../../types/types";
import { Avatars, getStyleColor } from "../../utils/helpers";
import {
  isValidMedicalPlanAnswers,
  OtherMedicalConditionQuestionId,
  PhysicalConditionId,
} from "../../utils/medicalConditionsHelper";
import { isNullOrEmpty } from "../../utils/stringUtils";

const MuiAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    paddingLeft: theme.spacing(3),
    paddignRight: theme.spacing(3),
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(AccordionDetails);

const useStyles = makeStyles()((theme) => {
  return {
    accordion: {
      width: "100%",
      marginBottom: "0 !important",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formLegend: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: theme.spacing(4),
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
    formLabel: {
      marginBottom: theme.spacing(2),
    },
    radio: {
      padding: 0,
      paddingTop: "3px",
      paddingRight: theme.spacing(2),
    },
    heading: {
      marginTop: 0,
      marginBottom: 0,
    },
    subHeading: {
      fontSize: "16px",
      color: getStyleColor("--custom-color-dark-grey"),
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    selectEmpty: {
      marginBottom: theme.spacing(2),
    },
    textField: { marginBottom: theme.spacing(2) },
    container: { paddingLeft: 0 },
    primary: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      borderColor: "black",
      borderWidth: 2,
      backgroundColor: getStyleColor("--color-accent-1"),
      borderRadius: 30,
      height: 50,
      textTransform: "none",
    },
    icon: {
      width: 128,
      height: 128,
    },
    errorIcon: {
      color: theme.palette.error.main,
      paddingLeft: "5px",
      width: 20,
      height: 20,
    },
    voucherLink: {
      color: "#2754b3",
    },
  };
});

function EditChild() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams();
  const newChildId = -1;
  const childId = id ? parseInt(id) : newChildId;

  const { isChildUpdated, isUpdateError } = useSelector((state: RootState) => state.child);
  const childrenByCustomer = useSelector((state: RootState) => state.child.childrenByCustomer);
  const customerAccountId = useSelector((state: RootState) => state.customer.customer?.customerAccountId);
  const primaryContact = useSelector((state: RootState) =>
    state.customer.customer?.contacts?.find((c) => c.isPrimary === true)
  );
  const childDocuments = useSelector((state: RootState) =>
    state.customer.customer?.documents?.filter((document) => document.childId === childId)
  );

  const tempDocuments = useSelector((state: RootState) => state.customer.tempDocuments);

  const planTemplate = useSelector((state: RootState) => state?.commonData?.data.medicalPlanTemplate);
  const contacts = useSelector((state: RootState) => state.customer.customer?.contacts);
  const avatars = useSelector((state: RootState) => state.avatars?.avatars);
  const [tempValuesCleared, setTempValuesCleared] = React.useState(false);
  const [initialValuesConfigured, setInitialValuesConfigured] = React.useState(false);
  const [selectedMedicalConditions, setSelectedMedicalConditions] = React.useState<List<number>>(new List<number>());
  const [medicalPlanAnswers, setMedicalPlanAnswers] = React.useState<List<MedicalPlanAnswer>>(
    new List<MedicalPlanAnswer>()
  );
  const [firstChildHasMedicalContact, setFirstChildHasMedicalContact] = React.useState(false);
  const [showRedeemDialog, setShowRedeemDialog] = React.useState(false);
  const [showAuthorization, setShowAuthorization] = React.useState(false);
  useEffect(() => {
    dispatch(voucherInit());
  }, [showRedeemDialog]);

  useEffect(() => {
    if (!tempValuesCleared) {
      dispatch(childReset());
      dispatch(DocumentInit());
      dispatch(removeTemporaryAvatars());
      setTempValuesCleared(true);
    }
  }, [tempValuesCleared]);

  useEffect(() => {
    if (isChildUpdated) {
      setTimeout(() => {
        dispatch(childReset());
        if (customerAccountId) {
          getServices(dispatch, customerAccountId);
        }
        navigate("/account-home");
      }, 3000);
    }
  }, [isChildUpdated]);

  const child: Child = childrenByCustomer?.find((c) => c.childId === childId) ?? new Child({ childId: childId });

  const entityType = EntityType.Child;
  const entityId = child?.childId?.toString() ?? 0;
  const firstChild = childrenByCustomer?.indexOf(child) !== 0 ? childrenByCustomer?.[0] : null;

  if (child.childId === newChildId) {
    const avatarsInUse = childrenByCustomer
      ?.map((c) => c.avatarIndex ?? -1)
      .concat(contacts?.map((c) => c.avatarIndex ?? -1) ?? []);

    const availableAvatarIndex = Avatars.getFirstAvailable(avatarsInUse ?? []);
    child.avatarIndex = availableAvatarIndex;
  } else {
    if (childrenByCustomer?.find((c) => c.childId === childId) == null) {
      navigate("/account-home");
    }
  }

  const courtOrderDocuments = childDocuments?.filter(
    (document) => document.documentTypeId === DocumentTypes.CourtOrders
  );

  const immunisationDocuments = childDocuments?.filter(
    (document) => document.documentTypeId === DocumentTypes.ImmunizationRecords
  );

  const medicalConditions = planTemplate?.conditions;
  const otherMedicalConditionsIds =
    planTemplate?.conditions
      .filter((x) => x.conditionTypeId === ConditionTypes.Other)
      .sort((a, b) => a.conditionId - b.conditionId)
      .map((x) => x.conditionId) ?? [];
  const firstOtherMedicalCondition = otherMedicalConditionsIds[0];
  const allQuestions = planTemplate?.questions;

  const childAvatar = avatars.find((x) => x.entityType === entityType && x.entityId === entityId);

  if (!initialValuesConfigured) {
    if (primaryContact) {
      if (primaryContact.residentialAddress != null && child.residentialAddress != null) {
        child.sameAddressDetailsAsPrimary =
          primaryContact.residentialAddress.streetLine1 === child.residentialAddress.streetLine1 &&
          primaryContact.residentialAddress.suburb === child.residentialAddress.suburb &&
          primaryContact.residentialAddress.postCode === child.residentialAddress.postCode &&
          primaryContact.residentialAddress.state === child.residentialAddress.state;
      }

      child.sameCulturalDetailsAsPrimary =
        primaryContact.mainLanguage === child.mainLanguage &&
        primaryContact.culturalBackground === child.culturalBackground &&
        (primaryContact.culturalNeeds ?? "") === child.culturalNeeds;

      if (firstChild !== null && firstChild !== undefined && firstChild.childId !== child.childId) {
        child.sameMedicalContactAsFirstChild =
          firstChild.medicalServiceName === child.medicalServiceName &&
          firstChild.doctorName === child.doctorName &&
          firstChild.doctorPhone === child.doctorPhone &&
          firstChild.doctorAddress === child.doctorAddress;

        child.sameSchoolDetailsAsFirstChild = firstChild.schoolId === child.schoolId;

        setFirstChildHasMedicalContact(
          !isNullOrEmpty(firstChild.medicalServiceName) &&
            !isNullOrEmpty(firstChild.doctorName) &&
            !isNullOrEmpty(firstChild.doctorPhone) &&
            !isNullOrEmpty(firstChild.doctorAddress)
        );
      }
    }

    setSelectedMedicalConditions(
      new List(child.answers)
        .Select((x) => x.conditionId)
        .Distinct()
        .ToList()
    );

    var mappedAnswers = new List(child.answers).Select((ans) => {
      var q = new List(allQuestions).FirstOrDefault((x) => x?.questionId === ans.questionId);
      return new MedicalPlanAnswer({
        ...ans,
        conditionId: ans.conditionId,
        isFreeTextRequired: (q?.allowFreeText && q?.freeTextRequired) ?? false,
        isRequired: q?.required ?? false,
      });
    });

    setMedicalPlanAnswers(mappedAnswers);

    setInitialValuesConfigured(true);
  }

  const [initialValues] = React.useState(
    merge(
      {
        firstName: "",
        lastName: "",
        avatarIndex: childAvatar ? childAvatar.index : null,
        culturalBackground: "",
        culturalNeeds: "",
        mainLanguage: "",
        genderId: "",
        otherGender: "",
        schoolId: -1,
        schoolName: "",
        birthday: dayjs.Dayjs,
        sameAddressDetailsAsPrimary: false,
        sameCulturalDetailsAsPrimary: false,
        sameSchoolDetailsAsFirstChild: false,
        sameMedicalContactAsFirstChild: false,
        residentialAddress: { uniqueId: uuid(), streetLine1: "", suburb: "", state: "", postCode: "" },
        hasBeenImmunised: undefined,
        hasMedicalConditions: true,
        hasPhysicalNeeds: undefined,
        hasBehaviouralNeeds: undefined,
        hasSpecialNeeds: undefined,
        hasDietaryRequirements: undefined,
        physicalNeeds: "",
        behaviouralNeeds: "",
        specialNeeds: "",
        dietaryRequirements: "",
        medicalServiceName: "",
        doctorName: "",
        doctorPhone: "",
        addressUniqueId: uuid(),
        doctorAddress: "",
        doctorSuburb: "",
        doctorPostcode: "",
        doctorState: "",
        medicareNumber: "",
        priorityAccessId: undefined,
        authorizationSignature: null,
        childSituations: [],
        firstChildHasMedicalContact: firstChildHasMedicalContact,
        medicalPlanAnswers:
          child.answers !== undefined
            ? new List(child.answers)
                .Where((ans) => ans?.conditionId === PhysicalConditionId)
                .Select((ans) => {
                  return {
                    questionId: ans.questionId,
                    conditionId: ans.conditionId,
                    multipleAnswerIndex: ans.multipleAnswerIndex,
                    value: ans.value,
                    freeText: ans.freeText,
                  };
                })
                .ToArray()
            : [],
        selectedMedicalConditions:
          medicalPlanAnswers !== undefined ? medicalPlanAnswers.Select((x) => x.conditionId).Distinct() : [],
        medicalAnswersValid: !medicalPlanAnswers.Any(
          (an) => an !== undefined && an.conditionId < firstOtherMedicalCondition && !an.isValid
        ),
      },
      child,
      {
        entityType: entityType,
        entityId: entityId,
      }
    )
  );

  initialValues.firstChildHasMedicalContact = firstChildHasMedicalContact;

  var firstNameReadOnly = !isNullOrEmpty(initialValues.firstName);
  var lastNameReadOnly = !isNullOrEmpty(initialValues.lastName);
  var birthdayReadOnly = initialValues.birthday !== undefined;

  function updateChild(values: Child, authorizationSignature: string | null, errorCallback: () => void) {
    let documentIdsAdded = tempDocuments
      ?.filter((document) => document.childId === childId && document.created)
      .map((x) => x.uniqueId ?? "");

    let documentIdsDeleted = tempDocuments
      ?.filter((document) => document.childId === childId && document.deleted)
      .map((x) => x.uniqueId ?? "");

    var mappedAnswers = medicalPlanAnswers.Select((ans) => {
      var currentAnswer = new List(child.answers).FirstOrDefault(
        (x) =>
          x?.questionId === ans.questionId &&
          x.conditionId === ans.conditionId &&
          x.multipleAnswerIndex === ans.multipleAnswerIndex
      );
      var uniqueId = currentAnswer?.uniqueId;
      return {
        ...ans,
        conditionId: ans.conditionId,
        uniqueId: uniqueId,
        childId: values.childId,
      };
    });

    values.answers = mappedAnswers.ToArray();

    // Add Physical answers
    if (values.medicalPlanAnswers && values.medicalPlanAnswers.length > 0 && values.answers !== undefined) {
      new List(values.medicalPlanAnswers)
        .Where((ans) => ans?.conditionId === PhysicalConditionId)
        .ForEach((ans) => {
          if (ans !== undefined) {
            var currentAnswer = new List(values.answers).FirstOrDefault(
              (x) =>
                x?.questionId === ans.questionId &&
                x.conditionId === ans.conditionId &&
                x.multipleAnswerIndex === ans.multipleAnswerIndex
            );
            if (currentAnswer !== undefined) {
              currentAnswer.value = ans.value;
              currentAnswer.freeText = ans.freeText;
            } else {
              values.answers?.push({
                uniqueId: undefined,
                childId: values.childId,
                questionId: ans.questionId,
                conditionId: ans.conditionId,
                multipleAnswerIndex: ans.multipleAnswerIndex,
                value: ans.value,
                freeText: ans.freeText,
              });
            }
          }
        });
    }

    var documentsToRemove = childDocuments
      ?.filter(
        (x) => x.medicalPlanConditionId !== undefined && !selectedMedicalConditions.Contains(x.medicalPlanConditionId)
      )
      .map((x) => x.uniqueId ?? "");
    if (documentsToRemove !== undefined && documentsToRemove?.length > 0) {
      documentIdsDeleted = documentIdsDeleted.concat(documentsToRemove);
    }

    if (childId > 0) {
      actions.updateChild(
        dispatch,
        customerAccountId!,
        childId,
        values,
        documentIdsAdded,
        documentIdsDeleted,
        authorizationSignature,
        errorCallback
      );
    } else {
      actions.createChild(
        dispatch,
        customerAccountId!,
        values,
        documentIdsAdded,
        documentIdsDeleted,
        authorizationSignature!,
        errorCallback
      );
    }
  }

  return (
    <LayoutAccent1>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={EditChildSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(true);
            updateChild(values, values.authorizationSignature, () => setSubmitting(false));
          }, 500);
        }}
      >
        {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => {
          const generalInformationError =
            (touched.firstName && Boolean(errors.firstName)) ||
            (touched.lastName && Boolean(errors.lastName)) ||
            (touched.birthday && Boolean(errors.birthday)) ||
            (touched.genderId && Boolean(errors.genderId)) ||
            (touched.schoolId && Boolean(errors.schoolId)) ||
            (touched.residentialAddress?.streetLine1 && Boolean(errors.residentialAddress?.streetLine1)) ||
            (touched.residentialAddress?.suburb && Boolean(errors.residentialAddress?.suburb)) ||
            (touched.residentialAddress?.postCode && Boolean(errors.residentialAddress?.postCode)) ||
            (touched.residentialAddress?.state && Boolean(errors.residentialAddress?.state)) ||
            (touched.mainLanguage && Boolean(errors.mainLanguage)) ||
            (touched.culturalBackground && Boolean(errors.culturalBackground));

          const physicalBehaviouralError =
            (touched.hasPhysicalNeeds && Boolean(errors.hasPhysicalNeeds)) ||
            (touched.hasBehaviouralNeeds && Boolean(errors.hasBehaviouralNeeds)) ||
            (touched.hasSpecialNeeds && Boolean(errors.hasSpecialNeeds)) ||
            (touched.hasDietaryRequirements && Boolean(errors.hasDietaryRequirements)) ||
            (touched.physicalNeeds && Boolean(errors.physicalNeeds)) ||
            (touched.behaviouralNeeds && Boolean(errors.behaviouralNeeds)) ||
            (touched.specialNeeds && Boolean(errors.specialNeeds)) ||
            (touched.dietaryRequirements && Boolean(errors.dietaryRequirements));

          const medicalContactError =
            (touched.medicalServiceName && Boolean(errors.medicalServiceName)) ||
            (touched.doctorName && Boolean(errors.doctorName)) ||
            (touched.doctorPhone && Boolean(errors.doctorPhone)) ||
            (touched.doctorAddress && Boolean(errors.doctorAddress)) ||
            (touched.medicareNumber && Boolean(errors.medicareNumber));
          return (
            <Form>
              <div className={styles.EditChild}>
                <div className={styles["EditChild-Card"]}>
                  <h1 className={`h2`} style={{ marginTop: 0, marginBottom: 0 }}>
                    {id === undefined ? "Add Child" : "Edit Child"}
                  </h1>
                  <h2 className={`h5 light`} style={{ marginTop: 0, marginBottom: theme.spacing(3) }}>
                    {child.firstName} {child.lastName}{" "}
                    {child.residentialAddress?.state?.toLowerCase() === "nsw" && (
                      <Link
                        to="#"
                        onClick={(event) => {
                          event.preventDefault();
                          setShowRedeemDialog(true);
                        }}
                        className={classes.voucherLink}
                      >
                        <span>Redeem NSW Voucher</span>
                      </Link>
                    )}
                  </h2>
                  {showRedeemDialog && (
                    <NswVoucherRedeem
                      show={showRedeemDialog}
                      customerAccountId={customerAccountId !== undefined ? customerAccountId : 0}
                      childId={childId}
                      onClose={function () {
                        setShowRedeemDialog(false);
                      }}
                    />
                  )}
                  <MuiAccordion defaultExpanded className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Account Details</h3>
                        {getAccordionSummaryError(generalInformationError)}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <GeneralInformation
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        firstChild={firstChild}
                        primaryContact={primaryContact}
                        courtOrderDocuments={courtOrderDocuments}
                        dispatch={dispatch}
                        firstNameReadOnly={firstNameReadOnly}
                        lastNameReadOnly={lastNameReadOnly}
                        birthdayReadOnly={birthdayReadOnly}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <MuiAccordion className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Immunisation</h3>
                        {getAccordionSummaryError(touched.hasBeenImmunised && Boolean(errors.hasBeenImmunised))}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <Immunisation
                        classes={classes}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        immunisationDocuments={immunisationDocuments}
                        customerAccountId={customerAccountId}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <MuiAccordion className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Medical Conditions</h3>
                        {getAccordionSummaryError(Boolean(errors.medicalAnswersValid))}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <Grid container direction="row">
                        {medicalConditions &&
                          medicalConditions.length > 0 &&
                          medicalConditions.map((medicalCondition, index) => {
                            const mdId = medicalCondition.conditionId;
                            const checked =
                              mdId < firstOtherMedicalCondition
                                ? selectedMedicalConditions.Contains(medicalCondition.conditionId)
                                : selectedMedicalConditions.Intersect(new List(otherMedicalConditionsIds)).Count() > 0;
                            var hasOtherMedicalConditionAnswer =
                              medicalPlanAnswers?.FirstOrDefault(
                                (x) =>
                                  x !== undefined &&
                                  x.conditionId === mdId &&
                                  x.questionId === OtherMedicalConditionQuestionId
                              ) !== undefined;
                            var previousOtherMedicalConditionAnswer = medicalPlanAnswers?.FirstOrDefault(
                              (x) =>
                                x !== undefined &&
                                x.conditionId === mdId - 1 &&
                                x.questionId === OtherMedicalConditionQuestionId
                            );
                            return (
                              medicalCondition && (
                                <Grid item xs={12} key={index}>
                                  {medicalCondition.conditionId <= firstOtherMedicalCondition && (
                                    <Grid container>
                                      <FormControlLabel
                                        key={medicalCondition.conditionId}
                                        control={
                                          <Checkbox
                                            name={medicalCondition.name}
                                            key={medicalCondition.conditionId}
                                            value={medicalCondition.conditionId}
                                            checked={checked}
                                            onChange={(e) => {
                                              const checked = e.target.checked;
                                              let selected;

                                              const medicalConditionId = parseInt(e.target.value);
                                              selected = selectedMedicalConditions ?? [];

                                              if (checked) {
                                                if (!selected.Contains(medicalConditionId)) {
                                                  selected.Add(medicalConditionId);
                                                }
                                              } else {
                                                selected = selected.Where((x) => x !== medicalConditionId);

                                                let childAnswers =
                                                  medicalPlanAnswers?.Where(
                                                    (ans) => ans?.conditionId !== medicalConditionId
                                                  ) ?? [];

                                                if (otherMedicalConditionsIds.includes(medicalConditionId)) {
                                                  selected = selected.Where(
                                                    (x) => !otherMedicalConditionsIds.includes(medicalConditionId)
                                                  );

                                                  childAnswers =
                                                    medicalPlanAnswers?.Where(
                                                      (ans) =>
                                                        ans !== undefined &&
                                                        !otherMedicalConditionsIds.includes(ans?.conditionId)
                                                    ) ?? [];
                                                }

                                                setMedicalPlanAnswers(childAnswers);
                                                setFieldValue(
                                                  "medicalAnswersValid",
                                                  isValidMedicalPlanAnswers(allQuestions!, childAnswers)
                                                );
                                              }

                                              setFieldValue("selectedMedicalConditions", selected.ToList());
                                              setSelectedMedicalConditions(selected.ToList());
                                            }}
                                          />
                                        }
                                        label={medicalCondition.name}
                                      />
                                    </Grid>
                                  )}
                                  {checked &&
                                    (mdId === firstOtherMedicalCondition ||
                                      (otherMedicalConditionsIds.includes(mdId) &&
                                        previousOtherMedicalConditionAnswer !== undefined &&
                                        previousOtherMedicalConditionAnswer.value !== undefined &&
                                        previousOtherMedicalConditionAnswer.value.length > 2)) && (
                                      <Box marginTop={1} marginBottom={1}>
                                        <TextField
                                          fullWidth
                                          type="text"
                                          placeholder="Name of Condition"
                                          value={
                                            medicalPlanAnswers?.FirstOrDefault(
                                              (p) =>
                                                p !== undefined &&
                                                p.conditionId === mdId &&
                                                p.questionId === OtherMedicalConditionQuestionId &&
                                                p.multipleAnswerIndex === 0
                                            )?.value
                                          }
                                          onChange={(evt) => {
                                            var text = evt.target.value;

                                            if (text && text.trim().length > 2) {
                                              var childAnswers = medicalPlanAnswers.RemoveAll(
                                                (x) =>
                                                  x !== undefined &&
                                                  x.conditionId === mdId &&
                                                  x.questionId === OtherMedicalConditionQuestionId &&
                                                  x.multipleAnswerIndex === 0
                                              );
                                              var otherMedicalConditionAnswer = new MedicalPlanAnswer({
                                                value: text,
                                                freeText: "",
                                                conditionId: mdId,
                                                questionId: OtherMedicalConditionQuestionId,
                                                multipleAnswerIndex: 0,
                                                isRequired: true,
                                                isFreeTextRequired: false,
                                              });
                                              childAnswers.Add(otherMedicalConditionAnswer);
                                            } else {
                                              childAnswers = medicalPlanAnswers.RemoveAll(
                                                (x) => x !== undefined && x.conditionId === mdId
                                              );
                                            }

                                            setMedicalPlanAnswers(childAnswers);
                                            setFieldValue(
                                              "medicalAnswersValid",
                                              isValidMedicalPlanAnswers(allQuestions!, childAnswers)
                                            );
                                          }}
                                        />
                                      </Box>
                                    )}
                                  {((checked && mdId < firstOtherMedicalCondition) ||
                                    (checked &&
                                      otherMedicalConditionsIds.includes(mdId) &&
                                      hasOtherMedicalConditionAnswer)) && (
                                    <MedicalConditionQuestionnaire
                                      allQuestions={new List(allQuestions).Where((x) => x?.visible ?? true)}
                                      childAnswers={medicalPlanAnswers.Where((md) => md?.conditionId === mdId)}
                                      childId={values.childId}
                                      customerAccountId={customerAccountId}
                                      documents={childDocuments?.filter((d) => d.childId === values.childId) ?? []}
                                      medicalConditionId={mdId}
                                      onAnswersChanged={(answers) => {
                                        const childAnswers =
                                          medicalPlanAnswers?.Where(
                                            (ans) =>
                                              ans?.conditionId !== mdId ||
                                              ans.questionId === OtherMedicalConditionQuestionId
                                          ) ?? [];
                                        childAnswers.AddRange(answers.ToArray());
                                        setMedicalPlanAnswers(childAnswers);
                                        setFieldValue(
                                          "medicalAnswersValid",
                                          isValidMedicalPlanAnswers(allQuestions!, childAnswers)
                                        );
                                      }}
                                    />
                                  )}
                                </Grid>
                              )
                            );
                          })}
                      </Grid>
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <MuiAccordion className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Physical, Behavioural and Other Needs</h3>
                        {getAccordionSummaryError(physicalBehaviouralError)}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <PhysicalBehaviouralAndOtherNeeds
                        classes={classes}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        dispatch={dispatch}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <MuiAccordion className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Medical Contact Information </h3>
                        {getAccordionSummaryError(medicalContactError)}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <MedicalContactInformation
                        classes={classes}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        firstChild={firstChild}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <MuiAccordion className={classes.accordion}>
                    <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                      <Grid container alignItems="center">
                        <h3 className={`regular h5`}>Priority of Access</h3>
                        {getAccordionSummaryError(touched.priorityAccessId && Boolean(errors.priorityAccessId))}
                      </Grid>
                    </MuiAccordionSummary>
                    <MuiAccordionDetails>
                      <PriorityAccess
                        classes={classes}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        dispatch={dispatch}
                      />
                    </MuiAccordionDetails>
                  </MuiAccordion>
                  <ButtonPrimary
                    disabled={isSubmitting}
                    onClick={async () => {
                      if (!doesRequireAuthorization(values)) {
                        submitForm();
                      } else {
                        try {
                          await validateYupSchema(values, EditChildSchema, false);
                          setShowAuthorization(true);
                        } catch (ex) {
                          submitForm();
                        }
                      }
                    }}
                  >
                    <strong>Update</strong>
                  </ButtonPrimary>
                  {isUpdateError && (
                    <Alert severity="error">{`An error occurred while trying ${
                      childId > 0 ? "update" : "create"
                    } child. Please try again later`}</Alert>
                  )}
                </div>
              </div>
              <Backdrop className={classes.backdrop} open={isSubmitting}>
                <CircularProgress color="primary" />
              </Backdrop>
              <AnimatedDialog open={showAuthorization} maxWidth="sm" onClose={() => setShowAuthorization(false)}>
                <ParentAuthorizationControl
                  onSigned={(signature) => {
                    setFieldValue("authorizationSignature", signature);
                    submitForm();
                    setShowAuthorization(false);
                  }}
                />
              </AnimatedDialog>
            </Form>
          );
        }}
      </Formik>
    </LayoutAccent1>
  );

  function getAccordionSummaryError(hasErrors: boolean | undefined): React.ReactNode {
    return hasErrors ? <ErrorOutlineIcon className={classes.errorIcon} /> : "";
  }

  function doesRequireAuthorization(values: Child) {
    let documentIdsAdded = tempDocuments
      ?.filter((document) => document.childId === childId && document.created)
      .map((x) => x.uniqueId ?? "");

    let documentIdsDeleted = tempDocuments
      ?.filter((document) => document.childId === childId && document.deleted)
      .map((x) => x.uniqueId ?? "");

    return (
      values.hasBeenImmunised !== initialValues.hasBeenImmunised ||
      documentIdsAdded.length > 0 ||
      documentIdsDeleted.length > 0 ||
      values.hasBehaviouralNeeds !== initialValues.hasBehaviouralNeeds ||
      values.hasDietaryRequirements !== initialValues.hasDietaryRequirements ||
      values.hasMedicalConditions !== initialValues.hasMedicalConditions ||
      values.hasPhysicalNeeds !== initialValues.hasPhysicalNeeds ||
      values.hasSpecialNeeds !== initialValues.hasSpecialNeeds ||
      areAnswersSame(initialValues.answers ?? [], values.answers ?? [])
    );
  }
}

function areAnswersSame(initial: IAnswer[], current: IAnswer[]) {
  if (initial.length !== current.length) return false;

  for (var i = 0; i < initial.length; i++) {
    var val = initial[i];
    var index = current.findIndex(
      // eslint-disable-next-line no-loop-func
      (a) =>
        a.childId === val.childId &&
        a.conditionId === val.conditionId &&
        a.questionId === val.questionId &&
        a.multipleAnswerIndex === val.multipleAnswerIndex &&
        a.value === val.value
    );

    if (index < 0) {
      return false;
    }
  }

  return true;
}
export default EditChild;
