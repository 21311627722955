import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { makeStyles } from "tss-react/mui";

import DownloadStatement from "./DownloadStatement";
import RaiseQuery from "./RaiseQuery";
import Transactions from "./Transactions";
import { downloadStatement } from "../../store/modules/statementReport/statementReportActions";
import theme from "../../theme";
import { StatementTransaction } from "../../types/types";
import { formatCurrency, getStyleColor } from "../../utils/helpers";
import { ButtonPrimary } from "../Common/Buttons/Buttons";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 2,
  },
  control: {
    padding: theme.spacing(4),
  },
  accordion: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    border: 0,
    borderRadius: 3,
    marginBottom: theme.spacing(1),
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    alignItems: "flex-start",
  },
  chip: {
    borderRadius: 3,
    border: 0,
  },
}));

export interface BillingCycleProps {
  totalNumberOfBookings: number;
  billingPeriodEnd: Dayjs;
  billingCycleStatus: string;
  statusColor: string;
  totalBookingsAmount: number;
  totalCCSAndACCSAmount: number;
  totalOtherFees: number;
  totalAmount: number;
  transactions: StatementTransaction[];
  billingCycleId: number;
  customerAccountId: number;
}

const BillingCycle = (props: BillingCycleProps) => {
  const [showQuery, setShowQuery] = React.useState(false);
  const dispatch = useDispatch();

  const handleQueryClicked = () => {
    setShowQuery((prev) => !prev);
  };

  const handleDownloadClicked = () => {
    downloadStatement(dispatch, props.customerAccountId, props.billingCycleId);
  };

  const { classes } = useStyles();

  return (
    <Accordion className={classes.accordion} key={props.billingCycleId} elevation={0}>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<IoChevronDownOutline />}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography style={{ color: theme.palette.text.primary }}>
              {dayjs(props.billingPeriodEnd).format("D MMM YYYY")}
            </Typography>
          </Grid>
          {props.billingCycleStatus && (
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Chip
                label={props.billingCycleStatus}
                className={classes.chip}
                style={{ backgroundColor: props.statusColor }}
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={4} md={3}>
            {formatCurrency(props.totalBookingsAmount)}
            <br />
            <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>BOOKINGS</span>
          </Grid>
          <Grid item xs={4} md={3}>
            {formatCurrency(props.totalCCSAndACCSAmount)}
            <br />
            <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>CCS + ACCS</span>
          </Grid>
          <Grid item xs={4} md={3}>
            {formatCurrency(props.totalOtherFees)}
            <br />
            <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>OTHER</span>
          </Grid>
          <Grid item xs={12} md={3}>
            <>
              <strong>{formatCurrency(props.totalAmount)}</strong>
              <br />
              <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>TOTAL</span>
            </>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <DownloadStatement
              onDownload={handleDownloadClicked}
              key={props.billingCycleId}
              customerAccountId={props.customerAccountId}
              billingCycleId={props.billingCycleId}
            />
            <Collapse in={!showQuery}>
              <ButtonPrimary hidden={showQuery} onClick={handleQueryClicked} fullWidth style={{ marginTop: 0 }}>
                <strong> Raise Query</strong>
              </ButtonPrimary>
            </Collapse>
            <Box paddingBottom={3}>
              <RaiseQuery
                billingId={props.billingCycleId}
                showForm={showQuery}
                transactions={props.transactions}
                handleDismiss={handleQueryClicked}
              />
            </Box>
          </Grid>
          <Transactions transactions={props.transactions}></Transactions>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default BillingCycle;
