import { createReducer } from "deox";

import {
  LoadingSaveRecurringPreview,
  LoadSaveRecurringPreview,
  LoadSaveRecurringPreviewError,
  LoadSaveRecurringPreviewInitial,
} from "./bookingStateActions";
import { IGetChangeRecurringBookingPreviewResponse } from "../../../types/types";

export interface BookingPreviewState {
  previewResult: IGetChangeRecurringBookingPreviewResponse | null;
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
}

export const initialState: BookingPreviewState = {
  previewResult: null,
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
};

const bookingSaveRecurringPreviewReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadSaveRecurringPreview, (state, { payload }) => ({
    ...state,
    previewResult: payload,
    isErrorState: false,
    isInitialised: true,
    isLoading: false,
  })),
  handleAction(LoadSaveRecurringPreviewError, (state) => ({
    ...state,
    previewResult: null,
    isErrorState: true,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadSaveRecurringPreviewInitial, (state) => ({
    ...state,
    previewResult: null,
    isErrorState: false,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadingSaveRecurringPreview, (state) => ({
    ...state,
    //previewResult: null,
    isErrorState: false,
    isInitialised: false,
    isLoading: true,
  })),
]);

export default bookingSaveRecurringPreviewReducer;
