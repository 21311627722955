import { createReducer } from "deox";

import { appStatusLoading, appStatusLoaded, appStatusReset } from "./appStatusStateActions";
import { AppStatusHttpResponse } from "../../parentApi";
import { purge } from "../purge/purgeStateActions";

export interface StatusState {
  appStatus: AppStatusHttpResponse | null;
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: StatusState = {
  appStatus: null,
  isLoading: false,
  isLoaded: false,
};

const appStatusReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    appStatusLoaded,
    (state, action): StatusState => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      appStatus: action.payload,
    })
  ),
  handleAction(
    appStatusLoading,
    (state): StatusState => ({
      ...state,
      isLoading: true,
    })
  ),
  handleAction(appStatusReset, () => initialState),
  handleAction(purge, () => initialState),
]);

export default appStatusReducer;
