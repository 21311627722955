import { createActionCreator } from "deox";

import { CommonData } from "../../../types/types";

export const commonDataError = createActionCreator("commonDataError");

export const commonDataSuccess = createActionCreator(
  "commonDataSuccess",
  (resolve) => (data: CommonData) => resolve(data)
);

export const commonDataLoading = createActionCreator(
  "commonDataLoading",
  (resolve) => (data: CommonData) => resolve(data)
);
