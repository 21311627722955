import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { List } from "linqts";
import { merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import ChildStep6Schema from "./ChildStep6Schema";
import * as actions from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/store";
import { MedicalPlanAnswer } from "../../../types/models";
import { IChild, IQuestionTemplate, IRegistrationMedicalPlanAnswer } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { PhysicalConditionId } from "../../../utils/medicalConditionsHelper";
import PhysicalBehaviouralAndOtherNeeds from "../../Children/PhysicalBehaviouralAndOtherNeeds";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep6({ child, id }: Props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  function updateChild(values: IChild) {
    if (values.hasPhysicalNeeds === false) {
      values.physicalNeeds = "";
    }

    if (values.hasBehaviouralNeeds === false) {
      values.behaviouralNeeds = "";
    }

    if (values.hasSpecialNeeds === false) {
      values.specialNeeds = "";
    }

    if (values.hasDietaryRequirements === false) {
      values.dietaryRequirements = "";
    }

    actions.saveChild(dispatch, values);
    navigate(`/registration-child-step/7/${id}`);
  }

  var medicalConditions =
    child.medicalPlanAnswers !== undefined
      ? child.medicalPlanAnswers.map((x) => x.conditionId).filter((value, index, self) => self.indexOf(value) === index)
      : [];
  const medicalPlanTemplate = useSelector((state: RootState) => state?.commonData?.data.medicalPlanTemplate);

  var allQuestions = new List(medicalPlanTemplate?.questions ?? []);

  const formikValues = {
    hasPhysicalNeeds: undefined,
    hasBehaviouralNeeds: undefined,
    hasSpecialNeeds: undefined,
    hasDietaryRequirements: undefined,
    physicalNeeds: "",
    behaviouralNeeds: "",
    specialNeeds: "",
    dietaryRequirements: "",
    medicalConditions: medicalConditions,
    medicalPlanAnswers: new Array<IRegistrationMedicalPlanAnswer>(),
  };
  const initialValues = merge(formikValues, child);

  function CannotGoNext(
    isSubmitting: boolean,
    allQuestions: List<IQuestionTemplate>,
    currentAnswers: List<IRegistrationMedicalPlanAnswer>,
    currentMedicalConditionId: number
  ) {
    var mappedAnswers = currentAnswers.Select((ans) => {
      var q = allQuestions.FirstOrDefault((x) => x?.questionId === ans.questionId);
      return new MedicalPlanAnswer({
        ...ans,
        isFreeTextRequired: (q?.allowFreeText && q?.freeTextRequired) ?? false,
        isRequired: q?.required ?? false,
      });
    });

    console.log(currentAnswers);

    return (
      isSubmitting ||
      mappedAnswers.Any((an) => an !== undefined && an.conditionId === currentMedicalConditionId && !an.isValid)
    );
  }

  const backUrl =
    child.medicalConditions !== undefined && child.medicalConditions?.length > 0
      ? `/registration-child-step/5/${id}`
      : `/registration-child-step/4/${id}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChildStep6Schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild(values);
        }, 500);
      }}
    >
      {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
        <Form>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={12}>
              <PhysicalBehaviouralAndOtherNeeds
                classes={classes}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                dispatch={dispatch}
              />
            </Grid>
          </Grid>
          <Pager>
            <ButtonSecondary href={backUrl}>
              <strong>Back</strong>
            </ButtonSecondary>
            <ButtonPrimary
              disabled={CannotGoNext(
                isSubmitting,
                allQuestions,
                new List(values.medicalPlanAnswers ?? []),
                PhysicalConditionId
              )}
              onClick={submitForm}
            >
              <strong>Next</strong>
            </ButtonPrimary>
          </Pager>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}

export default FormChildStep6;
