import React from "react";

interface Props {
  label: string;
  symbol: string;
  size?: number | undefined;
}

const Emoji = (props: Props) => {
  const { label, symbol, size } = props;
  var emojiSize = size ?? 24 + "px";
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label}
      aria-hidden={label ? "false" : "true"}
      style={{ fontSize: emojiSize }}
    >
      {symbol}
    </span>
  );
};

export default Emoji;
