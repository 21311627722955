import { Dayjs } from "dayjs";

import apiPayment from "./paymentApi";
import {
  dueDatesError,
  dueDatesLoaded,
  dueDatesLoading,
  paymentError,
  paymentInit,
  paymentProcessing,
  paymentSuccess,
} from "./paymentStateActions";
import { IBankInfoHttpRequest, ICreditCardInfoHttpRequest, IMakePaymentHttpResponse } from "../../../types/types";
import { PayloadEncryptHelper } from "../../../utils/cryptoTokenHelper";
import { PayloadSigningHelper } from "../../../utils/payloadSigningHelper";
import {
  BankInfoHttpRequest,
  CreditCardInfoHttpRequest,
  CryptoSignatureHttpRequest,
  EncryptedPayloadHttpRequestOfMakeOneOffCardPaymentHttpRequest,
  MakeOneOffBankPaymentHttpRequest,
  MakeRegularPaymentHttpRequest,
  TokenHttpResponse,
} from "../../parentApi";

const errorMsg = "Oops! An error has occurred. Please try again later, otherwise contact Customer Care on 1300 105 343";
const errorTitle = "Transaction Failed";
export function makeOneOffBankPayment(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  amount: number,
  bankInfo: IBankInfoHttpRequest
) {
  dispatch(paymentProcessing());
  apiPayment
    .makeOneOffBankPayment(
      customerAccountId,
      new MakeOneOffBankPaymentHttpRequest({
        amount: amount,
        customerContactId: parseInt(customerContactId.toString()),
        bank: new BankInfoHttpRequest(bankInfo),
      })
    )
    .then(
      function (value: IMakePaymentHttpResponse | null) {
        if (value) {
          if (value.isSuccess) {
            dispatch(paymentSuccess(value.title));
          } else {
            dispatch(paymentError({ message: value.description, title: value.title }));
          }
        } else {
          dispatch(paymentError({ message: "", title: "Error" }));
        }
      },
      function (error: any) {
        dispatch(paymentError({ title: errorTitle, message: errorMsg }));
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(paymentInit());
      }, 500);
    });
}

export function makeOneOffCardPayment(
  dispatch: any,
  token: TokenHttpResponse,
  customerAccountId: number,
  customerContactId: number,
  amount: number,
  card: ICreditCardInfoHttpRequest
) {
  dispatch(paymentProcessing());
  var payload = {
    amount: amount,
    customerContactId: parseInt(customerContactId.toString()),
    creditCard: new CreditCardInfoHttpRequest(card),
  };

  var encryptedPayload = PayloadEncryptHelper.encrypt(JSON.stringify(payload), token.value);
  var cryptoSignature = new CryptoSignatureHttpRequest({
    value: PayloadSigningHelper.hashMapObject(payload, token.value),
    tokenId: token.id,
    customerAccountId: customerAccountId,
  });
  apiPayment
    .makeOneOffCardPayment(
      customerAccountId,
      new EncryptedPayloadHttpRequestOfMakeOneOffCardPaymentHttpRequest({
        payload: encryptedPayload,
        cryptoSignature: cryptoSignature,
      })
    )
    .then(
      function (value: IMakePaymentHttpResponse | null) {
        if (value) {
          if (value.isSuccess) {
            dispatch(paymentSuccess(value.title));
          } else {
            dispatch(paymentError({ message: value.description, title: value.title }));
          }
        } else {
          dispatch(paymentError({ message: "", title: "Error" }));
        }
      },
      function (error: any) {
        dispatch(paymentError({ title: errorTitle, message: errorMsg }));
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(paymentInit());
      }, 500);
    });
}
export function makeRegularPayment(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  amount: number
) {
  dispatch(paymentProcessing());
  apiPayment
    .makeRegularPayment(
      customerAccountId,
      new MakeRegularPaymentHttpRequest({
        amount: amount,
        customerContactId: customerContactId,
      })
    )
    .then(
      function (value: IMakePaymentHttpResponse | null) {
        if (value) {
          if (value.isSuccess) {
            dispatch(paymentSuccess(value.title));
          } else {
            dispatch(paymentError({ message: value.description, title: value.title }));
          }
        } else {
          dispatch(paymentError({ message: "", title: "Error" }));
        }
      },
      function (error: any) {
        dispatch(paymentError({ title: errorTitle, message: errorMsg }));
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(paymentInit());
      }, 500);
    });
}

export function getDueDates(dispatch: any, year: number, customerAccountId: number) {
  dispatch(dueDatesLoading());
  apiPayment.getBillingDueDates(year, customerAccountId).then(
    function (value: Dayjs[] | null) {
      dispatch(dueDatesLoaded(value ?? [], year));
    },
    function (error: any) {
      dispatch(dueDatesError());
    }
  );
}
