import { createReducer } from "deox";

import { ICreatePaymentMethodHttpResponse, IPaymentMethodHttpResponse } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import {
  paymentMethodError,
  paymentMethodLoading,
  paymentMethodSaved,
  paymentMethodSaveError,
  paymentMethodSaveInit,
  paymentMethodSaving,
  paymentMethodSuccess,
} from "./paymentMethodStateActions";

export interface PaymentMethodState {
  payload: IPaymentMethodHttpResponse | null;
  savePayload: ICreatePaymentMethodHttpResponse | null;
  isError: boolean;
  isSavingError: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isSaved: boolean;
  isLoaded: boolean;
}

export const initialState: PaymentMethodState = {
  payload: null,
  savePayload: null,
  isError: false,
  isLoading: false,
  isSaved: false,
  isSaving: false,
  isSavingError: false,
  isLoaded: false,
};

const paymentMethodReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    paymentMethodSuccess,
    (state, action): PaymentMethodState => ({
      ...state,
      isLoading: false,
      isError: false,
      payload: action.payload,
      isLoaded: true,
    })
  ),
  handleAction(
    paymentMethodLoading,
    (state): PaymentMethodState => ({
      ...state,
      isLoading: true,
      isLoaded: false,
      isError: false,
    })
  ),
  handleAction(
    paymentMethodSaving,
    (state): PaymentMethodState => ({
      ...state,
      isSaving: true,
      isError: false,
      isSavingError: false,
      isSaved: false,
    })
  ),
  handleAction(
    paymentMethodSaved,
    (state, action): PaymentMethodState => ({
      ...state,
      isSaving: false,
      savePayload: action.payload,

      isSavingError: false,
      isSaved: true,
    })
  ),
  handleAction(
    paymentMethodError,
    (state): PaymentMethodState => ({
      ...state,
      isError: true,
      isLoading: false,
      isLoaded: false,
      isSaving: false,
      isSaved: false,
      payload: null,
      isSavingError: false,
    })
  ),
  handleAction(
    paymentMethodSaveError,
    (state): PaymentMethodState => ({
      ...state,
      isSaving: false,
      isSaved: false,
      savePayload: null,
      isSavingError: true,
    })
  ),
  handleAction(paymentMethodSaveInit, (state) => ({
    ...state,
    isSaved: false,
    isSavingError: false,
    savePayload: null,
    isSaving: false,
  })),
  handleAction(purge, () => initialState),
]);

export default paymentMethodReducer;
