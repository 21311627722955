import React from "react";

import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AuthenticatedRegisteredRoutes from "./AuthenticatedRegisteredRoutes";
import AuthenticatedUnregisteredRoutes from "./AuthenticatedUnregisteredRoutes";
import GtmEvents from "./GtmEvents";
import ScrollRestoration from "./ScrollRestoration";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import Unavailable from "../pages/AppStatus/Unavailable";
import { Status } from "../store/parentApi";
import { RootState } from "../store/reducers";

function Routes() {
  const appStatusIsLoaded = useSelector((state: RootState) => state.appStatus.isLoaded);
  const appStatus = useSelector((state: RootState) => state.appStatus.appStatus);
  const appUnavailable = appStatusIsLoaded && appStatus !== null && appStatus.status === Status.UnAvailable;
  const auth = useSelector((state: RootState) => state.auth);
  const { registeredUser, user } = auth;

  return (
    <BrowserRouter>
      <ScrollRestoration />

      {appUnavailable ? (
        <Unavailable />
      ) : user && registeredUser ? (
        <>
          <GtmEvents />
          <AuthenticatedRegisteredRoutes />
        </>
      ) : user && !registeredUser ? (
        <AuthenticatedUnregisteredRoutes />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </BrowserRouter>
  );
}

export default Routes;
