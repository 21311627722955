import React, { useEffect, useState } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import RepeatIcon from "@mui/icons-material/Repeat";
import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import styles from "../../components/Common/Calendars/UpcomingBookings.module.scss";
import { useAppDispatch } from "../../store/hooks";
import { getRecurringBookings } from "../../store/modules/booking/bookingActions";
import { CancelBookingInitial } from "../../store/modules/booking/bookingStateActions";
import { RootState } from "../../store/store";
import { EntityType } from "../../types/models";
import { IBookingScheduleSummaryModel, IEditRecurringBookingState } from "../../types/types";
import { getCareTypeColor, getCareTypeColorVariable } from "../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../Common/Buttons/Buttons";
import { CancellationDialog } from "../Common/Calendars/CancellationDialog";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";
import Spacer from "../Common/Spacers/Spacers";

const useStyles = makeStyles()((theme) => {
  return {
    logo: {
      height: 30,
      "@media screen and (min-width: 720px)": {
        height: 30,
      },
    },
    icon: {
      width: "1.3rem",
      height: "1.3rem",
      paddingRight: "10px",
    },
    childName: {
      wordBreak: "break-all",
      overflowWrap: "anywhere",
    },
    button: {
      pointerEvents: "auto",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
  };
});
export default function RecurringBookings() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedBooking, setSelectedBooking] = useState<IBookingScheduleSummaryModel | undefined>();
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const { isSuccess: bookingCanceled } = useSelector((state: RootState) => state.bookingCancel);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cancellationPopUpOpen, setCancellationPopUpOpen] = React.useState(false);

  const handleCancellationPopUpOpen = () => {
    setAnchorEl(null);

    setCancellationPopUpOpen(true);
    handleClickAway();
  };

  const handleCancellationPopUpClose = () => {
    setCancellationPopUpOpen(false);
  };

  const [bookings, setBookings] = React.useState<IBookingScheduleSummaryModel[]>([]);

  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );

  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);

  useEffect(() => {
    if (customer_account_id)
      getRecurringBookings(dispatch, customer_account_id).then((data) => {
        if (data) {
          setBookings(data.sort((a, b) => a.effectiveFromDate.diff(b.effectiveFromDate)));
        }
      });
  }, [customer_account_id, bookingCanceled]);

  return (
    <Grid container gap={1}>
      <Grid container direction="column" gap={1}>
        <Grid item>
          <Typography>Active Recurring Bookings</Typography>
        </Grid>
        <Spacer margin={5} />
      </Grid>
      {bookings.map((bk, index) => {
        const careType = bk.sessionType ?? "";
        var child = children?.find((x) => x.childId === bk.childId);
        const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
        var isHolidayClub = careType.toUpperCase() === "VAC";
        var endOfYearDt = new Date(bk.effectiveFromDate.year(), 12, 31);
        var endOfYear = dayjs(endOfYearDt);
        return (
          <Grid item key={index} xs={12}>
            <Card
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: getCareTypeColorVariable(careType),
              }}
            >
              <CardContent>
                <Grid
                  container
                  direction="row"
                  pl="15px"
                  pt={2}
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="start"
                >
                  <Grid item lg={2} xs={10}>
                    <Grid container direction={"row"} justifyContent={"flex-start"}>
                      <Grid item pr="20px">
                        <Box
                          // flexDirection="row"
                          // display="flex"
                          // justifyContent="space-between"
                          // justifyItems={"center"}
                          className={`${styles[getCareTypeColor(careType.toUpperCase(), "Bg")]}`}
                          sx={{
                            width: "10px",
                            borderBottomWidth: 25,
                            alignContent: "center",
                            margin: "2px",
                            height: "70px",
                            alignItems: "center",

                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                          }}
                        ></Box>
                      </Grid>

                      <Grid item>
                        <Grid container direction="column" alignItems="flex-start">
                          <Typography sx={{ fontSize: 14 }} gutterBottom>
                            {bk.rollName}
                          </Typography>
                          {isHolidayClub && <img src={RocketeersLogo} alt="Rocketeers Logo" className={classes.logo} />}
                          {!isHolidayClub && <img src={YourOSHCLogo} alt="Your OSHC Logo" className={classes.logo} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <Grid container direction="column" alignItems="start" gap={1}>
                      <Grid container direction="row" columnGap={1} alignItems="center">
                        <PersonOutlineIcon fontSize="small" className={classes.icon} />
                        <Stack
                          direction="row"
                          gap={1}
                          alignItems="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                        >
                          <Grid container direction="row" gap={1} alignItems="center">
                            <AvatarDisplay info={avatar || { index: child?.avatarIndex }} size={25} />
                            <Typography
                              fontSize={{
                                md: "14px",
                                xs: "12px",
                              }}
                              className={classes.childName}
                            >
                              {`${child?.firstName} ${child?.lastName?.substring(0, 1)}`}
                            </Typography>
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid container direction="row" columnGap={1} alignItems="center">
                        <Stack direction={"row"} alignItems="center" columnGap={1}>
                          <CalendarTodayIcon className={classes.icon} />
                          <Typography
                            fontSize={{
                              md: "14px",
                              xs: "12px",
                            }}
                          >
                            {" "}
                            {bk.effectiveFromDate.format("ddd MMM DD, YYYY")}{" "}
                          </Typography>
                          <ArrowRightAltIcon />
                          <Typography
                            fontSize={{
                              md: "14px",
                              xs: "12px",
                            }}
                          >
                            {(bk.effectiveToDate ?? endOfYear).format("ddd MMM DD, YYYY")}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid container direction="row" columnGap={1} alignItems="center">
                        <LocationOnOutlinedIcon className={classes.icon} />
                        <Typography
                          fontSize={{
                            md: "14px",
                            xs: "12px",
                          }}
                        >
                          {" "}
                          {bk.serviceName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={4} xs={12} pt={0}>
                    <Grid container direction="row" columnGap={1}>
                      <Grid item pt={0.5}>
                        <RepeatIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" columnGap={1} alignItems="flex-start">
                          <Typography variant="subtitle2" fontWeight={"bold"}>
                            {careType}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            fontSize={{
                              md: "14px",
                              xs: "12px",
                            }}
                          >
                            {getScheduleDescription(bk)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            fontSize={{
                              md: "14px",
                              xs: "12px",
                            }}
                          >
                            {bk.totalBookings} sessions
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Spacer margin={5} />
                <Divider sx={{ borderBottomWidth: "2px" }} variant="fullWidth" />
              </CardContent>
              <CardActions sx={{ p: "15px" }}>
                <Grid container direction="row" justifyContent={"space-between"} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <Typography variant="subtitle2" fontSize={15} fontWeight="bold">
                      Your recurring booking is finishing on {(bk.effectiveToDate ?? endOfYear).format("MMM DD, YYYY")}.
                      Would you like to reschedule this booking?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      sx={{ flexDirection: { md: "column", lg: "row" } }}
                      columnGap={2}
                      pt={1}
                      rowGap={1}
                      justifyContent={{ md: "flex-start", lg: "flex-end" }}
                      alignItems="center"
                    >
                      <ButtonPrimary
                        className={`${classes.button} ${styles[getCareTypeColor(careType.toUpperCase(), "Bg")]}`}
                        sx={{ color: "black", textTransform: "none" }}
                        onClick={(event: any) => {
                          navigate("/edit-booking", {
                            state: JSON.stringify({
                              bookingScheduleId: bk.bookingScheduleId,
                              childId: bk.childId,
                              effectiveFromDate: bk.effectiveFromDate,
                              effectiveToDate: bk.effectiveToDate,
                              uniqueId: bk.uniqueId,
                              rollId: bk.rollId,
                              serviceId: bk.serviceId,
                              sessionType: bk.sessionType,
                              firstFriday: bk.firstFriday,
                              firstThursday: bk.firstThursday,
                              firstWednesday: bk.firstWednesday,
                              firstTuesday: bk.firstTuesday,
                              firstMonday: bk.firstMonday,
                              originalEffectiveFromDate: bk.effectiveFromDate,
                            } as IEditRecurringBookingState),
                          });
                        }}
                      >
                        Edit Booking
                      </ButtonPrimary>

                      <ButtonSecondary
                        className={classes.button}
                        sx={{ color: "black", textTransform: "none" }}
                        onClick={(event: any) => {
                          setSelectedBooking(bk);

                          dispatch(CancelBookingInitial());

                          handleCancellationPopUpOpen();
                        }}
                      >
                        Cancel Schedule
                      </ButtonSecondary>
                    </Grid>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        );
      })}

      {selectedBooking && cancellationPopUpOpen && (
        <CancellationDialog
          childName={children?.find((x) => x.childId === selectedBooking.childId)?.firstName ?? ""}
          minimumDaysToAvoidCancellationFee={
            servicesByCustomer?.find((s) => s.serviceId === selectedBooking.serviceId)
              ?.minimumDaysToAvoidCancellationFeeRecurringBooking ?? 0
          }
          className={`${styles[getCareTypeColor(selectedBooking.sessionType ?? "", "Bg")]}`}
          careTypeColor={getCareTypeColor(selectedBooking.sessionType ?? "")}
          customer_account_id={customer_account_id!}
          date={selectedBooking.effectiveFromDate!}
          handleClose={handleCancellationPopUpClose}
          open={cancellationPopUpOpen}
          serviceId={selectedBooking.serviceId!}
          rollName={selectedBooking.rollName!}
          schedule={{
            endDate: selectedBooking.effectiveToDate!,
            startDate: selectedBooking.effectiveFromDate!,
            scheduleId: selectedBooking.uniqueId,
          }}
          session={undefined}
        />
      )}
    </Grid>
  );

  function getScheduleDescription(schedule: IBookingScheduleSummaryModel) {
    const days = [];
    if (schedule.firstMonday) {
      days.push("Mon");
    }
    if (schedule.firstTuesday) {
      days.push("Tue");
    }
    if (schedule.firstWednesday) {
      days.push("Wed");
    }
    if (schedule.firstThursday) {
      days.push("Thu");
    }
    if (schedule.firstFriday) {
      days.push("Fri");
    }

    return renderDays(days, "Occurs every");
  }

  function renderDays(days: string[], title: string, suffix?: string) {
    var lastDay = days.pop();

    return (
      <Typography variant="subtitle2" sx={{ display: "flex", justifyContent: "center" }} fontSize={"14px"}>
        {title}&nbsp;
        {days.map((day, index) => (
          <>
            {index > 0 && index < days.length && ", "}
            {day}
          </>
        ))}
        {days.length > 0 && <>&nbsp;and</>}
        <>
          &nbsp;
          {lastDay}
        </>
        {suffix != null ? suffix : null}
      </Typography>
    );
  }
}
