const plugin = (option, dayjsClass, dayjsFactory) => {
  const proto = dayjsClass.prototype;
  proto.toJSON = function (_arguments) {
    var result = this.format("YYYY-MM-DDTHH:mm:ss");
    return result;
  };

  proto.toISOString = function (_arguments) {
    var result = this.format("YYYY-MM-DDTHH:mm:ss");
    return result;
  };
};
export default plugin;
