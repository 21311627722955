import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";

import { AvatarInfo } from "../../../types/models";
import { Avatars, getStyleColor } from "../../../utils/helpers";
import Emoji from "../Emoji/Emoji";

interface Props {
  info?: AvatarInfo;
  size?: number | undefined;
  selected?: boolean | undefined;
  disabled?: boolean | undefined;
  icon?: any;
  alt?: string;
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    boxShadow: theme.shadows[2],
    backgroundColor: "white",
    // border: "2px solid white",
  },
  avatarDisabled: {
    opacity: 0.5,
  },
  avatarSelected: {
    backgroundColor: `${getStyleColor("--color-accent-1")} !important`,
  },
}));

export const AvatarDisplay = (props: Props) => {
  const { classes } = useStyles();

  var avatarSize = props.size ?? 44 + "px";
  const alt = props.alt || "";

  return (
    <>
      {props.icon ? (
        <Tooltip title={alt} aria-label="add">
          <Avatar style={{ height: avatarSize, width: avatarSize }} className={classes.avatar}>
            {props.icon}
          </Avatar>
        </Tooltip>
      ) : props.info?.imageDataUrl ? (
        <Tooltip title={alt} aria-label="add">
          <Avatar
            style={{ height: avatarSize, width: avatarSize }}
            className={classes.avatar}
            src={props.info.imageDataUrl}
            alt=""
          />
        </Tooltip>
      ) : props.info?.index !== undefined ? (
        <Tooltip title={alt} aria-label="add">
          <Avatar
            style={{ height: avatarSize, width: avatarSize }}
            className={`${classes.avatar} ${props.disabled && classes.avatarDisabled} ${
              props.selected && classes.avatarSelected
            } `}
            alt=""
          >
            <Emoji size={Math.floor((props.size ?? 44) / 2)} symbol={Avatars.get(props.info.index)} label="avatar" />
          </Avatar>
        </Tooltip>
      ) : null}
    </>
  );
};
