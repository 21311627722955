import { createActionCreator } from "deox";

import { IPasswordSettings } from "../../../types/types";

export const loadPasswordSettingsPending = createActionCreator("loadPasswordSettingsPending");
export const loadPasswordSettingsError = createActionCreator("loadPasswordSettingsError");

export const loadPasswordSettingsInit = createActionCreator("loadPasswordSettingsInit");

export const loadPasswordSettingsSuccess = createActionCreator(
  "loadPasswordSettingsSuccess",
  (resolve) => (settings: IPasswordSettings) => resolve(settings)
);
