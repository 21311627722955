import * as Yup from "yup";
const ChangeEmailSchema = Yup.object().shape({
  newEmail: Yup.string().email().required("New Login Email is required"),
  confirmEmail: Yup.string()
    .email()
    .required("Confirm Email is required")
    .oneOf([Yup.ref("newEmail")], "Confirm Email should match New Login Email"),
});

export default ChangeEmailSchema;
