import Button, { ButtonProps } from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

import { getStyleColor } from "../../../utils/helpers";

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderColor: "black",
    borderWidth: 1,
    backgroundColor: getStyleColor("--color-primary"),
    borderRadius: 3,
    height: 50,
    paddingLeft: 50,
    paddingRight: 50,
    textTransform: "none",
    "&.Mui-disabled": {
      backgroundColor: "#929292!important",
      color: "white!important",
    },
  },
  primary: {
    backgroundColor: getStyleColor("--color-primary"),
    "&:hover": {
      backgroundColor: getStyleColor("--color-primary"),
    },
  },
  white: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  orange: {
    backgroundColor: getStyleColor("--color-accent-5"),
    "&:hover": {
      backgroundColor: getStyleColor("--color-accent-5"),
    },
  },
  accent1: {
    backgroundColor: getStyleColor("--color-accent-1"),
    "&:hover": {
      backgroundColor: getStyleColor("--color-accent-1"),
    },
  },
  accent2: {
    backgroundColor: getStyleColor("--color-accent-2"),
    color: "white",
    "&:hover": {
      backgroundColor: getStyleColor("--color-accent-2"),
    },
  },
  accent3: {
    backgroundColor: getStyleColor("--color-accent-3"),
    color: "black",
    "&:hover": {
      backgroundColor: getStyleColor("--color-accent-3"),
    },
  },
  accent2_outlined_round: {
    borderColor: getStyleColor("--color-accent-2"),
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    borderRadius: 25,
  },
  secondary: {
    backgroundColor: "white",
  },
  link: {
    backgroundColor: "transparent",
    color: "black",
    textDecoration: "underline",
  },
}));

export const ButtonPrimary = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.primary}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonSecondary = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.secondary}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonAccent1 = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.accent1}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonAccent2 = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.accent2}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonAccent3 = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.accent3}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonWhite = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.white}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonOrange = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.orange}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonLink = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.link}`} {...props}>
      {props.children}
    </Button>
  );
};

export const ButtonAccent2OutlinedRound = (props: ButtonProps) => {
  const { classes } = useStyles();
  return (
    <Button className={`${classes.button} ${classes.accent2_outlined_round}`} variant="outlined" {...props}>
      {props.children}
    </Button>
  );
};
