import React, { useEffect, useState } from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Alert from "@mui/lab/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import dayjs from "dayjs";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { List } from "linqts";
import { merge } from "lodash";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import styles from "./EditContact.module.scss";
import EditContactSchema from "./EditContactSchema";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import ParentAuthorizationControl from "../../components/Common/Controls/ParentAuthorizationControl";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import BasicInformation from "../../components/Contacts/BasicInformation";
import ChildCareSubsidy from "../../components/Contacts/ChildCareSubsidy";
import ContactAuthorisation from "../../components/Contacts/ContactAuthorisation";
import ChildSelection from "../../components/Forms/Bookings/ChildSelection";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { getChildren } from "../../store/modules/child/childActions";
import * as customerActions from "../../store/modules/customer/customerActions";
import { contactReset, removeTemporaryAvatars } from "../../store/modules/customer/customerActionTypes";
import { DocumentInit } from "../../store/modules/document/documentStateActions";
import { ChildCrnPair } from "../../store/parentApi";
import { RootState } from "../../store/store";
import theme from "../../theme";
import { Child, ChildAgeConstraint, EntityType, SelectedChild } from "../../types/models";
import { Contact, ContactTypeCategory } from "../../types/types";
import { Avatars, getStyleColor } from "../../utils/helpers";
import { isNullOrEmpty } from "../../utils/stringUtils";

const MuiAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    paddingLeft: theme.spacing(3),
    paddignRight: theme.spacing(3),
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(AccordionDetails);

const useStyles = makeStyles()((theme) => {
  return {
    accordion: {
      width: "100%",
      marginBottom: "0 !important",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formLegend: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: theme.spacing(4),
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
    formLabel: {
      marginBottom: theme.spacing(2),
    },
    radio: {
      padding: 0,
      paddingTop: "3px",
      paddingRight: theme.spacing(2),
    },
    heading: {
      marginTop: 0,
      marginBottom: 0,
    },
    subHeading: {
      fontSize: "16px",
      color: getStyleColor("--custom-color-dark-grey"),
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    selectEmpty: {
      marginBottom: theme.spacing(2),
    },
    textField: { marginBottom: theme.spacing(2) },
    container: { paddingLeft: 0 },
    primary: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      borderColor: "black",
      borderWidth: 2,
      backgroundColor: getStyleColor("--color-accent-1"),
      borderRadius: 30,
      height: 50,
      textTransform: "none",
    },
    icon: {
      width: 128,
      height: 128,
    },
    errorIcon: {
      color: theme.palette.error.main,
      paddingLeft: "5px",
      width: 20,
      height: 20,
    },
  };
});

function EditContact() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  let { id } = useParams();
  const newCustomerContactId = -1;
  const customerContactId = id ? parseInt(id) : newCustomerContactId;

  const { customerAccountId, ccbSubmissionContactId } = useSelector((state: RootState) => state.customer.customer!);
  const { isContactUpdated, isUpdateError } = useSelector((state: RootState) => state.customer);
  const contacts = useSelector((state: RootState) => state.customer.customer?.contacts);
  const [tempValuesCleared, setTempValuesCleared] = React.useState(false);
  const [initialValuesConfigured, setInitialValuesConfigured] = React.useState(false);
  const [ccbContactId, setCCbContactId] = React.useState(ccbSubmissionContactId);
  const [showAuthorization, setShowAuthorization] = React.useState(false);
  useEffect(() => {
    if (!tempValuesCleared) {
      dispatch(contactReset());
      dispatch(DocumentInit());
      dispatch(removeTemporaryAvatars());
      setTempValuesCleared(true);
    }
  }, [tempValuesCleared]);

  useEffect(() => {
    if (isContactUpdated) {
      setTimeout(() => {
        dispatch(contactReset());
        getChildren(dispatch, customerAccountId!);
        navigate("/account-home");
      }, 3000);
    }
  }, [isContactUpdated]);

  const contact = contacts?.find((c) => c.customerContactId === customerContactId) ?? {
    customerContactId: customerContactId,
    firstName: "",
    lastName: "",
    avatarIndex: null,
    isPrimary: false,
    sameAddressDetailsAsPrimary: false,
    sameCulturalDetailsAsPrimary: false,
    culturalBackground: "",
    culturalNeeds: "",
    mainLanguage: "",
    residentialAddress: { uniqueId: uuid(), streetLine1: "", suburb: "", state: "", postCode: "" },
  };

  const children = useSelector((state: RootState) => state.child.childrenByCustomer);

  const [selectedChildren, setSelectedChildren] = useState(
    children?.map((x) => new SelectedChild({ child: x, isSelected: false }))
  );

  if (contact.customerContactId === newCustomerContactId) {
    const avatarsInUse = children
      ?.map((c) => c.avatarIndex ?? -1)
      .concat(contacts?.map((c) => c.avatarIndex ?? -1) ?? []);

    const availableAvatarIndex = Avatars.getFirstAvailable(avatarsInUse ?? []);
    contact.avatarIndex = availableAvatarIndex;
  } else {
    if (contacts?.find((c) => c.customerContactId === customerContactId) == null) {
      navigate("/account-home");
    }
  }

  const entityType = EntityType.Contact;
  const entityId = customerContactId?.toString() ?? 0;

  const primaryContact = useSelector((state: RootState) =>
    state.customer.customer?.contacts?.find((c) => c.isPrimary === true)
  );

  const ccmsContacts = useSelector(
    (state: RootState) => state.customer.customer?.contacts?.filter((c) => c.isPrimary !== true) ?? []
  );

  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const tempDocuments = useSelector((state: RootState) => state.customer.tempDocuments);

  const avatars = useSelector((state: RootState) => state.avatars?.avatars);

  const contactAvatar = avatars.find((x) => x.entityType === entityType && x.entityId === entityId);

  const { contactTypes } = useSelector((state: RootState) => state.commonData.data);
  let availableContactTypes = contactTypes;
  if (contact.isPrimary) {
    availableContactTypes = availableContactTypes.filter(
      (x) => x.contactTypeCategoryId === ContactTypeCategory.Immediate || x.contactTypeId === ContactTypeCategory.Other
    );
  }

  if (!initialValuesConfigured) {
    if (primaryContact && !contact.isPrimary) {
      if (primaryContact.residentialAddress != null && contact.residentialAddress != null) {
        contact.sameAddressDetailsAsPrimary =
          primaryContact.residentialAddress.streetLine1 === contact.residentialAddress.streetLine1 &&
          primaryContact.residentialAddress.suburb === contact.residentialAddress.suburb &&
          primaryContact.residentialAddress.postCode === contact.residentialAddress.postCode &&
          primaryContact.residentialAddress.state === contact.residentialAddress.state;
      }

      contact.sameCulturalDetailsAsPrimary =
        primaryContact.mainLanguage === contact.mainLanguage &&
        primaryContact.culturalBackground === contact.culturalBackground &&
        (primaryContact.culturalNeeds ?? "") === contact.culturalNeeds;
    }

    setInitialValuesConfigured(true);
  }

  const [initialValues] = React.useState(
    merge(
      {
        uniqueId: "",
        firstName: "",
        lastName: "",
        avatarIndex: contactAvatar ? contactAvatar.index : undefined,
        email: "",
        mobilePhone: "",
        contactTypeId: "",
        culturalBackground: "",
        culturalNeeds: "",
        mainLanguage: "",
        sameAddressDetailsAsPrimary: false,
        sameCulturalDetailsAsPrimary: false,
        primaryContactEmail: primaryContact ? primaryContact.email : "",
        genderId: undefined,
        birthday: undefined,
        authorizationSignature: null,
        residentialAddress: { uniqueId: uuid(), streetLine1: "", suburb: "", state: "", postCode: "" },
        authorisation: {
          canManageAccount: undefined,
          canMakeAndChangeBookings: undefined,
          consentToAdministerMedicine: undefined,
          consentToMedicalTreatment: undefined,
          canApproveAmbulance: undefined,
          canPickUpChild: undefined,
          consentToTakeOutsidePremises: undefined,
          consentForTransportation: undefined,
        },
        children,
        ccbContactId,
      },
      contact,
      {
        entityType: entityType,
        entityId: entityId,
      }
    )
  );

  var firstNameReadOnly = !isNullOrEmpty(initialValues.firstName) && initialValues.isPrimary;
  var lastNameReadOnly = !isNullOrEmpty(initialValues.lastName) && initialValues.isPrimary;
  var birthdayReadOnly = initialValues.birthday !== undefined && initialValues.isPrimary;
  var crnReadOnly = !isNullOrEmpty(initialValues.crn);
  var initialChildren = initialValues.children;

  function updateContact(
    values: Contact,
    children: Child[] | null,
    childrenWithNewAddres: Child[],
    ccbContactId: number | undefined,
    authorizationSignature: string | null,
    errorCallback: () => void
  ) {
    let documentIdsAdded = tempDocuments
      ?.filter((document) => document.customerContactId === customerContactId && document.created)
      .map((x) => x.uniqueId ?? "");

    let documentIdsDeleted = tempDocuments
      ?.filter((document) => document.customerContactId === customerContactId && document.deleted)
      .map((x) => x.uniqueId ?? "");

    var childCrns = undefined;
    if (values.isPrimary && values.planToClaimChildCareSubsidy && children !== null) {
      childCrns = new List(children)
        .Select((x) => new ChildCrnPair({ childId: x.childId ?? 0, crn: x.crn ?? "", birthday: dayjs(x.birthday) }))
        .ToArray();
    }

    if (customerContactId > 0) {
      customerActions.updateContact(
        dispatch,
        customerAccountId!,
        customerContactId,
        values,
        documentIdsAdded,
        documentIdsDeleted,
        childCrns,
        childrenWithNewAddres.map((x) => x.childId!),
        ccbContactId,
        authorizationSignature,
        errorCallback
      );
    } else {
      customerActions.createContact(
        dispatch,
        customerAccountId!,
        values,
        documentIdsAdded,
        documentIdsDeleted,
        authorizationSignature!,
        errorCallback
      );
    }
  }

  var handleClickClose = function () {
    setIsChangeAddress(false);
  };

  return (
    <>
      <LayoutAccent1>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={(value) => {
            try {
              validateYupSchema(value, EditContactSchema, true, value);
            } catch (err) {
              return yupToFormErrors(err); //for rendering validation errors
            }
            return {};
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(true);
              updateContact(
                values,
                values.children,
                selectedChildren?.filter((x) => x.isSelected).map((x) => x.child) ?? [],
                ccbContactId,
                values.authorizationSignature,
                () => setSubmitting(false)
              );
            }, 500);
          }}
        >
          {({
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            submitForm,
            isSubmitting,
            values,
            touched,
            errors,
          }) => {
            const generalInformationError =
              (touched.firstName && Boolean(errors.firstName)) ||
              (touched.lastName && Boolean(errors.lastName)) ||
              (touched.birthday && Boolean(errors.birthday)) ||
              (touched.genderId && Boolean(errors.genderId)) ||
              (touched.residentialAddress?.streetLine1 && Boolean(errors.residentialAddress?.streetLine1)) ||
              (touched.residentialAddress?.suburb && Boolean(errors.residentialAddress?.suburb)) ||
              (touched.residentialAddress?.postCode && Boolean(errors.residentialAddress?.postCode)) ||
              (touched.residentialAddress?.state && Boolean(errors.residentialAddress?.state)) ||
              (touched.mainLanguage && Boolean(errors.mainLanguage)) ||
              (touched.culturalBackground && Boolean(errors.culturalBackground));

            let ccsError = false;
            if (
              errors &&
              errors.children &&
              touched.children &&
              Array.isArray(errors.children) &&
              Array.isArray(touched.children)
            ) {
              for (let index = 0; index < touched.children.length; index++) {
                if (errors.children[index]) {
                  ccsError = true;
                }
              }
            }

            let authArror = false;
            if (errors && errors.authorisation && touched.authorisation) {
              authArror = true;
            }

            return (
              <Form>
                <AnimatedDialog open={isChangeAddress} title="Address Change" onClose={handleClickClose}>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        You have updated your address to: &nbsp;
                        <strong>
                          {values.residentialAddress.streetLine1 +
                            ", " +
                            values.residentialAddress.suburb +
                            ", " +
                            values.residentialAddress.state +
                            ", " +
                            values.residentialAddress.postCode}
                        </strong>
                        <p>
                          Please select the children from the below list that should be updated with the same address:
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider></Divider>
                      </Grid>
                      <Grid item xs={12}>
                        {selectedChildren &&
                          selectedChildren.map((child, index) => (
                            <ChildSelection
                              key={"childSelection_" + child.child.childId}
                              child={child}
                              constraints={new List(new Array<ChildAgeConstraint>())}
                              onSelectionChanged={(isAgeLess, isAgeGreater, checked) => {
                                selectedChildren[index].isSelected = checked;
                                setSelectedChildren(selectedChildren.splice(0));
                              }}
                              showAddress={true}
                              preventDisableSelection={true}
                            ></ChildSelection>
                          ))}
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonPrimary
                          disabled={isSubmitting}
                          onClick={() => {
                            setIsChangeAddress(false);
                            submitForm();
                          }}
                        >
                          <strong>Update</strong>
                        </ButtonPrimary>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </AnimatedDialog>
                <div className={styles.EditContact}>
                  <div className={styles["EditContact-Card"]}>
                    <h1 className={`h2`} style={{ marginTop: 0, marginBottom: 0 }}>
                      {id === undefined ? "Add Contact" : "Edit Contact"}
                    </h1>
                    <h2 className={`h5 light`} style={{ marginTop: 0, marginBottom: theme.spacing(3) }}>
                      {contact.firstName} {contact.lastName}
                    </h2>
                    <MuiAccordion defaultExpanded className={classes.accordion}>
                      <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                        <Grid container alignItems="center">
                          <h3 className={`regular h5`}>General Information</h3>
                          {getAccordionSummaryError(generalInformationError)}
                        </Grid>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <BasicInformation
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          errors={errors}
                          primaryContact={primaryContact}
                          availableContactTypes={availableContactTypes}
                          firstNameReadOnly={firstNameReadOnly}
                          lastNameReadOnly={lastNameReadOnly}
                          birthdayReadOnly={birthdayReadOnly}
                        />
                      </MuiAccordionDetails>
                    </MuiAccordion>
                    {contact.isPrimary && (
                      <MuiAccordion className={classes.accordion}>
                        <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                          <Grid container alignItems="center">
                            <h3 className={`regular h5`}>Child Care Subsidy</h3>
                            {getAccordionSummaryError(ccsError)}
                          </Grid>
                        </MuiAccordionSummary>
                        <MuiAccordionDetails>
                          <ChildCareSubsidy
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            ccmsContacts={ccmsContacts}
                            ccbSubmissionContactId={ccbContactId}
                            handleCCBContactChange={setCCbContactId}
                            crnReadOnly={crnReadOnly}
                            initialChildren={initialChildren}
                          />
                        </MuiAccordionDetails>
                      </MuiAccordion>
                    )}
                    <MuiAccordion className={classes.accordion}>
                      <MuiAccordionSummary expandIcon={<IoChevronDownOutline />}>
                        <Grid container alignItems="center">
                          <h3 className={`regular h5`}>Authorisation</h3>
                          {getAccordionSummaryError(authArror)}
                        </Grid>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <ContactAuthorisation
                          classes={classes}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          errors={errors}
                        />
                      </MuiAccordionDetails>
                    </MuiAccordion>
                    <ButtonPrimary
                      disabled={isSubmitting}
                      onClick={async () => {
                        if (!doesRequireAuthorization(values)) {
                          if (values.isPrimary) {
                            if (
                              contact.residentialAddress?.postCode !== values.residentialAddress.postCode ||
                              contact.residentialAddress?.state !== values.residentialAddress.state ||
                              contact.residentialAddress?.streetLine1 !== values.residentialAddress.streetLine1 ||
                              contact.residentialAddress?.suburb !== values.residentialAddress.suburb
                            ) {
                              setIsChangeAddress(true);
                            } else {
                              submitForm();
                            }
                          } else {
                            submitForm();
                          }
                        } else {
                          try {
                            await validateYupSchema(values, EditContactSchema, false);
                            setShowAuthorization(true);
                          } catch (ex) {
                            submitForm();
                          }
                        }
                      }}
                    >
                      <strong>Update</strong>
                    </ButtonPrimary>
                    {isUpdateError && (
                      <Alert severity="error">{`An error occurred while trying ${
                        customerContactId > 0 ? "update" : "create"
                      } contact. Please try again later`}</Alert>
                    )}
                  </div>
                </div>
                <Backdrop className={classes.backdrop} open={isSubmitting}>
                  <CircularProgress color="primary" />
                </Backdrop>
                <AnimatedDialog open={showAuthorization} maxWidth="sm" onClose={() => setShowAuthorization(false)}>
                  <ParentAuthorizationControl
                    onSigned={(signature) => {
                      setFieldValue("authorizationSignature", signature);
                      submitForm();
                      setShowAuthorization(false);
                    }}
                  />
                </AnimatedDialog>
              </Form>
            );
          }}
        </Formik>
      </LayoutAccent1>
    </>
  );

  function getAccordionSummaryError(hasErrors: boolean | undefined): React.ReactNode {
    return hasErrors ? <ErrorOutlineIcon className={classes.errorIcon} /> : "";
  }

  function doesRequireAuthorization(values: Contact) {
    return (
      initialValues.authorisation.canApproveAmbulance !== values.authorisation?.canApproveAmbulance ||
      initialValues.authorisation.canMakeAndChangeBookings !== values.authorisation?.canMakeAndChangeBookings ||
      initialValues.authorisation.canManageAccount !== values.authorisation?.canManageAccount ||
      initialValues.authorisation.canPickUpChild !== values.authorisation?.canPickUpChild ||
      initialValues.authorisation.consentForTransportation !== values.authorisation?.consentForTransportation ||
      initialValues.authorisation.consentToAdministerMedicine !== values.authorisation?.consentToAdministerMedicine ||
      initialValues.authorisation.consentToMedicalTreatment !== values.authorisation?.consentToMedicalTreatment ||
      initialValues.authorisation.consentToTakeOutsidePremises !== values.authorisation?.consentToTakeOutsidePremises
    );
  }
}

export default EditContact;
