interface TabPanelProps {
  children: JSX.Element;
  index: number;
  value: number;
  className?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ca-tabpanel-${index}`}
      aria-labelledby={`ca-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default TabPanel;
