import api from "./termsAndConditionsApi";
import {
  termsAccepted,
  termsAcceptedError,
  termsAcceptedSaving,
  termsLoading,
  termsLoadingError,
  termsRequired,
} from "./termsAndConditionsStateActions";
export function fetchTermsAndConditions(dispatch: any, customerAccountId: number) {
  dispatch(termsLoading());
  api.getCustomerTermsAndConditions(customerAccountId).then(
    (value: string) => {
      if (value.length === 0) {
        dispatch(termsAccepted());
      } else {
        dispatch(termsRequired(value));
      }
    },
    function (error: any) {
      dispatch(termsLoadingError());
    }
  );
}

export function acceptTermsAndConsitions(dispatch: any, customerAccountId: number) {
  dispatch(termsAcceptedSaving());
  api.acceptTermsAndConditions(customerAccountId).then(
    function () {
      dispatch(termsAccepted());
    },
    function () {
      dispatch(termsAcceptedError());
    }
  );
}
