import * as Yup from "yup";

const postCodeRe = /^[0-9]{4}$/;

const ChildStep1Schema = Yup.object().shape({
  firstName: Yup.string().required("Required").lengthCheck(2, 100),
  lastName: Yup.string().required("Required").lengthCheck(2, 100),
  birthday: Yup.date()
    .min(new Date(new Date().getFullYear() - 20, 1, 1), "Invalid date")
    .required("Required")
    .typeError("Invalid date"),
  genderId: Yup.number().positive("Please select").required("Required"),
  schoolId: Yup.number().when("sameSchoolDetailsAsFirstChild", {
    is: false,
    then: Yup.number().test("required", "Required", (value) => {
      return value !== undefined && value !== null && value > 0;
    }),
  }),
  schoolName: Yup.string().when("sameSchoolDetailsAsFirstChild", {
    is: false,
    then: Yup.string().required("Required"),
  }),
  mainLanguage: Yup.string().when("sameCulturalDetailsAsPrimary", {
    is: false,
    then: Yup.string().required("This field is required"),
  }),
  culturalBackground: Yup.string().when("sameCulturalDetailsAsPrimary", {
    is: false,
    then: Yup.string().required("Required").lengthCheck(3, 255),
  }),
  culturalNeeds: Yup.string().lengthCheck(3, 255),
  sameAddressDetailsAsPrimary: Yup.boolean(),
  sameCulturalDetailsAsPrimary: Yup.boolean(),
  sameSchoolDetailsAsFirstChild: Yup.boolean(),
  residentialAddress: Yup.object().when("sameAddressDetailsAsPrimary", {
    is: false,
    then: Yup.object({
      streetLine1: Yup.string().required("Required").lengthCheck(3, 200),
      suburb: Yup.string().required("Required").lengthCheck(3, 200),
      postCode: Yup.string()
        .required("Required")
        .matches(postCodeRe, "Please enter a valid postcode")
        .lengthCheck(4, 50),
      state: Yup.string().required("Required"),
    }),
  }),
});

export default ChildStep1Schema;
