import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { Form, Formik, FormikErrors, validateYupSchema, yupToFormErrors } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import * as actions from "../../../store/modules/register/registerActions";
import { saveChildren } from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/reducers";
import { Contact, IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import ContactStep2Schema from "./ContactStep2Schema";

interface Props {
  contact: Contact;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  ul: {
    marginBottom: theme.spacing(2),
  },
}));

function FormContactStep2({ contact, id }: Props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const children = useSelector((state: RootState) => state.register?.children);

  function updateContact(contact: Contact, children: IChild[]) {
    actions.saveContact(dispatch, contact);
    saveChildren(dispatch, children, [], undefined);

    navigate(`/registration-contact-step/3/${id}`);
  }

  const initialValues = { contact, children };

  return (
    <Formik
      initialValues={initialValues}
      validate={(value) => {
        try {
          validateYupSchema(value, ContactStep2Schema, true, value);
        } catch (err) {
          return yupToFormErrors(err); //for rendering validation errors
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateContact(values.contact, values.children);
        }, 500);
      }}
    >
      {({ handleBlur, handleChange, setFieldValue, submitForm, isSubmitting, values, touched, errors }) => {
        return (
          <Form>
            <Grid container className={classes.root} spacing={3}>
              <Grid item xs={12} md={6}>
                <FormGroup className={classes.formGroup}>
                  <legend className={classes.formLegend}>Child Care Subsidy</legend>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Do you plan to claim the Child Care Subsidy?
                  </FormLabel>
                  <FormControl error={Boolean(errors.contact?.planToClaimChildCareSubsidy)} fullWidth>
                    <RadioGroup name="planToClaimChildCareSubsidy" aria-label="child-care-subsidy">
                      <FormControlLabel
                        control={<Radio disabled={isSubmitting} />}
                        label="Yes"
                        disabled={isSubmitting}
                        checked={values.contact?.planToClaimChildCareSubsidy === true}
                        onChange={() => setFieldValue("contact.planToClaimChildCareSubsidy", true)}
                      />
                      <FormControlLabel
                        control={<Radio disabled={isSubmitting} />}
                        label="No"
                        disabled={isSubmitting}
                        checked={values.contact?.planToClaimChildCareSubsidy === false}
                        onChange={() => {
                          values.contact.planToClaimChildCareSubsidy = false;
                          values.contact.crn = "";
                          values.children.forEach((_c: IChild, index) => {
                            setFieldValue(`children[${index}].crn`, "");
                          });
                        }}
                      />
                    </RadioGroup>
                    {errors.contact?.planToClaimChildCareSubsidy && (
                      <>
                        <FormHelperText error required>
                          {errors.contact?.planToClaimChildCareSubsidy}
                        </FormHelperText>
                      </>
                    )}
                  </FormControl>
                </FormGroup>
                {values.contact?.planToClaimChildCareSubsidy === true && (
                  <>
                    <FormGroup className={classes.formGroup}>
                      <legend className={classes.formLegend}>Customer Reference Number (CRN)</legend>
                      <TextField
                        id="crn"
                        label={`${contact.firstName} ${contact.lastName}`}
                        type="text"
                        value={values.contact?.crn}
                        onChange={handleChange("contact.crn")}
                        onBlur={handleBlur("contact.crn")}
                        helperText={errors.contact?.crn}
                        error={Boolean(errors.contact?.crn)}
                        fullWidth
                        className={classes.textField}
                        required={values.contact?.planToClaimChildCareSubsidy === true}
                        inputProps={{
                          maxLength: 10,
                        }}
                      />

                      {values.children.length &&
                        values.children.map((child: IChild, index: number) => {
                          return (
                            <TextField
                              id={`crn${child.uniqueId}`}
                              label={`${child.firstName} ${child.lastName}`}
                              type="text"
                              value={child.crn}
                              onChange={handleChange(`children[${index}].crn`)}
                              onBlur={handleBlur(`children[${index}].crn`)}
                              helperText={
                                touched.children !== undefined && touched.children[index] !== undefined
                                  ? errors.children !== undefined &&
                                    errors.children[index] !== undefined &&
                                    (errors.children[index] as FormikErrors<IChild>).crn
                                    ? (errors.children[index] as FormikErrors<IChild>).crn
                                    : ""
                                  : ""
                              }
                              error={
                                touched.children !== undefined &&
                                touched.children[index] !== undefined &&
                                errors.children !== undefined &&
                                errors.children[index] !== undefined &&
                                Boolean((errors.children[index] as FormikErrors<IChild>).crn)
                              }
                              fullWidth
                              className={classes.textField}
                              required={values.contact?.planToClaimChildCareSubsidy === true}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          );
                        })}
                    </FormGroup>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <strong>Reduce the cost of care with the Child Care Subsidy</strong>
                    <p className="small">
                      To find out if you are eligible, log into&nbsp;
                      <Link href="https://my.gov.au/LoginServices/main/login" target="_blank">
                        my.gov.au
                      </Link>{" "}
                      and complete your CCS Assessment. You will receive a notification from Centrelink if your claim
                      has been assessed or approved.
                    </p>
                    <p className="small"> From there, ensure that:</p>
                    <ul className={classes.ul}>
                      <li className="small">
                        Your children’s details are correct in our systems (particularly their date of birth and
                        individual CRNs).
                      </li>
                      <li className="small">
                        We have the details of the contact or parent claiming the subsidy. Then simply review and
                        confirm your enrolments via&nbsp;
                        <Link href="https://my.gov.au/LoginServices/main/login" target="_blank">
                          <strong>MyGov</strong>
                        </Link>
                        .
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Pager>
              <ButtonSecondary href={`/registration-contact-step/1/${id}`}>
                <strong>Back</strong>
              </ButtonSecondary>
              <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                <strong>Next</strong>
              </ButtonPrimary>
            </Pager>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormContactStep2;
