import { createActionCreator } from "deox";
export const childEnrolmentPromptRequired = createActionCreator(
  "childEnrolmentPromptRequired",
  (resolve) => (contactsIds: number[]) => resolve(contactsIds)
);

export const childEnrolmentPromptDismissed = createActionCreator(
  "childEnrolmentPromptDismissed",
  (resolve) => (contactId: number) => resolve(contactId)
);

export const childEnrolmentPromptNotRequired = createActionCreator(
  "childEnrolmentPromptNotRequired",
  (resolve) => () => resolve()
);

export const childEnrolmentPromptInitial = createActionCreator(
  "childEnrolmentPromptInitial",
  (resolve) => () => resolve()
);

export type ChildEnrolmentPromptStatus = "Initial" | "Required" | "Not Required" | "Dismissed";
