import React, { useEffect } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import WarningAmber from "@mui/icons-material/WarningAmber";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { grey } from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack/Stack";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import { Dayjs } from "dayjs";
import { List as QList } from "linqts";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import {
  cancelBooking,
  cancelSchedule,
  getCancellableBooking,
  getScheduleCancellableBookings,
} from "../../../store/modules/booking/bookingActions";
import { IBookingToCancel } from "../../../store/parentApi";
import { RootState } from "../../../store/reducers";
import { FeeType } from "../../../types/types";
import { getCareTypeColorVariable } from "../../../utils/helpers";
import CancellationNote from "../../Bookings/CancellationNote";
import { ButtonPrimary } from "../Buttons/Buttons";
import Center from "../Center";
import { AnimatedDialog } from "../Dialogs/AnimatedDialog";
import Spacer from "../Spacers/Spacers";

interface ISession {
  bookingId: string;
  sessionDate: Dayjs;
}

interface ISchedule {
  scheduleId: string;
  startDate: Dayjs;
  endDate: Dayjs;
}
interface IProps {
  handleClose: () => void;
  schedule: ISchedule | undefined;
  session: ISession | undefined;
  open: boolean;
  careTypeColor: string;
  date: Dayjs;
  customer_account_id: number;
  serviceId: number;
  rollName: string;
  className: string;
  childName: string;
  minimumDaysToAvoidCancellationFee: number;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  columnText: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    marginTop: theme.spacing(2),
  },
  select: {
    "&:focus": {
      backgroundColor: "white",
      //borderColor: "brown",
    },
  },

  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },

  disabledButton: {
    backgroundColor: grey[500] + "!important",
  },
}));

export function CancellationDialog(props: IProps) {
  const { classes } = useStyles();
  const {
    handleClose,
    schedule,
    session,
    open,
    careTypeColor,
    date,
    customer_account_id,
    rollName,
    serviceId,
    className,
    childName,
    minimumDaysToAvoidCancellationFee,
  } = props;

  const careTypeColorValue = getCareTypeColorVariable(careTypeColor);

  const [cancellationReason, setCancellationReason] = React.useState<number | null>(null);
  const [otherReason, setOtherreason] = React.useState<string | undefined>();

  const dispatch = useDispatch();

  const [bookingsToCancel, setBookingsToCancel] = React.useState<QList<IBookingToCancel>>(new QList([]));

  const nonChargeableBookings = bookingsToCancel.Where((x) => x?.cancellationStatusId !== 2);
  const chargeableBookings = bookingsToCancel.Where((x) => x?.cancellationStatusId === 2);
  const [isLoading, setIsLoading] = React.useState(false);

  const cancellationReasons = useSelector((state: RootState) => state.bookingCancellationReason.reasons);

  const isDisabled =
    cancellationReason === null ||
    cancellationReason < 0 ||
    (cancellationReason === 0 && (otherReason === undefined || otherReason?.trim() === "")) ||
    bookingsToCancel.Count() === 0 ||
    isLoading;
  useEffect(() => {
    if (schedule !== undefined) {
      setIsLoading(true);
      getScheduleCancellableBookings(customer_account_id!, schedule.scheduleId, cancellationReason ?? 0)
        .then((result) => {
          if (result) {
            setBookingsToCancel(result == null ? new QList() : new QList(result));
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      getCancellableBooking(customer_account_id!, session!.bookingId, cancellationReason ?? 0)
        .then((result) => {
          if (result) {
            setBookingsToCancel(result == null ? new QList() : new QList([result]));
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [cancellationReason]);

  return (
    <AnimatedDialog open={open} onClose={handleClose} sx={{ borderRadius: 33, p: 0 }} maxWidth={"sm"}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      {/* Border around popup bos */}
      <Box sx={{ border: 2, borderColor: careTypeColorValue }}>
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center" }}>
            {/*  ! LOGO  */}
            <ErrorIcon
              sx={{
                mx: "auto",
                background: "black",
                color: careTypeColorValue,

                borderRadius: "50%",
                width: { xs: 25, md: 32 },
                height: { xs: 25, md: 32 },
              }}
            ></ErrorIcon>

            {/* Pop Up Title */}
            <Typography
              variant="h6"
              align="center"
              sx={{ fontWeight: "bold", py: { xs: 0, md: 1 }, fontSize: { xs: 15, md: 20 } }}
            >
              Cancel {schedule ? "Schedule" : "Session"}
            </Typography>
            {/* Pop Up Subtitle */}
            {schedule && (
              <Typography
                className={classes.columnText}
                variant="subtitle2"
                align="center"
                justifyContent="center"
                sx={{ pt: { xs: 1, md: 0 } }}
              >
                Do you want to cancel <b>{childName}'s</b> schedule? <br />
                <Typography className={classes.columnText}>
                  <b> {schedule.startDate.format("dddd, D MMMM YYYY")}</b> to{" "}
                  <b>{schedule.endDate.format("dddd, D MMMM YYYY")}</b>
                </Typography>
              </Typography>
            )}

            {session && (
              <Typography className={classes.columnText} variant="subtitle2" align="center" justifyContent="center">
                Do you want to cancel <b>{childName}'s</b> session on{" "}
                <b>{session.sessionDate.format("dddd, D MMMM YYYY")}</b>?
              </Typography>
            )}
          </Box>

          <IconButton aria-label="close" className={classes.closeButton} onClick={(e) => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={0} justifyContent="center" alignItems="center" direction="column">
            <Spacer margin={5} />
            {/* DROPDOWN CANCELLATION REASON */}

            <FormControl required variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="cancellation-reason">Reason for cancellation</InputLabel>
              <Select
                className={classes.select}
                native
                displayEmpty={true}
                value={cancellationReason}
                defaultValue={null}
                fullWidth
                input={<OutlinedInput label="Reason for cancellation" />}
                onChange={(evt) => {
                  if (evt.target.value === "") {
                    setCancellationReason(null);
                  } else {
                    var id = parseInt(evt.target.value as string);
                    setCancellationReason(id);
                  }
                }}
              >
                <option></option>
                {cancellationReasons.map((r) => (
                  <option key={r.bookingCancellationReasonId} value={r.bookingCancellationReasonId}>
                    {r.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            {cancellationReason === 0 && (
              <FormControl required fullWidth>
                <TextField
                  label="Other reason"
                  placeholder={"Reason for cancellation"}
                  inputProps={{ maxLength: 80 }}
                  value={otherReason ?? null}
                  onChange={(e) => setOtherreason(e.target.value)}
                  variant="outlined"
                  required
                ></TextField>
              </FormControl>
            )}
            <Spacer margin={5} />
            {/* CANCELLATION FEE INFO */}

            {isLoading === false && bookingsToCancel.Count() === 0 && (
              <Typography variant="subtitle2" align="center" justifyContent="center">
                There are no bookings to cancel.
              </Typography>
            )}
            {chargeableBookings.Count() > 0 && ChargedBookings()}
            {nonChargeableBookings.Count() > 0 && NonChargedBookings()}
            <Spacer margin={5} />
            {bookingsToCancel.Count() > 0 && (
              <Center>
                <CancellationNote
                  fontSize={11}
                  days={minimumDaysToAvoidCancellationFee}
                  isHotCancellation={bookingsToCancel.Count() === 1 && bookingsToCancel.First().isHotCancellation}
                />
              </Center>
            )}
            <Spacer margin={10} />
            <ButtonPrimary
              disabled={isDisabled}
              className={className}
              classes={{ disabled: classes.disabledButton }}
              onClick={
                isDisabled
                  ? undefined
                  : () => {
                      if (schedule !== undefined) {
                        //Cancel Schedule
                        cancelSchedule(
                          dispatch,
                          customer_account_id!,
                          schedule.scheduleId,
                          cancellationReason ?? 0,
                          cancellationReason === 0 ? otherReason : undefined,
                          date
                        );

                        handleClose();
                      } else {
                        //Cancel single booking
                        cancelBooking(
                          dispatch,
                          customer_account_id!,
                          session!.bookingId,
                          cancellationReason ?? 0,
                          cancellationReason === 0 ? otherReason : undefined,
                          date
                        );
                        handleClose();
                      }
                    }
              }
              sx={{
                px: 7,
                py: 1.5,
                mb: 2,
                textWeight: "bold",
                textTransform: "none",
                color: "black!important",
                borderColor: "black",
                border: 1,
                fontWeight: "bold",
                background: careTypeColor,
              }}
            >
              Cancel {schedule ? "Schedule" : "Session"}
            </ButtonPrimary>
          </Grid>
        </DialogContent>
      </Box>
    </AnimatedDialog>
  );

  function NonChargedBookings() {
    const nonChargeableGroupedByFee = nonChargeableBookings.GroupBy((x) => x.feeType);

    return (
      <List sx={{ backgroundColor: "#5DE5BB4A", width: "100%" }}>
        {Object.keys(nonChargeableGroupedByFee).map((feeType, index) => {
          const allAvBookings = new QList(nonChargeableGroupedByFee[feeType]);
          return (
            <ListItem alignItems="flex-start">
              {/* <ListItemSecondaryAction sx={{ top: "20%" }}>
                
              </ListItemSecondaryAction> */}
              <ListItemAvatar sx={{ minWidth: "35px" }}>
                <CheckCircleIcon fontSize="small" htmlColor="var(--custom-color-green)" />
              </ListItemAvatar>
              <ListItemText
                disableTypography={true}
                primary={
                  <Stack direction={"row"} alignItems={"center"} justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight="bold">
                      {`${rollName} - Cancelled Sessions`} {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          xs: "0.9rem",
                        },
                      }}
                      fontWeight={"bold"}
                    >
                      <span className="small" style={{ textDecoration: "line-through" }}>
                        <NumericFormat
                          displayType="text"
                          prefix="$"
                          value={allAvBookings.Sum((x) => x?.feeAmount ?? 0)}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          suffix="*"
                        />
                      </span>
                    </Typography>
                  </Stack>
                }
                secondary={
                  <Stack direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: {
                          lg: "0.8rem",
                          xs: "0.7rem",
                        },
                      }}
                    >
                      {allAvBookings.Count() > 1 &&
                        `${allAvBookings.First()?.date.format("ddd DD MMM, YYYY")} to ${allAvBookings
                          .Last()
                          .date?.format("ddd DD MMM, YYYY")}`}
                      {allAvBookings.Count() === 1 && allAvBookings.First()?.date?.format("ddd DD MMM")}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          lg: "0.8rem",
                          xs: "0.7rem",
                        },
                      }}
                    >
                      <NumericFormat
                        displayType="text"
                        prefix={`${allAvBookings.Count()} x $`}
                        value={allAvBookings.First().feeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="*"
                      />
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  function ChargedBookings() {
    const chargeableGroupedByFee = chargeableBookings.GroupBy((x) => x.feeType);

    return (
      <List sx={{ backgroundColor: "#f4f4f6", width: "100%" }}>
        {Object.keys(chargeableGroupedByFee).map((feeType, index) => {
          const allAvBookings = new QList(chargeableGroupedByFee[feeType]);
          return (
            <ListItem alignItems="flex-start">
              <ListItemAvatar sx={{ minWidth: "35px" }}>
                <WarningAmber fontSize="small" htmlColor="var(--custom-color-error)" />
              </ListItemAvatar>
              <ListItemText
                disableTypography={true}
                primary={
                  <Stack direction={"row"} alignItems={"center"} justifyContent="space-between">
                    <Typography variant="subtitle2" fontWeight="bold" color={"var(--custom-color-error)"}>
                      {`${rollName} - Late Cancellation`}
                      {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                    </Typography>

                    <Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          xs: "0.9rem",
                        },
                      }}
                      variant="subtitle2"
                      color="var(--custom-color-error)"
                    >
                      <span className="small">
                        <NumericFormat
                          displayType="text"
                          prefix="$"
                          value={allAvBookings.Sum((x) => x?.feeAmount ?? 0)}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          suffix="*"
                        />
                      </span>
                    </Typography>
                  </Stack>
                }
                secondary={
                  <Stack direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: {
                          lg: "0.8rem",
                          xs: "0.7rem",
                        },
                        color: "var(--custom-color-error)",
                      }}
                    >
                      {allAvBookings.Count() > 1 &&
                        `${allAvBookings.First()?.date?.format("ddd DD MMM")} to ${allAvBookings
                          .Last()
                          .date?.format("ddd DD MMM")}`}
                      {allAvBookings.Count() === 1 && allAvBookings.First()?.date?.format("ddd DD MMM")}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: {
                          lg: "0.8rem",
                          xs: "0.7rem",
                        },
                      }}
                      color="var(--custom-color-error)"
                    >
                      <NumericFormat
                        displayType="text"
                        prefix={`${allAvBookings.Count()} x $`}
                        value={allAvBookings.First().feeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="*"
                      />
                    </Typography>
                  </Stack>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}
