import { createReducer } from "deox";

import {
  dueChargesLoaded,
  dueChargesLoading,
  failed,
  moreDueChargesLoaded,
  moreDueChargesLoading,
} from "./dueChargesStateActions";
import { BillingCycleSummary } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";

export interface DueChargesState {
  dueCharges: BillingCycleSummary[];
  isError: boolean;
  isLoading: boolean;
  isMoreLoading: boolean;
  isLoaded: boolean;
  total: number;
  page: number;
  isLastPage: boolean;
}

export const initialState: DueChargesState = {
  dueCharges: [],
  isMoreLoading: false,
  total: 0,
  isError: false,
  isLoading: false,
  page: 1,
  isLastPage: false,
  isLoaded: false,
};

const dueChargesReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    dueChargesLoaded,
    (state, action): DueChargesState => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      isError: false,
      dueCharges: action.payload.records,
      total: action.payload.total,
      page: action.meta,
      isLastPage: action?.payload?.records.length === 0 || action?.payload?.records.length >= action.payload.total,
    })
  ),
  handleAction(
    moreDueChargesLoaded,
    (state, action): DueChargesState => ({
      ...state,
      isLoading: false,
      isError: false,
      isMoreLoading: false,
      dueCharges: [...state.dueCharges, ...action.payload.records],
      total: action.payload.total,
      page: action.meta,
      isLastPage: action?.payload?.records.length === 0 || action?.payload?.records.length >= action.payload.total,
    })
  ),
  handleAction(
    dueChargesLoading,
    (state): DueChargesState => ({
      ...state,
      isLoading: true,
      isLoaded: false,
      isMoreLoading: false,
      isError: false,
    })
  ),
  handleAction(
    moreDueChargesLoading,
    (state): DueChargesState => ({
      ...state,
      isLoading: false,
      isMoreLoading: true,
      isError: false,
    })
  ),
  handleAction(
    failed,
    (state): DueChargesState => ({
      ...state,
      isError: true,
      isLoading: false,
      isMoreLoading: false,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default dueChargesReducer;
