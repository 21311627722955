import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Box from "@mui/material/Box/Box";
import Card from "@mui/material/Card/Card";
import CardActionArea from "@mui/material/CardActionArea/CardActionArea";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import { List } from "linqts";

import { AvatarInfo, Child, ChildAgeConstraint, SelectedChild } from "../../types/models";
import { ISelectedChild } from "../../types/types";
import { getChildDisplayName } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface IBookingChildSelectionProps {
  child: Child;
  selected: ISelectedChild | undefined;
  constraints: ChildAgeConstraint[];
  avatar: AvatarInfo | undefined;
  onChildSelectionChanged: (ageIsGreater: boolean) => void;
  classes: any;
  serviceId?: number | undefined;
  isBlocked: boolean;
  isDifferentService: boolean;
  isDisabled?: boolean | undefined;
  overlayBackground?: string | undefined;
}

export default function BookingChildSelection(props: IBookingChildSelectionProps) {
  const {
    child,
    constraints,
    avatar,
    onChildSelectionChanged,
    classes,
    selected,
    isDisabled,
    serviceId,
    isBlocked,
    isDifferentService,
    overlayBackground = "var(--youroshc-color-primary)",
  } = props;

  function isAgeGreaterThanMax(constraints: List<ChildAgeConstraint>) {
    return constraints.Where(
      (ct) =>
        ct !== undefined &&
        ct.isAgeGreaterThanMax(
          selected
            ? new SelectedChild(selected)
            : new SelectedChild({
                child: child,
                isSelected: false,
              })
        )
    );
  }
  const isSuspended = child.isSuspended ?? false;

  function isAgeLessThanMin(constraints: List<ChildAgeConstraint>) {
    return constraints.Any(
      (ct) =>
        ct !== undefined &&
        ct.isAgeLessThanMin(
          selected
            ? new SelectedChild(selected)
            : new SelectedChild({
                child: child,
                isSelected: false,
              })
        )
    );
  }

  const ageIsLess = isAgeLessThanMin(new List(constraints));
  const ageIsGreater = isAgeGreaterThanMax(new List(constraints)).Count() > 0;
  var hasMessage = ageIsLess || ageIsGreater || isSuspended || isBlocked || isDifferentService;
  return (
    <Card
      sx={{ position: "relative" }}
      className={
        selected
          ? classes.selected
          : isDifferentService || isBlocked || ageIsLess || isDisabled
          ? classes.different
          : classes.unselected
      }
    >
      {hasMessage && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            opacity: 1,
            right: 0,
            bgcolor: overlayBackground,
            padding: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Typography color={"black"} fontSize={"12px"}>
            {isDifferentService
              ? "Different Service"
              : ageIsLess
              ? "Under the minimum age required"
              : ageIsGreater
              ? "Over the maximum age accepted"
              : isBlocked
              ? "Booking cannot be made"
              : "Suspended"}
          </Typography>
        </Box>
      )}

      <CardActionArea
        sx={{ pt: hasMessage ? "20px" : "0px", height: 110 }}
        onClick={
          isDisabled
            ? undefined
            : () => {
                if (selected) {
                  onChildSelectionChanged(ageIsGreater);
                } else {
                  if (!ageIsLess && !isSuspended && !isBlocked) onChildSelectionChanged(ageIsGreater);
                }
              }
        }
      >
        <Grid container direction={"row"} alignItems="center" p={1}>
          <Grid item xs={2} lg={2}>
            <Grid container direction={"row"}>
              <AvatarDisplay info={avatar || { index: child?.avatarIndex }} size={35} />
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid container direction={"column"}>
              <Grid item>
                <Typography variant="h6" className={classes.childName}>
                  {getChildDisplayName(child)}
                </Typography>
                {/* <Stack direction="row" spacing={2}>
                  <CakeOutlinedIcon fontSize="small" />
                  <Typography variant="subtitle2">
                    {(child.birthday && dayjs(child.birthday).format("MMMM DD, YYYY")) ?? ""}
                  </Typography>
                </Stack> */}
                <Stack direction="row" spacing={2} pt={1}>
                  <LocationOnOutlinedIcon fontSize="small" />
                  <Typography variant="subtitle2"> {child.serviceName}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
