import { createReducer } from "deox";

import { INotificationSettings, NotificationCategory, NotificationChannel } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { NotificationSettingsStatus } from "./notificationActions";
import {
  loadNotificationError,
  loadNotificationPending,
  loadNotificationSuccess,
  saveNotificationError,
  saveNotificationInit,
  saveNotificationPending,
  saveNotificationSuccess,
} from "./notificationStateActions";

export interface NotificationSettingsState {
  status: NotificationSettingsStatus;
  payload: INotificationSettings | null;
  savingStatus: NotificationSettingsStatus;
}

export const initialState: NotificationSettingsState = {
  status: "Initial",
  payload: null,
  savingStatus: "Initial",
};

const notificationSettingsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    loadNotificationSuccess,
    (state, action): NotificationSettingsState => ({
      ...state,
      status: "Success",
      payload: action.payload,
    })
  ),
  handleAction(
    saveNotificationSuccess,
    (state, action): NotificationSettingsState => ({
      ...state,
      savingStatus: "Success",
      payload: {
        emailBookingOffers:
          state.payload &&
          action.payload === NotificationCategory.BookingOffers &&
          action.meta === NotificationChannel.Email
            ? !state.payload?.emailBookingOffers
            : state.payload?.emailBookingOffers ?? false,

        emailGeneral:
          state.payload && action.payload === NotificationCategory.General && action.meta === NotificationChannel.Email
            ? !state.payload?.emailGeneral
            : state.payload?.emailGeneral ?? false,
        emailHolidayClubMarketing:
          state.payload &&
          action.payload === NotificationCategory.HolidayClubMarketing &&
          action.meta === NotificationChannel.Email
            ? !state.payload?.emailHolidayClubMarketing
            : state.payload?.emailHolidayClubMarketing ?? false,
        emailInSessionActivities:
          state.payload &&
          action.payload === NotificationCategory.InSessionActivities &&
          action.meta === NotificationChannel.Email
            ? !state.payload?.emailInSessionActivities
            : state.payload?.emailInSessionActivities ?? false,
        emailIndustryThoughts:
          state.payload &&
          action.payload === NotificationCategory.IndustryThoughts &&
          action.meta === NotificationChannel.Email
            ? !state.payload?.emailIndustryThoughts
            : state.payload?.emailIndustryThoughts ?? false,
        smsBookingOffers:
          state.payload &&
          action.payload === NotificationCategory.BookingOffers &&
          action.meta === NotificationChannel.Sms
            ? !state.payload?.smsBookingOffers
            : state.payload?.smsBookingOffers ?? false,
        smsGeneral:
          state.payload && action.payload === NotificationCategory.General && action.meta === NotificationChannel.Sms
            ? !state.payload?.smsGeneral
            : state.payload?.smsGeneral ?? false,
        smsHolidayClubMarketing:
          state.payload &&
          action.payload === NotificationCategory.HolidayClubMarketing &&
          action.meta === NotificationChannel.Sms
            ? !state.payload?.smsHolidayClubMarketing
            : state.payload?.smsHolidayClubMarketing ?? false,
        smsInSessionActivities:
          state.payload &&
          action.payload === NotificationCategory.InSessionActivities &&
          action.meta === NotificationChannel.Sms
            ? !state.payload?.smsInSessionActivities
            : state.payload?.smsInSessionActivities ?? false,
        smsIndustryThoughts:
          state.payload &&
          action.payload === NotificationCategory.IndustryThoughts &&
          action.meta === NotificationChannel.Sms
            ? !state.payload?.smsIndustryThoughts
            : state.payload?.smsIndustryThoughts ?? false,

        printBookingOffers:
          state.payload &&
          action.payload === NotificationCategory.BookingOffers &&
          action.meta === NotificationChannel.Print
            ? !state.payload?.printBookingOffers
            : state.payload?.printBookingOffers ?? false,
        printGeneral:
          state.payload && action.payload === NotificationCategory.General && action.meta === NotificationChannel.Print
            ? !state.payload?.printGeneral
            : state.payload?.printGeneral ?? false,
        printHolidayClubMarketing:
          state.payload &&
          action.payload === NotificationCategory.HolidayClubMarketing &&
          action.meta === NotificationChannel.Print
            ? !state.payload?.printHolidayClubMarketing
            : state.payload?.printHolidayClubMarketing ?? false,
        printInSessionActivities:
          state.payload &&
          action.payload === NotificationCategory.InSessionActivities &&
          action.meta === NotificationChannel.Print
            ? !state.payload?.printInSessionActivities
            : state.payload?.printInSessionActivities ?? false,
        printIndustryThoughts:
          state.payload &&
          action.payload === NotificationCategory.IndustryThoughts &&
          action.meta === NotificationChannel.Print
            ? !state.payload?.printIndustryThoughts
            : state.payload?.printIndustryThoughts ?? false,
      },
    })
  ),
  handleAction(
    loadNotificationPending,
    (state, action): NotificationSettingsState => ({
      ...state,
      status: "Pending",
      payload: null,
    })
  ),
  handleAction(
    saveNotificationPending,
    (state, action): NotificationSettingsState => ({
      ...state,
      savingStatus: "Pending",
    })
  ),
  handleAction(
    loadNotificationError,
    (state, action): NotificationSettingsState => ({
      ...state,
      status: "Errored",
      payload: null,
    })
  ),
  handleAction(
    saveNotificationError,
    (state, action): NotificationSettingsState => ({
      ...state,
      savingStatus: "Errored",
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(
    saveNotificationInit,
    (state, action): NotificationSettingsState => ({
      ...state,
      savingStatus: "Initial",
    })
  ),
]);

export default notificationSettingsReducer;
