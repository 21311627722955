import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FormikErrors } from "formik";
import { makeStyles } from "tss-react/mui";

import { Child } from "../../types/models";
import { Contact, IChild } from "../../types/types";
import { isNullOrEmpty } from "../../utils/stringUtils";
import ReadOnlyFields from "../Common/Tooltip/ReadOnlyFields";

interface Props {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  ccmsContacts: Contact[];
  ccbSubmissionContactId: number | undefined;
  handleCCBContactChange: any;
  crnReadOnly?: boolean;
  initialChildren: Child[] | null;
}

const useStyles = makeStyles()((theme) => ({
  container: { paddingLeft: 0 },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: { marginBottom: theme.spacing(2) },
  ul: {
    marginBottom: theme.spacing(2),
  },
}));

function ChildCareSubsidy({
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  values,
  errors,
  ccmsContacts,
  ccbSubmissionContactId,
  handleCCBContactChange,
  crnReadOnly = false,
  initialChildren = null,
}: Props) {
  const { classes } = useStyles();

  var childReadOnly = initialChildren?.some((x) => !isNullOrEmpty(x.crn) || x.birthday !== undefined);

  var childReadOnly = initialChildren?.some((x) => !isNullOrEmpty(x.crn) || x.birthday !== undefined);

  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Child Care Subsidy</legend>
          <FormLabel component="legend" className={classes.formLabel}>
            Do you plan to claim the Child Care Subsidy?
          </FormLabel>
          <FormControl
            error={Boolean(touched.planToClaimChildCareSubsidy && errors.planToClaimChildCareSubsidy)}
            fullWidth
          >
            <RadioGroup name="planToClaimChildCareSubsidy" aria-label="child-care-subsidy">
              <FormControlLabel
                control={<Radio />}
                label="Yes"
                checked={values.planToClaimChildCareSubsidy === true}
                onChange={() => setFieldValue("planToClaimChildCareSubsidy", true)}
              />
              <FormControlLabel
                control={<Radio />}
                label="No"
                checked={values.planToClaimChildCareSubsidy === false}
                onChange={() => setFieldValue("planToClaimChildCareSubsidy", false)}
              />
            </RadioGroup>
            {touched && errors && (
              <FormHelperText error required>
                {errors.planToClaimChildCareSubsidy}
              </FormHelperText>
            )}
          </FormControl>
        </FormGroup>
        {values.planToClaimChildCareSubsidy === true && (
          <>
            {/*  <FormControl className={classes.formControl} variant="filled" fullWidth required>
              <TextField
                select
                onChange={(e) => handleCCBContactChange(Number(e.target.value))}
                id="ccbSubmissionContactId"
                label="which parent will be receiving ccs payment?"
                value={ccbSubmissionContactId || ""}
                onBlur={handleBlur("ccbSubmissionContactId")}
                helperText={touched.ccbSubmissionContactId ? errors.ccbSubmissionContactId : ""}
                error={touched.ccbSubmissionContactId && Boolean(errors.ccbSubmissionContactId)}
                variant="filled"
                fullWidth
                required
              >
                <MenuItem value="">Please select</MenuItem>
                {ccmsContacts.map((contact: Contact) => (
                  <MenuItem value={contact.customerContactId} key={contact.customerContactId}>
                    {contact.firstName + " " + contact.lastName}
                  </MenuItem>
                ))}
              </TextField>
                </FormControl> */}
            <FormGroup className={classes.formGroup}>
              <legend className={classes.formLegend}>
                Customer Reference Number (CRN) {(crnReadOnly || childReadOnly) && <ReadOnlyFields />}
              </legend>
              <TextField
                id="crn"
                label={`${values.firstName} ${values.lastName}`}
                type="text"
                value={values.crn}
                onChange={handleChange("crn")}
                onBlur={handleBlur("crn")}
                helperText={touched.crn ? errors.crn : ""}
                error={touched.crn && Boolean(errors.crn)}
                fullWidth
                className={classes.textField}
                required={values.planToClaimChildCareSubsidy === true}
                disabled={crnReadOnly}
                inputProps={{
                  maxLength: 10,
                }}
              />
              {values.children.length &&
                values.children.map((child: IChild, index: number) => {
                  var childCrnReadOnly = false;
                  var childDOBReadOnly = false;
                  var initialChild = initialChildren?.find((x) => x.childId === child.childId);
                  if (initialChild !== undefined) {
                    childCrnReadOnly = !isNullOrEmpty(initialChild.crn);
                    childDOBReadOnly = initialChild.birthday !== undefined;
                  }
                  return (
                    <Grid container key={`key${child.childId}`} alignItems="flex-end" spacing={3}>
                      <Box paddingTop={7}></Box>
                      <Grid item xs={12}>
                        <Typography>
                          <b>
                            {child.firstName} {child.lastName}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id={`crn${child.uniqueId}`}
                          label="CRN"
                          type="text"
                          value={child.crn === undefined ? "" : child.crn}
                          onChange={handleChange(`children[${index}].crn`)}
                          onBlur={handleBlur(`children[${index}].crn`)}
                          helperText={
                            touched.children !== undefined && touched.children[index] !== undefined
                              ? errors.children !== undefined &&
                                errors.children[index] !== undefined &&
                                (errors.children[index] as FormikErrors<IChild>).crn
                                ? (errors.children[index] as FormikErrors<IChild>).crn
                                : ""
                              : ""
                          }
                          error={
                            touched.children !== undefined &&
                            touched.children[index] !== undefined &&
                            errors.children !== undefined &&
                            errors.children[index] !== undefined &&
                            Boolean((errors.children[index] as FormikErrors<IChild>).crn)
                          }
                          fullWidth
                          className={classes.textField}
                          required={values.planToClaimChildCareSubsidy === true}
                          disabled={childCrnReadOnly}
                          inputProps={{
                            maxLength: 10,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            format="DD/MM/YYYY"
                            label="Date of Birth"
                            value={child.birthday || null}
                            minDate={dayjs().subtract(20, "year")}
                            maxDate={dayjs().subtract(3, "year")}
                            onChange={(birthday) => {
                              setFieldValue(`children[${index}].birthday`, birthday?.clone());
                            }}
                            // onBlur={handleBlur(`children[${index}].birthday`)}
                            slotProps={{
                              textField: {
                                helperText:
                                  touched.children !== undefined && touched.children[index] !== undefined
                                    ? errors.children !== undefined &&
                                      errors.children[index] !== undefined &&
                                      (errors.children[index] as FormikErrors<IChild>).birthday
                                      ? (errors.children[index] as FormikErrors<IChild>).birthday
                                      : ""
                                    : "",
                                error:
                                  touched.children !== undefined &&
                                  touched.children[index] !== undefined &&
                                  errors.children !== undefined &&
                                  errors.children[index] !== undefined &&
                                  Boolean((errors.children[index] as FormikErrors<IChild>).birthday),
                                required: values.planToClaimChildCareSubsidy === true,
                                id: `dob${child.uniqueId}`,
                                "aria-label": "change birthday date",
                              },
                            }}
                            disabled={childDOBReadOnly}
                          />
                        </LocalizationProvider>
                        {/* <KeyboardDatePicker
                          className={classes.textField}
                          format="DD/MM/YYYY"
                          margin="normal"
                          id={`dob${child.uniqueId}`}
                          label="Date of Birth"
                          value={child.birthday || null}
                          minDate={dayjs().subtract(20, "year")}
                          maxDate={dayjs().subtract(3, "year")}
                          onChange={(birthday) => {
                            setFieldValue(`children[${index}].birthday`, birthday?.clone());
                          }}
                          onBlur={handleBlur(`children[${index}].birthday`)}
                          fullWidth
                          KeyboardButtonProps={{
                            "aria-label": "change birthday date",
                          }}
                          required={values.planToClaimChildCareSubsidy === true}
                          disabled={childDOBReadOnly}
                          helperText={
                            touched.children !== undefined && touched.children[index] !== undefined
                              ? errors.children !== undefined &&
                                errors.children[index] !== undefined &&
                                (errors.children[index] as FormikErrors<IChild>).birthday
                                ? (errors.children[index] as FormikErrors<IChild>).birthday
                                : ""
                              : ""
                          }
                          error={
                            touched.children !== undefined &&
                            touched.children[index] !== undefined &&
                            errors.children !== undefined &&
                            errors.children[index] !== undefined &&
                            Boolean((errors.children[index] as FormikErrors<IChild>).birthday)
                          }
                        /> */}
                      </Grid>
                    </Grid>
                  );
                })}
            </FormGroup>
          </>
        )}
        {values.planToClaimChildCareSubsidy === false && (
          <>
            <strong>Reduce the cost of care with the Child Care Subsidy</strong>
            <p className="small">
              To find out if you are eligible, log into&nbsp;
              <Link href="https://my.gov.au/LoginServices/main/login" target="_blank">
                my.gov.au
              </Link>{" "}
              and complete your CCS Assessment. You will receive a notification from Centrelink if your claim has been
              assessed or approved.
            </p>
            <p className="small"> From there, ensure that:</p>
            <ul className={classes.ul}>
              <li className="small">
                Your children’s details are correct in our systems (particularly their date of birth and individual
                CRNs).
              </li>
              <li className="small">
                We have the details of the contact or parent claiming the subsidy. Then simply review and confirm your
                enrolments via&nbsp;
                <Link href="https://my.gov.au/LoginServices/main/login" target="_blank">
                  <strong>MyGov</strong>
                </Link>
              </li>
            </ul>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default ChildCareSubsidy;
