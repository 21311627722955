import { createReducer } from "deox";

import {
  SaveContactAction,
  SaveChildAction,
  ResetState,
  ResetContacts,
  ResetChildren,
  RegistrationCompletedAction,
  RegistrationErrorAction,
  RegistrationErrorClearAction,
  SetPossibleDuplicatedAccountAction,
  RegistrationIsCompleting,
  SetRequirePaymentConfiguration,
} from "./registerStateActions";
import { Contact, IChild, Document } from "../../../types/types";
import { SaveRegistrationDocumentAction, RemoveRegistrationDocumentAction } from "../document/documentStateActions";
import { purge } from "../purge/purgeStateActions";

export interface RegisterState {
  isInitialised: boolean;
  isCompleted: boolean;
  isCompleting: boolean;
  isRegistrationError: boolean;
  registrationError: any;
  contacts: Contact[];
  children: IChild[];
  documents: Document[];
  primary: Contact;
  possibleDuplicatedAccount: boolean;
  requirePaymentConfiguration: boolean;
}

const initialState: RegisterState = {
  isInitialised: false,
  isCompleted: false,
  isCompleting: false,
  isRegistrationError: false,
  registrationError: null,
  contacts: [],
  children: [],
  documents: [],
  primary: { uniqueId: "", firstName: "", lastName: "" },
  possibleDuplicatedAccount: false,
  requirePaymentConfiguration: true,
};

const registerReducer = createReducer(initialState, (handleAction) => [
  handleAction(ResetState, (state, { payload }) => ({
    ...state,
    contacts: payload.contacts.sort((a, b) => (a.isPrimary && !b.isPrimary ? -1 : 1)),
    primary: payload.contacts.find((contact) => contact.isPrimary) ?? { uniqueId: "", firstName: "", lastName: "" },
    children: payload.children,
    documents: payload.documents,
    possibleDuplicatedAccount: payload.possibleDuplicatedAccount,
    isInitialised: true,
  })),
  handleAction(ResetContacts, (state, { payload }) => ({
    ...state,
    contacts: payload.contacts,
  })),
  handleAction(ResetChildren, (state, { payload }) => ({
    ...state,
    children: payload.children,
  })),
  handleAction(SaveContactAction, (state, { payload }) => ({
    ...state,
    contacts: state.contacts.map((contact) =>
      contact.uniqueId === payload.uniqueId ? { ...contact, ...payload } : contact
    ),
    primary: state.contacts.find((contact) => contact.isPrimary) ?? { uniqueId: "", firstName: "", lastName: "" },
  })),
  handleAction(SaveChildAction, (state, { payload }) => ({
    ...state,
    children: state.children.map((child) => (child.uniqueId === payload.uniqueId ? { ...child, ...payload } : child)),
  })),
  handleAction(SaveRegistrationDocumentAction, (state, { payload }) => ({
    ...state,
    documents: [...state.documents, payload.document],
  })),
  handleAction(RemoveRegistrationDocumentAction, (state, { payload }) => ({
    ...state,
    documents: state.documents.filter((document) => document.uniqueId !== payload.uniqueId),
  })),
  handleAction(RegistrationCompletedAction, (state) => ({
    ...state,
    isCompleting: false,
    isCompleted: true,
    isRegistrationError: false,
  })),
  handleAction(RegistrationErrorAction, (state, { payload }) => ({
    ...state,
    isCompleting: false,
    isCompleted: false,
    isRegistrationError: true,
    registrationError: payload,
  })),
  handleAction(RegistrationErrorClearAction, (state) => ({
    ...state,
    isCompleting: false,
    isCompleted: false,
    isRegistrationError: false,
    registrationError: null,
  })),
  handleAction(RegistrationIsCompleting, (state) => ({
    ...state,
    isCompleting: true,
    isCompleted: false,
    isRegistrationError: false,
    registrationError: null,
  })),
  handleAction(SetPossibleDuplicatedAccountAction, (state, { payload }) => ({
    ...state,
    possibleDuplicatedAccount: payload.possibleDuplicatedAccount,
  })),
  handleAction(SetRequirePaymentConfiguration, (state, { payload }) => ({
    ...state,
    requirePaymentConfiguration: payload.requirePaymentConfiguration,
  })),
  handleAction(purge, () => initialState),
]);

export default registerReducer;
