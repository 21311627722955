import { Dayjs } from "dayjs";

import sessionApi from "./sessionApi";
import {
  LoadedRolls,
  LoadedRollsError,
  LoadedUnavailableDates,
  LoadedUnavailableDatesError,
  LoadingRolls,
  LoadingUnavailableDates,
} from "./sessionStateAction";
import { RollHttpResponse, SessionAvailabilityInfo } from "../../parentApi";

export function getRollsForService(dispatch: any, serviceId: number) {
  dispatch(LoadingRolls());
  sessionApi.getRollsForService(serviceId).then(
    (response: RollHttpResponse[] | null) =>
      response ? dispatch(LoadedRolls(response, serviceId)) : dispatch(LoadedRollsError()),
    (error: any) => dispatch(LoadedRollsError())
  );
}

export function getUnAvailableSessionDays(
  dispatch: any,
  customerId: number,
  from: Dayjs,
  to: Dayjs,
  rollIds: number[],
  childrenIds: number[]
) {
  dispatch(LoadingUnavailableDates());
  rollIds = Array.from(new Set(rollIds));
  sessionApi.getSessionsUnavailability(customerId, from, to, rollIds, childrenIds).then(
    (response: SessionAvailabilityInfo[] | null) =>
      response ? dispatch(LoadedUnavailableDates(response)) : dispatch(LoadedUnavailableDates([])),
    (error: any) => dispatch(LoadedUnavailableDatesError())
  );
}

export function getScheduleUnAvailableSessionDays(
  dispatch: any,
  customerId: number,
  scheduleId: string,
  from: Dayjs,
  to: Dayjs
) {
  dispatch(LoadingUnavailableDates());

  sessionApi.getSessionsUnavailabilityForScheduleEdit(customerId, scheduleId, from, to).then(
    (response: SessionAvailabilityInfo[] | null) =>
      response ? dispatch(LoadedUnavailableDates(response)) : dispatch(LoadedUnavailableDates([])),
    (error: any) => dispatch(LoadedUnavailableDatesError())
  );
}

export function getNextAvailableSession(rollId: number, year: number) {
  return sessionApi.getNextAvailableSession(rollId, year).then(
    (response: Dayjs | null) => response,
    (error: any) => null
  );
}
