import React from "react";

import styles from "./Headers.module.scss";

interface Props {
  heading?: string;
  subHeading?: string;
  children?: any;
}

export const RegistrationHeader = (props: Props) => {
  const { children, heading, subHeading } = props;
  return (
    <header className={styles["Registration-Header"]}>
      {heading && <h1 className={styles["Heading"]}>{heading}</h1>}
      {subHeading && <h2 className={`h5 ${styles["SubHeading"]}`}>{subHeading}</h2>}
      <div className={styles["Children"]}>{children}</div>
    </header>
  );
};
