import { useEffect } from "react";

import HistoryIcon from "@mui/icons-material/History";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PaymentIcon from "@mui/icons-material/Payment";
import PhoneIcon from "@mui/icons-material/Phone";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ListItemButton } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import saveAs, { FileSaverOptions } from "file-saver";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAppDispatch } from "../../store/hooks";
import { getCurrentPaymentMethod } from "../../store/modules/paymentMethod/paymentMethodActions";
import { downloadStatement, initReport } from "../../store/modules/statementReport/statementReportActions";
import { RootState } from "../../store/store";
import { formatCurrency } from "../../utils/helpers";
import { ButtonPrimary } from "../Common/Buttons/Buttons";
import Spacer from "../Common/Spacers/Spacers";

export default function BillingSection() {
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);

  const balance = useSelector((state: RootState) => state.balance.payload);
  const charges = useSelector((state: RootState) => state.dueCharges.dueCharges);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const file = useSelector((state: RootState) => state.statementReport.payload);
  const isDownloaded = useSelector((state: RootState) => state.statementReport.isDownloaded);

  const isDownloading = useSelector((state: RootState) => state.statementReport.isDownloading);
  const downloadClicked = () => {
    const cycleId = charges[0].billingCycleId;
    downloadStatement(dispatch, customer_account_id!, cycleId);
  };

  useEffect(() => {
    if (isDownloaded && file) {
      const options: FileSaverOptions = {
        autoBom: false,
      };

      saveAs(file, "report.pdf", options);
      initReport(dispatch);
    }
  }, [file, isDownloaded]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!currentPaymentMethod) {
      getCurrentPaymentMethod(dispatch, customer_account_id ?? 0);
    }
  }, [currentPaymentMethod, customer_account_id]);

  return (
    <Grid container spacing={2} direction="column" rowGap={1} sx={{ bgcolor: "background.paper", p: 1, pt: 1 }}>
      <Typography variant="h6" sx={{ textDecoration: "underline" }} component={Link} to={"/billing"}>
        <b>Billing and payments</b>
      </Typography>
      <Spacer margin={2} />
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        Amount due
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {formatCurrency(balance?.balance ?? 0)}
      </Typography>

      {charges && charges.length > 0 && (
        <Typography
          fontSize={12}
          color="white"
          sx={{ backgroundColor: "#B42931", color: "white", width: "fit-content", pr: 1, pl: 1, borderRadius: "5px" }}
        >
          Due on {charges[0].isOverDue ? "NOW" : dayjs(charges[0].dueDate).format("DD MMMM YYYY")}
        </Typography>
      )}

      <ButtonPrimary sx={{ paddingLeft: 5, paddingRight: 5 }} href={"/pay-now"}>
        <strong>Pay Now</strong>
      </ButtonPrimary>

      {currentPaymentMethod !== null && charges && charges.length > 0 && (
        <Typography variant="caption">
          You'll be charged automatically on {dayjs(charges[0].dueDate).format("DD MMMM YYYY")}.
        </Typography>
      )}

      <List
        disablePadding
        sx={{ width: "100%", bgcolor: "background.paper", fontSize: "0.8rem" }}
        subheader={
          <ListSubheader disableGutters sx={{ fontWeight: "bold", color: "black" }}>
            Manage your bills
          </ListSubheader>
        }
      >
        <ListItemButton disableGutters component={Link} to={"/pay-now"} hidden={true}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
          </Box>
          <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="Make advance payment" />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <KeyboardArrowRightIcon />
          </Box>
        </ListItemButton>
        <Divider component="li" variant="fullWidth" hidden={true} />
        <ListItemButton disableGutters hidden={true}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ListItemIcon>
              <PlayCircleOutlineIcon />
            </ListItemIcon>
          </Box>
          <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="Request payment extension" />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <KeyboardArrowRightIcon />
          </Box>
        </ListItemButton>
        <Divider component="li" variant="fullWidth" hidden={true} />

        <ListItemButton disableGutters hidden={true}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
          </Box>
          <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="Report a payment" />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <KeyboardArrowRightIcon />
          </Box>
        </ListItemButton>
        <Divider component="li" variant="fullWidth" hidden={true} />
        {/* {charges && charges.length > 0 && (
          <>
            <ListItemButton disableGutters>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ListItemIcon>
                  <HelpOutlineIcon />
                </ListItemIcon>
              </Box>
              <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="Query a bill" />
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <KeyboardArrowRightIcon />
              </Box>
            </ListItemButton>
            <Divider component="li" variant="fullWidth" />
          </>
        )} */}
        {charges && charges.length > 0 && (
          <>
            <ListItemButton disableGutters disabled={isDownloading} onClick={(event) => downloadClicked()}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ListItemIcon>
                  <SaveAltIcon />
                </ListItemIcon>
              </Box>

              <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="Download bill" />
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <KeyboardArrowRightIcon />
              </Box>
            </ListItemButton>
            <LinearProgress hidden={!isDownloading} />
            <Divider component="li" variant="fullWidth" />{" "}
          </>
        )}

        <ListItemButton disableGutters component={Link} to={"/billing"}>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
          </Box>

          <ListItemText disableTypography sx={{ fontSize: "1rem " }} primary="View previous bills" />

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <KeyboardArrowRightIcon />
          </Box>
        </ListItemButton>
      </List>
      {/* <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Balances />
        </Grid>
        <Grid item>
          <Button component={Link} to={"/billing"} style={{ marginLeft: -8 }}>
            <strong className={`color-accent-2 small underline`}>MORE BILLING</strong>
          </Button>
        </Grid>
      </Grid> */}
      {/* <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <Box paddingBottom={2} />
          <UcomingChargesListing />
        </Grid>
      </Grid> */}
    </Grid>
  );
}
