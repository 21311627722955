import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import FormAddChildren from "../../components/Forms/AddChildren/AddChildren";
import { LayoutAccent1 } from "../../layouts/Layouts";

function AddChildren() {
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage>
        <RegistrationHeader heading="Primary Information">
          <p>Please add the names of any children that will be attending Camp Australia.</p>
        </RegistrationHeader>
        <FormAddChildren />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default AddChildren;
