import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getIn } from "formik";

import { ConditionTypes, Document, IChild, IMedicalCondition, IRegistrationMedicalPlanAnswer } from "../../types/types";
import { OtherMedicalConditionQuestionId } from "../../utils/medicalConditionsHelper";
import { MedicalConditionsSelector } from "../Common/Controls/MedicalConditionsSelector";

interface Props {
  classes: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: IChild;
  errors: any;
  dispatch: any;
  customerAccountId?: number | undefined;
  medicalConditions?: IMedicalCondition[];
  documents?: Document[];
  selectedMedicalConditions?: number[];
  onMedicalConditionsChange?: (medicalConditionName: string, checked: boolean) => void;
}

function MedicalConditions({
  classes,
  setFieldValue,
  touched,
  values,
  errors,
  customerAccountId,
  medicalConditions,
  documents,
  selectedMedicalConditions,
  onMedicalConditionsChange,
}: Props) {
  const otherMedicalConditionsIds =
    medicalConditions?.filter((x) => x.conditionTypeId === ConditionTypes.Other).map((x) => x.conditionId) ?? [];

  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={12}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Medical Conditions</legend>
          <FormLabel component="legend" className={classes.formLabel}>
            Does your child have any medical conditions?
          </FormLabel>
          <FormControl error={Boolean(touched.hasMedicalConditions && errors.hasMedicalConditions)} fullWidth>
            <RadioGroup row={true}>
              <FormControlLabel
                value="true"
                label="Yes"
                control={<Radio />}
                checked={values.hasMedicalConditions === true}
                onChange={() => setFieldValue("hasMedicalConditions", true)}
              />
              <FormControlLabel
                value="false"
                label="No"
                control={<Radio />}
                checked={values.hasMedicalConditions === false}
                onChange={() => {
                  setFieldValue("medicalPlanAnswers", []);
                  setFieldValue("hasMedicalConditions", false);
                }}
              />
            </RadioGroup>
            <FormHelperText error={Boolean(touched.hasMedicalConditions && errors.hasMedicalConditions)}>
              {touched.hasMedicalConditions && errors.hasMedicalConditions ? errors.hasMedicalConditions : ""}
            </FormHelperText>
          </FormControl>
          {values.hasMedicalConditions === true ? (
            <Container className={classes.container}>
              <Box paddingTop={3} />
              <FormLabel component="legend" className={classes.formLabel}>
                Please check which medical conditions apply to the child:
              </FormLabel>
              <MedicalConditionsSelector
                id="medicalConditions"
                label="Medical Conditions"
                value={values.medicalConditions}
                planAnswers={values.medicalPlanAnswers}
                onChange={(e) => {
                  const checked = e.target.checked;
                  let selectedMedicalConditions;

                  const medicalConditionId = parseInt(e.target.value);
                  selectedMedicalConditions = values.medicalConditions ?? [];

                  if (checked) {
                    selectedMedicalConditions.push(medicalConditionId);
                  } else {
                    selectedMedicalConditions = selectedMedicalConditions.filter(
                      (x: number) => x !== medicalConditionId
                    );
                    let childAnswers =
                      values.medicalPlanAnswers?.filter((ans) => ans?.conditionId !== medicalConditionId) ?? [];

                    if (otherMedicalConditionsIds.includes(medicalConditionId)) {
                      selectedMedicalConditions = selectedMedicalConditions.filter(
                        (x: number) => !otherMedicalConditionsIds.includes(x)
                      );

                      childAnswers =
                        values.medicalPlanAnswers?.filter(
                          (ans) => !otherMedicalConditionsIds.includes(ans?.conditionId)
                        ) ?? [];
                    }

                    setFieldValue("medicalPlanAnswers", childAnswers);
                  }
                  setFieldValue("medicalConditions", selectedMedicalConditions);
                }}
                onOtherTextChange={(id, text) => {
                  var mdConditions = values.medicalConditions ?? [];
                  var mpAnswers = values.medicalPlanAnswers ?? [];
                  if (text && text.trim().length) {
                    var existingAnswer = mpAnswers.find(
                      (m) =>
                        m.conditionId === id &&
                        m.questionId === OtherMedicalConditionQuestionId &&
                        m.multipleAnswerIndex === 0
                    );
                    if (existingAnswer) {
                      existingAnswer.value = text;
                    } else {
                      existingAnswer = {
                        questionId: OtherMedicalConditionQuestionId,
                        conditionId: id,
                        value: text,
                        multipleAnswerIndex: 0,
                        freeText: "",
                      } as IRegistrationMedicalPlanAnswer;
                      mpAnswers.push(existingAnswer);
                    }
                    if (mdConditions.indexOf(id) < 0) {
                      mdConditions.push(id);
                    }
                  } else {
                    var index = mdConditions.indexOf(id);
                    if (index >= 0) {
                      mdConditions.splice(index, 1);
                    }
                    index = mpAnswers.findIndex((m) => m.conditionId === id);
                    if (index >= 0) {
                      mpAnswers.splice(index, 1);
                    }
                  }
                  setFieldValue("medicalPlanAnswers", mpAnswers);
                }}
                helperText={getIn(touched, "medicalConditions") ? getIn(errors, "medicalConditions") : ""}
                error={getIn(touched, "medicalConditions") && Boolean(getIn(errors, "medicalConditions"))}
              />
              {touched.medicalConditions && errors.medicalConditions && (
                <FormHelperText error>{errors.medicalConditions}</FormHelperText>
              )}
            </Container>
          ) : null}
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default MedicalConditions;
