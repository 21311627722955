import AccessTime from "@mui/icons-material/AccessTime";
import Alert from "@mui/lab/Alert";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";

import theme from "../../../theme";
import { Child, SelectedBookingEvent } from "../../../types/models";
import { getStyleColor } from "../../../utils/helpers";

interface Props {
  bookingEvent: SelectedBookingEvent;
}
const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    padding: theme.spacing(2),
    borderRadius: 3,
    marginBottom: theme.spacing(1),
  },
  button: {
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  pill: {
    maxWidth: 60,
    "@media screen and (min-width: 1280px)": {
      maxWidth: 200,
    },
  },
  image: {
    width: 60,
    height: 45,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "block",
    marginBottom: theme.spacing(1),
    borderRadius: 3,

    "@media screen and (min-width: 1280px)": {
      width: 200,
      height: 150,
    },
  },
}));

export default function ChildEventBookingCard(props: Props) {
  const { classes } = useStyles();
  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemAvatar style={{ paddingRight: theme.spacing(2) }}>
        <span
          aria-label={props.bookingEvent?.bookingEvent?.name}
          role="img"
          className={classes.image}
          style={{ backgroundImage: `url(${props.bookingEvent?.bookingEvent?.imageUrl})` }}
        ></span>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>{props.bookingEvent?.bookingEvent?.name}</strong>
            </Grid>
            <Grid item xs={12}>
              <span style={{ color: "black" }}>
                {props.bookingEvent?.bookingEvent?.datesText ??
                  props.bookingEvent?.bookingEvent?.dateAest?.format("ddd, D MMM")}
              </span>
              <Grid container alignItems="center">
                <AccessTime fontSize="small" style={{ marginRight: 10 }} />
                <span style={{ color: "black" }}>After School Care</span>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <strong>
                <NumericFormat
                  displayType="text"
                  value={props.bookingEvent?.bookingEvent?.cost}
                  decimalScale={2}
                  prefix="&nbsp;$"
                />
                &nbsp;per child*
              </strong>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="warning">
                Please ensure your child is booked into After School Care during the week of the 22nd – 26th March to
                secure their spot.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {props.bookingEvent?.addChildren?.map((child: Child, index: number) => (
                  <Grid item xs={12} key={index}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        {child?.firstName} {child?.lastName}
                      </Grid>
                      <Grid item>
                        <IoCheckmarkOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                {props.bookingEvent?.removeChildren?.map((child: Child, index: number) => (
                  <Grid item xs={12} key={index}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        {child?.firstName} {child?.lastName}
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center">
                          <IoCloseOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                          <span style={{ color: theme.palette.success.main }}>Cancellation</span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        }
      ></ListItemText>
    </ListItem>
  );
}
