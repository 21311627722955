import React from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";

export interface ILoadingDialogProps {
  message?: string;
  open: boolean;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const LoadingDialog = (props: ILoadingDialogProps): JSX.Element => {
  const { classes } = useStyles();
  return (
    <Backdrop open={props.open} className={classes.backdrop}>
      <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <h1 className="h5">{props?.message}</h1>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <CircularProgress color="primary" style={{ color: "black" }} />
          </Grid>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default LoadingDialog;
