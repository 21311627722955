import { useEffect, useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box/Box";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { List } from "linqts";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BookingCompleteDialog from "./BookingCompleteDialog";
import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import { useAppDispatch } from "../../store/hooks";
import { createCasualBookings, createVacBooking } from "../../store/modules/booking/bookingActions";
import { RootState } from "../../store/store";
import {
  AgeViolationReasonInfo,
  BookingDateInfo,
  ChildAgeConstraint,
  CreateCasualBookingsHttpRequest,
  EntityType,
  RollDetails,
  SelectedChild,
  SessionAvailability,
} from "../../types/models";
import { BookingPreview, ISessionAvailability, VacBookingInfo } from "../../types/types";
import {
  creditCardIsExpired,
  getAvailabilityReasonsText,
  getChildDisplayName,
  getStyleColor,
} from "../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../Common/Buttons/Buttons";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface IProps {
  formState: VacBookingInfo;
  classes: any;
  isAllowedToBook: boolean;
  isFriendlyFee: boolean;
  // loadUnAvailableDates: { (values: OshcBookingDetails): void; (arg0: OshcBookingDetails): void };
  pageState: {
    hasConflict: boolean;
    hasError: boolean;
    unAvailableDates: ISessionAvailability[];
    newlyAddedUnAvailableDates: ISessionAvailability[];
  };
  setPageState: React.Dispatch<
    React.SetStateAction<{
      hasConflict: boolean;
      hasError: boolean;
      unAvailableDates: ISessionAvailability[];
      newlyAddedUnAvailableDates: ISessionAvailability[];
    }>
  >;
}

export default function VACBookingSummary(props: IProps) {
  const { formState, classes, isAllowedToBook, pageState, setPageState } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(true);
  const saveState = useSelector((state: RootState) => state.createBooking);

  const [availableBookings, setAvailableBookings] = useState<{ [key: string]: BookingPreview[] }>({});
  const isSubmitting = saveState !== undefined && saveState.isSaving;
  const auth = useSelector((state: RootState) => state.auth);
  const customer_account_id = auth?.user?.profile.customer_account_id;

  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  const casualPreviews = useSelector((state: RootState) => state.bookingSaveCasualPreview.previewResult);
  const [ageViolationReasons, setAgeViolationReasons] = useState<AgeViolationReasonInfo[]>([]);
  const { servicePrograms } = useSelector((state: RootState) => state.servicePrograms);

  const [ccExpired, setCCExpired] = useState(false);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);

  const [showCompleted, setShowCompleted] = useState<{ show: boolean; success: boolean }>({
    show: false,
    success: false,
  });

  const unAvailableMatchedDates = pageState.unAvailableDates.filter((d) =>
    formState.dates.some((fd) => fd.program.rollId === d.rollId && fd.program.session.date.isSame(d.date, "date"))
  );

  useEffect(() => {
    if (casualPreviews !== null) populateAvailableBookings();
  }, [casualPreviews]);

  function populateAvailableBookings() {
    const programs = casualPreviews!
      .map((x) => x)
      .filter(
        (p) =>
          !pageState.unAvailableDates.some(
            (du) =>
              du?.rollId === p?.rollId &&
              du?.isAvailableForCasualBooking === false &&
              du?.date.isSame(p?.date as dayjs.Dayjs, "date")
          )
      );
    /*
       var bookings = previewResult!.bookings.where((p) => !unavailableDates.any((du) => du.rollId == p.rollId && du.isAvailableForCasualBooking == false && du.date.isSameDate(p.date))).toList();
    var bookingGroupedByRoll = groupBy(bookings, (b) => '${b.rollId}+${b.date.formatDate("yyyy-MM-dd")}');

      */ // newBookings.GroupBy((b) => b?.feeType as FeeType);
    var bookingGroupedByRoll = new List(programs).GroupBy((b) => `${b?.rollId}+${b?.date.format("YYYY-MM-DD")}`);

    // groupBy(programs, (b) => `${b.rollId}+${b.date.format("YYYY-MM-DD")}`);
    setAvailableBookings(bookingGroupedByRoll);
  }

  useEffect(() => {
    if (currentPaymentMethod) {
      setCCExpired(creditCardIsExpired(currentPaymentMethod));
    }
  }, [currentPaymentMethod]);
  function getMatchingUnAvailableDates(unAvailableDates: ISessionAvailability[] | null) {
    if (!unAvailableDates) {
      return new Array<ISessionAvailability>();
    }
    const casualDates = new List(servicePrograms?.map((d) => d) ?? []);
    return new List(unAvailableDates)
      .Where(
        (ud) =>
          ud !== undefined &&
          !ud.isAvailableForCasualBooking &&
          casualDates.Any((cd) => cd !== undefined && cd.rollId === ud.rollId && cd.date.isSame(ud.date, "date"))
      )
      .ToArray();
  }

  useEffect(() => {
    const violations = new Array<AgeViolationReasonInfo>();
    formState.children.forEach((c) => {
      var rollViolations = new List(
        formState.dates.map(
          (d) =>
            new ChildAgeConstraint({
              rollDate: d.program.date,
              session: { ...d.program.session, isHolidayClub: true, isOhsc: false },
            })
        )
      )
        .Where((d) => d !== undefined && d.isAgeGreaterThanMax(new SelectedChild(c)))
        .Select((d) => d.session.rollId)
        .Distinct();
      rollViolations.ForEach((rId) => {
        violations.push(new AgeViolationReasonInfo(c.child.childId as number, rId as number, c.reason as string));
      });
    });

    setAgeViolationReasons(violations);
  }, [formState.children, formState.dates]);

  useEffect(() => {
    if (formState.children.length === 0 || formState.dates.length === 0)
      setPageState({
        hasConflict: false,
        hasError: false,
        unAvailableDates: [],
        newlyAddedUnAvailableDates: [],
      });
  }, [formState.dates, formState.children]);
  useEffect(() => {

    setShowCompleted({
      show: saveState.isErrorState || saveState.isSuccess,
      success: saveState?.isSuccess,
    });
    if (saveState && saveState.isErrorState) {
      if (saveState.errorResponse && saveState.errorResponse.info) {
        var updatedUnAvailableDates = saveState.errorResponse.info.map((d: any) =>
          SessionAvailability.fromJson(d)
        ) as SessionAvailability[];
        var newUnAvailableDates = new Array<SessionAvailability>();
        for (var sess of updatedUnAvailableDates) {
          // eslint-disable-next-line no-loop-func
          if (pageState.unAvailableDates.findIndex((d) => d.rollId === sess.rollId && d.date.isSame(sess.date)) < 0) {
            newUnAvailableDates.push(sess);
          }
        }
        setPageState({
          hasError: false,
          hasConflict: true,
          unAvailableDates: getMatchingUnAvailableDates(updatedUnAvailableDates),
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      } else {
      }
    } else {
      setPageState({
        hasError: false,
        hasConflict: false,
        unAvailableDates: [],
        newlyAddedUnAvailableDates: [],
      });
    }
  }, [saveState]);
  // function getTotalFee() {
  //   return (
  //     formState.children.length *
  //     availableBookings.Sum((x) =>
  //       isFriendlyFee || !x?.isCasualFee ? x?.session.primaryFeeAmount ?? 0 : x.session.casualFeeAmount ?? 0
  //     )
  //   );
  // }

  function hasDiscount() {
    return (
      formState.children.length > 0 &&
      formState.dates.length > 0 &&
      casualPreviews !== null &&
      casualPreviews!.some((p) => p.multiChildrenDiscount > 0)
    );
  }

  function getConstraints() {
    return formState.dates.map(
      (d) =>
        new ChildAgeConstraint({
          rollDate: d.program.date,
          session: new RollDetails(d.program.session),
          activityCode: d.program.activityCode,
        })
    );
  }

  function isAgeGreaterThanMax(constraints: ChildAgeConstraint[], child: SelectedChild) {
    return constraints.filter((ct) => ct !== undefined && ct.isAgeGreaterThanMax(child));
  }

  function hasMaxAgeViolation(): boolean {
    const constraints = getConstraints();
    if (constraints.length === 0 || formState.children.length === 0) return false;
    return formState.children.some(
      (c) =>
        isAgeGreaterThanMax(constraints, new SelectedChild(c)).length > 0 &&
        (c.reason === undefined || c.reason === null || c.reason.trim() === "")
    );
  }

  function CannotConfirm(): boolean {
    var violatesMaxAge = hasMaxAgeViolation();

    var inValidSession = formState.dates.some((d) => {
      const unvDate = pageState.unAvailableDates.find(
        (ud) => ud.date.isSame(d.program.date, "date") && !ud.isAvailableForCasualBooking
      );
      return unvDate !== undefined;
    });

    const disabled =
      isSubmitting ||
      inValidSession ||
      violatesMaxAge ||
      pageState.unAvailableDates == null ||
      formState.service === undefined ||
      formState.children == null ||
      formState.children.length === 0 ||
      Object.keys(availableBookings).length === 0;

    return disabled;
  }
  return (
    <Grid
      container
      direction={"column"}
      justifyContent="space-between"
      alignContent={"space-between"}
      className={classes.sectionVac}
      padding={2}
      sx={{ bgcolor: "background.paper", minHeight: "500px" }}
    >
      <Grid container direction={"column"} rowGap={2}>
        <Typography variant="h5" fontWeight={"bold"}>
          Booking Summary
        </Typography>

        <Grid container>
          <Divider
            orientation="vertical"
            flexItem
            color="primary"
            sx={{
              width: 4,
              borderRightWidth: 5,
              alignContent: "center",
              margin: 1,
              borderRadius: 5,
              backgroundColor: "var(--custom-color-rocketeer)!important",
            }}
          />
          <Grid item xs={10}>
            <Grid container p={1} alignItems="flex-start" direction="column">
              <Typography variant="subtitle2">Care Type</Typography>
              <img src={RocketeersLogo} alt="Rocketeers Logo" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
        {formState.service && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1}>
              <PlaceOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2">{formState.service.serviceName}</Typography>
            </Grid>
          </Grid>
        )}

        {formState.dates.length > 0 && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1}>
              <CalendarTodayIcon fontSize="small" />
              <Stack
                direction="row"
                spacing={1}
                className={classes.stack}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {Array.from(new Set(formState.dates.map((x) => x.program.date))).map((cd, index) => {
                  return (
                    <Typography key={index} variant="subtitle2">
                      {cd.format("MMM DD, YYYY")}
                    </Typography>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        )}
        {formState.children.length > 0 && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1} flexWrap="wrap">
              {/* <Stack direction="row" spacing={2} flexWrap="wrap"> */}
              <PersonOutlineIcon fontSize="small" />
              {formState.children.map((child) => {
                const avatar = avatars.find((x) => x.entityId === child.child.childId?.toString());
                return (
                  <Stack direction="row" key={child.child.childId} spacing={1} className={classes.stack}>
                    <AvatarDisplay info={avatar || { index: child.child.avatarIndex }} size={25} />
                    <Typography variant="subtitle2">{getChildDisplayName(child.child)}</Typography>
                  </Stack>
                );
              })}
              {/* </Stack> */}
            </Grid>
          </Grid>
        )}
        {unAvailableMatchedDates.length > 0 && (
          <Grid item xs={12}>
            {displayValidationErrors()}
          </Grid>
        )}
        {formState.dates.length > 0 && (
          <Grid item xs={12}>
            <Accordion
              elevation={0}
              expanded={expanded}
              sx={{ backgroundColor: "#F4F4F6" }}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary sx={{ backgroundColor: "#F4F4F6" }} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1" sx={{ textDecoration: "underline" }}>
                  {expanded ? "Hide booking details" : "Show booking details"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ bgcolor: "#F4F4F6" }}>
                <Grid container spacing={2} direction="column">
                  {formState.children.length > 0 &&
                    Object.keys(availableBookings).map((feeType, index) => {
                      const allAvBookings = availableBookings[feeType];
                      const firstItem = allAvBookings[0];

                      return (
                        <Grid item xs={12} key={index}>
                          <Grid container direction="row" columnGap={1}>
                            <CalendarTodayIcon fontSize="small" />
                            <Stack direction="column">
                              <Typography variant="subtitle2" fontWeight="bold">
                                {servicePrograms?.find(
                                  (x) => x.rollId === firstItem.rollId && x.session.date.isSame(firstItem.date)
                                )?.programName ?? ""}
                              </Typography>
                              <Typography variant="subtitle2">
                                {allAvBookings.length} session x{" "}
                                <span className="small">
                                  <NumericFormat
                                    displayType="text"
                                    prefix="$"
                                    value={firstItem.fee}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="*"
                                  />
                                </span>
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}

                  {formState.children.length === 0 &&
                    formState.dates.length > 0 &&
                    formState.dates.map((dt, index) => {
                      return (
                        <Grid item xs={12} key={index}>
                          <Grid container direction="row" columnGap={1}>
                            <CalendarTodayIcon fontSize="small" />
                            <Stack direction="column">
                              <Typography variant="subtitle2" fontWeight="bold">
                                {dt.program.programName}
                              </Typography>
                              <Typography variant="subtitle2">
                                0 session x{" "}
                                <span className="small">
                                  <NumericFormat
                                    displayType="text"
                                    prefix="$"
                                    value={dt.program.session.casualFeeAmount ?? 0}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix="*"
                                  />
                                </span>
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      <Grid container direction={"row"} justifyContent="space-between" pt={5}>
        {/* <Grid item>
          <strong>TOTAL</strong>
        </Grid>
        <Grid item>
          <strong>
            <NumericFormat
              displayType="text"
              prefix="$"
              value={getTotalFee()}
              decimalScale={2}
              fixedDecimalScale={true}
              suffix="*"
            />
          </strong>
        </Grid> */}

        <Grid item xs={12}>
          <Typography variant="caption">*Prices exclude available Child Care Subsidy (CCS)</Typography>
        </Grid>

        {hasDiscount() && (
          <Grid item xs={12}>
            <Typography variant="caption">
              **<b>Rocketeer</b> bookings are eligible for multi children’s discount. The discount for eligible bookings
              gets applied to the fortnightly statement
            </Typography>
          </Grid>
        )}

        <Grid item>
          <ButtonPrimary
            style={{
              backgroundColor: getStyleColor("--custom-color-rocketeer"),
              color: "black",
              fontWeight: "bold",
            }}
            disabled={CannotConfirm() || ccExpired || !isAllowedToBook}
            onClick={() => {
              const casReq = new CreateCasualBookingsHttpRequest(
                formState.children.map((c) => c.child.childId as number),
                formState.dates.map((cd) => new BookingDateInfo(cd.program.rollId, cd.program.date)),
                pageState.unAvailableDates.map((d) => new BookingDateInfo(d.rollId, d.date)),
                ageViolationReasons
              );
             
              createVacBooking(dispatch, customer_account_id as number, casReq,  new List(formState.dates)
              .Select((p) => p.program)
              .ToArray(), new List(formState.children).Select(c=>c.child).ToArray());
            }}
          >
            Book Now
          </ButtonPrimary>
        </Grid>
        <Grid item>
          <ButtonSecondary
            onClick={() => {
              navigate("/");
            }}
          >
            <strong>Cancel</strong>
          </ButtonSecondary>
        </Grid>
      </Grid>
      <BookingCompleteDialog
        color="var(--custom-color-rocketeer)"
        handleClose={() => {
          setShowCompleted({ ...showCompleted, show: false });
        }}
        open={showCompleted.show}
        isSuccessful={showCompleted.success}
        title={
          pageState.hasConflict
            ? saveState.errorResponse?.message ?? "Some sessions are no longer available:"
            : pageState.hasError
            ? saveState.errorResponse?.message ?? ""
            : ""
        }
        onViewClicked={() => {}}
        onBack={() => {
          navigate("/");
        }}
        errorDetails={pageState.hasConflict && noLongerAvailableSessions()}
      />
    </Grid>
  );
  function availabilityErrors() {
    return (
      <MuiList disablePadding={true}>
        {unAvailableMatchedDates.map((unAvailableDate, index) => {
          return (
            <Tooltip
              arrow
              title={getAvailabilityReasonsText(unAvailableDate.reasonForCasualBooking)}
              placement="top-end"
            >
              <ListItem disableGutters={true} disablePadding={true} key={index}>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon fontSize="small" sx={{ fontSize: 10, color: "black" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={`${unAvailableDate.date.format("dddd MMMM DD, YYYY")} - ${getRollName(
                    unAvailableDate.rollId
                  )}`}
                />
              </ListItem>
            </Tooltip>
          );
        })}
      </MuiList>
    );
  }
  function displayValidationErrors() {
    return (
      <Accordion className={classes.accordionError} elevation={1}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ width: "100%" }}>
            <Typography variant="body1" fontWeight={"bold"}>
              The following {unAvailableMatchedDates.length} sessions are unable to be booked
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>{availabilityErrors()}</AccordionDetails>
      </Accordion>
    );
  }
  function getRollName(rollId: number) {
    return formState.dates.find((x) => x.program.rollId === rollId)?.program.programName ?? "";
  }

  function noLongerAvailableSessions() {
    return (
      <Grid container spacing={2} justifyContent="center" justifyItems={"center"}>
        {pageState.newlyAddedUnAvailableDates.map((d, i) => {
          return (
            <Grid item xs={12} key={i}>
              <Grid container direction="row" columnGap={1} justifyContent="center">
                <Typography variant="subtitle2" fontSize={14}>
                  <b> {d.date.format("ddd MMM DD,YYYY")}</b>
                </Typography>
                <Typography fontSize={14} variant="subtitle2" fontWeight={"bold"}>
                  {/* - {getRollSessionType(d.rollId)} */}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
