import { createActionCreator } from "deox";
export const reportDownloading = createActionCreator(
  "reportDownloading",
  (resolve) => (bCycleId: number) => resolve(bCycleId)
);
export const reportDownloaded = createActionCreator(
  "reportDownloaded",
  (resolve) => (response: Blob) => resolve(response)
);

export const reportError = createActionCreator("reportError", (resolve) => (error: string) => resolve(error));
export const reportInit = createActionCreator("reportInit");
