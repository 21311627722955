import { createActionCreator } from "deox";

import { RegistrationStatus } from "../../../types/types";

export const signupUpdateStatus = createActionCreator(
  "signupUpdateStatus",
  (resolve) => (registrationStatus: RegistrationStatus, email: string) => resolve({ registrationStatus, email })
);

export const signupVerifyError = createActionCreator("signupVerifyError");

export const sendOtpEmailError = createActionCreator("sendOtpEmailError");

export const sendOtpEmailSuccess = createActionCreator("sendOtpEmailSuccess");

export const sendOtpEmailReset = createActionCreator("sendOtpEmailReset");
