import * as Yup from "yup";

import BankAccountSchema from "../../components/Payment/BankAccountSchema";
import CreditCardSchema from "../../components/Payment/CreditCardSchema";
const PaymentMethodSchema = Yup.object().shape({
  account: Yup.object().when("method", {
    is: "BankAccount",
    then: BankAccountSchema,
  }),
  card: Yup.object().when("method", {
    is: "CreditCard",
    then: CreditCardSchema,
  }),
  termsAccepted: Yup.bool().oneOf([true], "You must agree to the Terms and conditions"),
});
//yup.bool().oneOf([true], 'Field must be checked')

export default PaymentMethodSchema;
