import React, { useEffect } from "react";

import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { acknowledgeAdditionalNeeds } from "../../../store/modules/child/childActions";
import { TermsAndConditionsStatus } from "../../../store/modules/termsAndConditions/termsAndConditionsStateActions";
import { RootState } from "../../../store/store";
import { ChildBookingStatus, IRegistrationMedicalPlanAnswer } from "../../../types/types";
import { isBlockingAnswer } from "../../../utils/medicalConditionsHelper";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";
import { AnimatedDialog } from "../../Common/Dialogs/AnimatedDialog";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: "1rem",
    paddingBottom: 0,
  },
  dialogContent: {
    maxHeight: 400,
  },
}));

function BookingOnHoldMessage() {
  const { classes } = useStyles();

  const dispatch = useDispatch();

  const termsStatus = useSelector((state: RootState) => state.termsAndConsitions).status;
  const isLoadingChildren = useSelector((state: RootState) => state.child.isLoadingChildren);

  const customerAccountId = useSelector((state: RootState) => state.customer.customer?.customerAccountId);
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);

  const completedRegistration = useSelector((state: RootState) => state.register);

  const [, setLocalStatus] = React.useState<TermsAndConditionsStatus>("Initial");
  const [showTerms, setShowTerms] = React.useState(false);
  const [bookingOnHoldMessageAcknowledgedLocal, setbookingOnHoldMessageAcknowledgedLocal] = React.useState(true);

  useEffect(() => {
    setLocalStatus(termsStatus);
    if (termsStatus === "Required") {
      setShowTerms(true);
    } else if (termsStatus === "Accepted") {
      setShowTerms(false);
    }
  }, [termsStatus]);

  useEffect(() => {
    setTimeout(() => {
      if (!isLoadingChildren && children) {
        var showWarning = children.some(
          (c) =>
            c.childBookingStatus?.toString().toLowerCase() === ChildBookingStatus.BlockedForAllServices.toLowerCase() &&
            !(c.acknowledgedStatus ?? false)
        );

        if (!showWarning) {
          if (completedRegistration != null && completedRegistration.children.length > 0) {
            for (let child of children.filter((x) => !(x.acknowledgedStatus ?? false))) {
              var registrationChildWithAdditionalNeeds = completedRegistration.children.find(
                (x) =>
                  x.firstName === child.firstName &&
                  x.lastName === child.lastName &&
                  x.medicalPlanAnswers !== null &&
                  x.medicalPlanAnswers?.some((x: IRegistrationMedicalPlanAnswer) => {
                    return isBlockingAnswer(x);
                  })
              );
              if (registrationChildWithAdditionalNeeds != null) {
                showWarning = true;
                break;
              }
            }
          }
        }
        setbookingOnHoldMessageAcknowledgedLocal(!showWarning);
      } else {
        setbookingOnHoldMessageAcknowledgedLocal(true);
      }
    }, 1000);
  }, [children, isLoadingChildren]);

  return (
    <AnimatedDialog open={!showTerms && !bookingOnHoldMessageAcknowledgedLocal} style={{ marginTop: 95 }}>
      <DialogTitle className={classes.dialogTitle}>
        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              acknowledgeAdditionalNeeds(dispatch, customerAccountId!);
            }, 500);
          }}
        >
          {() => (
            <Form noValidate autoComplete="off">
              Thank you for registering with Camp Australia!<br></br> <h2>* BEFORE YOU BOOK...</h2>
              <br></br>As you have identified that your child(ren) has individual requirements, one of our
              representatives will be in touch with you within 2 business days to discuss the individual needs of your
              child(ren) before you make a booking with us. This will ensure our services will be notified and prepared
              to cater for your child(ren)'s needs in the best possible way. Thank you for your patience and
              understanding. We look forward to getting in touch soon. <br></br>
              <br></br>Kind regards,<br></br> Camp Australia
              <div style={{ textAlign: "center" }}>
                <ButtonPrimary type="submit">
                  <strong>I Understand</strong>
                </ButtonPrimary>
              </div>
            </Form>
          )}
        </Formik>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}></DialogContent>
    </AnimatedDialog>
  );
}

export default BookingOnHoldMessage;
