import { PageableBillingCycleSummary } from "../../../types/types";
import { PageableObjectOfBillingCycleSummaryModel } from "../../parentApi";
import apiPayment from "./pastChargesApi";
import { failed, morePastChargesLoading, pastChargesLoaded, pastChargesLoading } from "./pastChargesStateActions";

export function loadPastCharges(dispatch: any, customerAccountId: number, pageSize: number, pageIndex: number) {
  dispatch(pastChargesLoading());
  apiPayment.getBillingCycles(customerAccountId, pageIndex, pageSize, false).then(
    (response: PageableObjectOfBillingCycleSummaryModel | null) => {
      return dispatch(pastChargesLoaded(response ?? ({} as PageableBillingCycleSummary), pageIndex));
    },
    (error: any) => dispatch(failed())
  );
}

export function loadMore(dispatch: any, customerAccountId: number, pageSize: number, pageIndex: number) {
  dispatch(morePastChargesLoading());
  apiPayment.getBillingCycles(customerAccountId, pageIndex, pageSize, false).then(
    (response: PageableObjectOfBillingCycleSummaryModel | null) => {
      return dispatch(pastChargesLoaded(response ?? ({} as PageableBillingCycleSummary), pageIndex));
    },
    (error: any) => dispatch(failed())
  );
}
