import { createActionCreator } from "deox";

import { IChild, Contact, Document } from "../../../types/types";

export const ResetState = createActionCreator(
  "ResetState",
  (resolve) => (contacts: Contact[], children: IChild[], documents: Document[], possibleDuplicatedAccount: boolean) =>
    resolve({ contacts, children, documents, possibleDuplicatedAccount })
);

export const ResetContacts = createActionCreator(
  "ResetContacts",
  (resolve) => (contacts: Contact[]) => resolve({ contacts })
);

export const ResetChildren = createActionCreator(
  "ResetChildren",
  (resolve) => (children: IChild[]) => resolve({ children })
);

export const SaveContactAction = createActionCreator(
  "SaveContactAction",
  (resolve) => (contact: Contact) => resolve(contact)
);

export const SaveChildAction = createActionCreator("SaveChildAction", (resolve) => (child: IChild) => resolve(child));

export const RegistrationIsCompleting = createActionCreator("RegistrationIsCompleting", (resolve) => () => resolve());

export const RegistrationCompletedAction = createActionCreator(
  "RegistrationCompletedAction",
  (resolve) => () => resolve()
);

export const RegistrationErrorAction = createActionCreator(
  "RegistrationErrorAction",
  (resolve) => (error: any) => resolve({ error })
);

export const RegistrationErrorClearAction = createActionCreator(
  "RegistrationErrorClearAction",
  (resolve) => () => resolve()
);

export const SetPossibleDuplicatedAccountAction = createActionCreator(
  "SetPossibleDuplicatedAccountAction",
  (resolve) => (possibleDuplicatedAccount: boolean) => resolve({ possibleDuplicatedAccount })
);

export const SetRequirePaymentConfiguration = createActionCreator(
  "SetRequirePaymentConfiguration",
  (resolve) => (requirePaymentConfiguration: boolean) => resolve({ requirePaymentConfiguration })
);
