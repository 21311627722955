import React from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { IoCallOutline, IoMailOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import styles from "./YourServices.module.scss";
import { ButtonAccent3, ButtonWhite } from "../../components/Common/Buttons/Buttons";
import { CloudWhite } from "../../components/Common/Shapes/Shapes";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import { LocalOpenHours, LocalWeekendOpenHours } from "../../utils/timeUtils";

const useStyles = makeStyles()((theme) => {
  return {
    card: {
      backgroundColor: "white",
      border: "1px solid black",
    },
  };
});

function YourServices() {
  const { classes } = useStyles();

  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);

  return (
    <LayoutAccent1>
      <div className={styles.YourServices}>
        <div className={styles["YourServices-Card"]}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <h1 className="h2" style={{ marginTop: 0 }}>
                Your Services
              </h1>
              <p>
                Should you have an emergency during our session times, you can contact the service directly. Please note
                that the team need to focus their time on engaging with the children, so if it is not an emergency,
                please contact our Customer Care Team.
              </p>
            </Grid>
          </Grid>
          {servicesByCustomer && servicesByCustomer.length > 0 && (
            <Grid item xs={12}>
              <Grid container direction="row" spacing={3}>
                {servicesByCustomer
                  .sort((a, b) => a.serviceName.localeCompare(b.serviceName))
                  .map((service, index) => {
                    const serviceNumber = service.mobile || service.phone;
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={12} md={4}>
                          <Card className={classes.card} elevation={0}>
                            <CardContent>
                              <strong>{service.serviceName}</strong>
                              <br />
                              <span>{service.primaryAddressStreet}</span>
                              <br />
                              <span>{`${service.primaryAddressSuburb?.toUpperCase()}, ${service.primaryAddressPostcode?.toUpperCase()}`}</span>
                            </CardContent>
                            <CardActions>
                              {serviceNumber && (
                                <Button
                                  aria-label="Call"
                                  startIcon={<IoCallOutline size={24} />}
                                  href={`tel:${serviceNumber}`}
                                >
                                  <strong>Call {serviceNumber}</strong>
                                </Button>
                              )}
                            </CardActions>
                          </Card>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </Grid>
          )}
        </div>
        <div className={styles["YourServices-Contact"]}>
          <CloudWhite style={{ position: "absolute", top: -140, left: -140 }} />
          <div className={styles["YourServices-Contact-Copy"]}>
            <h2 className="h3">
              Contact Your <br />
              Customer Care Team
            </h2>
            <p>
              Our hours of operation are <br />
              {LocalOpenHours()} <br />
              {LocalWeekendOpenHours()} <br />
              Closed on National Public Holidays.
            </p>
            <br />
            <ButtonWhite
              variant="outlined"
              aria-label="Call"
              startIcon={<IoMailOutline size={32} />}
              href={`mailto:oshc@campaustralia.com.au`}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              <strong>oshc@campaustralia.com.au</strong>
            </ButtonWhite>
            <br />
            <ButtonAccent3 href="tel:1300 105 343">
              <strong>Call 1300 105 343</strong>
            </ButtonAccent3>
          </div>
        </div>
      </div>
    </LayoutAccent1>
  );
}

export default YourServices;
