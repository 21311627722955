import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { List } from "linqts";

import { Child, SelectedRollDate, SelectedRollDay } from "../types/models";
import {
  CustomerAccount,
  DayOfWeek,
  IChild,
  IPasswordSettings,
  ISessionAvailability,
  PaymentType,
  SessionUnavailabilityReason,
} from "../types/types";
dayjs.extend(isSameOrBefore);

dayjs.extend(weekOfYear);

export const popularLanguages: string[] = [
  "English",
  "Mandarin",
  "Chinese",
  "Vietnamese",
  "Hindi",
  "Spanish",
  "Cantonese",
  "Punjabi",
  "Korean",
  "Gujarati",
  "Sinhalese",
  "Russian",
  "Tamil",
  "Malayalam",
  "Arabic",
  "Telugu",
  "Japanese",
  "Bengali",
  "French",
  "Nepalese",
  "Farsi",
  "Portuguese",
  "Urdu",
  "Afrikaans",
  "Indonesian",
  "Filipino",
  "Persian",
  "Marathi",
  "German",
  "Hebrew",
];

export const getWeekNum = (value: Dayjs | undefined) => {
  if (!!value) {
    return value.week();
  }
  return null;
};
export const formatCurrency = (value: number | undefined) => {
  if (value !== undefined) {
    return "$" + value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
  return "";
};

export const getWeekDays = (dt?: dayjs.Dayjs) => {
  var d = dt ?? dayjs();
  d = d.startOf("isoWeek");
  var days = new Array<dayjs.Dayjs>();
  days.push(d);
  days.push(d.add(1, "day"));
  days.push(d.add(2, "day"));
  days.push(d.add(3, "day"));
  days.push(d.add(4, "day"));
  return days;
};

export enum DayOfWeekEnum {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

const isWeekDaySelected = (
  isWeekly: boolean,
  allowedWeekNumber: number,
  weekNumber: number,
  curDate: Dayjs,
  isMonday: boolean,
  isTuesday: boolean,
  isWednesday: boolean,
  isThursday: boolean,
  isFriday: boolean,
  isSaturday: boolean = false,
  isSunday: boolean = false
) => {
  return (
    (isWeekly || weekNumber % 2 === allowedWeekNumber) &&
    ((curDate.day() === DayOfWeekEnum.Monday && isMonday) ||
      (curDate.day() === DayOfWeekEnum.Tuesday && isTuesday) ||
      (curDate.day() === DayOfWeekEnum.Wednesday && isWednesday) ||
      (curDate.day() === DayOfWeekEnum.Thursday && isThursday) ||
      (curDate.day() === DayOfWeekEnum.Friday && isFriday) ||
      (curDate.day() === DayOfWeekEnum.Saturday && isSaturday) ||
      (curDate.day() === DayOfWeekEnum.Sunday && isSunday))
  );
};

export const getBookableDates = (
  from: Dayjs,
  to: Dayjs,
  rollDays: SelectedRollDay,
  unAvailableDates: ISessionAvailability[]
): List<Dayjs> => {
  var unAvDates = new List(unAvailableDates);
  var allDates = new List<Dayjs>(new Array<Dayjs>());
  var curDate = from;
  var weekNumber = 0;
  if (to != null) {
    while (curDate.isBefore(to) || curDate.isSame(to, "date")) {
      if (
        isWeekDaySelected(
          rollDays.isWeekly,
          0,
          weekNumber,
          curDate,
          rollDays.isMonday,
          rollDays.isTuesday,
          rollDays.isWednesday,
          rollDays.isThursday,
          rollDays.isFriday
        ) ||
        isWeekDaySelected(
          rollDays.isWeekly,
          1,
          weekNumber,
          curDate,
          rollDays.isSecondMonday,
          rollDays.isSecondTuesday,
          rollDays.isSecondWednesday,
          rollDays.isSecondThursday,
          rollDays.isSecondFriday
        )
      ) {
        if (
          !unAvDates.Any(
            // eslint-disable-next-line no-loop-func
            (d) =>
              d?.rollId === rollDays.roll.rollId && curDate.isSame(d.date, "date") && !d.isAvailableForRecurringBooking
          )
        ) {
          allDates.Add(curDate);
        }
      }
      curDate = curDate.add(1, "day");
      if (curDate.day() === 1) {
        weekNumber++;
      }
    }
  }
  return allDates;
};

export const getBookableRollDates = (
  from: Dayjs | null,
  to: Dayjs | null,
  rollDays: SelectedRollDay[],
  unAvailableDates: ISessionAvailability[]
): SelectedRollDate[] => {
  var dates = new Array<SelectedRollDate>();
  if (from === null || to === null) {
    return dates;
  }
  var datesList = new List(dates);
  for (var roll of rollDays) {
    var rDates = getBookableDates(from, to, roll, unAvailableDates);
    // eslint-disable-next-line no-loop-func
    datesList.AddRange(rDates.Select((d) => new SelectedRollDate({ roll: roll.roll, date: d })).ToArray());
  }
  return datesList.ToArray();
};

export const hasMinRequiredBookingsAcrossWeeks = (
  from: Dayjs,
  to: Dayjs,
  rollDays: SelectedRollDay,
  unAvailableDates: ISessionAvailability[],
  minNumberOfBookings: number
): boolean => {
  var dates = getBookableDates(from, to, rollDays, unAvailableDates);
  var result = dates.GroupBy((d) => d.format("ddd"));
  var hasMin = false;
  for (var k in result) {
    if (result[k].length >= minNumberOfBookings) {
      hasMin = true;
      break;
    }
  }

  return hasMin;
};

export const violatesMinRequiredBookingsAcrossWeeks = (
  from: Dayjs,
  to: Dayjs,
  rollDays: SelectedRollDay[],
  unAvailableDates: ISessionAvailability[],
  minNumberOfBookings: number
): boolean => {
  if (rollDays.length === 0) return false;
  var hasMin = false;
  rollDays.forEach((rd) => {
    hasMin = false;
    var dates = getBookableDates(from, to, rd, unAvailableDates);
    var result = dates.GroupBy((d) => d.format("ddd"));
    for (var k in result) {
      if (result[k].length >= minNumberOfBookings) {
        hasMin = true;
        break;
      }
    }
  });

  return !hasMin;
};

export const getAvailabilityReasons = (reason: String): string[] => {
  var reasons = reason.split(", ");
  var reasonDetails = new Array<string>();
  for (var r of reasons) {
    switch (r) {
      case SessionUnavailabilityReason.HasDuplicateCareType:
        reasonDetails.push("A child already has a booking");
        break;
      case SessionUnavailabilityReason.NotAllowedCareType:
        reasonDetails.push("Care type is not allowed");
        break;

      case SessionUnavailabilityReason.SessionNotFound:
        reasonDetails.push("Session not found");
        break;
      case SessionUnavailabilityReason.SessionNotOperating:
        reasonDetails.push("Session is not operating");
        break;
      case SessionUnavailabilityReason.TooLateToBook:
        //give priority to this reason over others like fully booked, that's what we want to show on the screen
        reasonDetails.splice(0, 0, "Cannot book after cut-off time");
        break;
      case SessionUnavailabilityReason.OverCapacity:
        reasonDetails.push("Session is fully booked");
        break;
      default:
        break;
    }
  }
  return reasonDetails;
};

export const getAvailabilityReasonsText = (reason: String | undefined): string => {
  if (reason === undefined) return "";
  var result = getAvailabilityReasons(reason);
  if (result.length === 0) return "";
  return result[0];
};

export class Avatars {
  static list = [
    "🐶",
    "🐱",
    "🐨",
    "🐼",
    "🦄",
    "🐭",
    "🐹",
    "🐰",
    "🐬",
    "🐝",
    "🦘",
    "🐵",
    "🌼",
    "🐻",
    "🦊",
    "🐴",
    "🐮",
    "🐷",
    "🦁",
    "🐯",
    "🐳",
    "🦋",
    "🐺",
    "🦖",
  ];

  static get(index: number) {
    return this.list[index];
  }

  static getFirstAvailable(inUse: number[]) {
    for (var i = 0; i < Avatars.list.length; i++) {
      if (!inUse.includes(i)) {
        return i;
      }
    }
    return 0;
  }
}

export function dataURLtoBlob(dataUrl: string, contentType: string): Blob {
  var arr = dataUrl.split(","),
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: contentType });
}

export const blobToDataURL = (blob: Blob, callback: any, reject: any) => {
  var a = new FileReader();
  a.onerror = reject;
  a.onload = function (e) {
    callback(a.result);
  };
  a.readAsDataURL(blob);
};

export const getActivityType = (
  code: string | null | undefined
):
  | "Incursion"
  | "Excursion"
  | "Base Camp"
  | "Kids at work"
  | "Project Incursion"
  | "Experience Takeover Incursion"
  | null => {
  if (code === null || code === undefined) {
    return null;
  }
  if (code.startsWith("INC")) {
    return "Incursion";
  }

  if (code.startsWith("EXC")) {
    return "Excursion";
  }

  if (code.startsWith("CLU")) {
    return "Base Camp";
  }

  if (code.startsWith("KAW")) {
    return "Kids at work";
  }
  if (code.startsWith("PIN")) {
    return "Project Incursion";
  }
  if (code.startsWith("ETI")) {
    return "Experience Takeover Incursion";
  }

  return null;
};

export const getActivityColor = (
  activityType:
    | "Incursion"
    | "Excursion"
    | "Base Camp"
    | "Project Incursion"
    | "Experience Takeover Incursion"
    | "Kids at work"
    | null
) => {
  switch (activityType) {
    case "Incursion":
    case "Project Incursion":
    case "Experience Takeover Incursion":
      return getStyleColor("--custom-color-red");
    case "Excursion":
      return getStyleColor("--rocketeers-color-accent-1");
    default:
      return getStyleColor("--rocketeers-color-accent-2");
  }
};

export const getSelectedDays = (
  isMonday: boolean,
  isTuesday: boolean,
  isWednesday: boolean,
  isThursday: boolean,
  isFriday: boolean
): DayOfWeek[] => {
  var days = new Array<DayOfWeek>();
  if (isMonday) {
    days.push(DayOfWeek.Monday);
  }
  if (isTuesday) {
    days.push(DayOfWeek.Tuesday);
  }
  if (isWednesday) {
    days.push(DayOfWeek.Wednesday);
  }
  if (isThursday) {
    days.push(DayOfWeek.Thursday);
  }
  if (isFriday) {
    days.push(DayOfWeek.Friday);
  }
  return days;
};

export const getDates = (
  from: Dayjs,
  to: Dayjs,
  isMonday: boolean,
  isTuesday: boolean,
  isWednesday: boolean,
  isThursday: boolean,
  isFriday: boolean
): List<Dayjs> => {
  var allDates = new List<Dayjs>(new Array<Dayjs>());
  var curDate = from;
  var weekNumber = 0;
  if (to != null) {
    while (curDate.isBefore(to) || curDate.isSame(to, "date")) {
      if (isWeekDaySelected(true, 0, weekNumber, curDate, isMonday, isTuesday, isWednesday, isThursday, isFriday)) {
        allDates.Add(curDate);
      }
      curDate = curDate.add(1, "day");
      if (curDate.day() === 1) {
        weekNumber++;
      }
    }
  }
  return allDates;
};

export const defaultPasswordSettings = {
  passwordRequireDigit: true,
  passwordRequireLowercase: true,
  passwordRequireNonAlphanumeric: true,
  passwordRequireUppercase: true,
  passwordRequiredLength: 8,
  passwordRequiredUniqueChars: 1,
} as IPasswordSettings;

export const cloneObject = (obj: any) => {
  if (obj) {
    var cloneObj = obj.prototype ? new (obj.constructor() as any)() : {};
    for (var attribut in obj) {
      if (typeof obj[attribut] === "object") {
        cloneObj[attribut] = cloneObject(obj[attribut]);
      } else {
        cloneObj[attribut] = obj[attribut];
      }
    }
    return cloneObj;
  }
  return obj;
};

export const getStyleColor = (ref: string) => {
  const style = getComputedStyle(document.body);
  return style.getPropertyValue(ref) || "#000000";
};

export const isContactAllowedToBook = (account: CustomerAccount, userId: string, customerContactId: number) => {
  return (
    account?.canBook === true &&
    account?.contacts?.find(
      (c) =>
        c.customerContactId === customerContactId ||
        c.identityServerUserId?.toLocaleLowerCase() === userId.toLocaleLowerCase()
    )?.authorisation?.canMakeAndChangeBookings === true
  );
};

export const contactPlansToClaimChildCareSubsidy = (
  account: CustomerAccount,
  userId: string,
  customerContactId: number
) => {
  return (
    account?.contacts?.find(
      (c) =>
        c.customerContactId === customerContactId ||
        c.identityServerUserId?.toLocaleLowerCase() === userId.toLocaleLowerCase()
    )?.planToClaimChildCareSubsidy === true
  );
};

export const creditCardIsExpired = (paymentMethod: any) => {
  return (
    paymentMethod &&
    paymentMethod.paymentType === PaymentType.CreditCard &&
    daysToPaymentMethodExpiration(paymentMethod) <= 0
  );
};

export const daysToPaymentMethodExpiration = (paymentMethod: any) => {
  if (paymentMethod && paymentMethod.expiryYear && paymentMethod.expiryMonth) {
    let expirationDate = dayjs();
    expirationDate = expirationDate.set("year", paymentMethod.expiryYear);
    expirationDate = expirationDate.set("month", paymentMethod.expiryMonth - 1);
    expirationDate = expirationDate.set("date", 1);
    expirationDate = expirationDate.endOf("month");
    let diffInMs = expirationDate.diff(dayjs());
    return diffInMs / (1000 * 60 * 60 * 24);
  }
  return 0;
};

export function getMonthViewDates(date: Dayjs) {
  var temp: Dayjs[] = [];
  var startOfMonth = date.startOf("month").startOf("isoWeek");
  var endOfMonth = date.endOf("month").endOf("isoWeek");
  var current = startOfMonth.clone();
  while (current.isSameOrBefore(endOfMonth, "date")) {
    temp.push(current);
    current = current.add(1, "day");
  }
  return temp;
}

export function getMonthViewDatesGrouped(date: Dayjs): Dayjs[][] {
  var all = [];
  var startOfMonth = date.startOf("month").startOf("isoWeek");
  var endOfMonth = date.endOf("month").endOf("isoWeek");
  var current = startOfMonth.clone();
  var currentIsoWeek = current.isoWeek();
  var innerArray: Dayjs[] = [];
  all.push(innerArray);
  while (current.isSameOrBefore(endOfMonth, "date")) {
    var newIsoWeek = current.isoWeek();
    if (currentIsoWeek !== newIsoWeek) {
      innerArray = [];
      all.push(innerArray);
      currentIsoWeek = newIsoWeek;
    }

    innerArray.push(current);
    current = current.add(1, "day");
  }
  return all;
}

export function getWeekViewDates(date: Dayjs) {
  var temp: Dayjs[] = [];
  var startOfWeek = date.startOf("isoWeek");
  var endOfWeek = date.endOf("isoWeek");
  var current = startOfWeek.clone();
  while (current.isSameOrBefore(endOfWeek, "date")) {
    temp.push(current);
    current = current.add(1, "day");
  }
  return temp;
}

export function getCareTypeColor(careTypeId: string, suffix: string = ""): string {
  switch (careTypeId.toUpperCase()) {
    case `BSC`:
      return `Bsc${suffix}`;
    case `ASC`:
      return `Asc${suffix}`;
    case `PFD`:
      return `Pfd${suffix}`;
    case `PD`:
      return `Pd${suffix}`;
    case `HL`:
      return `Hl${suffix}`;
    default:
      return `Vac${suffix}`;
  }
}

export function getCareTypeColorVariable(careTypeId: string): string {
  switch (careTypeId.toUpperCase()) {
    case `BSC`:
      return "var(--youroshc-color-primary)";
    case `ASC`:
      return "var(--youroshc-color-accent-2)";
    case `PFD`:
      return `#929292`;
    case `HL`:
      return `#f0eb4d`;
    default:
      return `var(--youroshc-color-accent-1)`;
  }
}

export function getChildDisplayName(child: Child | IChild): string {
  return `${child.firstName} ${child.lastName?.substring(0, 1)}`;
}


export function isCurrentWeek(date: Dayjs) {
  var monday = getMondayOfCurrentWeek();
  return date >= monday && date <= monday.add(4, "days");
}

export function getMondayOfCurrentWeek() {
  return dayjs().startOf("isoWeek");
}

export function getNextMonday() {
  return getMondayOfCurrentWeek().add(7, "days");
}