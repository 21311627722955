import { ReactNode } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Divider from "@mui/material/Divider/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link/Link";
import Typography from "@mui/material/Typography/Typography";
import { makeStyles } from "tss-react/mui";

import Center from "../Common/Center";
import { AnimatedDialog } from "../Common/Dialogs/AnimatedDialog";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: "0.7em",
    height: "0.7em",
    color: "black",
  },
  errorBorder: {
    border: "1px solid var(--custom-color-error)",
  },
}));
interface IProps {
  open: boolean;
  onViewClicked: () => void;
  onBack: () => void;
  isSuccessful: boolean;
  errorDetails?: ReactNode;
  title: string;
  handleClose: () => void;
  color: string;
}
export default function BookingCompleteDialog(props: IProps) {
  const { open, color, onBack, isSuccessful, errorDetails, title, handleClose } = props;
  const { classes } = useStyles();
  return (
    <AnimatedDialog
      open={open}
      PaperProps={{
        sx: { border: `1px solid ${isSuccessful ? color : "var(--custom-color-error)"}` },
      }}
      onClose={(event, reason) => {
        if ((reason !== "backdropClick" && reason !== "escapeKeyDown") || !isSuccessful) {
          handleClose();
        }
      }}
      maxWidth="xs"
    >
      <DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", pt: 0 }}>
          {isSuccessful && (
            <Center>
              <CheckCircleIcon style={{ color: color, fontSize: "3rem" }} />
            </Center>
          )}
          {!isSuccessful && (
            <Center>
              <ErrorIcon style={{ color: "var(--custom-color-error)", fontSize: "3rem" }} />
            </Center>
          )}
          <Typography
            gutterBottom
            sx={{ color: isSuccessful ? "#1d4e5c" : "var(--custom-color-error)", fontWeight: "bold" }}
          >
            <Center> {isSuccessful ? "Booking complete" : "Booking failed"}</Center>
          </Typography>

          <Typography variant="caption">
            <Center>{title}</Center>
          </Typography>
          {errorDetails && <Typography pt={1}>{errorDetails}</Typography>}
        </Box>

        {!isSuccessful && (
          <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={(e) => handleClose()}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogActions sx={{ marginTop: 1, marginBottom: 2, marginRight: 2, justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            alignContent: "center",
          }}
        >
          <Divider style={{ width: "70%" }} />
          <Typography variant="caption" paddingTop={2}>
            Return to the{" "}
            <Link
              href="#"
              sx={{ color: "gray", textDecorationColor: "#707070", fontSize: 15 }}
              onClick={() => {
                onBack();
              }}
            >
              Dashboard
            </Link>
          </Typography>
        </Box>
      </DialogActions>
    </AnimatedDialog>
  );
}
