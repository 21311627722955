import { createActionCreator } from "deox";

import { AvatarInfo } from "../../../types/models";

export const avatarsLoading = createActionCreator("avatarsLoading");

export const setAllAvatarsInitialised = createActionCreator(
  "setAllAvatarsInitialised",
  (resolve) => (allAvatarsInitialized: boolean) => resolve({ allAvatarsInitialized })
);

export const setAvatar = createActionCreator(
  "setAvatar",
  (resolve) => (avatarInfo: AvatarInfo) => resolve({ avatarInfo })
);

export const avatarsReset = createActionCreator("avatarsReset");
