import apiAppConfig from "./appConfigsApi";
import { appConfigError, appConfigLoading, appConfigSuccess } from "./appConfigsStateActions";

export function getAppConfigs(dispatch: any) {
  dispatch(appConfigLoading());
  apiAppConfig.getAppConfigs().then(
    (configs: any) => {
      dispatch(appConfigSuccess(configs));
    },
    function (error: any) {
      return dispatch(appConfigError());
    }
  );
}
