import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { List } from "linqts";

import UpcomingServiceTransaction from "./UpcomingServiceTransaction";
import theme from "../../theme";
import { UpcomingCharge } from "../../types/types";
import { formatCurrency } from "../../utils/helpers";

export interface UpcomingWeeklyTransactionProps {
  transactions: UpcomingCharge[];
  week: number;
}
const UpcomingWeeklyTransaction = (props: UpcomingWeeklyTransactionProps) => {
  const arr = new List<UpcomingCharge>(props.transactions);
  const transByDate = arr.GroupBy((t) => t.date.toString());

  return (
    <Grid item xs={12}>
      {Object.keys(transByDate).map((date, index) => {
        return (
          <Grid container key={index}>
            <Grid item xs={12}>
              <span style={{ color: theme.palette.secondary.main }}>
                {dayjs(date).format("D MMM").toUpperCase() ?? ""}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Box paddingTop={1} />
              <UpcomingServiceTransaction transactions={transByDate[date]}></UpcomingServiceTransaction>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        <Grid item xs={12}>
          <Box paddingTop={2} paddingBottom={2}>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            <span className="color-accent-2">Weekly Total</span>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container justifyContent="flex-end">
            <span className="color-accent-2">
              {formatCurrency(
                props.transactions.reduce((sum, current) => sum + (current.grossAmount - current.discountAmount), 0)
              )}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpcomingWeeklyTransaction;
