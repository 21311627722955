import React, { useEffect } from "react";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import { shouldShowOverDue } from "../../store/modules/overDue/overDueActions";
import { RootState } from "../../store/store";
import { DebtCategoryEnum } from "../../types/types";

export default function OverDuePayment() {
  const dispatch = useDispatch();
  const customer = useSelector((state: RootState) => state.customer).customer;
  const paymentMethod = useSelector((state: RootState) => state.paymentMethod).payload;
  const overDueStatus = useSelector((state: RootState) => state.overDueBill).status;
  const navigate = useNavigate();
  const [showOverdueMsg, setShowOverdueMsg] = React.useState(false);
  const [title, setTitle] = React.useState("");
  function getErrorTitle() {
    var title = "OVERDUE BILL";
    if (customer?.isExcluded) {
      title = "YOUR ACCOUNT HAS BEEN EXCLUDED";
    } else if (customer?.debtCategoryId === DebtCategoryEnum.ProposedExcluded) {
      title = "PLEASE ARRANGE PAYMENT";
    }

    return title;
  }
  useEffect(() => {
    if (customer) {
      shouldShowOverDue(dispatch, customer, overDueStatus);
    }
  }, [customer]);
  useEffect(() => {
    if (overDueStatus === "Show") {
      setTitle(getErrorTitle());
      setShowOverdueMsg(true);
    } else {
      setShowOverdueMsg(false);
    }
  }, [overDueStatus]);

  const handleClose = () => {
    if (canDimiss()) {
      setShowOverdueMsg(false);
    }
  };

  function canDimiss() {
    return !customer?.isExcluded && customer?.debtCategoryId !== DebtCategoryEnum.ProposedExcluded;
  }

  return (
    <AnimatedDialog
      open={showOverdueMsg}
      //onClose={handleClose}
      title={title}
      onClose={(event, reason) => {
        if ((reason !== "backdropClick" && reason !== "escapeKeyDown") || canDimiss()) {
          handleClose();
        }
      }}
      // disableBackdropClick={!canDimiss()}
    >
      <DialogContent>
        {OverDueMessage({
          isExcluded: customer?.isExcluded ?? false,
          hasDefaultPaymentMethod: paymentMethod !== null,
          proposedExcluded: customer?.debtCategoryId === DebtCategoryEnum.ProposedExcluded ?? false,
        })}
      </DialogContent>
      <DialogActions>
        <ButtonPrimary style={{ backgroundColor: "white" }} href="tel:123-456-7890">
          Call Customer Care
        </ButtonPrimary>
        <ButtonPrimary onClick={() => navigate("/pay-now")}>Pay Now</ButtonPrimary>
      </DialogActions>
    </AnimatedDialog>
  );
}
interface IOverDueProps {
  isExcluded: boolean;
  proposedExcluded: boolean;
  hasDefaultPaymentMethod: boolean;
}
function OverDueMessage(props: IOverDueProps) {
  return props.isExcluded
    ? "As you have not met the payment requirements for using care, all bookings have been removed and your account has been excluded from accessing our services.\n\nPayment must be made in full in order to reactivate your account.\n\nWe recommend arranging automatic direct debits from your account to prevent this from happening again."
    : props.proposedExcluded && props.hasDefaultPaymentMethod
    ? "Your account is currently overdue and requires immediate payment.\n\nIf this amount is not paid on the next due date, your account will be excluded from using care and all future bookings will be removed.\n\nAs you have a direct debit in place, we will process this payment automatically for you on the next due date.\n\nTo prevent any disruption to your account, please ensure funds are available."
    : props.proposedExcluded && !props.hasDefaultPaymentMethod
    ? "Your account is currently overdue and requires immediate payment.\n\nIf this amount is not paid on the next due date, your account will be excluded from using care and all future bookings will be removed.\n\nPlease arrange payment as soon as possible. To prevent this from happening again, we recommend arranging automatic direct debits for your account."
    : props.hasDefaultPaymentMethod
    ? "You currently have an overdue balance that requires immediate payment.\n\nIf this amount is not paid on the next due date, restrictions will be placed on your account, preventing access to adding and editing future bookings.\n\nAs you have a direct debit in place, we will process this payment automatically for you on the next due date.\n\nTo prevent any disruption to your account, please ensure funds are available."
    : "You currently have an overdue balance that requires immediate payment.\n\nIf this amount is not paid on the next due date, restrictions will be placed on your account, preventing access to adding and editing future bookings.\n\nPlease arrange payment as soon as possible. To prevent this from happening again, we recommend arranging automatic direct debits for your account.";
}
