import { createReducer } from "deox";

import { TempDocument } from "../../../types/models";
import { CustomerAccount } from "../../../types/types";
import { childUpdated } from "../child/childStateActions";
import { SaveDocumentAction, RemoveDocumentAction } from "../document/documentStateActions";
import { purge } from "../purge/purgeStateActions";
import {
  contactReset,
  contactUpdated,
  contactUpdating,
  customerError,
  customerLoading,
  customerSuccess,
  contactUpdateError,
} from "./customerActionTypes";

export interface CustomerState {
  isLoadingCustomer: boolean;
  isErrorCustomer: boolean;
  customer?: CustomerAccount | null;
  tempDocuments: TempDocument[];
  isUpdatingContact: boolean;
  isContactUpdated: boolean;
  isUpdateError: boolean;
}

const initialState: CustomerState = {
  customer: null,
  isErrorCustomer: false,
  isLoadingCustomer: false,
  tempDocuments: [],
  isUpdatingContact: false,
  isContactUpdated: false,
  isUpdateError: false,
};

const customerReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    customerSuccess,
    (state, action): CustomerState => ({
      ...state,
      isLoadingCustomer: false,
      isErrorCustomer: false,
      customer: action.payload,
    })
  ),
  handleAction(
    customerLoading,
    (state): CustomerState => ({
      ...state,
      isErrorCustomer: false,
      isLoadingCustomer: true,
    })
  ),
  handleAction(
    customerError,
    (state): CustomerState => ({
      ...state,
      customer: null,
      isErrorCustomer: true,
      isLoadingCustomer: false,
    })
  ),
  handleAction(SaveDocumentAction, (state, action) => {
    state.customer?.documents?.push(action.payload.document);

    if (!state.tempDocuments) {
      state.tempDocuments = [];
    }

    state.tempDocuments?.push({
      childId: action.payload.document.childId,
      customerContactId: action.payload.document.customerContactId,
      uniqueId: action.payload.document.uniqueId,
      created: true,
    });

    return {
      ...state,
    };
  }),
  handleAction(RemoveDocumentAction, (state, action) => {
    if (state.customer && state.customer?.documents) {
      state.customer.documents = state.customer?.documents?.filter(
        (x) => x.uniqueId !== action.payload.document.uniqueId
      );
    }

    if (!state.tempDocuments) {
      state.tempDocuments = [];
    }

    state.tempDocuments?.push({
      childId: action.payload.document.childId,
      customerContactId: action.payload.document.customerContactId,
      uniqueId: action.payload.document.uniqueId,
      deleted: true,
    });

    return {
      ...state,
    };
  }),
  handleAction(childUpdated, (state, action) => {
    state.tempDocuments = state.tempDocuments?.filter((x) => !x.childId || x.childId !== action.payload.child.childId);

    if (action.payload.isNew && action.payload.documentIdsAdded && action.payload.documentIdsAdded?.length > 0) {
      action.payload.documentIdsAdded.forEach((x) => {
        var document = state.customer?.documents?.find((y) => y.uniqueId === x);
        if (document) {
          document.childId = action.payload.child.childId;
        }
      });
    }

    return {
      ...state,
    };
  }),
  handleAction(
    contactUpdating,
    (state): CustomerState => ({
      ...state,
      isLoadingCustomer: false,
      isErrorCustomer: false,
      isUpdatingContact: true,
      isContactUpdated: false,
      isUpdateError: false,
    })
  ),

  handleAction(contactUpdated, (state, { payload }) => {
    if (state.customer) {
      if (state.customer.contacts === null) {
        state.customer.contacts = [];
      }
      if (state.customer.contacts) {
        var contact = payload.contact;
        var contactIndex = state.customer.contacts.findIndex((x) => x.customerContactId === contact.customerContactId);

        if (contactIndex === -1) {
          state.customer.contacts.push(contact);
        } else {
          state.customer.contacts[contactIndex] = contact;
        }
      }
    }

    return {
      ...state,
      isUpdatingContact: false,
      isContactUpdated: true,
      isUpdateError: false,
    };
  }),
  handleAction(
    contactUpdateError,
    (state): CustomerState => ({
      ...state,
      isLoadingCustomer: false,
      isErrorCustomer: false,
      isUpdatingContact: false,
      isContactUpdated: false,
      isUpdateError: true,
    })
  ),
  handleAction(
    contactReset,
    (state): CustomerState => ({
      ...state,
      isLoadingCustomer: false,
      isErrorCustomer: false,
      isUpdatingContact: false,
      isContactUpdated: false,
      isUpdateError: false,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default customerReducer;
