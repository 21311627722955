import { createReducer } from "deox";

import { AvatarInfo, EntityType } from "../../../types/models";
import { Avatars } from "../../../utils/helpers";
import { childUpdated } from "../child/childStateActions";
import { contactUpdated, removeTemporaryAvatars } from "../customer/customerActionTypes";
import { purge } from "../purge/purgeStateActions";
import { avatarsLoading, setAvatar, setAllAvatarsInitialised, avatarsReset } from "./avatarStateActions";

export interface AvatarState {
  avatars: AvatarInfo[];
  isLoading: boolean;
  allAvatarsInitialised: boolean;
}

export const initialState: AvatarState = {
  avatars: [],
  isLoading: false,
  allAvatarsInitialised: false,
};

const avatarReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    avatarsLoading,
    (state): AvatarState => ({
      ...state,
      isLoading: true,
    })
  ),
  handleAction(setAvatar, (state, { payload }) => {
    if (state.avatars === null) {
      state.avatars = [];
    }

    var avatarIndex = state.avatars.findIndex(
      (x) => x.entityType === payload.avatarInfo.entityType && x.entityId === payload.avatarInfo.entityId
    );

    if (payload.avatarInfo.setDefault) {
      var firstAvailableAvatar = Avatars.getFirstAvailable(state.avatars.map((x) => x.index ?? -1) ?? []);
      payload.avatarInfo.index = firstAvailableAvatar;
      payload.avatarInfo.setDefault = false;
    }

    if (avatarIndex === -1) {
      state.avatars.push(payload.avatarInfo);
    } else {
      state.avatars[avatarIndex] = payload.avatarInfo;
    }

    var allAvatarsInitialized = state.avatars.every((avatar) => {
      return avatar !== undefined && !avatar.isLoading;
    });

    return {
      ...state,
      allAvatarsInitialised: allAvatarsInitialized,
      isLoading: !allAvatarsInitialized,
    };
  }),
  handleAction(setAllAvatarsInitialised, (state, { payload }) => ({
    ...state,
    allAvatarsInitialised: payload.allAvatarsInitialized,
    isLoading: false,
  })),
  handleAction(childUpdated, (state, { payload }) => {
    if (payload.isNew) {
      var avatarIndex = state.avatars.findIndex((x) => x.entityType === EntityType.Child && x.entityId === "-1");
      if (avatarIndex > -1) {
        state.avatars[avatarIndex].entityId = payload.child.childId?.toString();
      }
    }

    return {
      ...state,
    };
  }),
  handleAction(contactUpdated, (state, { payload }) => {
    if (payload.isNew) {
      var avatarIndex = state.avatars.findIndex((x) => x.entityType === EntityType.Contact && x.entityId === "-1");
      if (avatarIndex > -1) {
        state.avatars[avatarIndex].entityId = payload.contact.customerContactId?.toString();
      }
    }

    return {
      ...state,
    };
  }),
  handleAction(removeTemporaryAvatars, (state) => {
    state.avatars = state.avatars.filter((x) => x.entityId !== "-1");

    return {
      ...state,
    };
  }),
  handleAction(avatarsReset, () => initialState),
  handleAction(purge, () => initialState),
]);

export default avatarReducer;
