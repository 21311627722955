import { useDispatch, useSelector } from "react-redux";

import { downloadStatement } from "../../store/modules/statementReport/statementReportActions";
import { RootState } from "../../store/store";
import { ButtonSecondary } from "../Common/Buttons/Buttons";

export interface IDownloadStatementProps {
  billingCycleId: number;
  customerAccountId: number;
  onDownload(): void;
}

export default function DownloadStatement(props: IDownloadStatementProps) {
  const dispatch = useDispatch();
  const bCycleId = useSelector((state: RootState) => state.statementReport.billingCycleId);
  const isDownloading = useSelector((state: RootState) => state.statementReport.isDownloading);
  const downloadClicked = (customerId: number, cycleId: number) => {
    downloadStatement(dispatch, customerId, cycleId);
  };

  return (
    <ButtonSecondary
      fullWidth
      disabled={bCycleId === props.billingCycleId && isDownloading}
      type="button"
      onClick={(event) => downloadClicked(props.customerAccountId, props.billingCycleId)}
    >
      <strong>{bCycleId === props.billingCycleId && isDownloading ? " Downloading..." : " Download Statement"}</strong>
    </ButtonSecondary>
  );
}
