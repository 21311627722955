import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { RegistrationStepper } from "../../components/Common/Stepper/Stepper";
import FormChildStep8 from "../../components/Forms/Registration/ChildStep8";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import { childSteps } from "../../utils/stepperSteps";

interface ParamTypes {
  id: string;
}

function ChildStep8() {
  let { id } = useParams();
  const memoizedId = useMemo(() => parseInt(id!, 10), [id]);
  const child = useSelector((state: RootState) => state.register?.children[memoizedId]);
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage stepper={<RegistrationStepper activeStep={6} steps={childSteps(memoizedId)} />}>
        <RegistrationHeader heading={`${child.firstName} ${child.lastName}`} subHeading={`Child ${memoizedId + 1}`}>
          <p>
            Camp Australia adheres to government regulation to ensure that all priority children have access to the
            before school, after school and vacation care programs.
          </p>
        </RegistrationHeader>
        <FormChildStep8 child={child} id={id!} />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default ChildStep8;
