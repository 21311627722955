import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { List } from "linqts";
import { useDispatch } from "react-redux";

import { MedicalPlanAnswer, MedicalPlanQuestionTypes, QuestionTemplate } from "../../../types/models";
import { Document, IQuestionTemplate, IRegistrationMedicalPlanAnswer } from "../../../types/types";
import { cloneObject } from "../../../utils/helpers";
import MedicalPlanQuestion from "../../Common/Controls/MedicalPlanQuestion";

interface Props {
  medicalConditionId: number;
  childAnswers: List<IRegistrationMedicalPlanAnswer>;
  allQuestions: List<IQuestionTemplate>;
  childUniqueId?: string | undefined;
  childId?: number | undefined;
  customerAccountId?: number | undefined;
  documents: Document[];
  onAnswersChanged?: (answer: List<MedicalPlanAnswer>) => void;
}
export default function MedicalConditionQuestionnaire(props: Props) {
  var dispatch = useDispatch();
  var mdQuestions = props.allQuestions
    .Where(
      (x) =>
        x !== undefined &&
        x.parentQuestionId == null &&
        (x.forMedicalConditions === undefined ||
          x.forMedicalConditions.length === 0 ||
          x.forMedicalConditions.indexOf(props.medicalConditionId) >= 0) &&
        (x.notForMedicalConditions === undefined ||
          x.notForMedicalConditions.length === 0 ||
          x.notForMedicalConditions.indexOf(props.medicalConditionId) < 0)
    )
    .OrderBy((q) => q.questionCategoryId);
  var data = getQuestionsAndAnswers(props.allQuestions, mdQuestions, props.childAnswers, props.medicalConditionId);
  var questions = data.questions;
  var answers = data.answers;

  var [initialAnswers] = useState(answers);
  useEffect(() => {
    if (props.onAnswersChanged) props.onAnswersChanged(answers);
  }, [initialAnswers, dispatch]);
  return (
    <Box paddingTop={1} paddingBottom={2}>
      {questions &&
        questions.Count() > 0 &&
        questions
          .Select((q, index) => (
            <MedicalPlanQuestion
              key={index}
              question={q}
              medicalConditionId={props.medicalConditionId}
              answers={answers}
              relatedQuestions={props.allQuestions
                .Where((z) => z?.parentQuestionId === q.questionId)
                .Select((mq) => new QuestionTemplate(mq))}
              answer={
                cloneObject(
                  answers.FirstOrDefault(
                    (a) =>
                      a?.conditionId === props.medicalConditionId &&
                      q.questionId === a.questionId &&
                      a.multipleAnswerIndex === q.multipleQuestionIndex
                  )
                ) as MedicalPlanAnswer
              }
              childUniqueId={props.childUniqueId}
              childId={props.childId}
              customerAccountId={props.customerAccountId}
              documents={props.documents ?? []}
              onAnswerChanged={(questionId, value, freeText, answerIndex) => {
                upsertAnswer(
                  answers,
                  questionId,
                  value,
                  freeText,
                  answerIndex,
                  props.medicalConditionId,
                  props.allQuestions
                );
                var ques = props.allQuestions.FirstOrDefault((q) => q?.questionId === questionId);
                if (ques && ques.questionTypeId === MedicalPlanQuestionTypes.YesNo) {
                  var related = props.allQuestions
                    .Where((z) => z?.parentQuestionId === questionId)
                    .Select((mq) => new QuestionTemplate(mq));
                  for (var rq of related.ToArray()) {
                    if (value !== "1") {
                      // eslint-disable-next-line no-loop-func
                      var temp = answers.FirstOrDefault(
                        // eslint-disable-next-line no-loop-func
                        (x) => x?.questionId === rq.questionId && x.multipleAnswerIndex === answerIndex
                      );
                      if (temp) answers.Remove(temp);
                    }
                  }
                }
                if (props.onAnswersChanged) props.onAnswersChanged(answers);
              }}
            />
          ))
          .ToArray()}
    </Box>
  );
}
function addAnswer(
  allAnswers: List<MedicalPlanAnswer>,
  answers: List<IRegistrationMedicalPlanAnswer>,
  q: IQuestionTemplate,
  index: number,
  medicalConditionId: number
) {
  var temp = allAnswers.FirstOrDefault(
    (a) => a?.questionId === q.questionId && a.multipleAnswerIndex === index && a.conditionId === medicalConditionId
  );
  if (!temp) {
    var ans = answers.FirstOrDefault(
      (a) => a?.questionId === q.questionId && a.multipleAnswerIndex === index && a.conditionId === medicalConditionId
    );
    if (!ans) {
      ans = {
        conditionId: medicalConditionId,
        multipleAnswerIndex: index,
        questionId: q.questionId,
        freeText: "",
        value: q.questionTypeId === MedicalPlanQuestionTypes.YesNo ? "0" : "",
      } as IRegistrationMedicalPlanAnswer;
    }
    allAnswers.Add(
      new MedicalPlanAnswer({
        ...ans,
        isRequired: q.required,
        isFreeTextRequired: (q.allowFreeText && q.freeTextRequired) ?? false,
      })
    );
  }
}

function getQuestionsAndAnswers(
  allQuestions: List<IQuestionTemplate>,
  questions: List<IQuestionTemplate>,
  answers: List<IRegistrationMedicalPlanAnswer>,
  medicalConditionId: number
) {
  var allMedicalConditionQuestions = new List<QuestionTemplate>([]);
  var allAnswers = new List<MedicalPlanAnswer>();

  answers.ForEach((ans) => {
    var q = allQuestions.FirstOrDefault((x) => x?.questionId === ans?.questionId);
    if (q) {
      allAnswers.Add(
        new MedicalPlanAnswer({
          ...ans!,
          isRequired: q!.required,
          isFreeTextRequired: (q!.allowFreeText && q!.freeTextRequired) ?? false,
        })
      );
    }
  });

  questions.ForEach((q) => {
    if (q) {
      allMedicalConditionQuestions.Add(new QuestionTemplate(q));
      addAnswer(allAnswers, answers, q, q.multipleQuestionIndex ?? 0, medicalConditionId);

      if (q.allowMultipleAnswers) {
        var multipleAnswersCount = answers.Count(
          (x) => x !== undefined && x.questionId === q.questionId && x.value === "1"
        );

        if (multipleAnswersCount > 0) {
          for (var i = 1; i < multipleAnswersCount + 1; i++) {
            let multipleQuestionIndex = i;
            var question = allMedicalConditionQuestions.FirstOrDefault(
              (x) =>
                x !== undefined && x.questionId === q.questionId && x.multipleQuestionIndex === multipleQuestionIndex
            );
            if (!question) {
              var extraQuestion = cloneObject(q) as QuestionTemplate;
              extraQuestion.parentQuestionId = q.questionId;
              extraQuestion.text = q.addAnswerQuestion;
              extraQuestion.multipleQuestionIndex = i;
              allMedicalConditionQuestions.Add(extraQuestion);
              addAnswer(allAnswers, answers, extraQuestion, i, medicalConditionId);
            }
          }
        }
      }
    }
  });

  return {
    questions: allMedicalConditionQuestions,
    answers: allAnswers,
  };
}

function upsertAnswer(
  currentAnswers: List<MedicalPlanAnswer>,
  questionId: number,
  value: string | undefined,
  freeText: string,
  answerIndex: number,
  medicalConditionId: number,
  allQuestions: List<IQuestionTemplate>
) {
  var currentAnswer = currentAnswers.FirstOrDefault(
    (a) => a?.conditionId === medicalConditionId && a.questionId === questionId && a.multipleAnswerIndex === answerIndex
  );
  if (currentAnswer === undefined) {
    var ques = allQuestions.FirstOrDefault((aq) => aq?.questionId === questionId);
    currentAnswer = new MedicalPlanAnswer({
      value: value,
      freeText: freeText,
      conditionId: medicalConditionId,
      questionId: questionId,
      multipleAnswerIndex: answerIndex,
      isRequired: ques.required,
      isFreeTextRequired: ques.freeTextRequired ?? false,
    });
    currentAnswers.Add(currentAnswer);
  } else {
    currentAnswer.value = value;
    currentAnswer.freeText = freeText;
  }
}
