import React, { useEffect, useRef } from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import Routes from "./routes/Routes";
import { getAccountBalance } from "./store/modules/accountBalance/accountBalanceActions";
import { getAppConfigs } from "./store/modules/appConfigs/appConfigsActions";
import { getAppStatus } from "./store/modules/appStatus/appStatusActions";
import {
  setRegisteredChildAvatars,
  setRegisteredContactAvatars,
  setUnregisteredAvatars,
} from "./store/modules/avatar/avatarActions";
import { setAllAvatarsInitialised } from "./store/modules/avatar/avatarStateActions";
import { getCancellationBookingReasons } from "./store/modules/booking/bookingActions";
import { getChildren } from "./store/modules/child/childActions";
import { getCommonData } from "./store/modules/common/commonActions";
import { getCryptoTokens } from "./store/modules/cryptoToken/cryptoTokenActions";
import { getCustomer } from "./store/modules/customer/customerActions";
import { loadDueCharges } from "./store/modules/dueCharges/dueChargesActions";
import { getPasswordSettings } from "./store/modules/passwordSettings/passwordSettingsActions";
import { getDueDates } from "./store/modules/payment/paymentActions";
import { getCurrentPaymentMethod } from "./store/modules/paymentMethod/paymentMethodActions";
import { getRegistration } from "./store/modules/register/registerActions";
import { getServices } from "./store/modules/service/servicesActions";
import { fetchTermsAndConditions } from "./store/modules/termsAndConditions/termsAndConditionsActions";
import { RootState } from "./store/store";
import theme from "./theme";
import { Child } from "./types/models";
import { Contact } from "./types/types";

const muiCache = createCache({
  key: "mui",
  prepend: true,
});
const tagManagerArgs = {
  gtmId: "GTM-NL8C6F",
};

const hotJarArgs = {
  id: Number(window.__config.HOTJAR_SITEID),
  sv: Number(window.__config.HOTJAR_VERSION),
  enable: window.__config.HOTJAR_ENABLED ?? "false",
};

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    if (hotJarArgs.id > 0 && hotJarArgs.enable.localeCompare("true", undefined, { sensitivity: "base" }) === 0)
      hotjar.initialize(hotJarArgs);
  }, []);

  const { user } = useSelector((state: RootState) => state?.auth);
  const commonDataIsInitialised = useSelector((state: RootState) => state.commonData.isInitialised);
  const commonDataIsLoading = useSelector((state: RootState) => state.commonData.isLoading);

  const appConfigsIsLoading = useSelector((state: RootState) => state.appConfigs.isLoading);
  const appConfigsIsLoaded = useSelector((state: RootState) => state.appConfigs.isLoaded);

  const { customer, isLoadingCustomer } = useSelector((state: RootState) => state.customer);
  const { childrenByCustomer } = useSelector((state: RootState) => state.child);
  const isLoadingChildren = useSelector((state: RootState) => state.child.isLoadingChildren);

  const paymentMethodIsLoaded = useSelector((state: RootState) => state.paymentMethod.isLoaded);
  const paymentMethodIsLoading = useSelector((state: RootState) => state.paymentMethod.isLoading);

  const termsAndConsitionsIsLoaded = useSelector((state: RootState) => state.termsAndConsitions.isLoaded);
  const termsAndConsitionsIsLoading = useSelector((state: RootState) => state.termsAndConsitions.isLoading);

  const registerIsInitialised = useSelector((state: RootState) => state.register.isInitialised);
  const { documents } = useSelector((state: RootState) => state.register);
  const registrationContacts = useSelector((state: RootState) => state.register.contacts);
  const registrationChildren = useSelector((state: RootState) => state.register.children);

  const { allAvatarsInitialised, avatars } = useSelector((state: RootState) => state.avatars);
  const isLoadingAvatars = useSelector((state: RootState) => state.avatars.isLoading);

  const [avatarsRequested, setAvatarsRequested] = React.useState(false);
  const [contactAvatarsRequested, setContactAvatarsRequested] = React.useState(false);
  const [childAvatarsRequested, setChildAvatarsRequested] = React.useState(false);

  const balance = useSelector((state: RootState) => state.balance.payload);
  const isBalanceLoading = useSelector((state: RootState) => state.balance.isLoading);

  const isDueChargesLoaded = useSelector((state: RootState) => state.dueCharges.isLoaded);
  const isDueChargesLoading = useSelector((state: RootState) => state.dueCharges.isLoading);

  const isLoadingServices = useSelector((state: RootState) => state.services.isLoadingServices);
  const isLoadingCancellationReasons = useSelector((state: RootState) => state.bookingCancellationReason.isLoading);
  const isLoadedCancellationReasons = useSelector((state: RootState) => state.bookingCancellationReason.isInitialised);

  const passwordSettingsStatus = useSelector((state: RootState) => state.passwordSettings.status);
  const billingDueDateState = useSelector((state: RootState) => state.billingDueDate);

  const cryptoTokenIsLoading = useSelector((state: RootState) => state.cryptoToken.isLoading);
  const cryptoTokenIsLoaded = useSelector((state: RootState) => state.cryptoToken.isLoaded);

  const appStatusIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      const customerAccountId = user.profile?.customer_account_id;

      if (customerAccountId) {
        if (!isLoadingCustomer && customer === null) {
          getCustomer(dispatch, customerAccountId);
        } else {
          if (!cryptoTokenIsLoading && !cryptoTokenIsLoaded) {
            getCryptoTokens(dispatch);
          }

          if (customer?.contacts !== undefined) {
            processRegisteredContactsAvatars(customer?.contacts, customerAccountId);
          }

          if (!isLoadingCancellationReasons && !isLoadedCancellationReasons) {
            getCancellationBookingReasons(dispatch);
          }

          if (childrenByCustomer === null) {
            if (!isLoadingChildren) {
              getChildren(dispatch, customerAccountId);
            }
          } else {
            if (customer?.contacts !== undefined) {
              processRegisteredChildrenAvatars(childrenByCustomer, customerAccountId);

              if (!commonDataIsLoading && !commonDataIsInitialised) {
                getCommonData(dispatch);
              }

              if (!appConfigsIsLoading && !appConfigsIsLoaded) {
                getAppConfigs(dispatch);
              }

              if (!isLoadingServices) {
                getServices(dispatch, customerAccountId);
              }

              if (!termsAndConsitionsIsLoaded && !termsAndConsitionsIsLoading) {
                fetchTermsAndConditions(dispatch, customerAccountId);
              }

              if (!paymentMethodIsLoaded && !paymentMethodIsLoading) {
                getCurrentPaymentMethod(dispatch, customerAccountId);
              }

              if (!balance && !isBalanceLoading) {
                getAccountBalance(dispatch, customerAccountId);
              }
              if (!isDueChargesLoading && !isDueChargesLoaded) {
                loadDueCharges(dispatch, customerAccountId, 5, 1);
              }

              if (!billingDueDateState.isSuccess && !billingDueDateState.isProcessing) {
                getDueDates(dispatch, new Date().getFullYear(), customerAccountId);
              }
            }
          }
        }
      } else {
        if (!registerIsInitialised) {
          getRegistration(dispatch, user);
        } else {
          processUregisteredAvatars();

          if (!commonDataIsLoading && !commonDataIsInitialised) {
            getCommonData(dispatch);
          }

          if (!appConfigsIsLoading && !appConfigsIsLoaded) {
            getAppConfigs(dispatch);
          }
        }
      }
    }
  }, [
    user,
    customer,
    childrenByCustomer,
    registerIsInitialised,
    paymentMethodIsLoaded,
    paymentMethodIsLoading,
    termsAndConsitionsIsLoaded,
    termsAndConsitionsIsLoading,
    cryptoTokenIsLoading,
    cryptoTokenIsLoaded,
  ]);

  useEffect(() => {
    if (passwordSettingsStatus !== "Pending" && passwordSettingsStatus !== "Success") {
      getPasswordSettings(dispatch);
    }
  });

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (appStatusIntervalRef.current === null) {
      appStatusIntervalRef.current = setInterval(() => {
        getAppStatus(dispatch);
      }, 60 * 1000);

      setTimeout(() => {
        getAppStatus(dispatch);
      }, 100);
    }

    return () => {
      if (appStatusIntervalRef.current !== null) {
        clearInterval(appStatusIntervalRef.current);
      }
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );

  function processUregisteredAvatars() {
    if (!avatarsRequested || !allAvatarsInitialised) {
      var allAvatarsAreInitialized =
        !isLoadingAvatars &&
        avatarsRequested &&
        avatars?.every((avatar: any) => {
          return avatar !== undefined && !avatar.isLoading;
        });

      dispatch(setAllAvatarsInitialised(allAvatarsAreInitialized || false));

      if (!allAvatarsAreInitialized && !avatarsRequested) {
        setUnregisteredAvatars(dispatch, avatars || [], registrationContacts, registrationChildren, documents ?? []);
        setAvatarsRequested(true);
      }
    }
  }

  function processRegisteredContactsAvatars(contacts: Contact[], customerAccountId: number) {
    if (!contactAvatarsRequested) {
      var allAvatarsAreInitialized =
        !isLoadingAvatars &&
        contactAvatarsRequested &&
        childAvatarsRequested &&
        avatars?.every((avatar: any) => {
          return avatar !== undefined && !avatar.isLoading;
        });

      if (!allAvatarsAreInitialized && !contactAvatarsRequested) {
        setRegisteredContactAvatars(dispatch, avatars || [], customerAccountId, contacts, customer?.documents ?? []);
        setContactAvatarsRequested(true);
      }
    }
  }

  function processRegisteredChildrenAvatars(childrenByCustomer: Child[], customerAccountId: number) {
    if (!childAvatarsRequested) {
      var allAvatarsAreInitialized =
        !isLoadingAvatars &&
        contactAvatarsRequested &&
        childAvatarsRequested &&
        avatars?.every((avatar) => {
          return avatar !== undefined && !avatar.isLoading;
        });

      if (!allAvatarsAreInitialized && !childAvatarsRequested) {
        setRegisteredChildAvatars(
          dispatch,
          avatars || [],
          customerAccountId,
          childrenByCustomer,
          customer?.documents ?? []
        );
        setChildAvatarsRequested(true);
      }
    }
  }
}

export default App;
