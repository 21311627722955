import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { FieldArray, Form, Formik, FormikErrors, validateYupSchema, yupToFormErrors } from "formik";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import AddContactsSchema from "./AddContactsSchema";
import { saveContacts } from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/store";
import theme from "../../../theme";
import { AvatarInfo, EntityType } from "../../../types/models";
import { Contact } from "../../../types/types";
import { Avatars } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import { Avatar } from "../../Common/Controls/Avatar";
import Pager from "../../Common/Pager/Pager";

function FormAddContacts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useStyles = makeStyles()((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    textField: {
      marginRight: theme.spacing(2),
    },
    addButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      backgroundColor: "white",
    },
    removeButton: {
      width: 36,
      height: 36,
      backgroundColor: "white",
      alignSelf: "flex-end",
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  }));

  const { classes } = useStyles();
  const contactsAvatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.RegistrationContact)
  );

  const storeContacts = useSelector((state: RootState) => state.register?.contacts);
  const storeChildren = useSelector((state: RootState) => state.register.children);

  function _updateContacts(formContacts: Contact[]): void {
    const removedContacts = storeContacts.filter(
      (storeContact) => formContacts.findIndex((c: any) => c.uniqueId === storeContact.uniqueId) === -1
    );
    saveContacts(dispatch, formContacts, removedContacts);

    navigate("/registration-add-children");
  }

  function _newContact(contacts: Contact[]) {
    var avatarsInUse = contacts.map((c) => c.avatarIndex ?? -1).concat(storeChildren.map((c) => c.avatarIndex ?? -1));
    var availableAvatarIndex = Avatars.getFirstAvailable(avatarsInUse);
    return {
      uniqueId: uuid(),
      firstName: "",
      lastName: "",
      createdAt: dayjs(),
      isPrimary: false,
      avatarIndex: availableAvatarIndex,
      avatarInfo: { index: availableAvatarIndex, isLoading: false },
    };
  }

  return (
    <>
      <Formik
        validate={(value) => {
          try {
            validateYupSchema(value, AddContactsSchema, true, value);
          } catch (err) {
            return yupToFormErrors(err); //for rendering validation errors
          }
          return {};
        }}
        enableReinitialize={true}
        initialValues={{ contacts: storeContacts }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            _updateContacts(values.contacts);
          }, 500);
        }}
      >
        {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, errors, touched }) => {
          return (
            <Form>
              <FieldArray
                name="contacts"
                render={(arrayHelpers) => {
                  return (
                    <>
                      {values.contacts.map((contact, index: number) => {
                        const contactTouched = (touched.contacts?.length && touched.contacts[index]) || {};
                        const contactError = (errors.contacts?.length && errors.contacts[index]) || {};
                        const contactAvatar = contactsAvatars?.find((x) => x.entityId === contact.uniqueId);

                        return (
                          <div key={index} style={{ marginBottom: "2rem" }}>
                            <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                              <Grid item md={3}>
                                <strong>
                                  {index === 0
                                    ? "Primary Contact"
                                    : index === 1
                                    ? "Secondary Contact"
                                    : `Contact ${index + 1}`}
                                </strong>
                              </Grid>
                              <Grid item md>
                                {index !== 0 && (
                                  <Fab
                                    aria-label="add"
                                    className={classes.removeButton}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <IoRemoveOutline size={28} color={theme.palette.primary.dark} />
                                  </Fab>
                                )}
                              </Grid>
                            </Grid>
                            <Grid container spacing={4} paddingTop={1}>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  name={`contacts[${index}].firstName`}
                                  label="First Name"
                                  type="text"
                                  value={contact.firstName}
                                  onChange={handleChange(`contacts[${index}].firstName`)}
                                  onBlur={handleBlur(`contacts[${index}].firstName`)}
                                  helperText={
                                    contactTouched.firstName ? (contactError as FormikErrors<Contact>).firstName : ""
                                  }
                                  error={
                                    contactTouched.firstName &&
                                    Boolean((contactError as FormikErrors<Contact>).firstName)
                                  }
                                  fullWidth
                                  className={classes.textField}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  name={`contacts[${index}].lastName`}
                                  label="Last Name"
                                  type="text"
                                  value={contact.lastName}
                                  onChange={handleChange(`contacts[${index}].lastName`)}
                                  onBlur={handleBlur(`contacts[${index}].lastName`)}
                                  helperText={
                                    contactTouched.lastName ? (contactError as FormikErrors<Contact>).lastName : ""
                                  }
                                  error={
                                    contactTouched.lastName && Boolean((contactError as FormikErrors<Contact>).lastName)
                                  }
                                  fullWidth
                                  className={classes.textField}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Avatar
                                  avatarInfo={
                                    contactAvatar || {
                                      entityType: EntityType.RegistrationContact,
                                      entityId: contact.uniqueId!,
                                      index: contact.avatarIndex,
                                    }
                                  }
                                  onAvatarChange={(avatarInfo: AvatarInfo) => {
                                    setFieldValue(`contacts[${index}].avatarIndex`, avatarInfo.index);
                                  }}
                                  avatarsInUse={values.contacts
                                    .map((c) => c.avatarIndex ?? -1)
                                    .concat(storeChildren.map((c) => c.avatarIndex ?? -1))}
                                />
                              </Grid>
                            </Grid>
                            {values.contacts.length === 1 &&
                              touched.contacts &&
                              errors.contacts !== undefined &&
                              !(errors.contacts instanceof Array) && (
                                <Box paddingTop={2}>
                                  <FormHelperText error={Boolean(touched.contacts && errors.contacts)} required>
                                    {errors.contacts.toString()}
                                  </FormHelperText>
                                </Box>
                              )}
                          </div>
                        );
                      })}
                      <Fab
                        aria-label="add"
                        className={classes.addButton}
                        onClick={() => {
                          arrayHelpers.push(_newContact(values.contacts));
                        }}
                      >
                        <IoAddOutline size={28} color={theme.palette.primary.dark} />
                      </Fab>
                      <Pager>
                        <ButtonSecondary href="/registration-introduction">
                          <strong>Back</strong>
                        </ButtonSecondary>
                        <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                          <strong>Next</strong>
                        </ButtonPrimary>
                      </Pager>
                      <Backdrop className={classes.backdrop} open={isSubmitting}>
                        <CircularProgress color="primary" />
                      </Backdrop>
                    </>
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default FormAddContacts;
