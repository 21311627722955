import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { List } from "linqts";

import UpcomingWeeklyTransaction from "./UpcomingWeeklyTransaction";
import { UpcomingCharge } from "../../types/types";

dayjs.extend(isoWeek);

export interface UpcomingTransactionsProps {
  transactions: UpcomingCharge[];
}

const UpcomingTransactions = (props: UpcomingTransactionsProps) => {
  if (props.transactions && props.transactions.length > 0) {
    const arr = new List<UpcomingCharge>(props.transactions);

    const transactionsByWeek = arr.GroupBy((t) => Number(dayjs(t.date).week()));

    return (
      <>
        {Object.keys(transactionsByWeek)
          .sort((a, b) => Number(a) - Number(b))
          .map((week, index) => {
            return (
              <UpcomingWeeklyTransaction
                key={index}
                week={parseInt(week)}
                transactions={transactionsByWeek[week]}
              ></UpcomingWeeklyTransaction>
            );
          })}
      </>
    );
  } else {
    return <div></div>;
  }
};

export default UpcomingTransactions;
