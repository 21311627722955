import CryptoJS from "crypto-js";

import { CryptoTokenState } from "../store/modules/cryptoToken/cryptoTokenReducer";
import { TokenHttpResponse } from "../store/parentApi";

export class PayloadEncryptHelper {
  public static encrypt(payload: string, tokenValue: string): string {
    const key = CryptoJS.enc.Utf8.parse(tokenValue.substring(0, 32));
    const iv = CryptoJS.enc.Utf8.parse(tokenValue.substring(0, 16));

    const encrypted = CryptoJS.AES.encrypt(payload, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  }
}

export function getAvailableToken(cryptoTokenState: CryptoTokenState): TokenHttpResponse | null {
  if (cryptoTokenState.isLoaded && cryptoTokenState.payload !== null && cryptoTokenState.payload.length > 0) {
    return cryptoTokenState.payload[cryptoTokenState.payload.length - 1];
  }
  return null;
}
