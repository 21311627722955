import { createActionCreator } from "deox";

import { IBreakHttpResponse, Service, TermDate } from "../../../types/types";

export const servicesLoading = createActionCreator("servicesLoading");

export const servicesError = createActionCreator("servicesError");
export const servicesSuccess = createActionCreator(
  "servicesSuccess",
  (resolve) => (services: Service[]) => resolve(services)
);

export const termDatesError = createActionCreator("termDatesError");
export const termDatesSuccess = createActionCreator(
  "termDatesSuccess",
  (resolve) => (termDates: TermDate[]) => resolve(termDates)
);

export const breakDatesError = createActionCreator("breakDatesError");
export const breakDatesSuccess = createActionCreator(
  "breakDatesSuccess",
  (resolve) => (breaks: IBreakHttpResponse[]) => resolve(breaks)
);
