import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { makeStyles } from "tss-react/mui";

import { getGalleryPhoto, getGalleryThumbnail } from "../../store/modules/gallery/galleryActions";
import { IGalleryChildPhoto } from "../../types/types";
import { useViewport } from "../../utils/hooks";

interface IProps {
  customer_account_id: number;
  photo: IGalleryChildPhoto;
}

export default function GalleryPhotoItem(props: IProps) {
  const { width } = useViewport();

  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles()((theme) => {
    return {
      content: {
        backgroundColor: "black",
      },
      closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    };
  });

  const { classes } = useStyles();

  useEffect(() => {
    if (thumbnailUrl === null || thumbnailUrl.length === 0) {
      getGalleryThumbnail(props.customer_account_id, props.photo.uniqueId, 600).then(
        (data: Blob | undefined | null) => {
          if (data) {
            setThumbnailUrl(URL.createObjectURL(data));
          }
        },
        () => {}
      );
    }
  }, [props.photo.uniqueId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
    if (!imageUrl) {
      getGalleryPhoto(props.customer_account_id, props.photo.uniqueId).then(
        (data: Blob | undefined | null) => {
          if (data) {
            setImageUrl(URL.createObjectURL(data));
          }
        },
        (reason) => {}
      );
    }
  };

  return thumbnailUrl != null ? (
    <>
      <CardMedia
        onClick={width >= 720 ? handleClickOpen : undefined}
        component={"img"}
        sx={{
          maxHeight: 400,
          backgroundColor: "#000",
          height: 400,
          width: "100%",
          objectFit: "contain",
          "&:hover": {
            cursor: width >= 720 ? "pointer" : "default",
          },
        }}
        src={thumbnailUrl}
      ></CardMedia>
      {width >= 720 && (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle>
            <Typography variant="h6">{dayjs(props.photo.uploadedAt).format("D MMM YYYY")}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Grid container justifyContent="center">
              <Box paddingTop={2} paddingBottom={2}>
                {imageUrl && (
                  <img src={imageUrl} alt={props.photo.title} style={{ maxWidth: "100%", maxHeight: "640px" }} />
                )}
                {!imageUrl && <CircularProgress color="primary" style={{ color: "white" }} />}
              </Box>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  ) : null;
}
