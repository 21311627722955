import * as Yup from "yup";

const postCodeRe = /^[0-9]{4}$/;

const ChildStep7Schema = Yup.object().shape({
  medicalServiceName: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").lengthCheck(3, 500),
  }),
  doctorName: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").lengthCheck(2, 250),
  }),
  doctorPhone: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").lengthCheck(10, 10),
  }),
  doctorAddress: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").lengthCheck(3, 150),
  }),
  doctorSuburb: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").lengthCheck(3, 40),
  }),
  doctorPostcode: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required").matches(postCodeRe, "Please enter a valid postcode").lengthCheck(4, 50),
  }),
  doctorState: Yup.string().when(["sameMedicalContactAsFirstChild", "firstChildHasMedicalContact"], {
    is: (sameMedicalContactAsFirstChild: boolean, firstChildHasMedicalContact: boolean) =>
      !sameMedicalContactAsFirstChild || !firstChildHasMedicalContact,
    then: Yup.string().required("Required"),
  }),
  //medicareNumber: Yup.string().required("Required").lengthCheck(10, 255),
  medicareNumber: Yup.string()
    .test("blank", "Required", function (value) {
      return value !== null && value?.trim().length! > 0;
    })
    .test("not N/A", "Minimum of 10 Characters and Maximum of 255 Characters", function (value) {
      return (value?.length! >= 10 && value?.length! <= 255) || value === "N/A";
    }),
});

export default ChildStep7Schema;
