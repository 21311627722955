import { buffer } from "stream/consumers";

import { useEffect, useState } from "react";

import { ListItemAvatar } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography/Typography";
import { List as LinqList } from "linqts";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import { useAppDispatch } from "../../store/hooks";
import {
  downloadProgramPreview,
  getActiveProgramByServices,
  getProgramPreview,
} from "../../store/modules/digitalProgram/digitalProgramActions";
import { RootState } from "../../store/store";
import { DigitalProgramDetailsModel, ProgramCareGroup, ProgramCareTypeGroupId } from "../../types/models";
import { IProgramVersionSummaryModel } from "../../types/types";
import { getMondayOfCurrentWeek, getNextMonday } from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import CAToggleButton from "../Common/Buttons/CAToggleButton";
import { AnimatedDialog } from "../Common/Dialogs/AnimatedDialog";
import Spacer from "../Common/Spacers/Spacers";

import RocketeersLogo from "../../assets/logos/Rocketeers_Logo.svg";
import DigitalProgramSummary from "../DigitalProgram/DigitalProgramSummary";
import DigitalProgramReportViewer from "../DigitalProgram/ProgramViewer";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    position: "absolute",
  },
  logo: {
    height: 30,
    "@media screen and (min-width: 720px)": {
      height: 30,
    },
  },
  yourOshc: {
    color: "var(--youroshc-color-primary)",
    cursor: "pointer",
  },

  rocketeers: {
    color: "var(--rocketeers-color-accent-2)",
    cursor: "pointer",
  },

  yourOshcBg: {
    backgroundColor: "var(--youroshc-color-primary)",
  },

  rocketeersBg: {
    backgroundColor: "var(--rocketeers-color-accent-2)",
  },

  toggleBtn: {
    textTransform: "capitalize",
    fontSize: "0.8rem",
    color: "black",
    fontWeight: "bold",
  },
}));

export default function AvailablePrograms() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { width } = useViewport();

  const isMobile = width <= 768;
  const isDesktop = width >= 1280;

  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const serviceProgramSummaryState = useSelector((state: RootState) => state.digitalProgram.serviceProgramSummaryState);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedProgram, setSelectedProgram] = useState<IProgramVersionSummaryModel | undefined>();
  const programs = serviceProgramSummaryState.programsByService;
  const week1 = getMondayOfCurrentWeek();
  const week2 = getNextMonday();

  const [week, setWeek] = useState<string>("1");

  const programsByWeek = new LinqList<IProgramVersionSummaryModel>(
    (programs ?? []).filter((x) => x.weekOf?.date() === (week === "1" ? week1.date() : week2.date()))
  );

  const programsByService = programsByWeek.OrderBy((b) => b.serviceName).GroupBy((g) => g.serviceId);

  const programsByServiceArr = Object.keys(programsByService);

  useEffect(() => {
    if (servicesByCustomer !== null && servicesByCustomer.length > 0) {
      getActiveProgramByServices(
        dispatch,
        customer_account_id!,
        servicesByCustomer.map((s) => s.serviceId),
        week1,
        week2.add(4, "day")
      );
    }
  }, [servicesByCustomer]);

  const handleWeekChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value === week || value === null || value === undefined) return;
    setWeek(value);
  };

  const handleProgramSelected = (program: IProgramVersionSummaryModel) => {
    setSelectedProgram(program);
  };

  return (
    <Grid container spacing={2} direction="column" rowGap={1} sx={{ bgcolor: "background.paper", p: 1, pt: 1 }}>
      <Typography variant="h6" sx={{ textDecoration: "underline" }}>
        <b>Available Programs ({programsByWeek.Count()})</b>
      </Typography>
      <Spacer margin={2} />
      <ToggleButtonGroup
        color="primary"
        value={week}
        exclusive
        unselectable="off"
        fullWidth
        onChange={handleWeekChange}
        aria-label="Program week"
      >
        <CAToggleButton value="1" className={classes.toggleBtn}>
          This Week
        </CAToggleButton>

        <CAToggleButton value="2" className={classes.toggleBtn}>
          Next Week
        </CAToggleButton>
      </ToggleButtonGroup>

      {servicesByCustomer && programs && (
        <List
          sx={{
            width: "100%",
            rowGap: 5,
            paddingBottom: 1,
            position: "relative",
          }}
        >
          {servicesByCustomer.sort((a, b) => a.serviceName.localeCompare(b.serviceName)).map((svc, index) => {
            const servicehasPrograms = svc.serviceId in programsByService;
            return (
              <Box key={`date-${index}`} sx={{ pr: "5px" }}>
                <Typography variant="subtitle2" color="black">
                  <b>{svc.serviceName}</b>
                </Typography>
                {servicehasPrograms && <Box sx={{ p: 1 }}></Box>}
                {servicehasPrograms &&
                  programsByService[svc.serviceId]
                    .sort((a, b) => a.programName.localeCompare(b.programName))
                    .map((program: IProgramVersionSummaryModel, index) => {
                      const isRocketeer = program.careTypeGroupId === ProgramCareTypeGroupId.Rocketeers;
                      return (
                        <ListItemButton
                          key={`program_link-${index}`}
                          disabled={isLoading}
                          sx={{
                            borderWidth: "1px",
                            borderStyle: "solid",
                            marginBottom: 1,
                            borderRadius: "5px",
                            paddingLeft: "6px",
                          }}
                          alignItems="center"
                          className={isRocketeer ? classes.rocketeers : classes.yourOshc}
                          onClick={(event) => {
                            handleProgramSelected(program);
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 10 }}>
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={isRocketeer ? classes.rocketeersBg : classes.yourOshcBg}
                              sx={{
                                height: 50,
                                borderRightWidth: 5,
                                alignContent: "center",
                                margin: 1,
                                borderRadius: 3,
                              }}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={program.programName}
                            sx={{ color: "black", paddingLeft: "6px", fontWeight: "bold" }}
                          />
                          <ListItemAvatar>
                            <img
                              src={isRocketeer ? RocketeersLogo : YourOSHCLogo}
                              alt={`${isRocketeer ? "Rocketeer" : "OSHC"} logo`}
                              width={40}
                            />
                          </ListItemAvatar>
                        </ListItemButton>
                      );
                    })}

                {!servicehasPrograms && (
                  <ListItem
                    sx={{
                      paddingTop: "4px",
                      paddingLeft: "6px",
                    }}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: "medium" }} primary="No programs available" />
                  </ListItem>
                )}
              </Box>
            );
          })}
          {servicesByCustomer.length === 0 && (
            <ListItem
              sx={{
                paddingLeft: "6px",
              }}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "medium" }}
                sx={{ fontSize: "medium" }}
                primary="No services found"
              />
            </ListItem>
          )}
        </List>
      )}

      {selectedProgram && (
        <AnimatedDialog
          maxWidth={isMobile ? "sm" : isDesktop ? "lg" : "md"}
          sx={{ mt: 2 }}
          open={true}
          onClose={(event, reason) => {
            setSelectedProgram(undefined);
          }}
        >
          <DigitalProgramReportViewer
            programName={selectedProgram.programName}
            programVersionId={selectedProgram.programVersionId}
            serviceId={selectedProgram.serviceId}
            formattedName={`${selectedProgram.serviceName}_${
              selectedProgram.programName
            }_${selectedProgram.weekOf?.format("D MMM")}_${selectedProgram.weekOf?.add(4, "day").format("D MMM")}.pdf`}
          />
        </AnimatedDialog>
      )}

      {(serviceProgramSummaryState.isLoading || isLoading) && <LinearProgress color="secondary" />}
    </Grid>
  );
}
