import { NotificationCategory, NotificationChannel } from "../../../types/types";
import { ToggleNotificationSettingHttpRequest } from "../../parentApi";
import api from "./notificationApi";
import {
  loadNotificationError,
  loadNotificationPending,
  loadNotificationSuccess,
  saveNotificationError,
  saveNotificationInit,
  saveNotificationPending,
  saveNotificationSuccess,
} from "./notificationStateActions";
export type NotificationSettingsStatus = "Initial" | "Pending" | "Success" | "Errored";
export function getNotificationSettings(dispatch: any, customerAccountId: number) {
  dispatch(loadNotificationPending());
  api.getNotificationSettings(customerAccountId).then(
    (settings: any) => {
      dispatch(loadNotificationSuccess(settings));
    },
    function (error: any) {
      return dispatch(loadNotificationError());
    }
  );
}

export function toggleNotificationSetting(
  dispatch: any,
  customerAccountId: number,
  channel: NotificationChannel,
  category: NotificationCategory,
  value: boolean
) {
  dispatch(saveNotificationPending());
  api
    .toggleNotificationSetting(
      customerAccountId,
      new ToggleNotificationSettingHttpRequest({
        channel: channel,
        category: category,
        subscribed: value,
      })
    )
    .then(
      function () {
        dispatch(saveNotificationSuccess(category, channel));
      },
      function (error: any) {
        dispatch(saveNotificationError());
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(saveNotificationInit());
      }, 500);
    });
}
