import Chip from "@mui/material/Chip/Chip";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./BookNowButton.module.scss";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { RootState } from "../../store/store";
import { DebtCategoryEnum } from "../../types/types";
import { creditCardIsExpired } from "../../utils/helpers";

export default function BookNowButton() {
  const navigate = useNavigate();
  const customerAccount = useSelector((state: RootState) => state.customer.customer);

  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const ccExpired = creditCardIsExpired(currentPaymentMethod);
  return (
    <Grid container justifyContent="flex-start" p={0} className={styles["bookNow"]}>
      <Typography variant="body2" color={"white"}>
        Book your casual and permanent sessions
      </Typography>
      {!ccExpired && (
        <ButtonPrimary
          id="btnNewBooking"
          onClick={() => {
            navigate("/new-booking");
          }}
          style={{ fontSize: "8px", paddingLeft: "2px", paddingRight: "2px" }}
        >
          <strong>New Booking</strong>
        </ButtonPrimary>
      )}

      {customerAccount?.debtCategoryId === DebtCategoryEnum.CreditInvalidAddress && (
        <Chip
          label="Cannot Book: Invalid Address"
          style={{ backgroundColor: "red", color: "white" }}
          variant="outlined"
        />
      )}
      {ccExpired && (
        <Chip
          label="Cannot Book: Credit Card Expired"
          style={{ backgroundColor: "red", color: "white" }}
          variant="outlined"
        />
      )}
    </Grid>
  );
}
