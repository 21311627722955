import { useEffect, useMemo, useState } from "react";

import AccessTime from "@mui/icons-material/AccessTime";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import { IoCheckmarkCircle, IoCloseCircleOutline, IoEllipseOutline, IoFlagOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";

import { getProgramDateImage } from "../../../store/modules/program/programActions";
import theme from "../../../theme";
import { SelectedServiceProgram } from "../../../types/models";
import { IServiceProgram, ISessionAvailability } from "../../../types/types";
import { getActivityColor, getActivityType, getAvailabilityReasonsText, getStyleColor } from "../../../utils/helpers";
import { ButtonLink } from "../../Common/Buttons/Buttons";

interface Props {
  program: SelectedServiceProgram;
  unAvailableDates: ISessionAvailability[];
  onSelectedDateChanged?: (roll: IServiceProgram, isChecked: boolean) => void;
  isFriendlyFee: boolean;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    padding: theme.spacing(2),
    borderRadius: 3,
    marginBottom: theme.spacing(1),
  },
  button: {
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  pill: {
    maxWidth: 60,
    "@media screen and (min-width: 1280px)": {
      maxWidth: 200,
    },
  },
  image: {
    width: 60,
    height: 45,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "block",
    marginBottom: theme.spacing(1),
    borderRadius: 3,

    "@media screen and (min-width: 1280px)": {
      width: 200,
      height: 150,
    },
  },
}));

const ServiceProgramSelection = (props: Props) => {
  const { classes } = useStyles();
  const {
    unAvailableDates,
    program: { program },
  } = props || {};

  const unvDate = useMemo(
    () =>
      unAvailableDates.find(
        (d) => d.rollId === program.rollId && d.date.isSame(program.date, "date") && !d.isAvailableForCasualBooking
      ),
    [unAvailableDates]
  );

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (imageUrl === null || imageUrl.length === 0) {
      getProgramDateImage(program.programDateId).then((data: Blob | undefined) => {
        if (data) {
          setImageUrl(URL.createObjectURL(data));
        }
      });
    }
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const handleOnClick = () => setIsVisible(!isVisible);

  return (
    <>
      <ListItem alignItems="flex-start" className={classes.listItem}>
        <ListItemAvatar style={{ paddingRight: theme.spacing(2) }}>
          <span
            aria-label={program.programName}
            role="img"
            className={classes.image}
            style={
              imageUrl !== null && imageUrl.length !== 0
                ? { backgroundImage: `url(${imageUrl})` }
                : { backgroundColor: getStyleColor("--custom-color-dark-grey") }
            }
          ></span>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid container spacing={1}>
              <Grid item xs>
                <strong>{program.programName}</strong>
                <br />
                <span style={{ color: "black" }}>{program?.date?.format("ddd, D MMM")}</span>
                {program?.programDescription && <Box marginTop={2} />}
                {program?.programDescription && (
                  <div
                    className="dangerouslySetInnerHTML"
                    dangerouslySetInnerHTML={{
                      __html: program.programDescription,
                    }}
                  ></div>
                )}
                {program?.programPrivateDescription && (
                  <>
                    <div>
                      <ButtonLink
                        style={{ backgroundColor: getStyleColor("--custom-color-mid-grey") }}
                        onClick={handleOnClick}
                      >
                        Show More
                      </ButtonLink>
                    </div>
                    {isVisible && (
                      <>
                        <div
                          className="dangerouslySetInnerHTML"
                          dangerouslySetInnerHTML={{
                            __html: program.programPrivateDescription,
                          }}
                        ></div>
                        <Box marginBottom={4} />
                      </>
                    )}
                  </>
                )}
                <Box marginTop={1} />
                {DisplayActivityType(program)}
                <Grid container alignItems="center">
                  <AccessTime fontSize="small" style={{ marginRight: 10 }} />
                  <span style={{ color: "black" }}>
                    {dayjs(program.session.startTime).format("hh:mm a") +
                      " - " +
                      dayjs(program.session.endTime).format("hh:mm a")}
                  </span>
                </Grid>
                <p>
                  <strong>
                    <NumericFormat
                      displayType="text"
                      value={
                        props.isFriendlyFee || !program.isCasualFee
                          ? program.session.primaryFeeAmount
                          : program.session.casualFeeAmount
                      }
                      decimalScale={2}
                      prefix="$"
                      suffix="*"
                    />
                  </strong>
                </p>
                {unvDate && (
                  <Grid container alignItems="flex-start">
                    <Grid item>
                      <IoFlagOutline color={theme.palette.error.main} style={{ marginTop: 4, marginRight: 10 }} />
                    </Grid>
                    <Grid item xs>
                      <span style={{ color: theme.palette.error.main }}>
                        {getAvailabilityReasonsText(unvDate.reasonForCasualBooking)}
                      </span>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Checkbox
                  disabled={unvDate !== undefined}
                  checked={props.program.isSelected}
                  icon={
                    unvDate !== undefined ? (
                      <IoCloseCircleOutline size={32} />
                    ) : (
                      <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                    )
                  }
                  checkedIcon={<IoCheckmarkCircle size={32} />}
                  onChange={(_ev, chk) =>
                    props.onSelectedDateChanged ? props.onSelectedDateChanged(program, chk) : {}
                  }
                />
              </Grid>
            </Grid>
          }
        />
      </ListItem>
    </>
  );
};

function DisplayActivityType(program: IServiceProgram) {
  var actType = getActivityType(program.activityCode);
  var color = getActivityColor(actType);
  return (
    <Box
      marginBottom={2}
      height={30}
      borderRadius={3}
      borderColor={color}
      style={{ backgroundColor: color, display: "flex", justifyContent: "center", alignItems: "center", maxWidth: 250 }}
    >
      <span style={{ color: actType === "Incursion" || actType === "Excursion" ? "white" : "black" }}>{actType}</span>
    </Box>
  );
}

export default ServiceProgramSelection;
