import { FileResponse, IGalleryChildPhoto } from "../../../types/types";
import { GalleryPhoto } from "../../parentApi";
import api from "./galleryApi";

export function getCustomerChildrenGallery(
  customerAccountId: number,
  childId: number | null,
  skip: number,
  limit: number
) {
  if (childId == null) {
    return api.getGalleryPage(customerAccountId, skip, limit).then(
      (response: GalleryPhoto[] | null) => (response ? (response as IGalleryChildPhoto[]) : null),
      (response: any) => null
    );
  }

  return api.getGalleryPhotos(customerAccountId, skip, limit, childId).then(
    (response: GalleryPhoto[] | null) => (response ? (response as IGalleryChildPhoto[]) : null),
    (response: any) => null
  );
}
export function getGalleryPhoto(customer_account_id: number, photoId: string) {
  return api
    .downloadBlob(customer_account_id, photoId)
    .catch((reason) => null)
    .then(
      (resp: FileResponse | null) => resp?.data,
      (reason: any) => null
    );
}

export function getGalleryThumbnail(customer_account_id: number, photoId: string, width: number) {
  return api
    .downloadBlobOptimized(customer_account_id, photoId, width)
    .catch(() => null)
    .then(
      (resp: FileResponse | null) => resp?.data,
      (reason: any) => null
    );
}
