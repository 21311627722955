import React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { formatCurrency } from "../../utils/helpers";

export default function Balances() {
  const balance = useSelector((state: RootState) => state.balance.payload);
  const charges = useSelector((state: RootState) => state.dueCharges.dueCharges);
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <strong className={`color-accent-2 small`}>CURRENT BALANCE</strong>
        <br />
        <Typography variant="h5">{balance ? formatCurrency(balance?.balance) : "$0.00"}</Typography>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem />
      <Grid item>
        <strong className={`color-accent-2 small`}>YOUR NEXT BILL</strong>
        <br />
        {charges && charges.length > 0 ? (
          <>
            <Typography variant="h5">{formatCurrency(charges[0].balance)}</Typography>
            <strong className={`color-accent-2 small`}>
              {charges[0].isOverDue ? "NOW" : dayjs(charges[0].dueDate).format("ddd DD")}
            </strong>
          </>
        ) : (
          <Typography variant="h5">{formatCurrency(0)}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
