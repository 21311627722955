import { createReducer } from "deox";

import { Service } from "../../../types/types";
import { servicesError, servicesLoading, servicesSuccess } from "./servicesStateActions";

export interface ServicesState {
  servicesByCustomer: Service[] | null;
  isLoadingServices: boolean;
}

export const initialState: ServicesState = {
  servicesByCustomer: null,
  isLoadingServices: false,
};

const serviceReducer = createReducer(initialState, (handleAction) => [
  handleAction(servicesLoading, (state) => ({
    ...state,
    isLoadingServices: true,
  })),
  handleAction(servicesSuccess, (state, action) => ({
    ...state,
    isLoadingServices: false,
    servicesByCustomer: action.payload,
  })),
  handleAction(servicesError, (state) => ({
    ...state,
    isLoadingServices: false,
    servicesByCustomer: null,
  })),
]);

export default serviceReducer;
