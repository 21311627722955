import { createReducer } from "deox";

import { IBreakHttpResponse } from "../../../types/types";
import { breakDatesError, breakDatesSuccess } from "./servicesStateActions";

export interface TermBreakState {
  breaks: IBreakHttpResponse[];
  isLoading: boolean;
}

export const initialState: TermBreakState = {
  breaks: [],
  isLoading: false,
};

const breaksReducer = createReducer(initialState, (handleAction) => [
  handleAction(breakDatesSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    breaks: action.payload,
  })),
  handleAction(breakDatesError, (state) => ({
    ...state,
    isLoading: false,
    breaks: new Array<IBreakHttpResponse>(),
  })),
]);

export default breaksReducer;
