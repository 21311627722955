import { createReducer } from "deox";

import { BookingPreview } from "../../../types/types";
import {
  LoadCreateRecurringPreview,
  LoadCreateRecurringPreviewError,
  LoadCreateRecurringPreviewInitial,
  LoadingCreateRecurringPreview,
} from "./bookingStateActions";

export interface BookingPreviewState {
  bookings: BookingPreview[];
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
}

export const initialState: BookingPreviewState = {
  bookings: new Array<BookingPreview>(),
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
};

const bookingCreateRecurringPreviewReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadCreateRecurringPreview, (state, { payload }) => ({
    ...state,
    bookings: payload,
    isErrorState: false,
    isInitialised: true,
    isLoading: false,
  })),
  handleAction(LoadCreateRecurringPreviewError, (state) => ({
    ...state,
    bookings: new Array<BookingPreview>(),
    isErrorState: true,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadCreateRecurringPreviewInitial, (state) => ({
    ...state,
    bookings: new Array<BookingPreview>(),
    isErrorState: false,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadingCreateRecurringPreview, (state) => ({
    ...state,
    isErrorState: false,
    isInitialised: false,
    isLoading: true,
  })),
]);

export default bookingCreateRecurringPreviewReducer;
