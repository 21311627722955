import { useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import BillingCycle from "./BillingCycle";
import { loadDueCharges, loadMoreDueCharges } from "../../store/modules/dueCharges/dueChargesActions";
import { RootState } from "../../store/store";
import theme from "../../theme";

function DueCharges(): JSX.Element | null {
  const dispatch = useDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const dueCharges = useSelector((state: RootState) => state.dueCharges.dueCharges);
  const isDueMoreLoading = useSelector((state: RootState) => state.dueCharges.isMoreLoading);
  const isDueChargesLoaded = useSelector((state: RootState) => state.dueCharges.isLoaded);
  const pageIndex = useSelector((state: RootState) => state.dueCharges.page);
  const isLastPage = useSelector((state: RootState) => state.dueCharges.isLastPage);

  useEffect(() => {
    if (customer_account_id && !isDueChargesLoaded && pageIndex === 1) {
      loadDueCharges(dispatch, customer_account_id, 5, 1);
    }
  }, [customer_account_id, isDueChargesLoaded, pageIndex]);

  const handleShowMore = () => {
    loadMoreDueCharges(dispatch, customer_account_id ?? 0, 5, pageIndex + 1);
  };

  const LoadMoreButton = () => {
    if (isDueMoreLoading === false && isLastPage === false) {
      return (
        <Button onClick={handleShowMore}>
          <strong className="color-accent-2">Load more</strong>
        </Button>
      );
    }
    return null;
  };

  if (dueCharges && dueCharges.length > 0) {
    return (
      <>
        <h2 className="h4">Due Charges</h2>
        {dueCharges.map((item) => (
          <>
            <BillingCycle
              key={item.billingCycleId}
              billingCycleId={item.billingCycleId}
              customerAccountId={customer_account_id as number}
              billingPeriodEnd={item.billingPeriodEnd}
              totalAmount={item.totalAmount ?? 0}
              billingCycleStatus={item.isStatementCleared ? "" : item.isOverDue ? "OverDue" : item.isDue ? "Due" : ""}
              statusColor={
                item.isStatementCleared
                  ? theme.palette.success.light
                  : item.isOverDue
                  ? theme.palette.error.light
                  : item.isDue
                  ? theme.palette.warning.light
                  : theme.palette.info.light
              }
              totalBookingsAmount={item.transactions
                .filter((t) => t.childId != null && t.childId !== undefined)
                .reduce((a, b) => a + (b.totalMovement ?? 0), 0)}
              totalCCSAndACCSAmount={item.transactions.reduce((a, b) => a + (b.ccs + b.accs), 0)}
              totalOtherFees={!!item.totalAmount ? item.totalAmount : 0}
              totalNumberOfBookings={item.transactions.length}
              transactions={item.transactions}
            ></BillingCycle>
            <Box paddingTop={1} />
          </>
        ))}
        <Grid container justifyContent="center">
          <LoadMoreButton />
        </Grid>
        <Box marginBottom={3} />
      </>
    );
  }
  return null;
}

export default DueCharges;
