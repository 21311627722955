import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Form, Formik } from "formik";
import { merge } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import * as actions from "../../../store/modules/register/registerActions";
import { Contact } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import ContactAuthorisation from "../../Contacts/ContactAuthorisation";

interface Props {
  contact: Contact;
  step: string;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  ul: {
    marginBottom: theme.spacing(2),
  },
}));

function FormContactStep3({ contact, id }: Props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  let navigate = useNavigate();

  function updateContact(values: Contact) {
    actions.saveContact(dispatch, values);
    navigate(`/registration-pending-information`);
  }

  function goBack() {
    if (contact.isPrimary) {
      navigate(`/registration-contact-step/2/${id}`);
    } else {
      navigate(`/registration-contact-step/1/${id}`);
    }
  }

  const formikValues = {
    authorisation: {
      canManageAccount: false,
      canMakeAndChangeBookings: false,
      consentToAdministerMedicine: false,
      consentToMedicalTreatment: false,
      canApproveAmbulance: false,
      canPickUpChild: false,
      consentToTakeOutsidePremises: false,
      consentForTransportation: false,
    },
  };
  const initialValues = merge(formikValues, contact);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateContact(values);
        }, 500);
      }}
    >
      {({ handleBlur, handleChange, setFieldValue, submitForm, isSubmitting, values, touched, errors }) => {
        return (
          <Form>
            <ContactAuthorisation
              classes={classes}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              errors={errors}
            />
            <Pager>
              <ButtonSecondary onClick={goBack}>
                <strong>Back</strong>
              </ButtonSecondary>
              <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                <strong>Next</strong>
              </ButtonPrimary>
            </Pager>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FormContactStep3;
