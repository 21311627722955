import React, { createRef, useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import { IoBusinessOutline, IoCardOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import PaymentMethodSchema from "./PaymentMethodSchema";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import BankAccount, { IBankAccountProps, IBankAccoutRef } from "../../components/Payment/BankAccount";
import CreditCardForm, { ICardRef, ICreditCardProps } from "../../components/Payment/CreditCard";
import { CardLayout } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import {
  addBankPaymentMethod,
  addCardPaymentMethod,
  getCurrentPaymentMethod,
} from "../../store/modules/paymentMethod/paymentMethodActions";
import { RootState } from "../../store/store";
import { directDebitDetails } from "../../utils/constants";
import { getStyleColor } from "../../utils/helpers";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    control: {
      padding: theme.spacing(2),
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    formLegend: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
    },
  };
});

function DefaultPayment() {
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  return (
    <Grid item xs={12}>
      {currentPaymentMethod ? (
        <Card elevation={0} style={{ backgroundColor: getStyleColor("--custom-color-light-grey") }}>
          <Box p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                {currentPaymentMethod && currentPaymentMethod.paymentType.toLowerCase() === "creditcard" ? (
                  <IoCardOutline size={24} color="black" style={{ marginTop: 6 }} />
                ) : (
                  <IoBusinessOutline size={24} color="black" style={{ marginTop: 6 }} />
                )}
              </Grid>
              <Grid item xs>
                <span>{currentPaymentMethod?.paymentTypeName} ending with</span>
              </Grid>
              <Grid item>
                <Box paddingLeft={2}>
                  <Chip label={currentPaymentMethod?.masked} color="secondary" style={{ borderRadius: 3 }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      ) : (
        <Card elevation={0} style={{ backgroundColor: getStyleColor("--custom-color-light-grey") }}>
          <Box p={2}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <span>"No active payment method"</span>
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
    </Grid>
  );
}

function PaymentMethod() {
  const { classes } = useStyles();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const isLoaded = useSelector((state: RootState) => state.paymentMethod.isLoaded);
  const isProcessing = useSelector((state: RootState) => state.paymentMethod.isSaving);
  const isSubmitted = useSelector((state: RootState) => state.paymentMethod.isSaved);
  const failed = useSelector((state: RootState) => state.paymentMethod.isSavingError);

  const dispatch = useAppDispatch();
  const [isAddingPayment, setIsAddingPayment] = React.useState(false);

  const cardRef = createRef<ICardRef>();
  const bankRef = createRef<IBankAccoutRef>();

  useEffect(() => {
    if (customer_account_id && !currentPaymentMethod && !isLoaded) {
      getCurrentPaymentMethod(dispatch, customer_account_id);
    }
  }, [currentPaymentMethod, customer_account_id, isLoaded]);

  useEffect(() => {
    if (isSubmitted || failed) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  });

  function savePayment(values: any): void {
    switch (values.method) {
      case "BankAccount":
        addBankPaymentMethod(dispatch, customer_account_id ?? 0, customer_contact_id ?? 0, {
          accountName: values.account.accountName,
          accountNumber: values.account.accountNumber.toString(),
          bsb: values.account.bsb.toString(),
        });
        break;

      case "CreditCard":
        addCardPaymentMethod(dispatch, customer_account_id ?? 0, customer_contact_id ?? 0, {
          month: parseInt(values.card.expiry.substr(0, 2)),
          year: parseInt(values.card.expiry.substring(3, 7)),
          name: values.card.fullName,
          number: values.card.cardNumber,
          cvc: values.card.cvcNumber,
        });

        break;
    }
  }
  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <h1 className={`h2`}>Payment Details</h1>
      </CardLayout.Header>
      <CardLayout.Body style={{ paddingTop: 0 }}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box paddingTop={4} paddingBottom={2}>
                  <strong>Default payment method</strong>
                </Box>
              </Grid>
              <DefaultPayment />
              {!isAddingPayment && (
                <Grid item xs={12}>
                  <Button
                    onClick={() => setIsAddingPayment(true)}
                    style={{ textTransform: "none", textDecorationLine: "underline" }}
                    component={Link}
                  >
                    {currentPaymentMethod ? "Change my default payment method" : "Add a default payment method"}
                  </Button>
                </Grid>
              )}
              {isAddingPayment && (
                <Grid item xs={12}>
                  <Formik
                    validationSchema={PaymentMethodSchema}
                    initialValues={{
                      method: "CreditCard",
                      termsAccepted: false,
                      card: {
                        cardType: "Mastercard",
                        cardNumber: "",
                        fullName: "",
                        expiry: "",
                        cvcNumber: "",
                        path: "card.",
                      } as ICreditCardProps,
                      account: {
                        path: "account.",
                        isOneOffPayment: false,
                        accountName: "",
                        accountNumber: undefined,
                        bsb: undefined,
                      } as IBankAccountProps,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      var isValid = false;
                      if (cardRef != null && cardRef.current) {
                        isValid = cardRef.current.validate();
                      }
                      if (bankRef != null && bankRef.current) {
                        isValid = bankRef.current.validate();
                      }

                      if (isValid) {
                        setTimeout(() => {
                          setSubmitting(false);
                          savePayment(values);
                        }, 500);
                      }
                    }}
                  >
                    {({ handleChange, setFieldValue, resetForm, values }) => (
                      <Form autoComplete="off" noValidate>
                        <Box paddingTop={4} />
                        <NotificationMessage reset={() => setIsAddingPayment(false)} />
                        <FormControl component="fieldset">
                          <legend className={classes.formLegend}>Direct debit options</legend>
                          <RadioGroup
                            aria-label="method"
                            id="method"
                            name="method"
                            value={values.method}
                            onChange={(e) => {
                              resetForm();
                              setFieldValue("method", e.target.value);
                            }}
                          >
                            <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                            <FormControlLabel value="BankAccount" control={<Radio />} label="Bank Account" />
                          </RadioGroup>
                        </FormControl>
                        {values.method === "CreditCard" && <CreditCardForm ref={cardRef} {...values.card} />}
                        {values.method === "BankAccount" && <BankAccount ref={bankRef} {...values.account} />}
                        <legend className={classes.formLegend}>Terms and conditions</legend>
                        <Grid item xs={12}>
                          <div dangerouslySetInnerHTML={{ __html: directDebitDetails || "" }}></div>
                        </Grid>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.termsAccepted}
                              onChange={handleChange}
                              name="termsAccepted"
                              color="primary"
                            />
                          }
                          label="I agree to the terms and conditions"
                        />
                        <ErrorMessage name="termsAccepted">
                          {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
                        </ErrorMessage>
                        <Box paddingTop={2} />
                        <Grid container justifyContent="space-between" alignItems="center">
                          <ButtonSecondary
                            type="button"
                            onClick={() => setIsAddingPayment(false)}
                            disabled={isProcessing}
                          >
                            <strong>Cancel</strong>
                          </ButtonSecondary>
                          <ButtonPrimary type="submit" disabled={isProcessing}>
                            <strong>Update</strong>
                          </ButtonPrimary>
                        </Grid>
                        <Backdrop className={classes.backdrop} open={isProcessing}>
                          <CircularProgress color="primary" />
                        </Backdrop>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}
const NotificationMessage = (props: any) => {
  const { resetForm } = useFormikContext();
  const [successLocal, setSuccessLocal] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const isSubmitted = useSelector((state: RootState) => state.paymentMethod.isSaved);
  const isError = useSelector((state: RootState) => state.paymentMethod.isSavingError);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (isSubmitted) {
      setSuccessLocal(isSubmitted);
      resetForm({});

      setTimeout(() => {
        //dispatch(paymentMethodSaveInit());
        props.reset();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);
  useEffect(() => {
    if (isError) {
      setFailed(isError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  return successLocal ? (
    <Alert severity="success">Your payment method has been saved.</Alert>
  ) : failed ? (
    <Alert severity="error">An error has been encountered.</Alert>
  ) : (
    <div></div>
  );
};
export default PaymentMethod;
