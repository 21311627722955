import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { queryError, queryInit, querySaving, querySubmitted } from "./transactionQueryStateActions";
export interface RaiseQueryState {
  isError: boolean;
  isSaving: boolean;
  isSaved: boolean;
  billingId: number | null;
}

export const initialState: RaiseQueryState = {
  isError: false,
  isSaving: false,
  isSaved: false,
  billingId: null,
};

const raiseQueryReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    querySubmitted,
    (state, action): RaiseQueryState => ({
      ...state,
      isSaving: false,
      isError: false,
      isSaved: true,
      billingId: action.payload,
    })
  ),
  handleAction(
    querySaving,
    (state): RaiseQueryState => ({
      ...state,
      isSaving: true,
      isError: false,
      isSaved: false,
      billingId: null,
    })
  ),
  handleAction(
    queryError,
    (state, action): RaiseQueryState => ({
      ...state,
      isError: true,
      isSaving: false,
      isSaved: false,
      billingId: action.payload,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(queryInit, () => initialState),
]);

export default raiseQueryReducer;
