import api from "./cryptoTokenApi";
import { cryptoTokenError, cryptoTokenLoaded, cryptoTokenLoading } from "./cryptoTokenStateActions";
import { TokenHttpResponse } from "../../parentApi";

export function getCryptoTokens(dispatch: any) {
  dispatch(cryptoTokenLoading());
  api.getTokens().then(
    (value: TokenHttpResponse[] | null) => {
      if (value !== null && value.length > 0) {
        dispatch(cryptoTokenLoaded(value));
      } else {
        dispatch(cryptoTokenError());
      }
    },
    function (error: any) {
      dispatch(cryptoTokenError());
    }
  );
}
