import { useEffect, useState } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import { PossibleDuplicatedAccount } from "../../components/Common/Controls/PossibleDuplicatedAccount";
import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import Pager from "../../components/Common/Pager/Pager";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { RegistrationTimeline } from "../../components/Common/Timelines/Timelines";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import { signinSilent } from "../../store/modules/auth/authServices";
import {
  checkPossibleDuplicatedAccount,
  checkRequirePaymentConfiguration,
  complete,
} from "../../store/modules/register/registerActions";
import { RootState } from "../../store/store";
import { ChildHelper } from "../../utils/childHelper";
import { ContactHelper } from "../../utils/contactHelper";
import { PrimaryContactHelper } from "../../utils/primaryContactHelper";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
}));

function Introduction() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const children = useSelector((state: RootState) => state.register?.children);
  const contacts = useSelector((state: RootState) => state.register?.contacts);
  const { isCompleted, isCompleting, possibleDuplicatedAccount, requirePaymentConfiguration } = useSelector(
    (state: RootState) => state.register
  );

  useEffect(() => {
    checkPossibleDuplicatedAccount(dispatch);
    checkRequirePaymentConfiguration(dispatch);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      signinSilent()
        .then(() => {
          navigate("/registration-complete");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isCompleted]);

  function completeRegistration(): void {
    complete(dispatch, children, contacts, undefined);
  }

  var nextPageUrl = null;
  var firstUncompletedContact = contacts.find(
    (contact: any) =>
      (contact.isPrimary ? PrimaryContactHelper.percentCompleted(contact) : ContactHelper.percentCompleted(contact)) !==
      100
  );
  if (firstUncompletedContact) {
    var index = contacts.indexOf(firstUncompletedContact);
    nextPageUrl = firstUncompletedContact.isPrimary
      ? PrimaryContactHelper.getFirstUncompletedStep(firstUncompletedContact, index)
      : ContactHelper.getFirstUncompletedStep(firstUncompletedContact, index);
  } else {
    var firstUncompletedChild = children?.find((child: any) => ChildHelper.percentCompleted(child) !== 100);
    if (firstUncompletedChild) {
      var childIndex = children.indexOf(firstUncompletedChild);
      nextPageUrl = ChildHelper.getFirstUncompletedStep(firstUncompletedChild, childIndex);
    }
  }

  var informationComplete = false;
  if (nextPageUrl == null) {
    nextPageUrl = "/registration-payment";
    informationComplete = true;
  }

  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage>
        <RegistrationHeader heading="Pending Information">
          <p>You can come back at any time to complete registration. We’ll save where you get up to.</p>
        </RegistrationHeader>
        <RegistrationTimeline contacts={contacts} children={children} />

        {possibleDuplicatedAccount && (
          <Grid container justifyContent="center">
            <PossibleDuplicatedAccount />
          </Grid>
        )}
        {!possibleDuplicatedAccount && (
          <Pager>
            <ButtonSecondary href="/registration-add-children">
              <strong>Back</strong>
            </ButtonSecondary>
            {contacts.length > 0 && (!informationComplete || requirePaymentConfiguration) && (
              <ButtonPrimary href={nextPageUrl}>
                <strong>Next</strong>
              </ButtonPrimary>
            )}
            {informationComplete && !requirePaymentConfiguration && (
              <ButtonPrimary onClick={completeRegistration}>
                <strong>Next</strong>
              </ButtonPrimary>
            )}
          </Pager>
        )}
        <NotificationMessage />
        <Backdrop className={classes.backdrop} open={isCompleting}>
          <CircularProgress color="primary" />
        </Backdrop>
      </RegistrationPage>
    </LayoutAccent1>
  );
}

const NotificationMessage = () => {
  const [failed, setFailed] = useState(false);
  const isRegistrationError = useSelector((state: RootState) => state.register.isRegistrationError);

  useEffect(() => {
    if (isRegistrationError) {
      setFailed(isRegistrationError);
    }
  }, [isRegistrationError]);

  return failed ? (
    <Alert severity="error">An error ocurred while trying complete registration. Please try again.</Alert>
  ) : (
    <div></div>
  );
};

export default Introduction;
