import React from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getIn } from "formik";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { SchoolHttpResponse } from "../../store/parentApi";
import { RootState } from "../../store/store";
import { AvatarInfo } from "../../types/models";
import { DocumentTypes } from "../../types/types";
import { isNullOrEmpty } from "../../utils/stringUtils";
import { Address } from "../Common/Controls/Address";
import { Avatar } from "../Common/Controls/Avatar";
import { DocumentManager } from "../Common/Controls/DocumentManager";
import { GenderSelector } from "../Common/Controls/GenderSelector";
import { SchoolAutocomplete } from "../Common/Controls/SchoolAutocomplete";
import ReadOnlyFields from "../Common/Tooltip/ReadOnlyFields";

interface Props {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  primaryContact: any;
  firstChild: any;
  courtOrderDocuments: any;
  dispatch: any;
  firstNameReadOnly?: boolean;
  lastNameReadOnly?: boolean;
  birthdayReadOnly?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: { paddingLeft: 0 },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: { marginBottom: theme.spacing(2) },
  ul: {
    marginBottom: theme.spacing(2),
  },
}));

function GeneralInformation({
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  values,
  errors,
  primaryContact,
  firstChild,
  courtOrderDocuments,
  firstNameReadOnly = false,
  lastNameReadOnly = false,
  birthdayReadOnly = false,
}: Props) {
  const { states, popularLanguages } = useSelector((state: RootState) => state?.commonData?.data);
  const { classes } = useStyles();

  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find((x) => x.entityType === values.entityType && x.entityId === values.entityId)
  );

  if (childAvatar) {
    childAvatar.customerAccountId = primaryContact.customerAccountId;
  }
  function handleGenderChange(event: React.ChangeEvent<any>) {
    handleChange("genderId")(event);
    setFieldValue("otherGender", "");
  }
  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>
            General Information {(firstNameReadOnly || lastNameReadOnly || birthdayReadOnly) && <ReadOnlyFields />}
          </legend>
          <Grid container>
            <Grid item xs={6} md={8}>
              <TextField
                id="firstName"
                label="First Name *"
                type="text"
                value={values.firstName}
                onChange={handleChange("firstName")}
                onBlur={handleBlur("firstName")}
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
                fullWidth
                className={classes.textField}
                disabled={firstNameReadOnly}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Avatar
                avatarInfo={
                  childAvatar || {
                    entityType: values.entityType,
                    entityId: values.entityId,
                    index: values.avatarIndex,
                    customerAccountId: primaryContact.customerAccountId,
                  }
                }
                onAvatarChange={(avatarInfo: AvatarInfo) => {
                  setFieldValue("avatarIndex", avatarInfo.index);
                }}
              />
            </Grid>
          </Grid>
          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            value={values.lastName}
            onChange={handleChange("lastName")}
            onBlur={handleBlur("lastName")}
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName && Boolean(errors.lastName)}
            fullWidth
            className={classes.textField}
            required
            disabled={lastNameReadOnly}
            inputProps={{
              maxLength: 100,
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              format="DD/MM/YYYY"
              label="Date of Birth"
              value={values.birthday || null}
              maxDate={dayjs().subtract(3, "year")}
              onChange={(birthday) => {
                setFieldValue("birthday", birthday?.clone());
              }}
              slotProps={{
                textField: {
                  helperText: touched.birthday ? errors.birthday : "",
                  error: touched.birthday && Boolean(errors.birthday),
                  required: true,
                  id: "dateOfBirth",
                },
              }}
              disabled={birthdayReadOnly}
            />
          </LocalizationProvider>
          {/* <DatePicker
            format="DD/MM/YYYY"
            // margin="normal"
            // id="dateOfBirth"
            label="Date of Birth"
            value={values.birthday || null}
            maxDate={dayjs().subtract(3, "year")}
            onChange={(birthday) => {
              setFieldValue("birthday", birthday?.clone());
            }}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change birthday date",
            }}
            helperText={touched.birthday ? errors.birthday : ""}
            error={touched.birthday && Boolean(errors.birthday)}
            required
          /> */}
          <FormControl variant="filled" className={classes.formControl} fullWidth required>
            <GenderSelector
              id="genderId"
              label="Gender"
              value={values.genderId || ""}
              onChange={handleGenderChange}
              onBlur={handleBlur("genderId")}
              helperText={getIn(touched, "genderId") ? getIn(errors, "genderId") : ""}
              error={getIn(touched, "genderId") && Boolean(getIn(errors, "genderId"))}
            ></GenderSelector>
          </FormControl>
          {values.genderId === 3 && (
            <TextField
              id="otherGender"
              label="Other Gender"
              type="text"
              value={values.genderId === 3 ? values.otherGender : undefined}
              onChange={handleChange("otherGender")}
              onBlur={handleBlur("otherGender")}
              helperText={touched.otherGender ? errors.otherGender : ""}
              error={touched.otherGender && Boolean(errors.otherGender)}
              fullWidth
              className={classes.textField}
              inputProps={{
                maxLength: 100,
              }}
            />
          )}
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>School Details</legend>
          <Container className={classes.container}>
            {firstChild && firstChild.schoolId !== null && (
              <Container className={classes.container}>
                <FormControlLabel
                  id="sameSchoolDetailsAsFirstChild"
                  control={<Checkbox />}
                  label={`Same as ${firstChild.firstName} ${firstChild.lastName}`}
                  checked={values.sameSchoolDetailsAsFirstChild}
                  onChange={(e, checked) => {
                    if (checked) {
                      setFieldValue("schoolName", firstChild.schoolName);
                      setFieldValue("schoolId", firstChild.schoolId);
                      setFieldValue("sameSchoolDetailsAsFirstChild", true);
                    } else {
                      setFieldValue("sameSchoolDetailsAsFirstChild", false);
                    }
                  }}
                />
                <Box paddingBottom={3} />
              </Container>
            )}

            {!values.sameSchoolDetailsAsFirstChild && (
              <SchoolAutocomplete
                id="schoolName"
                label="School Name"
                schoolId={values.schoolId}
                schoolName={values.schoolName}
                onChange={(_event: object, values: SchoolHttpResponse | null) => {
                  if (values !== null) {
                    setFieldValue("schoolName", values?.schoolName);
                    setFieldValue("schoolId", values?.schoolId);
                  }
                }}
                helperText={touched.schoolId ? errors.schoolId : ""}
                error={touched.schoolId && Boolean(errors.schoolId)}
              />
            )}
          </Container>
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Residential Details</legend>

          {primaryContact.residentialAddress &&
            !isNullOrEmpty(primaryContact.residentialAddress.streetLine1) &&
            !isNullOrEmpty(primaryContact.residentialAddress.suburb) &&
            !isNullOrEmpty(primaryContact.residentialAddress.state) &&
            !isNullOrEmpty(primaryContact.residentialAddress.postCode) && (
              <Container className={classes.container}>
                <FormControlLabel
                  id="sameAddressDetailsAsPrimary"
                  control={<Checkbox />}
                  label={`Same as ${primaryContact.firstName} ${primaryContact.lastName}`}
                  checked={values.sameAddressDetailsAsPrimary}
                  onChange={(e, checked) => {
                    if (checked) {
                      setFieldValue("residentialAddress.streetLine1", primaryContact.residentialAddress.streetLine1);
                      setFieldValue("residentialAddress.suburb", primaryContact.residentialAddress.suburb);
                      setFieldValue("residentialAddress.state", primaryContact.residentialAddress.state);
                      setFieldValue("residentialAddress.postCode", primaryContact.residentialAddress.postCode);
                      setFieldValue("sameAddressDetailsAsPrimary", true);
                    } else {
                      setFieldValue("sameAddressDetailsAsPrimary", false);
                    }
                  }}
                />
                <Box paddingBottom={3} />
              </Container>
            )}

          {!values.sameAddressDetailsAsPrimary && (
            <Address
              values={values.residentialAddress}
              streetLineFieldName={"residentialAddress.streetLine1"}
              suburbFieldName={"residentialAddress.suburb"}
              stateFieldName={"residentialAddress.state"}
              postCodeFieldName={"residentialAddress.postCode"}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              classes={classes}
              states={states}
              required={true}
              helperText={null}
              error={null}
            />
          )}
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Cultural Details</legend>

          {primaryContact.mainLanguage && primaryContact.culturalBackground && (
            <Container className={classes.container}>
              <FormControlLabel
                id="sameCulturalDetailsAsPrimary"
                control={<Checkbox />}
                label={`Same as ${primaryContact.firstName} ${primaryContact.lastName}`}
                checked={values.sameCulturalDetailsAsPrimary}
                onChange={(e, checked) => {
                  if (checked) {
                    setFieldValue("mainLanguage", primaryContact.mainLanguage);
                    setFieldValue("culturalBackground", primaryContact.culturalBackground);
                    setFieldValue("sameCulturalDetailsAsPrimary", true);
                  } else {
                    setFieldValue("sameCulturalDetailsAsPrimary", false);
                  }
                }}
              />
              <Box paddingBottom={3} />
            </Container>
          )}

          {values.sameCulturalDetailsAsPrimary === false ? (
            <React.Fragment>
              <FormControl variant="filled" className={classes.formControl} fullWidth required>
                <TextField
                  select
                  id="mainLanguage"
                  label="Main language spoken"
                  value={values.mainLanguage || ""}
                  onChange={handleChange("mainLanguage")}
                  onBlur={handleBlur("mainLanguage")}
                  helperText={touched.mainLanguage ? errors.mainLanguage : ""}
                  error={touched.mainLanguage && Boolean(errors.mainLanguage)}
                  variant="filled"
                  fullWidth
                >
                  <MenuItem value="">Please select</MenuItem>
                  {popularLanguages.map((lang, index) => (
                    <MenuItem value={lang} key={index}>
                      {lang}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <TextField
                id="culturalBackground"
                label="Cultural Details"
                value={values.culturalBackground || ""}
                onChange={handleChange("culturalBackground")}
                onBlur={handleBlur("culturalBackground")}
                helperText={touched.culturalBackground ? errors.culturalBackground : ""}
                error={touched.culturalBackground && Boolean(errors.culturalBackground)}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 255,
                }}
              />
              <TextField
                id="culturalNeeds"
                label="Specify any other considerations"
                value={values.culturalNeeds || ""}
                onChange={handleChange("culturalNeeds")}
                onBlur={handleBlur("culturalNeeds")}
                helperText={touched.culturalNeeds ? errors.culturalNeeds : ""}
                error={touched.culturalNeeds && Boolean(errors.culturalNeeds)}
                fullWidth
                multiline
                className={classes.textField}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </React.Fragment>
          ) : null}
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Living and Care Arrangements</legend>
          <em>
            If there are any Court Orders or Parenting Plans in place for this child, please upload a copy of the
            relevant documents below.
          </em>
          <Container className={classes.container}>
            <DocumentManager
              controlId={"courtOrderDocument"}
              documentTypeId={DocumentTypes.CourtOrders}
              customerAccountId={primaryContact.customerAccountId}
              childId={values.childId}
              registrationChildUniqueId={values.uniqueId}
              isRegistration={values.uniqueId ? true : false}
              isTemp={true}
              documents={courtOrderDocuments}
              allowedExtensions="jpg,png,pdf,tiff,docx,pct,pic"
              maxFiles={5}
            />
          </Container>
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default GeneralInformation;
