import { createActionCreator } from "deox";

import { INotificationSettings, NotificationCategory, NotificationChannel } from "../../../types/types";
export const loadNotificationPending = createActionCreator("loadNotificationPending");
export const saveNotificationPending = createActionCreator("saveNotificationPending");
export const loadNotificationError = createActionCreator("loadNotificationError");
export const saveNotificationError = createActionCreator("saveNotificationError");

export const saveNotificationInit = createActionCreator("saveNotificationInit");

export const loadNotificationSuccess = createActionCreator(
  "loadNotificationSuccess",
  (resolve) => (settings: INotificationSettings) => resolve(settings)
);
export const saveNotificationSuccess = createActionCreator(
  "saveNotificationSuccess",
  (resolve) => (category: NotificationCategory, channel: NotificationChannel) => resolve(category, channel)
);
