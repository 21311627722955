import { createActionCreator } from "deox";

import { AvatarInfo } from "../../../types/models";
import { Document } from "../../../types/types";
import { FileResponse } from "../../parentApi";

export const DocumentInit = createActionCreator("DocumentInit");

export const DocumentProcessing = createActionCreator(
  "DocumentProcessing",
  (resolve) => (stateId: string) => resolve(stateId)
);

export const DocumentUploading = createActionCreator(
  "DocumentUploading",
  (resolve) => (stateId: string) => resolve(stateId)
);

export const DocumentsUploaded = createActionCreator("DocumentsUploaded");

export const DocumentDeleted = createActionCreator(
  "DocumentDeleted",
  (resolve) => (documentId: string) => resolve(documentId)
);

export const DocumentDownloaded = createActionCreator(
  "DocumentDownloaded",
  (resolve) => (stateId: string, fileResponse: FileResponse, fileName: string, isLoading: boolean) =>
    resolve({ stateId, fileResponse, fileName, isLoading })
);

export const SaveRegistrationDocumentAction = createActionCreator(
  "SaveRegistrationDocumentAction",
  (resolve) => (stateId: string | undefined, document: Document) => resolve({ stateId, document })
);

export const RemoveRegistrationDocumentAction = createActionCreator(
  "RemoveRegistrationDocumentAction",
  (resolve) => (stateId: string, uniqueId: string) => resolve({ stateId, uniqueId })
);

export const DocumentErrorAction = createActionCreator(
  "DocumentErrorAction",
  (resolve) => (stateId: string, error: string) => resolve({ stateId, error })
);

export const SetRegisteredChildAvatar = createActionCreator(
  "SetRegisteredChildAvatar",
  (resolve) => (childId: number, avatarInfo: AvatarInfo) => resolve({ childId, avatarInfo })
);

export const SetRegisteredContactAvatar = createActionCreator(
  "SetRegisteredContactAvatar",
  (resolve) => (contactId: number, avatarInfo: AvatarInfo) => resolve({ contactId, avatarInfo })
);

export const SaveDocumentAction = createActionCreator(
  "SaveDocumentAction",
  (resolve) => (stateId: string | undefined, document: Document) => resolve({ stateId, document })
);

export const RemoveDocumentAction = createActionCreator(
  "RemoveDocumentAction",
  (resolve) => (stateId: string, document: Document) => resolve({ stateId, document })
);

export const DocumentResetAction = createActionCreator(
  "DocumentResetAction",
  (resolve) => (stateId: string) => resolve(stateId)
);
