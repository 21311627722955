import { createActionCreator } from "deox";

import { School } from "../../../types/types";

export const LoadedSchoolsError = createActionCreator("LoadedSchoolsError");

export const LoadedSchools = createActionCreator(
  "LoadedSchools",
  (resolve) => (schools: School[], shortStateName: string) => resolve({ schools, shortStateName })
);
