import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import {
  termsAccepted,
  termsAcceptedError,
  termsAcceptedSaving,
  TermsAndConditionsStatus,
  termsLoading,
  termsRequired,
} from "./termsAndConditionsStateActions";

export interface TermsAndConditionsState {
  payload: string | null;
  status: TermsAndConditionsStatus;
  isLoading: boolean;
  isLoaded: boolean;
}

export const initialState: TermsAndConditionsState = {
  payload: null,
  status: "Initial",
  isLoading: false,
  isLoaded: false,
};

const termsAndConsitionsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    termsAccepted,
    (state, action): TermsAndConditionsState => ({
      ...state,
      status: "Accepted",
      isLoading: false,
      isLoaded: true,
    })
  ),
  handleAction(
    termsRequired,
    (state, action): TermsAndConditionsState => ({
      ...state,
      status: "Required",
      payload: action.payload,
      isLoading: false,
      isLoaded: true,
    })
  ),
  handleAction(
    termsAcceptedSaving,
    (state): TermsAndConditionsState => ({
      ...state,
      status: "Saving",
      isLoading: false,
      isLoaded: true,
    })
  ),
  handleAction(
    termsAcceptedError,
    (state, action): TermsAndConditionsState => ({
      ...state,
      status: "Errored",
      isLoading: false,
      isLoaded: true,
    })
  ),
  handleAction(
    termsLoading,
    (state, action): TermsAndConditionsState => ({
      ...state,
      isLoading: true,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default termsAndConsitionsReducer;
