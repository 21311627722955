import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import dayjs from "dayjs";
import { List } from "linqts";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import BookingSummary from "./BookingSummary";
import VacBookingEditor from "./VacBookingEditor";
import VACBookingSummary from "./VACBookingSummary";
import YourOshcBookingEditor from "./YourOshcBookingEditor";
import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import { useAppDispatch } from "../../store/hooks";
import {
  LoadCreateCasualBookingsPreviewInitial,
  LoadCreateRecurringPreviewInitial,
  RecurrenceChange,
  SaveBookingInitial,
} from "../../store/modules/booking/bookingStateActions";
import { getServices } from "../../store/modules/service/servicesActions";
import { getRollsForService } from "../../store/modules/session/sessionActions";
import { AddOshBookingSelection, LoadedUnavailableDatesReset, RemoveOshBookingSelection } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/store";
import {
  ChildAgeConstraint,
  OshcBookingDetails,
  SelectedChild,
  SelectedRollDay,
  SelectedServiceProgram,
  WeekDaysOptions,
} from "../../types/models";
import { DayOfWeek, IEditRecurringBookingState, ISessionAvailability, Roll, VacBookingInfo } from "../../types/types";
import { getBookableRollDates, getStyleColor, isContactAllowedToBook } from "../../utils/helpers";
import { GetPageState } from "../../utils/pageUtil";
import TabPanel from "../Common/Tab/TabPanel";

function a11yProps(index: number) {
  return {
    id: `booking-tab-${index}`,
    "aria-controls": `booking-tabpanel-${index}`,
  };
}
const useStyles = makeStyles()((theme) => {
  return {
    tab: {
      "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
      },
      "& .MuiButtonBase-root.MuiTab-root": {
        marginLeft: "20px",
        padding: "20px 10px",
        minWidth: "18rem",
        [theme.breakpoints.down("sm")]: {
          minWidth: "8.5rem",
          padding: "10px 5px",
          marginLeft: "10px",
        },

        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        transition: "color 0.2s ease-in-out",
      },
    },
    tabPanel: {
      border: "2px solid black",
      borderRadius: "10px",
      padding: "20px",
      backgroundColor: "white",
    },
    rocketeerTabPanel: {
      borderTop: "2px solid var(--custom-color-rocketeer)",
      borderLeft: "2px solid var(--custom-color-rocketeer)",
      borderRight: "2px solid var(--custom-color-rocketeer)",
      backgroundColor: "white",
      "&:hover": {
        color: "var(--custom-color-rocketeer)",
      },
      "&.Mui-selected": {
        backgroundColor: "var(--custom-color-rocketeer)!important",
      },
    },
    yourOshcTabPanel: {
      borderTop: "2px solid var(--youroshc-color-primary)",
      borderLeft: "2px solid var(--youroshc-color-primary)",
      borderRight: "2px solid var(--youroshc-color-primary)",
      backgroundColor: "white",
      "&:hover": {
        color: "var(--youroshc-color-primary)",
      },
      "&.Mui-selected": {
        backgroundColor: "var(--youroshc-color-primary)!important",
      },
    },
    rocketeer: {
      backgroundColor: "white",
      border: "2px solid var(--custom-color-rocketeer)",
    },
    rocketeerDisabled: {
      backgroundColor: "gray",
      opacity: 0.5,

      "&.Mui-selected": {
        backgroundColor: "var(--custom-color-rocketeer)!important",
      },
    },
    yourOshc: {
      backgroundColor: "white",
      border: "2px solid var(--youroshc-color-primary)",
    },
    logo: {
      height: 40,
      "@media screen and (min-width: 720px)": {
        height: 40,
      },
    },
    logoRocketeer: {
      height: 50,
    },
    icon: {
      width: "3rem",
      height: "3rem",
    },
    childName: {
      wordBreak: "break-all",
      overflowWrap: "anywhere",
      fontWeight: "bold",
      textDecoration: "underline",
    },

    button: {
      pointerEvents: "auto",
    },
    selected: {
      border: "2px solid var(--youroshc-color-primary)",
      backgroundColor: "#D08DFF26",
    },
    selectedVac: {
      border: "2px solid var(--custom-color-rocketeer)",
      backgroundColor: "#daf3ff",
    },

    unselected: {
      border: "2px solid #00000029",
    },
    different: {
      "& .MuiCardActionArea-root": {
        opacity: 0.5,
      },
      border: "2px solid #00000029",
    },
    dayAvailable: {
      backgroundColor: "white",
      border: "1px solid black",
      color: "black",
    },

    daySelected: {
      backgroundColor: "var(--youroshc-color-primary)",
      border: "1px solid black",
      color: "black",
    },

    dayDisabled: {
      backgroundColor: "#DDDDDD",
      border: "2px solid white",
      color: "white",
    },
    accordion: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      borderRadius: "10px",
      marginBottom: theme.spacing(1),
      "&::before": {
        display: "none",
      },
    },

    accordionVac: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      border: 0,
      borderRadius: "10px",
      marginBottom: theme.spacing(1),
      "&::before": {
        display: "none",
      },
      "&.Mui-expanded": {
        border: "2px solid var(--custom-color-rocketeer)",
      },
    },

    accordionError: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),

      borderRadius: "10px",
      marginBottom: theme.spacing(1),

      border: "2px solid #b42931",
      borderLeftWidth: 5,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    section: {
      border: "2px solid var(--youroshc-color-primary)",
      borderRadius: "10px",
    },
    sectionVac: {
      border: "2px solid var(--custom-color-rocketeer)",
      borderRadius: "10px",
    },

    stack: {
      marginLeft: "5px!important",
      "& .MuiStack-root": {
        marginLeft: "5px!important",
      },
    },
    accordionSummary: {
      alignItems: "flex-start",
      borderRadius: "10px",
      backgroundColor: "white",
    },
    stepYourOSHC: {
      color: "var(--youroshc-color-primary)",
      width: "40px",
      height: "40px",
    },

    stepDisabled: {
      color: "grey",
      width: "40px",
      height: "40px",
    },
    stepVac: {
      color: "var(--custom-color-rocketeer)",
      width: "40px",
      height: "40px",
    },
  };
});

export default function BookingEditor() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const state = GetPageState<IEditRecurringBookingState>();
  const isEdit = state !== undefined && state !== null;
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);

  const auth = useSelector((state: RootState) => state.auth);
  const { userId } = auth;
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const saveState = useSelector((state: RootState) => state.createBooking);
  const isRecurringPreviewLoading = useSelector((state: RootState) => state.recurringBookingPreview.isLoading);
  const isCasualPreviewLoading = useSelector((state: RootState) => state.bookingSaveCasualPreview.isLoading);
  const isSaveRecurringPreviewLoading = useSelector((state: RootState) => state.bookingSaveRecurringPreview.isLoading);

  const isSubmitting = saveState !== undefined && saveState.isSaving;

  const customer_account_id = auth?.user?.profile.customer_account_id;
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const customerAccount = useSelector((state: RootState) => state.customer.customer);

  const [tabIndex, setTabIndex] = React.useState(0);

  const { rolls: serviceRolls, serviceId: rollsServiceId } = useSelector((state: RootState) => state.session);
  const { dates: unAvailableDates, isLoading: datesLoading } = useSelector(
    (state: RootState) => state.unAvailableDates
  );

  const [oshcPageState, setOshcPageState] = useState({
    hasConflict: false,
    hasError: false,
    unAvailableDates: new Array<ISessionAvailability>(),
    newlyAddedUnAvailableDates: new Array<ISessionAvailability>(),
  });

  const [vacPageState, setVacPageState] = useState({
    hasConflict: false,
    hasError: false,
    unAvailableDates: new Array<ISessionAvailability>(),
    newlyAddedUnAvailableDates: new Array<ISessionAvailability>(),
  });

  function isAllowedToBook() {
    return isContactAllowedToBook(customerAccount!, userId!, parseInt(customer_contact_id!.toString()));
  }

  useEffect(() => {
    if (state !== null && (serviceRolls === null || rollsServiceId !== state.serviceId))
      getRollsForService(dispatch, state.serviceId);
  }, [isEdit]);

  useEffect(() => {
    if (serviceRolls !== null && serviceRolls.length > 0 && isEdit) {
      setFormState({
        ...formState,
        yourOshc: {
          ...formState.yourOshc,
          recurringDays: [
            new SelectedRollDay({
              isFriday: state.firstFriday,
              isMonday: state.firstMonday,
              isThursday: state.firstThursday,
              isTuesday: state.firstTuesday,
              isWednesday: state.firstWednesday,
              roll: serviceRolls!.find((r) => r.rollId === state.rollId)!,
              isWeekly: true,
            }),
          ],
        },
      });
    }
  }, [serviceRolls]);

  function resetPageState() {
    setOshcPageState({
      hasConflict: false,
      hasError: false,
      newlyAddedUnAvailableDates: [],
      unAvailableDates: [],
    });
    setVacPageState({      hasConflict: false,
      hasError: false,
      newlyAddedUnAvailableDates: [],
      unAvailableDates: [],
    });
  }
  const vacInitialValues: VacBookingInfo = {
    service: undefined,
    children: new Array<SelectedChild>(),
    dates: new Array<SelectedServiceProgram>(),
  };

  function getDays() {
    var days = new Array<DayOfWeek>();
    if (state?.firstMonday) {
      days.push(DayOfWeek.Monday);
    }
    if (state?.firstTuesday) {
      days.push(DayOfWeek.Tuesday);
    }
    if (state?.firstWednesday) {
      days.push(DayOfWeek.Wednesday);
    }
    if (state?.firstThursday) {
      days.push(DayOfWeek.Thursday);
    }
    if (state?.firstFriday) {
      days.push(DayOfWeek.Friday);
    }
    return days;
  }
  const initialValues: OshcBookingDetails = {
    scheduleId: isEdit ? state.uniqueId : undefined,
    originalRecurringEndDate: isEdit ? state.effectiveToDate : undefined,
    orginalWeekDays: isEdit ? getDays() : undefined,
    children:
      isEdit && children !== null
        ? [
            new SelectedChild({
              child: children.find((c) => c.childId === state.childId)!,
              isSelected: true,
            }),
          ]
        : [],
    serviceId: isEdit ? state.serviceId : undefined,
    casualDates: [],
    isRecurring: isEdit ? true : false,
    currentDate: isEdit ? state.effectiveFromDate : dayjs(dayjs().format("YYYY-MM-DD")),
    recurringDays:
      isEdit && serviceRolls !== null && serviceRolls?.length > 0
        ? [
            new SelectedRollDay({
              isFriday: state.firstFriday,
              isMonday: state.firstMonday,
              isThursday: state.firstThursday,
              isTuesday: state.firstTuesday,
              isWednesday: state.firstWednesday,
              roll: serviceRolls!.find((r) => r.rollId === state.rollId)!,

              isWeekly: true,
            }),
          ]
        : [],
    recurringStartDate: isEdit ? state.effectiveFromDate : null,
    recurringEndDate: isEdit ? state.effectiveToDate ?? null : null,
    recurringDateType: isEdit ? "Custom" : null,
    minBookings: isEdit
      ? servicesByCustomer?.find((x) => x.serviceId === state.serviceId)?.firstMinimumBookingsRequired ?? 2
      : 2,
    originalRollId: isEdit ? state.rollId : undefined,
  };
  const [formState, setFormState] = useState<{ yourOshc: OshcBookingDetails; vac: VacBookingInfo }>({
    yourOshc: initialValues,
    vac: vacInitialValues,
  });

  useEffect(() => {
    if (customer_account_id) {
      dispatch(SaveBookingInitial());
      dispatch(LoadCreateRecurringPreviewInitial());
      dispatch(LoadedUnavailableDatesReset());

      if (servicesByCustomer === null) {
        getServices(dispatch, customer_account_id);
      }
    }
  }, [customer_account_id, servicesByCustomer]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(LoadedUnavailableDatesReset());
    dispatch(LoadCreateCasualBookingsPreviewInitial());
    setTabIndex(newValue);
    setFormState({
      ...formState,
      yourOshc: initialValues,
      vac: vacInitialValues,
    });
    resetPageState();
  };
  useEffect(() => {
    const constraints = getConstraints();
    const ageIsGreater = formState.yourOshc.children.filter((cs) =>
      isAgeGreaterThanMax(constraints, new SelectedChild(cs))
    );
    setFormState({
      ...formState,
      yourOshc: {
        ...formState.yourOshc,
        children: formState.yourOshc.children.map((c) => {
          return {
            ...c,
            reasonRequired: ageIsGreater.findIndex((a) => a.child.childId === c.child.childId) >= 0,
          };
        }),
      },
    });
  }, [formState.yourOshc.casualDates, formState.yourOshc.recurringDays]);

  function onRollDaySelected(roll: Roll, day: WeekDaysOptions) {
    var rd = formState.yourOshc.recurringDays.find((r) => r?.roll.rollId === roll.rollId);
    if (!rd) {
      rd = new SelectedRollDay({
        roll: roll,
        isWeekly: true,
        isMonday: day === "Monday",
        isTuesday: day === "Tuesday",
        isWednesday: day === "Wednesday",
        isThursday: day === "Thursday",
        isFriday: day === "Friday",
      });
      setFormState({
        ...formState,
        yourOshc: {
          ...formState.yourOshc,
          recurringDays: [...formState.yourOshc.recurringDays.filter((x) => x.isAnyDaySeleced), rd].filter(
            (x) => !x.isSameSessionType(roll.rollId, roll.sessionType)
          ),
        },
      });
      //formState.recurringDays.Add(rd);
    } else {
      if (day === "Monday") {
        rd.isMonday = !rd.isMonday;
      } else if (day === "Tuesday") {
        rd.isTuesday = !rd.isTuesday;
      } else if (day === "Wednesday") {
        rd.isWednesday = !rd.isWednesday;
      } else if (day === "Thursday") {
        rd.isThursday = !rd.isThursday;
      } else if (day === "Friday") {
        rd.isFriday = !rd.isFriday;
      }
      setFormState({
        ...formState,
        yourOshc: {
          ...formState.yourOshc,
          recurringDays: formState.yourOshc.recurringDays
            .map((x) => {
              return x.roll.rollId === roll.rollId ? rd! : x;
            })
            .filter((x) => x.isAnyDaySeleced && !x.isSameSessionType(roll.rollId, roll.sessionType)),
        },
      });
    }
  }

  function getBookingDates() {
    return !formState.yourOshc.isRecurring
      ? formState.yourOshc.casualDates
      : getBookableRollDates(
          formState.yourOshc.recurringStartDate,
          formState.yourOshc.recurringEndDate,
          formState.yourOshc.recurringDays ?? [],
          unAvailableDates ?? []
        );
  }

  function getConstraints() {
    const dates = getBookingDates();
    return new List(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })));
  }

  function isAgeGreaterThanMax(constraints: List<ChildAgeConstraint>, child: SelectedChild) {
    return constraints.Where((ct) => ct !== undefined && ct.isAgeGreaterThanMax(child));
  }

  return (
    <Grid container spacing={1} p={2}>
      <Grid item xs={12}>
        <Grid container direction={"row"} spacing={1}>
          <Grid item xs={12}>
            <h2>{formState.yourOshc.scheduleId ? "Edit schedule" : "New Booking"}</h2>
          </Grid>
          <Backdrop
            className={classes.backdrop}
            open={
              datesLoading ||
              isRecurringPreviewLoading ||
              isCasualPreviewLoading ||
              isSubmitting ||
              isSaveRecurringPreviewLoading
            }
          >
            <CircularProgress color="primary" />
          </Backdrop>
          <Grid item xs={12} lg={8} sm={12}>
            <Box>
              <Tabs className={classes.tab} value={tabIndex} onChange={handleTabChange} style={{ color: "#1d4e5c" }}>
                <Tab
                  className={`${classes.yourOshcTabPanel}`}
                  sx={{ mr: 2 }}
                  label={<img src={YourOSHCLogo} alt="Your OSHC" className={classes.logo} />}
                  {...a11yProps(0)}
                />
                <Tab
                  className={`${
                    formState.yourOshc.scheduleId === undefined ? classes.rocketeerTabPanel : classes.rocketeerDisabled
                  }`}
                  disabled={formState.yourOshc.scheduleId !== undefined}
                  label={<img src={RocketeersLogo} alt="Rocketeers" className={classes.logoRocketeer} />}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabIndex} index={0}>
              <YourOshcBookingEditor
                pageState={oshcPageState}
                setPageState={setOshcPageState}
                classes={classes}
                formState={formState.yourOshc}
                onChildrenRefresh={(children) => {
                  setFormState({
                    ...formState,
                    yourOshc: {
                      ...formState.yourOshc,
                      children: children,
                    },
                  });
                }}
                onEndDateChanged={(dt) => {
                  if (
                    dt === null ||
                    (formState.yourOshc.recurringStartDate !== null &&
                      dt.isAfter(formState.yourOshc.recurringStartDate))
                  ) {
                    setFormState({
                      ...formState,
                      yourOshc: {
                        ...formState.yourOshc,
                        recurringEndDate: dt,
                      },
                    });
                  }
                }}
                onChildReasonChanged={(childId, reason) => {
                  setFormState({
                    ...formState,
                    yourOshc: {
                      ...formState.yourOshc,

                      children: formState.yourOshc.children.map((x) => {
                        if (x.child.childId === childId) {
                          x.reason = reason;
                        }
                        return x;
                      }),
                    },
                  });
                }}
                onChildSelectionChanged={(child, isAlreadySelected, ageIsGreater) => {
                  if (
                    (formState.yourOshc.serviceId && child.serviceId !== formState.yourOshc.serviceId) ||
                    child.serviceId === undefined ||
                    (child.isSuspended ?? false)
                  )
                    return;
                  if (isAlreadySelected) {
                    setFormState({
                      ...formState,
                      yourOshc: {
                        ...formState.yourOshc,
                        serviceId: formState.yourOshc.children.length === 1 ? undefined : formState.yourOshc.serviceId,
                        minBookings: formState.yourOshc.children.length === 1 ? 2 : formState.yourOshc.minBookings,
                        children: formState.yourOshc.children.filter((x) => x.child.childId !== child.childId),
                        casualDates: formState.yourOshc.children.length === 1 ? [] : formState.yourOshc.casualDates,
                        recurringDays: formState.yourOshc.children.length === 1 ? [] : formState.yourOshc.recurringDays,
                      },
                    });
                  } else {
                    setFormState({
                      ...formState,
                      yourOshc: {
                        ...formState.yourOshc,
                        serviceId:
                          formState.yourOshc.children.length === 0 ? child.serviceId! : formState.yourOshc.serviceId,
                        minBookings:
                          formState.yourOshc.children.length === 0
                            ? servicesByCustomer?.find((s) => s.serviceId === child.serviceId)
                                ?.firstMinimumBookingsRequired ?? 2
                            : formState.yourOshc.minBookings,
                        children: [
                          ...formState.yourOshc.children,
                          {
                            child: child,
                            isSelected: true,
                            reasonRequired: ageIsGreater,
                          },
                        ],
                      },
                    });
                  }
                }}
                onStartDateChanged={(dt) => {
                  setFormState({
                    ...formState,
                    yourOshc: {
                      ...formState.yourOshc,
                      currentDate: dt ?? undefined,
                      recurringStartDate: formState.yourOshc.isRecurring ? dt : formState.yourOshc.recurringStartDate,
                    },
                  });
                }}
                onRecurrenceChange={(option, termEnd, yearEnd) => {
                  const isCasual = option === "Casual";

                  dispatch(RecurrenceChange(isCasual));

                  setFormState({
                    ...formState,
                    yourOshc: {
                      ...formState.yourOshc,
                      casualDates: isCasual ? formState.yourOshc.casualDates : [],
                      recurringDays: isCasual ? [] : formState.yourOshc.recurringDays,
                      isRecurring: !isCasual,
                      currentDate: formState.yourOshc.currentDate,
                      recurringDateType: option,
                      recurringEndDate: isCasual
                        ? null
                        : option === "TermEnd"
                        ? termEnd!
                        : option === "YearEnd"
                        ? yearEnd!
                        : formState.yourOshc.recurringEndDate,
                      recurringStartDate: isCasual ? null : formState.yourOshc.currentDate ?? null,
                    },
                  });
                }}
                onRollDaySelected={onRollDaySelected}
                onRollSelected={(roll, isAlreadySelected) => {
                  if (isAlreadySelected) {
                    //unselect
                    setFormState({
                      ...formState,
                      yourOshc: {
                        ...formState.yourOshc,

                        casualDates: formState.yourOshc.casualDates.filter(
                          (y) =>
                            !(y.date.isSame(formState.yourOshc.currentDate!, "date") && y.roll.rollId === roll.rollId)
                        ),
                      },
                    });

                    dispatch(RemoveOshBookingSelection(roll));
                  } else {
                    setFormState({
                      ...formState,
                      yourOshc: {
                        ...formState.yourOshc,
                        casualDates: [
                          ...formState.yourOshc.casualDates,
                          {
                            date: formState.yourOshc.currentDate!,
                            roll: roll,
                          },
                        ],
                      },
                    });


                    dispatch(AddOshBookingSelection(roll));
                  }
                }}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <VacBookingEditor
                pageState={vacPageState}
                setPageState={setVacPageState}
                formState={formState.vac}
                onChildrenRefresh={(children) => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,
                      children: children,
                    },
                  });
                }}
                onChildReasonChanged={(childId, reason) => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,

                      children: formState.vac.children.map((x) => {
                        if (x.child.childId === childId) {
                          x.reason = reason;
                        }
                        return x;
                      }),
                    },
                  });
                }}
                onAddBooking={(serviceProgram) => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,
                      dates: [
                        ...formState.vac.dates,
                        {
                          isSelected: true,
                          program: serviceProgram,
                        } as SelectedServiceProgram,
                      ],
                    },
                  });
                }}
                onRemoveBooking={(serviceProgram) => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,
                      dates: [
                        ...formState.vac.dates.filter((x) => x.program.programDateId !== serviceProgram.programDateId),
                      ],
                    },
                  });
                }}
                onUnSelectService={() => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,
                      service: undefined,
                      children: [],
                      dates: [],
                    },
                  });
                }}
                onServiceSelected={(service) => {
                  setFormState({
                    ...formState,
                    vac: {
                      ...formState.vac,
                      service: service,
                      children: [],
                      dates: [],
                    },
                  });
                }}
                onChildSelectionChanged={(child, isAlreadySelected, ageIsGreater) => {
                  if (!formState.vac.service || (child.isSuspended ?? false)) return;
                  if (isAlreadySelected) {
                    setFormState({
                      ...formState,
                      vac: {
                        ...formState.vac,
                        children: formState.vac.children.filter((x) => x.child.childId !== child.childId),
                      },
                    });
                    //setRefreshUnavailableDates(true);
                  } else {
                    setFormState({
                      ...formState,
                      vac: {
                        ...formState.vac,

                        children: [
                          ...formState.vac.children,
                          {
                            child: child,
                            isSelected: true,
                            reasonRequired: ageIsGreater,
                          },
                        ],
                      },
                    });
                  }
                }}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={12} lg={4} sm={12}>
            {tabIndex === 0 && (
              <BookingSummary
                pageState={oshcPageState}
                setPageState={setOshcPageState}
                classes={classes}
                formState={formState.yourOshc}
                isAllowedToBook={isAllowedToBook()}
                isFriendlyFee={customerAccount?.isFriendlyFeeEligible === true}
              />
            )}
            {tabIndex === 1 && (
              <VACBookingSummary
                pageState={vacPageState}
                setPageState={setVacPageState}
                isFriendlyFee={customerAccount?.isFriendlyFeeEligible === true}
                classes={classes}
                formState={formState.vac}
                isAllowedToBook={isAllowedToBook()}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
