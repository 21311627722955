import React, { useEffect, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardActions, IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card/Card";
import CardActionArea from "@mui/material/CardActionArea/CardActionArea";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { DateValidationError } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { List } from "linqts";
import { floor } from "lodash";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import BookingChildSelection from "./BookingChildSelection";
import ChildBookingReason from "./ChildBookingReason";
import BSCImage from "../../assets/images/Environment-GREY-BG.png";
import OtherImage from "../../assets/images/Mindfulness-GREY-BG.png";
import ASCImage from "../../assets/images/STEM-GREY-BG.png";
import { useAppDispatch } from "../../store/hooks";
import { getTermDates } from "../../store/modules/service/servicesActions";
import {
  getRollsForService,
  getScheduleUnAvailableSessionDays,
  getUnAvailableSessionDays,
} from "../../store/modules/session/sessionActions";
import { LoadedUnavailableDatesReset, SelectOshBookingStep, SelectStartDate } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/store";
import {
  Child,
  ChildAgeConstraint,
  EndDateOptions,
  EntityType,
  OshcBookingDetails,
  SelectedChild,
  WeekDaysOptions,
} from "../../types/models";
import { BookingStatus3, ISelectedChild, ISessionAvailability, Roll, TermDate } from "../../types/types";
import { HasMinimumBookingsAcrossWeeks } from "../../utils/bookingHelper";
import {
  getAvailabilityReasonsText,
  getBookableRollDates,
  getChildDisplayName,
  getStyleColor,
  hasMinRequiredBookingsAcrossWeeks,
} from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import { isChildBlockedForAllServices } from "../../utils/medicalConditionsHelper";
import { ButtonPrimary } from "../Common/Buttons/Buttons";
import Spacer from "../Common/Spacers/Spacers";

interface Iprops {
  classes: any;
  formState: OshcBookingDetails;
  onEndDateChanged: (date: Dayjs | null) => void;
  onRollDaySelected: (roll: Roll, day: WeekDaysOptions) => void;
  onRecurrenceChange: (option: EndDateOptions, termEnd: Dayjs | undefined, yearEnd: Dayjs | undefined) => void;
  onChildSelectionChanged: (child: Child, isAlreadySelected: boolean, ageIsGreater: boolean) => void;
  onStartDateChanged: (date: Dayjs | null) => void;
  onRollSelected(roll: Roll, isAlreadySelected: boolean): void;
  onChildReasonChanged(childId: number, reason: string): void;
  onChildrenRefresh(children: ISelectedChild[]): void;
  pageState: {
    hasConflict: boolean;
    hasError: boolean;
    unAvailableDates: ISessionAvailability[];
    newlyAddedUnAvailableDates: ISessionAvailability[];
  };
  setPageState: React.Dispatch<
    React.SetStateAction<{
      hasConflict: boolean;
      hasError: boolean;
      unAvailableDates: ISessionAvailability[];
      newlyAddedUnAvailableDates: ISessionAvailability[];
    }>
  >;
}

const topRightOverlay = (title: string, bgColor: string) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      opacity: 1,
      right: 0,
      backgroundColor: bgColor,
      padding: "5px",
      borderTopRightRadius: "5px",
    }}
  >
    <Typography color={"black"} fontSize={"12px"}>
      {title}
    </Typography>
  </Box>
);

export default function YourOshcBookingEditor(props: Iprops) {
  const dispatch = useAppDispatch();

  const {
    classes,
    formState,
    onChildReasonChanged,
    onEndDateChanged,
    onRollDaySelected,
    onChildSelectionChanged,
    onStartDateChanged,
    onRollSelected,
    onChildrenRefresh,
    pageState,
  } = props;

  const isEditMode = formState.scheduleId !== undefined;

  const [endDateError, setEndDateError] = useState<DateValidationError | undefined>();

  const [startDateError, setStartDateError] = useState<DateValidationError | undefined>();

  const [currentStep, setcurrentStep] = useState<number | undefined>(isEditMode ? 1 : 0);
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const register = useSelector((state: RootState) => state.register);

  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  const auth = useSelector((state: RootState) => state.auth);
  const [oshcRolls, setOshcRolls] = useState<Roll[]>([]);

  const customer_account_id = auth?.user?.profile.customer_account_id;
  const { rolls: serviceRolls, isLoading: rollsLoading } = useSelector((state: RootState) => state.session);

  const { dates: unAvailableDates, isLoading: datesLoading } = useSelector(
    (state: RootState) => state.unAvailableDates
  );
  const termDates = useSelector((state: RootState) => state.termDates.termDatesByServiceId);
  const [termEnd, setTermEnd] = useState<Dayjs | undefined>();
  const [yearEnd, setYearEnd] = useState<Dayjs | undefined>();
  const handleChange = (step: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setcurrentStep(isExpanded ? step : undefined);
  };
  const previewResult = useSelector((state: RootState) => state.bookingSaveRecurringPreview.previewResult);

  const { width } = useViewport();
  const isMobile = width <= 768;
  const isSmallScreen = width <= 400;
  function getRollIdBeingEdited() {
    return isEditMode ? formState.originalRollId! : undefined;
  }
  useEffect(() => {
    const startDate = formState.recurringStartDate ?? dayjs(dayjs().format("YYYY-MM-DD"));
    if (termDates && termDates.length) {
      var dates = new List<TermDate>(termDates);
      var quarter = floor(startDate.get("month") / 3 + 1);
      var year = startDate.year();
      setTermEnd(dates.FirstOrDefault((d) => d?.year === year && d?.termNumber === quarter)?.lastDayOfTerm);
      setYearEnd(dates.FirstOrDefault((d) => d?.year === year && d?.termNumber === 4)?.lastDayOfTerm);
    }
  }, [termDates, formState.recurringStartDate]);

  useEffect(() => {
    if (formState.serviceId && rollsLoading !== true) {
      dispatch(LoadedUnavailableDatesReset());
    }
  }, [formState.serviceId]);
  useQuery(["getRollsForService", formState.serviceId], () => getRollsForService(dispatch, formState.serviceId!), {
    refetchOnWindowFocus: false,
    enabled: formState.serviceId !== undefined,
  });

  useQuery(["getTermDates", formState.serviceId], () => getTermDates(dispatch, formState.serviceId!), {
    refetchOnWindowFocus: false,
    enabled: formState.serviceId !== undefined,
  });

  useEffect(() => {
    setOshcRolls(getOshcRolls());
  }, [serviceRolls]);

  useEffect(() => {
    setOshcRolls(getOshcRolls());
  }, [formState.recurringDateType]);

  useQuery(
    ["loadUnAvailableDates", formState.recurringDateType, formState.children.length],
    () => loadUnAvailableDates(),
    {
      refetchOnWindowFocus: false,
      staleTime: (10 / 60) * (60 * 1000),
    }
  );
  useQuery(["loadUnAvailableDates_serviceRolls", serviceRolls?.length ?? 0], () => loadUnAvailableDates(), {
    refetchOnWindowFocus: false,
    enabled: serviceRolls !== null && unAvailableDates == null && !datesLoading,
    staleTime: (10 / 60) * (60 * 1000),
  });
  function getOshcRolls() {
    return new List(serviceRolls ?? [])
      .Where((x) => x?.isOhsc === true)
      .OrderBy((x) => x.startTime)
      .ThenBy((x) => x.rollName)
      .ToArray() as Roll[];
  }

  const scheduleOshcRolls = oshcRolls.filter((x) => x.rollClassificationId !== 9);

  function getRolls() {
    if (formState.recurringDateType && formState.recurringDateType !== "Casual") {
      return scheduleOshcRolls;
    }
    return oshcRolls;
  }

  const handleRecurrenceChange = (event: SelectChangeEvent) => {
    const recurrence = event.target.value as EndDateOptions;
    props.onRecurrenceChange(recurrence, termEnd, yearEnd);
  };

  const getDayUnv = (roll: any) => {
    return unAvailableDates?.find(
      (x) => x.rollId === roll.rollId && formState.currentDate!.isSame(x.date, "date") && !x.isAvailableForCasualBooking
    );
  };

  const isDaySelected = (roll: Roll) => {
    if (formState.isRecurring) {
      return formState.recurringDays.find(
        (x) => x.roll.rollId === roll.rollId && x.isAnyDaySeleced && x.roll.sessionType === roll.sessionType
      );
    } else
      return formState.casualDates.find(
        (x) => x.roll.rollId === roll.rollId && x.date.isSame(formState.currentDate!, "date")
      );
  };

  function loadUnAvailableDates(recurringEndDate?: Dayjs | null, recurringStartDate?: Dayjs | null) {
    if (formState.scheduleId) {
      getScheduleUnAvailableSessionDays(
        dispatch,
        customer_account_id as number,
        formState.scheduleId!,
        dayjs(),
        recurringEndDate ?? (formState.recurringEndDate as dayjs.Dayjs)
      );
    } else if (formState.serviceId && formState.children.length >= 0 && serviceRolls) {
      var childrenIds = formState.children.map((c) => c.child.childId as number);
      var rollIds = new List(serviceRolls)
        .Where((x) => x?.isOhsc === true)
        .Select((r) => r.rollId)
        .Distinct()
        .ToArray();
      var from = formState.isRecurring
        ? recurringStartDate ?? formState.recurringStartDate
        : recurringStartDate ?? formState.currentDate;
      var to = formState.isRecurring
        ? recurringEndDate ?? formState.recurringEndDate
        : recurringStartDate ?? formState.currentDate!;
      if (from && to && rollIds.length && (from.isSame(to, "date") || from.isBefore(to))) {
        getUnAvailableSessionDays(
          dispatch,
          customer_account_id as number,
          from as Dayjs,
          to as Dayjs,
          rollIds,
          childrenIds
        );
      }
    }
  }

  function StepHeader(title: string, index: number, completed: boolean, disabled: boolean = false) {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {!completed && (
          <Avatar sx={{ width: 30, height: 30, bgcolor: "white", color: "black", border: "2px solid black" }}>
            {index + 1}
          </Avatar>
        )}
        {completed && <CheckCircleIcon className={disabled ? classes.stepDisabled : classes.stepYourOSHC} />}
        <Grid container direction="column" justifyItems={"flex-start"}>
          <Typography variant="h4" fontWeight={"bold"}>
            {title}
          </Typography>
        </Grid>
      </Stack>
    );
  }

  function getBookingDates() {
    return !formState.isRecurring
      ? formState.casualDates
      : getBookableRollDates(
          formState.recurringStartDate,
          formState.recurringEndDate,
          formState.recurringDays ?? [],
          unAvailableDates ?? []
        );
  }

  function getConstraints() {
    const dates = getBookingDates();
    return new List(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })));
  }
  function isAgeLessThanMin(constraints: List<ChildAgeConstraint>, child: SelectedChild) {
    return constraints.Any((ct) => ct !== undefined && ct.isAgeLessThanMin(child));
  }

  function isAgeGreaterThanMax(constraints: List<ChildAgeConstraint>, child: SelectedChild) {
    return constraints.Where((ct) => ct !== undefined && ct.isAgeGreaterThanMax(child));
  }

  function hasMaxAgeViolation() {
    const constraints = getConstraints();
    if (constraints.Count() === 0 || formState.children.length === 0) return false;
    return formState.children.some((c) => isAgeGreaterThanMax(constraints, new SelectedChild(c)).Count() > 0);
  }

  function fulfillsMaxAgeRequirement() {
    const constraints = getConstraints();
    if (constraints.Count() === 0 || formState.children.length === 0) return true;
    return !formState.children.some(
      (c) =>
        isAgeGreaterThanMax(constraints, new SelectedChild(c)).Count() > 0 &&
        (c.reason === undefined || c.reason?.trim() === "")
    );
  }

  function refreshMaxAgeViolation() {
    const constraints = getConstraints();

    const children = formState.children.map((c) => {
      const violations = isAgeGreaterThanMax(constraints, new SelectedChild(c));
      c.reasonRequired = violations.Count() > 0;
      return c;
    });

    onChildrenRefresh(children);
  }
  useEffect(() => {
    if (currentStep === 2) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    dispatch(SelectOshBookingStep(currentStep));
  }, [currentStep]);

  useEffect(() => {
    refreshMaxAgeViolation();
  }, [formState.casualDates]);

  useEffect(() => {
    refreshMaxAgeViolation();
  }, [formState.recurringDays]);
  const isSessionValid =
    ((!formState.isRecurring && formState.casualDates.length > 0) ||
      (formState.isRecurring && formState.recurringDays.length > 0)) &&
    fulfillsMaxAgeRequirement() &&
    HasMinimumBookingsAcrossWeeks(isEditMode, previewResult, pageState.unAvailableDates ?? [], formState);
  var allChildren = children;
  if (isEditMode) {
    allChildren = allChildren?.filter((c) => c.childId === formState.children[0].child.childId) ?? [];
  }
  return (
    <Box sx={{ width: "100%" }}>
      {!isEditMode && (
        <Accordion
          className={classes.accordion}
          elevation={1}
          expanded={currentStep === 0}
          onChange={handleChange(0)}
          sx={{
            border: isEditMode ? "2px solid grey" : "2px solid var(--youroshc-color-primary)",
          }}
        >
          <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
            <Grid container direction={"column"}>
              {StepHeader("Child/ren", 0, formState.children.length > 0, isEditMode)}

              <Spacer margin={5} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: "background.paper" }}>
            <Grid container direction={"row"} spacing={2}>
              {allChildren?.map((child) => {
                const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
                const selected = formState.children.find((x) => x.child.childId === child.childId);
                const isSuspended = child.isSuspended ?? false;
                const isBlocked = isChildBlockedForAllServices(child, child.serviceId, register);
                return (
                  <Grid item xs={12} md={6} key={child.childId}>
                    <BookingChildSelection
                      isDisabled={isEditMode}
                      avatar={avatar}
                      isBlocked={isBlocked}
                      serviceId={formState.serviceId}
                      child={child}
                      classes={classes}
                      constraints={getConstraints().ToArray()}
                      selected={selected}
                      onChildSelectionChanged={(ageIsGreater) => {
                        if (
                          (formState.serviceId && child.serviceId !== formState.serviceId) ||
                          child.serviceId === undefined ||
                          isSuspended
                        )
                          return;

                        onChildSelectionChanged(child, selected !== undefined, ageIsGreater);
                      }}
                      isDifferentService={formState.serviceId ? child.serviceId !== formState.serviceId : false}
                    />
                    {isBlocked && (
                      <span style={{ color: "Red" }}>
                        Bookings are currently unavailable, but we'll be in touch with you within the next 2 days to
                        discuss the best options for your child.
                      </span>
                    )}
                  </Grid>
                );
              })}

              <Grid item xs={12}>
                <ButtonPrimary
                  className={classes.button}
                  disabled={formState.children.length === 0}
                  fullWidth={isMobile}
                  onClick={() => {
                    if (formState.children.length === 0) return;
                    setcurrentStep(1);
                  }}
                  sx={{
                    backgroundColor: getStyleColor("--youroshc-color-primary"),
                    color: "black",
                    float: "right",
                    width: !isMobile ? "200px" : null,
                    fontWeight: "bold",
                  }}
                >
                  Continue
                </ButtonPrimary>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        className={classes.accordion}
        elevation={1}
        expanded={currentStep === 1}
        onChange={handleChange(1)}
        sx={{ border: "2px solid var(--youroshc-color-primary)" }}
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ width: "100%" }}>
            {StepHeader("Session Date & Type", 1, isSessionValid)}

            <Spacer margin={5} />
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>
          <Grid container direction={"row"} spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Recurrence</InputLabel>
                <Select
                  disabled={isEditMode}
                  value={formState.recurringDateType?.toString() ?? "Casual"}
                  label="Recurrence"
                  onChange={handleRecurrenceChange}
                >
                  <MenuItem value={"Casual"}>Casual</MenuItem>
                  <MenuItem value={"TermEnd"}>End of term</MenuItem>
                  <MenuItem value={"YearEnd"}>End of year</MenuItem>
                  <MenuItem value={"Custom"}>Custom</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <DesktopDatePicker
                  format="DD/MM/YYYY"
                  label={formState.isRecurring ? "Start Date" : "Booking Date"}
                  minDate={isEditMode ? formState.recurringStartDate : dayjs()}
                  maxDate={isEditMode ? formState.recurringStartDate : formState.recurringEndDate ?? undefined}
                  value={formState.currentDate || null}
                  onChange={(dt) => {
                    onStartDateChanged(dt);
                    loadUnAvailableDates(null, dt);
                    dispatch(SelectStartDate(dt));
                  }}
                  onError={(error) => {
                    if (error != null) setStartDateError(error);
                    else setStartDateError(undefined);
                  }}
                  slotProps={{
                    textField: {
                      error: startDateError !== undefined,
                      helperText:
                        startDateError === undefined
                          ? ""
                          : startDateError === "invalidDate"
                          ? "Invalid date"
                          : startDateError === "disableFuture"
                          ? "Date should be in the future"
                          : startDateError === "disablePast"
                          ? "Date should be in the past"
                          : startDateError === "minDate"
                          ? "End date should not be before Start date"
                          : startDateError === "maxDate"
                          ? "Start date should not be after End date"
                          : "",
                      required: true,
                      id: "bookingDate",
                    },
                  }}
                />
              </FormControl>
            </Grid>

            {formState.isRecurring && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel></InputLabel>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    label="End Date"
                    disabled={formState.recurringDateType === "Custom" ? false : true}
                    minDate={isEditMode ? formState.originalRecurringEndDate : formState.currentDate ?? dayjs()}
                    value={formState.recurringEndDate}
                    onChange={(dt) => {
                      onEndDateChanged(dt);
                      loadUnAvailableDates(dt);
                    }}
                    onError={(error) => {
                      if (error != null) setEndDateError(error);
                      else setEndDateError(undefined);
                    }}
                    slotProps={{
                      textField: {
                        error: endDateError !== undefined,
                        helperText:
                          endDateError === undefined
                            ? ""
                            : endDateError === "invalidDate"
                            ? "Invalid date"
                            : endDateError === "disableFuture"
                            ? "Date should be in the future"
                            : endDateError === "disablePast"
                            ? "Date should be in the past"
                            : endDateError === "minDate"
                            ? "End date should not be before Start date"
                            : "",
                        required: true,
                        id: "endDate",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>
                <strong>Service type</strong> <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2}>
                {formState.serviceId && getRolls().length === 0 && (
                  <Grid item xs={12}>
                    <Typography>No bookable dates found</Typography>
                  </Grid>
                )}

                {formState.serviceId &&
                  getRolls().map((x, index) => {
                    const dayUnAv = getDayUnv(x);
                    const isFull = dayUnAv !== undefined && !formState.isRecurring;
                    const constraints = new List([
                      new ChildAgeConstraint({ rollDate: formState.currentDate!, session: x }),
                    ]);

                    const ageIsLess = formState.children.filter((cs) =>
                      isAgeLessThanMin(constraints, new SelectedChild(cs))
                    );
                    const isSelected = isDaySelected(x) !== undefined;
                    const availabilityReason = getAvailabilityReasonsText(
                      formState.isRecurring ? dayUnAv?.reasonForRecurringBooking : dayUnAv?.reasonForCasualBooking
                    );

                    var ageIsGreater = formState.children.some(
                      (cs) => isAgeGreaterThanMax(constraints, new SelectedChild(cs)).Count() > 0
                    );

                    var rd = formState.recurringDays.find((r) => r?.roll.rollId === x.rollId);
                    var hasMessage = ageIsGreater || ageIsLess.length > 0 || isFull;
                    var hasMinBookings = true;
                    if (rd && formState.isRecurring) {
                      if (isEditMode) {
                        var editBookingsCount =
                          previewResult?.bookings.filter(
                            (p) =>
                              p.status !== BookingStatus3.CancelledInNoticePeriod &&
                              p.status !== BookingStatus3.CancelledInTime
                          ).length ?? 0;

                        hasMinBookings =
                          editBookingsCount + (previewResult?.chargedBookingsCount ?? 0) >=
                          (previewResult?.minBookingsRequired ?? 0);
                      } else {
                        hasMinBookings = hasMinRequiredBookingsAcrossWeeks(
                          formState.recurringStartDate!,
                          formState.recurringEndDate!,
                          rd!,
                          pageState.unAvailableDates,
                          formState.minBookings
                        );
                      }
                    }
                    var isEditRoll = getRollIdBeingEdited() === x.rollId;
                    var disabled = isEditMode && !isEditRoll;
                    return (
                      <Grid item xs={12} md={6} key={index}>
                        <Card
                          title={availabilityReason}
                          sx={{ position: "relative" }}
                          className={
                            isSelected
                              ? classes.selected
                              : isFull || ageIsLess.length > 0 || disabled
                              ? classes.different
                              : classes.unselected
                          }
                        >
                          {ageIsLess.length > 0 && topRightOverlay("Age is less than minimum", "#FF6933")}
                          {ageIsLess.length === 0 && isFull && topRightOverlay(availabilityReason, "#FF6933")}
                          {ageIsGreater &&
                            ageIsLess.length === 0 &&
                            !isFull &&
                            topRightOverlay("over the maximum age accepted", "#FF6933")}
                          <CardActionArea
                            sx={{ pt: hasMessage ? "20px" : "0px", height: 120 }}
                            disabled={!isSelected && (isFull || ageIsLess.length > 0 || disabled)}
                            onClick={
                              formState.isRecurring || (!isSelected && (isFull || ageIsLess.length > 0))
                                ? undefined
                                : () => {
                                    onRollSelected(x, isSelected);
                                  }
                            }
                          >
                            <Grid container direction={"row"} alignItems="center" p={1}>
                              {!isSmallScreen && (
                                <Grid item xs={2} lg={2}>
                                  <Grid container direction={"row"}>
                                    <img
                                      width={40}
                                      height={45}
                                      src={
                                        x.sessionType === "BSC"
                                          ? BSCImage
                                          : x.sessionType === "ASC"
                                          ? ASCImage
                                          : OtherImage
                                      }
                                      alt={x.sessionType}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <Grid item xs={isSmallScreen ? 12 : 9}>
                                <Grid container direction={"column"} rowGap={1}>
                                  <Grid item>
                                    <strong>{x.rollName}</strong>
                                  </Grid>
                                  <Grid item>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                      <AttachMoneyIcon fontSize="small" />
                                      <Typography fontSize={"12px"}>
                                        <NumericFormat
                                          displayType="text"
                                          prefix={`Per session $`}
                                          value={
                                            formState.isRecurring ? x.primaryFeeAmount ?? 0 : x.casualFeeAmount ?? 0
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          suffix="*"
                                        />
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid item>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                      <AccessTimeIcon fontSize="small" />
                                      <Typography fontSize={"12px"}>
                                        {x.startTime && dayjs(x.startTime).format("hh:mm a")} -{" "}
                                        {x.endTime && dayjs(x.endTime).format("hh:mm a")}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardActionArea>
                          {formState.isRecurring && (
                            <CardActions>
                              <Grid container direction={"row"} spacing={1} p={2} columnGap={3}>
                                <DaySelection
                                  title="M"
                                  selected={rd?.isMonday ?? false}
                                  disabled={isFull || ageIsLess.length > 0 || disabled || !x.monIsOpen}
                                  dayAvailable={classes.dayAvailable}
                                  dayDisabled={classes.dayDisabled}
                                  daySelected={classes.daySelected}
                                  onClick={() => {
                                    onRollDaySelected(x, "Monday");
                                  }}
                                />

                                <DaySelection
                                  title="T"
                                  dayAvailable={classes.dayAvailable}
                                  dayDisabled={classes.dayDisabled}
                                  daySelected={classes.daySelected}
                                  selected={rd?.isTuesday ?? false}
                                  disabled={isFull || ageIsLess.length > 0 || disabled || !x.tueIsOpen}
                                  onClick={() => {
                                    onRollDaySelected(x, "Tuesday");
                                  }}
                                />

                                <DaySelection
                                  title="W"
                                  dayAvailable={classes.dayAvailable}
                                  dayDisabled={classes.dayDisabled}
                                  daySelected={classes.daySelected}
                                  selected={rd?.isWednesday ?? false}
                                  disabled={isFull || ageIsLess.length > 0 || disabled || !x.wedIsOpen}
                                  onClick={() => {
                                    onRollDaySelected(x, "Wednesday");
                                  }}
                                />

                                <DaySelection
                                  title="T"
                                  dayAvailable={classes.dayAvailable}
                                  dayDisabled={classes.dayDisabled}
                                  daySelected={classes.daySelected}
                                  selected={rd?.isThursday ?? false}
                                  disabled={isFull || ageIsLess.length > 0 || disabled || !x.thuIsOpen}
                                  onClick={() => {
                                    onRollDaySelected(x, "Thursday");
                                  }}
                                />

                                <DaySelection
                                  title="F"
                                  dayAvailable={classes.dayAvailable}
                                  dayDisabled={classes.dayDisabled}
                                  daySelected={classes.daySelected}
                                  selected={rd?.isFriday ?? false}
                                  disabled={isFull || ageIsLess.length > 0 || disabled || !x.friIsOpen}
                                  onClick={() => {
                                    onRollDaySelected(x, "Friday");
                                  }}
                                />
                              </Grid>
                            </CardActions>
                          )}
                        </Card>
                        {!hasMinBookings && (
                          <Grid item xs={12}>
                            <label style={{ color: "red" }}>
                              {isEditMode &&
                                `There should be at least ${
                                  previewResult?.minBookingsRequired ?? 0
                                } bookings per session - ${rd!.roll.rollName}`}
                              {!isEditMode &&
                                `You must make a minimum of ${
                                  formState.minBookings
                                } bookings, recurring on the same days over ${formState.minBookings} or more weeks. - ${
                                  rd!.roll.rollName
                                }`}
                            </label>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>

            {hasMaxAgeViolation() && (
              <Grid item xs={12}>
                <Grid container direction={"row"} spacing={1} p={2} rowGap={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                      <strong> Need more information</strong>
                    </Typography>
                  </Grid>
                  {formState.children
                    .filter((x) => x.reasonRequired)
                    .map((child, index) => {
                      const constraints = getConstraints();
                      const ageIsGreater = isAgeGreaterThanMax(constraints, new SelectedChild(child));
                      const chidlName = getChildDisplayName(child.child);
                      if (ageIsGreater.Count() > 0) {
                        return (
                          <Grid item xs={12} sm={12} key={index}>
                            <ChildBookingReason
                              chidlName={chidlName}
                              initialReason={child.reason ?? ""}
                              rollName={ageIsGreater.First().session.rollName ?? ""}
                              selected={classes.selected}
                              onChange={(reason) => {
                                onChildReasonChanged(child.child.childId!, reason);
                              }}
                            />
                          </Grid>
                        );
                      }
                      return null;
                    })}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <ButtonPrimary
                className={classes.button}
                disabled={!isSessionValid}
                fullWidth={isMobile}
                onClick={() => {
                  if (!isSessionValid) return;
                  setcurrentStep(2);
                }}
                sx={{
                  backgroundColor: getStyleColor("--youroshc-color-primary"),
                  color: "black",
                  float: "right",
                  width: !isMobile ? "200px" : null,
                  fontWeight: "bold",
                  border: "1px solid black",
                }}
              >
                Continue
              </ButtonPrimary>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

interface IDaySelectionProps {
  title: string;
  disabled: boolean;
  selected: boolean;
  onClick: () => void;
  daySelected?: string;
  dayDisabled?: string;
  dayAvailable?: string;
}

function DaySelection(props: IDaySelectionProps) {
  const { title, disabled, selected, onClick, dayAvailable, dayDisabled, daySelected } = props;
  return (
    <IconButton
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      component="span"
    >
      <Avatar sx={{ width: 40, height: 40 }} className={selected ? daySelected : disabled ? dayDisabled : dayAvailable}>
        {title}
      </Avatar>
    </IconButton>
  );
}
