import React from "react";

import styles from "./Home.module.scss";
import FormHome from "../../components/Forms/Home/Home";
import { LayoutInitialPages } from "../../layouts/Layouts";

function Home() {
  return (
    <LayoutInitialPages>
      <div className={styles.Home}>
        <FormHome />
      </div>
    </LayoutInitialPages>
  );
}

export default Home;
