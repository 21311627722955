import { createReducer } from "deox";

import { cryptoTokenLoading, cryptoTokenLoaded, cryptoTokenError } from "./cryptoTokenStateActions";
import { TokenHttpResponse } from "../../parentApi";
import { purge } from "../purge/purgeStateActions";

export interface CryptoTokenState {
  payload: TokenHttpResponse[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
}

export const initialState: CryptoTokenState = {
  payload: null,
  isLoading: false,
  isLoaded: false,
  isError: false,
};

const cryptoTokenReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    cryptoTokenLoaded,
    (state, action): CryptoTokenState => ({
      ...state,
      payload: action.payload,
      isLoading: false,
      isLoaded: true,
      isError: false,
    })
  ),
  handleAction(
    cryptoTokenError,
    (state): CryptoTokenState => ({
      ...state,
      isLoading: false,
      isLoaded: false,
      isError: true,
    })
  ),
  handleAction(
    cryptoTokenLoading,
    (state): CryptoTokenState => ({
      ...state,
      isLoading: true,
      isLoaded: false,
      isError: false,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default cryptoTokenReducer;
