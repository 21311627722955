import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import GeneralInformation from "../../../components/Children/GeneralInformation";
import * as actions from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/store";
import { EntityType } from "../../../types/models";
import { DocumentTypes, IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import ChildStep1Schema from "./ChildStep1Schema";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep1({ child, id }: Props) {
  const primaryContact = useSelector((state: RootState) => state.register?.primary);
  const children = useSelector((state: RootState) => state.register?.children);
  const courtOrderDocuments = useSelector((state: RootState) =>
    state.register?.documents.filter(
      (document) =>
        document.registrationChildUniqueId === child.uniqueId && document.documentTypeId === DocumentTypes.CourtOrders
    )
  );
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  function updateChild(values: IChild) {
    actions.saveChild(dispatch, values);
    navigate(`/registration-child-step/2/${id}`);
  }

  if (child.sameAddressDetailsAsPrimary === undefined) {
    child.sameAddressDetailsAsPrimary = false;
  }

  if (child.sameCulturalDetailsAsPrimary === undefined) {
    child.sameCulturalDetailsAsPrimary = false;
  }

  if (child.sameSchoolDetailsAsFirstChild === undefined) {
    child.sameSchoolDetailsAsFirstChild = false;
  }

  if (child.residentialAddress === undefined) {
    child.residentialAddress = {
      uniqueId: uuid(),
    };
  }

  var childIndex = children.indexOf(child);
  var firstChild = childIndex !== 0 && children[0].uniqueId !== child.uniqueId ? children[0] : null;

  const formikValues = {
    entityType: EntityType.RegistrationChild,
    entityId: child.uniqueId?.toString(),
    culturalBackground: "",
    culturalNeeds: "",
    mainLanguage: "",
    genderId: "",
    otherGender: "",
    schoolId: -1,
    schoolName: "",
    birthday: dayjs.Dayjs,
    sameAddressDetailsAsPrimary: false,
    sameCulturalDetailsAsPrimary: false,
    sameSchoolDetailsAsFirstChild: false,
    residentialAddress: { uniqueId: uuid(), streetLine1: "", suburb: "", state: "", postCode: "" },
  };
  const initialValues = merge(formikValues, child);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ChildStep1Schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild(values);
        }, 500);
      }}
    >
      {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
        <Form>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={12}>
              <GeneralInformation
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                firstChild={firstChild}
                primaryContact={primaryContact}
                courtOrderDocuments={courtOrderDocuments}
                dispatch={dispatch}
              />
            </Grid>
          </Grid>
          <Pager>
            <ButtonSecondary href="/registration-pending-information">
              <strong>Back</strong>
            </ButtonSecondary>
            <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
              <strong>Next</strong>
            </ButtonPrimary>
          </Pager>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}

export default FormChildStep1;
