import api from "./commonApi";
import { commonDataError, commonDataLoading, commonDataSuccess } from "./commonStateActions";
import { AddressSearchResultHttpResponse, IGetCommonDataHttpResponse } from "../../parentApi";

export function getCommonData(dispatch: any) {
  dispatch(
    commonDataLoading({
      states: [],
      genders: [],
      bookingCancellationStatuses: [],
      careTypes: [],
      popularLanguages: [],
      contactTypes: [],
      priorityAccess: [],
      childSituations: [],
      transQueryTypes: [],
      medicalPlanTemplate: null,
      programActivityTypes: [],
    })
  );
  return api.getCommonData().then(
    (response: IGetCommonDataHttpResponse | null) => {
      return dispatch(response === null ? commonDataError() : commonDataSuccess(response));
    },
    (error: any) => dispatch(commonDataError())
  );
}

export function searchAddress(pattern: string) {
  return api
    .searchAddress(pattern, 10)
    .then((response: AddressSearchResultHttpResponse[] | null) => (response ? response : []));
}

export function getCommonDates(customerAccountId: number, year: number) {
  return api.getCommonDates(customerAccountId, year);
}
