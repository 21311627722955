import React from "react";

import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import styles from "./InitialFooter.module.scss";

const useStyles = makeStyles()((theme) => ({
  footerLinks: {
    fontSize: 10,
    fontFamily: "ABC Whyte, Medium",
    textDecoration: "underline",
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  },

  footerDivider: {
    fontSize: 10,
    fontFamily: "ABC Whyte, Medium",
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  },
  footerLeftSection: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  footerRightSection: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
}));

function InitialFooter() {
  const { classes } = useStyles();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Grid
        container
        className={classes.footerLeftSection}
        xs={12}
        md={10}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ mx: "auto", display: "flex !important", alignContent: "center !important" }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          wrap="nowrap"
          className={styles["InitialFooter-nav"]}
          style={{
            fontFamily: "ABC Whyte, Book",

            overflowY: "auto",
            overflowX: "hidden",
            overflow: "auto",
          }}
        >
          <Grid item>
            <Link className={styles["InitialFooter-InitialFooterlink"]} href="https://campaustralia.com.au/contact-us">
              <Typography className={classes.footerLinks} noWrap>
                Contact us
              </Typography>
            </Link>
          </Grid>

          <Grid item>
            <Typography className={classes.footerDivider} noWrap>
              |
            </Typography>
          </Grid>

          <Grid item>
            <Link
              className={styles["InitialFooter-InitialFooterlink"]}
              href="https://campaustralia.com.au/privacy-policy"
            >
              <Typography className={classes.footerLinks} noWrap>
                Privacy Policy
              </Typography>
            </Link>
          </Grid>

          <Grid item>
            <Typography className={classes.footerDivider} noWrap>
              |
            </Typography>
          </Grid>

          <Grid item>
            <Link
              className={styles["InitialFooter-InitialFooterlink"]}
              href="https://campaustralia.com.au/social-media-policy"
            >
              <Typography className={classes.footerLinks} noWrap>
                Moderation Policy
              </Typography>
            </Link>
          </Grid>

          <Grid item>
            <Typography className={classes.footerDivider} noWrap>
              |
            </Typography>
          </Grid>

          <Grid item>
            <Link
              className={styles["InitialFooter-InitialFooterlink"]}
              href="https://campaustralia.com.au/terms-conditions"
            >
              <Typography className={classes.footerLinks} noWrap>
                Terms and Conditions
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      {/* COPYRIGHT MESSAGE */}
      <Grid
        container
        className={classes.footerRightSection}
        xs={12}
        md={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
      >
        <Grid item style={{ fontFamily: "ABC Whyte, Book" }} className={styles["InitialFooter-nav"]}>
          <Typography className={styles["InitialFooter-copyright"]} sx={{ fontSize: { xs: 10, md: 15 } }}>
            Copyright &copy; {currentYear} Camp Australia
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default InitialFooter;
