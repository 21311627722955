import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";

interface Props {
  id: string;
  label: string;
  handleBlur: any;
  onChange: (event: React.ChangeEvent<any>) => void;
  childSituations: number[];
  helperText: any;
  error: any;
  classes?: any;
}

export const ChildSituationsSelector = ({ onChange, handleBlur, childSituations, classes }: Props) => {
  const childSituationsList = useSelector((state: RootState) => state?.commonData?.data.childSituations);
  return (
    <FormGroup>
      {childSituationsList.map((childSituation, index) => (
        <FormControlLabel
          className={classes.formControlLabel}
          key={childSituation.childSituationId}
          control={
            <Checkbox
              style={{ alignSelf: "flex-start", padding: "0px 9px" }}
              name={childSituation.name}
              key={childSituation.childSituationId}
              value={childSituation.childSituationId}
              onClick={onChange}
              onBlur={handleBlur("childSituations")}
              checked={
                childSituations &&
                childSituations.length > 0 &&
                childSituations.map((x) => x.toString()).includes(childSituation.childSituationId.toString())
              }
            />
          }
          label={childSituation.name}
        />
      ))}
    </FormGroup>
  );
};
