import { useEffect } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { IoChevronDownOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { getNextBillingCharges } from "../../store/modules/upcomingCharges/upcomingChargesActions";
import { RootState } from "../../store/store";
import theme from "../../theme";
import { formatCurrency, getStyleColor } from "../../utils/helpers";
import UpcomingTransactions from "./UpcomingTransactions";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 2,
  },
  accordion: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    border: 0,
    borderRadius: 3,
    marginBottom: theme.spacing(1),
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    alignItems: "flex-start",
  },
}));

export default function UcomingChargesListing() {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const charges = useSelector((state: RootState) => state.upcomingCharges.payload);
  const isLoaded = useSelector((state: RootState) => state.upcomingCharges.isLoaded);

  useEffect(() => {
    if (customer_account_id && !isLoaded) {
      getNextBillingCharges(dispatch, customer_account_id);
    }
  }, [customer_account_id, isLoaded]);

  return charges && charges.charges.length > 0 ? (
    <Accordion
      elevation={0}
      TransitionProps={{
        timeout: 0,
      }}
      className={classes.accordion}
    >
      <AccordionSummary className={classes.accordionSummary} expandIcon={<IoChevronDownOutline />}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Typography style={{ color: theme.palette.text.primary }}>
                  {charges?.billingPeriodEnd.utc(false).format("D MMM YYYY") ?? ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={4} md={3}>
                {formatCurrency(charges.charges.reduce((a, b) => a + b.grossAmount, 0))}
                <br />
                <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>GROSS</span>
              </Grid>
              <Grid item xs={4} md={3}>
                {formatCurrency(charges.charges.reduce((a, b) => a + (b.ccs + b.accs), 0))}
                <br />
                <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>CCS + ACCS</span>
              </Grid>
              <Grid item xs={4} md={3}>
                {formatCurrency(charges.charges.reduce((a, b) => a + b.discountAmount, 0))}
                <br />
                <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>OTHER</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <>
                  <strong>
                    {formatCurrency(charges.charges.reduce((a, b) => a + (b.grossAmount - b.discountAmount), 0))}
                  </strong>
                  <br />
                  <span style={{ color: theme.palette.info.dark, fontSize: 14 }}>TOTAL</span>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="flex-end" spacing={2} className={classes.root}>
          <UpcomingTransactions transactions={charges.charges} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
}
