import SecurityOutlined from "@mui/icons-material/SecurityOutlined";
import Alert from "@mui/lab/Alert";
import { Box, Typography, Link } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import styles from "./ConfirmEmail.module.scss";
import ConfirmEmailSchema from "./ConfirmEmailSchema";
import { sendOtpEmail, verifyEmail } from "../../../store/modules/signup/signupActions";
import { sendOtpEmailReset } from "../../../store/modules/signup/signupActionTypes";
import { persistor, RootState } from "../../../store/store";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(3),
    maxHeight: "53.95px",
    [theme.breakpoints.between("xs", "sm")]: {
      width: 300,
      fontSize: 10,
    },
  },
  headline: {
    fontfamily: "ABC Whyte, Medium",
    fontWeight: "bold",
    fontSize: 23,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 21,
    },
  },
  headlineSubtitle: {
    fontfamily: "ABC Whyte, Medium",
    padding: 15,
    fontSize: 16,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "black !important",
    color: "black !important",
  },
  registrationTitle: {
    fontfamily: "ABC Whyte, Book",
    paddingBottom: 5,
    fontSize: 15,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 12,
    },
    display: "block",
    textAlign: "center",
  },
}));

interface ConfirmEmailState {
  token: string;
}

function FormConfirmEmail() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = useSelector((state: RootState) => state.signup.email);
  const isVerifyError = useSelector((state: RootState) => state.signup.isVerifyError);
  const isSendOtpEmailError = useSelector((state: RootState) => state.signup.isSendOtpEmailError);
  const isSendOtpEmailSuccess = useSelector((state: RootState) => state.signup.isSendOtpEmailSuccess);

  function verify(values: ConfirmEmailState): void {
    if (email) verifyEmail(dispatch, email, values.token);
  }

  function resendEmail(event: any): void {
    event.preventDefault();
    if (email) sendOtpEmail(dispatch, email);
    setTimeout(() => {
      dispatch(sendOtpEmailReset());
    }, 3000);
  }

  async function cancelEmail(event: any) {
    event.preventDefault();
    await persistor.purge();
    navigate("/");
  }

  const initialValues = {
    token: "",
  };

  return (
    <Box
      id="topHome"
      className={styles["Home"]}
      sx={{
        borderRadius: 5,
        maxWidth: { xs: 300, sm: 550 },
        maxHeight: 700,
        mx: "auto",
        padding: "2rem",
        background: "#fff",
        marginTop: "20px",
      }}
    >
      <Typography align="center" variant="h5" className={classes.headline}>
        Confirm Email
      </Typography>
      <Typography align="center" className={classes.headlineSubtitle}>
        We’ve sent an activation code to your email
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={ConfirmEmailSchema}
        onSubmit={(values, { setSubmitting }) => {
          verify(values);
          setTimeout(function () {
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ handleChange, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
          <Form>
            <TextField
              id="token"
              label="Enter email code"
              type="text"
              value={values.token}
              onChange={handleChange("token")}
              onBlur={handleBlur("token")}
              helperText={touched.token ? errors.token : ""}
              error={touched.token && Boolean(errors.token)}
              fullWidth
              className={classes.textField}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SecurityOutlined />
                  </InputAdornment>
                ),
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                style: { color: "black", fontWeight: "bold" },
              }}
            />
            {isVerifyError && <Alert severity="error">Please check your code</Alert>}
            {isSendOtpEmailError && <Alert severity="error">Oops something went wrong. Please try again.</Alert>}
            {isSendOtpEmailSuccess && (
              <Alert variant="outlined" severity="success">
                Email code has been sent successfully
              </Alert>
            )}
            <p>Please check your Inbox and Spam folders!</p>
            <ButtonPrimary
              sx={{
                margin: { xs: 0 },
                fontSize: { xs: 10, md: 328 },
              }}
              disabled={isSubmitting}
              onClick={submitForm}
              fullWidth
            >
              <strong>Submit</strong>
            </ButtonPrimary>
            <ButtonSecondary onClick={resendEmail} fullWidth>
              <strong>Resend email</strong>
            </ButtonSecondary>
            <Link onClick={cancelEmail} href="#" className={classes.registrationTitle}>
              Cancel
            </Link>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FormConfirmEmail;
