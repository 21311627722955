/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";

import { Grid } from "@mui/material";

import styles from "./Footer.module.scss";
const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <footer className={styles["Footer"]}>
      <Grid className={styles["Footer-nav"]}>
        <a href="https://campaustralia.com.au/contact-us">Contact us</a>
        <a href="javascript:void(0)" style={{ cursor: "default" }}>
          |
        </a>
        <a href="https://campaustralia.com.au/privacy-policy">Privacy Policy</a>
        <a href="javascript:void(0)" style={{ cursor: "default" }}>
          |
        </a>
        <a href="https://campaustralia.com.au/social-media-policy">Moderation Policy</a>
        <a href="javascript:void(0)" style={{ cursor: "default" }}>
          |
        </a>
        <a href="https://campaustralia.com.au/terms-conditions">Terms and Conditions</a>
      </Grid>
      <div className={styles["Footer-copyright"]}>Copyright &copy; {currentYear} Camp Australia</div>
    </footer>
  );
}

export default Footer;
