import React, { createRef, useEffect } from "react";

import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import PayNowSchema, { IPayNowState, PaymentAmountOption, PaymentMethod } from "./PayNowSchema";
import Balances from "../../components/Billing/Balances";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import ConfirmDialog from "../../components/Common/Dialogs/ConfirmDialog";
import BankAccount, { IBankAccountProps, IBankAccoutRef } from "../../components/Payment/BankAccount";
import CreditCard, { ICardRef, ICreditCardProps } from "../../components/Payment/CreditCard";
import { CardLayout } from "../../layouts/Layouts";
import { getCryptoTokens } from "../../store/modules/cryptoToken/cryptoTokenActions";
import {
  makeOneOffBankPayment,
  makeOneOffCardPayment,
  makeRegularPayment,
} from "../../store/modules/payment/paymentActions";
import { getCurrentPaymentMethod } from "../../store/modules/paymentMethod/paymentMethodActions";
import { RootState } from "../../store/store";
import { AccountStatement, PaymentType } from "../../types/types";
import { getAvailableToken } from "../../utils/cryptoTokenHelper";
import { formatCurrency } from "../../utils/helpers";

export default function PayNow() {
  const balance = useSelector((state: RootState) => state.balance.payload);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const isProcessing = useSelector((state: RootState) => state.payment.isProcessing);
  const creditCardSurcharge = useSelector((state: RootState) => state.appConfigs.creditCardSurcharge);
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const cryptoTokenState = useSelector((state: RootState) => state.cryptoToken);
  const cryptoTokenIsLoading = useSelector((state: RootState) => state.cryptoToken.isLoading);
  const cryptoTokenIsLoaded = useSelector((state: RootState) => state.cryptoToken.isLoaded);
  const cryptoToken = getAvailableToken(cryptoTokenState);

  const cardRef = createRef<ICardRef>();
  const bankRef = createRef<IBankAccoutRef>();

  const dispatch = useDispatch();
  const isError = useSelector((state: RootState) => state.payment.isError);
  const isSuccess = useSelector((state: RootState) => state.payment.isSuccess);
  const [open, setOpen] = React.useState(false);

  const handleConfirmOpen = () => {
    setOpen(true);
  };

  const handleConfirmClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!currentPaymentMethod) {
      getCurrentPaymentMethod(dispatch, customer_account_id ?? 0);
    }
  }, [currentPaymentMethod, customer_account_id]);

  useEffect(() => {
    if (!cryptoTokenIsLoaded && !cryptoTokenIsLoading) {
      getCryptoTokens(dispatch);
    }
  }, [cryptoTokenIsLoading, cryptoTokenIsLoaded]);

  useEffect(() => {
    if (isError || isSuccess) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [isError, isSuccess]);

  function getAmount(values: IPayNowState, includeSurcharge: boolean): number {
    let amount =
      values.amountOption === "Current"
        ? balance!.balance
        : values.amountOption === "Statment"
        ? balance!.statementBalance!
        : values.amount;
    let isSurcharge =
      values.method === "CreditCard" ||
      (values.method === "Default" && currentPaymentMethod?.paymentType.toLowerCase() === "creditcard");
    if (isSurcharge && includeSurcharge) {
      if (creditCardSurcharge) {
        let surcharge = 1 + creditCardSurcharge / 100;
        return amount * surcharge;
      } else return 0;
    }
    return amount;
  }

  function getPaymentMethodDescription() {
    return `${currentPaymentMethod?.paymentTypeName} ending with ....${currentPaymentMethod?.masked}`;
  }
  function isCreditCard(values: IPayNowState): boolean {
    switch (values.method) {
      case "Default":
        return currentPaymentMethod?.paymentType === PaymentType.CreditCard;
      case "CreditCard":
        return true;

      default:
        return false;
    }
  }
  function getPaymentMethod(values: IPayNowState): string {
    switch (values.method) {
      case "Default":
        return getPaymentMethodDescription();
      case "CreditCard":
        return `Credit Card ending with ...${
          values.card.cardNumber && values.card.cardNumber.substring(values.card.cardNumber.length - 4)
        }`;
      case "BankAccount":
        return `Bank Account ending with ...${
          values.account.accountNumber &&
          values.account.accountNumber.toString().substring(values.account.accountNumber.toString().length - 4)
        }`;
      default:
        return "";
    }
  }

  function processPayment(values: IPayNowState): void {
    let amount: number = getAmount(values, false);
    switch (values.method) {
      case "BankAccount":
        values.account.bsb &&
          values.account.accountNumber &&
          makeOneOffBankPayment(dispatch, customer_account_id ?? 0, customer_contact_id ?? 0, amount, {
            accountName: values.account.accountName,
            accountNumber: values.account.accountNumber.toString(),
            bsb: values.account.bsb.toString(),
          });
        break;

      case "CreditCard":
        if (cryptoToken !== null) {
          makeOneOffCardPayment(dispatch, cryptoToken, customer_account_id ?? 0, customer_contact_id ?? 0, amount, {
            month: parseInt(values.card.expiry.substr(0, 2)),
            year: parseInt(values.card.expiry.substring(3, 7)),
            name: values.card.fullName,
            number: values.card.cardNumber,
            cvc: values.card.cvcNumber,
          });
        }
        break;

      case "Default":
        makeRegularPayment(dispatch, customer_account_id ?? 0, customer_contact_id ?? 0, amount);
        break;
    }
  }

  const useStyles = makeStyles()((theme) => {
    return {
      root: {
        display: "flex",
        flexWrap: "wrap",
      },
      control: {
        padding: theme.spacing(2),
      },
      h1: {
        marginTop: 0,
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "white",
      },
      textField: {
        marginBottom: theme.spacing(2),
      },
    };
  });
  const { classes } = useStyles();

  function getDefaultPaymentAmount(): PaymentAmountOption {
    return balance
      ? balance.statementBalance && balance.statementBalance > 0
        ? "Statment"
        : balance.balance && balance.balance > 0
        ? "Current"
        : "Custom"
      : "Custom";
  }
  return (
    <CardLayout>
      <CardLayout.Header bgColor="white">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <h1 className={`h2 ${classes.h1}`}>Pay Now</h1>
          </Grid>
          <Grid item>
            <Balances />
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <Formik
          validationSchema={PayNowSchema}
          initialValues={{
            amount: 0,
            method: currentPaymentMethod ? ("Default" as PaymentMethod) : ("CreditCard" as PaymentMethod),
            amountOption: getDefaultPaymentAmount(),
            card: {
              cardType: "Mastercard",
              cardNumber: "",
              fullName: "",
              expiry: "",
              cvcNumber: "",
              path: "card.",
            } as ICreditCardProps,
            account: {
              path: "account.",
              isOneOffPayment: true,
              bsb: undefined,
              accountName: "",
              accountNumber: undefined,
            } as IBankAccountProps,
          }}
          onSubmit={() => {
            var isValid = true;
            if (cardRef != null && cardRef.current) {
              isValid = cardRef.current.validate();
            }
            if (bankRef != null && bankRef.current) {
              isValid = bankRef.current.validate();
            }

            if (isValid) {
              handleConfirmOpen();
            }
          }}
        >
          {({ handleChange, setSubmitting, isSubmitting, values, touched, errors }) => (
            <Form autoComplete="off">
              <ConfirmDialog
                title="Confirm Payment"
                open={open}
                onClose={handleConfirmClose}
                onConfirm={() => {
                  setTimeout(() => {
                    setSubmitting(false);
                    processPayment(values);
                  }, 500);
                  handleConfirmClose();
                }}
              >
                <DialogContentText>
                  {isCreditCard(values) && creditCardSurcharge
                    ? getMerchantFeeChargeNotification(creditCardSurcharge)
                    : null}
                  You are about to make a payment. Do you wish to continue?
                  <Grid item xs={12} className={classes.control}>
                    <strong>TOTAL</strong> {formatCurrency(getAmount(values, true))}
                  </Grid>
                  <Grid item xs={12} className={classes.control}>
                    <strong> PAYMENT METHOD</strong> {getPaymentMethod(values)}
                  </Grid>
                </DialogContentText>
              </ConfirmDialog>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Divider></Divider>
                  <NotificationMessage />
                </Grid>
                <Grid item xs={12}>
                  <PaymentAmount balance={balance} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <Box paddingBottom={3} paddingTop={3}>
                      <strong> Payment Method</strong>
                    </Box>
                    <RadioGroup
                      aria-label="method"
                      id="method"
                      name="method"
                      value={values.method}
                      onChange={handleChange}
                    >
                      {currentPaymentMethod && (
                        <FormControlLabel value="Default" control={<Radio />} label={getPaymentMethodDescription()} />
                      )}
                      <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                      <FormControlLabel value="BankAccount" control={<Radio />} label="Bank Account" />
                    </RadioGroup>
                  </FormControl>
                  <Box paddingTop={3} />
                  {values.method === "CreditCard" && <CreditCard ref={cardRef} {...values.card} />}
                  {values.method === "BankAccount" && <BankAccount ref={bankRef} {...values.account} />}
                </Grid>
                <Grid item xs={12}>
                  <ButtonPrimary type="submit" disabled={isProcessing}>
                    <strong>Pay Now</strong>
                  </ButtonPrimary>
                </Grid>
              </Grid>
              <Backdrop className={classes.backdrop} open={isProcessing}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Form>
          )}
        </Formik>
      </CardLayout.Body>
    </CardLayout>
  );
}
interface IAmountProps {
  balance: AccountStatement | null;
}
function getMerchantFeeChargeNotification(creditCardSurcharge: number) {
  return (
    <Box paddingBottom={3}>
      <Alert severity="warning">
        <AlertTitle>All payments by credit or debit card will incur the following surcharges:</AlertTitle>
        MasterCard (including Debit MasterCard) – {creditCardSurcharge}%
        <br />
        VISA (including VISA DebitCard) – {creditCardSurcharge}%
      </Alert>
    </Box>
  );
}
function PaymentAmount({ balance }: IAmountProps) {
  const { values, handleChange } = useFormikContext<IPayNowState>();

  return (
    <FormControl component="fieldset">
      <Box paddingBottom={3} paddingTop={3}>
        <strong>Payment Amount</strong>
      </Box>
      <RadioGroup aria-label="amountOption" name="amountOption" value={values.amountOption} onChange={handleChange}>
        {balance && balance.statementBalance && balance.statementBalance > 0 && (
          <FormControlLabel
            value="Statment"
            control={<Radio />}
            label={`Balance at ${balance.statementDate} ${formatCurrency(balance.statementBalance)}`}
          />
        )}

        {balance && balance.balance && balance.balance > 0 && (
          <FormControlLabel
            value="Current"
            control={<Radio />}
            label={`Current Balance ${formatCurrency(balance.balance)}`}
          />
        )}
        <FormControlLabel value="Custom" control={<Radio />} label="Custom Amount" />
      </RadioGroup>
      {values.amountOption === "Custom" && (
        <>
          <TextField
            id="amount"
            type="number"
            onChange={handleChange}
            placeholder="Enter amount"
            value={values.amount}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          ></TextField>
          <ErrorMessage name={`amount`}>{(msg) => <Alert severity="warning">{msg}</Alert>}</ErrorMessage>
        </>
      )}
    </FormControl>
  );
}

const NotificationMessage = (props: any) => {
  const [successLocal, setSuccessLocal] = React.useState(false);
  const [msgLocal, setMsgLocal] = React.useState("");
  const [failed, setFailed] = React.useState(false);
  const isSuccess = useSelector((state: RootState) => state.payment.isSuccess);
  const successMessage = useSelector((state: RootState) => state.payment.title);
  const isError = useSelector((state: RootState) => state.payment.isError);
  const paymentError = useSelector((state: RootState) => state.payment.error);
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (isSuccess) {
      setMsgLocal(successMessage ?? "");
      setSuccessLocal(isSuccess);
      resetForm({});
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setMsgLocal(paymentError?.message ?? "");
      setFailed(isError);
    }
  }, [isError]);

  let msg = failed ? (
    <Alert severity="error">{msgLocal}</Alert>
  ) : successLocal ? (
    <Alert severity="success">{msgLocal}</Alert>
  ) : (
    <div></div>
  );

  return <> {msg}</>;
};
