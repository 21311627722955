import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { List } from "linqts";
import { IoChevronDownOutline, IoCloseOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { RootState } from "../../store/reducers";
import theme from "../../theme";
import { Child, EntityType } from "../../types/models";
import { IBookingCancellationReasonModel, IBookingToCancel, IRollInfo } from "../../types/types";
import { getStyleColor } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface Props {
  child: Child;
  roll: IRollInfo;
  bookings: List<IBookingToCancel>;
  cancellationReasonId: number;
  cancellationReasonOtherText?: string;
  cancellationReasons: IBookingCancellationReasonModel[];
}
const useStyles = makeStyles()((theme) => {
  return {
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    icon: {},
    accordion: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      border: 0,
      borderRadius: 3,
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      "&::before": {
        display: "none",
      },
    },
    accordionSummary: {
      alignItems: "flex-start",
    },
  };
});

function ChildBookingCancelCard(props: Props) {
  const { classes } = useStyles();
  const bookingsByMonth = props.bookings.OrderBy((d) => d.date).GroupBy((d) => d.date.format("MMM YYYY"));
  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Child && x.entityId === props.child.childId?.toString()
    )
  );
  const nonChargeableCount = props.bookings.Count((x) => x?.cancellationStatusId !== 2);
  const chargeableCount = props.bookings.Count((x) => x?.cancellationStatusId === 2);
  return (
    <Box pb={2} key={"ChildAccordCancel_" + props.child.childId}>
      <Accordion className={classes.accordion} TransitionProps={{ timeout: 0 }} elevation={0} defaultExpanded>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<IoChevronDownOutline />}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <AvatarDisplay info={childAvatar ?? { index: props.child.avatarIndex }} />
                </Grid>
                <Grid item>
                  {props.child.firstName} {props.child.lastName}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span className="color-dark-grey">{props.roll?.service?.serviceName?.toUpperCase()}</span>
            </Grid>
            {nonChargeableCount > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <IoCloseOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                      <span style={{ color: theme.palette.success.main }}>{props.roll.rollName}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" alignContent="center" justifyContent="flex-end">
                      {`Session${nonChargeableCount > 1 ? "s" : ""} x ${nonChargeableCount}`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {chargeableCount > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <IoCloseOutline size={24} color={theme.palette.error.main} style={{ marginRight: 10 }} />
                      <span style={{ color: theme.palette.error.main }}>{props.roll.rollName}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" alignContent="center" justifyContent="flex-end">
                      {`Session${chargeableCount > 1 ? "s" : ""} x ${chargeableCount}`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    Reason for cancellation:&nbsp;
                    <strong>
                      {
                        props.cancellationReasons.find(
                          (x) => x.bookingCancellationReasonId === props.cancellationReasonId
                        )?.name
                      }
                    </strong>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {DisplayBookings(props, bookingsByMonth)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
function DisplayBookings(props: Props, bookingsByMonth: { [key: string]: IBookingToCancel[] }): JSX.Element[] {
  var data = new Array<JSX.Element>();
  for (var key in bookingsByMonth) {
    var monthBookings = bookingsByMonth[key];
    data.push(
      <React.Fragment key={"ChildAccordCancel_" + props.child.childId + "_Month_" + monthBookings[0].date.month()}>
        <Grid item xs={12}>
          <Box paddingTop={2} />
        </Grid>
        <Grid item xs={12}>
          <strong>{key}</strong>
        </Grid>
        {DisplayBookingsByDate(props, monthBookings)}
      </React.Fragment>
    );
  }
  return data;
}

function DisplayBookingsByDate(props: Props, bookings: IBookingToCancel[]): JSX.Element[] {
  var data = new Array<JSX.Element>();
  for (var bd of bookings) {
    data.push(
      <Grid item xs={12} key={"ChildAccordCancel_" + props.child.childId + "_BookingItem_" + bd.uniqueId}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{bd.date.format("ddd, D")}</Grid>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              {bd.cancellationStatusId === 1 ? (
                <Grid item>
                  <Grid container alignItems="center">
                    <IoCloseOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                    <span style={{ color: theme.palette.success.main, lineHeight: "24px" }}>Cancellation</span>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Grid container alignItems="center">
                    <IoCloseOutline size={24} color={theme.palette.error.main} />
                    <span style={{ color: theme.palette.error.main, lineHeight: "24px" }}>
                      Late Cancellation&nbsp;
                      <NumericFormat
                        displayType="text"
                        prefix=" $"
                        value={bd.feeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </span>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return data;
}
export default ChildBookingCancelCard;
