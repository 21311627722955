import React, { useEffect, useState } from "react";

import CancelButton from "@mui/icons-material/CancelRounded";
import Alert from "@mui/lab/Alert/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import { parseInt } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { submitQuery } from "../../store/modules/transactionQuery/transactionQueryActions";
import { RootState } from "../../store/store";
import theme from "../../theme";
import { StatementTransaction, TransactionQueryType } from "../../types/types";
import { formatCurrency } from "../../utils/helpers";
import { ButtonPrimary } from "../Common/Buttons/Buttons";
import FileUploader from "../Common/Buttons/FileUploader";
import TransQuerySchema from "./RaiseQuerySchema";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const RaiseQuery = (props: any) => {
  const { showForm, transactions } = props;
  const reasons = useSelector((state: RootState) => state.commonData.data?.transQueryTypes);
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);

  return (
    <Box zIndex="tooltip">
      <Collapse in={showForm}>
        <Paper elevation={0}>
          <QueryForm
            reasons={reasons}
            transactions={transactions}
            accountId={customer_account_id}
            handleDismiss={props.handleDismiss}
            billingId={props.billingId}
          />
        </Paper>
      </Collapse>
    </Box>
  );
};
interface ISubmitQueryState {
  reasonId: string;
  details: string;
  transactions: number[];
}
const QueryForm = (props: any) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { reasons, accountId, transactions, billingId } = props;
  const isSaving = useSelector((state: RootState) => state.raiseQuery.isSaving);

  function _submit(values: ISubmitQueryState, file: File | null): void {
    submitQuery(
      dispatch,
      accountId,
      file,
      values.details,
      parseInt(values.reasonId),
      values.transactions,
      false,
      billingId
    );
  }
  const serviceDescription = (txn: StatementTransaction) => {
    return `${txn.entryDescr} - ${txn.entryType} - ${txn.sessionType ?? ""}`;
  };

  return (
    <Formik
      validationSchema={TransQuerySchema}
      initialValues={{
        reasonId: "",
        transactions: [] as any,
        details: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          _submit(values, selectedFile);
        }, 500);
      }}
    >
      {({
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isSubmitting,
        values,
        touched,
        errors,
      }) => (
        <Box padding={3}>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container alignItems="flex-start" justifyContent="flex-start">
                  <Grid item xs>
                    If you believe your CCS entitlements are incorrect or have questions regarding your CCS payments,
                    eligibility or the application process to receive CCS, please contact Centrelink directly on 13 61
                    50 or log into &nbsp;
                    <Link href="https://my.gov.au/LoginServices/main/login" target="_blank">
                      <strong>MyGov</strong>
                    </Link>
                    .
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      type="reset"
                      onClick={props.handleDismiss}
                      style={{ marginTop: theme.spacing(-1) }}
                    >
                      <CancelButton />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl required fullWidth>
                  <TextField
                    select
                    label="Select a reason"
                    id="reasonId"
                    name="reasonId"
                    value={values.reasonId}
                    onChange={handleChange("reasonId")}
                    variant="filled"
                    required
                  >
                    {reasons &&
                      reasons.length &&
                      reasons.map((itm: TransactionQueryType) => (
                        <MenuItem key={itm.id} value={itm.id}>
                          {itm.description}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  {transactions &&
                    transactions.length > 0 &&
                    transactions.map((trans: StatementTransaction, index: number) => (
                      <Grid item key={index} xs={12}>
                        <Grid container alignItems="center">
                          <Grid item xs={12}>
                            <span className="color-dark-grey">{trans.serviceName?.toUpperCase()}</span>
                          </Grid>
                          <Grid item xs>
                            {serviceDescription(trans)}
                          </Grid>
                          <Grid item>{formatCurrency(trans.totalMovement)}</Grid>
                          <Grid item>
                            <Checkbox
                              name="transactions"
                              checked={values.transactions.includes(trans.transactionId)}
                              onChange={(e, checked) => {
                                if (checked) {
                                  values.transactions.push(trans.transactionId);
                                } else {
                                  values.transactions.splice(values.transactions.indexOf(trans.transactionId), 1);
                                }
                                setFieldTouched("transactions");
                              }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <TextField
                    required
                    label="Details"
                    onChange={handleChange("details")}
                    value={values.details}
                    multiline
                    rows={4}
                    className={classes.textField}
                    id="details"
                    helperText="To help us assist with your query, please provide all details relevant to your concern and our friendly Customer Care team will get back to you within 48 hours."
                    variant="outlined"
                  />
                </FormControl>
                {errors && (
                  <ErrorMessage name="details">{(msg) => <Alert severity="warning">{msg}</Alert>}</ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FileUploader
                    accept="image/*"
                    onFileSelect={(file: File) => {
                      setSelectedFile(file);
                      setFieldValue("fileData", file);
                    }}
                  />
                  {selectedFile != null && selectedFile.name && <label>{selectedFile?.name}</label>}
                </FormControl>
              </Grid>
              {errors && (
                <Grid item xs={12}>
                  <ErrorMessage name="transactions">{(msg) => <Alert severity="warning">{msg}</Alert>}</ErrorMessage>
                </Grid>
              )}
              <NotificationMessage resetFile={setSelectedFile} billingId={billingId} />
              <Grid item xs={12}>
                <ButtonPrimary disabled={isSubmitting} type="submit" fullWidth>
                  <strong>Submit</strong>
                </ButtonPrimary>
              </Grid>
              <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

const NotificationMessage = (props: any) => {
  const isSubmitted = useSelector(
    (state: RootState) => state.raiseQuery.isSaved && state.raiseQuery.billingId === props.billingId
  );
  const isError = useSelector(
    (state: RootState) => state.raiseQuery.isError && state.raiseQuery.billingId === props.billingId
  );
  const [successLocal, setSuccessLocal] = React.useState(false);
  const [failedLocal, setFailedLocal] = React.useState(false);
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (isSubmitted) {
      setSuccessLocal(isSubmitted);
      resetForm({});
      props.resetFile();
    }
  }, [isSubmitted]);
  useEffect(() => {
    if (isError) {
      setFailedLocal(isError);
    }
  }, [isError]);

  return successLocal ? (
    <Alert severity="success">Your query has been received.</Alert>
  ) : failedLocal ? (
    <Alert severity="error">An error has been encountered.</Alert>
  ) : null;
};
export default RaiseQuery;
