import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import {
  resetPasswordError,
  resetPasswordInit,
  resetPasswordPending,
  resetPasswordSuccess,
} from "./resetPasswordStateActions";

export interface ResetPasswordState {
  status: ResetPasswordStatus;
  payload: String | null;
}
type ResetPasswordStatus = "Initial" | "Pending" | "Success" | "Errored";

export const initialState: ResetPasswordState = {
  status: "Initial",
  payload: null,
};

const resetPasswordReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    resetPasswordSuccess,
    (state, action): ResetPasswordState => ({
      ...state,
      status: "Success",
      payload: null,
    })
  ),
  handleAction(
    resetPasswordPending,
    (state, action): ResetPasswordState => ({
      ...state,
      status: "Pending",
      payload: null,
    })
  ),
  handleAction(
    resetPasswordError,
    (state, action): ResetPasswordState => ({
      ...state,
      status: "Errored",
      payload: action.payload,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(resetPasswordInit, () => initialState),
]);

export default resetPasswordReducer;
