import apiPayment from "./upcomingChargesApi";
import { upcomingError, upcomingLoading, upcomingSuccess } from "./upcomingChargesStateActions";

export function getUpcomingCharges(dispatch: any, customerAccountId: number) {
  // dispatch(upcomingLoading());
  apiPayment.getUpcomingCharges(customerAccountId).then(
    (_charges: any) => dispatch(upcomingSuccess(_charges)),

    function (error: any) {
      return dispatch(upcomingError());
    }
  );
}

export function getNextBillingCharges(dispatch: any, customerAccountId: number) {
  dispatch(upcomingLoading());
  apiPayment.getNextBillingCharges(customerAccountId).then(
    (_charges: any) => dispatch(upcomingSuccess(_charges)),

    function (error: any) {
      return dispatch(upcomingError());
    }
  );
}
