import * as Yup from "yup";

import { IPasswordSettings } from "../../../types/types";

const HomeSchema = (settings: IPasswordSettings) =>
  Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().validEmail().required("Email is required"),
    referralCode: Yup.string().min(6, "Must be at least 6 characters"),
    passwordRequireUppercase: Yup.boolean(),
    PasswordRequireLowercase: Yup.boolean(),
    PasswordRequireDigit: Yup.boolean(),
    passwordRequireNonAlphanumeric: Yup.boolean(),
    passwordRequiredUniqueChars: Yup.number(),
    password: Yup.string()
      .required("Password is required")
      .when("passwordRequireNonAlphanumeric", {
        is: settings.passwordRequireNonAlphanumeric,
        then: Yup.string().matches(/[^a-zA-Z0-9_]/, "Must have at least one non alphanumeric character"),
      })
      .when("passwordRequireUppercase", {
        is: settings.passwordRequireUppercase,
        then: Yup.string().matches(/(.*[A-Z].*)/, "Must have at least one uppercase character"),
      })
      .when("PasswordRequireLowercase", {
        is: settings.passwordRequireLowercase,
        then: Yup.string().matches(/(.*[a-z].*)/, "Must have at least one lowercase character"),
      })
      .when("PasswordRequireDigit", {
        is: settings.passwordRequireDigit,
        then: Yup.string().matches(/((?=.*\d).{1,40})/, "Must have at least one number"),
      })
      .test(
        "passwordRequiredUniqueChars",
        `Must have at least ${settings.passwordRequiredUniqueChars} unique characters`,
        (password?: string | null) => {
          if (password) {
            var size = new Set(password).size;
            return size >= settings.passwordRequiredUniqueChars;
          }
          return false;
        }
      )
      .min(settings.passwordRequiredLength, `Must have at least ${settings.passwordRequiredLength} characters`),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Password is required"),
  });

export default HomeSchema;
