import { Contact } from "../../../types/types";
import {
  ChildCrnPair,
  CreateCustomerContactHttpRequest,
  UpdateCustomerContactHttpRequest,
  ParentAuthorizationHttpRequest,
} from "../../parentApi";
import {
  childEnrolmentPromptDismissed,
  childEnrolmentPromptNotRequired,
  childEnrolmentPromptRequired,
} from "./childEnrolnmentPrompt/childEnrolmentStateActions";
import {
  contactUpdated,
  contactUpdateError,
  contactUpdating,
  customerError,
  customerLoading,
  customerSuccess,
} from "./customerActionTypes";
import apiCustomers from "./customerApi";

export function getCustomer(dispatch: any, customerAccountId: number) {
  dispatch(customerLoading());
  return apiCustomers.getCustomerAccount(customerAccountId).then(
    (customer: any) => {
      dispatch(customerSuccess(customer));
    },
    () => dispatch(customerError())
  );
}

export function createContact(
  dispatch: any,
  customerAccountId: number,
  contact: Contact,
  documentIdsAdded: string[],
  documentIdsDeleted: string[],
  authorizationSignature: string,
  errorCallback?: () => void
) {
  dispatch(contactUpdating());
  var request = new CreateCustomerContactHttpRequest();
  request.init(contact);

  request.documentIdsAdded = documentIdsAdded;
  request.documentIdsDeleted = documentIdsDeleted;

  request.signature = new ParentAuthorizationHttpRequest({
    authorize: true,
    informationIsTrue: true,
    signatureData: authorizationSignature,
  });

  apiCustomers.createContact(customerAccountId, request).then(
    (response) => {
      if (response) {
        contact.customerContactId = response.customerContactId;
        dispatch(contactUpdated(contact, true));
      }
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
      dispatch(contactUpdateError());
    }
  );
}

export function updateContact(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  contact: Contact,
  documentIdsAdded: string[],
  documentIdsDeleted: string[],
  childCrns: ChildCrnPair[] | undefined,
  childrenIdsToUpdateAddress: number[],
  ccbSubmissionContactId: number | undefined,
  authorizationSignature: string | null,
  errorCallback?: () => void
) {
  dispatch(contactUpdating());
  var request = new UpdateCustomerContactHttpRequest();
  request.init(contact);

  request.childCrns = childCrns;
  request.documentIdsAdded = documentIdsAdded;
  request.documentIdsDeleted = documentIdsDeleted;
  request.childrenIdsToUpdateAddress = childrenIdsToUpdateAddress;
  request.ccbSubmissionContactId = ccbSubmissionContactId;

  if (authorizationSignature) {
    request.signature = new ParentAuthorizationHttpRequest({
      authorize: true,
      informationIsTrue: true,
      signatureData: authorizationSignature,
    });
  }

  apiCustomers.updateContact(customerAccountId, customerContactId, request).then(
    () => {
      dispatch(contactUpdated(contact));
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
      dispatch(contactUpdateError());
    }
  );
}

export function shouldShowChildEnrolmentPrompt(dispatch: any, contacts: Contact[] | undefined) {
  var contactsNeedingUpdate = contacts?.filter(
    (c) =>
      c.authorisation?.canApproveAmbulance === undefined ||
      c.authorisation.canMakeAndChangeBookings === undefined ||
      c.authorisation.canManageAccount === undefined ||
      c.authorisation.canPickUpChild === undefined ||
      c.authorisation.consentForTransportation === undefined ||
      c.authorisation.consentToAdministerMedicine === undefined ||
      c.authorisation.consentToMedicalTreatment === undefined ||
      c.authorisation.consentToTakeOutsidePremises === undefined
  );

  if (contactsNeedingUpdate && contactsNeedingUpdate.length > 0) {
    dispatch(childEnrolmentPromptRequired(contactsNeedingUpdate.map((c) => c.customerContactId!)));
  } else {
    dispatch(childEnrolmentPromptNotRequired());
  }
}

export function dismissChildEnrolmentPrompt(dispatch: any, contactId: number) {
  dispatch(childEnrolmentPromptDismissed(contactId));
}
