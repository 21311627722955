import CloseIcon from "@mui/icons-material/Close";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const AnimatedDialog = (props: DialogProps): JSX.Element => {
  const { classes } = useStyles();
  const { onClose, title, maxWidth="md" } = props;
  return (
    <Dialog maxWidth={maxWidth} keepMounted {...props} fullWidth>
      {(title || onClose) && (
        <DialogTitle className={classes.title}>
          {title && title}
          {onClose && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={(e) => onClose(e, "backdropClick")}>
              <CloseIcon />
            </IconButton>
          ) }
        </DialogTitle>
      )}
      {props.children}
    </Dialog>
  );
};
