import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { RegistrationStepper } from "../../components/Common/Stepper/Stepper";
import FormChildStep5 from "../../components/Forms/Registration/ChildStep5";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import { childSteps } from "../../utils/stepperSteps";
import { isNullOrEmpty } from "../../utils/stringUtils";

interface ParamTypes {
  id: string;
  medicalConditionId: string;
}

function ChildStep5() {
  let { id, medicalConditionId } = useParams();
  const memoizedId = useMemo(() => parseInt(id!, 10), [id]);
  const child = useSelector((state: RootState) => state.register?.children[memoizedId]);
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage stepper={<RegistrationStepper activeStep={3} steps={childSteps(memoizedId)} />}>
        <RegistrationHeader heading={`${child.firstName} ${child.lastName}`} subHeading={`Child ${memoizedId + 1}`} />
        <FormChildStep5
          child={child}
          id={id!}
          medicalConditionIndex={!isNullOrEmpty(medicalConditionId) ? parseInt(medicalConditionId!) : 0}
        />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default ChildStep5;
