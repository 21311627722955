import { createReducer } from "deox";

import { CommonData } from "../../../types/types";
import { commonDataSuccess, commonDataLoading } from "./commonStateActions";

export interface CommonDataState {
  isInitialised: Boolean;
  isLoading: Boolean;
  data: CommonData | null;
}

const initialState: CommonDataState = {
  isInitialised: false,
  isLoading: false,
  data: null,
};

const commonDataReducer = createReducer(initialState, (handleAction) => [
  handleAction(commonDataLoading, (state, { payload }) => ({
    ...state,
    data: payload,
    isInitialised: false,
    isLoading: true,
  })),
  handleAction(commonDataSuccess, (state, { payload }) => ({
    ...state,
    data: payload,
    isInitialised: true,
    isLoading: false,
  })),
]);

export default commonDataReducer;
