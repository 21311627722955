import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { List } from "linqts";
import { floor } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ConfirmOSHCBookingState } from "./ConfirmOSHCBooking";
import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import ToggleSwitch from "../../components/Common/Buttons/ToggleSwitch";
import CalendarStrip from "../../components/Common/Calendars/CalendarStrip";
import { CloudOshc2 } from "../../components/Common/Shapes/Shapes";
import ChildSelection from "../../components/Forms/Bookings/ChildSelection";
import RollDateSelection from "../../components/Forms/Bookings/RollDateSelection";
import RollDaySelection from "../../components/Forms/Bookings/RollDaySelection";
import { CardLayout } from "../../layouts/Layouts";
import {
  LoadCreateCasualBookingsPreviewInitial,
  LoadCreateRecurringPreviewInitial,
  SaveBookingInitial,
} from "../../store/modules/booking/bookingStateActions";
import { getServices, getTermDates } from "../../store/modules/service/servicesActions";
import { getRollsForService, getUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { LoadedUnavailableDatesReset, SelectStartDate } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/store";
import {
  ChildAgeConstraint,
  EndDateOptions,
  SelectedChild,
  SelectedRollDate,
  SelectedRollDay,
} from "../../types/models";
import { ISessionAvailability, Roll, Service, TermDate } from "../../types/types";
import dateOnlyToJson from "../../utils/dateOnlyToJson";
import {
  getBookableRollDates,
  getStyleColor,
  getWeekDays,
  hasMinRequiredBookingsAcrossWeeks,
  isContactAllowedToBook,
} from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import { GetPageState } from "../../utils/pageUtil";

dayjs.extend(dateOnlyToJson);

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: 40,
    "@media screen and (min-width: 720px)": {
      height: 80,
    },
  },
}));

export interface OshcBookingDetails {
  serviceId?: number;
  children: SelectedChild[];
  casualDates: SelectedRollDate[];
  isRecurring: boolean;
  currentDate: dayjs.Dayjs;
  recurringDays: List<SelectedRollDay>;
  recurringStartDate: dayjs.Dayjs | null;
  recurringEndDate: dayjs.Dayjs | null;
  recurringDateType: EndDateOptions;
  minBookings: number;
}

function NewOSHCBooking() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const auth = useSelector((state: RootState) => state.auth);
  const customer_account_id = auth?.user?.profile.customer_account_id;
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const customerChildren = useSelector((state: RootState) => state.child.childrenByCustomer);
  const serviceRolls = useSelector((state: RootState) => state.session.rolls);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);
  const termDates = useSelector((state: RootState) => state.termDates.termDatesByServiceId);
  const isAllowedToBook = isContactAllowedToBook(
    customerAccount!,
    auth.userId!,
    parseInt(auth.user!.profile.customer_contact_id!.toString())
  );

  const navigate = useNavigate();
  var state = GetPageState<ConfirmOSHCBookingState>();
  const initialValues: OshcBookingDetails = {
    serviceId:
      state?.service?.serviceId ??
      (servicesByCustomer && servicesByCustomer.length ? servicesByCustomer[0].serviceId : undefined),
    children: new Array<SelectedChild>(),
    casualDates: state?.casualDates ?? new Array<SelectedRollDate>(),
    isRecurring: state?.isSchedule ?? false,
    currentDate: dayjs(dayjs().format("YYYY-MM-DD")),
    recurringDays: state?.recurringDays ? new List(state?.recurringDays) : new List<SelectedRollDay>(),
    recurringStartDate: state?.recurringStartDate ?? null,
    recurringEndDate: state?.recurringEndDate ?? null,
    recurringDateType: state?.recurringDateType ?? "TermEnd",
    minBookings: state?.service?.firstMinimumBookingsRequired ?? 2,
  };

  const [serviceId, setServiceId] = useState(initialValues.serviceId);

  const [currentValues, setCurrentValues] = useState(initialValues);

  useEffect(() => {
    if (state && !unAvailableDates) {
      loadUnAvailableDates(initialValues);
    }
  }, [unAvailableDates, dispatch]);

  useEffect(() => {
    if (customer_account_id) {
      dispatch(SaveBookingInitial());
      dispatch(LoadCreateRecurringPreviewInitial());

      if (servicesByCustomer === null) {
        getServices(dispatch, customer_account_id);
      }
    }
  }, [customer_account_id, servicesByCustomer]);

  useEffect(() => {
    if (serviceId && serviceId > 0) {
      LoadedUnavailableDatesReset();
      getRollsForService(dispatch, serviceId);
      getTermDates(dispatch, serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    if (serviceRolls && !unAvailableDates) {
      loadUnAvailableDates(currentValues);
    }
  }, [serviceRolls]);

  function loadChildren(values: typeof initialValues) {
    if (values.children.length === 0 && customerChildren && customerChildren.length) {
      customerChildren.forEach((c) => {
        var stChild = state?.children?.find((ch) => ch.child.childId === c.childId);
        values.children.push(
          new SelectedChild({
            child: c,
            isSelected: stChild !== undefined,
            reason: stChild?.reason,
            reasonRequired: stChild?.reasonRequired,
          })
        );
      });
    }
  }

  function loadUnAvailableDates(values: typeof initialValues) {
    if (values.serviceId && values.children.findIndex((x) => x.isSelected) >= 0 && serviceRolls) {
      var monday = dayjs(values.currentDate).startOf("isoWeek");
      var childrenIds = values.children.filter((c) => c.isSelected).map((c) => c.child.childId as number);
      var rollIds = new List(serviceRolls)
        .Where((x) => x?.isOhsc === true)
        .Select((r) => r.rollId)
        .Distinct()
        .ToArray();
      var from = values.isRecurring ? values.recurringStartDate : monday;
      var to = values.isRecurring ? values.recurringEndDate : monday.add(4, "d");
      if (from && to && rollIds.length && (from.isSame(to, "date") || from.isBefore(to))) {
        getUnAvailableSessionDays(
          dispatch,
          customer_account_id as number,
          from as Dayjs,
          to as Dayjs,
          rollIds,
          childrenIds
        );
      }
    }
  }

  function removeUnAvailableRollDates(values: typeof initialValues) {
    if (values.casualDates && values.casualDates.length && unAvailableDates && unAvailableDates.length) {
      unAvailableDates.forEach((ud) => {
        if (!ud.isAvailableForCasualBooking) {
          var index = values.casualDates.findIndex(
            (cd) => ud.rollId === cd.roll.rollId && dayjs(cd.date).isSame(ud.date, "date")
          );
          if (index >= 0) {
            values.casualDates.splice(index, 1);
          }
        }
      });
    }
  }

  function CannotGoNext(
    isSubmitting: boolean,
    values: OshcBookingDetails,
    unAvailableDates: ISessionAvailability[] | null
  ): boolean {
    return (
      isSubmitting ||
      unAvailableDates == null ||
      values.serviceId == null ||
      values.children == null ||
      values.children.findIndex((x) => x.isSelected) < 0 ||
      values.children.findIndex((x) => x.isSelected && x.reasonRequired && !x.hasReason) >= 0 ||
      (!values.isRecurring && values.casualDates.length === 0) ||
      (values.isRecurring &&
        (values.recurringDays.Count(
          (r) =>
            r?.isMonday === true ||
            r?.isTuesday === true ||
            r?.isWednesday === true ||
            r?.isThursday === true ||
            r?.isFriday === true
        ) === 0 ||
          values.recurringStartDate === null ||
          values.recurringEndDate === null ||
          !HasMinimumBookingsAcrossWeeks(values, unAvailableDates)))
    );
  }

  function HasMinimumBookingsAcrossWeeks(
    values: OshcBookingDetails,
    unAvailableDates: ISessionAvailability[]
  ): boolean {
    var hasMinBookings = true;
    for (let d of values.recurringDays.ToArray()) {
      if (
        !hasMinRequiredBookingsAcrossWeeks(
          values.recurringStartDate ?? dayjs(),
          values.recurringEndDate ?? dayjs(),
          d,
          unAvailableDates,
          values.minBookings
        )
      ) {
        hasMinBookings = false;
        break;
      }
    }
    return hasMinBookings;
  }

  function DisplaySelection(
    values: OshcBookingDetails,
    loadUnAvailableDates: { (values: OshcBookingDetails): void; (arg0: OshcBookingDetails): void },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    serviceRolls: Roll[] | null,
    unAvailableDates: ISessionAvailability[],
    termDates: TermDate[]
  ) {
    if (values.serviceId && values.children && values.children.findIndex((x) => x.isSelected) >= 0) {
      return !values.isRecurring
        ? DisplayCasualSelection(values, loadUnAvailableDates, setFieldValue, serviceRolls, unAvailableDates)
        : DisplayRecurringSelection(
            values,
            loadUnAvailableDates,
            setFieldValue,
            serviceRolls,
            unAvailableDates,
            termDates
          );
    }
  }

  function DisplayCasualSelection(
    values: OshcBookingDetails,
    loadUnAvailableDates: { (values: OshcBookingDetails): void; (arg0: OshcBookingDetails): void },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    serviceRolls: Roll[] | null,
    unAvailableDates: ISessionAvailability[]
  ) {
    return (
      <Grid item xs={12}>
        <legend className={classes.formLegend}>Bookable Dates</legend>
        <Grid container spacing={3}>
          <CalendarStrip
            initialDate={values.currentDate}
            onWeekChanged={(dt) => {
              values.currentDate = dt;
              loadUnAvailableDates(values);
              setFieldValue("currentDate", dt);
            }}
          />
          {serviceRolls && serviceRolls.length > 0 && (
            <RollDateSelection
              key="casualDateSelection"
              unAvailableDates={unAvailableDates}
              rolls={
                new List(serviceRolls)
                  .Where((x) => x?.isOhsc === true)
                  .OrderBy((x) => x.startTime)
                  .ThenBy((x) => x.rollName)
                  .ToArray() as Roll[]
              }
              dates={values.casualDates}
              weekDays={getWeekDays(values.currentDate)}
              onSelectedDateChanged={(roll, dt, isChecked) => {
                var rDates = values.casualDates.slice();
                var index = rDates.findIndex((x) => x.date.isSame(dt, "date") && x.roll.rollId === roll.rollId);
                if (isChecked) {
                  if (index < 0) {
                    rDates.push(new SelectedRollDate({ roll: roll, date: dt }));
                  }
                } else {
                  if (index >= 0) {
                    rDates.splice(index, 1);
                  }
                }
                rDates = rDates.filter((d) => d.roll.rollId === roll.rollId || d.roll.sessionType !== roll.sessionType);
                setFieldValue("casualDates", rDates);
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  function setDates(
    values: OshcBookingDetails,
    options: EndDateOptions,
    termEnd: Dayjs | null,
    yearEnd: Dayjs | null,
    fromDate: Dayjs,
    endDate: Dayjs | null
  ) {
    values.recurringStartDate = fromDate;
    values.recurringDateType = options;
    switch (options) {
      case "TermEnd":
        values.recurringEndDate = termEnd;
        break;
      case "YearEnd":
        values.recurringEndDate = yearEnd;
        break;
      default:
        var temp: Dayjs | null = null;
        if (endDate && endDate.isAfter(fromDate)) {
          temp = endDate;
        }
        values.recurringEndDate = temp;
        break;
    }
  }

  function DisplayRecurringSelection(
    values: OshcBookingDetails,
    loadUnAvailableDates: { (values: OshcBookingDetails): void; (arg0: OshcBookingDetails): void },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    serviceRolls: Roll[] | null,
    unAvailableDates: ISessionAvailability[],
    termDates: TermDate[]
  ) {
    let startDate = values.recurringStartDate ?? dayjs(dayjs().format("YYYY-MM-DD"));
    if (serviceRolls && serviceRolls.length && !values.recurringStartDate) {
      values.recurringStartDate = startDate =
        new List(serviceRolls).OrderBy((s) => s.nextAvailableSession).FirstOrDefault()?.nextAvailableSession ?? dayjs();
    }

    let termEnd: Dayjs | null = null;
    let yearEnd: Dayjs | null = null;
    if (termDates && termDates.length) {
      var dates = new List<TermDate>(termDates);
      var quarter = floor(startDate.get("month") / 3 + 1);
      var year = startDate.year();
      termEnd = dates.FirstOrDefault((d) => d?.year === year && d?.termNumber === quarter)?.lastDayOfTerm ?? null;
      yearEnd = dates.FirstOrDefault((d) => d?.year === year && d?.termNumber === 4)?.lastDayOfTerm ?? null;
      if (values.recurringEndDate == null && values.recurringDateType !== "Custom") {
        setDates(values, values.recurringDateType, termEnd, yearEnd, startDate, null);
      }
    }

    return (
      <>
        <legend className={classes.formLegend}>Bookable Days</legend>
        {serviceRolls && serviceRolls.length && (
          <RollDaySelection
            rolls={
              new List(serviceRolls)
                .Where((x) => x?.isOhsc === true && x?.rollClassificationId !== 9)
                .OrderBy((x) => x.startTime)
                .ThenBy((x) => x.rollName)
                .ToArray() as Roll[]
            }
            rollDays={values.recurringDays}
            minNumberOfBookings={values.minBookings}
            endDateSelectionType={values.recurringDateType}
            startDate={startDate}
            endDate={values.recurringEndDate}
            unAvailableDates={unAvailableDates}
            onStartDateChanged={(date) => {
              setDates(values, values.recurringDateType, termEnd, yearEnd, date, values.recurringEndDate);
              dispatch(SelectStartDate(date));
              setFieldValue("recurringStartDate", date);
              loadUnAvailableDates(values);
            }}
            onEndDateChanged={(date) => {
              setDates(values, values.recurringDateType, termEnd, yearEnd, startDate, date);
              setFieldValue("recurringEndDate", date);
              loadUnAvailableDates(values);
            }}
            onEndDateSelectionTypeChanged={(tp) => {
              setDates(values, tp, termEnd, yearEnd, startDate, values.recurringEndDate);
              setFieldValue("recurringDateType", tp);
              loadUnAvailableDates(values);
            }}
            onSelectedDayChanged={(roll, day, isChecked) => {
              var rd = values.recurringDays.FirstOrDefault((r) => r?.roll.rollId === roll.rollId);
              if (!rd) {
                rd = new SelectedRollDay({
                  roll: roll,
                  isWeekly: true,
                  isMonday: false,
                  isTuesday: false,
                  isWednesday: false,
                  isThursday: false,
                  isFriday: false,
                });
                values.recurringDays.Add(rd);
              }
              if (day === "Monday") {
                rd.isMonday = isChecked;
              } else if (day === "Tuesday") {
                rd.isTuesday = isChecked;
              } else if (day === "Wednesday") {
                rd.isWednesday = isChecked;
              } else if (day === "Thursday") {
                rd.isThursday = isChecked;
              } else if (day === "Friday") {
                rd.isFriday = isChecked;
              }
              if (!rd.isAnyDaySeleced) {
                values.recurringDays.Remove(rd);
              }
              var rdays = values.recurringDays.RemoveAll(
                (x) => x?.roll.rollId !== roll.rollId && x?.roll.sessionType === roll.sessionType
              );
              setFieldValue("recurringDays", rdays);
            }}
          />
        )}
      </>
    );
  }

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--youroshc-color-primary")}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h1 className="h3">New Booking</h1>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end">
              <img src={YourOSHCLogo} alt="Your OSHC" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <CloudOshc2 style={{ position: "absolute", top: -80, right: -80, display: width <= 1280 ? "none" : "block" }} />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(LoadedUnavailableDatesReset());
            dispatch(SaveBookingInitial());
            dispatch(LoadCreateCasualBookingsPreviewInitial());
            dispatch(LoadCreateRecurringPreviewInitial());
            navigate("/confirm-oshc", {
              state: JSON.stringify({
                service: servicesByCustomer?.find((s) => s.serviceId === (values.serviceId as number)) as Service,
                children: values.children.filter((c) => c.isSelected),
                casualDates: !values.isRecurring ? values.casualDates : [],
                isSchedule: values.isRecurring,
                recurringDays: values.isRecurring ? values.recurringDays.ToArray() : [],
                recurringStartDate: dayjs(values.recurringStartDate?.format("YYYY-MM-DD")),
                recurringEndDate: values.recurringEndDate,
                recurringDateType: values.recurringDateType,
              }),
            });
            setSubmitting(false);
          }}
        >
          {({ submitForm, handleChange, setFieldValue, isSubmitting, values }) => {
            loadChildren(values);
            removeUnAvailableRollDates(values);
            var dates = !values.isRecurring
              ? values.casualDates
              : getBookableRollDates(
                  values.recurringStartDate,
                  values.recurringEndDate,
                  values.recurringDays?.ToArray() ?? [],
                  unAvailableDates ?? []
                );
            return (
              <Form className={classes.root}>
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <legend className={classes.formLegend}>Select Service</legend>
                      <FormControl required variant="filled" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="service-location">Service Location</InputLabel>
                        <Select
                          native
                          value={values.serviceId?.toString() || ""}
                          onChange={(evt) => {
                            values.serviceId = parseInt(evt.target.value as string);
                            if (values.serviceId) {
                              values.minBookings =
                                servicesByCustomer?.find((s) => s.serviceId === (values.serviceId as number))
                                  ?.firstMinimumBookingsRequired ?? 2;
                            } else {
                              values.minBookings = 2;
                            }
                            setFieldValue("casualDates", []);
                            setFieldValue("recurringDays", new List<SelectedRollDay>());
                            setServiceId(values.serviceId);
                            setCurrentValues(values);
                          }}
                          inputProps={{
                            name: "service",
                            id: "service-location",
                          }}
                          className={classes.textField}
                          fullWidth
                        >
                          {servicesByCustomer &&
                            servicesByCustomer.length &&
                            servicesByCustomer.map((service, index) => {
                              return (
                                <option key={service.serviceId} value={service.serviceId}>
                                  {service.serviceName}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <legend className={classes.formLegend}>Select Children</legend>
                      {values?.children?.map((child, index) => {
                        return (
                          <ChildSelection
                            key={"childSelection_" + child.child.childId}
                            constraints={
                              new List(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })))
                            }
                            child={child}
                            serviceId={serviceId}
                            onSelectionChanged={(isAgeLess, isAgeGreater, checked) => {
                              values.children[index].isSelected = checked;
                              if (isAgeGreater) {
                                values.children[index].reasonRequired =
                                  values.children[index].reason === null ||
                                  values.children[index].reason === undefined ||
                                  (values.children[index].reason?.trim()?.length as number) < 4;
                              }
                              loadUnAvailableDates(values);
                              setFieldValue("children[" + index + "].isSelected", checked);
                            }}
                            onReasonChanged={(reason) => {
                              values.children[index].reason = reason;
                              values.children[index].reasonRequired = reason === null || reason.trim().length < 4;
                              setFieldValue("children[" + index + "].reason", reason);
                            }}
                          ></ChildSelection>
                        );
                      })}
                      <Grid item xs={12}>
                        <Box paddingBottom={4} />
                        <ToggleSwitch
                          checked={values.isRecurring}
                          onChange={(evt) => {
                            values.isRecurring = evt.target.checked;
                            setFieldValue("isRecurring", evt.target.checked);
                            loadUnAvailableDates(values);
                          }}
                          onText="Casual"
                          offText="Recurring"
                        />
                        <Box paddingBottom={4} />
                      </Grid>
                      <Grid item xs={12}>
                        {DisplaySelection(
                          values,
                          loadUnAvailableDates,
                          setFieldValue,
                          serviceRolls,
                          unAvailableDates ?? [],
                          termDates
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <ButtonSecondary
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            <strong>Cancel</strong>
                          </ButtonSecondary>
                        </Grid>
                        <Grid item>
                          <ButtonPrimary
                            style={{ backgroundColor: getStyleColor("--youroshc-color-primary") }}
                            disabled={!isAllowedToBook || CannotGoNext(isSubmitting, values, unAvailableDates)}
                            onClick={submitForm}
                          >
                            <strong>Next</strong>
                          </ButtonPrimary>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default NewOSHCBooking;
