import { useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import SignaturePad from "react-signature-pad-wrapper";

import { ButtonPrimary } from "../Buttons/Buttons";

interface IParentAuthorizationControlProps {
  onSigned: (signature: string) => void;
}

function ParentAuthorizationControl(props: IParentAuthorizationControlProps) {
  const { onSigned } = props;
  const [authorize, setAuthorize] = useState(false);
  const [infoIsTrue, setInfoIsTrue] = useState(false);
  const [pad, setPad] = useState<SignaturePad | null>(null);
  const [hasSignature, setHasSignature] = useState(false);
  return (
    <Grid container p={2} rowSpacing={2}>
      <Grid item xs={12}>
        <strong>Parent Authorization</strong>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          sx={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              checked={authorize}
              onChange={(evt, checked) => {
                setAuthorize(checked);
              }}
            />
          }
          label="I declare as the child's parent/person named in the form as authorised to consent to the medical treatment of the child, authorise for the Approved provider, Nominated Supervisor or an Educator to seek medical treatment for the child from a registered medical practitioner, hospital or ambulance service and transportation of the child by an ambulance and/or obtain all necessary medical assistance and treatment for the child and agree to meet any expenses attached to such treatment."
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          sx={{ alignItems: "flex-start" }}
          control={
            <Checkbox
              checked={infoIsTrue}
              onChange={(evt, checked) => {
                setInfoIsTrue(checked);
              }}
            />
          }
          label="I declare as the person with parental authority of the child referred to on this page that the information provided is true and correct and undertake to immediately inform the children's service in the event of any change to this information."
        />
      </Grid>
      <Grid item xs={12}>
        <strong>Signature</strong>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ border: "1px solid black" }}>
          <SignaturePad
            options={{ backgroundColor: "rgb(255,255,255)" }}
            ref={(ref) => {
              if (ref == null) return;
              ref.instance.addEventListener("endStroke", () => {
                setHasSignature(true);
              });
              setPad(ref);
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-start" alignContent="center">
          <Link
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              pad?.clear();
              setHasSignature(false);
            }}
          >
            <RefreshIcon /> Clear Signature
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <ButtonPrimary
            disabled={!authorize || !infoIsTrue || pad?.isEmpty() === true}
            onClick={() => {
              onSigned(pad!.toDataURL());
            }}
          >
            Confirm
          </ButtonPrimary>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ParentAuthorizationControl;
