import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { List } from "linqts";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { DocumentTypes } from "../../types/types";
import { OtherMedicalConditionQuestionId, PhysicalConditionId } from "../../utils/medicalConditionsHelper";
import MedicalConditionQuestionnaire from "../Common/Controls/MedicalConditionQuestionnaire";

interface Props {
  classes: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  dispatch: any;
}

function PhysicalBehaviouralAndOtherNeeds({
  classes,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  values,
  errors,
}: Props) {
  const medicalPlanTemplate = useSelector((state: RootState) => state?.commonData?.data.medicalPlanTemplate);

  const childDocuments = useSelector((state: RootState) =>
    state.register?.documents.filter(
      (document) =>
        document.registrationChildUniqueId === values.uniqueId &&
        document.documentTypeId === DocumentTypes.ChildMedicalDocument
    )
  );

  var allQuestions = new List(medicalPlanTemplate?.questions ?? []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Physical, Behavioural and Other Needs</legend>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormLabel component="legend" className={classes.formLabel} style={{ fontWeight: "bold" }}>
                Does the child have physical needs?
              </FormLabel>
              <FormControl error={Boolean(touched.hasPhysicalNeeds && errors.hasPhysicalNeeds)} fullWidth>
                <RadioGroup row={true}>
                  <FormControlLabel
                    value="true"
                    label="Yes"
                    control={<Radio />}
                    checked={values.hasPhysicalNeeds === true}
                    onChange={() => setFieldValue("hasPhysicalNeeds", true)}
                  />
                  <FormControlLabel
                    value="false"
                    label="No"
                    control={<Radio />}
                    checked={values.hasPhysicalNeeds === false}
                    onChange={() => setFieldValue("hasPhysicalNeeds", false)}
                  />
                </RadioGroup>
                {touched.hasPhysicalNeeds && errors.hasPhysicalNeeds && (
                  <>
                    <FormHelperText error required>
                      {errors.hasPhysicalNeeds}
                    </FormHelperText>
                  </>
                )}
              </FormControl>
              {values.hasPhysicalNeeds === true ? (
                <>
                  <Box pt={1} />
                  <TextField
                    id="physicalNeeds"
                    label="Physical Needs"
                    type="text"
                    value={values.physicalNeeds}
                    onChange={handleChange("physicalNeeds")}
                    onBlur={handleBlur("physicalNeeds")}
                    helperText={touched.physicalNeeds ? errors.physicalNeeds : ""}
                    error={touched.physicalNeeds && Boolean(errors.physicalNeeds)}
                    fullWidth
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend" className={classes.formLabel} style={{ fontWeight: "bold" }}>
                Does the child have behavioural/educational needs?
              </FormLabel>
              <FormControl error={Boolean(touched.hasBehaviouralNeeds && errors.hasBehaviouralNeeds)} fullWidth>
                <RadioGroup row={true}>
                  <FormControlLabel
                    value="true"
                    label="Yes"
                    control={<Radio />}
                    checked={values.hasBehaviouralNeeds === true}
                    onChange={() => setFieldValue("hasBehaviouralNeeds", true)}
                  />
                  <FormControlLabel
                    value="false"
                    label="No"
                    control={<Radio />}
                    checked={values.hasBehaviouralNeeds === false}
                    onChange={() => setFieldValue("hasBehaviouralNeeds", false)}
                  />
                </RadioGroup>
                {touched.hasBehaviouralNeeds && errors.hasBehaviouralNeeds && (
                  <>
                    <FormHelperText error required>
                      {errors.hasBehaviouralNeeds}
                    </FormHelperText>
                  </>
                )}
              </FormControl>
              {values.hasBehaviouralNeeds === true ? (
                <>
                  <Box pt={1} />
                  <TextField
                    id="behaviouralNeeds"
                    label="Behavioural Needs"
                    type="text"
                    value={values.behaviouralNeeds}
                    onChange={handleChange("behaviouralNeeds")}
                    onBlur={handleBlur("behaviouralNeeds")}
                    helperText={touched.behaviouralNeeds ? errors.behaviouralNeeds : ""}
                    error={touched.behaviouralNeeds && Boolean(errors.behaviouralNeeds)}
                    fullWidth
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend" className={classes.formLabel} style={{ fontWeight: "bold" }}>
                Does the child have other needs?
              </FormLabel>
              <FormControl error={Boolean(touched.hasSpecialNeeds && errors.hasSpecialNeeds)} fullWidth>
                <RadioGroup row={true}>
                  <FormControlLabel
                    value="true"
                    label="Yes"
                    control={<Radio />}
                    checked={values.hasSpecialNeeds === true}
                    onChange={() => setFieldValue("hasSpecialNeeds", true)}
                  />
                  <FormControlLabel
                    value="false"
                    label="No"
                    control={<Radio />}
                    checked={values.hasSpecialNeeds === false}
                    onChange={() => setFieldValue("hasSpecialNeeds", false)}
                  />
                </RadioGroup>
                {touched.hasSpecialNeeds && errors.hasSpecialNeeds && (
                  <>
                    <FormHelperText error required>
                      {errors.hasSpecialNeeds}
                    </FormHelperText>
                  </>
                )}
              </FormControl>
              {values.hasSpecialNeeds === true ? (
                <>
                  <Box pt={1} />
                  <TextField
                    id="specialNeeds"
                    label="Other Needs"
                    type="text"
                    value={values.specialNeeds}
                    onChange={handleChange("specialNeeds")}
                    onBlur={handleBlur("specialNeeds")}
                    helperText={touched.specialNeeds ? errors.specialNeeds : ""}
                    error={touched.specialNeeds && Boolean(errors.specialNeeds)}
                    fullWidth
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend" className={classes.formLabel} style={{ fontWeight: "bold" }}>
                Does the child have dietary requirements?
              </FormLabel>
              <FormControl error={Boolean(touched.hasDietaryRequirements && errors.hasDietaryRequirements)} fullWidth>
                <RadioGroup row={true}>
                  <FormControlLabel
                    value="true"
                    label="Yes"
                    control={<Radio />}
                    checked={values.hasDietaryRequirements === true}
                    onChange={() => setFieldValue("hasDietaryRequirements", true)}
                  />
                  <FormControlLabel
                    value="false"
                    label="No"
                    control={<Radio />}
                    checked={values.hasDietaryRequirements === false}
                    onChange={() => setFieldValue("hasDietaryRequirements", false)}
                  />
                </RadioGroup>
                {touched.hasDietaryRequirements && errors.hasDietaryRequirements && (
                  <FormHelperText error required>
                    {errors.hasDietaryRequirements}
                  </FormHelperText>
                )}
              </FormControl>
              {values.hasDietaryRequirements === true ? (
                <>
                  <Box pt={1} />
                  <TextField
                    id="dietaryRequirements"
                    label="Dietary Requirements"
                    type="text"
                    value={values.dietaryRequirements}
                    onChange={handleChange("dietaryRequirements")}
                    onBlur={handleBlur("dietaryRequirements")}
                    helperText={touched.dietaryRequirements ? errors.dietaryRequirements : ""}
                    error={touched.dietaryRequirements && Boolean(errors.dietaryRequirements)}
                    fullWidth
                    className={classes.textField}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={6}>
              <MedicalConditionQuestionnaire
                allQuestions={allQuestions.Where((x) => x?.visible ?? true)}
                childAnswers={
                  new List(
                    values.medicalPlanAnswers?.filter((ans: any) => ans.conditionId === PhysicalConditionId) ?? []
                  )
                }
                childUniqueId={values.uniqueId}
                documents={childDocuments}
                medicalConditionId={PhysicalConditionId}
                onAnswersChanged={(answers) => {
                  var childAnswers =
                    values.medicalPlanAnswers?.filter(
                      (ans: any) =>
                        ans.conditionId !== PhysicalConditionId || ans.questionId === OtherMedicalConditionQuestionId
                    ) ?? [];
                  childAnswers.push(...answers.ToArray());
                  setFieldValue("medicalPlanAnswers", childAnswers);
                }}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default PhysicalBehaviouralAndOtherNeeds;
