import React, { useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { acceptTermsAndConsitions } from "../../../store/modules/termsAndConditions/termsAndConditionsActions";
import { TermsAndConditionsStatus } from "../../../store/modules/termsAndConditions/termsAndConditionsStateActions";
import { RootState } from "../../../store/store";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";
import { AnimatedDialog } from "../../Common/Dialogs/AnimatedDialog";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  dialogTitle: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: "1rem",
    paddingBottom: 0,
  },
  dialogContent: {
    maxHeight: 400,
  },
}));

function FormTermsAndConditions() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);

  const termsStatus = useSelector((state: RootState) => state.termsAndConsitions).status;
  const termsAndConditions = useSelector((state: RootState) => state.termsAndConsitions).payload;
  const [localStatus, setLocalStatus] = React.useState<TermsAndConditionsStatus>("Initial");
  const [localError, setLocalError] = React.useState("");

  const [showTerms, setShowTerms] = React.useState(false);

  useEffect(() => {
    setLocalStatus(termsStatus);
    if (termsStatus === "Required") {
      setShowTerms(true);
    } else if (termsStatus === "Accepted") {
      setShowTerms(false);
    } else if (termsStatus === "Errored") {
      setLocalError("An error has been encountered.");
    }
  }, [termsStatus]);

  return (
    <AnimatedDialog open={showTerms} style={{ marginTop: 95 }}>
      <DialogTitle className={classes.dialogTitle}>Updated terms &amp; conditions</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p>Our terms and conditions have been updated. Please read and acknowledge the below before proceeding.</p>
        <Box paddingBottom={1} />
        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              if (customer_account_id) {
                acceptTermsAndConsitions(dispatch, customer_account_id);
              }
            }, 500);
          }}
        >
          {() => (
            <Form className={classes.root} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    dangerouslySetInnerHTML={{ __html: termsAndConditions || "" }}
                    className="dangerouslySetInnerHTML"
                  ></div>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container justifyContent="flex-end">
                    {localStatus === "Errored" && <Alert severity="error">{localError}</Alert>}
                    <ButtonPrimary type="submit" disabled={localStatus === "Saving"}>
                      <strong>Yes, I Agree</strong>
                    </ButtonPrimary>
                  </Grid>
                  <Backdrop className={classes.backdrop} open={localStatus === "Saving"}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </AnimatedDialog>
  );
}

export default FormTermsAndConditions;
