import api from "./passwordSettingsApi";
import {
  loadPasswordSettingsError,
  loadPasswordSettingsPending,
  loadPasswordSettingsSuccess,
} from "./passwordSettingsStateActions";
export type PasswordSettingsStatus = "Initial" | "Pending" | "Success" | "Errored";
export function getPasswordSettings(dispatch: any) {
  dispatch(loadPasswordSettingsPending());
  api.getPasswordRules().then(
    (settings: any) => {
      dispatch(loadPasswordSettingsSuccess(settings));
    },
    function (error: any) {
      return dispatch(loadPasswordSettingsError());
    }
  );
}
