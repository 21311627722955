import React from "react";

import { Link } from "react-router-dom";

import { IStep } from "../../../utils/stepperSteps";
import styles from "./Stepper.module.scss";

interface RegistrationProps {
  activeStep?: number;
  steps?: IStep[];
}

export const RegistrationStepper = (props: RegistrationProps) => {
  const { activeStep, steps } = props;

  return (
    <div className={styles["Stepper"]}>
      {steps &&
        steps.length > 0 &&
        steps.map((step: any, index: number) => (
          <Link key={index} to={step.path}>
            <div className={styles["Stepper-Step"]}>
              <div className={`${styles["Stepper-Step__Fab"]} ${activeStep === index + 1 && styles["Active"]}`}>
                {index + 1}
              </div>
              <div className={styles["Stepper-Step__Label"]}>{step.label}</div>
            </div>
          </Link>
        ))}
    </div>
  );
};
