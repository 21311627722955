import React, { useEffect, useMemo, useState } from "react";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar/Avatar";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import CardActionArea from "@mui/material/CardActionArea/CardActionArea";
import CardContent from "@mui/material/CardContent/CardContent";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import Stack from "@mui/material/Stack/Stack";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { List } from "linqts";
import { IoFlagOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import BookingChildSelection from "./BookingChildSelection";
import ChildBookingReason from "./ChildBookingReason";
import { useAppDispatch } from "../../store/hooks";
import { getCreateCasualBookingsPreview } from "../../store/modules/booking/bookingActions";
import {
  LoadCreateCasualBookingsPreviewInitial,
  SaveBookingInitial,
} from "../../store/modules/booking/bookingStateActions";
import { getChildren } from "../../store/modules/child/childActions";
import {
  getProgramDateImage,
  getServicesByLocation,
  getServicesEventsSummary,
  getServiceUpCommingPrograms,
} from "../../store/modules/program/programActions";
import {
  serviceLocationsReset,
  serviceProgramBeginCheckout,
  serviceProgramSelect,
  serviceProgramView,
  serviceProgramsReset,
  serviceProgramsView,
} from "../../store/modules/program/programStateActions";
import { getUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { LoadedUnavailableDatesReset } from "../../store/modules/session/sessionStateAction";
import { RootState } from "../../store/store";
import theme from "../../theme";
import {
  BookingDateInfo,
  Child,
  ChildAgeConstraint,
  EntityType,
  RollDetails,
  SelectedChild,
  ServiceProgram,
} from "../../types/models";
import {
  IGetCreateCasualBookingsPreviewRequest,
  ISelectedChild,
  IService,
  ISessionAvailability,
  Service,
  VacBookingInfo,
} from "../../types/types";
import {
  getAvailabilityReasonsText,
  getChildDisplayName,
  getStyleColor,
  isContactAllowedToBook,
} from "../../utils/helpers";
import { isChildBlockedForAllServices } from "../../utils/medicalConditionsHelper";
import { ButtonPrimary, ButtonSecondary } from "../Common/Buttons/Buttons";
import Spacer from "../Common/Spacers/Spacers";
import TabPanel from "../Common/Tab/TabPanel";

interface IProps {
  formState: VacBookingInfo;
  onAddBooking: (program: ServiceProgram) => void;
  onRemoveBooking: (program: ServiceProgram) => void;
  onServiceSelected: (service: IService | Service) => void;
  onUnSelectService: () => void;
  onChildSelectionChanged: (child: Child, isAlreadySelected: boolean, ageIsGreater: boolean) => void;
  onChildReasonChanged(childId: number, reason: string): void;
  onChildrenRefresh(children: ISelectedChild[]): void;
  pageState: {
    hasConflict: boolean;
    hasError: boolean;
    unAvailableDates: ISessionAvailability[];
    newlyAddedUnAvailableDates: ISessionAvailability[];
  };
  setPageState: React.Dispatch<
    React.SetStateAction<{
      hasConflict: boolean;
      hasError: boolean;
      unAvailableDates: ISessionAvailability[];
      newlyAddedUnAvailableDates: ISessionAvailability[];
    }>
  >;
}

function a11yProps(index: number) {
  return {
    id: `event-tab-${index}`,
    "aria-controls": `event-tabpanel-${index}`,
  };
}
const useVacStyles = makeStyles()((theme) => {
  return {
    tab: {
      minWidth: "250px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "150px",
      },
      padding: "1rem",
      borderRadius: "0.5rem",
    },
    rocketeer: {
      backgroundColor: "white",
      border: "2px solid var(--custom-color-rocketeer)",
      "&.Mui-selected": {
        backgroundColor: "var(--custom-color-rocketeer)!important",
      },
    },
    rocketeerDisabled: {
      backgroundColor: "gray",
      opacity: 0.5,

      "&.Mui-selected": {
        backgroundColor: "var(--custom-color-rocketeer)!important",
      },
    },

    logo: {
      height: 40,
      "@media screen and (min-width: 720px)": {
        height: 40,
      },
    },
    logoRocketeer: {
      height: 50,
    },
    icon: {
      width: "3rem",
      height: "3rem",
    },
    childName: {
      fontWeight: "bold",
      textDecoration: "underline",
    },

    button: {
      pointerEvents: "auto",
    },
    selected: {
      border: "2px solid var(--custom-color-rocketeer)",
      backgroundColor: "#daf3ff",
    },
    selectedVac: {
      border: "2px solid var(--custom-color-rocketeer)",
      backgroundColor: "#daf3ff",
    },

    unselected: {
      border: "1px solid #00000029",
    },
    different: {
      "& .MuiCardActionArea-root": {
        opacity: 0.5,
      },
      border: "1px solid #00000029",
    },
    dayAvailable: {
      backgroundColor: "white",
      border: "1px solid black",
      color: "black",
    },

    daySelected: {
      backgroundColor: "var(--youroshc-color-primary)",
      border: "1px solid black",
      color: "black",
    },

    dayDisabled: {
      backgroundColor: "#DDDDDD",
      border: "1px solid white",
      color: "white",
    },
    accordion: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      border: "1px solid var(--youroshc-color-primary)",
      borderRadius: "10px",
      marginBottom: theme.spacing(1),
      "&::before": {
        display: "none",
      },
    },

    accordionVac: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      borderRadius: "10px",
      margin: "0px 0px 10px 0px !important",
      "&::before": {
        display: "none",
      },
    },

    accordionError: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),

      borderRadius: "10px",
      marginBottom: theme.spacing(1),

      border: "1px solid #b42931",
      borderLeftWidth: 5,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    section: {
      border: "1px solid var(--youroshc-color-primary)",
      borderRadius: "10px",
    },
    sectionVac: {
      border: "1px solid var(--custom-color-rocketeer)",
      borderRadius: "10px",
    },

    stack: {
      marginLeft: "5px!important",
      "& .MuiStack-root": {
        marginLeft: "5px!important",
      },
    },
    accordionSummary: {
      alignItems: "flex-start",
      borderRadius: "10px",
      backgroundColor: "white",
    },
    stepYourOSHC: {
      color: "var(--youroshc-color-primary)",
      width: "40px",
      height: "40px",
    },

    stepDisabled: {
      color: "grey",
      width: "40px",
      height: "40px",
    },
    stepVac: {
      color: "var(--custom-color-rocketeer)",
      width: "40px",
      height: "40px",
    },

    locButton: {
      height: 55,
    },
  };
});

export default function VacBookingEditor(props: IProps) {
  const {
    formState,
    onAddBooking,
    onRemoveBooking,
    onServiceSelected,
    onChildSelectionChanged,
    onChildReasonChanged,
    onChildrenRefresh,
    onUnSelectService,
    pageState,
    setPageState,
  } = props;
  const { classes } = useVacStyles();

  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = React.useState(0);

  const [currentStep, setcurrentStep] = useState<number | undefined>(0);
  const register = useSelector((state: RootState) => state.register);
  const commonData = useSelector((state: RootState) => state.commonData.data);

  const auth = useSelector((state: RootState) => state.auth);
  const profile = auth.user?.profile;
  const { servicesByLocation: programServices, isLoading: isServicesLoading } = useSelector(
    (state: RootState) => state.programServices
  );
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);

  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);

  const { serviceProgramSummary, isLoading: isSummaryLoading } = useSelector(
    (state: RootState) => state.serviceProgramSummary
  );

  const [selectedProgram, setSelectedProgram] = useState<{
    program: ServiceProgram | undefined;
    ageIsGreater: boolean;
    ageIsLess: boolean;
  }>({
    program: undefined,
    ageIsGreater: false,
    ageIsLess: false,
  });
  const isAllowedToBook = isContactAllowedToBook(
    customerAccount!,
    auth.userId!,
    parseInt(profile!.customer_contact_id!.toString())
  );
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);

  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  const { servicePrograms, isLoading: isEventsLoading } = useSelector((state: RootState) => state.servicePrograms);
  const [filteredServicePrograms, setFilteredServicePrograms] = useState<ServiceProgram[]>(servicePrograms ?? []);

  const handleChange = (step: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setcurrentStep(isExpanded ? step : undefined);
  };

  const [searchText, setSearchText] = useState<{ query: string; trigger: boolean }>({ query: "", trigger: false });
  const [searchEvents, setSearchEvents] = useState("");
  const [loadMoreService, setLoadMoreService] = useState(false);
  const onSearchTextChanged = () => {
    if (isServicesLoading) return;
    dispatch(serviceLocationsReset());
    if (searchText.query.length > 2) {
      getServicesByLocation({
        dispatch,
        customerAccountId: profile?.customer_account_id!,
        address: searchText.query,
        take: 4,
        skip: 0,
      });
      setLoadMoreService(false);
    }
  };

  const loadMoreServices = () => {
    getServicesByLocation({
      dispatch,
      customerAccountId: profile?.customer_account_id!,
      address: searchText.query + ", Australia",
      take: 4,
      skip: 4,
    });
    setLoadMoreService(true);
  };

  useEffect(() => {
    //trigger search after 1 second when user stops typing
    const search = setTimeout(() => {
      onSearchTextChanged();
    }, 1000);
    return () => clearTimeout(search);
  }, [searchText.query]);
  useEffect(() => {
    if (searchText.trigger) {
      onSearchTextChanged();
      setSearchText({ ...searchText, trigger: false });
    }
  }, [searchText.trigger]);

  useEffect(() => {
    if (selectedProgram.program) {
      dispatch(serviceProgramSelect(selectedProgram.program, "select_item"));
      dispatch(serviceProgramView(selectedProgram.program));
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (currentStep == 3) {
      dispatch(serviceProgramsView(servicePrograms!))
    }
  }, [currentStep])

  function clearSearchText() {
    dispatch(serviceLocationsReset());
    setSearchText({ query: "", trigger: false });
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function getMatchingUnAvailableDates(unAvailableDates: ISessionAvailability[] | null) {
    if (!unAvailableDates) {
      return new Array<ISessionAvailability>();
    }
    const casualDates = new List(servicePrograms?.map((d) => d) ?? []);
    return new List(unAvailableDates)
      .Where(
        (ud) =>
          ud !== undefined &&
          !ud.isAvailableForCasualBooking &&
          casualDates.Any((cd) => cd !== undefined && cd.rollId === ud.rollId && cd.date.isSame(ud.date, "date"))
      )
      .ToArray();
  }

  useQuery(
    ["getServicesEventsSummary", profile?.customer_account_id],
    () => getServicesEventsSummary(dispatch, profile!.customer_account_id!),
    {
      refetchOnWindowFocus: false,
      enabled: profile?.customer_account_id !== undefined,
    }
  );

  useEffect(() => {
    loadUnAvailableDates();
  }, [formState.children.length]);

  function loadUnAvailableDates() {
    if (profile?.customer_account_id) {
      if (formState.children.length === 0) return;
      var from = dayjs();
      var to = from.add(4, "month");
      getUnAvailableSessionDays(
        dispatch,
        profile!.customer_account_id as number,
        from as dayjs.Dayjs,
        to as dayjs.Dayjs,
        servicePrograms?.map((x) => x.rollId) ?? [],
        formState.children.map((c) => c.child.childId as number)
      );
    }
  }
  useEffect(() => {
    if (unAvailableDates) {
      var unvDates = getMatchingUnAvailableDates(unAvailableDates);
      setPageState({
        ...pageState,
        unAvailableDates: unvDates,
      });
      var avDates = formState.dates
        .map((x) => new BookingDateInfo(x.program.rollId, x.program.date))
        .filter((x) => unvDates.findIndex((y) => y.rollId === x.rollId && y.date.isSame(x.date, "date")) < 0);
      if (avDates.length > 0) {
        getCreateCasualBookingsPreview(
          dispatch,
          profile!.customer_account_id! as number,
          {
            childrenIds: formState.children.map((c) => c.child.childId as number),
            dates: avDates,
          } as IGetCreateCasualBookingsPreviewRequest
        );
      } else {
        var newUnAvailableDates = unvDates.filter(
          (x) =>
            formState.dates.findIndex((y) => y.program.rollId === x.rollId && y.program.date.isSame(x.date, "date")) >=
            0
        );
        setPageState({
          hasConflict: true,
          unAvailableDates: unvDates,
          newlyAddedUnAvailableDates: newUnAvailableDates,
          hasError: false,
        });
      }
    }
  }, [unAvailableDates]);

  function handleUseMyLocation() {
    if (customerAccount) {
      var cont = customerAccount.contacts?.find((c) => c.isPrimary);
      if (cont) {
        var addr = cont.residentialAddress;
        setSearchText({
          query: addr?.streetLine1 + ", " + addr?.suburb + ", " + addr?.state + " " + addr?.postCode,
          trigger: false,
        });
      }
    }
  }

  function handleServiceSelected(svc: IService | Service) {
    dispatch(serviceProgramsReset());
    setFilteredServicePrograms(new Array<ServiceProgram>());
    setTabIndex(0);
    if (formState.service?.serviceId === svc.serviceId) {
      onUnSelectService();
    } else {
      onServiceSelected(svc);
      //move to next section
      setcurrentStep(1);
    }
  }

  useEffect(() => {
    if (profile?.customer_account_id) {
      dispatch(SaveBookingInitial());
      dispatch(LoadedUnavailableDatesReset());
      dispatch(LoadCreateCasualBookingsPreviewInitial());
    }
  }, [profile?.customer_account_id, formState.service]);

  useQuery(["getChildren", profile?.customer_account_id], () => getChildren(dispatch, profile!.customer_account_id!), {
    refetchOnWindowFocus: false,
    enabled: profile?.customer_account_id !== undefined && children !== null,
  });

  useQuery(
    ["getServiceUpCommingPrograms", formState.service?.serviceId],
    () => getServiceUpCommingPrograms(dispatch, profile!.customer_account_id!, formState.service!.serviceId),
    {
      refetchOnWindowFocus: false,
      enabled: profile?.customer_account_id !== undefined && formState.service !== undefined,
    }
  );

  function getFilteredServicePrograms() {
    var activityType = commonData.programActivityTypes?.find((x) => x.programActivityTypeId === tabIndex);
    if (activityType) {
      return (
        servicePrograms?.filter((x) => x.session.programActivityTypeId === activityType?.programActivityTypeId) ?? []
      );
    }
    return servicePrograms ?? [];
  }

  var availableActivityTypes =
    commonData.programActivityTypes?.filter(
      (x) => (servicePrograms ?? []).findIndex((y) => y.session.programActivityTypeId === x.programActivityTypeId) >= 0
    ) ?? [];

  useEffect(() => {
    setFilteredServicePrograms(servicePrograms ?? []);
    setTabIndex(0);
  }, [servicePrograms]);

  useEffect(() => {
    const events = getFilteredServicePrograms();
    setFilteredServicePrograms(
      events.filter(
        (x) => searchEvents.trim() === "" || x.programName.toLowerCase().includes(searchEvents.toLowerCase())
      )
    );
  }, [searchEvents, tabIndex]);

  function isAgeGreaterThanMax(constraints: ChildAgeConstraint[], child: SelectedChild) {
    return constraints.filter((ct) => ct !== undefined && ct.isAgeGreaterThanMax(child));
  }
  function hasMaxAgeViolation(): boolean {
    const constraints = getConstraints();
    if (constraints.length === 0 || formState.children.length === 0) return false;
    return formState.children.some(
      (c) =>
        isAgeGreaterThanMax(constraints, new SelectedChild(c)).length > 0 &&
        (c.reason === undefined || c.reason === null || c.reason.trim() === "")
    );
  }
  function refreshMaxAgeViolation() {
    const constraints = getConstraints();

    const children = formState.children.map((c) => {
      const violations = isAgeGreaterThanMax(constraints, new SelectedChild(c));
      c.reasonRequired = violations.length > 0;
      return c;
    });

    onChildrenRefresh(children);
  }

  useEffect(() => {
    loadUnAvailableDates();
    refreshMaxAgeViolation();
  }, [formState.dates]);
  function StepHeader(title: string, index: number, isComplete: boolean) {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {!isComplete && (
          <Avatar sx={{ width: 30, height: 30, bgcolor: "white", color: "black", border: "2px solid black" }}>
            {index + 1}
          </Avatar>
        )}
        {isComplete && <CheckCircleIcon className={classes.stepVac} />}{" "}
        <Grid container direction="column" justifyItems={"flex-start"}>
          <Typography variant="h4" fontWeight={"bold"}>
            {title}
          </Typography>

          {currentStep !== 1 &&
            index === 1 &&
            formState.dates.length > 0 &&
            formState.dates.map((d, index) => {
              return (
                <Grid container direction={"row"} columnGap={1} margin={1} ml={0} key={index}>
                  <Typography variant="subtitle2">{d.program.programName}</Typography>

                  <Typography variant="subtitle2">
                    <strong>
                      <NumericFormat
                        displayType="text"
                        value={
                          isFriendlyFee() || !d.program.isCasualFee
                            ? d.program.session.primaryFeeAmount
                            : d.program.session.casualFeeAmount
                        }
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="$"
                        suffix=""
                      />
                    </strong>
                  </Typography>
                </Grid>
              );
            })}

          {currentStep !== 0 && index === 0 && formState.service && (
            <Stack direction="row" spacing={2}>
              <LocationOnOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2"> {formState.service.serviceName}</Typography>
            </Stack>
          )}
        </Grid>
      </Stack>
    );
  }

  function isFriendlyFee() {
    return customerAccount?.isFriendlyFeeEligible === true;
  }

  function getConstraints() {
    return formState.dates.map(
      (d) =>
        new ChildAgeConstraint({
          rollDate: d.program.date,
          session: new RollDetails(d.program.session),
          activityCode: d.program.activityCode,
        })
    );
  }

  function getConstraintsByProgram(program: ServiceProgram) {
    return [
      new ChildAgeConstraint({
        rollDate: program.date,
        session: new RollDetails(program.session),
        activityCode: program.activityCode,
      }),
    ];
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Backdrop className={classes.backdrop} open={isServicesLoading || isEventsLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Accordion
        sx={{ border: "2px solid var(--custom-color-rocketeer)" }}
        className={classes.accordionVac}
        elevation={1}
        expanded={currentStep === 0}
        onChange={handleChange(0)}
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Grid container direction={"column"}>
            {StepHeader("Service Location", 0, formState.service !== undefined)}

            <Spacer margin={5} />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} direction={"row"} justifyContent="space-between" alignItems={"center"}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    sx={{
                      "& fieldset": {
                        borderRadius: "10px",
                        backgroundColor: "#c7c5c5",
                        opacity: 0.2,
                      },
                    }}
                    value={searchText.query}
                    placeholder="School name, suburb or postcode"
                    variant="outlined"
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchText({
                        query: event.target.value,
                        trigger: false,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      endAdornment:
                        searchText.query.length > 0 ? (
                          <InputAdornment position="end">
                            <IconButton onClick={() => clearSearchText()}>
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ButtonSecondary
                    variant="outlined"
                    fullWidth
                    className={classes.locButton}
                    onClick={handleUseMyLocation}
                    startIcon={<MyLocationIcon htmlColor="black" />}
                  >
                    Use my location
                  </ButtonSecondary>
                </Grid>

                {searchText.query.trim().length === 0 && (
                  <Grid item xs={12}>
                    <Grid container direction="column">
                      <Typography variant="h6" fontWeight={"bold"}>
                        My schools
                      </Typography>
                      <Grid container direction="row" spacing={2}>
                        {servicesByCustomer?.map((s, index) => {
                          var summary = serviceProgramSummary?.find((p) => p.serviceId === s.serviceId);
                          return (
                            <Grid item xs={12} md={6} key={index}>
                              {isSummaryLoading && <Skeleton variant="rectangular" height={130} />}
                              {!isSummaryLoading && (
                                <Card
                                  key={index}
                                  elevation={1}
                                  className={
                                    formState.service?.serviceId === s.serviceId
                                      ? classes.selectedVac
                                      : !summary || summary.programsCount === 0
                                      ? classes.different
                                      : classes.unselected
                                  }
                                >
                                  <CardActionArea
                                    sx={{ height: 130 }}
                                    onClick={
                                      summary && summary.programsCount > 0 ? () => handleServiceSelected(s) : undefined
                                    }
                                  >
                                    <Grid container direction={"row"} alignItems="center" p={1}>
                                      <Grid item xs={10}>
                                        <Grid container direction="column" padding={1} rowGap={1}>
                                          <Typography
                                            variant="h6"
                                            fontSize={{
                                              lg: "20px",
                                              md: "16px",
                                              xs: "15px",
                                            }}
                                            className={classes.childName}
                                          >
                                            {s.serviceName}
                                          </Typography>
                                          {summary && (
                                            <Stack direction="row" spacing={2}>
                                              <EventAvailableIcon fontSize="small" htmlColor="#c5c5c5" />
                                              <NumericFormat
                                                value={summary.distanceKm}
                                                prefix={`${summary.programsCount} events available | `}
                                                displayType={"text"}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                suffix=" KM"
                                              />
                                            </Stack>
                                          )}
                                          <Stack direction="row" spacing={2}>
                                            <LocationOnOutlinedIcon fontSize="small" htmlColor="#c5c5c5" />
                                            <Typography variant="body2">
                                              {s.primaryAddressSuburb} {s.primaryAddressState}{" "}
                                              {s.primaryAddressPostcode}
                                            </Typography>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </CardActionArea>
                                </Card>
                              )}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {searchText.query.trim().length > 0 && (
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                      {programServices?.map((p, index) => (
                        <Grid item xs={6} key={index}>
                          <Card
                            key={index}
                            elevation={1}
                            className={
                              formState.service?.serviceId === p.service.serviceId
                                ? classes.selectedVac
                                : classes.unselected
                            }
                          >
                            <CardActionArea onClick={() => handleServiceSelected(p.service)}>
                              <Grid container direction={"row"} alignItems="center" p={1}>
                                <Grid item xs={10}>
                                  <Grid container direction="column" padding={1} rowGap={1}>
                                    <Typography variant="h6" className={classes.childName}>
                                      {p.service.serviceName}
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                      <EventAvailableIcon fontSize="small" htmlColor="#c5c5c5" />
                                      <NumericFormat
                                        value={p.distanceKM}
                                        prefix={`${p.programsCount.toString()} events available | `}
                                        displayType={"text"}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        suffix=" KM"
                                      />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <LocationOnOutlinedIcon fontSize="small" htmlColor="#c5c5c5" />
                                      <Typography variant="body2">
                                        {p.service.primaryAddressSuburb} {p.service.primaryAddressState}{" "}
                                        {p.service.primaryAddressPostcode}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>

                    {loadMoreService !== true && (programServices?.length ?? 0) > 3 && (
                      <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="center" pt={5}>
                          <ButtonPrimary
                            sx={{
                              backgroundColor: "#85D6FF",
                              color: "black",
                              float: "right",
                              width: "200px",
                              fontWeight: "bold",
                            }}
                            className={classes.button}
                            variant="outlined"
                            onClick={loadMoreServices}
                          >
                            Load more
                          </ButtonPrimary>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonPrimary
                className={classes.button}
                disabled={formState.service === undefined}
                onClick={() => {
                  setcurrentStep(1);
                }}
                sx={{
                  backgroundColor: "#85D6FF",
                  color: "black",
                  float: "right",
                  width: "200px",
                  fontWeight: "bold",
                }}
              >
                Continue
              </ButtonPrimary>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordionVac}
        elevation={1}
        expanded={currentStep === 1}
        sx={{ border: "2px solid var(--custom-color-rocketeer)" }}
        onChange={formState.service === undefined ? undefined : handleChange(1)}
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Grid container direction={"column"}>
            {StepHeader("Child/ren", 1, formState.children.length > 0)}

            <Spacer margin={5} />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>
          <Grid container direction={"row"} spacing={2}>
            {children?.map((child) => {
              const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
              const selected = formState.children.find((x) => x.child.childId === child.childId);
              const isSuspended = child.isSuspended ?? false;
              const isBlocked = isChildBlockedForAllServices(child, formState.service?.serviceId, register);

              return (
                <Grid item xs={12} sm={6} key={child.childId}>
                  <BookingChildSelection
                    overlayBackground="var(--custom-color-rocketeer)"
                    isDifferentService={false}
                    isBlocked={isBlocked}
                    serviceId={formState.service?.serviceId}
                    avatar={avatar}
                    child={child}
                    classes={classes}
                    constraints={getConstraints()}
                    selected={selected}
                    onChildSelectionChanged={(ageIsGreater) => {
                      if (isSuspended) return;

                      onChildSelectionChanged(child, selected ? true : false, ageIsGreater);
                    }}
                  />
                  {isBlocked && (
                    <span style={{ color: "Red" }}>
                      Bookings are currently unavailable, but we'll be in touch with you within the next 2 days to
                      discuss the best options for your child.
                    </span>
                  )}
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <ButtonPrimary
                disabled={formState.children.length === 0}
                className={classes.button}
                onClick={() => {
                  setcurrentStep(2);
                }}
                sx={{
                  backgroundColor: getStyleColor("--custom-color-rocketeer"),
                  color: "black",
                  float: "right",
                  width: "200px",
                  fontWeight: "bold",
                }}
              >
                Continue
              </ButtonPrimary>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordionVac}
        sx={{ border: "2px solid var(--custom-color-rocketeer)" }}
        elevation={1}
        expanded={currentStep === 2}
        onChange={formState.children.length === 0 ? () => {} : handleChange(2)}
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Grid container direction={"column"}>
            {StepHeader("Learning Events", 2, formState.dates.length > 0)}

            <Spacer margin={5} />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>
          <Grid container direction={"column"}>
            {!selectedProgram.program && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  sx={{
                    "& fieldset": {
                      borderRadius: "10px",
                      backgroundColor: "#c7c5c5",
                      opacity: 0.2,
                    },
                  }}
                  value={searchEvents}
                  placeholder="Learning event"
                  variant="outlined"
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchEvents(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),

                    endAdornment:
                      searchText.query.length > 0 ? (
                        <InputAdornment position="end">
                          <IconButton onClick={clearSearchText}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {!selectedProgram.program && (
                <Box sx={{ borderBottom: 1, borderColor: "divider", maxWidth: { xs: 250, sm: "100%" } }}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="Event Type"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab label="All" {...a11yProps(0)} />
                    {availableActivityTypes.map((at) => (
                      <Tab
                        key={`AvitityTypeTab_${at.programActivityTypeId}`}
                        label={at.activityTypeName}
                        {...a11yProps(at.programActivityTypeId)}
                        value={at.programActivityTypeId}
                      />
                    ))}
                  </Tabs>
                </Box>
              )}
              <TabPanel value={tabIndex} index={tabIndex}>
                <Grid container spacing={2} padding={2}>
                  {!selectedProgram.program &&
                    filteredServicePrograms?.map(function (p, index) {
                      var constraints = getConstraintsByProgram(p);
                      const ageIsLess = isAgeLessThanMin(new List(constraints));

                      function isAgeGreaterThanMax(constraints: ChildAgeConstraint[]) {
                        return constraints.filter(
                          (ct) =>
                            ct !== undefined &&
                            formState.children.some((child) => ct.isAgeGreaterThanMax(new SelectedChild(child)))
                        );
                      }
                      const ageIsGreater = isAgeGreaterThanMax(constraints).length > 0;
                      return (
                        <Grid item xs={12} sm={6} md={3} lg={3} key={`Program_${p.programDateId}`}>
                          <ServiceEventSelection
                            isAnyChildAgeGreaterThanMax={ageIsGreater}
                            isAnyChildAgeLessThanMin={ageIsLess}
                            unAvailableDates={pageState.unAvailableDates ?? []}
                            isFriendlyFee={isFriendlyFee()}
                            program={p}
                            selected={formState.dates.some((x) => x.program.programDateId === p.programDateId)}
                            selectedClassName={classes.selectedVac}
                            onSelect={() => {
                              setSelectedProgram({
                                program: p,
                                ageIsGreater: ageIsGreater,
                                ageIsLess: ageIsLess,
                              });
                            }}
                          />
                        </Grid>
                      );
                    })}

                  {!selectedProgram.program && filteredServicePrograms?.length === 0 && (
                    <Grid item xs={12}>
                      <Typography>No events found.</Typography>
                    </Grid>
                  )}
                  {selectedProgram.program && (
                    <Grid item xs={12}>
                      <ServiceEventDetail
                        isAnyChildAgeGreaterThanMax={selectedProgram.ageIsGreater}
                        isAnyChildAgeLessThanMin={selectedProgram.ageIsLess}
                        isFriendlyFee={isFriendlyFee()}
                        isAllowedToBook={isAllowedToBook}
                        program={selectedProgram.program}
                        unAvailableDates={pageState.unAvailableDates ?? []}
                        onDismiss={() =>
                          setSelectedProgram({ program: undefined, ageIsGreater: false, ageIsLess: false })
                        }
                        isBooked={formState.dates.some(
                          (x) => x.program.programDateId === selectedProgram.program!.programDateId
                        )}
                        onBook={() => {
                          onAddBooking(selectedProgram.program!);
                          dispatch(serviceProgramSelect(selectedProgram.program!, "add_to_cart"));
                          dispatch(serviceProgramSelect(selectedProgram.program!, "begin_checkout"));

                          setSelectedProgram({ program: undefined, ageIsGreater: false, ageIsLess: false });
                        }}
                        onRemove={() => {
                          dispatch(serviceProgramSelect(selectedProgram.program!, "remove_from_cart"));
                          onRemoveBooking(selectedProgram.program!);
                          setSelectedProgram({ program: undefined, ageIsGreater: false, ageIsLess: false });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </Grid>

            {!selectedProgram.program && formState.children.some((x) => x.reasonRequired) && (
              <Grid item xs={12}>
                <Grid container direction={"row"} spacing={1} p={2} rowGap={1}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                      <strong> Need more information</strong>
                    </Typography>
                  </Grid>

                  {formState.children
                    .filter((x) => x.reasonRequired)
                    .map((child, index) => {
                      const constraints = getConstraints();
                      function isAgeGreaterThanMax(constraints: ChildAgeConstraint[]) {
                        return constraints.filter(
                          (ct) => ct !== undefined && ct.isAgeGreaterThanMax(new SelectedChild(child!))
                        );
                      }
                      const ageIsGreater = isAgeGreaterThanMax(constraints);
                      const chidlName = getChildDisplayName(child.child);

                      return (
                        <Grid item xs={12} sm={12} key={index}>
                          <ChildBookingReason
                            chidlName={chidlName}
                            initialReason={child.reason ?? ""}
                            rollName={ageIsGreater[0]?.session.rollName ?? ""}
                            selected={classes.selected}
                            onChange={(reason) => {
                              onChildReasonChanged(child.child.childId!, reason);
                            }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
            {!selectedProgram.program && (
              <Grid item xs={12}>
                <ButtonPrimary
                  disabled={formState.dates.length === 0 || hasMaxAgeViolation()}
                  className={classes.button}
                  onClick={() => {
                    if (formState.dates.length === 0) return;
                    setcurrentStep(3);
                    dispatch(serviceProgramBeginCheckout(formState.dates.map((f) => f.program)));
                  }}
                  sx={{
                    backgroundColor: getStyleColor("--custom-color-rocketeer"),
                    color: "black",
                    float: "right",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Continue
                </ButtonPrimary>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  function isAgeLessThanMin(constraints: List<ChildAgeConstraint>) {
    return constraints.Any(
      (ct) => ct !== undefined && formState.children.some((child) => ct.isAgeLessThanMin(new SelectedChild(child)))
    );
  }
}

interface IServiceProgramSelectionProps {
  program: ServiceProgram;
  isFriendlyFee: boolean;
  onSelect: () => void;
  unAvailableDates: ISessionAvailability[];
  selected: boolean;
  selectedClassName: string;
  isAnyChildAgeLessThanMin: boolean;
  isAnyChildAgeGreaterThanMax: boolean;
}
function ServiceEventSelection(props: IServiceProgramSelectionProps): JSX.Element {
  const {
    program,
    isFriendlyFee,
    onSelect,
    unAvailableDates,
    selected,
    selectedClassName,
    isAnyChildAgeGreaterThanMax,
    isAnyChildAgeLessThanMin,
  } = props;

  const [imageUrl, setImageUrl] = useState<{ isDownloading: boolean; url: string }>({
    isDownloading: false,
    url: "",
  });

  const unvDate = useMemo(
    () => unAvailableDates.find((d) => d.date.isSame(program.date, "date") && !d.isAvailableForCasualBooking),
    [unAvailableDates]
  );

  useEffect(() => {
    if (imageUrl.url === null || imageUrl.url.length === 0) {
      setImageUrl({ ...imageUrl, isDownloading: true });
      getProgramDateImage(program.programDateId)
        .then((data: Blob | undefined) => {
          if (data) {
            setImageUrl({
              isDownloading: false,
              url: URL.createObjectURL(data),
            });
          } else {
            setImageUrl({
              isDownloading: false,
              url: "",
            });
          }
        })
        .catch((error) => {
          setImageUrl({
            isDownloading: false,
            url: "",
          });
        });
    }
  }, [program.programDateId]);
  return (
    <Card sx={{ height: "100%", border: "1px solid #707070" }} className={selected ? selectedClassName : ""}>
      <CardActionArea sx={{ height: "100%" }} onClick={onSelect}>
        <Box sx={{ position: "relative" }}>
          {!imageUrl.isDownloading && (
            <CardMedia
              component={"img"}
              sx={{ width: "100%", objectFit: "cover" }}
              src={imageUrl.url}
              title="event image"
            ></CardMedia>
          )}
          {imageUrl.isDownloading && <Skeleton variant="rectangular" width="100%" height={200} />}
          {(unvDate || isAnyChildAgeGreaterThanMax || isAnyChildAgeLessThanMin) && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                opacity: 1,
                right: 0,
                bgcolor: "var(--custom-color-rocketeer)",
                padding: "5px",
                borderTopRightRadius: "10px",
              }}
            >
              <Typography color={"black"} fontSize={"12px"}>
                {unvDate && getAvailabilityReasonsText(unvDate.reasonForCasualBooking)}
                {!unvDate && isAnyChildAgeLessThanMin && "Age is less than min"}

                {!unvDate && !isAnyChildAgeLessThanMin && isAnyChildAgeGreaterThanMax && "Age is greater than max"}
              </Typography>
            </Box>
          )}
        </Box>
        <CardContent sx={{ height: "100%" }}>
          <Grid container direction="column" padding={1}>
            <Typography variant="h6" fontWeight={"bold"} fontSize={"15px"}>
              {program.programName}
            </Typography>
            <Typography variant="subtitle2" className="threeLines" gutterBottom fontSize={"14px"}>
              {program.programDescription}
            </Typography>
            <Typography pt={1} variant="subtitle2" fontSize={"12px"}>
              {program.date.format("DD MMM YYYY")}
            </Typography>

            <Typography fontSize={"20px"}>
              <strong>
                <NumericFormat
                  displayType="text"
                  fixedDecimalScale={true}
                  value={
                    isFriendlyFee || !program.isCasualFee
                      ? program.session.primaryFeeAmount
                      : program.session.casualFeeAmount
                  }
                  decimalScale={2}
                  prefix="$"
                  suffix="*"
                />
              </strong>
            </Typography>

            <Typography fontSize={"10px"} variant="caption">
              *Prices exclude available Child Care Subsidy (CCS)
            </Typography>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface IServiceEventDetailProps {
  program: ServiceProgram;
  isFriendlyFee: boolean;
  unAvailableDates: ISessionAvailability[];
  onDismiss?: () => void;
  onBook: () => void;
  onRemove: () => void;
  isAllowedToBook: boolean;
  isBooked: boolean;
  isAnyChildAgeLessThanMin: boolean;
  isAnyChildAgeGreaterThanMax: boolean;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginBottom: theme.spacing(1),
  },
  button: {
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  pill: {
    maxWidth: 60,
    "@media screen and (min-width: 1280px)": {
      maxWidth: 200,
    },
  },
  image: {
    width: 60,
    height: 45,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    display: "block",
    marginBottom: theme.spacing(1),
    borderRadius: "10px",

    "@media screen and (min-width: 1280px)": {
      width: 200,
      height: 150,
    },
  },
  accordion: {
    backgroundColor: "white",

    borderRadius: "10px",
    marginBottom: theme.spacing(1),

    border: "1px solid var(--custom-color-rocketeer)",
    borderLeftWidth: 5,
  },
  accordionSummary: {
    alignItems: "flex-start",
    borderRadius: "10px",
    backgroundColor: getStyleColor("--custom-color-light-grey"),
  },
}));
function ServiceEventDetail(props: IServiceEventDetailProps) {
  const {
    program,
    isFriendlyFee,
    unAvailableDates,
    onDismiss,
    onBook,
    isAllowedToBook,
    isBooked,
    onRemove,
    isAnyChildAgeGreaterThanMax,
    isAnyChildAgeLessThanMin,
  } = props;
  const { classes } = useStyles();

  const [imageUrl, setImageUrl] = useState<{ isDownloading: boolean; url: string }>({
    isDownloading: false,
    url: "",
  });

  const unvDate = useMemo(
    () => unAvailableDates.find((d) => d.date.isSame(program.date, "date") && !d.isAvailableForCasualBooking),
    [unAvailableDates]
  );

  useEffect(() => {
    if (imageUrl.url === "") {
      setImageUrl({ ...imageUrl, isDownloading: true });
      getProgramDateImage(program.programDateId)
        .then((data: Blob | undefined) => {
          if (data) {
            setImageUrl({
              isDownloading: false,
              url: URL.createObjectURL(data),
            });
          } else {
            setImageUrl({
              isDownloading: false,
              url: "",
            });
          }
        })
        .catch((error) => {
          setImageUrl({
            isDownloading: false,
            url: "",
          });
        });
    }
  }, [program.programDateId]);

  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemAvatar style={{ paddingRight: theme.spacing(2) }}>
        <div
          aria-label={program.programName}
          role="img"
          className={classes.image}
          style={
            imageUrl.url.length > 0
              ? { backgroundImage: `url(${imageUrl.url})` }
              : { backgroundColor: getStyleColor("--custom-color-dark-grey") }
          }
        ></div>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography fontSize={"16px"} fontWeight="bold">
                {program.programName}
              </Typography>

              <Typography variant="subtitle2" gutterBottom fontSize={"14px"}>
                {program.programDescription}
              </Typography>
              <Grid container direction={"row"} rowGap={1} pt={1}>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={2}>
                    <CalendarTodayIcon fontSize="small" />
                    <Typography variant="subtitle2" fontSize={"16px"}>
                      {program?.date?.format("MMMM DD, YYYY")} &nbsp;| &nbsp;
                      {dayjs(program.session.startTime).format("hh:mm a") +
                        " to " +
                        dayjs(program.session.endTime).format("hh:mm a")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={2}>
                    <LocationOnOutlinedIcon fontSize="small" />
                    <Typography variant="subtitle2" fontSize={"16px"}>
                      {program.serviceName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} pb={1}>
                  <Stack direction={"row"} spacing={2}>
                    <AttachMoneyIcon fontSize="small" />
                    <Grid container direction="column">
                      <Typography variant="subtitle2" fontSize={"16px"} fontWeight="bold">
                        <NumericFormat
                          displayType="text"
                          value={
                            isFriendlyFee || !program.isCasualFee
                              ? program.session.primaryFeeAmount
                              : program.session.casualFeeAmount
                          }
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="$"
                          suffix="*"
                        />
                      </Typography>
                      <Typography fontSize={"10px"} variant="caption">
                        *Prices exclude available Child Care Subsidy (CCS)
                      </Typography>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              {program?.programPrivateDescription && (
                <Accordion className={classes.accordion} elevation={1}>
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      <AddCircleRoundedIcon
                        sx={{ pt: "10px" }}
                        alignmentBaseline="central"
                        htmlColor="var(--custom-color-rocketeer)"
                      />
                    }
                  >
                    <Typography variant="body1" fontWeight={"bold"}>
                      Highlights
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ bgcolor: "background.paper" }}>
                    <div
                      className="dangerouslySetInnerHTML"
                      dangerouslySetInnerHTML={{
                        __html: program.programPrivateDescription,
                      }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              )}

              <Box marginTop={1} />

              {unvDate && (
                <Grid container alignItems="flex-start">
                  <Grid item>
                    <IoFlagOutline color={theme.palette.error.main} style={{ marginTop: 4, marginRight: 10 }} />
                  </Grid>
                  <Grid item xs>
                    <span style={{ color: theme.palette.error.main }}>
                      {getAvailabilityReasonsText(unvDate.reasonForCasualBooking)}
                    </span>
                  </Grid>
                </Grid>
              )}

              {!unvDate && isAnyChildAgeLessThanMin && (
                <Grid container alignItems="flex-start">
                  <Grid item>
                    <IoFlagOutline color={theme.palette.error.main} style={{ marginTop: 4, marginRight: 10 }} />
                  </Grid>
                  <Grid item xs>
                    <span style={{ color: theme.palette.error.main }}>Age is less than min</span>
                  </Grid>
                </Grid>
              )}

              {!unvDate && !isAnyChildAgeLessThanMin && isAnyChildAgeGreaterThanMax && (
                <Grid container alignItems="flex-start">
                  <Grid item>
                    <IoFlagOutline color={theme.palette.error.main} style={{ marginTop: 4, marginRight: 10 }} />
                  </Grid>
                  <Grid item xs>
                    <span style={{ color: theme.palette.error.main }}>Age is greater than max</span>
                  </Grid>
                </Grid>
              )}
              <Grid container direction="row" justifyContent="space-between">
                <ButtonPrimary
                  style={{
                    backgroundColor: getStyleColor("--custom-color-rocketeer"),
                    color: "black",
                    fontWeight: "bold",
                  }}
                  disabled={!isBooked && (unvDate !== undefined || !isAllowedToBook || isAnyChildAgeLessThanMin)}
                  onClick={isBooked ? onRemove : onBook}
                >
                  {isBooked && "Remove Booking"}
                  {!isBooked && "Add to booking"}
                </ButtonPrimary>

                <Button onClick={onDismiss} variant="text">
                  Back
                </Button>
              </Grid>
            </Grid>
            {/* <Grid item>
              <Checkbox
                disabled={unvDate !== undefined}
                checked={props.program.isSelected}
                icon={
                  unvDate !== undefined ? (
                    <IoCloseCircleOutline size={32} />
                  ) : (
                    <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                  )
                }
                checkedIcon={<IoCheckmarkCircle size={32} />}
                onChange={(_ev, chk) => (props.onSelectedDateChanged ? props.onSelectedDateChanged(program, chk) : {})}
              />
            </Grid> */}
          </Grid>
        }
      />
    </ListItem>
  );
}
