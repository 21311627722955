import { Dayjs } from "dayjs";
import { IProgramVersionSummaryModel } from "../../../types/types";
import apiService from "./digitalProgramApi";
import { digitalProgramsByServicesError, digitalProgramsByServicesLoading, digitalProgramsByServicesSuccess } from "./digitalProgramStateAction";


export function getActiveProgramByServices(dispatch: any, customerAccountId: number, serviceIds: number[], from: Dayjs, to: Dayjs) {
  dispatch(digitalProgramsByServicesLoading());
  apiService.getActiveProgramByServices(customerAccountId, serviceIds, from, to).then(
    (progarms: IProgramVersionSummaryModel[] | null) =>
      dispatch(digitalProgramsByServicesSuccess(progarms ?? new Array<IProgramVersionSummaryModel>())),
    (error: any) => dispatch(digitalProgramsByServicesError())
  );
}

export function getProgramPreview(customerAccountId: number, serviceId: number, programVersionId: number, careTypeGroupId: number) {
  return apiService.getProgramPreview(customerAccountId, serviceId, programVersionId, careTypeGroupId);
}

export function downloadProgramPreview(customerAccountId: number, serviceId: number, programVersionId: number) {
  return apiService.downloadProgram(customerAccountId, serviceId, programVersionId);
}
