import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { signoutRedirectCallback } from "../../store/modules/auth/authServices";

function SignOutOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback().then((response) => navigate("/"));
    }
    signoutAsync();
  }, [history]);

  return null;
}

export default SignOutOidc;
