interface ICenterProps {
  children?: React.ReactNode;
}
export default function Center(props: ICenterProps) {
  const { children } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        //height: "100vh",
      }}
    >
      {children}
    </div>
  );
}
