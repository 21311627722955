import { UserManager, WebStorageStateStore } from "oidc-client";

declare global {
  interface Window {
    __config: any;
  }
}

const config = {
  authority: window.__config.AUTH_URI,
  client_id: (window as any).__config.AUTH_CLIENT_ID,
  redirect_uri: (window as any).__config.AUTH_REDIRECT_URI,
  silent_redirect_uri: (window as any).__config.AUTH_SILENT_REDIRECT,
  post_logout_redirect_uri: (window as any).__config.AUTH_LOGOUT_REDIRECT,
  response_type: "id_token token",
  scope: "parent_gateway openid profile",
  accessTokenExpiringNotificationTime: 4,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(config);

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback();
}

export function signinSilent() {
  userManager.stopSilentRenew();
  return userManager
    .signinSilent()
    .then((response) => {
      console.log(`[oidc] successful silent signin ${response.profile.sub}`);
    })
    .catch((err) => {
      console.log(`[oidc] error with silent signin ${err}`);
    })
    .finally(() => {
      userManager.startSilentRenew();
    });
}

export function clearUserState() {
  userManager.clearStaleState();
  userManager.removeUser();
}

export default userManager;
