import { createReducer } from "deox";

import { IPasswordSettings } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { PasswordSettingsStatus } from "./passwordSettingsActions";
import {
  loadPasswordSettingsError,
  loadPasswordSettingsPending,
  loadPasswordSettingsSuccess,
} from "./passwordSettingsStateActions";

export interface PasswordSettingsState {
  status: PasswordSettingsStatus;
  payload: IPasswordSettings | null;
}

export const initialState: PasswordSettingsState = {
  status: "Initial",
  payload: null,
};

const passwordSettingsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    loadPasswordSettingsSuccess,
    (state, action): PasswordSettingsState => ({
      ...state,
      status: "Success",
      payload: action.payload,
    })
  ),

  handleAction(
    loadPasswordSettingsPending,
    (state, action): PasswordSettingsState => ({
      ...state,
      status: "Pending",
      payload: null,
    })
  ),

  handleAction(
    loadPasswordSettingsError,
    (state, action): PasswordSettingsState => ({
      ...state,
      status: "Errored",
      payload: null,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default passwordSettingsReducer;
