import apiCustomer from "./../customer/customerApi";
import apiChild from "./childApi";
import {
  acknowledgedAdditionalNeeds,
  childrenError,
  childrenLoading,
  childrenSuccess,
  childUpdated,
  childUpdateError,
  childUpdating,
} from "./childStateActions";
import { Child } from "../../../types/models";
import {
  AcknowledgeSpecialNeedsHttpRequest,
  CreateChildHttpRequest,
  ParentAuthorizationHttpRequest,
  UpdateChildHttpRequest,
} from "../../parentApi";

export const getChildren = (dispatch: any, customerAccountId: number) => {
  //return function (dispatch: any) {
  dispatch(childrenLoading());
  apiChild.getChildrenByCustomerAccountId(customerAccountId).then(
    (children: any) => {
      dispatch(childrenSuccess(children));
    },
    () => dispatch(childrenError())
  );
  //};
};

export function acknowledgeAdditionalNeeds(dispatch: any, customerAccountId: number, errorCallback?: () => void) {
  var request = new AcknowledgeSpecialNeedsHttpRequest();
  request.acknowledgeSpecialNeeds = true;

  apiCustomer.acknowledgeAdditionalNeeds(customerAccountId, request).then(
    (response: any) => {
      dispatch(acknowledgedAdditionalNeeds());
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
      dispatch(childUpdateError());
    }
  );
}

export function createChild(
  dispatch: any,
  customerAccountId: number,
  child: Child,
  documentIdsAdded: string[],
  documentIdsDeleted: string[],
  authorizationSignature: string,
  errorCallback?: () => void
) {
  dispatch(childUpdating(child));
  var request = new CreateChildHttpRequest();
  request.init(child);

  request.documentIdsAdded = documentIdsAdded;
  request.documentIdsDeleted = documentIdsDeleted;
  request.authorization = new ParentAuthorizationHttpRequest({
    authorize: true,
    informationIsTrue: true,
    signatureData: authorizationSignature,
  });

  apiChild.createChild(customerAccountId, request).then(
    (response: any) => {
      if (response) {
        dispatch(childUpdated(response, true, documentIdsAdded));
      }
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
      dispatch(childUpdateError());
    }
  );
}

export function acknowledgeBookingOnHoldMessage(dispatch: any, children: Child[], customerAccountId: number) {
  children!.forEach((c) => {
    updateChild(
      dispatch,
      customerAccountId!,
      c.childId!,
      { ...c, acknowledgedStatus: true } as Child,
      [],
      [],
      null,
      () => {
        console.log(`error acknowledging for child ID ${c.childId}`);
      }
    );
  });
}

export function updateChild(
  dispatch: any,
  customerAccountId: number,
  childId: number,
  child: Child,
  documentIdsAdded: string[],
  documentIdsDeleted: string[],
  authorizationSignature: string | null,
  errorCallback?: () => void
) {
  dispatch(childUpdating(child));
  var request = new UpdateChildHttpRequest();
  request.init(child);

  request.documentIdsAdded = documentIdsAdded;
  request.documentIdsDeleted = documentIdsDeleted;
  if (authorizationSignature) {
    request.authorization = new ParentAuthorizationHttpRequest({
      authorize: true,
      informationIsTrue: true,
      signatureData: authorizationSignature,
    });
  }

  apiChild.updateChild(customerAccountId, childId, request).then(
    (response: any) => {
      dispatch(childUpdated(response));
    },
    () => {
      if (errorCallback) {
        errorCallback();
      }
      dispatch(childUpdateError());
    }
  );
}
