import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CardLayout } from "../../layouts/Layouts";
import { newUserSuccess } from "../../store/modules/newUser/newUserStateActions";
import { RootState } from "../../store/reducers";
import { getStyleColor } from "../../utils/helpers";

function RegistrationComplete() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const customerAccountId = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);

  useEffect(() => {
    if (customerAccountId) {
      dispatch(newUserSuccess());
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [customerAccountId]);

  return (
    <CardLayout>
      <CardLayout.Body
        style={{
          backgroundColor: getStyleColor("--color-primary"),
          minHeight: 300,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container alignItems="center" direction="column">
          <h1 className="h3">Registration Complete</h1>
          <IconButton>
            <IoCheckmarkCircle fontSize={90} color="black" />
          </IconButton>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default RegistrationComplete;
