import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { List } from "linqts";
import { IoAlertOutline, IoCheckmarkOutline, IoChevronDownOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { RootState } from "../../store/reducers";
import theme from "../../theme";
import { Child, EntityType } from "../../types/models";
import { BookingPreview, BookingStatus, FeeType, ISessionAvailability, Roll, Service } from "../../types/types";
import { getAvailabilityReasonsText, getStyleColor } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface Props {
  child: Child;
  rolls: List<Roll>;
  previews: List<BookingPreview>;
  datesUnAvailable: List<ISessionAvailability>;
  service: Service;
  isSchedule: boolean;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  icon: {
    verticalAlign: "top",
    height: 20,
    width: 20,
  },
  accordion: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    border: 0,
    borderRadius: 3,
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    "&::before": {
      display: "none",
    },
  },
  accordionSummary: {
    alignItems: "flex-start",
  },
}));

const ChildOSHCBookingCard = (props: Props) => {
  const { classes } = useStyles();
  const availableBookings = props.previews.Where(
    (p) =>
      !props.datesUnAvailable.Any(
        (du) =>
          du?.rollId === p?.rollId &&
          ((props.isSchedule && du?.isAvailableForRecurringBooking === false) ||
            (!props.isSchedule && du?.isAvailableForCasualBooking === false)) &&
          du?.date.isSame(p?.date as dayjs.Dayjs, "date")
      )
  );

  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Child && x.entityId === props.child.childId?.toString()
    )
  );

  const bookingsByMonth = props.previews
    .OrderBy((d) => d.date)
    .GroupBy((d) => (d?.date?.format ? d.date.format("MMM YYYY") : d.childId));
  return (
    <Box pb={2} className={classes.root}>
      <Accordion
        elevation={0}
        TransitionProps={{
          timeout: 0,
        }}
        defaultExpanded
        className={classes.accordion}
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<IoChevronDownOutline />}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <span style={{ marginRight: 10 }}>
                  <AvatarDisplay info={childAvatar ?? { index: props.child.avatarIndex }} />
                </span>
                <strong>
                  {props.child.firstName} {props.child.lastName}
                </strong>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span className="color-dark-grey">{props.service.serviceName.toUpperCase()}</span>
            </Grid>
            <Grid item xs={12}>
              {DisplayRolls(props, availableBookings)}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {DisplayBookings(props, bookingsByMonth)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

function DisplayBookings(props: Props, bookingsByMonth: { [key: string]: BookingPreview[] }): JSX.Element[] {
  const initialValue = new Array<JSX.Element>();

  let data = Object.keys(bookingsByMonth).reduce((accumulator: any, currentValue: any, index) => {
    const monthBookings = bookingsByMonth[currentValue];
    const bookingsByDate = new List(monthBookings).GroupBy((mb) =>
      mb.date.format ? mb.date.format("ddd, D") : mb.childId
    );
    accumulator.push(
      <Grid item xs={12} key={index}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <strong>{currentValue}</strong>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {DisplayBookingsByDate(props, bookingsByDate)}
        </Grid>
      </Grid>
    );
    return accumulator;
  }, initialValue);

  return data;
}

function DisplayBookingsByDate(props: Props, bookingsByDate: { [key: string]: BookingPreview[] }): JSX.Element[] {
  const initialValue = new Array<JSX.Element>();

  let data = Object.keys(bookingsByDate).reduce((accumulator: any, currentValue: any, index) => {
    const dateBookings = new List(bookingsByDate[currentValue]);
    accumulator.push(
      <Grid item xs={12} key={index}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <span style={{ lineHeight: "24px" }}>{currentValue}</span>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" alignContent="center" justifyContent="flex-end">
              {dateBookings
                .DistinctBy((d) => d.rollId)
                .Select((c, index) => {
                  return <React.Fragment key={index}>{DisplayCareTypes(props, c)}</React.Fragment>;
                })
                .ToArray()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    return accumulator;
  }, initialValue);

  return data;
}

function DisplayCareTypes(props: Props, bk: BookingPreview): JSX.Element {
  const roll = props.rolls.First((r) => r?.rollId === bk.rollId);

  switch (bk.status) {
    case BookingStatus.New:
      const unvDate = props.datesUnAvailable.FirstOrDefault(
        (dt) =>
          dt !== undefined &&
          bk.rollId === dt.rollId &&
          dt.date.isSame(bk.date, "date") &&
          !dt.isAvailableForRecurringBooking
      );

      return !unvDate ? (
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end" style={{ color: theme.palette.success.main }}>
            <IoCheckmarkOutline fontSize={24} />
            {roll.sessionType} {props.isSchedule && bk.feeType === FeeType.Casual ? " (Casual)" : ""}
          </Grid>
        </Grid>
      ) : (
        <Tooltip
          arrow
          title={getAvailabilityReasonsText(
            props.isSchedule ? unvDate.reasonForRecurringBooking : unvDate.reasonForCasualBooking
          )}
          placement="top"
        >
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end" style={{ color: theme.palette.error.main }}>
              <IoAlertOutline fontSize={24} />
              {roll.sessionType}
            </Grid>
          </Grid>
        </Tooltip>
      );

    case BookingStatus.Existing:
      return (
        <div style={{ color: "gray" }}>
          <IoAlertOutline fontSize={24} />
          Existing booking
        </div>
      );
  }
  return <div>{roll.sessionType}</div>;
}

function DisplayRolls(props: Props, availableBookings: List<BookingPreview>) {
  return props.rolls
    .Select((r, index): JSX.Element => {
      const bookingsByFee = availableBookings
        .Where((ab) => ab?.rollId === r.rollId && ab.status === BookingStatus.New.toLowerCase())
        .GroupBy((ab) => ab.feeType as FeeType);

      return <React.Fragment key={index}>{DisplayRollFee(r, bookingsByFee, props.isSchedule)}</React.Fragment>;
    })
    .ToArray();
}

function DisplayRollFee(r: Roll, bookingsByFee: { [x: string]: BookingPreview[] }, isSchedule: boolean) {
  const initialValue = new Array<JSX.Element>();

  let data = Object.keys(bookingsByFee).reduce((accumulator: any, currentValue: any, index) => {
    const allAvBookings = bookingsByFee[currentValue];
    const firstItem = allAvBookings[0];
    accumulator.push(
      <Grid container key={"ChildAccordFee_" + index}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <strong>
              {r.rollName} {isSchedule && firstItem.feeType === FeeType.Casual ? " (Casual)" : ""}
            </strong>
          </Grid>
          <Grid item>
            <NumericFormat
              value={firstItem.fee}
              displayType={"text"}
              thousandSeparator={false}
              prefix={`${allAvBookings.length} x $`}
              suffix={"*"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Grid>
        </Grid>
      </Grid>
    );
    return accumulator;
  }, initialValue);

  return data;
}

export default ChildOSHCBookingCard;
