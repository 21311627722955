import React from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getIn } from "formik";

import { ChildSituationsSelector } from "../Common/Controls/ChildSituationsSelector";

interface Props {
  classes?: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  dispatch: any;
}

function PriorityAccess({ classes, handleBlur, setFieldValue, touched, values, errors }: Props) {
  return (
    <FormGroup className={classes.formGroup}>
      <Grid container className={classes.root} spacing={6}>
        <Grid item xs={12} md={6}>
          <legend className={classes.formLegend}>
            Please indicate which priority category best describes the child's situation
          </legend>
          <FormControl error={Boolean(touched.priorityAccessId && errors.priorityAccessId)} fullWidth>
            <RadioGroup row={true}>
              <FormControlLabel
                className={classes.formControlLabel}
                value="1"
                label="Priority 1: a child at risk of serious abuse or neglect"
                control={<Radio className={classes.radio} />}
                checked={values.priorityAccessId === 1}
                onChange={() => setFieldValue("priorityAccessId", 1)}
              />
              <FormControlLabel
                className={classes.formControlLabel}
                value="2"
                label="Priority 2: a child of a single parent or parents who satisfy the work/training/study test under Section 14 of the 'A New Tax System (Family Assistance) Act 1999'"
                control={<Radio className={classes.radio} style={{ alignSelf: "flex-start" }} />}
                checked={values.priorityAccessId === 2}
                onChange={() => setFieldValue("priorityAccessId", 2)}
              />
              <FormControlLabel
                className={classes.formControlLabel}
                value="3"
                label="Priority 3: any other child"
                control={<Radio className={classes.radio} />}
                checked={values.priorityAccessId === 3}
                onChange={() => setFieldValue("priorityAccessId", 3)}
              />
            </RadioGroup>
            {touched && errors && (
              <FormHelperText error required>
                {errors.priorityAccessId}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <legend className={classes.formLegend}>
            Please select all that apply from the below categories that best describe the child's situation
          </legend>
          <ChildSituationsSelector
            classes={classes}
            id="childSituations"
            label="Child Situations"
            handleBlur={handleBlur}
            helperText={getIn(touched, "childSituations") ? getIn(errors, "childSituations") : ""}
            error={getIn(touched, "childSituations") && Boolean(getIn(errors, "childSituations"))}
            childSituations={values.childSituations}
            onChange={(e) => {
              const { checked, value } = e.target;
              var childSituationId = parseInt(value);
              let selectedChildSituations = values.childSituations;
              if (checked) {
                if (childSituationId === 7 || selectedChildSituations.includes(7)) {
                  selectedChildSituations = [7];
                } else {
                  selectedChildSituations.push(childSituationId);
                }
              } else {
                selectedChildSituations = selectedChildSituations.filter(
                  (x: number) => x.toString() !== childSituationId.toString()
                );
              }
              setFieldValue("childSituations", selectedChildSituations);
            }}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
}

export default PriorityAccess;
