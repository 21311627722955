import { configureStore } from "@reduxjs/toolkit";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { ServiceProgram } from "../types/models";
import { BookingPreview, ViewItemListItem } from "../types/types";
import { parseJSON } from "../utils/jsonUtil";
import { BookingInfo } from "../types/models";
import { gtmMiddleware } from "../utils/gtmMiddleware";

const dateTransform = createTransform(null, (outBoundState, k) => {
  var val = JSON.stringify(outBoundState);
  return parseJSON(val);
});

const persistConfig = {
  key: "root",
  storage: storageSession,
  transforms: [dateTransform],
};

const middlewares = [thunk, gtmMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  devTools: process.env.NODE_ENV === "development",
  reducer: persistedReducer,
  middleware: middlewares,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
