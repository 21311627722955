import { createActionCreator } from "deox";

export const resetPasswordPending = createActionCreator("resetPasswordPending");
export const resetPasswordSuccess = createActionCreator("resetPasswordSuccess");

export const resetPasswordError = createActionCreator(
  "resetPasswordError",
  (resolve) => (error: string) => resolve(error)
);
export const resetPasswordInit = createActionCreator("resetPasswordInit");
