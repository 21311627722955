import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { IoCheckmarkCircle, IoCloseCircleOutline, IoEllipseOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

import theme from "../../../theme";
import { SelectedRollDate } from "../../../types/models";
import { ISessionAvailability, Roll } from "../../../types/types";
import { getAvailabilityReasonsText } from "../../../utils/helpers";
import { useViewport } from "../../../utils/hooks";

interface Props {
  rolls: Roll[];
  unAvailableDates: ISessionAvailability[];
  dates: SelectedRollDate[];
  weekDays: dayjs.Dayjs[];
  onSelectedDateChanged?: (roll: Roll, date: dayjs.Dayjs, isChecked: boolean) => void;
}

const RollDateSelection = (props: Props) => {
  const getDay = (roll: any, day: number) => {
    return props.dates?.find((x) => x.roll.rollId === roll.rollId && x.date.isSame(props.weekDays[day], "date"));
  };
  const getDayUnv = (roll: any, day: number) => {
    return props.unAvailableDates?.find(
      (x) =>
        x.rollId === roll.rollId && dayjs(props.weekDays[day]).isSame(x.date, "date") && !x.isAvailableForCasualBooking
    );
  };
  const { width } = useViewport();

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {props.rolls?.map((roll) => {
        const monday = getDay(roll, 0);
        const mondayUnAv = getDayUnv(roll, 0);
        const tuesday = getDay(roll, 1);
        const tuesdayUnAv = getDayUnv(roll, 1);
        const wednesday = getDay(roll, 2);
        const wednesdayUnAv = getDayUnv(roll, 2);
        const thursday = getDay(roll, 3);
        const thursdayUnAv = getDayUnv(roll, 3);
        const friday = getDay(roll, 4);
        const fridayUnAv = getDayUnv(roll, 4);
        return (
          <Grid item xs={12} key={"roll_" + roll.rollId}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <div>{roll.rollName}</div>
                {width >= 480 && (
                  <div>
                    {dayjs(roll.startTime).format("hh:mm a")} - {dayjs(roll.endTime).format("hh:mm a")}
                  </div>
                )}
              </Grid>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                      <Tooltip
                        title={getAvailabilityReasonsText(mondayUnAv?.reasonForCasualBooking)}
                        placement="top"
                        arrow
                        disableFocusListener={mondayUnAv === undefined}
                        disableHoverListener={mondayUnAv === undefined}
                        disableTouchListener={mondayUnAv === undefined}
                      >
                        <div>
                          <Checkbox
                            disabled={mondayUnAv !== undefined}
                            checked={monday !== undefined}
                            icon={
                              mondayUnAv !== undefined ? (
                                <IoCloseCircleOutline size={32} />
                              ) : (
                                <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                              )
                            }
                            checkedIcon={<IoCheckmarkCircle size={32} />}
                            onChange={(_ev, chk) =>
                              props.onSelectedDateChanged
                                ? props.onSelectedDateChanged(roll, props.weekDays[0], chk)
                                : {}
                            }
                            color="secondary"
                            style={{ padding: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                      <Tooltip
                        title={getAvailabilityReasonsText(tuesdayUnAv?.reasonForCasualBooking)}
                        placement="top"
                        arrow
                        disableFocusListener={tuesdayUnAv === undefined}
                        disableHoverListener={tuesdayUnAv === undefined}
                        disableTouchListener={tuesdayUnAv === undefined}
                      >
                        <div>
                          <Checkbox
                            disabled={tuesdayUnAv !== undefined}
                            checked={tuesday !== undefined}
                            icon={
                              tuesdayUnAv !== undefined ? (
                                <IoCloseCircleOutline size={32} />
                              ) : (
                                <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                              )
                            }
                            checkedIcon={<IoCheckmarkCircle size={32} />}
                            onChange={(_ev, chk) =>
                              props.onSelectedDateChanged
                                ? props.onSelectedDateChanged(roll, props.weekDays[1], chk)
                                : {}
                            }
                            color="secondary"
                            style={{ padding: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                      <Tooltip
                        title={getAvailabilityReasonsText(wednesdayUnAv?.reasonForCasualBooking)}
                        placement="top"
                        arrow
                        disableFocusListener={wednesdayUnAv === undefined}
                        disableHoverListener={wednesdayUnAv === undefined}
                        disableTouchListener={wednesdayUnAv === undefined}
                      >
                        <div>
                          <Checkbox
                            disabled={wednesdayUnAv !== undefined}
                            checked={wednesday !== undefined}
                            icon={
                              wednesdayUnAv !== undefined ? (
                                <IoCloseCircleOutline size={32} />
                              ) : (
                                <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                              )
                            }
                            checkedIcon={<IoCheckmarkCircle size={32} />}
                            onChange={(_ev, chk) =>
                              props.onSelectedDateChanged
                                ? props.onSelectedDateChanged(roll, props.weekDays[2], chk)
                                : {}
                            }
                            color="secondary"
                            style={{ padding: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                      <Tooltip
                        title={getAvailabilityReasonsText(thursdayUnAv?.reasonForCasualBooking)}
                        placement="top"
                        arrow
                        disableFocusListener={thursdayUnAv === undefined}
                        disableHoverListener={thursdayUnAv === undefined}
                        disableTouchListener={thursdayUnAv === undefined}
                      >
                        <div>
                          <Checkbox
                            disabled={thursdayUnAv !== undefined}
                            checked={thursday !== undefined}
                            icon={
                              thursdayUnAv !== undefined ? (
                                <IoCloseCircleOutline size={32} />
                              ) : (
                                <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                              )
                            }
                            checkedIcon={<IoCheckmarkCircle size={32} />}
                            onChange={(_ev, chk) =>
                              props.onSelectedDateChanged
                                ? props.onSelectedDateChanged(roll, props.weekDays[3], chk)
                                : {}
                            }
                            color="secondary"
                            style={{ padding: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                      <Tooltip
                        title={getAvailabilityReasonsText(fridayUnAv?.reasonForCasualBooking)}
                        placement="top"
                        arrow
                        disableFocusListener={fridayUnAv === undefined}
                        disableHoverListener={fridayUnAv === undefined}
                        disableTouchListener={fridayUnAv === undefined}
                      >
                        <div>
                          <Checkbox
                            disabled={fridayUnAv !== undefined}
                            checked={friday !== undefined}
                            icon={
                              fridayUnAv !== undefined ? (
                                <IoCloseCircleOutline size={32} />
                              ) : (
                                <IoEllipseOutline size={32} color={theme.palette.secondary.main} />
                              )
                            }
                            checkedIcon={<IoCheckmarkCircle size={32} />}
                            onChange={(_ev, chk) =>
                              props.onSelectedDateChanged
                                ? props.onSelectedDateChanged(roll, props.weekDays[4], chk)
                                : {}
                            }
                            color="secondary"
                            style={{ padding: 0 }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <strong>Pricing</strong>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {props.rolls?.map((roll, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs={12} md>
                    <strong className="small">{roll.rollName}</strong>
                  </Grid>
                  <Grid item xs={12} md>
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        prefix="$"
                        value={roll.casualFeeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="/ Session"
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span className="small">* Prices exclude available Child Care Subsidy (CCS)</span>
      </Grid>
    </>
  );
};
export default RollDateSelection;
