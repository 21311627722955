import Yup from "../../../utils/yupExtensions";

const AddContactsSchema = Yup.object().shape({
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("First name is required").lengthCheck(2, 100),
        lastName: Yup.string().required("Last name is required").lengthCheck(2, 100),
      })
    )
    .min(2, "At least one additional contact is required"),
});

export default AddContactsSchema;
