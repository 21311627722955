import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { List } from "linqts";

import WeeklyTransaction from "./WeeklyTransaction";
import { StatementTransaction } from "../../types/types";

dayjs.extend(isoWeek);

export interface TransactionsProps {
  transactions: StatementTransaction[];
}

const Transactions = (props: TransactionsProps) => {
  if (props.transactions && props.transactions.length > 0) {
    const arr = new List<StatementTransaction>(props.transactions);

    const transactionsByWeek = arr.GroupBy((t) => dayjs(t.entryDate).week() ?? 0);
    return (
      <>
        {Object.keys(transactionsByWeek)
          .sort((a, b) => Number(a) - Number(b))
          .map((week, index) => {
            return (
              <WeeklyTransaction
                key={index}
                week={parseInt(week)}
                transactions={transactionsByWeek[week]}
              ></WeeklyTransaction>
            );
          })}
      </>
    );
  } else {
    return <div></div>;
  }
};

export default Transactions;
