import { Contact } from "../types/types";
import { isNullOrEmpty } from "./stringUtils";

enum PrimaryContactRegistrationStep {
  step1,
  step2,
  step3,
}

type Step = [step: PrimaryContactRegistrationStep, count: number];

const steps: Step[] = [
  [PrimaryContactRegistrationStep.step1, 13], // General Info
  [PrimaryContactRegistrationStep.step3, 1], // Authorization
];

export class PrimaryContactHelper {
  static percentCompleted(contact: Contact) {
    var complete = 0;

    for (var stepWithRequiredFields in steps) {
      complete += PrimaryContactHelper.getStepCompletion(contact, steps[stepWithRequiredFields]);
    }

    return (complete * 100) / 14;
  }

  static getStepCompletion(contact: Contact, step: Step) {
    var completion = 0;

    switch (step[0]) {
      case PrimaryContactRegistrationStep.step1:
        completion += isNullOrEmpty(contact.firstName) ? 0 : 1;
        completion += isNullOrEmpty(contact.lastName) ? 0 : 1;
        completion += contact.contactTypeId == null ? 0 : 1;
        completion += contact.birthday == null ? 0 : 1;
        completion += contact.genderId == null ? 0 : 1;
        completion += contact.email == null ? 0 : 1;
        completion += isNullOrEmpty(contact.mobilePhone) ? 0 : 1;
        completion += isNullOrEmpty(contact.residentialAddress?.streetLine1) ? 0 : 1;
        completion += isNullOrEmpty(contact.residentialAddress?.postCode) ? 0 : 1;
        completion += isNullOrEmpty(contact.residentialAddress?.suburb) ? 0 : 1;
        completion += isNullOrEmpty(contact.residentialAddress?.state) ? 0 : 1;
        completion += isNullOrEmpty(contact.mainLanguage) ? 0 : 1;
        completion += isNullOrEmpty(contact.culturalBackground) ? 0 : 1;
        break;
      case PrimaryContactRegistrationStep.step3:
        completion += contact.authorisation == null ? 0 : 1;
        break;
      default:
        break;
    }

    return completion;
  }

  static getFirstUncompletedStep(contact: Contact, index: number) {
    for (var stepWithRequiredFields in steps) {
      var stepCompletion = PrimaryContactHelper.getStepCompletion(contact, steps[stepWithRequiredFields]);
      if (stepCompletion < steps[stepWithRequiredFields][1]) {
        return `/registration-contact-step/${steps[stepWithRequiredFields][0] + 1}/${index}`;
      }
    }
    return null;
  }
}
