import React from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider/Divider";
import Fab from "@mui/material/Fab/Fab";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import HeaderDrawer from "./HeaderDrawer";
import logo from "../../assets/logos/logo-master-yellow.svg";
import { signoutRedirect } from "../../store/modules/auth/authServices";
import { RootState } from "../../store/store";
import { EntityType } from "../../types/models";
import { getStyleColor } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";
import Spacer from "../Common/Spacers/Spacers";

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      margin: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
    label: {
      fontSize: 16,
      marginRight: 10,
      display: "none",
      color: getStyleColor("--color-accent-4"),
      "@media screen and (min-width: 720px)": {
        display: "inline-block",
      },
    },
    iconButton: {
      width: 48,
      height: 48,
    },
    root: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      height: 64,
      paddingLeft: 0,
      paddingRight: 0,
      boxShadow: "none",
      backgroundColor: "#5ce5bb",
      justifyContent: "center",
      "@media screen and (min-width: 1280px)": {
        height: 94,
      },
    },

    fab: {
      backgroundColor: "white",
      boxShadow: "none",
      marginRight: theme.spacing(1),
      "&:hover": {
        boxShadow: "none",
      },
    },
    icon: {
      height: 34,
      "@media screen and (min-width: 1200px)": {
        height: 44,
      },
    },
  };
});

function Header() {
  const { classes } = useStyles();
  async function signoutAsync() {
    await signoutRedirect().then(() => console.log("Signed out"));
  }
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const auth = useSelector((state: RootState) => state.auth);
  const avatars = useSelector((state: RootState) => state.avatars.avatars);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { user, registeredUser } = auth;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id) ?? "";

  const contact = useSelector((state: RootState) =>
    state.customer.customer?.contacts?.find((c) => c.customerContactId === parseInt(customer_contact_id.toString()))
  );

  const contactAvatar = avatars?.find(
    (x) => x.entityType === EntityType.Contact && x.entityId === contact?.customerContactId?.toString()
  );

  const handleMenu = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          {user && registeredUser && isMobile && <HeaderDrawer />}
          <Link to="/">
            <img src={logo} className={classes.icon} alt="Camp Australia" />
          </Link>
          <div className={classes.title} />
          {user && (
            <>
              <Grid
                container
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
                justifyItems="flex-end"
                height="100%"
              >
                {registeredUser && (
                  <>
                    <Fab
                      onClick={() => (window.location.href = "https://campaustralia.com.au/how-to-book")}
                      sx={{ transform: "scale(0.8)", backgroundColor: "white", width: 42, height: 42 }}
                    >
                      <QuestionMarkIcon style={{ width: 30, height: 30 }} />
                    </Fab>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ backgroundColor: "white", width: 0.001, alignContent: "center", margin: 2 }}
                    />
                    <Spacer margin={5} />
                  </>
                )}

                {contact?.firstName && (
                  <Link
                    to="#"
                    className={classes.label}
                    onClick={handleMenu}
                  >{`${contact?.firstName} ${contact?.lastName}`}</Link>
                )}
                <Stack
                  direction={"row"}
                  spacing={0}
                  // pt={isDesktop ? 0 : isMobile ? 1 : 0.5}
                  alignContent="center"
                  alignItems="center"
                  height={"100%"}
                >
                  <IconButton
                    id="btnAccount"
                    aria-label="account of current user"
                    aria-controls="primary-account-menu"
                    aria-haspopup="true"
                    onClick={handleMenu}
                  >
                    {contactAvatar || contact?.avatarIndex ? (
                      <AvatarDisplay info={contactAvatar || { index: contact?.avatarIndex }} size={38} />
                    ) : (
                      <AvatarDisplay
                        icon={
                          <AccountCircle
                            style={{ color: getStyleColor("--custom-color-dark-grey"), width: 32, height: 32 }}
                          />
                        }
                        size={48}
                      />
                    )}
                  </IconButton>

                  <IconButton
                    hidden={isMobile}
                    id="btnAccountArrow"
                    aria-label="account of current user"
                    aria-controls="primary-account-menu"
                    aria-haspopup="true"
                    size="small"
                    onClick={handleMenu}
                  >
                    <KeyboardArrowDownIcon sx={{ color: getStyleColor("--color-accent-4"), width: 26, height: 26 }} />
                  </IconButton>
                  <Menu
                    id="primary-account-menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                  >
                    {registeredUser && isMobile && (
                      <MenuItem id="menuMuAccount" onClick={() => navigate("/account-home")}>
                        My account
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => navigate("/account-home")}>Account Settings</MenuItem>
                    <MenuItem onClick={signoutAsync}> Sign out</MenuItem>
                  </Menu>
                </Stack>
              </Grid>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
