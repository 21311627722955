import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { clearUserState, signinRedirectCallback, signoutRedirect } from "../../store/modules/auth/authServices";

function SignInOidc() {
  const navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      try {
        await signinRedirectCallback().then(() => navigate("/"));
      } catch (err: any) {
        if (err.error === "access_denied") {
          clearUserState();
          navigate("/");
        } else {
          signoutRedirect();
        }
      }
    }
    signinAsync();
  }, [history]);

  return null;
}

export default SignInOidc;
