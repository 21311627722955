import { FileResponse } from "../../../types/types";
import apistatementReport from "./statementReportApi";
import { reportDownloaded, reportDownloading, reportError, reportInit } from "./statementReportStateActions";
export function downloadStatement(dispatch: any, customerAccountId: number, statementRunEntryNo: number) {
  dispatch(reportDownloading(statementRunEntryNo));
  apistatementReport.getStatementReport(customerAccountId, statementRunEntryNo).then(
    function (value: FileResponse | null) {
      if (value && value.status === 200) {
        dispatch(reportDownloaded(value.data));
      } else {
        dispatch(reportError("Error occurred while downloading statement"));
      }
    },
    function (error: any) {
      dispatch(reportError("Error occurred while downloading statement"));
    }
  );
}

export function initReport(dispatch: any) {
  dispatch(reportInit());
}
