import CryptoJS from "crypto-js";

//The algorithm is used in Events API and here is described here
//https://campaustralia.atlassian.net/wiki/spaces/MICRO/pages/2334916651/OneService+Events+API+Security
export class PayloadSigningHelper {
  static hashMapObject(
    data: Record<string, any>,
    tokenValue: string,
    {
      blobs,
      ignoreProps,
      forceToLowerProps,
    }: {
      blobs?: Uint8Array[];
      ignoreProps?: string[];
      forceToLowerProps?: string[];
    } = {}
  ): string {
    const dataString = PayloadSigningHelper.stringify(data, ignoreProps ?? [], forceToLowerProps ?? []);

    let bytes = CryptoJS.enc.Utf8.parse(dataString);

    if (blobs) {
      blobs.forEach((b) => {
        bytes.concat(CryptoJS.lib.WordArray.create(b));
      });
    }

    bytes = bytes.concat(CryptoJS.enc.Utf8.parse(tokenValue));

    const digest = CryptoJS.SHA256(bytes);

    return digest.toString(CryptoJS.enc.Hex);
  }

  static stringify(data: Record<string, any>, ignoreProps: string[], forceToLower: string[]): string {
    const keys = Object.keys(data).sort((a, b) => a.localeCompare(b));
    let result = "";
    for (const k of keys) {
      let val = data[k];
      if (!PayloadSigningHelper.skipProperty(ignoreProps, k)) {
        if (k !== "properties") {
          val = val ?? "";
          val = PayloadSigningHelper.doForceLowerCase(forceToLower, k) ? val.toString().toLowerCase() : val;
          result += `${k.toLowerCase()}=${
            typeof val === "object" ? PayloadSigningHelper.stringifyDictionary(val) : val
          };`;
        } else {
          result += `${k.toLowerCase()}=${PayloadSigningHelper.stringifyDictionary(val)};`;
        }
      }
    }
    return result.substring(0, result.length - 1);
  }

  static doForceLowerCase(forceToLower: string[], propName: string): boolean {
    return forceToLower.some((x) => x.toLowerCase() === propName.toLowerCase());
  }

  static skipProperty(ignoreProps: string[], propName: string): boolean {
    return ignoreProps.some((x) => x.toLowerCase() === propName.toLowerCase());
  }

  static stringifyDictionary(data: Record<string, any>): string {
    const result = Object.entries(data).map(([key, value]) => `${key}: ${value ?? ""}`);
    return `{${result.join(", ")}}`;
  }
}
