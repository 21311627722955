import dayjs, { Dayjs } from "dayjs";

import { BookingDaysInfo, ScheduleInfo, SelectedServiceProgram } from "./models";
import { FileParameter } from "../store/parentApi";

export interface CustomerAccount {
  customerAccountId: number;
  debtCategoryId?: number | undefined;
  isFriendlyFeeEligible?: boolean | undefined;
  primaryServiceId?: number | undefined;
  isExcluded: boolean;
  canBook: boolean;
  showTutorial?: boolean | undefined;
  contacts?: Contact[] | undefined;
  documents?: Document[] | undefined;
  ccbSubmissionContactId?: number | undefined;
}

export interface Document {
  documentId?: number;
  documentTypeId?: number;
  isRegistration: boolean;
  isTemp?: boolean;
  uniqueId?: string | undefined;
  registrationChildUniqueId?: string | undefined;
  registrationContactUniqueId?: string | undefined;
  fileName?: string | undefined;
  medicalPlanConditionId?: number | undefined;
  childId?: number | undefined;
  customerContactId?: number | undefined;
  file?: FileParameter | undefined;
  medicalPlanQuestionId?: number | undefined;
  medicalPlanAnswerIndex?: number | undefined;
  expiresOn?: dayjs.Dayjs | undefined;
}

export enum DocumentTypes {
  CourtOrders = 13006,
  ImmunizationRecords = 13008,
  ChildMedicalDocument = 13015,
  ChildAvatarBigSize = 13017,
  ChildAvatarSmallSize = 13018,
  ContactAvatarBigSize = 13019,
  ContactAvatarSmallSize = 13020,
  CustomerBillingQuery = 13030,
}

export enum ContactTypeCategory {
  Immediate = 1,
  Additional = 2,
  Extra = 3,
  Other = 4,
}

export interface Service {
  serviceId: number;
  isOperating: boolean;
  licensedPlaces: number;
  primaryAddressCountry: string;
  primaryAddressPostcode: string;
  primaryAddressState: string;
  primaryAddressStreet: string;
  primaryAddressSuburb: string;
  primaryAddressType: string;
  serviceName: string;
  isPrimary: boolean;
  phone: string;
  email: string;
  mobile: string;
  lat?: number | undefined;
  lng?: number | undefined;
  currentDateTimeOffset: dayjs.Dayjs;
  minimumDaysToAvoidCancellationFeeRecurringBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeCasualBooking?: number | undefined;
  minimumBookingFeeDaysForDiscount?: number | undefined;
  firstMinimumBookingsRequired?: number | undefined;
  checkCapacityForScheduledBookings?: boolean | undefined;
  minimumDaysToAvoidCancellationFeeVacBooking: number;
  minimumVacBookingFeeDaysForDiscount: number;
  minRepetitiveBookingsRequired: number;
}

export interface User {
  id_token: string;
  session_state: string;
  access_token: string;
  token_type: string;
  scope: string;
  profile: {
    s_hash: string;
    sid: string;
    sub: string;
    auth_time: number;
    idp: string;
    family_name: string;
    customer_contact_id: number;
    name: string;
    given_name: string;
    customer_account_id: number;
    acl: string;
    preferred_username: string;
    email: string;
    amr: string[];
  };
  expires_at: number;
}

export interface ResidentialAddress {
  uniqueId?: string;
  addressId?: number;
  streetLine1?: string;
  streetLine2?: string;
  suburb?: string;
  postCode?: string;
  state?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export interface IChild {
  childId?: number;
  uniqueId?: string;
  createdAt?: dayjs.Dayjs;
  crn?: string;
  firstName?: string;
  lastName?: string;
  birthday?: dayjs.Dayjs;
  genderId?: number;
  otherGender?: string;
  schoolId?: number;
  homePhone?: string;
  culturalBackground?: string;
  mainLanguage?: string;
  secondaryLanguage?: string;
  culturalNeeds?: string;
  sameAddressDetailsAsPrimary?: boolean;
  sameCulturalDetailsAsPrimary?: boolean;
  sameSchoolDetailsAsFirstChild?: boolean;
  residentialAddress?: ResidentialAddress;
  indigenousStatus?: string;
  dietaryRequirements?: string;
  specialNeeds?: string;
  educationNeeds?: string;
  behaviouralNeeds?: string;
  physicalNeeds?: string;
  medicalNeeds?: string;
  disability?: string;
  allergies?: string;
  anaphylaxisProblem?: string;
  anaphylaxisSeverity?: string;
  anaphylaxisType?: string;
  anaphylaxisTreatment?: string;
  medicalServiceName?: string;
  doctorName?: string;
  doctorAddress?: string;
  doctorPhone?: string;
  doctorSuburb?: string;
  doctorState?: string;
  doctorPostcode?: string;
  ambulanceNumber?: string;
  hasBeenImmunised?: boolean;
  hasMedicalConditions?: boolean;
  hasPhysicalNeeds?: boolean;
  hasBehaviouralNeeds?: boolean;
  hasSpecialNeeds?: boolean;
  hasDietaryRequirements?: boolean;
  medicalConditions?: number[];
  medicalConditionDetails?: number[];
  noMedicalConditionDetails?: number[];
  childSituations?: number[];
  allowAdministerMedicine?: boolean;
  allowAmbulanceTransportation?: boolean;
  allowMedicalTreatment?: boolean;
  allowTakeOutside?: boolean;
  priorityAccessId?: number;
  avatarIndex?: number;
  medicareNumber?: string;
  sameLivingCareAsFirstChild?: boolean;
  sameMedicalContactAsFirstChild?: boolean;
  otherMedicalCondition?: string;
  schoolName?: string;
  firstChildHasMedicalContact?: boolean;
  medicalPlanAnswers?: IRegistrationMedicalPlanAnswer[];
  answers?: IAnswer[];
  childSuspensionEndDate?: dayjs.Dayjs | undefined;
  childSuspensionReasonId?: number | undefined;
  isSuspended?: boolean;
  acknowledgedStatus?: boolean | undefined;
  ageExemptionExpiryDate?: dayjs.Dayjs | undefined;
  serviceId?: number | undefined;
  serviceName?: string | undefined;
  childBookingStatus?: ChildBookingStatus | undefined;
  childServices?: IChildService[];
  authorize?: boolean;
  informationIsTrue?: boolean;
  signature?: string;
  signatureDocumentId?: string;
}
export enum ChildBookingStatus {
  ApprovedForAllServices = "approvedForAllServices",
  BlockedForAllServices = "blockedForAllServices",
  ApprovedForSpecificServices = "approvedForSpecificServices",
}
export enum ChildServiceStatus {
  BookingsApproved = "bookingsApproved",
  OnHoldAdditionalNeeds = "onHoldAdditionalNeeds",
  OnHoldActionPlanNeeds = "onHoldActionPlanNeeds",
  OnHoldRemoved = "onHoldRemoved",
}
export interface IChildService {
  childId: number;
  status: ChildServiceStatus;
  serviceId: number;
  serviceName: string;
}
export enum ConditionIds {
  Epilepsy = 11,
  Allergy = 23,
  DiabetesTypeOne = 25,
  Physical = 111,
}

export interface Contact {
  customerContactId?: number;
  customerAccountId?: number;
  identityServerUserId?: string;
  uniqueId: string;
  contactTypeId?: number | string;
  isPrimary?: boolean;
  firstName: string;
  lastName: string;
  createdAt?: string;
  birthday?: dayjs.Dayjs | string;
  genderId?: number;
  email?: string;
  mobilePhone?: string;
  homePhone?: string;
  workPhone?: string;
  crn?: string;
  culturalBackground?: string;
  mainLanguage?: string;
  secondaryLanguage?: string;
  culturalNeeds?: string;
  planToClaimChildCareSubsidy?: boolean;
  sameAddressDetailsAsPrimary?: boolean;
  sameCulturalDetailsAsPrimary?: boolean;
  avatarIndex?: number;
  residentialAddress?: {
    uniqueId?: string;
    streetLine1?: string;
    streetLine2?: string;
    suburb?: string;
    postCode?: string;
    state?: string;
    country?: string;
  };
  authorisation?: {
    canApproveAmbulance?: boolean;
    canManageAccount?: boolean;
    canMakeAndChangeBookings?: boolean;
    canPickUpChild?: boolean;
    consentToAdministerMedicine?: boolean;
    consentToMedicalTreatment?: boolean;
    consentToTakeOutsidePremises?: boolean;
    consentForTransportation?: boolean;
  };
}

export interface IRegistrationMedicalPlanAnswer {
  questionId: number;
  conditionId: number;
  multipleAnswerIndex: number;
  value?: string | undefined;
  freeText?: string | undefined;
}

export interface IAnswer {
  uniqueId: string | undefined;
  childId: number | undefined;
  questionId: number;
  conditionId: number;
  multipleAnswerIndex: number;
  value?: string | undefined;
  freeText?: string | undefined;
}

export interface ITemplateQuestionOption {
  questionOptionId: number;
  name: string;
}

export interface SystemState {
  loggedIn: boolean;
  session: string;
  userName: string;
}

export interface ITemplateQuestionDocument {
  title: string;
  url: string;
  forMedicalConditions?: number[] | undefined;
}

export interface IQuestionTemplate {
  questionId: number;
  questionCategoryId: number;
  questionTypeId: number;
  parentQuestionId?: number | undefined;
  parentQuestionOptionId?: number | undefined;
  text?: string | undefined;
  defaultValue?: string | undefined;
  allowMultipleAnswers: boolean;
  allowFreeText: boolean;
  visible: boolean;
  required: boolean;
  addAnswerQuestion?: string | undefined;
  freeTextHint?: string | undefined;
  forMedicalConditions?: number[] | undefined;
  notForMedicalConditions?: number[] | undefined;
  allowedFileTypes?: string | undefined;
  allowedFileSizeInKb?: number | undefined;
  note?: string | undefined;
  freeTextRequired?: boolean | undefined;
  uploadExpirationRequired?: boolean | undefined;
  link?: string | undefined;
  questionDocuments?: ITemplateQuestionDocument[] | undefined;
  questionOptions?: ITemplateQuestionOption[] | undefined;
  multipleQuestionIndex?: number;
}

export interface IMedicalCondition {
  conditionId: number;
  name: string;
  conditionTypeId: number;
}

export interface IMedicalConditionType {
  conditionTypeId: number;
  name: string;
}

export interface IMedicalPlanTemplate {
  questions: IQuestionTemplate[];
  conditions: IMedicalCondition[];
  conditionTypes: IMedicalConditionType[];
}

export interface IProgramActivityTypeModel {
  programActivityTypeId: number;
  activityTypeName: string;
}

export interface CommonData {
  states: State[];
  genders: Gender[];
  bookingCancellationStatuses: BookingCancellationStatus[];
  careTypes: EnumDescriptor[];
  popularLanguages: string[];
  contactTypes: ContactType[];
  priorityAccess: PriorityAccess[];
  childSituations: ChildSituation[];
  transQueryTypes: TransactionQueryType[];
  medicalPlanTemplate: IMedicalPlanTemplate | null;
  programActivityTypes: IProgramActivityTypeModel[];
}

export interface State {
  uniqueId: string;
  stateId: number;
  shortName: string;
  fullName: string;
  timeZoneId: string;
  timeZone: TimeZone;
}

export interface TimeZone {
  uniqueId: string;
  windowsName: string;
  linuxName: string;
}

export interface Gender {
  genderId: number;
  name: string;
}

export interface BookingCancellationStatus {
  bookingCancellationStatusId?: number | undefined;
  description: string;
  isChargeable: boolean;
}

export interface EnumDescriptor {
  value: number;
  code: string;
  description: string;
}

export interface ContactType {
  contactTypeId: number;
  name: string;
  contactTypeCategoryId: number;
  order: number;
}

export interface MedicalCondition {
  medicalConditionId: number;
  name: string;
  medicalConditionDetails?: MedicalConditionDetail[] | undefined;
}

export interface MedicalConditionDetail {
  medicalConditionDetailId: number;
  medicalConditionId: number;
  name: string;
  description?: string | undefined;
  isOptional: boolean;
  documents?: string | undefined;
  order: number;
  medicalConditionDetailTypeId: number;
  allowedFileTypes: string;
  allowedFileSizeInKb: number;
}

export interface PriorityAccess {
  priorityAccessId: number;
  name: string;
  description?: string | undefined;
}

export interface ChildSituation {
  childSituationId: number;
  name: string;
  description?: string | undefined;
}

export interface TransactionQueryType {
  id: number;
  description?: string | undefined;
}

export interface Booking {
  uniqueId: string;
  childId: number;
  rollId: number;
  scheduleId?: string | undefined;
  feeAmount: number;
  discountAmount: number;
  cancellationStatusId: number;
  cancelledAt?: dayjs.Dayjs | undefined;
  cancelledBy?: string | undefined;
  attendedTimeIn?: dayjs.Dayjs | undefined;
  attendedTimeOut?: dayjs.Dayjs | undefined;
  feeName: string;
  hasAttended?: boolean | undefined;
  isAttendanceMarked?: boolean | undefined;
  isBookingCreated?: boolean | undefined;
  nonAttendanceReason?: string | undefined;
  date: dayjs.Dayjs;
  isCancelled: boolean;
  netAmount: number;
  createdAt: dayjs.Dayjs;
  roll?: IRollInfo | undefined;
  schedule?: IScheduleInfo | undefined;
  program?: IHolidayClubProgramInfo | undefined;
}

export interface IHolidayClubProgramInfo {
  programDateId: number;
  sessionId: number;
  season: string;
  year: number;
  supplierId?: number | undefined;
  provider?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  activityCode?: string | undefined;
  programDescription: string;
  programName: string;
  imageUrl?: string | undefined;
  date: dayjs.Dayjs;
}

export interface IRollInfo {
  rollId: number;
  serviceId: number;
  rollName?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  primaryFeeId?: number | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  casualFeeId?: number | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  rollClassificationId?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  ageCalculationMethodId: number;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
  service?: IServiceInfo | undefined;
}

export interface IServiceInfo {
  serviceId: number;
  serviceName?: string | undefined;
  minimumDaysToAvoidCancellationFeeRecurringBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeCasualBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeVacBooking: number;
}

export interface IScheduleInfo {
  uniqueId: string;
  createdAt: dayjs.Dayjs;
  customerAccountId: number;
  rollId: number;
  childId: number;
  scheduleType: BookingScheduleType;
  effectiveFromDate: dayjs.Dayjs;
  effectiveToDate?: dayjs.Dayjs | undefined;
  firstMonday: boolean;
  firstTuesday: boolean;
  firstWednesday: boolean;
  firstThursday: boolean;
  firstFriday: boolean;
  secondMonday: boolean;
  secondTuesday: boolean;
  secondWednesday: boolean;
  secondThursday: boolean;
  secondFriday: boolean;
  numberOfUpcomingBookings: number;
  minBookingsRequired: number;
}

export enum BookingScheduleType {
  Weekly = "weekly",
  Fortnightly = "fortnightly",
}

export enum AgeCalculationMethod {
  Normal = "normal",
  TillStartOfYear = "tillStartOfYear",
}

export interface School {
  schoolId: number;
  schoolName?: string | undefined;
  caServiceId?: number | undefined;
  city?: string | undefined;
  state?: string | undefined;
  postcode?: string | undefined;
}

export interface Roll {
  rollId: number;
  serviceId: number;
  rollName?: string | undefined;
  startTime?: Dayjs | undefined;
  endTime?: Dayjs | undefined;
  primaryFeeId?: number | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  casualFeeId?: number | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  rollClassificationId?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  isHolidayClub: boolean;
  isOhsc: boolean;
  nextAvailableSession: dayjs.Dayjs;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
  monIsOpen?: boolean;
  tueIsOpen?: boolean;
  wedIsOpen?: boolean;
  thuIsOpen?: boolean;
  friIsOpen?: boolean;
}

export interface UpcomingCharge {
  bookingId?: number | undefined;
  date: dayjs.Dayjs;
  childId: number;
  rollId: number;
  grossAmount: number;
  discountAmount: number;
  ccs: number;
  accs: number;
  serviceName: string;
  firstName: string;
  lastName: string;
  feeName: string;
  cancellationStatusId: number;
  hasAttended?: boolean | undefined;
  isMarked?: boolean | undefined;
  rollName: string;
  multiChildrenDiscount: number;
}

export enum RegistrationStatus {
  Success = "success",
  AlreadyRegistered = "alreadyRegistered",
  UnknownError = "unknownError",
  NeedVerification = "needVerification",
  InvalidReferralCode = "invalidReferralCode",
}

export interface BillingCycleSummary {
  transactionId: number;
  billingCycleId: number;
  billingPeriodStart: dayjs.Dayjs;
  billingPeriodEnd: dayjs.Dayjs;
  dueDate: dayjs.Dayjs;
  openBalance?: number | undefined;
  totalAmount?: number | undefined;
  closingBalance?: number | undefined;
  paymentsMade: number;
  isStatementCleared?: boolean | undefined;
  isOverDue?: boolean | undefined;
  isPostCCITS: boolean;
  isDue: boolean;
  transactions: StatementTransaction[];
  balance?: number | undefined;
  statementDate: dayjs.Dayjs;
}
export interface StatementTransaction {
  rollWeekBegin?: dayjs.Dayjs | undefined;
  rollWeekEnd?: dayjs.Dayjs | undefined;
  parentID?: number | undefined;
  ccb?: number | undefined;
  ccr?: number | undefined;
  sServiceID?: number | undefined;
  qkLedgerId?: number | undefined;
  accs: number;
  ccs: number;
  entryDate?: dayjs.Dayjs | undefined;
  entryDescr?: string | undefined;
  entryType?: string | undefined;
  transactionTypeId?: number | undefined;
  transactionClassId?: number | undefined;
  totalDebits?: number | undefined;
  totalCredits?: number | undefined;
  fees?: number | undefined;
  description?: string | undefined;
  totalMovement?: number | undefined;
  serviceName?: string | undefined;
  transactionId: number;
  bookingId?: number | undefined;
  hours?: number | undefined;
  sessionTimeStart?: dayjs.Dayjs | undefined;
  sessionTimeEnd?: dayjs.Dayjs | undefined;
  attendanceTimeStart?: dayjs.Dayjs | undefined;
  attendanceTimeEnd?: dayjs.Dayjs | undefined;
  sessionType?: string | undefined;
  childId?: number | undefined;
  parentFeeWaiver?: number | undefined;
  hasAttended?: number | undefined;
  cancellationStatusId?: number | undefined;
  bookingDescription?: string | undefined;
  reversalDate?: dayjs.Dayjs | undefined;
  billingCycleId: number;
}

export interface IBillingCycleTransaction {
  transactionId: number;
  entryDate: dayjs.Dayjs;
  childId: number;
  isSchedule: boolean;
  hours: number;
  rate: number;
  accs: number;
  ccs: number;
  fees: number;
  sessionType: string;
  serviceId: number;
  serviceName: string;
  entryDescr: string;
  entryType: string;
  totalMovement: number;
}
export interface PageableBillingCycleSummary {
  records: BillingCycleSummary[];
  total: number;
}
export interface BillingCycleSummaryList extends PageableBillingCycleSummary {
  accountBalance: number;
  statementBalance: number;
  statementDate: dayjs.Dayjs;
}

export class AccountStatement {
  balance!: number;
  statementDate!: dayjs.Dayjs;
  statementBalance?: number | undefined;
}

export interface IMakeOneOffBankPaymentHttpRequest {
  customerContactId: number;
  amount: number;
  bank: IBankInfoHttpRequest;
}

export interface IBankInfoHttpRequest {
  bsb: string;
  accountNumber: string;
  accountName: string;
}
export interface IMakeOneOffCardPaymentHttpRequest {
  customerContactId: number;
  amount: number;
  creditCard: ICreditCardInfoHttpRequest;
}
export interface ICreditCardInfoHttpRequest {
  number: string;
  month: number;
  year: number;
  name: string;
  cvc: string;
}
export interface IMakePaymentHttpResponse {
  title: string;
  description: string;
  result: string;
  reference: string;
  isSuccess: boolean;
  amount?: string | undefined;
}
export interface TermDate {
  termId: number;
  year: number;
  termNumber: number;
  firstDayOfTerm?: dayjs.Dayjs | undefined;
  lastDayOfTerm?: dayjs.Dayjs | undefined;
}
export interface BookingPreview {
  rollId: number;
  childId: number;
  date: dayjs.Dayjs;
  status: BookingStatus;
  fee: number;
  feeType?: FeeType | undefined;
  multiChildrenDiscount: number;
}

export enum BookingStatus3 {
  New = "new",
  Existing = "existing",
  CancelledInTime = "cancelledInTime",
  CancelledInTimeAsHot = "cancelledInTimeAsHot",
  CancelledInNoticePeriod = "cancelledInNoticePeriod",
}
export interface IBookingInfo3 {
  rollId: number;
  date: dayjs.Dayjs;
  status: BookingStatus3;
  fee: number;
  feeType?: FeeType | undefined;
  multiChildrenDiscount: number;
}
export interface IBookingInfo2 {
  rollId: number;
  childId: number;
  date: dayjs.Dayjs;
  status: BookingStatus2;
  fee: number;
  feeType?: FeeType | undefined;
  multiChildrenDiscount: number;
}

export interface BookingPreview2 {
  rollId: number;
  date: dayjs.Dayjs;
  status: BookingStatus2;
  fee: number;
  feeType?: FeeType | undefined;
  multiChildrenDiscount: number;
}
export enum BookingStatus {
  New = "new",
  Existing = "existing",
  CancelledInTime = "cancelledInTime",
  CancelledInTimeAsHot = "cancelledInTimeAsHot",
  CancelledInNoticePeriod = "cancelledInNoticePeriod",
}
export enum BookingStatus2 {
  New = "new",
  Existing = "existing",
  CancelledInTime = "cancelledInTime",
  CancelledInTimeAsHot = "cancelledInTimeAsHot",
  CancelledInNoticePeriod = "cancelledInNoticePeriod",
}

export enum FeeType {
  Casual = "casual",
  Recurring = "recurring",
}

export interface ISessionAvailability {
  date: dayjs.Dayjs;
  rollId: number;
  availableCapacity: number;
  reasonForRecurringBooking?: String | undefined;
  reasonForCasualBooking?: String | undefined;
  isAvailableForRecurringBooking: boolean;
  isAvailableForCasualBooking: boolean;
}
export enum SessionUnavailabilityReason {
  None = "none",
  HasDuplicateCareType = "hasDuplicateCareType",
  OverCapacity = "overCapacity",
  TooLateToBook = "tooLateToBook",
  NotAllowedCareType = "notAllowedCareType",
  SessionNotOperating = "sessionNotOperating",
  SessionNotFound = "sessionNotFound",
}

export interface GetCreateRecurringPreviewRequest {
  childrenIds: number[];
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  scheduleType: BookingScheduleType;
  days: BookingDays[];
  excludedDates?: BookingDate[] | undefined;
  ageViolationReasons?: AgeViolationReason[] | undefined;
}

export interface IGetCreateCasualBookingsPreviewRequest {
  childrenIds: number[];
  dates: BookingDate[];
}

export interface BookingDays {
  rollId: number;
  week1: DayOfWeek[];
  week2: DayOfWeek[] | undefined;
}

export enum DayOfWeek {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

export interface BookingDate {
  rollId: number;
  date: dayjs.Dayjs;
}

export interface AgeViolationReason {
  childId: number;
  rollId: number;
  reason: string;
}

export interface IPaymentMethodHttpResponse {
  paymentMethodId: number;
  paymentType: PaymentType;
  customerAccountId: number;
  paymentTypeName?: string | undefined;
  paymentMethodCreated: dayjs.Dayjs;
  accountName?: string | undefined;
  expiryMonth?: number | undefined;
  expiryYear?: number | undefined;
  isCurrentPaymentMethod: boolean;
  masked?: string | undefined;
}

export enum PaymentType {
  BankAccount = "bankAccount",
  CreditCard = "creditCard",
}

export interface IAppConfig {
  name?: string | undefined;
  value?: string | undefined;
}
export interface ICreatePaymentMethodHttpResponse {
  paymentMethodId: number;
  result?: string | undefined;
  isSuccess: boolean;
}
export interface IBillingUpcomingCharges {
  charges: UpcomingCharge[];
  billingCycleId: number;
  billingPeriodStart: dayjs.Dayjs;
  billingPeriodEnd: dayjs.Dayjs;
}

export interface FileResponse {
  data: Blob;
  status: number;
  fileName?: string;
  headers?: { [name: string]: any };
}

export interface ICreateRecurringBookingsRequest {
  childrenIds: number[];
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  scheduleType: BookingScheduleType;
  days: BookingDays[];
  excludedDates: BookingDate[] | undefined;
  ageViolationReasons: AgeViolationReason[] | undefined;
}

export interface IGetChangeRecurringBookingPreviewResponse {
  minBookingsRequired: number;
  chargedBookingsCount: number;
  bookings: IBookingInfo3[];
}

export interface IGetChangeRecurringBookingPreviewRequest {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  days: BookingDays;
  excludedDates?: dayjs.Dayjs[] | undefined;
}

export interface IErrorResponse {
  message: string;
  info?: any | undefined;
  correlationId: string;
  errorCode: number;
}

export interface ICreateCasualBookingsHttpRequest {
  childrenIds: number[];
  dates: BookingDate[];
  excludedDates?: BookingDate[] | undefined;
  ageViolationReasons?: AgeViolationReason[] | undefined;
}

export interface IPrivacySetting {
  allowActivityPhoto: boolean;
  allowAutoLogin: boolean;
}

export enum PrivacySettingType {
  AllowAutoLogin = "allowAutoLogin",
  AllowActivityPhoto = "allowActivityPhoto",
}

export interface INotificationSettings {
  smsBookingOffers: boolean;
  smsGeneral: boolean;
  smsHolidayClubMarketing: boolean;
  smsInSessionActivities: boolean;
  smsIndustryThoughts: boolean;
  emailBookingOffers: boolean;
  emailGeneral: boolean;
  emailHolidayClubMarketing: boolean;
  emailInSessionActivities: boolean;
  emailIndustryThoughts: boolean;
  printBookingOffers: boolean;
  printGeneral: boolean;
  printHolidayClubMarketing: boolean;
  printInSessionActivities: boolean;
  printIndustryThoughts: boolean;
}

export enum NotificationCategory {
  BookingOffers = "bookingOffers",
  General = "general",
  HolidayClubMarketing = "holidayClubMarketing",
  InSessionActivities = "inSessionActivities",
  IndustryThoughts = "industryThoughts",
}

export enum NotificationChannel {
  Sms = "sms",
  Email = "email",
  PushNotification = "pushNotification",
  Print = "print",
}

export interface IService {
  serviceId: number;
  isOperating?: boolean | undefined;
  licensedPlaces?: number | undefined;
  primaryAddressCountry?: string | undefined;
  primaryAddressPostcode?: string | undefined;
  primaryAddressState?: string | undefined;
  primaryAddressStreet?: string | undefined;
  primaryAddressSuburb?: string | undefined;
  primaryAddressType?: string | undefined;
  serviceName?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  mobile?: string | undefined;
  lat?: number | undefined;
  lng?: number | undefined;
  currentDateTimeOffset: dayjs.Dayjs;
  minimumDaysToAvoidCancellationFeeRecurringBooking?: number | undefined;
  minimumDaysToAvoidCancellationFeeCasualBooking?: number | undefined;
  minimumBookingFeeDaysForDiscount?: number | undefined;
  firstMinimumBookingsRequired?: number | undefined;
  checkCapacityForScheduledBookings?: boolean | undefined;
  minimumDaysToAvoidCancellationFeeVacBooking: number;
  minimumVacBookingFeeDaysForDiscount: number;
  minRepetitiveBookingsRequired: number;
}

export interface IServiceDistance {
  service: IService;
  distanceKM: number;
}

export interface IServiceWithUpcomingProgram {
  service: IService;
  distanceKM: number;
  programsCount: number;
}

export interface ISessionInfo {
  date: dayjs.Dayjs;
  sessionId: number;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  serviceId: number;
  sessionSubType?: string | undefined;
  sessionType?: string | undefined;
  rollId: number;
  rollName?: string | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  programActivityType?: string | undefined;
  programActivityTypeId?: number | undefined;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
}

export interface IServiceProgram {
  programDateId: number;
  sessionId: number;
  season: string;
  year: number;
  serviceId: number;
  serviceName: string;
  rollId: number;
  rollName?: string | undefined;
  isELC: boolean;
  supplierId?: number | undefined;
  provider?: string | undefined;
  startTime?: dayjs.Dayjs | undefined;
  endTime?: dayjs.Dayjs | undefined;
  activityCode?: string | undefined;
  programDescription?: string | undefined;
  programName: string;
  date: dayjs.Dayjs;
  imageUrl?: string | undefined;
  session: ISessionInfo;
  isCasualFee: boolean;
  programPrivateDescription?: string | undefined;
}

export interface IBookingEvent {
  onlineSessionId: number;
  name: string;
  shortDescription?: string | undefined;
  description: string;
  workshopRequirements?: string | undefined;
  dateAest: dayjs.Dayjs;
  dateAcst?: dayjs.Dayjs | undefined;
  dateAwst?: dayjs.Dayjs | undefined;
  duration: dayjs.Dayjs;
  cost: number;
  imageUrl: string;
  allowBooking: boolean;
  allowCancel: boolean;
  datesText?: string | undefined;
  timeText?: string | undefined;
  durationText?: string | undefined;
}

export interface IBreakHttpResponse {
  term1: ITermHttpResponse;
  term2: ITermHttpResponse;
  firstDayOfBreak?: dayjs.Dayjs | undefined;
  lastDayOfBreak?: dayjs.Dayjs | undefined;
}

export interface ITermHttpResponse {
  termId: number;
  year: number;
  termNumber: number;
  firstDayOfTerm?: dayjs.Dayjs | undefined;
  lastDayOfTerm?: dayjs.Dayjs | undefined;
}

export interface IBookingToCancel {
  uniqueId: string;
  childId: number;
  rollId: number;
  date: dayjs.Dayjs;
  sessionStartTime: dayjs.Dayjs;
  cancellationStatusId: number;
  originalCancellationStatusId: number;
  cancelledInTimeCutoffServerTime: dayjs.Dayjs;
  feeAmount: number;
  createdAt: dayjs.Dayjs;
  isHotCancellation: boolean;
  feeType: FeeType;
}

export interface IChangeRecurringBookingRequest {
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  days: BookingDaysInfo;
  excludedDates?: dayjs.Dayjs[] | undefined;
  ageViolationReason?: string | undefined;
}

export interface IRollDetails {
  rollId: number;
  serviceId: number;
  rollName?: string | undefined;
  startTime?: Dayjs | undefined;
  endTime?: Dayjs | undefined;
  primaryFeeId?: number | undefined;
  primaryFeeAmount?: number | undefined;
  primaryFeeName?: string | undefined;
  casualFeeId?: number | undefined;
  casualFeeName?: string | undefined;
  casualFeeAmount?: number | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  rollClassificationId?: number | undefined;
  minAgeMths: number;
  maxAgeMths: number;
  ageCalculationMethod: string;
  ageCalculation: AgeCalculationMethod;
  defaultMinAgeMths: number;
  defaultMaxAgeMths: number;
  isHolidayClub: boolean;
  isOhsc: boolean;
}

export interface ISelectedChild {
  child: IChild;
  isSelected: boolean;
  reasonRequired?: boolean;
  reason?: string | null;
}

export interface IChildAgeConstraint {
  rollDate: dayjs.Dayjs;
  session: IRollDetails;
  activityCode?: string | null;
}

export interface ISelectedRollDay {
  roll: Roll;
  isMonday: boolean;
  isTuesday: boolean;
  isWednesday: boolean;
  isThursday: boolean;
  isFriday: boolean;
  isWeekly: boolean;
  isSecondMonday?: boolean;
  isSecondTuesday?: boolean;
  isSecondWednesday?: boolean;
  isSecondThursday?: boolean;
  isSecondFriday?: boolean;
}

export interface ISelectedRollDate {
  roll: Roll;
  date: dayjs.Dayjs;
}

export enum EditModelEnum {
  None,
  Cancel,
  CancelSchedule,
  ModifySchedule,
}

export interface IInlineBookingEditorModel {
  booking: Booking;
  child: IChild;
  editMode: EditModelEnum;
  reason?: string | null;
  cancellationReasonId: number;
  cancellationReasonOtherText?: string;
}

export interface ICancellableBooking {
  bookingDate: dayjs.Dayjs;
  bookingFee: number;
  cancellationStatusId: number;
  schedule: ScheduleInfo | null;
  scheduleId: string | null;
  bookingId: string | null;
  bookingRoll: string | null;
  cancellationNoticePeriod: number | null;
}

export interface IPasswordSettings {
  passwordRequiredLength: number;
  passwordRequiredUniqueChars: number;
  passwordRequireNonAlphanumeric: boolean;
  passwordRequireLowercase: boolean;
  passwordRequireUppercase: boolean;
  passwordRequireDigit: boolean;
}

export interface ISelectedServiceProgram {
  program: IServiceProgram;
  isSelected: boolean;
}

export interface ISelectedBookingEvent {
  bookingEvent: IBookingEvent;
  bookedChildren: IChild[];
  addChildren: IChild[];
  removeChildren: IChild[];
}

export interface IOnlineSessionPaymentHttpRequest {
  useOnFileDetails: boolean;
  paymentTypeId: number;
  bsb?: string | undefined;
  accountNumber?: string | undefined;
  accountName?: string | undefined;
  cardType?: string | undefined;
  cardNumber?: string | undefined;
  expireDateMonth?: number | undefined;
  expireDateYear?: number | undefined;
  holdersName?: string | undefined;
}

export interface ISaveOnlineSessionBookingRequest {
  onlineSessionId: number;
  childrenIds: number[];
}

export interface IUpdateOnlineSessionBookingsRequest {
  payment?: IOnlineSessionPaymentHttpRequest | undefined;
  bookings: ISaveOnlineSessionBookingRequest[];
}

export interface IOnlineSessionBookings {
  uniqueId: string;
  onlineSessionId: number;
  childId: number;
  createdDate: dayjs.Dayjs;
  isDeleted?: boolean | undefined;
}

export enum ConditionTypes {
  General = 1,
  Other = 2,
}

export interface IGalleryChildPhoto {
  uniqueId: string;
  uploadedAt: dayjs.Dayjs;
  title?: string | undefined;
  learningAreaName?: string | undefined;
  learningAreaDescription?: string | undefined;
}

export interface IVACBookingComplete {
  reference: string;
  children: IChild[];
  programs: IServiceProgram[];
}

export enum DebtCategoryEnum {
  CreditInvalidAddress = 16,
  Other = 22,
  ProposedExcluded = 23,
}

export interface IBookingCancellationReasonModel {
  bookingCancellationReasonId: number;
  name: string;
}
export interface IServiceProgramSummaryModel {
  serviceId: number;
  distanceKm: number;
  programsCount: number;
}

export interface VacBookingInfo {
  service: IService | Service | undefined;
  children: ISelectedChild[];
  dates: SelectedServiceProgram[];
}

export interface IBookingScheduleSummaryModel {
  uniqueId: string;
  createdAt: dayjs.Dayjs;
  bookingScheduleId: number;
  effectiveFromDate: dayjs.Dayjs;
  effectiveToDate?: dayjs.Dayjs | undefined;
  secondMonday: boolean;
  secondTuesday: boolean;
  secondWednesday: boolean;
  secondThursday: boolean;
  secondFriday: boolean;
  customerAccountId: number;
  rollId: number;
  childId: number;
  firstMonday: boolean;
  firstTuesday: boolean;
  firstWednesday: boolean;
  firstThursday: boolean;
  firstFriday: boolean;
  scheduleTypeId: number;
  childFirstName: string;
  childLastName: string;
  rollName?: string | undefined;
  sessionType?: string | undefined;
  sessionSubType?: string | undefined;
  serviceId?: number | undefined;
  serviceName?: string | undefined;
  minBookingsRequired: number;
  totalBookings: number;
}

export interface IEditRecurringBookingState {
  uniqueId: string;
  bookingScheduleId: number;
  effectiveFromDate: dayjs.Dayjs;
  effectiveToDate?: dayjs.Dayjs | undefined;
  rollId: number;
  sessionType: string;
  childId: number;
  firstMonday: boolean;
  firstTuesday: boolean;
  firstWednesday: boolean;
  firstThursday: boolean;
  firstFriday: boolean;
  serviceId: number;
}

export interface IPublicHolidayModel {
  state?: string | undefined;
  date: dayjs.Dayjs;
  description?: string | undefined;
}

export interface ICommonDatesModel {
  publicHolidays: IPublicHolidayModel[];
  paymentDueDates: dayjs.Dayjs[];
}

export interface IProgramVersionSummaryModel {
  experienceNames?: string | undefined;
  programId: number;
  serviceId: number;
  serviceName?: string | undefined;
  careTypeGroupId: number;
  programName: string;
  publishStatusId: number;
  submittedAt?: dayjs.Dayjs | undefined;
  employeeId: number;
  version: number;
  createdAt: dayjs.Dayjs;
  isCurrent: boolean;
  programVersionId: number;
  themeId?: number | undefined;
  themeName?: string | undefined;
  careTypeGroup?: string | undefined;
  weekOf?: dayjs.Dayjs | undefined;
  isTemplate: boolean;
  learningOutcomeNames?: string | undefined;
}

export interface IProgramVersionExtendedModel {
  programId: number;
  serviceId?: number | undefined;
  careTypeGroupId: number;
  isTemplate: boolean;
  programName: string;
  weekOf?: dayjs.Dayjs | undefined;
  publishStatusId: number;
  submittedAt?: dayjs.Dayjs | undefined;
  employeeId: number;
  version: number;
  createdAt: dayjs.Dayjs;
  isCurrent: boolean;
  programVersionId: number;
  publishComment?: string | undefined;
  themeId?: number | undefined;
  themeName?: string | undefined;
  careTypeGroup?: string | undefined;
  serviceName?: string | undefined;
}
export interface ILearningOutcomeSimpleModel {
  learningOutcomeId: number;
  learningOutcomeGroupId: number;
  name: string;
  groupOrder: number;
  order: number;
}
export interface ILookupItemModel {
  id: number;
  name: string;
  group?: string;
}
export interface IProgramAreaExperienceSummaryDayModel {
  dayOfWeek: number;
  date?: dayjs.Dayjs | undefined;
  content?: string | undefined;
  experienceId?: number | undefined;
  experienceVersionId?: number | undefined;
  experienceName?: string | undefined;
  experienceSummary?: string | undefined;
  theme?: string | undefined;
  duration?: string | undefined;
  experienceSourceId?: number | undefined;
  experienceSource?: string | undefined;
  experiencePublishStatusId?: number | undefined;
  learningOutcomes: ILearningOutcomeSimpleModel[];
  skills: ILookupItemModel[];
  categories: ILookupItemModel[];
}
export interface IProgramAreaExperienceSummaryModel {
  programAreaId: number;
  careTypeGroupId: number;
  name: string;
  description?: string | undefined;
  sortOrder: number;
  programId: number;
  serviceId?: number | undefined;
  programVersionId: number;
  isAllWeek: boolean;
  allowEdit?: boolean | undefined;
  canModifyBeforeWeek: boolean;
  canModifyAfterWeek: boolean;
  weekOf?: dayjs.Dayjs | undefined;
  programAreaTypeId: number;
  days: IProgramAreaExperienceSummaryDayModel[];
}

export interface IProgramZoneExperienceSummaryModel {
  slotNumber: number;
  zoneId?: number | undefined;
  zoneName?: string | undefined;
  content?: string | undefined;
}
export interface IDigitalProgramDetailsModel {
  programVersion?: IProgramVersionExtendedModel | undefined;
  areas: IProgramAreaExperienceSummaryModel[];
  zones: IProgramZoneExperienceSummaryModel[];
}

export interface ViewItemListItem {
  item_id: String;
  item_name: String;
  affiliation: String;
  index: Number;
  item_brand: String;
  item_category: String | undefined;
  item_category2?: String | undefined;
  item_category3?: String | undefined;
  item_category4?: String | undefined;
  item_category5?: String | undefined;
  item_list_id: String;
  item_list_name: String;
  item_variant: String;
  price: Number | undefined;
  quantity: Number;
}

export enum CareTypeGroupEnum {
  OSHC = 1,
  Rocketeers = 2,
}

export enum ProgramAreaTypeEnum {
  Experience = 1,
  Theme = 2,
  Text = 3,
}
