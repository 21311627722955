import { createActionCreator } from "deox";
export const termsLoading = createActionCreator("termsLoading");
export const termsLoadingError = createActionCreator("termsLoadingError");

export const termsAccepted = createActionCreator("termsAccepted", (resolve) => () => resolve());

export const termsAcceptedSaving = createActionCreator("termsAcceptedSaving");
export const termsRequired = createActionCreator("termsRequired", (resolve) => (terms: string) => resolve(terms));

export const termsAcceptedError = createActionCreator("termsAcceptedError", (resolve) => () => resolve());

export type TermsAndConditionsStatus = "Initial" | "Loading" | "Accepted" | "Errored" | "Required" | "Saving";
