import React, { useRef } from "react";

import { ButtonAccent2OutlinedRound } from "../Buttons/Buttons";

interface IUploadProps {
  onFileSelect: (file: File) => void;
  accept: string;
}
export default function FileUploader(props: IUploadProps) {
  const { onFileSelect, accept } = props;
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (event: any) => {
    onFileSelect(event.target.files[0]);
  };

  return (
    <>
      <input hidden type="file" ref={fileInput} onChange={handleFileInput} accept={accept} />
      <ButtonAccent2OutlinedRound onClick={() => fileInput?.current?.click()}>
        <strong>Upload Image</strong>
      </ButtonAccent2OutlinedRound>
    </>
  );
}
