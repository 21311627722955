import Yup from "../../../utils/yupExtensions";

const AddChildrenSchema = Yup.object().shape({
  children: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("First name is required").lengthCheck(2, 100),
      lastName: Yup.string().required("Last name is required").lengthCheck(2, 100),
    })
  ),
});

export default AddChildrenSchema;
