import React from "react";

import { makeStyles } from "tss-react/mui";

import arch3 from "../../../assets/vectors/arch-3.svg";
import cloud1 from "../../../assets/vectors/cloud-1.svg";
import cloud2 from "../../../assets/vectors/cloud-2.svg";
import event1 from "../../../assets/vectors/Handball-promo-1.svg";
import egg1 from "../../../assets/vectors/Rocketeers-promo-1.svg";
import egg2 from "../../../assets/vectors/Rocketeers-promo-2.svg";
import egg3 from "../../../assets/vectors/Rocketeers-promo-3.svg";
import cloudOshc1 from "../../../assets/vectors/YourOSHC-promo-1.svg";
import cloudOshc2 from "../../../assets/vectors/YourOSHC-promo-2.svg";
import cloudOshc3 from "../../../assets/vectors/YourOSHC-promo-3.svg";

const useStyles = makeStyles()((theme) => ({
  cloud: {
    width: 365,
    height: 365,
  },
  cloudWhite: { width: 655, height: 655 },
  arch: {
    width: 365,
    height: 365,
  },
  egg: {
    width: 600,
    height: 600,
  },
  cloudOshc: {
    width: 500,
    height: 500,
  },
}));

interface Props {
  style?: React.CSSProperties;
  classes?: any;
}
export const Arch = (props: Props) => {
  const { classes } = useStyles();
  return <img src={arch3} className={classes.arch} style={props.style} alt="Arch Vector" />;
};

export const CloudWhite = (props: Props) => {
  const { classes } = useStyles();
  return <img src={cloud1} className={classes.cloudWhite} style={props.style} alt="Cloud Vector" />;
};

export const Cloud = (props: Props) => {
  const { classes } = useStyles();
  return <img src={cloud2} className={classes.cloud} style={props.style} alt="Cloud Vector" />;
};

export const CloudOshc1 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={cloudOshc1} className={classes.cloudOshc} style={props.style} alt="Cloud Vector" />;
};

export const CloudOshc2 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={cloudOshc2} className={classes.cloudOshc} style={props.style} alt="Cloud Vector" />;
};

export const CloudOshc3 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={cloudOshc3} className={classes.cloudOshc} style={props.style} alt="Cloud Vector" />;
};

export const Egg1 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={egg1} className={classes.egg} style={props.style} alt="Egg Vector" />;
};

export const Egg2 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={egg2} className={classes.egg} style={props.style} alt="Egg Vector" />;
};

export const Egg3 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={egg3} className={classes.egg} style={props.style} alt="Egg Vector" />;
};

export const Event1 = (props: Props) => {
  const { classes } = useStyles();
  return <img src={event1} className={classes.egg} style={props.style} alt="Event Vector" />;
};
