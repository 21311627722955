import React from "react";

import styled from "@emotion/styled";
import Event from "@mui/icons-material/Event";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import { IoCallOutline, IoHomeOutline, IoImagesOutline, IoReceiptOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: #5ce5bb;
    left: 0;
    width: 4px;
  }
`;

const useStyles = makeStyles()((theme) => {
  return {
    tab: {
      color: "#8EA7AE !important",
      marginTop: "50px",
    },
    tabSelected: {
      color: "#5ce5bb !important",
    },
  };
});

interface IProps {
  onBack?: () => void;
}

function DesktopSideNav(props: IProps) {
  const { classes } = useStyles();
  var tabs = [
    { label: "Dashboard", to: "/", icon: <IoHomeOutline size={30} /> },
    { label: "Bookings", to: "/bookings", icon: <Event /> },
    { label: "Gallery", to: "/gallery", icon: <IoImagesOutline size={30} /> },
    {
      label: "Billing",
      to: "/billing",
      icon: <IoReceiptOutline size={30} />,
    },
    { label: "Your Service", to: "/your-services", icon: <IoCallOutline size={30} /> },
  ];
  var path = window.location.pathname;

  const [value, setValue] = React.useState(tabs.findIndex((t) => path.toLowerCase() === t.to.toLowerCase()));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100px",
        height: "100%",
        display: "flex",
        backgroundColor: "#1D4E5C",
        position: "fixed",
      }}
    >
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={value < 0 ? 0 : value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {tabs.map((t, index) => (
          <Tab
            key={"navTab_" + index}
            component={Link}
            classes={{ root: classes.tab, selected: classes.tabSelected }}
            label={t.label}
            sx={{ textTransform: "none", fontSize: "16px" }}
            icon={t.icon}
            to={t.to}
          />
        ))}
      </StyledTabs>
    </Box>
  );
}

export default DesktopSideNav;
