import { PasswordChangeHttpRequest, ValidationProblemDetails } from "../../parentApi";
import api from "./resetPasswordApi";
import {
  resetPasswordError,
  resetPasswordInit,
  resetPasswordPending,
  resetPasswordSuccess,
} from "./resetPasswordStateActions";
export function ResetPassword(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  currentPassword: string,
  newPassword: string
) {
  dispatch(resetPasswordPending());
  api
    .passwordChange(
      customerAccountId,
      customerContactId,
      new PasswordChangeHttpRequest({
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    )
    .then(
      function () {
        dispatch(resetPasswordSuccess());
      },
      function (error: ValidationProblemDetails) {
        if (error.errors) {
          let msg = "";
          for (let key in error["errors"]) {
            if (error["errors"].hasOwnProperty(key))
              msg += error["errors"][key] !== undefined ? error["errors"][key] : [];
          }
          dispatch(resetPasswordError(msg));
        } else {
          dispatch(resetPasswordError("An error ocurred while trying to change your password. Please try again later"));
        }
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(resetPasswordInit());
      }, 500);
    });
}

export function initRsetPassword() {
  return function (dispatch: any) {
    dispatch(resetPasswordInit());
  };
}
