import * as Yup from "yup";

const CreditCardSchema = Yup.object().shape({
  fullName: Yup.string().required("Name on Card is required"),
  cardType: Yup.string().required("Card Type is required"),
  expiry: Yup.string().required("Expiry Date is required"),
  cardNumber: Yup.string().required("Card Number is required"),
});

export default CreditCardSchema;
