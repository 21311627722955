import * as Yup from "yup";

const ChildStep6Schema = Yup.object().shape({
  hasPhysicalNeeds: Yup.bool().required("Required"),
  physicalNeeds: Yup.string().when("hasPhysicalNeeds", {
    is: (val: boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasBehaviouralNeeds: Yup.bool().required("Required"),
  behaviouralNeeds: Yup.string().when("hasBehaviouralNeeds", {
    is: (val: boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasSpecialNeeds: Yup.bool().required("Required"),
  specialNeeds: Yup.string().when("hasSpecialNeeds", {
    is: (val: boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
  hasDietaryRequirements: Yup.bool().required("Required"),
  dietaryRequirements: Yup.string().when("hasDietaryRequirements", {
    is: (val: boolean) => val === true,
    then: Yup.string().required("Required").lengthCheck(3, 500),
    otherwise: Yup.string().notRequired(),
  }),
});

export default ChildStep6Schema;
