import React, { useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

import { getWeekDays } from "../../../utils/helpers";

interface Props {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  initialDate?: dayjs.Dayjs;
  onWeekChanged?: (currentDate: dayjs.Dayjs) => void;
  disabled?: boolean;
}

function CalendarStrip(props: Props) {
  const [allDays, setWeekDays] = useState(getWeekDays(props.initialDate));

  const nextWeek = () => {
    var d = allDays[0].add(7, "day");
    setWeekDays(getWeekDays(d));
    if (props.onWeekChanged !== undefined) props.onWeekChanged(d);
  };
  const prevWeek = () => {
    var d = allDays[0].subtract(7, "day");
    setWeekDays(getWeekDays(d));
    if (props.onWeekChanged !== undefined) props.onWeekChanged(d);
  };

  const monthShort = allDays[0].format("MMM YYYY");

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="center">
        <IconButton disabled={props.disabled || props.initialDate?.isBefore(dayjs())} onClick={prevWeek}>
          <IoChevronBackOutline />
        </IconButton>
        <Box paddingRight={2} />
        <strong>Week {allDays[0].isoWeek()}</strong>
        <Box paddingLeft={2} />
        <IconButton onClick={nextWeek} disabled={props.disabled}>
          <IoChevronForwardOutline />
        </IconButton>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Box paddingBottom={3} />
        </Grid>
        <Grid item xs={3}>
          <strong>{monthShort.toUpperCase()}</strong>
        </Grid>
        <Grid item xs={9}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                Mon
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                Tue
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                Wed
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                Thu
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                Fri
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                {allDays[0].get("date")}
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                {allDays[1].get("date")}
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                {allDays[2].get("date")}
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                {allDays[3].get("date")}
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container alignItems="center" justifyContent="center">
                {allDays[4].get("date")}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CalendarStrip;
