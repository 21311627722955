import * as React from "react";
import { Fragment, useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Popover } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { List as LinqList } from "linqts";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import styles from "./Calendar.module.scss";
import CalendarNavigation from "./CalendarNavigator";
import { CancellationDialog } from "./CancellationDialog";
import BookingSchedule from "./Schedule";
import { listBookings } from "../../../store/modules/booking/bookingActions";
import { CancelBookingInitial } from "../../../store/modules/booking/bookingStateActions";
import { RootState } from "../../../store/store";
import { BookingInfo, BookingToggleType, EntityType } from "../../../types/models";
import { ICommonDatesModel, IEditRecurringBookingState } from "../../../types/types";
import dateOnlyToJson from "../../../utils/dateOnlyToJson";
import {
  getCareTypeColor,
  getCareTypeColorVariable,
  getMonthViewDates,
  getStyleColor,
  getWeekViewDates,
} from "../../../utils/helpers";
import { ButtonPrimary } from "../Buttons/Buttons";
import { AvatarDisplay } from "../Controls/AvatarDisplay";

dayjs.extend(dateOnlyToJson);

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  popover: {
    pointerEvents: "auto",
  },

  button: {
    pointerEvents: "auto",
    color: "black",
    borderWidth: 1,
    borderColor: "black",
    fontWeight: "bold",
    textTransform: "none",
  },

  editCancelButton: {
    pointerEvents: "auto",
    color: "black",
    borderWidth: 1,
    borderColor: "black",
    fontWeight: "bold",
    textTransform: "none",
    width: 170,
    height: 40,
  },

  wrap: {
    flexBasis: "100%",
  },
  gridItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: `${100 / 5}%`,
  },
  legend: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "10px",
    marginBottom: "10px",
  },
  legendItemLabel: {
    marginLeft: "10px",
  },
  calDay: {
    borderRadius: 18,
    minWidth: 40,
    "@media screen and (min-width: 1280px)": {
      minWidth: 44,
      borderRadius: 20,
    },
  },

  calDayOn: {
    borderRadius: 16,
    minWidth: 32,
    "@media screen and (min-width: 1280px)": {
      minWidth: 44,
      borderRadius: 22,
    },
  },
  calDayToday: {
    backgroundColor: getStyleColor("--color-primary"),
    borderRadius: 32,
    borderWidth: 1,
    borderColor: "black",
    borderStyle: "solid",
    minWidth: 32,
    minHeight: 32,
    "@media screen and (min-width: 1280px)": {
      minWidth: 44,
      borderRadius: 44,
      minHeight: 44,
    },
    "&:hover": {
      backgroundColor: getStyleColor("--color-accent-1"),
    },
  },
  badge: {
    zIndex: 1,
    position: "absolute",
    top: -12,
    width: 20,
    height: 20,
  },
  childName: {
    maxWidth: "100px",
    wordBreak: "break-all",
    overflowWrap: "anywhere",
  },
}));

interface ISelectedBooking {
  bookings: BookingInfo[] | undefined;
  day: number | undefined;
  isHoliday: boolean;
}
interface ICalendarProps {
  calendarMode: BookingToggleType;
  isAllowedToBook: boolean;
  allowWeekView?: boolean;
  commonDates: ICommonDatesModel;
  date: Dayjs;
  onDateChanged: (date: Dayjs) => void;
}

Calendar.propTypes = {
  trigger: PropTypes.string,
};

export function Calendar(props: ICalendarProps) {
  const dispatch = useDispatch();
  const { commonDates, date, onDateChanged } = props;
  const bookings = useSelector((state: RootState) =>
    state.booking.bookings == null ? null : state.booking.bookings.filter((b) => b.cancellationStatusId < 100)
  );
  const commonData = useSelector((state: RootState) => state.commonData);
  const { from, isLoading } = useSelector((state: RootState) => state.booking);
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id) ?? 0;
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const [isWeekView, setIsWeekView] = useState(props.allowWeekView ?? false);

  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  //Cancelation Popup
  const [cancellationPopUpOpen, setCancellationPopUpOpen] = React.useState(false);

  const [selectedBooking, setSelectedBooking] = useState<BookingInfo | undefined>();

  const [isSchedule, setIsSchedule] = React.useState(false);

  const { classes } = useStyles();
  const [selectedBookings, setSelectedBookings] = React.useState<ISelectedBooking>({
    bookings: undefined,
    day: undefined,
    isHoliday: false,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const node = React.useRef<HTMLDivElement | null>(null);

  const DAYS_OF_THE_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri"];

  useEffect(() => {
    if (date) {
      listBookings(
        dispatch,
        customer_account_id,
        dayjs(new Date(date.year(), date.month(), 1)),
        dayjs(new Date(date.year(), date.month(), date.daysInMonth()))
      );
    }
  }, [date, isWeekView]);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleCancellationPopUpOpen = () => {
    setAnchorEl(null);
    setCancellationPopUpOpen(true);
    handleClickAway();
  };

  const handleCancellationPopUpClose = () => {
    setAnchorEl(null);
    setSelectedBooking(undefined);
    setCancellationPopUpOpen(false);
    handleClickAway();
  };

  const open = Boolean(anchorEl);
  const isPaymentDueDate = (calDay: dayjs.Dayjs) => {
    return commonDates.paymentDueDates.findIndex((d) => d.isSame(calDay, "date")) >= 0;
  };

  var daysToView = isWeekView ? getWeekViewDates(date) : getMonthViewDates(date);

  return (
    <Grid item xs={12}>
      {/* CANCELLATION DIALOG WINDOW */}
      {selectedBooking && cancellationPopUpOpen && (
        <CancellationDialog
          childName={children?.find((x) => x.childId === selectedBooking.childId)?.firstName ?? ""}
          minimumDaysToAvoidCancellationFee={selectedBooking.minimumDaysToAvoidCancellationFee ?? 0}
          className={`${styles[getCareTypeColor(selectedBooking.roll?.sessionType ?? "", "Bg")]}`}
          careTypeColor={getCareTypeColor(selectedBooking.roll?.sessionType ?? "")}
          customer_account_id={customer_account_id}
          date={selectedBooking.date}
          handleClose={handleCancellationPopUpClose}
          open={cancellationPopUpOpen}
          serviceId={selectedBooking.roll!.serviceId!}
          rollName={selectedBooking.roll!.rollName!}
          schedule={
            isSchedule
              ? {
                  endDate: selectedBooking.schedule!.effectiveToDate!,
                  startDate: selectedBooking.schedule!.effectiveFromDate!,
                  scheduleId: selectedBooking.schedule!.uniqueId,
                }
              : undefined
          }
          session={
            !isSchedule
              ? {
                  bookingId: selectedBooking.uniqueId,
                  sessionDate: selectedBooking.date,
                }
              : undefined
          }
        />
      )}

      {/* CANCELLATION DIALOG WINDOW END */}
      <CalendarNavigation
        date={date}
        isWeekView={isWeekView}
        onBackClick={() => {
          onDateChanged(isWeekView ? date.subtract(1, "week") : date.subtract(1, "month"));
        }}
        onForwardClick={() => {
          onDateChanged(isWeekView ? date.add(1, "week") : date.add(1, "month"));
        }}
      />
      <Backdrop className={classes.backdrop} open={isLoading || commonData.isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      {props.calendarMode === BookingToggleType.Schedule && (
        <BookingSchedule
          id="bookingSchedule"
          isAllowedToBook={props.isAllowedToBook}
          bookings={bookings}
          isLoading={isLoading}
        />
      )}
      {props.calendarMode === BookingToggleType.Calendar && (
        <>
          <Grid container id="bookingCalendar">
            {DAYS_OF_THE_WEEK.map((d, index) => (
              <Grid item key={index} xs className={classes.gridItem}>
                <Grid container justifyContent="center" alignItems="center">
                  <strong className={styles["DayOfTheWeek"]}>{d}</strong>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* SMALL CalendarPopOver, APPEARS WHEN CALENDAR TAB CLICKED */}

          <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown" touchEvent="onTouchStart">
            <Popover
              open={open}
              anchorEl={anchorEl}
              disableScrollLock={true}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              onClose={handleClickAway}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              className={classes.popover}
            >
              <GetBookingsPopOver
                isAllowedToBook={props.isAllowedToBook}
                date={dayjs(new Date(date.year(), date.month(), selectedBookings.day)).format("ddd DD MMM")}
                bookings={selectedBookings.bookings ?? []}
                isHoliday={selectedBookings.isHoliday}
              />
            </Popover>
          </ClickAwayListener>

          <Grid container justifyContent="flex-start">
            {daysToView.map((currentDay, index) => {
              const todaysBookings = bookings?.filter(
                (b) => !b.isCancelled && dayjs(b.date ?? new Date()).isSame(currentDay, "date")
              );
              const byRoll = new LinqList<BookingInfo>(todaysBookings).GroupBy((t) =>
                t.roll?.rollClassificationId === 9 ? "PFD" : t.roll?.sessionType ?? ""
              );

              const isToday = currentDay.isSame(dayjs(), "date");
              const isSameMonth = isWeekView || currentDay.isSame(date, "month");
              const isWeekend = currentDay.day() === 0 || currentDay.day() === 6;
              const holiday = commonDates.publicHolidays.find((d) => d.date.isSame(currentDay, "date"));

              const isPaymentDate = isPaymentDueDate(currentDay);
              if (isWeekend) return null;
              return (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12 / 5}
                    ref={(el: any) => {
                      node.current = el;
                    }}
                    className={classes.gridItem}
                  >
                    <Grid container justifyContent="center" alignItems="center">
                      {isSameMonth ? (
                        <Button
                          className={
                            isToday
                              ? classes.calDayToday
                              : holiday == null && !isWeekend
                              ? classes.calDayOn
                              : classes.calDay
                          }
                          onClick={(event: any) => {
                            //setDate(calDay);
                            setSelectedBookings({
                              bookings: todaysBookings,
                              day: currentDay.date(),
                              isHoliday: holiday != null,
                            });
                            !isWeekend && handleClick(event);
                          }}
                        >
                          <Typography
                            className={
                              isToday ? styles.Today : holiday != null || isWeekend ? styles.OtherDay : styles.NormalDay
                            }
                          >
                            {currentDay.date()}
                          </Typography>
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                      {Object.keys(byRoll).length > 0 ? (
                        Object.keys(byRoll).map((roll, index) => {
                          return (
                            <Fragment key={index}>
                              {byRoll[roll].map((bk, index) => {
                                if (index === 0) {
                                  const careType =
                                    bk.roll?.rollClassificationId === 9 ? "PFD" : bk.roll?.sessionType ?? "";
                                  return (
                                    <span
                                      key={index}
                                      className={`${styles["TrianglePad"]} ${styles[getCareTypeColor(careType)]}`}
                                      title={getCareTypeColor(careType)}
                                      aria-label={getCareTypeColor(careType)}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </Fragment>
                          );
                        })
                      ) : holiday == null && !isPaymentDate && isSameMonth ? (
                        <span key={index} className={`${styles["TrianglePad"]} ${styles["Empty"]}`} />
                      ) : null}
                      {holiday != null && isSameMonth && (
                        <span
                          key={`hd-${index}`}
                          className={`${styles["TrianglePad"]} ${styles["Hl"]}`}
                          title={holiday.description ?? "Holiday"}
                          aria-label={"Holiday"}
                        />
                      )}
                      {isPaymentDate && isSameMonth && (
                        <span
                          key={`Pd-${index}`}
                          className={`${styles["TrianglePad"]} ${styles["Pd"]}`}
                          title={"Payment Due Date"}
                          aria-label={"Payment Due Date"}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {(index + 1) % 7 === 0 && <Grid item className={classes.wrap} />}
                </React.Fragment>
              );
            })}
          </Grid>
        </>
      )}
      <Box paddingTop={2} />
      {props.calendarMode === BookingToggleType.Calendar && (
        <>
          <Grid container justifyContent="flex-start" alignItems="center">
            {props.allowWeekView && (
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <ButtonPrimary
                    onClick={() => {
                      setIsWeekView(!isWeekView);
                    }}
                  >
                    {isWeekView ? <ExpandMore /> : <ExpandLess />}
                    {isWeekView ? "View Month" : "View Week"}
                  </ButtonPrimary>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} className={classes.legend}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Bsc12"]} />
                    <span className={classes.legendItemLabel}>Before School Care</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Asc12"]} />
                    <span className={classes.legendItemLabel}>After School Care</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Vac12"]} />
                    <span className={classes.legendItemLabel}>Rocketeers</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Pfd12"]} />
                    <span className={classes.legendItemLabel}>Pupil Free Day</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Hl12"]} />
                    <span className={classes.legendItemLabel}>Public Holiday</span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <span className={styles["Pd12"]} />
                    <span className={classes.legendItemLabel}>Payment Due Date</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  interface IBookingPopOverProps {
    bookings: BookingInfo[];
    date: string;
    isAllowedToBook: boolean;
    isHoliday: boolean;
  }

  //Little Window that pops up when you click a date
  function GetBookingsPopOver(props: IBookingPopOverProps) {
    const bookings = new LinqList<BookingInfo>(props.bookings);
    const bookingsByService = bookings.GroupBy((t) => t.roll?.service?.serviceName ?? "");
    const hasEvents = props.bookings && props.bookings.length > 0;
    const totalNumberOfBookings = bookings.Count();

    return (
      <Grid
        id="popover-container"
        container
        padding={2}
        spacing={2}
        sx={{
          background: "white",
        }}
      >
        <Grid item direction="column">
          {/* DATE TOP BAR */}
          <Grid item xs={12} id="dateBar">
            <strong>{props.date}</strong>
            <Box paddingBottom={1} />
          </Grid>

          {/* Booking Info - shows box for each roll/child*/}
          {hasEvents &&
            Object.keys(bookingsByService).map((svc, index) => {
              return (
                <React.Fragment key={index}>
                  <div id="bookingsByRoll">
                    <BookingsByRoll
                      bookings={bookingsByService[svc]}
                      isAllowedToBook={props.isAllowedToBook}
                      numberOfBookings={totalNumberOfBookings}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          {!hasEvents && !props.isHoliday && (
            <Grid item xs={12}>
              <Typography variant="caption">Term Date</Typography>
            </Grid>
          )}
          {props.isHoliday && (
            <Grid item xs={12}>
              <Typography variant="caption">Holiday</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  interface IBookingsByRollProps {
    bookings: BookingInfo[];
    isAllowedToBook: boolean;
    numberOfBookings: number;
  }

  //List of Bookings
  function BookingsByRoll(props: IBookingsByRollProps) {
    const bookings = new LinqList<BookingInfo>(props.bookings);
    const byRoll = bookings.GroupBy((t) => t.roll?.rollName ?? "");
    let bookingCounter = 0;

    return (
      <List
        id="careList"
        sx={{
          padding: 0,
          rowGap: 5,
          maxHeight: "100%",
          position: "relative",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {Object.keys(byRoll).map((roll, index) => {
          return byRoll[roll].map((bk, index) => {
            const child = children?.find((x) => x.childId === bk.childId) ?? null;
            const careType = bk.roll?.rollClassificationId === 9 ? "PFD" : bk.roll?.sessionType ?? "";
            const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
            bookingCounter++;

            return (
              <ListItem
                id="careList-Item"
                divider
                key={`booking-${index}`}
                alignItems="flex-start"
                sx={{ paddingLeft: 0, borderBottom: bookingCounter !== props.numberOfBookings ? "inheirt" : "none" }}
              >
                {/* Color Bar */}
                <Divider
                  orientation="vertical"
                  flexItem
                  className={`${styles[getCareTypeColor(careType, "Bg")]}`}
                  sx={{
                    width: 0.01,
                    borderRightWidth: 5,
                    height: "100px",
                    alignContent: "center",
                    margin: 1,
                    borderRadius: 5,
                  }}
                />
                <ListItemText
                  primary={
                    <Grid container direction={"column"}>
                      {/* ROLL NAME */}
                      <Typography>
                        {!bk.isHolidayClub && bk.roll
                          ? bk.roll?.rollName
                          : bk.isHolidayClub && bk.program && bk.roll
                          ? bk.program.programName
                          : "N/A"}
                      </Typography>
                      {/* CHILD NAME */}
                      {child && (
                        <Grid container direction="row" alignItems="center">
                          <AvatarDisplay info={avatar || { index: child.avatarIndex }} size={30} />
                          <Box width={10} />
                          <Typography fontSize="15px" className={classes.childName} fontWeight="bold">
                            {`${child.firstName} ${child.lastName?.substring(0, 1)}`}
                          </Typography>
                        </Grid>
                      )}
                      {/* SESSION TIMES */}
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        justifyItems={"center"}
                        fontSize={12}
                        alignContent={"center"}
                        variant="subtitle2"
                        color="text.primary"
                      >
                        {!bk.isHolidayClub &&
                          bk.roll &&
                          `${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(bk.roll.endTime).format("hh:mm a")}`}
                        {bk.isHolidayClub &&
                          bk.program &&
                          bk.roll &&
                          `${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(bk.roll.endTime).format("hh:mm a")}`}
                        {/* REPEAT ICON (DENOTING SCHEDULE) */}
                        {bk.scheduleId && <RepeatIcon style={{ marginLeft: 5, fontSize: 18 }} />}
                      </Typography>
                      {/* SERVICE NAME */}
                      <Typography variant="caption" color="black" fontSize={12}>
                        {bk.roll?.service?.serviceName?.toUpperCase()}
                      </Typography>

                      {bk.isEditable && props.isAllowedToBook && (
                        <Grid container justifyContent="center" pt={2}>
                          <CancelEditButtons
                            bookings={[bk]}
                            isAllowedToBook={props.isAllowedToBook}
                            date={bk.date.toString()}
                            isHoliday={bk.isHolidayClub}
                          />
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </ListItem>
            );
          });
        })}
      </List>
    );
  }

  function CancelEditButtons(props: IBookingPopOverProps) {
    const navigate = useNavigate();
    var currentBooking = props.bookings[0];
    const isSchedule = currentBooking.scheduleId !== undefined;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    return currentBooking != null ? (
      <>
        <Grid
          container
          spacing={1}
          id="button-row"
          sx={{ flexDirection: { xs: "column", sm: "column", lg: "row" }, flexWrap: "nowrap" }}
        >
          {isSchedule && (
            <>
              <Grid item>
                {/* EDIT BOOKING (if SCHEDULED) */}
                <ButtonPrimary
                  className={classes.editCancelButton}
                  sx={{
                    background: getCareTypeColorVariable(
                      currentBooking.roll?.rollClassificationId === 9 ? "Pfd" : currentBooking.roll?.sessionType ?? ""
                    ),
                  }}
                  onClick={(event: any) => {
                    navigate("/edit-booking", {
                      state: JSON.stringify({
                        bookingScheduleId: Number(currentBooking.scheduleId),
                        childId: currentBooking.childId,
                        effectiveFromDate: currentBooking.schedule?.effectiveFromDate,
                        effectiveToDate: currentBooking.schedule?.effectiveToDate,
                        uniqueId: currentBooking.schedule?.uniqueId,
                        rollId: currentBooking.rollId,
                        serviceId: currentBooking.roll?.serviceId,
                        sessionType: currentBooking.roll?.sessionType,
                        firstFriday: currentBooking.schedule?.firstFriday,
                        firstThursday: currentBooking.schedule?.firstThursday,
                        firstWednesday: currentBooking.schedule?.firstWednesday,
                        firstTuesday: currentBooking.schedule?.firstTuesday,
                        firstMonday: currentBooking.schedule?.firstMonday,
                        originalEffectiveFromDate: currentBooking.schedule?.effectiveFromDate,
                      } as IEditRecurringBookingState),
                    });
                  }}
                >
                  Edit Booking
                </ButtonPrimary>
              </Grid>
              <Grid item>
                <>
                  {/* CANCELLATION SUB MENU */}
                  <ButtonPrimary
                    endIcon={<KeyboardArrowDownIcon />}
                    className={classes.editCancelButton}
                    onClick={(event: any) => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    Cancel Booking
                  </ButtonPrimary>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {/* CANCEL SINGULAR BOOKING */}
                    <MenuItem
                      key="cancelSession"
                      onClick={(event: any) => {
                        setIsSchedule(false);
                        handleCancelSession();
                        handleClose();
                      }}
                    >
                      Cancel Session
                    </MenuItem>
                    {/* CANCEL SCHEDULE */}
                    <MenuItem
                      key="cancelSchedule"
                      onClick={(event: any) => {
                        setIsSchedule(true);
                        handleCancelSession();
                        handleClose();
                      }}
                    >
                      Cancel Schedule
                    </MenuItem>
                  </Menu>
                </>
              </Grid>
            </>
          )}
          {/* SHOWS ONE BUTTON IF NOT SCHEDULE */}
          {!isSchedule && (
            <Grid item>
              {/* CANCEL BUTTON - brings up CANCELATION DIALOG */}
              <ButtonPrimary
                className={classes.editCancelButton}
                onClick={(event: any) => {
                  setIsSchedule(false);
                  handleCancelSession();
                  handleClose();
                }}
              >
                Cancel Session
              </ButtonPrimary>
            </Grid>
          )}
        </Grid>
      </>
    ) : null;

    function handleCancelSession() {
      if (currentBooking != null) {
        dispatch(CancelBookingInitial());
        setSelectedBooking(currentBooking);
      }
      handleClickAway();

      handleCancellationPopUpOpen();
    }
  }
}
