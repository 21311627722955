import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { List } from "linqts";
import { NumericFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";

import theme from "../../../theme";
import { BookingEvent, Child, ChildAgeConstraint, SelectedBookingEvent, SelectedChild } from "../../../types/models";
import { getStyleColor } from "../../../utils/helpers";
import ChildSelection from "../Bookings/ChildSelection";

interface Props {
  bookingEvent: SelectedBookingEvent;
  allChildren: Child[];
  onAddChildrenChanged?: (bookingEvent: BookingEvent, children: Child[]) => void;
  onRemoveChildrenChanged?: (bookingEvent: BookingEvent, children: Child[]) => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  listItem: {
    backgroundColor: getStyleColor("--custom-color-light-grey"),
    padding: theme.spacing(2),
    borderRadius: 3,
    marginBottom: theme.spacing(1),
  },
  button: {
    color: "black",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  pill: {
    maxWidth: 60,
    "@media screen and (min-width: 1280px)": {
      maxWidth: 200,
    },
  },
  image: {
    width: 60,
    height: 45,
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "block",
    marginBottom: theme.spacing(1),
    borderRadius: 3,

    "@media screen and (min-width: 1280px)": {
      width: 200,
      height: 150,
    },
  },
}));

export default function EventSelection(props: Props) {
  const { classes } = useStyles();
  var evt = props.bookingEvent;

  var notBookedChildren = props.allChildren
    .filter(
      (c) => c !== undefined && evt.bookedChildren.findIndex((ch) => ch !== undefined && ch.childId === c.childId) < 0
    )
    .map(
      (c) =>
        new SelectedChild({
          child: c,
          isSelected: evt.addChildren.findIndex((ch) => ch !== undefined && ch.childId === c.childId) >= 0,
        })
    );

  var bookedChildren = props.allChildren
    .filter(
      (c) => c !== undefined && evt.bookedChildren.findIndex((ch) => ch !== undefined && ch.childId === c.childId) >= 0
    )
    .map(
      (c) =>
        new SelectedChild({
          child: c,
          isSelected: evt.removeChildren.findIndex((ch) => ch !== undefined && ch.childId === c.childId) >= 0,
        })
    );
  return (
    <ListItem alignItems="flex-start" className={classes.listItem}>
      <ListItemAvatar style={{ paddingRight: theme.spacing(2) }}>
        <span
          aria-label={evt.bookingEvent.name}
          role="img"
          className={classes.image}
          style={{ backgroundImage: `url(${evt.bookingEvent.imageUrl})` }}
        ></span>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h2 className="h5" style={{ marginTop: 0, marginBottom: 0 }}>
                {evt.bookingEvent.name}
              </h2>
              <div dangerouslySetInnerHTML={{ __html: evt.bookingEvent.description }}></div>
              <p>
                <strong>Dates: </strong>
                {evt.bookingEvent.datesText}
              </p>
              <p>
                <strong>Time: </strong>
                {evt.bookingEvent.timeText}
              </p>
              <p>
                <strong>Duration: </strong>
                {evt.bookingEvent.durationText}
              </p>
              <p>
                <strong>Cost: </strong>
                <NumericFormat displayType="text" value={evt.bookingEvent.cost} decimalScale={2} prefix="$" /> per child
              </p>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              {notBookedChildren && notBookedChildren.length > 0 && (
                <>
                  <Box paddingTop={2} paddingBottom={2}>
                    <strong>Add Children:</strong>
                  </Box>
                  {notBookedChildren?.map((child, index) => {
                    return (
                      <ChildSelection
                        key={"childSelectionEvent_" + child.child.childId}
                        constraints={new List<ChildAgeConstraint>([])}
                        child={child}
                        onSelectionChanged={(isAgeLess, isAgeGreater, checked) => {
                          notBookedChildren[index].isSelected = checked;
                          if (props.onAddChildrenChanged)
                            props.onAddChildrenChanged(
                              props.bookingEvent.bookingEvent,
                              notBookedChildren.filter((c) => c.isSelected).map((c) => c.child)
                            );
                        }}
                      ></ChildSelection>
                    );
                  })}
                </>
              )}
              {bookedChildren && bookedChildren.length > 0 && (
                <>
                  <Box paddingTop={2} paddingBottom={2}>
                    <strong>Remove Children:</strong>
                  </Box>
                  {bookedChildren?.map((child, index) => {
                    return (
                      <ChildSelection
                        key={"childSelectionEvent_" + child.child.childId}
                        constraints={new List<ChildAgeConstraint>([])}
                        child={child}
                        onSelectionChanged={(isAgeLess, isAgeGreater, checked) => {
                          bookedChildren[index].isSelected = checked;
                          if (props.onRemoveChildrenChanged)
                            props.onRemoveChildrenChanged(
                              props.bookingEvent.bookingEvent,
                              bookedChildren.filter((c) => c.isSelected).map((c) => c.child)
                            );
                        }}
                      ></ChildSelection>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        }
      ></ListItemText>
    </ListItem>
  );
}
