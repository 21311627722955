import React from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { FormikErrors, FormikTouched, getIn } from "formik";

import { AddressSearchResultHttpResponse } from "../../../store/parentApi";
import { State } from "../../../types/types";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { PostCodeNumberFormat } from "./NumberFormats";

interface Props {
  values: {
    uniqueId: string;
    streetLine1: string;
    suburb: string;
    state: string;
    postCode: string;
  };
  streetLineFieldName: string;
  suburbFieldName: string;
  stateFieldName: string;
  postCodeFieldName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  classes: Record<"formControl" | "textField", string>;
  states: State[];
  required: boolean;
  helperText?: any;
  error?: any;
}

export const Address = (props: Props) => {
  return (
    <>
      <AddressAutocomplete
        id="addressSearch"
        label="Search Address"
        onChange={(_event: object, values: AddressSearchResultHttpResponse | null) => {
          if (values !== null) {
            props.setFieldValue(props.streetLineFieldName, values?.streetLine1);
            props.setFieldValue(props.suburbFieldName, values?.suburb);
            props.setFieldValue(props.postCodeFieldName, values?.postalCode);
            var state = props.states.find((state) => state.fullName === values?.state);
            if (state) {
              props.setFieldValue(props.stateFieldName, state.shortName);
            }
          }
        }}
        helperText={props.helperText}
        error={props.error}
      />

      <TextField
        id="streetLine1"
        label="Street Address"
        type="text"
        value={props.values.streetLine1}
        onChange={props.handleChange(props.streetLineFieldName)}
        onBlur={props.handleBlur(props.streetLineFieldName)}
        helperText={
          getIn(props.touched, props.streetLineFieldName) ? getIn(props.errors, props.streetLineFieldName) : ""
        }
        error={
          getIn(props.touched, props.streetLineFieldName) && Boolean(getIn(props.errors, props.streetLineFieldName))
        }
        required={props.required}
        fullWidth
        className={props.classes.textField}
        inputProps={{
          maxLength: 150,
        }}
      />
      <TextField
        id="suburb"
        label="Suburb"
        type="text"
        value={props.values.suburb}
        onChange={props.handleChange(props.suburbFieldName)}
        onBlur={props.handleBlur(props.suburbFieldName)}
        helperText={getIn(props.touched, props.suburbFieldName) ? getIn(props.errors, props.suburbFieldName) : ""}
        error={getIn(props.touched, props.suburbFieldName) && Boolean(getIn(props.errors, props.suburbFieldName))}
        required={props.required}
        fullWidth
        className={props.classes.textField}
        inputProps={{
          maxLength: 40,
        }}
      />
      <TextField
        id="postCode"
        label="Postcode"
        type="text"
        value={props.values.postCode}
        onChange={props.handleChange(props.postCodeFieldName)}
        onBlur={props.handleBlur(props.postCodeFieldName)}
        helperText={getIn(props.touched, props.postCodeFieldName) ? getIn(props.errors, props.postCodeFieldName) : ""}
        error={getIn(props.touched, props.postCodeFieldName) && Boolean(getIn(props.errors, props.postCodeFieldName))}
        required={props.required}
        fullWidth
        className={props.classes.textField}
        InputProps={{
          inputComponent: PostCodeNumberFormat as any,
        }}
      />
      <FormControl variant="filled" className={props.classes.formControl} fullWidth required>
        <TextField
          select
          id="contactTypeId"
          label="State"
          value={props.values.state}
          onChange={props.handleChange(props.stateFieldName)}
          onBlur={props.handleBlur(props.stateFieldName)}
          helperText={getIn(props.touched, props.stateFieldName) ? getIn(props.errors, props.stateFieldName) : ""}
          error={getIn(props.touched, props.stateFieldName) && Boolean(getIn(props.errors, props.stateFieldName))}
          required={props.required}
          variant="filled"
          fullWidth
        >
          <MenuItem value="">Please select</MenuItem>
          {props.states.map((state) => (
            <MenuItem value={state.shortName} key={state.stateId}>
              {state.fullName}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </>
  );
};
