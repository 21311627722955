import dayjs from "dayjs";

export function isNullOrEmpty(s: string | undefined | null): boolean {
  return s === undefined || s === null || s.match(/^ *$/) !== null;
}

export function dump(entity: any): string {
  let result = "";
  for (var key in entity) {
    var keyType = typeof entity[key];
    var isDayjs = entity[key] instanceof dayjs;
    if (!isDayjs && keyType === "object") {
      result += dump(entity[key]);
    } else {
      if (entity[key] instanceof dayjs) {
        result += key + ": " + entity[key].format() + "<br/>";
      } else {
        result += key + ": " + entity[key] + "<br/>";
      }
    }
  }
  return result;
}
