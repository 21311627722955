import React, { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  dismissChildEnrolmentPrompt,
  shouldShowChildEnrolmentPrompt,
} from "../../store/modules/customer/customerActions";
import { RootState } from "../../store/store";
import { ButtonPrimary } from "../Common/Buttons/Buttons";
import { AnimatedDialog } from "../Common/Dialogs/AnimatedDialog";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  closeButton: {
    root: { fontSize: "1rem" },
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "black",
  },
}));
export default function ChildEnrolmentPrompt() {
  const { classes } = useStyles();
  const customer = useSelector((state: RootState) => state.customer).customer;
  const dispatch = useDispatch();
  const enrolmentState = useSelector((state: RootState) => state.childEnrolmentPrompt);
  const { data } = useSelector((state: RootState) => state.commonData);
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = React.useState<{ show: boolean; contact: number | undefined }>({
    show: false,
    contact: undefined,
  });

  useEffect(() => {
    if (data !== null && customer && enrolmentState.status !== "Not Required") {
      shouldShowChildEnrolmentPrompt(dispatch, customer.contacts);
    }
  }, [customer, data]);

  useEffect(() => {
    if (
      data !== null &&
      enrolmentState.status === "Required" &&
      enrolmentState.contactIds &&
      enrolmentState.contactIds.length > 0
    ) {
      setShowPrompt({ show: true, contact: enrolmentState.contactIds!.at(0) });
    } else if (
      enrolmentState.status === "Not Required" ||
      enrolmentState.status === "Dismissed" ||
      (enrolmentState.status === "Required" && enrolmentState.contactIds?.length === 0)
    ) {
      setShowPrompt({ show: false, contact: undefined });
    }
  }, [enrolmentState, data]);

  const handleClose = (contactId: number) => {
    setShowPrompt({ show: false, contact: undefined });
    setTimeout(() => {
      dismissChildEnrolmentPrompt(dispatch, contactId);
    }, 2000);
  };

  return (
    <AnimatedDialog
      open={showPrompt.show}
      PaperProps={{
        style: { border: "#b42931 2px solid" },
      }}
      fullWidth={false}
      maxWidth="sm"
    >
      <DialogTitle style={{ padding: "0" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ErrorIcon style={{ color: "#b42931", fontSize: "2rem" }} />
          <Typography>New Information Required</Typography>
        </Box>

        <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(showPrompt.contact!)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Authorization details for contact(s) are incomplete. Please click the button below to be directed to where this
        information can be provided.
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            paddingTop: "5px",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <ButtonPrimary
            style={{ backgroundColor: "#b42931", color: "white" }}
            onClick={() => navigate(`/edit-contact/${showPrompt.contact!}`)}
          >
            Review Account
          </ButtonPrimary>
        </Box>
      </DialogContent>
    </AnimatedDialog>
  );
}
