import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Fab from "@mui/material/Fab/Fab";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import dayjs from "dayjs";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import AddChildrenSchema from "./AddChildrenSchema";
import { saveChildren } from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/store";
import theme from "../../../theme";
import { AvatarInfo, EntityType } from "../../../types/models";
import { IChild } from "../../../types/types";
import { Avatars } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import { Avatar } from "../../Common/Controls/Avatar";
import Pager from "../../Common/Pager/Pager";

function FormAddChildren() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useStyles = makeStyles()((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    textField: {
      marginRight: theme.spacing(2),
    },
    addButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      backgroundColor: "white",
    },
    removeButton: {
      width: 36,
      height: 36,
      backgroundColor: "white",
      alignSelf: "flex-end",
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  }));

  const { classes } = useStyles();
  const childrenAvatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.RegistrationChild)
  );

  const storeChildren = useSelector((state: RootState) => state.register?.children);
  const storeContacts = useSelector((state: RootState) => state.register?.contacts);
  function _updateChildren(formChildren: IChild[]): void {
    const removedChildren = storeChildren.filter(
      (storeChildren) => formChildren.findIndex((c: any) => c.uniqueId === storeChildren.uniqueId) === -1
    );
    saveChildren(dispatch, formChildren, removedChildren, "/registration-pending-information");

    navigate("/registration-pending-information");
  }

  function _newChild(children: IChild[]) {
    var avatarsInUse = children.map((c) => c.avatarIndex ?? -1).concat(storeContacts.map((c) => c.avatarIndex ?? -1));
    var availableAvatarIndex = Avatars.getFirstAvailable(avatarsInUse);
    return {
      uniqueId: uuid(),
      firstName: "",
      lastName: "",
      createdAt: dayjs(),
      avatarIndex: availableAvatarIndex,
      avatarInfo: { index: availableAvatarIndex, isLoading: false },
    };
  }

  return (
    <>
      <Formik
        validationSchema={AddChildrenSchema}
        enableReinitialize={true}
        initialValues={{ children: storeChildren }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            _updateChildren(values.children);
          }, 500);
        }}
      >
        {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
          <Form>
            <FieldArray
              name="children"
              render={(arrayHelpers) => {
                if (values.children.length === 0) {
                  arrayHelpers.push(_newChild(values.children));
                }
                return (
                  <>
                    {values.children.map((child, index: number) => {
                      const childTouched = (touched.children?.length && touched.children[index]) || {};
                      const childError = (errors.children?.length && errors.children[index]) || {};
                      const childAvatar = childrenAvatars?.find((x) => x.entityId === child.uniqueId);
                      return (
                        <div key={index} style={{ marginBottom: "2rem" }}>
                          <Grid container spacing={4} alignItems="center" justifyContent="space-between">
                            <Grid item md={3}>
                              <strong>{`Child ${index + 1}`}</strong>
                            </Grid>
                            <Grid item md>
                              {index !== 0 && (
                                <Fab
                                  aria-label="add"
                                  className={classes.removeButton}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <IoRemoveOutline size={28} color={theme.palette.primary.dark} />
                                </Fab>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={4} paddingTop={1}>
                            <Grid item xs={12} md={3}>
                              <TextField
                                name={`children[${index}].firstName`}
                                label="First Name"
                                type="text"
                                value={child.firstName}
                                onChange={handleChange(`children[${index}].firstName`)}
                                onBlur={handleBlur(`children[${index}].firstName`)}
                                helperText={
                                  childTouched.firstName ? (childError as FormikErrors<IChild>).firstName : ""
                                }
                                error={
                                  childTouched.firstName && Boolean((childError as FormikErrors<IChild>).firstName)
                                }
                                fullWidth
                                className={classes.textField}
                                inputProps={{
                                  maxLength: 100,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <TextField
                                name={`children[${index}].lastName`}
                                label="Last Name"
                                type="text"
                                value={child.lastName}
                                onChange={handleChange(`children[${index}].lastName`)}
                                onBlur={handleBlur(`children[${index}].lastName`)}
                                helperText={childTouched.lastName ? (childError as FormikErrors<IChild>).lastName : ""}
                                error={childTouched.lastName && Boolean((childError as FormikErrors<IChild>).lastName)}
                                fullWidth
                                className={classes.textField}
                                inputProps={{
                                  maxLength: 100,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Avatar
                                avatarInfo={
                                  childAvatar || {
                                    entityType: EntityType.RegistrationChild,
                                    entityId: child.uniqueId!,
                                    index: child.avatarIndex,
                                  }
                                }
                                onAvatarChange={(avatarInfo: AvatarInfo) => {
                                  setFieldValue(`children[${index}].avatarIndex`, avatarInfo.index);
                                }}
                                avatarsInUse={values.children
                                  .map((c) => c.avatarIndex ?? -1)
                                  .concat(storeContacts.map((c) => c.avatarIndex ?? -1))}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    <Fab
                      aria-label="add"
                      className={classes.addButton}
                      onClick={() => {
                        arrayHelpers.push(_newChild(values.children));
                      }}
                    >
                      <IoAddOutline size={28} color={theme.palette.primary.dark} />
                    </Fab>
                    <Pager>
                      <ButtonSecondary href="/registration-add-contacts">
                        <strong>Back</strong>
                      </ButtonSecondary>
                      <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                        <strong>Next</strong>
                      </ButtonPrimary>
                    </Pager>
                    <Backdrop className={classes.backdrop} open={isSubmitting}>
                      <CircularProgress color="primary" />
                    </Backdrop>
                  </>
                );
              }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormAddChildren;
