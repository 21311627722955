import { createActionCreator } from "deox";

import { PageableBillingCycleSummary } from "../../../types/types";

export const dueChargesLoading = createActionCreator("dueChargesLoading");
export const failed = createActionCreator("failed");
export const moreDueChargesLoading = createActionCreator("moreDueChargesLoading");
export const dueChargesLoaded = createActionCreator(
  "dueChargesLoaded",
  (resolve) => (dueCharges: PageableBillingCycleSummary, pageIndex: number) => resolve(dueCharges, pageIndex)
);

export const moreDueChargesLoaded = createActionCreator(
  "moreDueChargesLoaded",
  (resolve) => (dueCharges: PageableBillingCycleSummary, pageIndex: number) => resolve(dueCharges, pageIndex)
);
