import RepeatIcon from "@mui/icons-material/Repeat";
import WarningIcon from "@mui/icons-material/Warning";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { NumericFormat } from "react-number-format";

import { SelectedRollDay } from "../../../types/models";
import { FeeType, IBookingInfo3 } from "../../../types/types";

interface IProps {
  existingBookings: {
    [key: string]: IBookingInfo3[];
  };
  isFriendlyFee: boolean;
  roll: SelectedRollDay;
  weekDays: string;
}

export default function EditScheduleExistingBookings(props: IProps) {
  const { existingBookings, roll, weekDays } = props;

  return (
    <List disablePadding={true} sx={{ p: 1 }}>
      {Object.keys(existingBookings).map((feeType, index) => {
        const allAvBookings = existingBookings[feeType];
        return (
          <ListItem
            disableGutters
            disabled={true}
            alignItems="center"
            secondaryAction={
              <Stack direction="row" alignItems="center">
                <WarningIcon sx={{ fontSize: "1rem" }} fontSize="small" htmlColor="gray" />

                <Typography
                  variant="subtitle2"
                  sx={{
                    wordBreak: "break-word",
                    fontSize: {
                      xl: "0.8rem",
                      lg: "0.7rem",
                      md: "0.6rem",
                      sm: "0.5rem",
                      xs: "0.5rem",
                    },
                  }}
                >
                  Existing Bookings
                </Typography>
              </Stack>
            }
          >
            <ListItemAvatar sx={{ minWidth: "35px" }}>
              <RepeatIcon fontSize="small" />
            </ListItemAvatar>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography variant="subtitle2" fontWeight="bold">
                  {roll.roll.rollName}
                  {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                </Typography>
              }
              secondary={
                <Stack direction="column">
                  {feeType !== FeeType.Casual && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: {
                          xl: "1rem",
                          lg: "0.9rem",
                          md: "0.9rem",
                          sm: "0.7rem",
                          xs: "0.7rem",
                        },
                      }}
                    >
                      Occurs every {weekDays}
                    </Typography>
                  )}
                  <Typography variant="subtitle2">
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={`${allAvBookings.length} x $`}
                        value={allAvBookings[0].fee}
                        suffix="*"
                      />
                    </span>
                  </Typography>
                </Stack>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
