// import Autocomplete from "@mui/lab/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";

import { AddressSearchResultHttpResponse } from "../../store/parentApi";
import { RootState } from "../../store/store";
import { Address } from "../Common/Controls/Address";
import { AddressAutocomplete } from "../Common/Controls/AddressAutocomplete";
import { PhoneNumberFormat } from "../Common/Controls/NumberFormats";

interface Props {
  classes: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  firstChild: any;
}

function MedicalContactInformation({
  classes,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  values,
  errors,
  firstChild,
}: Props) {
  const { states } = useSelector((state: RootState) => state?.commonData?.data);

  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Medical Contact Information</legend>
          {values.firstChildHasMedicalContact && (
            <Container className={classes.container}>
              <FormControlLabel
                id="sameMedicalContactAsFirstChild"
                control={<Checkbox />}
                label={`Same as ${firstChild.firstName} ${firstChild.lastName}`}
                checked={values.sameMedicalContactAsFirstChild}
                onChange={(e, checked) => {
                  if (checked) {
                    setFieldValue("medicalServiceName", firstChild.medicalServiceName);
                    setFieldValue("doctorName", firstChild.doctorName);
                    setFieldValue("doctorPhone", firstChild.doctorPhone);
                    setFieldValue("sameMedicalContactAsFirstChild", true);

                    if (values.uniqueId) {
                      setFieldValue("streetLine1", firstChild.streetLine1);
                      setFieldValue("suburb", firstChild.suburb);
                      setFieldValue("state", firstChild.state);
                      setFieldValue("postCode", firstChild.postCode);
                    } else {
                      setFieldValue("doctorAddress", firstChild.doctorAddress);
                    }
                  } else {
                    setFieldValue("sameMedicalContactAsFirstChild", false);
                  }
                }}
              />
              <Box paddingBottom={3} />
            </Container>
          )}
          <Autocomplete
            id="medicareNumber"
            freeSolo
            disableClearable
            value={values.medicareNumber || ""}
            onBlur={handleBlur("medicareNumber")}
            onChange={(event: any, newValue: any | null) => {
              setFieldValue("medicareNumber", newValue);
            }}
            options={["N/A"]}
            fullWidth
            renderInput={(params) => (
              <TextField
                onChange={handleChange("medicareNumber")}
                helperText={touched.medicareNumber ? errors.medicareNumber : ""}
                error={touched.medicareNumber && Boolean(errors.medicareNumber)}
                {...params}
                label="Medicare Number *"
                margin="normal"
                variant="standard"
                onBlur={handleBlur("medicareNumber")}
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            )}
          />

          {!values.sameMedicalContactAsFirstChild && (
            <div>
              <TextField
                id="ambulanceNumber"
                label="Ambulance Insurance Provider Number"
                type="text"
                value={values.ambulanceNumber || ""}
                onChange={handleChange("ambulanceNumber")}
                onBlur={handleBlur("ambulanceNumber")}
                helperText={touched.ambulanceNumber ? errors.ambulanceNumber : ""}
                error={touched.ambulanceNumber && Boolean(errors.ambulanceNumber)}
                fullWidth
                className={classes.textField}
                InputProps={{
                  inputComponent: PhoneNumberFormat as any,
                }}
              />
              <TextField
                id="medicalServiceName"
                label="Medical Service Name *"
                type="text"
                value={values.medicalServiceName || ""}
                onChange={handleChange("medicalServiceName")}
                onBlur={handleBlur("medicalServiceName")}
                helperText={touched.medicalServiceName ? errors.medicalServiceName : ""}
                error={touched.medicalServiceName && Boolean(errors.medicalServiceName)}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                id="doctorName"
                label="Doctor's Name *"
                type="text"
                value={values.doctorName || ""}
                onChange={handleChange("doctorName")}
                onBlur={handleBlur("doctorName")}
                helperText={touched.doctorName ? errors.doctorName : ""}
                error={touched.doctorName && Boolean(errors.doctorName)}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <TextField
                id="doctorPhone"
                label="Doctor's / Medical Service Phone *"
                type="text"
                value={values.doctorPhone || ""}
                onChange={handleChange("doctorPhone")}
                onBlur={handleBlur("doctorPhone")}
                helperText={touched.doctorPhone ? errors.doctorPhone : ""}
                error={touched.doctorPhone && Boolean(errors.doctorPhone)}
                fullWidth
                className={classes.textField}
                InputProps={{
                  inputComponent: PhoneNumberFormat as any,
                }}
              />
            </div>
          )}
        </FormGroup>
      </Grid>
      {!values.sameMedicalContactAsFirstChild && (
        <Grid item xs={12} md={6}>
          <legend className={classes.formLegend}>Medical Contact Address</legend>
          {values.uniqueId && (
            <Address
              values={{
                uniqueId: values.addressUniqueId,
                streetLine1: values.doctorAddress,
                suburb: values.doctorSuburb,
                state: values.doctorState,
                postCode: values.doctorPostcode,
              }}
              streetLineFieldName={"doctorAddress"}
              suburbFieldName={"doctorSuburb"}
              stateFieldName={"doctorState"}
              postCodeFieldName={"doctorPostcode"}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              classes={classes}
              states={states}
              required={true}
              helperText={null}
              error={null}
            />
          )}
          {values.childId && (
            <div>
              <AddressAutocomplete
                id="addressSearch"
                label="Search Address"
                onChange={(_event: object, values: AddressSearchResultHttpResponse | null) => {
                  if (values !== null) {
                    setFieldValue("doctorAddress", values?.formattedAddress);
                  }
                }}
                helperText={touched.doctorAddress ? errors.doctorAddress : ""}
                error={touched.doctorAddress && Boolean(errors.doctorAddress)}
              />
              <TextField
                id="doctorAddress"
                label="Doctor's / Medical Service Address *"
                type="text"
                value={values.doctorAddress || ""}
                onChange={handleChange("doctorAddress")}
                onBlur={handleBlur("doctorAddress")}
                helperText={touched.doctorAddress ? errors.doctorAddress : ""}
                error={touched.doctorAddress && Boolean(errors.doctorAddress)}
                fullWidth
                className={classes.textField}
              />
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default MedicalContactInformation;
