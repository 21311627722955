import React, { ChangeEvent } from "react";

import { makeStyles } from "tss-react/mui";

import styles from "./StyledRadio.module.scss";

interface Props {
  checked?: boolean;
  id?: string;
  value?: string | ReadonlyArray<string> | number;
  width?: number | string;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  group?: string;
  itemKey: string;
}

function StyledRadio(props: Props) {
  const useStyles = makeStyles()((theme) => ({
    primary: {
      minWidth: props.width ?? 200,
    },
  }));
  const { classes } = useStyles();

  return (
    <label key={props.itemKey} className={`${styles["StyledRadio"]} ${classes.primary}`} id={props.id}>
      <input type="radio" checked={props.checked} value={props.value} onChange={props.onChange} name={props.group} />
      <span className={styles["StyledRadio-label"]}>{props.label}</span>
    </label>
  );
}

export default StyledRadio;
