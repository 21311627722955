import { combineReducers } from "redux";

import accountBalanceReducer from "./modules/accountBalance/accountBalanceReducer";
import appConfigsReducer from "./modules/appConfigs/appConfigsReducer";
import appStatusReducer from "./modules/appStatus/appStatusReducer";
import authReducer from "./modules/auth/authReducer";
import avatarsReducer from "./modules/avatar/avatarReducer";
import bookingCancellationReasonsReducer from "./modules/booking/bookingCancellationReasonsReducer";
import bookingCancelReducer from "./modules/booking/bookingCancelReducer";
import bookingCreateRecurringPreviewReducer from "./modules/booking/bookingCreateRecurringPreviewReducer";
import bookingCreateReducer from "./modules/booking/bookingCreateReducer";
import bookingReducer from "./modules/booking/bookingReducer";
import bookingSaveCasualPreviewReducer from "./modules/booking/bookingSaveCasualPreviewReducer";
import bookingSaveRecurringPreviewReducer from "./modules/booking/bookingSaveRecurringPreviewReducer";
import bookingSaveRecurringReducer from "./modules/booking/bookingSaveRecurringReducer";
import bookingOnHoldReducer from "./modules/bookingOnHoldMessage/bookingOnHoldMessageReducer";
import changeLoginReducer from "./modules/changeLogin/changeLoginReducer";
import childReducer from "./modules/child/childReducer";
import commonDataReducer from "./modules/common/commonReducer";
import cryptoTokenReducer from "./modules/cryptoToken/cryptoTokenReducer";
import childEnrolmentReducer from "./modules/customer/childEnrolnmentPrompt/childEnrolmentReducer";
import customerReducer from "./modules/customer/customerReducer";
import digitalProgramReducer from "./modules/digitalProgram/digitalProgramReducer";
import documentReducer from "./modules/document/documentReducer";
import dueChargesReducer from "./modules/dueCharges/dueChargesReducer";
import eventsReducer from "./modules/events/eventsReducer";
import newUserReducer from "./modules/newUser/newUserReducer";
import notificationSettingsReducer from "./modules/notification/notificationReducer";
import nswVoucherReducer from "./modules/nswVoucher/nswVoucherReducer";
import overDueReducer from "./modules/overDue/overDueReducer";
import passwordSettingsReducer from "./modules/passwordSettings/passwordSettingsReducer";
import pastChargesReducer from "./modules/pastCharges/pastChargesReducer";
import billingDueDateReducer from "./modules/payment/billingDueDateReducer";
import paymentReducer from "./modules/payment/paymentReducer";
import paymentMethodReducer from "./modules/paymentMethod/paymentMethodReducer";
import privacySettingsReducer from "./modules/privacy/privacyReducer";
import programServicesReducer from "./modules/program/programServicesReducer";
import serviceProgramsReducer from "./modules/program/serviceProgramsReducer";
import serviceProgramSummaryReducer from "./modules/program/serviceProgramSummaryReducer";
import registerReducer from "./modules/register/registerReducer";
import resetPasswordReducer from "./modules/resetPassword/resetPasswordReducer";
import schoolReducer from "./modules/school/schoolReducer";
import breaksReducer from "./modules/service/serviceBreaksReducer";
import servicesReducer from "./modules/service/servicesReducer";
import termDatesReducer from "./modules/service/termDatesReducer";
import nextAvailableSessionReducer from "./modules/session/nextAvailableSessionDateReducer";
import sessionReducer, { unAvailableDatesReducer } from "./modules/session/sessionReducer";
import signupReducer from "./modules/signup/signupReducer";
import statementReducer from "./modules/statementReport/statementReportReducer";
import termsAndConsitionsReducer from "./modules/termsAndConditions/termsAndConditionsReducer";
import raiseQueryReducer from "./modules/transactionQuery/transactionQueryReducer";
import upcomingChargesReducer from "./modules/upcomingCharges/upcomingChargesReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  customer: customerReducer,
  register: registerReducer,
  services: servicesReducer,
  child: childReducer,
  commonData: commonDataReducer,
  booking: bookingReducer,
  school: schoolReducer,
  session: sessionReducer,
  unAvailableDates: unAvailableDatesReducer,
  signup: signupReducer,
  upcomingCharges: upcomingChargesReducer,
  pastCharges: pastChargesReducer,
  dueCharges: dueChargesReducer,
  balance: accountBalanceReducer,
  raiseQuery: raiseQueryReducer,
  payment: paymentReducer,
  termDates: termDatesReducer,
  paymentMethod: paymentMethodReducer,
  appConfigs: appConfigsReducer,
  statementReport: statementReducer,
  recurringBookingPreview: bookingCreateRecurringPreviewReducer,
  createBooking: bookingCreateReducer,
  document: documentReducer,
  resetPassword: resetPasswordReducer,
  changeLogin: changeLoginReducer,
  privacySettings: privacySettingsReducer,
  notificationSettings: notificationSettingsReducer,
  programServices: programServicesReducer,
  servicePrograms: serviceProgramsReducer,
  termsAndConsitions: termsAndConsitionsReducer,
  avatars: avatarsReducer,
  breaks: breaksReducer,
  nextAvailableSession: nextAvailableSessionReducer,
  bookingSaveRecurringPreview: bookingSaveRecurringPreviewReducer,
  bookingSaveRecurring: bookingSaveRecurringReducer,
  bookingCancel: bookingCancelReducer,
  passwordSettings: passwordSettingsReducer,
  overDueBill: overDueReducer,
  bookingEvents: eventsReducer,
  newUser: newUserReducer,
  bookingCancellationReason: bookingCancellationReasonsReducer,
  nswVoucher: nswVoucherReducer,
  bookingSaveCasualPreview: bookingSaveCasualPreviewReducer,
  childEnrolmentPrompt: childEnrolmentReducer,
  billingDueDate: billingDueDateReducer,
  serviceProgramSummary: serviceProgramSummaryReducer,
  bookingOnHold: bookingOnHoldReducer,
  digitalProgram: digitalProgramReducer,
  cryptoToken: cryptoTokenReducer,
  appStatus: appStatusReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
