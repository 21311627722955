import apiPayment from "./dueChargesApi";
import {
  dueChargesLoaded,
  dueChargesLoading,
  failed,
  moreDueChargesLoaded,
  moreDueChargesLoading,
} from "./dueChargesStateActions";

export function loadDueCharges(dispatch: any, customerAccountId: number, pageSize: number, pageIndex: number) {
  dispatch(dueChargesLoading());
  apiPayment.getBillingCycles(customerAccountId, pageIndex, pageSize, true).then(
    (charges: any) => dispatch(dueChargesLoaded(charges, pageIndex)),
    function (error: any) {
      return dispatch(failed());
    }
  );
}

export function loadMoreDueCharges(dispatch: any, customerAccountId: number, pageSize: number, pageIndex: number) {
  dispatch(moreDueChargesLoading());
  apiPayment.getBillingCycles(customerAccountId, pageIndex, pageSize, true).then(
    (charges: any) => dispatch(moreDueChargesLoaded(charges, pageIndex)),
    function (error: any) {
      return dispatch(failed());
    }
  );
}
