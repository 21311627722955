import { BreakHttpResponse, TermHttpResponse } from "../../parentApi";
import apiService from "./servicesApi";
import {
  breakDatesError,
  breakDatesSuccess,
  servicesError,
  servicesLoading,
  servicesSuccess,
  termDatesError,
  termDatesSuccess,
} from "./servicesStateActions";

export function getServices(dispatch: any, customerAccountId: number) {
  dispatch(servicesLoading());
  apiService.getServicesByCustomer(customerAccountId).then(
    (services: any) => dispatch(servicesSuccess(services)),
    (error: any) => dispatch(servicesError())
  );
}

export function getTermDates(dispatch: any, serviceId: number) {
  apiService.getTermsByServiceId(serviceId).then(
    (termDates: TermHttpResponse[] | null) => dispatch(termDatesSuccess(termDates ?? [])),
    (error: any) => dispatch(termDatesError())
  );
}

export function getBreaks(dispatch: any, serviceId: number) {
  apiService.getBreaksByServiceId(serviceId).then(
    (breaks: BreakHttpResponse[] | null) => dispatch(breakDatesSuccess(breaks ?? [])),
    (error: any) => dispatch(breakDatesError())
  );
}
