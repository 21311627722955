import dayjs from "dayjs";

import apiPayment from "./paymentMethodApi";
import {
  paymentMethodError,
  paymentMethodLoading,
  paymentMethodSaved,
  paymentMethodSaveError,
  paymentMethodSaveInit,
  paymentMethodSaving,
  paymentMethodSuccess,
} from "./paymentMethodStateActions";
import {
  IBankInfoHttpRequest,
  ICreatePaymentMethodHttpResponse,
  ICreditCardInfoHttpRequest,
  PaymentType,
} from "../../../types/types";
import {
  AddBankPaymentMethodHttpRequest,
  AddCreditCardPaymentMethodHttpRequest,
  BankInfoHttpRequest,
  CreditCardInfoHttpRequest,
} from "../../parentApi";

export function getCurrentPaymentMethod(dispatch: any, customerAccountId: number) {
  dispatch(paymentMethodLoading());
  apiPayment.getCustomerCurrentPaymentMethod(customerAccountId).then(
    (method: any) => {
      if (method.paymentMethodId !== undefined) {
        dispatch(paymentMethodSuccess(method));
      } else {
        dispatch(paymentMethodSuccess(null));
      }
    },
    function (error: any) {
      dispatch(paymentMethodError());
    }
  );
}

export function addBankPaymentMethod(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  bankInfo: IBankInfoHttpRequest
) {
  dispatch(paymentMethodSaving());
  apiPayment
    .addBankPaymentMethod(
      customerAccountId,
      new AddBankPaymentMethodHttpRequest({
        customerContactId: customerContactId,
        bank: new BankInfoHttpRequest({
          accountName: bankInfo.accountName,
          accountNumber: bankInfo.accountNumber,
          bsb: bankInfo.bsb,
        }),
      })
    )
    .then(
      function (value: ICreatePaymentMethodHttpResponse | null) {
        if (value) {
          if (value.isSuccess) {
            dispatch(paymentMethodSaved(value));
            dispatch(
              paymentMethodSuccess({
                customerAccountId: customerAccountId,
                paymentMethodId: value.paymentMethodId,
                accountName: bankInfo.accountName,
                masked: bankInfo.accountNumber.substring(bankInfo.accountNumber.length - 4),
                isCurrentPaymentMethod: true,
                paymentType: PaymentType.BankAccount,
                paymentTypeName: "Bank Account",
                paymentMethodCreated: dayjs(),
              })
            );
          } else {
            dispatch(paymentMethodSaveError(value.result ? value.result : ""));
          }
        } else {
          dispatch(
            paymentMethodSaveError("An error ocurred while adding the new payment method. Please try again later")
          );
        }
      },

      function (error: any) {
        dispatch(
          paymentMethodSaveError("An error ocurred while adding the new payment method. Please try again later")
        );
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(paymentMethodSaveInit());
      }, 500);
    });
}

export function addCardPaymentMethod(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  cardInfo: ICreditCardInfoHttpRequest
) {
  dispatch(paymentMethodSaving());
  apiPayment
    .addCreditCardMethod(
      customerAccountId,
      new AddCreditCardPaymentMethodHttpRequest({
        customerContactId: customerContactId,
        creditCard: new CreditCardInfoHttpRequest({
          month: cardInfo.month,
          name: cardInfo.name,
          number: cardInfo.number,
          year: cardInfo.year,
          cvc: cardInfo.cvc,
        }),
      })
    )
    .then(
      function (value: ICreatePaymentMethodHttpResponse | null) {
        if (value) {
          if (value.isSuccess) {
            dispatch(paymentMethodSaved(value));
            dispatch(
              paymentMethodSuccess({
                customerAccountId: customerAccountId,
                paymentMethodId: value.paymentMethodId,
                expiryMonth: cardInfo.month,
                expiryYear: cardInfo.year,
                masked: cardInfo.number.substring(cardInfo.number.length - 4),
                isCurrentPaymentMethod: true,
                paymentType: PaymentType.CreditCard,
                paymentTypeName: "Credit Card",
                paymentMethodCreated: dayjs(),
              })
            );
          } else {
            dispatch(paymentMethodSaveError(value.result ? value.result : ""));
          }
        } else {
          dispatch(
            paymentMethodSaveError("An error ocurred while adding the new payment method. Please try again later")
          );
        }
      },

      function (error: any) {
        dispatch(
          paymentMethodSaveError("An error ocurred while adding the new payment method. Please try again later")
        );
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(paymentMethodSaveInit());
      }, 500);
    });
}
