import { createReducer } from "deox";

import { TermDate } from "../../../types/types";
import { termDatesSuccess, termDatesError } from "./servicesStateActions";

export interface TermDateState {
  termDatesByServiceId: TermDate[];
  isLoadingTermDates: boolean;
}

export const initialState: TermDateState = {
  termDatesByServiceId: [],
  isLoadingTermDates: false,
};

const termDatesReducer = createReducer(initialState, (handleAction) => [
  handleAction(termDatesSuccess, (state, action) => ({
    ...state,
    isLoadingTermDates: false,
    termDatesByServiceId: action.payload,
  })),
  handleAction(termDatesError, (state) => ({
    ...state,
    isLoadingTermDates: false,
    termDatesByServiceId: new Array<TermDate>(),
  })),
]);

export default termDatesReducer;
