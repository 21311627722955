import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";

const emailRe =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

yup.addMethod<yup.StringSchema>(yup.string, "validEmail", function () {
  return this.trim().matches(emailRe, "Please enter valid email");
});

yup.addMethod<yup.StringSchema>(yup.string, "lengthCheck", function (min, max) {
  return this.trim().min(min, `Minimum of ${min} characters`).max(max, `Maximum of ${max} characters`);
});

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    lengthCheck(min: number, max: number): StringSchema<TType, TContext>;
    validEmail(): StringSchema<TType, TContext>;
  }
}

export default yup;
