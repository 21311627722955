import React from "react";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { ButtonPrimary } from "../Buttons/Buttons";
import { AnimatedDialog } from "./AnimatedDialog";

export interface IConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  children?: React.ReactNode;
  title: string;
}
const ConfirmDialog = (props: IConfirmDialogProps): JSX.Element => (
  <AnimatedDialog open={props.open} title={props.title} onClose={props.onClose}>
    <DialogContent>{props.children}</DialogContent>
    <DialogActions>
      <ButtonPrimary onClick={() => props.onClose()}>No</ButtonPrimary>

      <ButtonPrimary
        onClick={() => {
          props.onConfirm();
        }}
      >
        Yes
      </ButtonPrimary>
    </DialogActions>
  </AnimatedDialog>
);

export default ConfirmDialog;
