import apiPayment from "./accountBalanceApi";
import { balanceError, balanceLoading, balanceSuccess } from "./accountBalanceStateActions";

export function getAccountBalance(dispatch: any, customerAccountId: number) {
  dispatch(balanceLoading());
  apiPayment.getAccountBalance(customerAccountId).then(
    (balance: any) => {
      dispatch(balanceSuccess(balance));
    },
    function (error: any) {
      return dispatch(balanceError());
    }
  );
}
