import ToggleButton from "@mui/material/ToggleButton/ToggleButton";
import styled from "@mui/styles/styled";
const CAToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "black",
    backgroundColor: "#5CE5BB!important",
  },
  outlineColor: "black",
  outlineWidth: "1px",
  outlineStyle: "solid",
  margin: "2px",
  borderRadius: "10px",
});

export default CAToggleButton;
