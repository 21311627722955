import { CustomerAccount } from "../../../types/types";
import { hideOverDue, showOverDue } from "./overDueStateActions";

export type OverDueStatus = "Initial" | "Show" | "Hide";
export enum DebtCategory {
  ProposedExcluded = 23,
  Other = 22,
}
export function shouldShowOverDue(dispatch: any, customer: CustomerAccount, status: OverDueStatus) {
  if (status === "Initial") {
    if (
      customer?.isExcluded ||
      customer?.debtCategoryId === DebtCategory.Other ||
      customer?.debtCategoryId === DebtCategory.ProposedExcluded
    ) {
      dispatch(showOverDue());
    }
  } else if (
    status === "Show" &&
    (customer?.isExcluded || customer?.debtCategoryId === DebtCategory.ProposedExcluded)
  ) {
    dispatch(showOverDue());
  } else {
    dispatch(hideOverDue());
  }
}
