import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";

import { RootState, store } from "../store/store";

dayjs.extend(dayjsPluginUTC);

function getRootState(): RootState {
  return store.getState();
}

export const app_uri: string = window.__config.API_URL;

const axiosClient = (): AxiosInstance => {
  const axiosClient = axios.create();
  axiosRetry(axios, { retries: 2, retryDelay: axiosRetry.exponentialDelay });

  function doConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    const token = getRootState().auth.user?.access_token;
    config.headers = {
      "Content-Type": config.data instanceof FormData ? "multipart/form-data" : "application/json",
      "Access-Control-Allow-Origin": process.env.NODE_ENV === "development" ? "*" : "*.campaustralia.com.au",
      Authorization: "Bearer " + token,
    };
    return config;
  }

  axiosClient.interceptors.request.use((config) => doConfig(config));

  return axiosClient;
};

export default axiosClient;
