import { Route, Routes } from "react-router-dom";

import SignIn from "../pages/Auth/SignIn";
import SignInOidc from "../pages/Auth/SignInOidc";
import SignOutOidc from "../pages/Auth/SignOutOidc";
import SigninSilentOidc from "../pages/Auth/SilentRenewOidc";
import CheckEmail from "../pages/CheckEmail/CheckEmail";
import ConfirmEmail from "../pages/ConfirmEmail/ConfirmEmail";
import Home from "../pages/Home/Home";
import ResetPassword from "../pages/ResetPassword/ResetPassword";

function UnAuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/account/check-email" element={<CheckEmail />}></Route>
      <Route path="/signout-oidc" element={<SignOutOidc />}></Route>
      <Route path="/signin-oidc" element={<SignInOidc />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      <Route path="/silent-renew-oidc" element={<SigninSilentOidc />}></Route>
      <Route path="/account/forgot-password" element={<ResetPassword />}></Route>
      <Route path="/account/confirm-email" element={<ConfirmEmail />}></Route>
      <Route path="/" element={<Home />}></Route>
      <Route path="/account/register-contacts/" element={<Home />}></Route>
    </Routes>
  );
}

export default UnAuthenticatedRoutes;
