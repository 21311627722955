import { useEffect } from "react";

import { signinRedirect } from "../../store/modules/auth/authServices";

function SignIn() {
  useEffect(() => {
    signinRedirect();
  });

  return null;
}

export default SignIn;
