import { useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { ErrorMessage, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { makeStyles } from "tss-react/mui";

import ChildStep9chema from "./ChildStep9Schema";
import * as actions from "../../../store/modules/register/registerActions";
import { IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep9({ child, id }: Props) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [pad, setPad] = useState<SignaturePad | null>(null);

  function updateChild(values: IChild) {
    actions.saveChild(dispatch, values);
    navigate("/registration-pending-information");
  }
  var initialValues = {
    authorize: false,
    informationIsTrue: false,
    signature: "",
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={ChildStep9chema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild({ ...child, ...values });
        }, 500);
      }}
    >
      {({ handleChange, submitForm, isSubmitting, values, handleBlur, setFieldValue, touched, errors }) => (
        <Form>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={values.authorize ?? false}
                        onChange={(evt, checked) => setFieldValue("authorize", checked)}
                        name="authorize"
                        color="primary"
                      />
                    }
                    label="I declare as the child's parent/person named in the form as authorised to consent to the medical treatment of the child, authorise for the Approved provider, Nominated Supervisor or an Educator to seek medical treatment for the child from a registered medical practitioner, hospital or ambulance service and transportation of the child by an ambulance and/or obtain all necessary medical assistance and treatment for the child and agree to meet any expenses attached to such treatment."
                  />
                  <ErrorMessage name="authorize">
                    {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={values.informationIsTrue ?? false}
                        onChange={(evt, checked) => setFieldValue("informationIsTrue", checked)}
                        name="informationIsTrue"
                        color="primary"
                      />
                    }
                    label="I declare as the person with parental authority of the child referred to on this page that the information provided is true and correct and undertake to immediately inform the children's service in the event of any change to this information."
                  />
                  <ErrorMessage name="informationIsTrue">
                    {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <strong>Signature</strong>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ border: "1px solid black" }}>
                    <SignaturePad
                      options={{ backgroundColor: "rgb(255,255,255)" }}
                      ref={(ref) => {
                        if (ref == null) return;
                        ref.instance.addEventListener("endStroke", () => {
                          setFieldValue("signature", ref!.toDataURL());
                        });
                        setPad(ref);
                      }}
                    />
                  </Box>
                  <ErrorMessage name="signature">
                    {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-start" alignContent="center">
                    <Link
                      sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                      onClick={() => {
                        pad?.clear();
                        setFieldValue("signature", null);
                      }}
                    >
                      <RefreshIcon /> Clear Signature
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Pager>
            <ButtonSecondary href={`/registration-child-step/8/${id}`}>
              <strong>Back</strong>
            </ButtonSecondary>
            <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
              <strong>Next</strong>
            </ButtonPrimary>
          </Pager>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}

export default FormChildStep9;
