import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid/Grid";
import { List } from "linqts";
import { IoCheckmarkOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";
import theme from "../../theme";
import { Child, EntityType } from "../../types/models";
import { BookingPreview, IService, IServiceProgram, ISessionAvailability } from "../../types/types";
import { getStyleColor } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface Props {
  child: Child;
  programs: List<IServiceProgram>;
  datesUnAvailable: List<ISessionAvailability>;
  service: IService;
  isFriendlyFee: boolean;
  previews: BookingPreview[];
}
const ChildVACBookingCard = (props: Props) => {
  var availableBookings = props.programs.Where(
    (p) =>
      !props.datesUnAvailable.Any(
        (ud) =>
          ud !== undefined &&
          ud?.rollId === p?.rollId &&
          ud?.date.isSame(p?.date, "date") &&
          !ud.isAvailableForCasualBooking
      )
  );

  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Child && x.entityId === props.child.childId?.toString()
    )
  );

  const serviceName = props.service.serviceName || "";

  return (
    <Grid item xs={12}>
      <Card
        style={{ backgroundColor: getStyleColor("--custom-color-light-grey") }}
        elevation={0}
        key={"ChildVACBookingCard_" + props.child.childId}
      >
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <span style={{ marginRight: 10 }}>
                  <AvatarDisplay info={childAvatar ?? { index: props.child.avatarIndex }} />
                </span>
                <strong>
                  {props.child.firstName} {props.child.lastName}
                </strong>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span className="color-dark-grey">{serviceName.toUpperCase()}</span>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {availableBookings
                  .Select((p, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <IoCheckmarkOutline
                              fontSize={24}
                              color={theme.palette.success.main}
                              style={{ marginRight: 10 }}
                            />
                          </Grid>
                          <Grid item xs>
                            <strong>{p.programName}</strong> <br />
                            {p.date.format("ddd, D")}
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent="flex-end">
                              <NumericFormat
                                displayType="text"
                                prefix="$"
                                suffix="*"
                                value={
                                  props.isFriendlyFee || !p.isCasualFee
                                    ? p.session.primaryFeeAmount
                                    : p.session.casualFeeAmount
                                }
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })
                  .ToArray()}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

export default ChildVACBookingCard;
