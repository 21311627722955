import { createReducer } from "deox";

import { IBillingUpcomingCharges } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { upcomingError, upcomingLoading, upcomingSuccess } from "./upcomingChargesStateActions";

export interface UpcomingChargesState {
  payload: IBillingUpcomingCharges | null;
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

export const initialState: UpcomingChargesState = {
  payload: null,
  isError: false,
  isLoading: false,
  isLoaded: false,
};

const upcomingChargesReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    upcomingSuccess,
    (state, action): UpcomingChargesState => ({
      ...state,
      isLoading: false,
      isLoaded: true,
      isError: false,
      payload: action.payload,
    })
  ),
  handleAction(
    upcomingLoading,
    (state): UpcomingChargesState => ({
      ...state,
      isLoading: true,
      isError: false,
    })
  ),
  handleAction(
    upcomingError,
    (state): UpcomingChargesState => ({
      ...state,
      isError: true,
      isLoading: false,
      isLoaded: false,
      payload: null,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default upcomingChargesReducer;
