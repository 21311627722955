import { IChild } from "../types/types";
import { isNullOrEmpty } from "./stringUtils";

enum ChildRegistrationStep {
  childStep1,
  childStep2,
  childStep3,
  childStep4,
  childStep5,
  childStep6,
  childStep7,
  childStep8,
  childStep9,
}

type Step = [step: ChildRegistrationStep, count: number];

const steps: Step[] = [
  [ChildRegistrationStep.childStep1, 11], // General Info
  [ChildRegistrationStep.childStep2, 1], // Immunization
  [ChildRegistrationStep.childStep4, 1], // Medical conditions
  [ChildRegistrationStep.childStep6, 4], // Physical conditions
  [ChildRegistrationStep.childStep7, 8], // Doctor
  [ChildRegistrationStep.childStep8, 1], // Priority
  [ChildRegistrationStep.childStep9, 1], // Signature
];

export class ChildHelper {
  static percentCompleted(child: IChild) {
    var complete = 0;

    for (var stepWithRequiredFields in steps) {
      complete += ChildHelper.getStepCompletion(child, steps[stepWithRequiredFields]);
    }

    return (complete * 100) / 27;
  }

  static getStepCompletion(child: IChild, step: Step) {
    var completion = 0;

    switch (step[0]) {
      case ChildRegistrationStep.childStep1:
        completion += isNullOrEmpty(child.firstName) ? 0 : 1;
        completion += isNullOrEmpty(child.lastName) ? 0 : 1;
        completion += child.birthday == null ? 0 : 1;
        completion += child.genderId == null ? 0 : 1;

        if (child.sameSchoolDetailsAsFirstChild != null && child.sameSchoolDetailsAsFirstChild) {
          completion += 1;
        } else {
          completion += child.schoolId == null ? 0 : 1;
        }

        if (child.sameAddressDetailsAsPrimary != null && child.sameAddressDetailsAsPrimary) {
          completion += 4;
        } else {
          completion += isNullOrEmpty(child.residentialAddress?.streetLine1) ? 0 : 1;
          completion += isNullOrEmpty(child.residentialAddress?.postCode) ? 0 : 1;
          completion += isNullOrEmpty(child.residentialAddress?.suburb) ? 0 : 1;
          completion += isNullOrEmpty(child.residentialAddress?.state) ? 0 : 1;
        }

        if (child.sameCulturalDetailsAsPrimary != null && child.sameCulturalDetailsAsPrimary) {
          completion += 2;
        } else {
          completion += isNullOrEmpty(child.mainLanguage) ? 0 : 1;
          completion += isNullOrEmpty(child.culturalBackground) ? 0 : 1;
        }
        break;

      case ChildRegistrationStep.childStep2:
        completion += child.hasBeenImmunised == null ? 0 : 1;
        break;
      case ChildRegistrationStep.childStep4:
        completion += child.hasMedicalConditions == null ? 0 : 1;
        break;
      case ChildRegistrationStep.childStep6:
        completion += child.hasPhysicalNeeds == null ? 0 : 1;
        completion += child.hasBehaviouralNeeds == null ? 0 : 1;
        completion += child.hasSpecialNeeds == null ? 0 : 1;
        completion += child.hasDietaryRequirements == null ? 0 : 1;
        break;

      case ChildRegistrationStep.childStep7:
        if (child.sameMedicalContactAsFirstChild != null && child.sameMedicalContactAsFirstChild) {
          completion += 8;
        } else {
          completion += isNullOrEmpty(child.doctorName) ? 0 : 1;
          completion += isNullOrEmpty(child.doctorPhone) ? 0 : 1;
          completion += isNullOrEmpty(child.doctorAddress) ? 0 : 1;
          completion += isNullOrEmpty(child.doctorSuburb) ? 0 : 1;
          completion += isNullOrEmpty(child.doctorPostcode) ? 0 : 1;
          completion += isNullOrEmpty(child.doctorState) ? 0 : 1;
          completion += isNullOrEmpty(child.medicalServiceName) ? 0 : 1;
          completion += isNullOrEmpty(child.medicareNumber) ? 0 : 1;
        }
        break;

      case ChildRegistrationStep.childStep8:
        completion += child.priorityAccessId == null ? 0 : 1;
        break;
      case ChildRegistrationStep.childStep9:
        completion += child.signature != null || child.signatureDocumentId != null ? 1 : 0;
        break;
      default:
        break;
    }

    return completion;
  }

  static getFirstUncompletedStep(child: IChild, index: number) {
    for (var stepWithRequiredFields in steps) {
      var stepCompletion = ChildHelper.getStepCompletion(child, steps[stepWithRequiredFields]);
      if (stepCompletion < steps[stepWithRequiredFields][1]) {
        return `/registration-child-step/${steps[stepWithRequiredFields][0] + 1}/${index}`;
      }
    }
    return null;
  }
}
