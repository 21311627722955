import { useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import { loadMore, loadPastCharges } from "../../store/modules/pastCharges/pastChargesActions";
import { RootState } from "../../store/store";
import theme from "../../theme";
import BillingCycle from "./BillingCycle";

function PastCharges() {
  const dispatch = useDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const pastCharges = useSelector((state: RootState) => state.pastCharges).payload;
  const isMorePastChargesLoading = useSelector((state: RootState) => state.pastCharges).isMoreLoading;
  const isPastChargesLoaded = useSelector((state: RootState) => state.pastCharges.initLoaded);
  const pageIndex = useSelector((state: RootState) => state.pastCharges.page);
  const isLastPage = useSelector((state: RootState) => state.pastCharges.isLastPage);

  useEffect(() => {
    if (customer_account_id && !isPastChargesLoaded && pageIndex === 1) {
      loadPastCharges(dispatch, customer_account_id, 5, 1);
    }
  }, [customer_account_id, isPastChargesLoaded, pageIndex]);

  const handleShowMore = () => {
    loadMore(dispatch, customer_account_id ?? 0, 5, pageIndex + 1);
  };

  const LoadMoreButton = () => {
    if (isMorePastChargesLoading === false && isLastPage === false) {
      return (
        <Button onClick={handleShowMore}>
          <strong className="color-accent-2">Load more</strong>
        </Button>
      );
    }
    return <div></div>;
  };

  if (pastCharges && pastCharges.length > 0) {
    return (
      <>
        <h2 className="h4" style={{ marginTop: 0 }}>
          Past Charges
        </h2>
        {pastCharges.map((item, index) => (
          <BillingCycle
            key={index}
            customerAccountId={customer_account_id as number}
            billingCycleId={item.billingCycleId}
            billingPeriodEnd={item.billingPeriodEnd}
            totalAmount={item.totalAmount ?? 0}
            billingCycleStatus={item.isStatementCleared ? "" : item.isOverDue ? "OverDue" : item.isDue ? "Due" : ""}
            statusColor={
              item.isStatementCleared
                ? theme.palette.success.light
                : item.isOverDue
                ? theme.palette.error.light
                : item.isDue
                ? theme.palette.warning.light
                : theme.palette.info.light
            }
            totalBookingsAmount={item.transactions
              .filter((t) => t.childId != null && t.childId !== undefined)
              .reduce((a, b) => a + (b.totalMovement ?? 0), 0)}
            totalCCSAndACCSAmount={item.transactions.reduce((a, b) => a + (b.ccs + b.accs), 0)}
            totalOtherFees={
              item.totalAmount ??
              0 -
                item.transactions
                  .filter((t) => t.childId != null && t.childId !== undefined)
                  .reduce((a, b) => a + (b.totalMovement ?? 0), 0) -
                item.transactions.reduce((a, b) => a + (b.ccs + b.accs), 0)
            }
            totalNumberOfBookings={item.transactions.length}
            transactions={item.transactions}
          ></BillingCycle>
        ))}
        <Grid container justifyContent="center">
          <LoadMoreButton />
        </Grid>
      </>
    );
  }
  return null as any;
}

export default PastCharges;
