import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { NumericFormat } from "react-number-format";

import { IServiceDistance } from "../../types/types";
import { getStyleColor } from "../../utils/helpers";
import { ButtonPrimary } from "../Common/Buttons/Buttons";

interface Props {
  serviceDistance: IServiceDistance;
  onSelect: (service: IServiceDistance) => void;
}
const ServiceLocationCard = (props: Props) => {
  var srv = props.serviceDistance.service;
  return (
    <Box pb={2}>
      <Card style={{ backgroundColor: getStyleColor("--custom-color-light-grey") }} elevation={0}>
        <Box p={3}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <strong>{srv.serviceName}</strong>
                </Grid>
                <Grid item xs={12}>
                  {srv.primaryAddressStreet}
                </Grid>
                <Grid item xs={12}>
                  {srv.primaryAddressSuburb} {srv.primaryAddressState} {srv.primaryAddressPostcode}
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignContent="center" justifyContent="flex-start" alignItems="center">
                    <LocationOnOutlined style={{ marginRight: 10 }} />
                    <strong>
                      <NumericFormat
                        value={props.serviceDistance.distanceKM}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        suffix=" KM AWAY"
                      />
                    </strong>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="flex-end">
                <ButtonPrimary
                  style={{ backgroundColor: getStyleColor("--rocketeers-color-accent-2"), marginTop: 0 }}
                  onClick={() => props.onSelect(props.serviceDistance)}
                >
                  <strong>Select</strong>
                </ButtonPrimary>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default ServiceLocationCard;
