import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { reportDownloaded, reportDownloading, reportError, reportInit } from "./statementReportStateActions";

export interface StatementReportState {
  isError: boolean;
  isDownloading: boolean;
  isDownloaded: boolean;
  payload: Blob | null;
  billingCycleId: number | null;
}

export const initialState: StatementReportState = {
  isError: false,
  isDownloading: false,
  isDownloaded: false,
  payload: null,
  billingCycleId: null,
};

const statementReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    reportDownloaded,
    (state, action): StatementReportState => ({
      ...state,
      isDownloading: false,
      isError: false,
      isDownloaded: true,
      payload: action.payload,
      billingCycleId: null,
    })
  ),
  handleAction(
    reportDownloading,
    (state, action): StatementReportState => ({
      ...state,
      isDownloading: true,
      payload: null,
      isError: false,
      isDownloaded: false,
      billingCycleId: action.payload,
    })
  ),
  handleAction(
    reportError,
    (state, action): StatementReportState => ({
      ...state,
      isError: true,
      isDownloading: false,
      isDownloaded: false,
      payload: null,
      billingCycleId: null,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(reportInit, () => initialState),
]);

export default statementReducer;
