import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { merge } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import * as actions from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/store";
import { EntityType } from "../../../types/models";
import { Contact, ContactTypeCategory } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import BasicInformation from "../../Contacts/BasicInformation";
import ContactStep1Schema from "./ContactStep1Schema";

interface Props {
  contact: Contact;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

const FormContactStep1 = ({ contact, id }: Props) => {
  const { contactTypes } = useSelector((state: RootState) => state?.commonData?.data);

  var availableContactTypes = contactTypes;
  if (contact.isPrimary) {
    availableContactTypes = availableContactTypes.filter(
      (x) => x.contactTypeCategoryId === ContactTypeCategory.Immediate || x.contactTypeId === ContactTypeCategory.Other
    );
  }

  const primaryContact = useSelector((state: RootState) => state.register?.primary);

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  function updateContact(values: Contact) {
    actions.saveContact(dispatch, values);
    if (values.isPrimary) {
      navigate(`/registration-contact-step/2/${id}`);
    } else {
      navigate(`/registration-contact-step/3/${id}`);
    }
  }

  const formikValues = {
    email: "",
    mobilePhone: "",
    contactTypeId: "",
    culturalBackground: "",
    culturalNeeds: "",
    mainLanguage: "",
    sameAddressDetailsAsPrimary: false,
    sameCulturalDetailsAsPrimary: false,
    primaryContactEmail: primaryContact.email,
    genderId: "",
    birthday: dayjs.Dayjs,
    residentialAddress: { uniqueId: uuid(), streetLine1: "", suburb: "", state: "", postCode: "" },
    entityType: EntityType.RegistrationContact,
    entityId: contact.uniqueId,
  };
  const initialValues = merge(formikValues, contact);

  return (
    <Formik
      initialValues={initialValues}
      validate={(value) => {
        try {
          validateYupSchema(value, ContactStep1Schema, true, value);
        } catch (err) {
          return yupToFormErrors(err); //for rendering validation errors
        }
        return {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateContact(values);
        }, 500);
      }}
    >
      {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => {
        return (
          <Form>
            <BasicInformation
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              errors={errors}
              primaryContact={primaryContact}
              availableContactTypes={availableContactTypes}
            />
            <Pager>
              <ButtonSecondary href="/registration-pending-information">
                <strong>Back</strong>
              </ButtonSecondary>
              <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
                <strong>Next</strong>
              </ButtonPrimary>
            </Pager>
            <Backdrop className={classes.backdrop} open={isSubmitting}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormContactStep1;
