import React, { useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";

import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import { CardLayout } from "../../layouts/Layouts";
import { getPrivacySettings, togglePrivacySetting } from "../../store/modules/privacy/privacyActions";
import { RootState } from "../../store/store";
import { PrivacySettingType } from "../../types/types";
import { getStyleColor } from "../../utils/helpers";

function Privacy() {
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const privacySettings = useSelector((state: RootState) => state.privacySettings.payload);
  const loadStatus = useSelector((state: RootState) => state.privacySettings.status);
  const saveStatus = useSelector((state: RootState) => state.privacySettings.savingStatus);
  const [localStatus, setLocalStatus] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer_account_id) {
      getPrivacySettings(dispatch, customer_account_id);
    }
  }, [customer_account_id]);

  useEffect(() => {
    if (loadStatus === "Errored" || saveStatus === "Errored") {
      setLocalStatus("Errored");
    }
  }, [loadStatus, saveStatus]);

  const handleToggle = (value: PrivacySettingType) => {
    const toggled =
      value === PrivacySettingType.AllowActivityPhoto
        ? !privacySettings?.allowActivityPhoto
        : !privacySettings?.allowAutoLogin;
    customer_account_id && togglePrivacySetting(dispatch, customer_account_id, value, toggled);
  };

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <h1 className="h2">Privacy</h1>
      </CardLayout.Header>
      <CardLayout.Body>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              {localStatus === "Errored" && (
                <Alert severity="error">An error has been encountered. Please try again later</Alert>
              )}
              <List>
                <ListItem>
                  <ListItemText
                    id="switch-photo"
                    primary="Allow my child to be photographed engaging with our in-service activities"
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() => {
                        console.log(PrivacySettingType.AllowActivityPhoto.toString());
                        handleToggle(PrivacySettingType.AllowActivityPhoto);
                      }}
                      checked={(privacySettings && privacySettings.allowActivityPhoto) ?? false}
                      inputProps={{ "aria-labelledby": "switch-photo" }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText id="switch-login" primary="Allow automatic login from email & sms" />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() => {
                        handleToggle(PrivacySettingType.AllowAutoLogin);
                      }}
                      checked={(privacySettings && privacySettings.allowAutoLogin) ?? false}
                      inputProps={{ "aria-labelledby": "switch-login" }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <LoadingDialog
          open={loadStatus === "Pending" || saveStatus === "Pending"}
          message={loadStatus === "Pending" ? "Loading settings" : "Saving changes"}
        />
      </CardLayout.Body>
    </CardLayout>
  );
}
export default Privacy;
