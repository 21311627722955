import { createActionCreator } from "deox";

import { PageableBillingCycleSummary } from "../../../types/types";

export const pastChargesLoading = createActionCreator("pastChargesLoading");
export const failed = createActionCreator("failed");
export const morePastChargesLoading = createActionCreator("morePastChargesLoading");
export const pastChargesLoaded = createActionCreator(
  "pastChargesLoaded",
  (resolve) => (charges: PageableBillingCycleSummary, pageIndex: number) => resolve(charges, pageIndex)
);

export const pastChargesMoreLoaded = createActionCreator(
  "pastChargesMoreLoaded",
  (resolve) => (charges: PageableBillingCycleSummary, pageIndex: number) => resolve(charges, pageIndex)
);
/*export const moreBillingCyclesLoaded = createActionCreator(
  "moreBillingCyclesLoaded",
  (resolve) => (charges: PageableBillingCycleSummary) => resolve(charges)
);*/
