import { createReducer } from "deox";

import {
  serviceLocationsError,
  serviceLocationsLoading,
  serviceLocationsReset,
  serviceLocationsSuccess,
} from "./programStateActions";
import { IServiceWithUpcomingProgram } from "../../../types/types";

export interface IProgramServicesState {
  servicesByLocation: IServiceWithUpcomingProgram[] | null;
  isLoading: boolean;
}

export const initialState: IProgramServicesState = {
  servicesByLocation: null,
  isLoading: false,
};

const programServicesReducer = createReducer(initialState, (handleAction) => [
  handleAction(serviceLocationsError, (state) => ({ ...state, isLoading: false })),
  handleAction(serviceLocationsReset, (state) => ({ ...state, isLoading: false, servicesByLocation: null })),
  handleAction(serviceLocationsLoading, (state) => ({
    ...state,
    isLoading: true,
  })),
  handleAction(serviceLocationsSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    servicesByLocation: [...(state.servicesByLocation ?? []), ...payload],
  })),
]);

export default programServicesReducer;
