import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { signinSilentCallback } from "../../store/modules/auth/authServices";

function SigninSilentOidc() {
  const history = useNavigate();
  useEffect(() => {
    async function signinSilentAsync() {
      await signinSilentCallback();
    }
    signinSilentAsync();
  }, [history]);

  return null;
}

export default SigninSilentOidc;
