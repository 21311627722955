import GoogleTagManager from "@redux-beacon/google-tag-manager";
import { RootState } from "../store/store";
import { BookingInfo, ServiceProgram } from "../types/models";
import { createMiddleware } from "redux-beacon";
import { BookingPreview, Roll, Service, ViewItemListItem } from "../types/types";
import { List } from "linqts";
import { debug } from "console";
import dayjs, { Dayjs } from "dayjs";

const gtm = GoogleTagManager(window.__config.GTM_CONTAINER);

const serviceProgramListEvent = (action: any) => ({
  hitType: "view_item_list",
  ecommerce: {
    items: action.payload.map((p: ServiceProgram, index: number) => toGaListItem(p, index)),
    item_list_id: "related_products",
    item_list_name: "Related products",
  },
});

const serviceProgramBeginCheckout = (action: any) => ({
  hitType: "begin_checkout",
  ecommerce: {
    items: action.payload.map((p: ServiceProgram, index: number) => toGaListItem(p, index)),
  },
});

const serviceProgramSelect = (action: any) => ({
  hitType: action.payload.event,
  ecommerce: {
    items: [toGaListItem(action.payload.serviceProgram, 0)],
  },
});

const serviceProgramView = (action: any) => ({
  hitType: "view_item",
  ecommerce: {
    items: [ {item_name: `${action.payload.serviceProgram.season} ${action.payload.serviceProgram.year}`,
    item_id: `${action.payload.serviceProgram.session.sessionId}`,
    price: action.payload.serviceProgram.session.primaryFeeAmount,
    item_brand: action.payload.serviceProgram.serviceName,
    item_category: action.payload.serviceProgram.rollName,
    item_category2: action.payload.serviceProgram.session.programActivityType,
    item_variant: action.payload.serviceProgram.programName,
    quantity: 1}],
  }});

function bookingComplete(action: any, _: any, state: RootState): any {
  let total = 0;

  if (state.bookingSaveCasualPreview?.previewResult) {
    state.bookingSaveCasualPreview.previewResult.forEach((b: BookingPreview) => total + b.fee);
  }

  return {
    hitType: "purchase",
    ecommerce: {
      value: total,
      currency: "AUD",
      transaction_id: action.payload.reference,
      items: action.payload.programs.map((p: ServiceProgram, index: number) => toGaListItem(p, index)),
    },
  };
}

function loadedSavedBookings(action: any, _:any, state: RootState): any {
    let items = action.payload.bookings.map((b:BookingInfo) =>
    ({
      item_id: b.uniqueId,
      item_name: `${b.date.format("D MMMM YYYY")}`,
      affiliation: b.date.toISOString(),
      item_brand: b.roll?.service?.serviceName,
      item_category: b.roll?.rollName,
      item_category2: b.roll?.sessionType,
      item_category3: "non-holiday booking",
      item_list_id: b.uniqueId,
      item_list_name: b.roll?.rollName,
      item_variant: b.roll?.sessionType,
      price: state.booking.isEditingCasual ? b.roll?.casualFeeAmount ?? 0 : b.roll?.primaryFeeAmount ?? 0,
      quantity: 1,
      index: 0
    }));

    return {
      hitType: "purchase",
      ecommerce: {
        value: new List(items).Sum((b:any) => b.price),
        currency: "AUD",
        transaction_id: action.payload.bookings[0].uniqueId,
        items: items
      },
    };
  }

function SelectOshBookingStep(action: any, _:any, state: RootState): any {
  if (action.payload == 1) {
      const services = new Map();

      state.services.servicesByCustomer?.forEach(s => {
        services.set(s.serviceId, s);
      });

      return {
        hitType: "view_item_list",
        item_list_id: "related_products",
        item_list_name: "Related products",
        ecommerce: {
          items: mapRollsToListItems(state.session.rolls?.filter(r => r.isOhsc)!, state)
        },
      };
  }

  return null;
}

function RemoveOshBookingSelection(action: any, _:any, state: RootState) {
  const serviceMap = buildServiceMap(state);
  const removedRoll:Roll = action.payload;

  const removedItem = { item_name:  state.session.startDate?.format("D MMMM YYYY"),
    item_id: removedRoll.rollId,
    price: removedRoll.primaryFeeAmount,
    item_brand: serviceMap?.get(removedRoll.serviceId)?.serviceName,
    item_category: removedRoll.rollName,
    item_category2: removedRoll.sessionType,
    item_category3: "non-holiday booking",
    item_variant: serviceMap?.get(removedRoll.serviceId)?.serviceName,
    quantity: 1
  };

  return {
    hitType: "remove_from_cart",
    items: [removedItem]
  }
}

function AddOshBookingSelection(action: any, _:any, state: RootState) {
  const serviceMap = buildServiceMap(state);
  const removedRoll:Roll = action.payload;

  const removedItem = { item_name:  state.session.startDate?.format("D MMMM YYYY"),
    item_id: removedRoll.rollId,
    price: removedRoll.primaryFeeAmount,
    item_brand: serviceMap?.get(removedRoll.serviceId)?.serviceName,
    item_category: removedRoll.rollName,
    item_category2: removedRoll.sessionType,
    item_category3: "non-holiday booking",
    item_variant: serviceMap?.get(removedRoll.serviceId)?.serviceName,
    quantity: 1
  };

  return {
    hitType: "add_to_cart",
    items: [removedItem]
  }
}


function buildServiceMap(state: RootState):Map<number, Service> {
  const services = new Map();

  state.services.servicesByCustomer?.forEach(s => {
    services.set(s.serviceId, s);
  });

  return services;
}

function mapRollsToListItems(rolls:Array<Roll>, state: RootState) {
  let serviceMap = buildServiceMap(state);

  state.services.servicesByCustomer?.forEach(s => {
    serviceMap.set(s.serviceId, s);
  });

  return rolls.map((r, i) => ({
    item_id: r.rollId,
    item_name: state.session.startDate?.format("D MMMM YYYY"),
    affiliation: state.session.startDate?.toISOString(),
    item_brand: serviceMap?.get(r.serviceId)?.serviceName,
    item_category: r.rollName,
    item_category2: r.sessionType,
    item_list_id: r.rollId,
    item_list_name: r.primaryFeeName,
    price: r.primaryFeeAmount,
    quantity: 1,
    index: i
  }));
}

function bookingScheduleCancelled(action: any, _: any, state: RootState): any {
  let b:BookingInfo = state.booking.bookings?.find(b => b.schedule?.uniqueId == action.payload)!;
  return toRefundGaItem(b, state);
}

function bookingInividualCancelled(action: any, _: any, state: RootState): any {
  let b:BookingInfo = state.booking.bookings?.find(b => b.uniqueId == action.payload)!;
  return toRefundGaItem(b, state);
}

function toRefundGaItem(b:BookingInfo, state: RootState) {
    return {
      hitType: "refund",
      ecommerce: {
        items: [
        {
          item_name: `${b.program?.season ?? b.roll?.rollName} ${b.program?.year ?? state.session.startDate?.format("D MMMM YYYY")}`,
          item_id: `${b.program?.sessionId ?? b.roll?.rollId}`,
          price: b.feeAmount ?? b.roll?.primaryFeeAmount,
          item_brand: b.roll?.service?.serviceName,
          item_category: b.roll?.rollName,
          item_category2: b.program?.activityCode ?? b.roll?.sessionType,
          item_variant: b.program?.programName ?? b.roll?.primaryFeeName,
          quantity: b.schedule?.numberOfUpcomingBookings ?? 1
        }
      ],
    }
  }
}

function toGaListItem(p: ServiceProgram, index: Number): ViewItemListItem {
  return {
    item_id: `${p.sessionId}`,
    item_name: `${p.date.format("D MMMM YYYY")}`,
    affiliation: p.date.toISOString(),
    item_brand: p.serviceName,
    item_category: p.rollName,
    item_category2: p.session.programActivityType,
    item_list_id: `${p.session.sessionId}`,
    item_list_name: `${p.programName}`,
    item_variant: `${p.programName}`,
    price: p.session.primaryFeeAmount,
    quantity: 1,
    index,
  };
}

const eventsMap = {
  serviceProgramsView: serviceProgramListEvent,
  serviceProgramSelect: serviceProgramSelect,
  serviceProgramBeginCheckout: serviceProgramBeginCheckout,
  SaveVacBookingComplete: bookingComplete,
  BookingScheduleCancelled: bookingScheduleCancelled,
  BookingIndividualCancelled:bookingInividualCancelled,
  serviceProgramView: serviceProgramView,
  LoadedSavedBookings: loadedSavedBookings,
  SelectOshBookingStep: SelectOshBookingStep,
  RemoveOshBookingSelection: RemoveOshBookingSelection,
  AddOshBookingSelection: AddOshBookingSelection
};

export const gtmMiddleware = createMiddleware(eventsMap, gtm);
