import nswVoucherApi from "./nswVoucherApi";
import { voucherError, voucherSaved, voucherSaving } from "./nswVoucherStateActions";
import { RedeemHttpRequest } from "../../parentApi";

export function redeemNswVoucher(dispatch: any, customerAccountId: number, childId: number, voucherCode: string) {
  dispatch(voucherSaving());

  var redeemHttpRequest = new RedeemHttpRequest({
    childId: childId,
    code: voucherCode.trim(),
  });

  nswVoucherApi.redeem(customerAccountId, redeemHttpRequest).then(
    (result: any) => {
      if (result.result === "redeemed") {
        dispatch(voucherSaved());
      } else {
        dispatch(voucherError(result.message));
      }
    },
    function (error: any) {
      return dispatch(voucherError(error));
    }
  );
}
