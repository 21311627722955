import dayjs, { Dayjs } from "dayjs";

import bookingApi from "./bookingApi";
import {
  BookingCancellationReasonsError,
  BookingCancellationReasonsLoading,
  BookingCancellationReasonsSuccess,
  BookingIndividualCancelled,
  BookingScheduleCancelled,
  CancelBookingError,
  CancelBookingLoading,
  CancelBookingSuccess,
  LoadCreateCasualBookingsPreview,
  LoadCreateCasualBookingsPreviewError,
  LoadCreateRecurringPreview,
  LoadCreateRecurringPreviewError,
  LoadedBookings,
  LoadedBookingsError,
  LoadedSavedBookings,
  LoadingBookings,
  LoadingCreateCasualBookingsPreview,
  LoadingCreateRecurringPreview,
  LoadingSaveRecurringPreview,
  LoadSaveRecurringPreview,
  LoadSaveRecurringPreviewError,
  SaveBookingError,
  SaveBookingFailed,
  SaveBookingLoading,
  SaveBookingSuccess,
  SaveVacBookingComplete,
} from "./bookingStateActions";
import { BookingInfo } from "../../../types/models";
import {
  Booking,
  BookingPreview,
  GetCreateRecurringPreviewRequest,
  IBookingToCancel,
  IChangeRecurringBookingRequest,
  IChild,
  ICreateCasualBookingsHttpRequest,
  ICreateRecurringBookingsRequest,
  IGetChangeRecurringBookingPreviewRequest,
  IGetCreateCasualBookingsPreviewRequest,
  IServiceProgram,
} from "../../../types/types";
import {
  BookingCancellationReason,
  BookingHttpResponse,
  CancelBookingReasonHttpRequest,
  ChangeRecurringBookingHttpRequest,
  CreateCasualBookingsHttpRequest,
  CreateRecurringBookingsHttpRequest,
  GetChangeRecurringBookingPreviewHttpRequest,
  GetChangeRecurringBookingPreviewHttpResponse,
  GetCreateCasualBookingsPreviewHttpRequest,
  GetCreateCasualBookingsPreviewHttpResponse,
  GetCreateRecurringBookingsPreviewHttpResponse,
  IErrorResponse,
} from "../../parentApi";

export function listBookings(dispatch: any, customerAccountId: number, from: Dayjs, to: Dayjs) {
  dispatch(LoadingBookings(from, to));
  bookingApi.getBookingsByCustomerId(customerAccountId, from, to).then(
    (response: BookingHttpResponse[] | null) =>
      response
        ? dispatch(
            LoadedBookings(
              from,
              to,
              (response as Booking[]).map((b) => {
                return new BookingInfo(b);
              })
            )
          )
        : dispatch(LoadedBookingsError()),
    (error: any) => dispatch(LoadedBookingsError())
  );
}


export function listBookingsByBookingsIds(dispatch: any, customerAccountId: number,  bookingIds: string[]) {
  bookingApi.getBookingsByBookingIds(customerAccountId, bookingIds).then(
    (response: BookingHttpResponse[] | null) =>
      response
        ? dispatch(
          LoadedSavedBookings(
              (response as Booking[]).map((b) => {
                return new BookingInfo(b);
              })
            )
          )
        : dispatch(LoadedBookingsError()),
    (error: any) => dispatch(LoadedBookingsError())
  );
}




export function getUpcomingBookings(dispatch: any, customerAccountId: number, skip: number, take: number) {
  return bookingApi.getUpcomingBookingsByCustomerId(customerAccountId, skip, take);
}

export function getRecurringBookings(dispatch: any, customerAccountId: number) {
  return bookingApi.getActiveBookingsSchedules(customerAccountId);
}

export function getCreateCasualBookingsPreview(
  dispatch: any,
  customerAccountId: number,
  request: IGetCreateCasualBookingsPreviewRequest
) {
  dispatch(LoadingCreateCasualBookingsPreview());
  var req = new GetCreateCasualBookingsPreviewHttpRequest();
  req.init(request);
  bookingApi.getCreateCasualBookingsPreview(customerAccountId, new GetCreateCasualBookingsPreviewHttpRequest(req)).then(
    (response: GetCreateCasualBookingsPreviewHttpResponse | null) =>
      response
        ? dispatch(LoadCreateCasualBookingsPreview(response.bookings ?? []))
        : dispatch(LoadCreateCasualBookingsPreviewError()),
    (error: any) => dispatch(LoadCreateCasualBookingsPreviewError())
  );
}

export function getCreateRecurringPreview(
  dispatch: any,
  customerAccountId: number,
  request: GetCreateRecurringPreviewRequest
) {
  dispatch(LoadingCreateRecurringPreview());
  var val = new CreateRecurringBookingsHttpRequest();
  val.init(request);
  bookingApi.getCreateRecurringBookingsPreview(customerAccountId, val).then(
    (response: GetCreateRecurringBookingsPreviewHttpResponse | null) =>
      response
        ? dispatch(
            LoadCreateRecurringPreview(
              response.bookings.map((x) => {
                return {
                  childId: x.childId,
                  date: x.date,
                  fee: x.fee,
                  multiChildrenDiscount: x.multiChildrenDiscount,
                  rollId: x.rollId,
                  status: x.status === "new" ? "new" : "existing",
                  feeType: x.feeType,
                } as BookingPreview;
              })
            )
          )
        : dispatch(LoadCreateRecurringPreviewError()),
    (error: any) => dispatch(LoadCreateRecurringPreviewError())
  );
}

export function getSaveRecurringPreview(
  dispatch: any,
  customerAccountId: number,
  scheduleId: string,
  request: IGetChangeRecurringBookingPreviewRequest
) {
  dispatch(LoadingSaveRecurringPreview());
  var val = new GetChangeRecurringBookingPreviewHttpRequest();
  val.init(request);

  bookingApi.getChangeRecurringBookingPreview(customerAccountId, scheduleId, val).then(
    (response: GetChangeRecurringBookingPreviewHttpResponse | null) =>
      response ? dispatch(LoadSaveRecurringPreview(response!)) : dispatch(LoadSaveRecurringPreviewError()),
    (error: any) => dispatch(LoadSaveRecurringPreviewError())
  );
}

export function createRecurringBooking(
  dispatch: any,
  customerAccountId: number,
  request: ICreateRecurringBookingsRequest
) {
  var mReq = new CreateRecurringBookingsHttpRequest();
  mReq.init(request);
  dispatch(SaveBookingLoading());
  bookingApi.createRecurringBookings(customerAccountId, mReq).then(
    (response: string[] | null) =>
      response
        ? dispatchSaveSuccess(customerAccountId, response.length ? response[0] : "", dispatch, false)
        : dispatch(SaveBookingError("An error has occurred")),
    (error: any) =>
      (error as IErrorResponse) != null
        ? dispatch(SaveBookingFailed(error))
        : dispatch(SaveBookingError("An error has occurred"))
  );
}

export function createVacBooking(
  dispatch: any,
  customerAccountId: number,
  request: ICreateCasualBookingsHttpRequest,
  programs: IServiceProgram[],
  children: IChild[]
) {
  var mReq = new CreateCasualBookingsHttpRequest();
  mReq.init(request);
  dispatch(SaveBookingLoading());
  bookingApi.createCasualBookings(customerAccountId, mReq).then(
    (response: string | null) =>
      response
        ? dispatch(SaveVacBookingComplete(response, programs, children))
        : dispatch(SaveBookingError("An error has occurred")),
    (error: any) =>
      (error as IErrorResponse) != null
        ? dispatch(SaveBookingFailed(error))
        : dispatch(SaveBookingError("An error has occurred"))
  );
}
export function createCasualBookings(
  dispatch: any,
  customerAccountId: number,
  request: ICreateCasualBookingsHttpRequest
) {
  var mReq = new CreateCasualBookingsHttpRequest();
  mReq.init(request);

  dispatch(SaveBookingLoading());
  bookingApi.createCasualBookings(customerAccountId, mReq).then(
    (response: string | null) =>
      response
        ? dispatchSaveSuccess(customerAccountId, response, dispatch, false)
        : dispatch(SaveBookingError("An error has occurred")),
    (error: any) =>
      (error as IErrorResponse) != null
        ? dispatch(SaveBookingFailed(error))
        : dispatch(SaveBookingError("An error has occurred"))
  );
}

export function getCancellableBooking(customerAccountId: number, bookingId: string, reasonId: number) {
  return bookingApi.getCancellableBookingByReason(customerAccountId, bookingId, reasonId).then(
    (response) => response as IBookingToCancel,
    (error: any) => null
  );
}

export function getScheduleCancellableBookings(customerAccountId: number, scheduleId: string, reasonId: number) {
  return bookingApi.getScheduleCancellableBookingsByReason(customerAccountId, scheduleId, reasonId).then(
    (response) => response as IBookingToCancel[],
    (error: any) => null
  );
}

function dispatchSaveSuccess(customer_account_id: number, reference: string, dispatch: any, isVac: boolean) {
  dispatch(SaveBookingSuccess(reference, isVac));
  listBookingsByBookingsIds(dispatch, customer_account_id, [reference]);

  const dt = dayjs();

  listBookings(
    dispatch,
    customer_account_id,
    dayjs(new Date(dt.year(), dt.month(), 1)),
    dayjs(new Date(dt.year(), dt.month(), dt.daysInMonth()))
  );
}

export function saveRecurringBooking(
  dispatch: any,
  customerAccountId: number,
  scheduleId: string,
  request: IChangeRecurringBookingRequest
) {
  var val = new ChangeRecurringBookingHttpRequest();
  val.init(request);

  dispatch(SaveBookingLoading());
  bookingApi.changeRecurringBooking(customerAccountId, scheduleId, val).then(
    (response: void) => dispatchSaveSuccess(customerAccountId, "", dispatch, false),
    (error: any) =>
      (error as IErrorResponse) != null
        ? dispatch(SaveBookingFailed(error))
        : dispatch(SaveBookingError("An error has occurred"))
  );
}

export function cancelBooking(
  dispatch: any,
  customerAccountId: number,
  bookingId: string,
  reasonId: number,
  otherReasonText?: string,
  date?: dayjs.Dayjs
) {
  dispatch(CancelBookingLoading());
  bookingApi
    .cancelSingleBookingWithReason(
      customerAccountId,
      bookingId,
      new CancelBookingReasonHttpRequest({
        reasonId: reasonId,
        otherReasonText: otherReasonText,
      })
    )
    .then(
      (response) =>
      {
        dispatch(BookingIndividualCancelled(bookingId));
        dispatchCancelSuccess(customerAccountId, dispatch, date);
      },
      (error) => dispatch(CancelBookingError())
    );
}

export function cancelSchedule(
  dispatch: any,
  customerAccountId: number,
  scheduleId: string,
  reasonId: number,
  otherReasonText?: string,
  date?: dayjs.Dayjs
) {
  dispatch(CancelBookingLoading());
  bookingApi
    .cancelScheduleWithReason(
      customerAccountId,
      scheduleId,
      new CancelBookingReasonHttpRequest({
        reasonId: reasonId,
        otherReasonText: otherReasonText,
      })
    )
    .then(
      (response) =>
      {
        dispatch(BookingScheduleCancelled(scheduleId));
        dispatchCancelSuccess(customerAccountId, dispatch, date);
      },
      (error) => dispatch(CancelBookingError())
    );
}

function dispatchCancelSuccess(customer_account_id: number, dispatch: any, date?: dayjs.Dayjs) {
  dispatch(CancelBookingSuccess());
  const dt = date ?? dayjs();
  listBookings(
    dispatch,
    customer_account_id,
    dayjs(new Date(dt.year(), dt.month(), 1)),
    dayjs(new Date(dt.year(), dt.month(), dt.daysInMonth()))
  );
}

export function getCancellationBookingReasons(dispatch: any) {
  dispatch(BookingCancellationReasonsLoading());
  bookingApi.getCancellationReasons().then(
    (result: BookingCancellationReason[] | null) => dispatch(BookingCancellationReasonsSuccess(result!)),
    (error: any) => dispatch(BookingCancellationReasonsError())
  );
}
