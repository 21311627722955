import React, { Fragment } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { List } from "linqts";

import theme from "../../theme";
import { StatementTransaction } from "../../types/types";
import { formatCurrency } from "../../utils/helpers";

export interface ServiceTransactionProps {
  transactions: StatementTransaction[];
}

const ServiceTransaction = (props: ServiceTransactionProps) => {
  const serviceDescription = (txn: StatementTransaction) => {
    return `${txn.entryDescr} - ${txn.entryType} - ${txn.sessionType ?? ""}`;
  };
  const arr = new List<StatementTransaction>(props.transactions);
  const transactionsByService = arr.GroupBy((t) => t.serviceName ?? "");

  return (
    <>
      {Object.keys(transactionsByService).map((value, index) => {
        return (
          <Fragment key={index}>
            <Grid item xs={12}>
              <Typography style={{ color: theme.palette.info.dark }}>{value}</Typography>
            </Grid>
            {transactionsByService[value].map((value, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={9}>
                    {serviceDescription(value)}
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                      {formatCurrency(value.totalMovement)}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Fragment>
        );
      })}
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            Daily Total
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container justifyContent="flex-end">
            {formatCurrency(props.transactions.reduce((sum, current) => sum + (current.totalMovement ?? 0), 0))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceTransaction;
