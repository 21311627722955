import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { RegistrationStepper } from "../../components/Common/Stepper/Stepper";
import FormContactStep3 from "../../components/Forms/Registration/ContactStep3";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import { contactSteps } from "../../utils/stepperSteps";

interface ParamTypes {
  id: string;
  step: string;
}

function ContactStep3() {
  let { id, step } = useParams();
  const memoizedId = useMemo(() => parseInt(id!, 10), [id]);
  const contact = useSelector((state: RootState) => state.register?.contacts[memoizedId]);
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage
        stepper={<RegistrationStepper activeStep={memoizedId === 0 ? 3 : 2} steps={contactSteps(memoizedId)} />}
      >
        <RegistrationHeader
          heading={`${contact.firstName} ${contact.lastName}`}
          subHeading={
            memoizedId === 0
              ? "Primary Account Holder"
              : memoizedId === 1
              ? "Secondary Account Holder"
              : `Contact ${memoizedId - 1}`
          }
        />
        <FormContactStep3 contact={contact} step={step!} id={id!} />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default ContactStep3;
