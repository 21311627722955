import React from "react";

import styles from "./CheckEmail.module.scss";
import FormCheckEmail from "../../components/Forms/CheckEmail/CheckEmail";
import { LayoutInitialPages } from "../../layouts/Layouts";

function CheckEmail() {
  return (
    <LayoutInitialPages>
      <div className={styles.Home}>
        <FormCheckEmail />
      </div>
    </LayoutInitialPages>
  );
}

export default CheckEmail;
