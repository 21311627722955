import React from "react";

import { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

import { AutocompleteBase } from "./Autocomplete";
import { searchSchools } from "../../../store/modules/school/schoolActions";
import { SchoolHttpResponse } from "../../../store/parentApi";

interface Props {
  id: string;
  label: string;
  schoolId: number | undefined;
  schoolName: string | undefined;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: SchoolHttpResponse | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<SchoolHttpResponse>
  ) => void;
  helperText: any;
  error: any;
}

export function SchoolAutocomplete(props: Props) {
  let defaultValue;
  if (props.schoolId !== undefined) {
    defaultValue = new SchoolHttpResponse();
    defaultValue.init({ schoolId: props.schoolId, schoolName: props.schoolName });
  }

  return (
    <AutocompleteBase<SchoolHttpResponse>
      id={props.id}
      label={props.label}
      onChange={props.onChange}
      getOptionSelected={(option, value) => option.schoolName === value.schoolName}
      getOptionLabel={(option) => option.schoolName}
      onPatternChange={async (pattern: string) => (pattern.length > 0 ? await searchSchools(pattern) : [])}
      defaultValue={defaultValue}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Grid container alignItems="center">
            {option.schoolName}
            <br />
            {option.city} {option.state} {option.postcode}
          </Grid>
        </li>
      )}
      helperText={props.helperText}
      error={props.error}
      minCharacters={2}
      maxCharacters={100}
    />
  );
}
