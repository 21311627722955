import { createReducer } from "deox";

import { RegistrationStatus } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import {
  signupUpdateStatus,
  signupVerifyError,
  sendOtpEmailError,
  sendOtpEmailSuccess,
  sendOtpEmailReset,
} from "./signupActionTypes";

export interface SignupState {
  registrationStatus: RegistrationStatus | null;
  email: string | null;
  isVerifyError: boolean;
  isSendOtpEmailError: boolean;
  isSendOtpEmailSuccess: boolean;
}

const initialState: SignupState = {
  registrationStatus: null,
  email: null,
  isVerifyError: false,
  isSendOtpEmailError: false,
  isSendOtpEmailSuccess: false,
};

const signupReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    signupUpdateStatus,
    (state, action): SignupState => ({
      ...state,
      registrationStatus: action.payload.registrationStatus,
      isVerifyError: false,
      email: action.payload.email,

    })
  ),
  handleAction(
    signupVerifyError,
    (state): SignupState => ({
      ...state,
      isVerifyError: true,

    })
  ),
  handleAction(
    signupVerifyError,
    (state): SignupState => ({
      ...state,
      isVerifyError: true,
    })
  ),

  handleAction(
    sendOtpEmailError,
    (state): SignupState => ({
      ...state,
      isSendOtpEmailError: true,
      isSendOtpEmailSuccess: false,

    })
  ),
  handleAction(
    sendOtpEmailSuccess,
    (state): SignupState => ({
      ...state,
      isSendOtpEmailError: false,
      isSendOtpEmailSuccess: true,
    })
  ),
  handleAction(
    sendOtpEmailReset,
    (state): SignupState => ({
      ...state,
      isSendOtpEmailError: false,
      isSendOtpEmailSuccess: false,

    })
  ),
  handleAction(purge, () => initialState),
]);

export default signupReducer;
