import { createReducer } from "deox";

import { IPrivacySetting } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { PrivacySettingsStatus } from "./privacyActions";
import {
  loadPrivacyError,
  loadPrivacyPending,
  loadPrivacySuccess,
  savePrivacyError,
  savePrivacyInit,
  savePrivacyPending,
  savePrivacySuccess,
} from "./privacyStateActions";

export interface PrivacySettingsState {
  status: PrivacySettingsStatus;
  payload: IPrivacySetting | null;
  savingStatus: PrivacySettingsStatus;
}

export const initialState: PrivacySettingsState = {
  status: "Initial",
  payload: null,
  savingStatus: "Initial",
};

const privacySettingsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    loadPrivacySuccess,
    (state, action): PrivacySettingsState => ({
      ...state,
      status: "Success",
      payload: action.payload,
    })
  ),
  handleAction(
    savePrivacySuccess,
    (state, action): PrivacySettingsState => ({
      ...state,
      savingStatus: "Success",
      payload: {
        allowActivityPhoto:
          state.payload && action.payload === "allowActivityPhoto"
            ? !state.payload?.allowActivityPhoto
            : state.payload?.allowActivityPhoto ?? false,

        allowAutoLogin:
          state.payload && action.payload === "allowAutoLogin"
            ? !state.payload?.allowAutoLogin
            : state.payload?.allowAutoLogin ?? false,
      },
    })
  ),
  handleAction(
    loadPrivacyPending,
    (state, action): PrivacySettingsState => ({
      ...state,
      status: "Pending",
      payload: null,
    })
  ),
  handleAction(
    savePrivacyPending,
    (state, action): PrivacySettingsState => ({
      ...state,
      savingStatus: "Pending",
    })
  ),
  handleAction(
    loadPrivacyError,
    (state, action): PrivacySettingsState => ({
      ...state,
      status: "Errored",
      payload: null,
    })
  ),
  handleAction(
    savePrivacyError,
    (state, action): PrivacySettingsState => ({
      ...state,
      savingStatus: "Errored",
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(
    savePrivacyInit,
    (state, action): PrivacySettingsState => ({
      ...state,
      savingStatus: "Initial",
    })
  ),
]);

export default privacySettingsReducer;
