import { IOnlineSessionBookings, IUpdateOnlineSessionBookingsRequest } from "../../../types/types";
import {
  OnlineSession,
  OnlineSessionBookingsHttpResponse,
  UpdateOnlineSessionBookingsHttpRequest,
} from "../../parentApi";
import eventsApi from "./eventsApi";
import { upcomingEventsLoaded, upcomingEventsLoading, upcomingEventsLoadingError } from "./eventStateActions";

export function getUpcomingEvents(dispatch: any) {
  dispatch(upcomingEventsLoading());
  eventsApi
    .getUpcoming()
    .then((response: OnlineSession[] | null) =>
      response && response.length > 0
        ? dispatch(upcomingEventsLoaded(response))
        : dispatch(upcomingEventsLoadingError())
    )
    .catch(() => dispatch(upcomingEventsLoadingError()));
}

export function saveEventsBooking(customerAccountId: number, request: IUpdateOnlineSessionBookingsRequest) {
  var val = new UpdateOnlineSessionBookingsHttpRequest();
  val.init(request);
  return eventsApi.saveBookings(customerAccountId, val).then(
    (response: OnlineSessionBookingsHttpResponse[] | null) =>
      response ? (response as IOnlineSessionBookings[]) : null,
    (response: any) => null
  );
}

export function getUpcomingEventBookings(customerAccountId: number) {
  return eventsApi.getUpcomingBookings(customerAccountId).then(
    (response: OnlineSessionBookingsHttpResponse[] | null) =>
      response ? (response as IOnlineSessionBookings[]) : null,
    (response: any) => null
  );
}
