import { createActionCreator } from "deox";

import { Child } from "../../../types/models";

export const childrenLoading = createActionCreator("childrenLoading");
export const childrenError = createActionCreator("childrenError");

export const childrenSuccess = createActionCreator(
  "childrenSuccess",
  (resolve) => (children: Child[]) => resolve(children)
);

export const childUpdating = createActionCreator("childUpdating", (resolve) => (child: Child) => resolve({ child }));
export const childUpdated = createActionCreator(
  "childUpdated",
  (resolve) => (child: Child, isNew?: boolean, documentIdsAdded?: string[]) =>
    resolve({ child, isNew, documentIdsAdded })
);

export const childUpdateError = createActionCreator("childUpdateError");

export const childReset = createActionCreator("childReset");

export const acknowledgedAdditionalNeeds = createActionCreator("acknowledgedAdditionalNeeds");
