import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { PossibleDuplicatedAccount } from "../../components/Common/Controls/PossibleDuplicatedAccount";
import Pager from "../../components/Common/Pager/Pager";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";

function Introduction() {
  const useStyles = makeStyles()((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  }));

  const { classes } = useStyles();

  const { isInitialised } = useSelector((state: RootState) => state.register);
  const commonDataIsInitialised = useSelector((state: RootState) => state.commonData.isInitialised);
  const possibleDuplicatedAccount = useSelector((state: RootState) => state.register?.possibleDuplicatedAccount);

  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage>
        <h1>Welcome to Camp Australia</h1>
        <p>
          Registration takes approximately 10 minutes. Camp Australia needs all documentation at registration to be able
          to care for your child.
        </p>
        <p>To save time, please ensure you have the following documents with you should they apply to you:</p>
        <ul>
          <li>Centrelink Customer Reference Number (CRN)</li>
          <li>Medical Documentation</li>
          <li>Court Order/Parenting Plans Documents </li>
          <li>Child Immunisation Certificates</li>
        </ul>
        <p>
          If you’re unable to provide some of these documents at the time of registration, you can always upload them
          later through the parent portal.
        </p>
        <p>Please note that you won’t be able to start booking until all mandatory information is completed.</p>

        {possibleDuplicatedAccount && <PossibleDuplicatedAccount />}
        {!possibleDuplicatedAccount && (
          <Pager>
            <Grid container justifyContent="flex-end">
              <ButtonPrimary href="/registration-add-contacts" disabled={!isInitialised || !commonDataIsInitialised}>
                <strong>Next</strong>
              </ButtonPrimary>
            </Grid>
          </Pager>
        )}
        <Backdrop className={classes.backdrop} open={!isInitialised || !commonDataIsInitialised}>
          <CircularProgress color="primary" />
        </Backdrop>
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default Introduction;
