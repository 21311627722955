import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { redeemNswVoucher } from "../../store/modules/nswVoucher/nswVoucherActions";
import { RootState } from "../../store/store";
import { ButtonPrimary } from "../Common/Buttons/Buttons";
import { AnimatedDialog } from "../Common/Dialogs/AnimatedDialog";
import NswVoucherRedeemSchema from "./NswVoucherRedeemSchema";

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

interface INswVoucherRedeemProps {
  show: boolean;
  customerAccountId: number;
  childId: number;
  onClose?: () => void;
}

export default function NswVoucherRedeem(props: INswVoucherRedeemProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { isSaving, isError, isSaved, errorMessage } = useSelector((state: RootState) => state.nswVoucher);

  function _submit(voucherCode: string): void {
    redeemNswVoucher(dispatch, props.customerAccountId, props.childId, voucherCode);
  }

  return (
    <AnimatedDialog open={true} onClose={props.onClose} title="Redeem NSW Voucher">
      <DialogContent>
        <Formik
          initialValues={{
            voucherCode: "",
          }}
          validationSchema={NswVoucherRedeemSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              _submit(values.voucherCode);
            }, 500);
          }}
        >
          {({ handleChange, handleBlur, isSubmitting, values, touched, errors }) => (
            <Box padding={3}>
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl required fullWidth>
                      <TextField
                        id="voucherCode"
                        label="Voucher Code *"
                        type="text"
                        value={values.voucherCode}
                        onChange={handleChange("voucherCode")}
                        onBlur={handleBlur("voucherCode")}
                        helperText={touched.voucherCode ? errors.voucherCode : ""}
                        error={touched.voucherCode && Boolean(errors.voucherCode)}
                        fullWidth
                        className={classes.textField}
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {isError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                  )}
                  {isSaved && (
                    <Grid item xs={12}>
                      <Alert severity="success">Voucher was correctly redeemed</Alert>
                    </Grid>
                  )}
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <ButtonPrimary disabled={isSubmitting} type="submit">
                      <strong>Redeem</strong>
                    </ButtonPrimary>
                  </Grid>
                  <Backdrop className={classes.backdrop} open={isSaving}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                </Grid>
              </Form>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </AnimatedDialog>
  );
}
