import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import { RegistrationStepper } from "../../components/Common/Stepper/Stepper";
import FormChildStep7 from "../../components/Forms/Registration/ChildStep7";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import { childSteps } from "../../utils/stepperSteps";

interface ParamTypes {
  id: string;
}

function ChildStep7() {
  let { id } = useParams();
  const memoizedId = useMemo(() => parseInt(id!, 10), [id]);
  const child = useSelector((state: RootState) => state.register?.children[memoizedId]);
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage stepper={<RegistrationStepper activeStep={5} steps={childSteps(memoizedId)} />}>
        <RegistrationHeader heading={`${child.firstName} ${child.lastName}`} subHeading={`Child ${memoizedId + 1}`} />
        <FormChildStep7 child={child} id={id!} />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default ChildStep7;
