import { ValidationProblemDetails } from "../../parentApi";
import { signoutRedirect } from "../auth/authServices";
import api from "./changeLoginApi";
import { changeLoginError, changeLoginInit, changeLoginPending, changeLoginSuccess } from "./changeLoginStateActions";

export type ChangeLoginStatus = "Initial" | "Pending" | "Success" | "Errored";

export function ChangeLogin(
  dispatch: any,
  customerAccountId: number,
  customerContactId: number,
  newLoginEmail: string
) {
  dispatch(changeLoginPending());
  api
    .loginEmailChange(customerAccountId, customerContactId, newLoginEmail, true)
    .then(
      function (value) {
        dispatch(changeLoginSuccess());
        setTimeout(() => {
          signoutRedirect();
        }, 3000);
      },
      function (error: ValidationProblemDetails) {
        if (error.errors) {
          let msg = "";
          for (let key in error["errors"]) {
            if (error["errors"].hasOwnProperty(key))
              msg += error["errors"][key] !== undefined ? error["errors"][key] : [];
          }
          dispatch(changeLoginError(msg));
        } else {
          dispatch(changeLoginError("An error ocurred while trying to change your email. Please try again later"));
        }
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(changeLoginInit());
      }, 500);
    });
}

export function InitChangeLogin() {
  return function (dispatch: any) {
    dispatch(changeLoginInit());
  };
}
