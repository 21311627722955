import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { List } from "linqts";
import { IoAlertOutline, IoCheckmarkOutline, IoChevronDownOutline, IoCloseOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { RootState } from "../../store/reducers";
import theme from "../../theme";
import { Child, EntityType } from "../../types/models";
import {
  BookingStatus3,
  FeeType,
  IBookingInfo3,
  IGetChangeRecurringBookingPreviewResponse,
  IRollInfo,
  ISessionAvailability,
} from "../../types/types";
import { getAvailabilityReasonsText, getStyleColor } from "../../utils/helpers";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface Props {
  child: Child;
  roll: IRollInfo;
  previewResult: IGetChangeRecurringBookingPreviewResponse;
  datesUnAvailable: List<ISessionAvailability>;
  isSchedule: boolean;
}

const useStyles = makeStyles()((theme) => {
  return {
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    icon: {
      verticalAlign: "top",
      height: 20,
      width: 20,
    },
    accordion: {
      backgroundColor: getStyleColor("--custom-color-light-grey"),
      border: 0,
      borderRadius: 3,
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      "&::before": {
        display: "none",
      },
    },
    accordionSummary: {
      alignItems: "flex-start",
    },
  };
});

const ChildBookingEditCard = (props: Props) => {
  const { classes } = useStyles();
  var roll = props.roll;
  var previewResult = props.previewResult;
  var datesUnAvailable = props.datesUnAvailable;
  var bookings = new List(props.previewResult.bookings);

  var unAvailableCount = bookings.Count(
    (s) =>
      s !== undefined &&
      s.status === BookingStatus3.New &&
      datesUnAvailable.Any(
        (d) =>
          d !== undefined && !d.isAvailableForRecurringBooking && s.rollId === d.rollId && d.date.isSame(s.date, "date")
      )
  );

  var chargedCancelledCount = bookings.Count(
    (b) => b !== undefined && b.status === BookingStatus3.CancelledInNoticePeriod
  );
  var cancelledInTimeCount = bookings.Count((b) => b !== undefined && b.status === BookingStatus3.CancelledInTime);
  var editBookingsCount = bookings.Count(
    (b) =>
      b !== undefined &&
      b.status !== BookingStatus3.CancelledInNoticePeriod &&
      b.status !== BookingStatus3.CancelledInTime
  );
  var hasMinBookings =
    editBookingsCount + (props.previewResult.chargedBookingsCount ?? 0) >=
    (props.previewResult.minBookingsRequired ?? 0);

  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Child && x.entityId === props.child.childId?.toString()
    )
  );
  var bookingsByMonth = bookings.OrderBy((d) => d.date).GroupBy((d) => d.date.format("MMM YYYY"));

  return (
    <Box pb={2}>
      <Accordion
        key={"ChildAccordEdit_" + props.child.childId}
        className={classes.accordion}
        TransitionProps={{ timeout: 0 }}
        elevation={0}
        defaultExpanded
      >
        <AccordionSummary className={classes.accordionSummary} expandIcon={<IoChevronDownOutline />}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <AvatarDisplay info={childAvatar ?? { index: props.child.avatarIndex }} />
                </Grid>
                <Grid item>
                  {props.child.firstName} {props.child.lastName}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <span className="color-dark-grey">{props.roll?.service?.serviceName?.toUpperCase()}</span>
            </Grid>
            {DisplayRolls(
              props,
              bookings.Where(
                (b) =>
                  b !== undefined &&
                  !datesUnAvailable.Any(
                    (d) => d !== undefined && d.rollId === b.rollId && d.date.isSame(b.date, "date")
                  )
              )
            )}
            {!hasMinBookings && (
              <Grid item xs={12}>
                <span style={{ color: theme.palette.error.main }}>
                  There should be at least {previewResult.minBookingsRequired} bookings per session
                </span>
              </Grid>
            )}
            {chargedCancelledCount > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <IoCloseOutline size={24} color={theme.palette.error.main} style={{ marginRight: 10 }} />
                      <span style={{ color: theme.palette.error.main }}>{roll.rollName}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" alignContent="center" justifyContent="flex-end">
                      {`Session${chargedCancelledCount > 1 ? "s" : ""} x ${chargedCancelledCount}`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {cancelledInTimeCount > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <IoCloseOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                      <span style={{ color: theme.palette.success.main }}>{roll.rollName}</span>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" alignContent="center" justifyContent="flex-end">
                      {`Session${cancelledInTimeCount > 1 ? "s" : ""} x ${cancelledInTimeCount}`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {unAvailableCount > 0 && (
              <Grid item xs={12}>
                <Grid container justifyContent="flex-start" alignItems="center">
                  <IoAlertOutline size={24} color={theme.palette.error.main} style={{ marginRight: 10 }} />
                  <span style={{ color: theme.palette.error.main }}>
                    {unAvailableCount} Unavailable {unAvailableCount === 1 ? "Session" : "Sessions"}
                  </span>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {DisplayBookings(props, bookingsByMonth)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

function DisplayBookings(props: Props, bookingsByMonth: { [key: string]: IBookingInfo3[] }): JSX.Element[] {
  var data = new Array<JSX.Element>();
  for (var key in bookingsByMonth) {
    var monthBookings = bookingsByMonth[key];
    var bookingsByDate = new List(monthBookings).GroupBy((mb) => mb.date.format("ddd, D"));
    data.push(
      <React.Fragment key={"ChildAccordEdit_" + props.child.childId + "_Month_" + monthBookings[0].date.month()}>
        <Grid item xs={12}>
          <Box paddingTop={2} />
        </Grid>
        <Grid item xs={12}>
          <strong>{key}</strong>
        </Grid>
        {DisplayBookingsByDate(props, bookingsByDate)}
      </React.Fragment>
    );
  }
  return data;
}

function DisplayBookingsByDate(props: Props, bookingsByDate: { [key: string]: IBookingInfo3[] }): JSX.Element[] {
  var data = new Array<JSX.Element>();
  for (var key in bookingsByDate) {
    var dateBookings = new List(bookingsByDate[key]);
    data.push(
      <Grid item xs={12} key={"ChildAccordEdit_" + props.child.childId + "_date_" + dateBookings.First().date.unix()}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item> {key}</Grid>
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              {dateBookings
                .DistinctBy((d) => d.rollId)
                .Select((c) => {
                  return DisplayCareTypes(props, c);
                })
                .ToArray()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return data;
}

function DisplayCareTypes(props: Props, bk: IBookingInfo3): JSX.Element {
  var roll = props.roll;

  switch (bk.status) {
    case BookingStatus3.New:
      var unvDate = props.datesUnAvailable.FirstOrDefault(
        (dt) =>
          dt !== undefined &&
          bk.rollId === dt.rollId &&
          dt.date.isSame(bk.date, "date") &&
          !dt.isAvailableForRecurringBooking
      );

      return unvDate === undefined ? (
        <Grid item key={"ChildAccordEdit_" + props.child.childId + "_caretype_" + roll.sessionType}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <IoCheckmarkOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
            <span style={{ color: theme.palette.success.main }}>
              {roll.sessionType} {props.isSchedule && bk.feeType === FeeType.Casual ? " (Casual)" : ""}
            </span>
          </Grid>
        </Grid>
      ) : (
        <Tooltip
          key={"ChildAccordEdit_" + props.child.childId + "_caretype_" + roll.sessionType}
          arrow
          title={getAvailabilityReasonsText(
            props.isSchedule ? unvDate.reasonForRecurringBooking : unvDate.reasonForCasualBooking
          )}
          placement="top"
        >
          <Grid item>
            <Grid container alignItems="center" justifyContent="flex-end">
              <IoAlertOutline size={24} color={theme.palette.error.main} style={{ marginRight: 10 }} />
              <span style={{ color: theme.palette.error.main }}>{roll.sessionType}</span>
            </Grid>
          </Grid>
        </Tooltip>
      );

    case BookingStatus3.Existing:
      return (
        <Grid
          key={"ChildAccordEdit_" + props.child.childId + "_caretype_" + roll.sessionType}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <IoAlertOutline size={24} color="gray" style={{ marginRight: 10 }} />
          <span style={{ color: "gray" }}>Existing booking</span>
        </Grid>
      );
    case BookingStatus3.CancelledInTime:
    case BookingStatus3.CancelledInTimeAsHot:
      return (
        <Grid
          key={"ChildAccordEdit_" + props.child.childId + "_caretype_" + roll.sessionType}
          container
          alignItems="center"
          justifyContent="flex-end"
          style={{ color: theme.palette.success.main }}
        >
          <IoCloseOutline size={24} color={theme.palette.success.main} />
          Cancellation
        </Grid>
      );
    case BookingStatus3.CancelledInNoticePeriod:
      return (
        <Grid
          key={"ChildAccordEdit_" + props.child.childId + "_caretype_" + roll.sessionType}
          container
          alignItems="center"
          justifyContent="flex-end"
          style={{ color: theme.palette.error.main }}
        >
          <IoCloseOutline size={24} color={theme.palette.error.main} />
          Late Cancellation&nbsp;
          <NumericFormat displayType="text" prefix="$" value={bk.fee} decimalScale={2} fixedDecimalScale={true} />
        </Grid>
      );
    default:
      return <div>{roll.sessionType}</div>;
  }
}

function DisplayRolls(props: Props, bookings: List<IBookingInfo3>) {
  var bookingsByFee = bookings
    .Where((ab) => ab?.rollId === props.roll.rollId && ab.status === BookingStatus3.New)
    .GroupBy((ab) => ab.feeType as FeeType);

  return DisplayRollFee(props.roll, bookingsByFee, props.isSchedule);
}

function DisplayRollFee(r: IRollInfo, bookingsByFee: { [x: string]: IBookingInfo3[] }, isSchedule: boolean) {
  var data = new Array<JSX.Element>();
  for (var _feeType in bookingsByFee) {
    var allAvBookings = bookingsByFee[_feeType];
    var firstItem = allAvBookings[0];
    data.push(
      <Grid container key={"ChildAccordEdit_" + r.rollId + "_fee_" + firstItem.feeType}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <IoCheckmarkOutline size={24} color={theme.palette.success.main} style={{ marginRight: 10 }} />
                <span style={{ color: theme.palette.success.main }}>
                  {r.rollName} {isSchedule && firstItem.feeType === FeeType.Casual ? " (Casual)" : ""}
                </span>
              </Grid>
            </Grid>
            <Grid item>
              {allAvBookings.length} x{" "}
              <NumericFormat
                displayType="text"
                prefix="$"
                value={firstItem.fee}
                decimalScale={2}
                fixedDecimalScale={true}
              />
              *
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return data;
}

export default ChildBookingEditCard;
