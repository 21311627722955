import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import FormPayment from "../../components/Forms/Registration/Payment";
import { LayoutAccent1 } from "../../layouts/Layouts";

function Payment() {
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage>
        <RegistrationHeader heading="Payment Details">
          <p>
            Camp Australia requires a payment type, but don't worry, you will not be charged until you use our service.
          </p>
        </RegistrationHeader>
        <FormPayment />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default Payment;
