import React from "react";

import styles from "./Pages.module.scss";

interface Props {
  children?: any;
  stepper?: any;
}

export const RegistrationPage = (props: Props) => {
  const { children, stepper } = props;
  return (
    <div className={styles["Page"]}>
      {stepper && <div className={styles["Page-Stepper"]}>{stepper}</div>}
      <div className={styles["Page-Card"]}>{children}</div>
    </div>
  );
};
