import { useEffect, useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CircleIcon from "@mui/icons-material/Circle";
import EastIcon from "@mui/icons-material/East";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box/Box";
import Divider from "@mui/material/Divider/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import dayjs from "dayjs";
import { List as QList } from "linqts";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BookingCompleteDialog from "./BookingCompleteDialog";
import BookingDetails from "./BookingDetails";
import CancellationNote from "./CancellationNote";
import YourOSHCLogo from "../../assets/logos/logo-your-oshc.svg";
import { useAppDispatch } from "../../store/hooks";
import {
  createCasualBookings,
  createRecurringBooking,
  getCreateCasualBookingsPreview,
  getCreateRecurringPreview,
  getSaveRecurringPreview,
  saveRecurringBooking,
} from "../../store/modules/booking/bookingActions";
import { RootState } from "../../store/store";
import {
  AgeViolationReasonInfo,
  BookingDateInfo,
  BookingDaysInfo,
  ChangeRecurringBookingPreviewRequest,
  ChangeRecurringBookingRequest,
  ChildAgeConstraint,
  CreateCasualBookingsHttpRequest,
  CreateRecurringBookingsRequest,
  CreateRecurringPreviewRequest,
  EntityType,
  OshcBookingDetails,
  SelectedChild,
  SelectedRollDate,
  SessionAvailability,
} from "../../types/models";
import {
  BookingPreview,
  BookingScheduleType,
  BookingStatus3,
  IGetCreateCasualBookingsPreviewRequest,
  ISessionAvailability,
} from "../../types/types";
import { getMatchingUnAvailableDates, HasMinimumBookingsAcrossWeeks } from "../../utils/bookingHelper";
import {
  getAvailabilityReasonsText,
  getBookableRollDates,
  getChildDisplayName,
  getStyleColor,
} from "../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../Common/Buttons/Buttons";
import { AvatarDisplay } from "../Common/Controls/AvatarDisplay";

interface IProps {
  formState: OshcBookingDetails;
  classes: any;
  isAllowedToBook: boolean;
  isFriendlyFee: boolean;
  pageState: {
    hasConflict: boolean;
    hasError: boolean;
    unAvailableDates: ISessionAvailability[];
    newlyAddedUnAvailableDates: ISessionAvailability[];
  };
  setPageState: React.Dispatch<
    React.SetStateAction<{
      hasConflict: boolean;
      hasError: boolean;
      unAvailableDates: ISessionAvailability[];
      newlyAddedUnAvailableDates: ISessionAvailability[];
    }>
  >;
}
export default function BookingSummary(props: IProps) {
  const { formState, classes, isAllowedToBook, isFriendlyFee, pageState, setPageState } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const servicesByCustomer = useSelector((state: RootState) => state.services.servicesByCustomer);
  const isEditMode = formState.scheduleId !== undefined;

  const saveState = useSelector((state: RootState) => state.createBooking);

  const [availableBookings, setAvailableBookings] = useState<QList<BookingPreview>>(new QList());
  const isSubmitting = saveState !== undefined && saveState.isSaving;
  const auth = useSelector((state: RootState) => state.auth);
  const customer_account_id = auth?.user?.profile.customer_account_id;
  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );
  const previews = useSelector((state: RootState) => state.recurringBookingPreview.bookings);
  const casualPreviews = useSelector((state: RootState) => state.bookingSaveCasualPreview.previewResult);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);

  const previewResult = useSelector((state: RootState) => state.bookingSaveRecurringPreview.previewResult);

  const [canConfirmModifySchedule, setCanConfirmModifySchedule] = useState(false);

  const [showCompleted, setShowCompleted] = useState<{ show: boolean; success: boolean }>({
    show: false,
    success: false,
  });

  const bookingsPreview = new QList((formState.isRecurring ? previews : casualPreviews) ?? new Array<BookingPreview>());
  var orderedCasualDates = new QList(formState.casualDates).OrderBy((d) => d.date);

  function getBkooingFrom() {
    return formState.isRecurring ? formState.recurringStartDate : orderedCasualDates.First().date;
  }

  function getBookingTo() {
    return formState.isRecurring ? formState.recurringEndDate : orderedCasualDates.Last().date;
  }
  useEffect(() => {
    populateAvailableBookings();
  }, [previews, casualPreviews]);

  useEffect(() => {
    var canConfirm = false;

    if (previewResult !== null && previewResult.bookings.length > 0) {
      const newBookings = previewResult.bookings
        .filter((x) => x?.status === BookingStatus3.New)
        .filter(
          (p) =>
            !(unAvailableDates ?? []).some(
              (du) =>
                du?.rollId === p?.rollId &&
                ((formState.isRecurring && du.isAvailableForRecurringBooking === false) ||
                  (!formState.isRecurring && du.isAvailableForCasualBooking === false)) &&
                du?.date.isSame(p?.date as dayjs.Dayjs, "date")
            )
        );
      var hasChanges =
        newBookings.length > 0 ||
        previewResult.bookings.findIndex(
          (b) => b.status !== BookingStatus3.Existing && b.status !== BookingStatus3.New
        ) > -1;

      var editBookingsCount = previewResult.bookings.filter(
        (b) => b.status !== BookingStatus3.CancelledInNoticePeriod && b.status !== BookingStatus3.CancelledInTime
      ).length;
      var hasMinBookings = editBookingsCount + previewResult.chargedBookingsCount >= previewResult.minBookingsRequired;
      canConfirm = hasChanges && hasMinBookings;
    }
    setCanConfirmModifySchedule(canConfirm);
  }, [previewResult]);

  function populateAvailableBookings() {
    setAvailableBookings(
      new QList([
        ...bookingsPreview
          .Where(
            (p) =>
              !pageState.unAvailableDates.some(
                (du) =>
                  du?.rollId === p?.rollId &&
                  ((formState.isRecurring && du.isAvailableForRecurringBooking === false) ||
                    (!formState.isRecurring && du.isAvailableForCasualBooking === false)) &&
                  du?.date.isSame(p?.date as dayjs.Dayjs, "date")
              )
          )
          .ToArray(),
      ])
    );
  }

  useEffect(() => {
    setShowCompleted({
      show: saveState.isErrorState || saveState.isSuccess,
      success: saveState?.isSuccess,
    });
    if (saveState && saveState.isErrorState) {
      if (saveState.errorResponse && saveState.errorResponse.info && saveState.errorResponse.errorCode === 100) {
        const updatedUnAvailableDates = saveState.errorResponse.info.map((d: any) =>
          SessionAvailability.fromJson(d)
        ) as SessionAvailability[];

        const newUnAvailableDates = updatedUnAvailableDates.filter(
          (sess: SessionAvailability) =>
            pageState.unAvailableDates.findIndex((d) => d.rollId === sess.rollId && d.date.isSame(sess.date)) < 0
        ) as SessionAvailability[];

        setPageState({
          hasConflict: true,
          hasError: false,
          unAvailableDates: getMatchingUnAvailableDates(formState, updatedUnAvailableDates),
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      } else {
        setPageState({
          ...pageState,
          hasError: true,
        });
      }
    }
  }, [saveState]);

  useQuery(
    ["getSaveRecurringPreview", unAvailableDates, formState.recurringDays],
    () =>
      getSaveRecurringPreview(
        dispatch,
        customer_account_id as number,
        formState.scheduleId as string,
        new ChangeRecurringBookingPreviewRequest(
          formState.recurringStartDate as dayjs.Dayjs,
          formState.recurringEndDate as dayjs.Dayjs,
          new BookingDaysInfo(
            formState.originalRollId!,
            formState.recurringDays.length > 0 ? formState.recurringDays[0].Week1Days : [],
            []
          ),
          []
        )
      ),
    {
      refetchOnWindowFocus: false,
      enabled:
        unAvailableDates !== null &&
        formState.scheduleId !== undefined &&
        formState.isRecurring &&
        formState.recurringDays.length > 0,
      staleTime: (10 / 60) * (60 * 1000),
    }
  );

  useQuery(
    ["getCreateRecurringPreview", unAvailableDates, formState.recurringDays],
    () =>
      getCreateRecurringPreview(
        dispatch,
        customer_account_id as number,
        new CreateRecurringPreviewRequest(
          formState.children.map((c) => c.child.childId as number),
          formState.recurringStartDate as dayjs.Dayjs,
          formState.recurringEndDate as dayjs.Dayjs,
          BookingScheduleType.Weekly,
          formState.recurringDays.map((rd) => new BookingDaysInfo(rd.roll.rollId, rd.Week1Days, [])),
          getMatchingUnAvailableDates(formState, unAvailableDates).map((un) => new BookingDateInfo(un.rollId, un.date))
        )
      ),
    {
      refetchOnWindowFocus: false,
      enabled:
        unAvailableDates !== null &&
        formState.scheduleId === undefined &&
        formState.isRecurring &&
        formState.recurringDays.length > 0 &&
        formState.recurringStartDate !== null &&
        formState.recurringEndDate !== null,
    }
  );

  useQuery(
    ["getCreateCasualBookingsPreview", unAvailableDates, formState.casualDates],
    () =>
      getCreateCasualBookingsPreview(
        dispatch,
        customer_account_id as number,
        {
          childrenIds: formState.children.map((c) => c.child.childId as number),
          dates: formState.casualDates
            .map((x) => new BookingDateInfo(x.roll.rollId, x.date))
            .filter(
              (x) =>
                getMatchingUnAvailableDates(formState, unAvailableDates).findIndex(
                  (y) => y.rollId === x.rollId && y.date.isSame(x.date)
                ) < 0
            ),
        } as IGetCreateCasualBookingsPreviewRequest
      ),
    {
      refetchOnWindowFocus: false,
      enabled: unAvailableDates !== null && formState.isRecurring === false && formState.casualDates.length > 0,
    }
  );
  useEffect(() => {
    if (unAvailableDates) {
      var unvDates = getMatchingUnAvailableDates(formState, unAvailableDates);
      setPageState({
        ...pageState,
        unAvailableDates: unvDates,
      });
      if (!formState.isRecurring) {
        if (formState.casualDates.length === 0) {
          setPageState({
            hasConflict: false,
            hasError: false,
            unAvailableDates: unvDates,
            newlyAddedUnAvailableDates: [],
          });
        }
      }
    }
  }, [unAvailableDates, formState.recurringDays, formState.casualDates]);

  function getCareTypeDescription() {
    const isRecurring = formState.isRecurring;
    const isBSC = isRecurring
      ? formState.recurringDays.some((x) => x.roll.sessionType === "BSC")
      : formState.casualDates.some((x) => x.roll.sessionType === "BSC");
    const isAsc = isRecurring
      ? formState.recurringDays.some((x) => x.roll.sessionType === "ASC")
      : formState.casualDates.some((x) => x.roll.sessionType === "ASC");

    if (isBSC && isAsc) {
      return "Before and After School Care";
    }
    if (isBSC) {
      return "Before School Care";
    }
    if (isAsc) {
      return "After School Care";
    }
    return "Care Type";
  }

  function getRollName(rollId: number) {
    if (!formState.isRecurring) {
      return formState.casualDates.find((x) => x.roll.rollId === rollId)?.roll.rollName;
    } else if (formState.recurringDays.length > 0) {
      return formState.recurringDays.find((x) => x.roll.rollId === rollId)?.roll.rollName;
    }

    return "";
  }
  function getRollSessionType(rollId: number) {
    if (!formState.isRecurring) {
      return formState.casualDates.find((x) => x.roll.rollId === rollId)?.roll.sessionType;
    } else if (formState.recurringDays.length > 0) {
      return formState.recurringDays.find((x) => x.roll.rollId === rollId)?.roll.sessionType;
    }

    return "";
  }

  function getServiceName() {
    return formState.children.length > 0 && formState.children[0].child.serviceName;
  }

  function getBookingDates() {
    return !formState.isRecurring
      ? formState.casualDates
      : getBookableRollDates(
          formState.recurringStartDate,
          formState.recurringEndDate,
          formState.recurringDays ?? [],
          unAvailableDates ?? []
        );
  }
  function getConstraints() {
    const dates = getBookingDates();
    return new QList(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })));
  }

  function isAgeGreaterThanMax(constraints: QList<ChildAgeConstraint>, child: SelectedChild) {
    return constraints.Where((ct) => ct !== undefined && ct.isAgeGreaterThanMax(child));
  }

  function hasMaxAgeViolation(): boolean {
    const constraints = getConstraints();
    if (constraints.Count() === 0 || formState.children.length === 0) return false;
    return formState.children.some(
      (c) =>
        isAgeGreaterThanMax(constraints, new SelectedChild(c)).Count() > 0 &&
        (c.reason === undefined || c.reason === null || c.reason.trim() === "")
    );
  }

  function CannotGoNext(isSubmitting: boolean, unAvailableDates: ISessionAvailability[] | null): boolean {
    var violatesMaxAge = hasMaxAgeViolation();
    if (formState.scheduleId) {
      return (
        isSubmitting ||
        violatesMaxAge ||
        !canConfirmModifySchedule ||
        formState.recurringDays.filter(
          (r) =>
            r?.isMonday === true ||
            r?.isTuesday === true ||
            r?.isWednesday === true ||
            r?.isThursday === true ||
            r?.isFriday === true
        ).length === 0 ||
        formState.recurringStartDate === null ||
        formState.recurringEndDate === null
      );
    }
    return (
      isSubmitting ||
      unAvailableDates == null ||
      formState.serviceId === undefined ||
      formState.children == null ||
      formState.children.length === 0 ||
      violatesMaxAge ||
      (!formState.isRecurring && formState.casualDates.length === 0) ||
      (formState.isRecurring &&
        (formState.recurringDays.filter(
          (r) =>
            r?.isMonday === true ||
            r?.isTuesday === true ||
            r?.isWednesday === true ||
            r?.isThursday === true ||
            r?.isFriday === true
        ).length === 0 ||
          formState.recurringStartDate === null ||
          formState.recurringEndDate === null ||
          !HasMinimumBookingsAcrossWeeks(isEditMode, previewResult, pageState.unAvailableDates ?? [], formState)))
    );
  }

  function getAgeViolationsReasons() {
    const ageViolationReasons = new Array<AgeViolationReasonInfo>();
    var orderedCasualDates = new QList(formState.casualDates).OrderBy((d) => d.date);

    var from = formState.isRecurring ? formState.recurringStartDate : orderedCasualDates.First().date;
    var to = formState.isRecurring ? formState.recurringEndDate : orderedCasualDates.Last().date;
    formState.children.forEach((c) => {
      var dates = new Array<SelectedRollDate>();
      if (formState.isRecurring) {
        dates = getBookableRollDates(from, to, formState.recurringDays, unAvailableDates ?? []);
      } else {
        dates = formState.casualDates;
      }
      var rollViolations = new QList(dates.map((d) => new ChildAgeConstraint({ rollDate: d.date, session: d.roll })))
        .Where((d) => d !== undefined && d.isAgeGreaterThanMax(new SelectedChild(c)))
        .Select((d) => d.session.rollId)
        .Distinct();
      rollViolations.ForEach((rId) => {
        ageViolationReasons.push(
          new AgeViolationReasonInfo(c.child.childId as number, rId as number, c.reason as string)
        );
      });
    });

    return ageViolationReasons;
  }

  function getDiscounts() {
    return formState.isRecurring
      ? formState.recurringDays
          .filter(
            (r) =>
              previews
                .filter((p) => p.rollId === r.roll.rollId)
                .reduce((acc, curr) => acc + curr.multiChildrenDiscount, 0) > 0
          )
          .map((r) => r.roll)
      : formState.casualDates
          .filter(
            (r) =>
              (casualPreviews ?? [])
                ?.filter((p) => p.rollId === r.roll.rollId)
                .reduce((acc, curr) => acc + curr.multiChildrenDiscount, 0) > 0
          )
          .map((r) => r.roll);
  }

  return (
    <Grid
      container
      direction={"column"}
      justifyContent="space-between"
      alignContent={"space-between"}
      className={classes.section}
      padding={2}
      sx={{ bgcolor: "background.paper", minHeight: "500px" }}
    >
      <Grid container direction={"column"} rowGap={2}>
        <Typography variant="h5" fontWeight={"bold"}>
          Booking Summary
        </Typography>

        <Grid container>
          <Divider
            orientation="vertical"
            flexItem
            color="primary"
            sx={{
              width: 4,
              borderRightWidth: 5,
              alignContent: "center",
              margin: 1,
              borderRadius: 5,
              backgroundColor: "var(--youroshc-color-primary)!important",
            }}
          />
          <Grid item xs={10}>
            <Grid container p={1} alignItems="flex-start" direction="column">
              <Typography variant="subtitle2">{getCareTypeDescription()}</Typography>
              <img src={YourOSHCLogo} alt="Rocketeers Logo" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
        {formState.children.length > 0 && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1} flexWrap="wrap">
              <Stack direction="row" spacing={2} flexWrap="wrap">
                <PersonOutlineIcon fontSize="small" />
                {formState.children.map((child) => {
                  const avatar = avatars.find((x) => x.entityId === child.child.childId?.toString());
                  return (
                    <Stack direction="row" key={child.child.childId} spacing={1} className={classes.stack}>
                      <AvatarDisplay info={avatar || { index: child.child.avatarIndex }} size={25} />
                      <Typography variant="subtitle2">{getChildDisplayName(child.child)}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        )}
        {formState.serviceId && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1}>
              <PlaceOutlinedIcon fontSize="small" />
              <Typography variant="subtitle2">{getServiceName()}</Typography>
            </Grid>
          </Grid>
        )}
        {formState.isRecurring && formState.recurringDays.length > 0 && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1}>
              <CalendarTodayIcon fontSize="small" />

              <Typography variant="subtitle2">{formState.recurringStartDate!.format("MMM DD, YYYY")}</Typography>
              <EastIcon fontSize="small" />
              <Typography variant="subtitle2">{formState.recurringEndDate?.format("MMM DD, YYYY")}</Typography>
            </Grid>
          </Grid>
        )}
        {!formState.isRecurring && formState.casualDates.length > 0 && (
          <Grid item xs={12}>
            <Grid container direction="row" columnGap={1}>
              <CalendarTodayIcon fontSize="small" />
              <Stack
                direction="row"
                spacing={1}
                className={classes.stack}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {Array.from(new Set(formState.casualDates.map((x) => x.date))).map((cd) => {
                  return <Typography variant="subtitle2">{cd.format("MMM DD, YYYY")}</Typography>;
                })}
              </Stack>
            </Grid>
          </Grid>
        )}
        {pageState.unAvailableDates.length > 0 && (
          <Grid item xs={12}>
            {displayValidationErrors()}
          </Grid>
        )}
        {(formState.casualDates.length > 0 || formState.recurringDays.length > 0 || formState.scheduleId) && (
          <Grid item xs={12}>
            <BookingDetails
              formState={formState}
              isFriendlyFee={isFriendlyFee}
              availableBookings={availableBookings}
              unAvailableDates={pageState.unAvailableDates}
            />
          </Grid>
        )}
      </Grid>
      <Grid container direction={"row"} justifyContent="space-between" pt={5}>
        {formState.isRecurring && formState.scheduleId && previewResult !== null && (
          <Grid item xs={12} pb={2}>
            <CancellationNote
              days={
                servicesByCustomer?.find((s) => s.serviceId === formState.serviceId)
                  ?.minimumDaysToAvoidCancellationFeeRecurringBooking ?? 0
              }
              isHotCancellation={
                previewResult?.bookings.filter(
                  (b) =>
                    b.status === BookingStatus3.CancelledInNoticePeriod || b.status === BookingStatus3.CancelledInTime
                ).length === 1
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="caption">*Prices exclude available Child Care Subsidy (CCS)</Typography>
        </Grid>

        {[...new Set(getDiscounts())].map((r, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Typography variant="caption">
                ** <b>{r.rollName}</b> bookings are eligible for multi children’s discount. The discount for eligible
                bookings gets applied to the fortnightly statement
              </Typography>
            </Grid>
          );
        })}

        <Grid item>
          <ButtonPrimary
            style={{
              backgroundColor: getStyleColor("--youroshc-color-primary"),
              color: "black",
              fontWeight: "bold",
            }}
            disabled={!isAllowedToBook || CannotGoNext(isSubmitting, unAvailableDates)}
            onClick={() => {
              if (formState.scheduleId) {
                var changeReq = new ChangeRecurringBookingRequest(
                  getBkooingFrom() as dayjs.Dayjs,
                  getBookingTo() as dayjs.Dayjs,
                  new BookingDaysInfo(
                    formState.recurringDays[0].roll.rollId,
                    formState.recurringDays[0].Week1Days,

                    []
                  ),
                  pageState.unAvailableDates.map((un) => un.date),
                  formState.children[0].reason ?? undefined
                );
                saveRecurringBooking(dispatch, customer_account_id as number, formState.scheduleId!, changeReq);
              } else if (formState.isRecurring) {
                var req = new CreateRecurringBookingsRequest(
                  formState.children.map((c) => c.child.childId as number),
                  getBkooingFrom() as dayjs.Dayjs,
                  getBookingTo() as dayjs.Dayjs,
                  BookingScheduleType.Weekly,
                  formState.recurringDays.map((rd) => new BookingDaysInfo(rd.roll.rollId, rd.Week1Days, [])),
                  pageState.unAvailableDates,
                  getAgeViolationsReasons()
                );
                createRecurringBooking(dispatch, customer_account_id as number, req);
              } else {
                var casReq = new CreateCasualBookingsHttpRequest(
                  formState.children.map((c) => c.child.childId as number),
                  formState.casualDates.map((cd) => new BookingDateInfo(cd.roll.rollId, cd.date)),
                  unAvailableDates ?? [],
                  getAgeViolationsReasons()
                );
                createCasualBookings(dispatch, customer_account_id as number, casReq);
              }
            }}
          >
            Book Now
          </ButtonPrimary>
        </Grid>
        <Grid item>
          <ButtonSecondary
            onClick={() => {
              navigate("/");
            }}
          >
            <strong>Cancel</strong>
          </ButtonSecondary>
        </Grid>
      </Grid>
      <BookingCompleteDialog
        color="var(--youroshc-color-primary)"
        handleClose={() => {
          setShowCompleted({ ...showCompleted, show: false });
        }}
        open={showCompleted.show}
        isSuccessful={showCompleted.success}
        title={
          pageState.hasConflict
            ? saveState.errorResponse?.message ?? "Some sessions are no longer available:"
            : pageState.hasError
            ? saveState.errorResponse?.message ?? ""
            : ""
        }
        onViewClicked={() => {}}
        onBack={() => {
          navigate("/");
        }}
        errorDetails={pageState.hasConflict && noLongerAvailableSessions()}
      />
      {/*** confirm dialog will be part of future addition ***/}
      {/* <ConfirmBookingDialog
        isEdit={formState.scheduleId !== undefined}
        isRecurring={formState.isRecurring}
        title={formState.isRecurring ? "Confirm your Schedule Booking" : "Confirm your Casual Booking"}
        open={showConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={() => {
          if (formState.scheduleId) {
            var changeReq = new ChangeRecurringBookingRequest(
              getBkooingFrom() as dayjs.Dayjs,
              getBookingTo() as dayjs.Dayjs,
              new BookingDaysInfo(
                formState.recurringDays[0].roll.rollId,
                formState.recurringDays[0].Week1Days,

                []
              ),
              pageState.unAvailableDates.map((un) => un.date),
              formState.children[0].reason ?? undefined
            );
            saveRecurringBooking(dispatch, customer_account_id as number, formState.scheduleId!, changeReq);
          } else if (formState.isRecurring) {
            var req = new CreateRecurringBookingsRequest(
              formState.children.map((c) => c.child.childId as number),
              getBkooingFrom() as dayjs.Dayjs,
              getBookingTo() as dayjs.Dayjs,
              BookingScheduleType.Weekly,
              formState.recurringDays.map((rd) => new BookingDaysInfo(rd.roll.rollId, rd.Week1Days, [])),
              pageState.unAvailableDates,
              getAgeViolationsReasons()
            );
            createRecurringBooking(dispatch, customer_account_id as number, req);
          } else {
            var casReq = new CreateCasualBookingsHttpRequest(
              formState.children.map((c) => c.child.childId as number),
              formState.casualDates.map((cd) => new BookingDateInfo(cd.roll.rollId, cd.date)),
              unAvailableDates ?? [],
              getAgeViolationsReasons()
            );
            createCasualBookings(dispatch, customer_account_id as number, casReq);
          }
          setShowConfirm(false);
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            {formState.isRecurring && (
              <Center>
                <Typography variant="subtitle1" fontSize="1rem">
                  Do you want to update following schedule? <br />
                  <b>{formState.recurringStartDate?.format("ddd MMM DD,YYYY")}</b> to{" "}
                  <b>{formState.recurringEndDate?.format("ddd MMM DD,YYYY")}</b>.
                </Typography>
              </Center>
            )}
          </Grid>

          {pageState.unAvailableDates.length > 0 && (
            <Grid item xs={12}>
              {displayValidationErrors()}
            </Grid>
          )}
          {(formState.casualDates.length > 0 || formState.recurringDays.length > 0 || formState.scheduleId) && (
            <Grid item xs={12}>
              <BookingDetails
                formState={formState}
                isFriendlyFee={isFriendlyFee}
                availableBookings={availableBookings}
              />
            </Grid>
          )}
        </Grid>
      </ConfirmBookingDialog> */}
    </Grid>
  );

  function displayValidationErrors() {
    return (
      <Accordion className={classes.accordionError} elevation={1}>
        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ width: "100%" }}>
            <Typography variant="body1" fontWeight={"bold"}>
              The following {pageState.unAvailableDates.length} sessions are unable to be booked
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: "background.paper" }}>{availabilityErrors()}</AccordionDetails>
      </Accordion>
    );
  }

  function noLongerAvailableSessions() {
    return (
      <Grid container spacing={2}>
        {pageState.newlyAddedUnAvailableDates.map((d, i) => {
          return (
            <Grid item xs={12} key={i}>
              <Grid container direction="row" columnGap={1}>
                <Typography variant="subtitle2" fontSize={14}>
                  <b> {d.date.format("ddd MMM DD,YYYY")}</b>
                </Typography>
                <Typography fontSize={14} variant="subtitle2" fontWeight={"bold"}>
                  - {getRollSessionType(d.rollId)}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }
  //
  function availabilityErrors() {
    return (
      <List disablePadding={true}>
        {pageState.unAvailableDates.map((unAvailableDate, index) => {
          return (
            <Tooltip
              key={index}
              arrow
              title={getAvailabilityReasonsText(
                formState.isRecurring
                  ? unAvailableDate.reasonForRecurringBooking
                  : unAvailableDate.reasonForCasualBooking
              )}
              placement="top-end"
            >
              <ListItem disableGutters={true} disablePadding={true} key={index}>
                <ListItemIcon sx={{ minWidth: 25 }}>
                  <CircleIcon fontSize="small" sx={{ fontSize: 10, color: "black" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 14 }}
                  primary={`${unAvailableDate.date.format("dddd MMMM DD, YYYY")} - ${getRollName(
                    unAvailableDate.rollId
                  )}`}
                />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    );
  }
}
