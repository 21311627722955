import React, { ReactNode, useState } from "react";

//import Autocomplete from "@mui/lab/Autocomplete";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import { makeStyles } from "tss-react/mui";

import { isNullOrEmpty } from "../../../utils/stringUtils";

export interface AutocompleteProperties<T> {
  id: string;
  label: string;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  getOptionSelected: ((option: T, value: T) => boolean) | undefined;
  getOptionLabel: ((option: T) => string) | undefined;

  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => ReactNode;

  onPatternChange: any;
  defaultValue: T | null | undefined;
  helperText: any;
  error: any;
  minCharacters: number;
  maxCharacters: number;
}

const useStyles = makeStyles()((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
}));

export function AutocompleteBase<T>(props: AutocompleteProperties<T>) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const loading = open && options.length === 0;
  const { classes } = useStyles();

  const onPatternChange = debounce(async (value: string) => {
    const response = await props.onPatternChange(value);
    setOptions(response);
  }, 500);

  return (
    <FormControl variant="filled" className={classes.formControl} fullWidth required>
      <Autocomplete
        multiple={false}
        freeSolo={false}
        id={props.id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(
          event: any,
          newValue: any,
          reason: AutocompleteChangeReason,
          details?: AutocompleteChangeDetails<T>
        ) => {
          if (reason === "clear") {
            setOptions([]);
          }
          props.onChange && props.onChange(event, newValue, reason, details);
        }}
        getOptionLabel={props.getOptionLabel}
        options={options}
        loading={loading}
        defaultValue={props.defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="filled"
            onChange={(ev) => {
              var targetValue = decodeURIComponent(ev.target.value).trim();
              if (!isNullOrEmpty(targetValue)) {
                if (targetValue.length >= props.minCharacters && targetValue.length <= props.maxCharacters) {
                  onPatternChange(targetValue);
                }
              }
            }}
            InputProps={{
              ...params.InputProps,
            }}
            required
            helperText={props.helperText}
            error={props.error}
          />
        )}
        renderOption={props.renderOption}
      />
    </FormControl>
  );
}
