import { createActionCreator } from "deox";

import { IPrivacySetting, PrivacySettingType } from "../../../types/types";
export const loadPrivacyPending = createActionCreator("loadPrivacyPending");
export const savePrivacyPending = createActionCreator("savePrivacyPending");
export const loadPrivacyError = createActionCreator("loadPrivacyError");
export const savePrivacyError = createActionCreator("savePrivacyError");

export const savePrivacyInit = createActionCreator("savePrivacyInit");

export const loadPrivacySuccess = createActionCreator(
  "loadPrivacySuccess",
  (resolve) => (settings: IPrivacySetting) => resolve(settings)
);
export const savePrivacySuccess = createActionCreator(
  "savePrivacySuccess",
  (resolve) => (setting: PrivacySettingType) => resolve(setting)
);
