import { List } from "linqts";

import { RegisterState } from "../store/modules/register/registerReducer";
import { Child, MedicalPlanAnswer, MedicalPlanQuestionTypes } from "../types/models";
import {
  ChildBookingStatus,
  ChildServiceStatus,
  ConditionIds,
  IAnswer,
  IQuestionTemplate,
  IRegistrationMedicalPlanAnswer,
} from "../types/types";

export const OtherMedicalConditionQuestionId = 10;

export const PhysicalConditionId = 111;

export function isBlockingAnswer(ans: IAnswer | IRegistrationMedicalPlanAnswer): unknown {
  return (
    (ans.conditionId >= 101 &&
      ans.conditionId <= 110 &&
      ans.questionId >= 25002 &&
      ans.questionId <= 25009 &&
      ans.value === "1") ||
    (ans.conditionId === ConditionIds.Physical && ans.value === "1") ||
    ans.conditionId === ConditionIds.DiabetesTypeOne ||
    ans.conditionId === ConditionIds.Epilepsy
  );
}

export function isChildBlockedForAllServices(child: Child, serviceId: number | undefined, register: RegisterState) {
  const childBookingStatus = child.childBookingStatus;
  const childServices = child.childServices;
  var isBlockedForAllServices =
    childBookingStatus !== undefined && childBookingStatus === ChildBookingStatus.BlockedForAllServices;

  const isBlockedForService =
    childBookingStatus !== null &&
    serviceId !== undefined &&
    childBookingStatus === ChildBookingStatus.ApprovedForSpecificServices &&
    childServices !== null &&
    childServices?.find((x) => x.serviceId === serviceId && x.status === ChildServiceStatus.BookingsApproved) ===
      undefined;

  if (!isBlockedForAllServices && !isBlockedForService && register.isCompleted && register.children.length > 0) {
    var registrationChild = register.children.find(
      (x) => x.firstName === child.firstName && x.lastName === child.lastName
    );
    if (
      registrationChild !== undefined &&
      registrationChild.medicalPlanAnswers?.some((x: IRegistrationMedicalPlanAnswer) => {
        return isBlockingAnswer(x);
      })
    ) {
      isBlockedForAllServices = true;
    }
  }

  return isBlockedForAllServices || isBlockedForService;
}

export function isValidMedicalPlanAnswers(allQuestions: IQuestionTemplate[], answers: List<MedicalPlanAnswer>) {
  var isValidMedical = !answers.Any((an) => an !== undefined && !an.isValid);

  for (var answer of answers.ToArray()) {
    if (answer.value === "1") {
      const questionId = answer.questionId;
      const medicalConditionId = answer.conditionId;
      var childQuestions = allQuestions?.filter((q: any) => q.parentQuestionId === questionId && q.required) ?? [];
      if (childQuestions.length > 0) {
        for (var childQuestion of childQuestions) {
          // YesNo have a default value to No. Document doesn't have validator
          if (
            childQuestion.questionTypeId !== MedicalPlanQuestionTypes.YesNo &&
            childQuestion.questionTypeId !== MedicalPlanQuestionTypes.Document
          ) {
            const childQuestionId = childQuestion.questionId;
            var childQuestionAnswer = answers.FirstOrDefault(
              (a) =>
                a?.questionId === childQuestionId && a.multipleAnswerIndex === 0 && a.conditionId === medicalConditionId
            );
            if (!childQuestionAnswer) {
              isValidMedical = false;
              break;
            }
          }
        }
      }
    }
  }
  return isValidMedical;
}
