import { createActionCreator } from "deox";

import { Contact, CustomerAccount } from "../../../types/types";

export const customerLoading = createActionCreator("customerLoading");
export const customerError = createActionCreator("customerError");
export const customerSuccess = createActionCreator(
  "customerSuccess",
  (resolve) => (customer: CustomerAccount) => resolve(customer)
);

export const contactUpdating = createActionCreator("contactUpdating");
export const contactUpdated = createActionCreator(
  "contactUpdated",
  (resolve) => (contact: Contact, isNew?: boolean) => resolve({ contact, isNew })
);

export const contactUpdateError = createActionCreator("contactUpdateError");

export const removeTemporaryAvatars = createActionCreator("removeTemporaryAvatars");
export const contactReset = createActionCreator("contactReset");
