import RepeatIcon from "@mui/icons-material/Repeat";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import { NumericFormat } from "react-number-format";

import { OshcBookingDetails, SelectedRollDay } from "../../../types/models";
import { FeeType, IBookingInfo3 } from "../../../types/types";

interface IProps {
  bookingsGroupedByFee: {
    [key: string]: IBookingInfo3[];
  };
  isFriendlyFee: boolean;
  formState: OshcBookingDetails;
  roll: SelectedRollDay;
  weekDays: string;
}

export default function EditScheduleNewBookings(props: IProps) {
  const { bookingsGroupedByFee, isFriendlyFee, formState, roll, weekDays } = props;
  return (
    <List disablePadding={true} sx={{ p: 1 }}>
      {Object.keys(bookingsGroupedByFee).map((feeType, index) => {
        const allAvBookings = bookingsGroupedByFee[feeType];

        return (
          <ListItem
            disableGutters
            alignItems="center"
            key={index}
            secondaryAction={
              <span className="small">
                <NumericFormat
                  displayType="text"
                  prefix="$"
                  value={allAvBookings[0].fee * allAvBookings.length}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix="*"
                />
              </span>
            }
          >
            <ListItemAvatar sx={{ minWidth: "35px" }}>
              <RepeatIcon fontSize="small" />
            </ListItemAvatar>
            <ListItemText
              disableTypography={true}
              primary={
                <Typography variant="subtitle2" fontWeight="bold">
                  {roll.roll.rollName}
                  {(feeType as FeeType) === FeeType.Casual ? " (Casual)" : ""}
                  {" - New bookings"}
                </Typography>
              }
              secondary={
                <Stack direction="column">
                  {feeType !== FeeType.Casual && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: "break-word",
                        fontSize: {
                          xl: "1rem",
                          lg: "0.9rem",
                          md: "0.9rem",
                          sm: "0.7rem",
                          xs: "0.7rem",
                        },
                      }}
                    >
                      Occurs every {weekDays}
                    </Typography>
                  )}

                  <Typography variant="subtitle2">
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        prefix={`${allAvBookings.length} x $`}
                        value={
                          isFriendlyFee || (formState.isRecurring && feeType !== FeeType.Casual)
                            ? roll.roll.primaryFeeAmount ?? 0
                            : roll.roll.casualFeeAmount ?? 0
                        }
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="*"
                      />
                    </span>
                  </Typography>
                </Stack>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}
