import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList/ImageList";
import ImageListItem from "@mui/material/ImageListItem/ImageListItem";
import RadioGroup from "@mui/material/RadioGroup";
import dayjs from "dayjs";
import { IoImagesOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import GalleryPhotoItem from "../../components/Children/GalleryPhotoItem";
import { ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import StyledRadio from "../../components/Common/Buttons/StyledRadio";
import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import { CardLayout } from "../../layouts/Layouts";
import { getCustomerChildrenGallery } from "../../store/modules/gallery/galleryActions";
import { RootState } from "../../store/reducers";
import { Child } from "../../types/models";
import { IGalleryChildPhoto } from "../../types/types";
import { useViewport } from "../../utils/hooks";

const useStyles = makeStyles()((theme) => {
  return {
    h1: {
      marginBottom: 0,
    },
    caption: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "auto",
      padding: "10px",
      backgroundColor: "rgba(255,255,255,.8)",
      color: "black",
      fontSize: "16px",
      lineHeight: "20px",
      minHeight: "40px",
      maxHeight: "180px",
      overflow: "auto",
    },
  };
});

function Gallery() {
  const { width } = useViewport();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customerChildren = useSelector((state: RootState) => state.child.childrenByCustomer);
  const [selectedChild, setSelectedChild] = useState<Child | null>(null);
  const [childImages, setChildImages] = useState(new Array<IGalleryChildPhoto>());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(false);

  function loadPhotos(take: number, limit: number) {
    setIsLoading(true);
    getCustomerChildrenGallery(customer_account_id!, selectedChild?.childId ?? null, take, limit).then(
      (result) => {
        if (take === 0) {
          setChildImages(result ?? []);
        } else {
          var imgs = childImages.splice(0);
          imgs.push(...(result ?? []));
          setChildImages(imgs);
        }
        setIsLoading(false);
        setIsLoadMoreVisible(result !== null && result?.length >= limit);
      },
      (error) => {
        setChildImages([]);
        setIsLoading(false);
      }
    );
  }

  useEffect(() => {
    if (customer_account_id) {
      setIsLoading(true);
      loadPhotos(0, 10);
    }
  }, [selectedChild]);

  const { classes } = useStyles();

  return (
    <CardLayout>
      <CardLayout.Header bgColor="white">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <h1 className={`h2 ${classes.h1}`}>Gallery</h1>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup row={true} aria-label="service" name="customized-radios">
              <Box pr={1} pt={1} key="StyledRadio_Child_All">
                <StyledRadio
                  width={90}
                  itemKey={"StyledRadio_Child_All"}
                  checked={selectedChild == null}
                  label={"ALL"}
                  group="child"
                  onChange={(evt) => {
                    setSelectedChild(null);
                  }}
                />
              </Box>
              {customerChildren &&
                customerChildren.map((c, index) => (
                  <Box pr={1} pt={1} key={index}>
                    <StyledRadio
                      width={90}
                      itemKey={"StyledRadio_Child_" + c.childId}
                      checked={selectedChild?.childId === c.childId}
                      label={c.firstName}
                      group="child"
                      value={c.childId}
                      onChange={(evt) => {
                        setSelectedChild(c);
                      }}
                    />
                  </Box>
                ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={2} marginBottom={2}>
              <Divider />
            </Box>
          </Grid>
          {childImages && childImages.length > 0 ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageList rowHeight={400} gap={16} cols={width >= 1280 ? 2 : 1}>
                    {childImages.map((tile) => (
                      <ImageListItem key={tile.uniqueId}>
                        <GalleryPhotoItem customer_account_id={customer_account_id!} photo={tile} />
                        <div className={classes.caption}>
                          {tile.title}
                          <br />
                          {dayjs(tile.uploadedAt).format("D MMM YYYY")}
                        </div>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
                {isLoadMoreVisible && (
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="center" alignContent="center">
                      <ButtonSecondary
                        onClick={() => {
                          loadPhotos(childImages.length, 10);
                        }}
                      >
                        Load More
                      </ButtonSecondary>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="center" alignContent="center" direction="column">
                <IoImagesOutline size={60} color="gray" />
                <p className="color-dark-grey">No photos found</p>
              </Grid>
            </Grid>
          )}
        </Grid>
        <LoadingDialog open={isLoading} />
      </CardLayout.Body>
    </CardLayout>
  );
}

export default Gallery;
