import WarningIcon from "@mui/icons-material/Warning";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

import styles from "./Unavailable.module.scss";
import pplogo from "../../assets/logos/ca-logo-master-green.png";
import { EmptyLayout } from "../../layouts/Layouts";
import { RootState } from "../../store/reducers";

function Unavailable() {
  const appStatus = useSelector((state: RootState) => state.appStatus.appStatus);

  var description =
    appStatus != null && appStatus.description != null
      ? appStatus.description
      : "Unfortunately we are experiencing intermittent issues with the Parent Portal. Please check back soon";
  return (
    <EmptyLayout>
      <Grid container spacing={0} direction="column" alignItems="center" className={styles["Unavailable"]}>
        <Grid item>
          <WarningIcon sx={{ color: "yellow", fontSize: 80 }} />
        </Grid>
        <Grid item className={styles["Unavailable-description"]}>
          <div dangerouslySetInnerHTML={{ __html: description || "" }} className="dangerouslySetInnerHTML"></div>
        </Grid>
        <Grid item className={styles["Unavailable-logo"]}>
          <img src={pplogo} alt="Camp Australia" />
        </Grid>
      </Grid>
    </EmptyLayout>
  );
}

export default Unavailable;
