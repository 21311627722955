import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { OverDueStatus } from "./overDueActions";
import { hideOverDue, showOverDue } from "./overDueStateActions";

export interface OverDueState {
  status: OverDueStatus;
}

export const initialState: OverDueState = {
  status: "Initial",
};

const overDueReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    showOverDue,
    (state, action): OverDueState => ({
      ...state,
      status: "Show",
    })
  ),

  handleAction(
    hideOverDue,
    (state, action): OverDueState => ({
      ...state,
      status: "Hide",
    })
  ),

  handleAction(purge, () => initialState),
]);

export default overDueReducer;
