import { createReducer } from "deox";

import { IBookingCancellationReasonModel } from "../../../types/types";
import {
  BookingCancellationReasonsError,
  BookingCancellationReasonsLoading,
  BookingCancellationReasonsSuccess,
  BookingCancellationReasonsInitial,
} from "./bookingStateActions";

export interface BookingCancellationReasonsState {
  reasons: IBookingCancellationReasonModel[];
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
}

export const initialState: BookingCancellationReasonsState = {
  reasons: [],
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
};

const bookingCancellationReasonsReducer = createReducer(initialState, (handleAction) => [
  handleAction(BookingCancellationReasonsInitial, (state) => ({
    ...state,
    reasons: [],
    isErrorState: false,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(BookingCancellationReasonsSuccess, (state, { payload }) => ({
    ...state,
    reasons: payload,
    isErrorState: false,
    isInitialised: true,
    isLoading: false,
  })),
  handleAction(BookingCancellationReasonsLoading, (state) => ({
    ...state,
    reasons: [],
    isErrorState: false,
    isInitialised: false,
    isLoading: true,
  })),
  handleAction(BookingCancellationReasonsError, (state) => ({
    ...state,
    reasons: [],
    isErrorState: true,
    isInitialised: false,
    isLoading: false,
  })),
]);

export default bookingCancellationReasonsReducer;
