import React from "react";

import Divider from "@mui/material/Divider/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./Layouts.module.scss";
import pplogo from "../assets/logos/parent-portal-logo.png";
import Footer from "../components/Footer/Footer";
import InitialFooter from "../components/Footer/InitialFooter";
import DesktopSideNav from "../components/Header/DesktopSideNav";
import Header from "../components/Header/Header";

interface Props {
  children: React.ReactNode;
  header?: boolean;
  topSection?: React.ReactNode;
  showSideNav?: boolean;
}

interface ISectionLayoutProps {
  children: React.ReactNode;
  id?: string;
}

interface ICardHeader {
  bgColor?: string;
  children: React.ReactNode;
}

interface ICardBody {
  style?: any;
  children: React.ReactNode;
}

interface ICardFooter {
  style?: any;
  children: React.ReactNode;
}

function LayoutInitialPages(props: Props) {
  const { children } = props;
  return (
    <>
      <main className={styles.InitialLayout}>
        <div className={styles["InitialLayout-logo"]}>
          <img
            style={{ width: 150, height: 57, boxSizing: "border-box", overflow: "hidden", margin: 10 }}
            src={pplogo}
            alt="campAustraliaLogo"
          />
        </div>
        <div className={styles["InitialLayout-body"]}>{children}</div>
        <div
          className={styles["InitialLayout-footer"]}
          style={{ zIndex: 100, bottom: 0, width: "100%", boxSizing: "border-box", overflow: "hidden" }}
        >
          <InitialFooter />
        </div>
      </main>
    </>
  );
}

function LayoutAccent1(props: Props) {
  const { header = true, children, showSideNav = true } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <main className={`${styles.BaseLayout}  ${styles["BaseLayout--Solid"]}`}>
      {header && (
        <div className={styles["BaseLayout-header"]}>
          <Header />
        </div>
      )}
      <div className={styles["BaseLayout-wrapper"]}>
        {!isMobile && showSideNav && <DesktopSideNav />}
        <div className={styles["BaseLayout-body"]}>{children}</div>
      </div>
      <div className={styles["BaseLayout-footer"]}>
        <Footer />
      </div>
      {/* <div className={styles["Cloud-2"]}>
        <Cloud />
      </div>
      <div className={styles["Arch-3"]}>
        <Arch />
      </div> */}
    </main>
  );
}

function CardLayout(props: ISectionLayoutProps) {
  return (
    <LayoutAccent1>
      <div className={styles.SectionLayout} {...(props.id ? { id: props.id } : {})}>
        <div className={styles["SectionLayout-Card"]}>{props.children}</div>
      </div>
    </LayoutAccent1>
  );
}

CardLayout.Header = (props: ICardHeader) => (
  <div
    className={styles["SectionLayout-Header"]}
    // style={{ backgroundColor: props.bgColor ?? getStyleColor("--color-primary") }}
  >
    {props.children}
    <Divider sx={{ marginTop: 0 }} />
  </div>
);

CardLayout.Body = (props: ICardBody) => (
  <div className={styles["SectionLayout-Body"]} style={props.style}>
    {props.children}
  </div>
);

CardLayout.Footer = (props: ICardFooter) => (
  <div className={styles["SectionLayout-Footer"]} style={props.style}>
    {props.children}
  </div>
);

function EmptyLayout(props: Props) {
  return <main className={`${styles.BaseLayout}`}>{props.children}</main>;
}

export { LayoutInitialPages, LayoutAccent1, CardLayout, EmptyLayout };
