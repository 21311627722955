import React from "react";

import styles from "./Pager.module.scss";

interface Props {
  children: any;
}

const Pager = (props: Props) => {
  const { children } = props;
  return <div className={styles["Pager"]}>{children}</div>;
};

export default Pager;
