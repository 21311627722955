import { useEffect } from "react";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { CardLayout } from "../../layouts/Layouts";
import { getStyleColor } from "../../utils/helpers";

function BookingComplete() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <CardLayout>
      <CardLayout.Body
        style={{
          backgroundColor: getStyleColor("--color-primary"),
          minHeight: 300,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container alignItems="center" direction="column">
          <h1 className="h3">Booking Complete</h1>
          <IconButton
            onClick={() => {
              navigate("/");
            }}
          >
            <IoCheckmarkCircle fontSize={90} color="black" />
          </IconButton>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default BookingComplete;
