import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { List } from "linqts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as actions from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/reducers";
import { MedicalPlanAnswer } from "../../../types/models";
import {
  ConditionTypes,
  DocumentTypes,
  IChild,
  IQuestionTemplate,
  IRegistrationMedicalPlanAnswer,
} from "../../../types/types";
import { OtherMedicalConditionQuestionId, isValidMedicalPlanAnswers } from "../../../utils/medicalConditionsHelper";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import MedicalConditionQuestionnaire from "../../Common/Controls/MedicalConditionQuestionnaire";
import LoadingDialog from "../../Common/Dialogs/LoadingDialog";
import Pager from "../../Common/Pager/Pager";

interface Props {
  child: IChild;
  id: string;
  medicalConditionIndex: number;
}

function FormChildStep5({ child, id, medicalConditionIndex }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const medicalConditions = useSelector((state: RootState) => state?.commonData?.data?.medicalPlanTemplate?.conditions);
  const otherMedicalConditionsIds =
    medicalConditions?.filter((x) => x.conditionTypeId === ConditionTypes.Other).map((x) => x.conditionId) ?? [];

  var currentMedicalConditionIndex = medicalConditionIndex ?? 0;

  var selectedMedicalConditions = new List(child.medicalConditions)
    .Select((x) => x)
    .Distinct()
    .OrderBy((x) => medicalConditions?.findIndex((m) => m.conditionId === x))
    .ToArray();

  var currentMedicalConditionId = selectedMedicalConditions![currentMedicalConditionIndex];

  var medicalCondition = medicalConditions?.find((x) => x.conditionId === currentMedicalConditionId);
  var medicalConditionName = medicalCondition?.name;

  if (medicalCondition && otherMedicalConditionsIds.includes(medicalCondition.conditionId)) {
    var otherMedicalConditionAnswer = child.medicalPlanAnswers?.find(
      (x) => x.conditionId === currentMedicalConditionId && x.questionId === OtherMedicalConditionQuestionId
    );
    if (otherMedicalConditionAnswer) {
      medicalConditionName = `Other Condition: ${otherMedicalConditionAnswer.value}`;
    }
  }

  function updateChild(values: IChild) {
    actions.saveChild(dispatch, values);
    if (currentMedicalConditionIndex < child.medicalConditions!.length - 1) {
      navigate(`/registration-child-step/5/${id}/${currentMedicalConditionIndex + 1}`);
    } else {
      navigate(`/registration-child-step/6/${id}`);
    }
  }

  function goBack() {
    if (currentMedicalConditionIndex > 0) {
      navigate(`/registration-child-step/5/${id}/${currentMedicalConditionIndex - 1}`);
    } else {
      navigate(`/registration-child-step/4/${id}`);
    }
  }

  const medicalPlanTemplate = useSelector((state: RootState) => state?.commonData?.data.medicalPlanTemplate);

  const childDocuments = useSelector((state: RootState) =>
    state.register?.documents.filter(
      (document) =>
        document.registrationChildUniqueId === child.uniqueId &&
        document.documentTypeId === DocumentTypes.ChildMedicalDocument
    )
  );

  var allQuestions = new List(medicalPlanTemplate?.questions ?? []);

  return (
    <Formik
      key={"Formik_MedCondition_" + currentMedicalConditionIndex}
      initialValues={child}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild(values);
        }, 500);
      }}
    >
      {({ setFieldValue, submitForm, isSubmitting, values, touched, errors, handleChange, handleBlur, resetForm }) => {
        return (
          <Form key={"MedicalConditionQuestionnaire_Container_" + currentMedicalConditionId}>
            <Typography variant="h5">{medicalConditionName}</Typography>
            <MedicalConditionQuestionnaire
              allQuestions={allQuestions.Where((x) => x?.visible ?? true)}
              childAnswers={
                new List(
                  values.medicalPlanAnswers?.filter((ans) => ans.conditionId === currentMedicalConditionId) ?? []
                )
              }
              childUniqueId={child.uniqueId}
              documents={childDocuments}
              medicalConditionId={currentMedicalConditionId}
              onAnswersChanged={(answers) => {
                var childAnswers =
                  values.medicalPlanAnswers?.filter(
                    (ans) =>
                      ans.conditionId !== currentMedicalConditionId ||
                      ans.questionId === OtherMedicalConditionQuestionId
                  ) ?? [];
                childAnswers.push(...answers.ToArray());
                setFieldValue("medicalPlanAnswers", childAnswers);
              }}
            />
            <Pager>
              <ButtonSecondary
                onClick={() => {
                  goBack();
                }}
              >
                <strong>Back</strong>
              </ButtonSecondary>
              <ButtonPrimary
                disabled={CannotGoNext(
                  isSubmitting,
                  allQuestions,
                  new List(values.medicalPlanAnswers ?? []),
                  currentMedicalConditionId
                )}
                onClick={submitForm}
              >
                <strong>Next</strong>
              </ButtonPrimary>
            </Pager>
            <LoadingDialog open={isSubmitting} message="Saving..." />
          </Form>
        );
      }}
    </Formik>
  );
}
function CannotGoNext(
  isSubmitting: boolean,
  allQuestions: List<IQuestionTemplate>,
  currentAnswers: List<IRegistrationMedicalPlanAnswer>,
  currentMedicalConditionId: number
) {
  var mappedAnswers = currentAnswers.Select((ans) => {
    var q = allQuestions.FirstOrDefault((x) => x?.questionId === ans.questionId);
    return new MedicalPlanAnswer({
      ...ans,
      isFreeTextRequired: (q?.allowFreeText && q?.freeTextRequired) ?? false,
      isRequired: q?.required ?? false,
    });
  });

  var medicalAnswersValid = isValidMedicalPlanAnswers(
    allQuestions!.ToArray(),
    mappedAnswers.Where((and) => and?.conditionId === currentMedicalConditionId).ToList()
  );
  return isSubmitting || !medicalAnswersValid;
}
export default FormChildStep5;
