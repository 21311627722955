import { createReducer } from "deox";

import { acknowledgedBookingOnHoldMessage } from "./bookingOnHoldMessageActionTypes";

export interface BookingOnHoldMessageState {
  bookingOnHoldMessageAcknowledged: boolean;
}

const initialState: BookingOnHoldMessageState = {
  bookingOnHoldMessageAcknowledged: false,
};

const bookingOnHoldReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    acknowledgedBookingOnHoldMessage,
    (state, action): BookingOnHoldMessageState => ({
      ...state,
      bookingOnHoldMessageAcknowledged: true,
    })
  ),
]);

export default bookingOnHoldReducer;
