import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { List } from "linqts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import ChildEventBookingCard from "../../components/Forms/Events/ChildEventBookingCard";
import { CardLayout } from "../../layouts/Layouts";
import { saveEventsBooking } from "../../store/modules/events/eventsActions";
import { RootState } from "../../store/reducers";
import { Child, SelectedBookingEvent } from "../../types/models";
import { getStyleColor } from "../../utils/helpers";
import { GetPageState } from "../../utils/pageUtil";

interface IEventBookingConfirm {
  eventBookings: SelectedBookingEvent[];
}

export default function NewEventBookingConfirm() {
  const navigate = useNavigate();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const state = GetPageState<IEventBookingConfirm>();

  return (
    <Formik
      initialValues={{}}
      onSubmit={(_, { setSubmitting }) => {
        var isValid = true;
        if (isValid) {
          saveEventsBooking(customer_account_id!, {
            bookings: state!.eventBookings!.map((ev) => {
              var bookedChildren = new Array<Child>();
              bookedChildren.push(...ev.addChildren);
              bookedChildren.push(
                ...ev.bookedChildren.filter((b) => ev.removeChildren.findIndex((c) => c.childId === b.childId) < 0)
              );
              return {
                onlineSessionId: ev.bookingEvent.onlineSessionId,
                childrenIds: bookedChildren.map((c) => c.childId!),
              };
            }),
          }).then((result) => {
            setSubmitting(false);
            if (result) {
              navigate("/event-booking-complete");
            }
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <CardLayout>
            <CardLayout.Header bgColor={getStyleColor("--color-accent-5")}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h1 className="h3">Confirmation</h1>
                </Grid>
                <Grid item>
                  <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end"></Grid>
                </Grid>
              </Grid>
            </CardLayout.Header>
            <CardLayout.Body>
              <Grid container>
                <Grid item xs={12} md={8}>
                  {state &&
                    new List(state?.eventBookings ?? [])
                      .Select((b) => (
                        <ChildEventBookingCard
                          key={"ChildEventBookingCard_" + b.bookingEvent.onlineSessionId}
                          bookingEvent={b}
                        />
                      ))
                      .ToArray()}
                  <Box marginTop={4}>
                    <span className="small">* Event charges will appear in your fortnightly statement</span>
                  </Box>
                  <LoadingDialog open={isSubmitting} message="Confirming booking" />
                </Grid>
              </Grid>
            </CardLayout.Body>
            <CardLayout.Footer>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ButtonSecondary
                      onClick={() => {
                        navigate("/new-event-booking", { state: JSON.stringify(state) });
                      }}
                    >
                      <strong>Back</strong>
                    </ButtonSecondary>
                  </Grid>
                  <Grid item>
                    <ButtonPrimary
                      type="submit"
                      style={{ backgroundColor: getStyleColor("--color-accent-5") }}
                      disabled={isSubmitting}
                    >
                      <strong>Confirm</strong>
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </CardLayout.Footer>
          </CardLayout>
        </Form>
      )}
    </Formik>
  );
}
