import React, { useEffect } from "react";

import RepeatIcon from "@mui/icons-material/Repeat";
import Box from "@mui/material/Box/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid/Grid";
import Link from "@mui/material/Link/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { List as LinqList } from "linqts";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { CancellationDialog } from "./CancellationDialog";
import styles from "./UpcomingBookings.module.scss";
import { useAppDispatch } from "../../../store/hooks";
import { getUpcomingBookings } from "../../../store/modules/booking/bookingActions";
import { CancelBookingInitial } from "../../../store/modules/booking/bookingStateActions";
import { RootState } from "../../../store/store";
import { BookingInfo, EntityType } from "../../../types/models";
import { Booking, IEditRecurringBookingState } from "../../../types/types";
import { getCareTypeColor } from "../../../utils/helpers";
import { AvatarDisplay } from "../Controls/AvatarDisplay";
import Spacer from "../Spacers/Spacers";

interface IProps {
  isAllowedToBook: boolean;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 2,
  },
  childName: {
    maxWidth: "70px",
    //wordWrap: "none",
    wordBreak: "break-all",
    overflowWrap: "anywhere",
  },
  accordionSummary: {
    alignItems: "flex-start",
  },
  lateCancellationColor: {
    backgroundColor: "#b42931",
  },
  cancelled: {
    textDecoration: "line-through",
  },
}));

export default function UpcomingBookings(props: IProps) {
  const { isAllowedToBook } = props;
  const { classes } = useStyles();
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );

  const { isSuccess: bookingCanceled } = useSelector((state: RootState) => state.bookingCancel);

  const [selectedBooking, setSelectedBooking] = React.useState<BookingInfo | undefined>();

  const [isSchedule, setIsSchedule] = React.useState(false);

  const [bookings, setBookings] = React.useState<{ records: BookingInfo[]; total: number | undefined }>({
    records: [],
    total: undefined,
  });
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id) ?? 0;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentBooking, setCurrentBooking] = React.useState<null | BookingInfo>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const bookingsByDate = new LinqList<BookingInfo>(bookings.records)
    .OrderBy((b) => b.date)
    .GroupBy((g) => g.date.format("DD MMMM YYYY"));

  const bookingsByDateArr = Object.keys(bookingsByDate);
  const handleClick = (event: React.MouseEvent<HTMLElement>, booing: BookingInfo) => {
    setAnchorEl(event.currentTarget);
    setCurrentBooking(booing);
  };

  const [cancellationPopUpOpen, setCancellationPopUpOpen] = React.useState(false);

  const handleCancellationPopUpOpen = () => {
    setCancellationPopUpOpen(true);
    handleClose();
  };

  const handleCancellationPopUpClose = () => {
    setSelectedBooking(undefined);
    setCancellationPopUpOpen(false);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUpcomingBookings(dispatch, customer_account_id, 0, 15).then((data) => {
      if (data) {
        setBookings({ records: data.records?.map((x) => new BookingInfo(x as Booking)) ?? [], total: data.total });
      }
    });
  }, [customer_account_id, bookingCanceled]);

  return (
    <Grid container direction="column" spacing={2} sx={{ bgcolor: "background.paper", p: 1, pt: 1 }}>
      {/* CANCELLATION DIALOG WINDOW */}
      {selectedBooking && cancellationPopUpOpen && (
        <CancellationDialog
          childName={children?.find((x) => x.childId === selectedBooking.childId)?.firstName ?? ""}
          minimumDaysToAvoidCancellationFee={selectedBooking.minimumDaysToAvoidCancellationFee ?? 0}
          className={`${styles[getCareTypeColor(selectedBooking.roll?.sessionType ?? "", "Bg")]}`}
          careTypeColor={getCareTypeColor(selectedBooking.roll?.sessionType ?? "")}
          customer_account_id={customer_account_id}
          date={selectedBooking.date}
          handleClose={handleCancellationPopUpClose}
          open={cancellationPopUpOpen}
          serviceId={selectedBooking.roll!.serviceId!}
          rollName={selectedBooking.roll!.rollName!}
          schedule={
            isSchedule
              ? {
                  endDate: selectedBooking.schedule!.effectiveToDate!,
                  startDate: selectedBooking.schedule!.effectiveFromDate!,
                  scheduleId: selectedBooking.schedule!.uniqueId,
                }
              : undefined
          }
          session={
            !isSchedule
              ? {
                  bookingId: selectedBooking.uniqueId,
                  sessionDate: selectedBooking.date,
                }
              : undefined
          }
        />
      )}

      {/* CANCELLATION DIALOG WINDOW END */}

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" sx={{ textDecoration: "underline" }} component={RouterLink} to={"/bookings"}>
          <b>Upcoming Bookings</b>
        </Typography>
        <Link
          component={RouterLink}
          to="/bookings"
          sx={{ color: "#464646", textDecorationColor: "#464646", fontSize: 14 }}
        >
          View All
        </Link>
      </Grid>
      <Spacer margin={10} />

      <List
        sx={{
          width: "100%",
          rowGap: 5,
          maxHeight: 700,
          position: "relative",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {bookingsByDateArr.map((date, index) => {
          return (
            <Box key={`date-${index}`} sx={{ pr: "5px" }}>
              <Typography variant="subtitle2" color="black">
                <b>{dayjs(date).format("dddd, DD MMMM YYYY")}</b>
              </Typography>
              <Box sx={{ p: 1 }}></Box>
              {bookingsByDate[date].map((bk: BookingInfo, index) => {
                const child = children?.find((x) => x.childId === bk.childId) ?? null;
                const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());
                const careType = bk.roll?.rollClassificationId === 9 ? "Pfd" : bk.roll?.sessionType ?? "";
                const isLateCancellation = bk.cancellationStatusId === 2;
                return (
                  <ListItem
                    key={`booking-${index}`}
                    alignItems="flex-start"
                    className={styles[getCareTypeColor(careType)]}
                    onClick={(event) => (bk.isCancelled ? null : handleClick(event, bk))}
                    sx={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderRadius: "5px",
                    }}
                  >
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={`${
                        isLateCancellation ? classes.lateCancellationColor : styles[getCareTypeColor(careType, "Bg")]
                      }`}
                      sx={{ width: 0.01, borderRightWidth: 5, alignContent: "center", margin: 1, borderRadius: 5 }}
                    />
                    <ListItemText
                      primaryTypographyProps={{ className: isLateCancellation ? classes.cancelled : "" }}
                      primary={
                        !bk.isHolidayClub && bk.roll
                          ? bk.roll?.rollName
                          : bk.isHolidayClub && bk.program && bk.roll
                          ? bk.program.programName
                          : "N/A"
                      }
                      secondary={
                        <Grid container direction={"column"}>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            justifyItems={"center"}
                            alignContent={"center"}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            {!bk.isHolidayClub &&
                              bk.roll &&
                              `${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(bk.roll.endTime).format(
                                "hh:mm a"
                              )}`}
                            {bk.isHolidayClub &&
                              bk.program &&
                              bk.roll &&
                              `${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(bk.roll.endTime).format(
                                "hh:mm a"
                              )}`}

                            {bk.scheduleId && <RepeatIcon style={{ marginLeft: 5, fontSize: 18 }} />}
                          </Typography>
                          <Typography variant="caption" color="black" fontSize={12}>
                            {bk.roll?.service?.serviceName?.toUpperCase()}
                          </Typography>
                        </Grid>
                      }
                    />

                    {child && (
                      <ListItemAvatar style={{ marginTop: 6 }}>
                        <Grid container direction="column" alignItems="center">
                          <AvatarDisplay info={avatar || { index: child.avatarIndex }} size={40} />
                          <Typography fontSize="15px" className={classes.childName}>
                            {`${child.firstName} ${child.lastName?.substring(0, 1)}`}
                          </Typography>
                        </Grid>
                      </ListItemAvatar>
                    )}
                  </ListItem>
                );
              })}
            </Box>
          );
        })}
        {bookingsByDateArr.length === 0 && (
          <ListItem>
            <ListItemText primary="No bookings found" />
          </ListItem>
        )}
      </List>

      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {currentBooking?.isEditable && isAllowedToBook && (
          <MenuItem
            key="cancel"
            onClick={(event: any) => {
              setIsSchedule(false);
              handleCancelSession();
              handleClose();
            }}
          >
            Cancel Session
          </MenuItem>
        )}
        {currentBooking?.isEditable && isAllowedToBook && currentBooking.isSchedule && (
          <>
            <MenuItem
              key="cancelRecurring"
              onClick={(event: any) => {
                setIsSchedule(true);
                handleCancelSession();
                handleClose();
              }}
            >
              Cancel Schedule
            </MenuItem>
            <MenuItem
              key="editRecurring"
              onClick={() => {
                navigate("/edit-booking", {
                  state: JSON.stringify({
                    bookingScheduleId: Number(currentBooking.scheduleId),
                    childId: currentBooking.childId,
                    effectiveFromDate: currentBooking.schedule?.effectiveFromDate,
                    effectiveToDate: currentBooking.schedule?.effectiveToDate,
                    uniqueId: currentBooking.schedule?.uniqueId,
                    rollId: currentBooking.rollId,
                    serviceId: currentBooking.roll?.serviceId,
                    sessionType: currentBooking.roll?.sessionType,
                    firstFriday: currentBooking.schedule?.firstFriday,
                    firstThursday: currentBooking.schedule?.firstThursday,
                    firstWednesday: currentBooking.schedule?.firstWednesday,
                    firstTuesday: currentBooking.schedule?.firstTuesday,
                    firstMonday: currentBooking.schedule?.firstMonday,
                    originalEffectiveFromDate: currentBooking.schedule?.effectiveFromDate,
                  } as IEditRecurringBookingState),
                });

                handleClose();
              }}
            >
              Edit Recurring
            </MenuItem>
          </>
        )}
      </Menu>
    </Grid>
  );
  function handleCancelSession() {
    if (currentBooking != null) {
      dispatch(CancelBookingInitial());
      setSelectedBooking(currentBooking);
    }
    handleCancellationPopUpOpen();
  }
}
