import React, { useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { CardLayout } from "../../../layouts/Layouts";
import { useAppDispatch } from "../../../store/hooks";
import { ResetPassword } from "../../../store/modules/resetPassword/resetPasswordActions";
import { RootState } from "../../../store/store";
import { defaultPasswordSettings, getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";
import PasswordTextField from "../../Common/Text/PasswordTextField";
import ResetPasswordSchema from "./ResetPasswordSchema";

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  primary: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: getStyleColor("--color-accent-1"),
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  secondary: {
    borderColor: "black",
    borderWidth: 2,
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
}));

function FormResetPassword() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const passwordSettings = useSelector((state: RootState) => state.passwordSettings.payload) ?? defaultPasswordSettings;
  const resetStatus = useSelector((state: RootState) => state.resetPassword).status;
  const resetError = useSelector((state: RootState) => state.resetPassword).payload;
  const [localStatus, setLocalStatus] = React.useState("");
  const [localError, setLocalError] = React.useState("");
  useEffect(() => {
    if (resetStatus !== "Initial") {
      setLocalStatus(resetStatus);
      if (resetStatus === "Success") {
        setTimeout(() => {
          navigate("/account-home");
        }, 500);
      }
    }
  }, [resetStatus]);
  useEffect(() => {
    if (resetError) {
      setLocalError(resetError.toString());
    }
  }, [resetError]);
  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <h1 className="h2">Change Password</h1>
      </CardLayout.Header>
      <CardLayout.Body>
        <Formik
          validationSchema={ResetPasswordSchema(passwordSettings)}
          initialValues={{
            oldPass: "",
            newPass: "",
            confirmPass: "",
            passwordRequireUppercase: passwordSettings.passwordRequireUppercase,
            passwordRequireLowercase: passwordSettings.passwordRequireLowercase,
            PasswordRequireDigit: passwordSettings.passwordRequireDigit,
            passwordRequireNonAlphanumeric: passwordSettings.passwordRequireNonAlphanumeric,
            passwordRequiredUniqueChars: passwordSettings.passwordRequiredUniqueChars,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              if (customer_account_id && customer_contact_id) {
                ResetPassword(dispatch, customer_account_id, customer_contact_id, values.oldPass, values.newPass);
              }
            }, 500);
          }}
        >
          {({ handleChange, handleBlur, values, touched, errors }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <PasswordTextField
                    name="oldPass"
                    value={values.oldPass}
                    label="Old Password"
                    className={classes.textField}
                    onChange={handleChange("oldPass")}
                    onBlur={handleBlur("oldPass")}
                    error={touched.oldPass && !!errors.oldPass}
                    helperText={errors.oldPass}
                  />
                  <PasswordTextField
                    className={classes.textField}
                    onChange={handleChange}
                    name="newPass"
                    onBlur={handleBlur}
                    value={values.newPass}
                    label="New Password"
                    error={touched.newPass && !!errors.newPass}
                    helperText={errors.newPass}
                  />
                  <PasswordTextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="confirmPass"
                    className={classes.textField}
                    value={values.confirmPass}
                    label="Confirm New Password"
                    error={touched.confirmPass && !!errors.confirmPass}
                    helperText={errors.confirmPass}
                  />
                  {localStatus === "Errored" && (
                    <Box marginBottom={1}>
                      <Alert severity="error">{localError}</Alert>
                    </Box>
                  )}
                  {localStatus === "Success" && (
                    <Box marginBottom={1}>
                      <Alert severity="success">Password has been updated</Alert>
                    </Box>
                  )}
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Box paddingTop={2} />
                      <ButtonPrimary type="submit" disabled={localStatus === "Pending"}>
                        <strong>Update</strong>
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Backdrop className={classes.backdrop} open={localStatus === "Pending"}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Form>
          )}
        </Formik>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default FormResetPassword;
