import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { IPaymentError, paymentError, paymentInit, paymentProcessing, paymentSuccess } from "./paymentStateActions";

export interface PaymentState {
  isError: boolean;
  isProcessing: boolean;
  isSuccess: boolean;
  title: string | null;
  error: IPaymentError | null;
}

export const initialState: PaymentState = {
  isError: false,
  isProcessing: false,
  isSuccess: false,
  error: null,
  title: null,
};

const paymentReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    paymentSuccess,
    (state, action): PaymentState => ({
      ...state,
      isProcessing: false,
      isError: false,
      isSuccess: true,
      error: null,
      title: action.payload,
    })
  ),
  handleAction(
    paymentProcessing,
    (state): PaymentState => ({
      ...state,
      isProcessing: true,
      isError: false,
      isSuccess: false,
      error: null,
    })
  ),
  handleAction(
    paymentError,
    (state, action): PaymentState => ({
      ...state,
      isError: true,
      isProcessing: false,
      isSuccess: false,
      error: action.payload,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(paymentInit, () => initialState),
]);

export default paymentReducer;
