import React from "react";

import Alert from "@mui/lab/Alert";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { List } from "linqts";
import { IoDownloadOutline, IoLink } from "react-icons/io5";
import { makeStyles } from "tss-react/mui";

import theme from "../../../theme";
import { MedicalPlanAnswer, MedicalPlanQuestionTypes, QuestionTemplate } from "../../../types/models";
import { Document, DocumentTypes } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { DocumentManager, DocumentManagerMode } from "./DocumentManager";

interface Props {
  medicalConditionId: number;
  question: QuestionTemplate;
  answer: MedicalPlanAnswer;
  relatedQuestions: List<QuestionTemplate>;
  answers: List<MedicalPlanAnswer>;
  childUniqueId: string | undefined;
  childId: number | undefined;
  customerAccountId: number | undefined;
  documents: Document[];
  onAnswerChanged: (
    questionId: number,
    value: string | undefined,
    freeText: string,
    multipleAnswerIndex: number
  ) => void;
}

const useStyles = makeStyles()((theme) => ({
  questionLink: {
    color: "#2754b3",
  },
  questionLinkIcon: {
    color: "#2754b3",
    verticalAlign: "middle",
    marginRight: 5,
  },
}));

export default function MedicalPlanQuestion(props: Props) {
  const { classes } = useStyles();
  var currentAnswer = props.answer;
  var showChildren =
    props.question.questionTypeId === MedicalPlanQuestionTypes.YesNo && currentAnswer && currentAnswer.value === "1"
      ? true
      : false;
  return (
    <>
      {props.question.questionTypeId !== MedicalPlanQuestionTypes.Link && (
        <p>
          <strong>{props.question.text}</strong>
        </p>
      )}
      {DisplayQuestionControl(props.question, props, classes)}
      {showChildren &&
        props.relatedQuestions &&
        props.relatedQuestions
          .Select((rq, index) => {
            rq.multipleQuestionIndex = props.question.multipleQuestionIndex;
            return (
              <React.Fragment key={index}>
                <p>
                  <strong>{rq.text}</strong>
                </p>
                {DisplayQuestionControl(rq, props, classes)}
              </React.Fragment>
            );
          })
          .ToArray()}
    </>
  );
}

function DisplayQuestionControl(q: QuestionTemplate, props: Props, classes: any) {
  var a = props.answers.FirstOrDefault(
    (ans) =>
      ans?.conditionId === props.medicalConditionId &&
      ans.questionId === q.questionId &&
      ans.multipleAnswerIndex === q.multipleQuestionIndex
  );

  var controls = new Array<JSX.Element>();
  switch (q.questionTypeId) {
    case MedicalPlanQuestionTypes.FreeText:
      controls.push(
        <TextField
          key={`MedicalPlanQuestionAnswer_FreeText_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          name={`MedicalPlanQuestionAnswer_FreeText_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          label={q.freeTextHint?.replace("*", "")}
          type="text"
          value={a?.value}
          required={q.required}
          error={!a?.isValueValid}
          fullWidth
          inputProps={{
            maxLength: 200,
          }}
          onChange={(evt) => {
            props.onAnswerChanged(q.questionId, evt.target.value, "", q.multipleQuestionIndex);
          }}
        />
      );
      break;

    case MedicalPlanQuestionTypes.YesNo:
      var boolValue = a?.value ?? "0";
      controls.push(
        <RadioGroup
          key={`MedicalPlanQuestionAnswer_YesNo_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          name={`MedicalPlanQuestionAnswer_YesNo_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          value={a?.value ?? "0"}
          row
          onChange={(evt) => {
            props.onAnswerChanged(q.questionId, evt.target.value, "", q.multipleQuestionIndex);
          }}
        >
          <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" />
        </RadioGroup>
      );
      if (boolValue === "1" && q.allowFreeText) {
        controls.push(
          <TextField
            key={`MedicalPlanQuestionAnswer_YesNo_FreeText_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
            name={`MedicalPlanQuestionAnswer_YesNo_FreeText_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
            label={q.freeTextHint?.replace("*", "")}
            type="text"
            value={a?.freeText}
            required={q.freeTextRequired}
            error={!a?.isFreeTextValid}
            fullWidth
            inputProps={{
              maxLength: 200,
            }}
            onChange={(evt) => {
              props.onAnswerChanged(q.questionId, boolValue, evt.target.value, q.multipleQuestionIndex);
            }}
          />
        );
      }
      break;

    case MedicalPlanQuestionTypes.Radio:
      controls.push(
        <RadioGroup
          key={`Question_Radio_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          name={`Question_Radio_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          value={a?.value}
          onChange={(evt) => {
            props.onAnswerChanged(q.questionId, evt.target.value, "", q.multipleQuestionIndex);
          }}
        >
          {q.questionOptions?.map((op, index) => (
            <FormControlLabel key={index} value={"" + op.questionOptionId} control={<Radio />} label={op.name} />
          ))}
        </RadioGroup>
      );
      if (q.required && a?.isValueValid !== true) {
        controls.push(<Typography color="error">Please select a value</Typography>);
      }
      break;

    case MedicalPlanQuestionTypes.Document:
      q.questionDocuments?.forEach((qd, index) => {
        if (qd && qd.forMedicalConditions && qd.forMedicalConditions?.indexOf(props.medicalConditionId) >= 0) {
          controls.push(
            <Box paddingBottom={2} paddingTop={2} key={index}>
              <Link href={qd.url} style={{ color: theme.palette.primary.dark }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <IoDownloadOutline
                      size={24}
                      color={getStyleColor("--color-accent-2")}
                      style={{ marginRight: 10 }}
                    />
                  </Grid>
                  <Grid item xs>
                    <strong className="color-accent-2">{qd.title}</strong>
                  </Grid>
                </Grid>
              </Link>
            </Box>
          );
        }
      });

      controls.push(
        <DocumentManager
          documentTypeId={DocumentTypes.ChildMedicalDocument}
          medicalPlanConditionId={props.medicalConditionId}
          medicalPlanAnswerIndex={q.multipleQuestionIndex}
          medicalPlanQuestionId={q.questionId}
          documents={props.documents.filter(
            (d) =>
              d.medicalPlanQuestionId === q.questionId &&
              d.medicalPlanConditionId === props.medicalConditionId &&
              d.medicalPlanAnswerIndex === q.multipleQuestionIndex
          )}
          isRegistration={props.childUniqueId ? true : false}
          isTemp={true}
          controlId={`QuestionAnswerDocument_${props.medicalConditionId}_${q.questionId}_${q.multipleQuestionIndex}`}
          registrationChildUniqueId={props.childUniqueId}
          childId={props.childId}
          customerAccountId={props.customerAccountId}
          documentManagerMode={
            q.uploadExpirationRequired == null || !q.uploadExpirationRequired
              ? DocumentManagerMode.standardList
              : DocumentManagerMode.medicalPlanAction
          }
          maxFiles={10}
        />
      );
      break;

    case MedicalPlanQuestionTypes.Link:
      controls.push(
        <Box paddingBottom={2} paddingTop={2}>
          <Link href={q.link} className={classes.questionLink} target="_blank">
            <IoLink size={24} className={classes.questionLinkIcon} />
            {q.text}
          </Link>
        </Box>
      );
      break;
  }

  if (q.note && (q.questionTypeId !== MedicalPlanQuestionTypes.YesNo || a?.value === "1")) {
    controls.push(
      <Box paddingBottom={2} paddingTop={2}>
        <Alert severity="warning" style={{ whiteSpace: "pre-wrap" }}>
          {q.note}
        </Alert>
      </Box>
    );
  }
  return controls;
}
