import React, { useEffect } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import * as Yup from "yup";

import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import ServiceLocationCard from "../../components/Bookings/ServiceLocationCard";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { Egg1 } from "../../components/Common/Shapes/Shapes";
import { CardLayout } from "../../layouts/Layouts";
import { getServicesByLocation } from "../../store/modules/program/programActions";
import { serviceLocationsReset, serviceProgramsReset } from "../../store/modules/program/programStateActions";
import { RootState } from "../../store/reducers";
import { IServiceDistance } from "../../types/types";
import { getStyleColor, isContactAllowedToBook } from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";

const searchValidatorSchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  maxDistanceKM: Yup.number().required("Max distance is required"),
});

interface VACSearch {
  address: string;
  maxDistanceKM: number;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: 40,
    "@media screen and (min-width: 720px)": {
      height: 80,
    },
  },
}));

function NewVACBooking() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();

  const auth = useSelector((state: RootState) => state.auth);
  const profile = auth.user?.profile;
  const isLoading = useSelector((state: RootState) => state.programServices.isLoading);
  const programServices = useSelector((state: RootState) => state.programServices.servicesByLocation);
  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const isAllowedToBook = isContactAllowedToBook(
    customerAccount!,
    auth.userId!,
    parseInt(auth.user!.profile.customer_contact_id!.toString())
  );

  const initialValues = { address: "", maxDistanceKM: 5 } as VACSearch;

  if (customerAccount) {
    var cont = customerAccount.contacts?.find((c) => c.isPrimary);
    if (cont) {
      var addr = cont.residentialAddress;
      initialValues.address = addr?.streetLine1 + ", " + addr?.suburb + ", " + addr?.state + " " + addr?.postCode;
    }
  }

  useEffect(() => {
    dispatch(serviceLocationsReset());
  }, [profile]);

  function DisplayServices(services: IServiceDistance[] | null, navigate: NavigateFunction) {
    if (services) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Box paddingTop={2} paddingBottom={2}>
                {services.length} result(s) found
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {services.map((p, index) => (
              <React.Fragment key={index}>
                <ServiceLocationCard
                  serviceDistance={p}
                  onSelect={() => {
                    dispatch(serviceProgramsReset());
                    navigate("vac-selection", { state: JSON.stringify({ serviceDistance: p }) });
                  }}
                />
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--rocketeers-color-accent-2")}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h1 className="h3">New Booking</h1>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end">
              <img src={RocketeersLogo} alt="Rocketeers Logo" className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <Egg1
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            display: width <= 1280 ? "none" : "block",
          }}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={searchValidatorSchema}
          onSubmit={(values, { setSubmitting }) => {
            getServicesByLocation({
              dispatch,
              customerAccountId: profile?.customer_account_id ?? 0,
              address: values.address + ", Australia",
              take: 4,
              skip: 0,
            });
          }}
        >
          {({ submitForm, handleChange, values, errors, isSubmitting }) => {
            return (
              <Form className={classes.root}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Address, Suburb or Postcode*"
                      name="address"
                      fullWidth={true}
                      value={values.address}
                      onChange={handleChange}
                      error={errors.address !== undefined}
                      helperText={errors.address}
                    />
                    <Box paddingBottom={3} />
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="max-distance">Max Distance</InputLabel>
                      <Select
                        fullWidth={true}
                        id="max-ditance"
                        name="maxDistanceKM"
                        value={values.maxDistanceKM}
                        onChange={handleChange}
                      >
                        <MenuItem value={5}>5KM</MenuItem>
                        <MenuItem value={10}>10KM</MenuItem>
                        <MenuItem value={20}>20KM</MenuItem>
                        <MenuItem value={25}>25KM</MenuItem>
                        <MenuItem value={30}>30KM</MenuItem>
                        <MenuItem value={35}>35KM</MenuItem>
                        <MenuItem value={45}>45KM</MenuItem>
                      </Select>
                    </FormControl>
                    <Box paddingBottom={3} />
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6}>
                  <Grid container justifyContent="center" alignItems="center">
                    <ButtonPrimary
                      disabled={!isAllowedToBook}
                      style={{ backgroundColor: getStyleColor("--rocketeers-color-accent-2") }}
                      onClick={submitForm}
                    >
                      <strong>Search</strong>
                    </ButtonPrimary>
                  </Grid>
                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              </Form>
            );
          }}
        </Formik>
        <Grid item xs={12} md={6}>
          {DisplayServices(programServices, navigate)}
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default NewVACBooking;
