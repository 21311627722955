import { createReducer } from "deox";

import { contactUpdated } from "../customerActionTypes";
import {
  childEnrolmentPromptDismissed,
  childEnrolmentPromptNotRequired,
  childEnrolmentPromptRequired,
  ChildEnrolmentPromptStatus,
} from "./childEnrolmentStateActions";

export interface ChildEnrolmentPromptState {
  status: ChildEnrolmentPromptStatus;
  contactIds?: number[];
}

export const initialState: ChildEnrolmentPromptState = {
  status: "Initial",
};

const childEnrolmentReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    childEnrolmentPromptRequired,
    (state, action): ChildEnrolmentPromptState => ({
      ...state,
      status: "Required",
      contactIds: action.payload,
    })
  ),

  handleAction(
    childEnrolmentPromptNotRequired,
    (state, action): ChildEnrolmentPromptState => ({
      ...state,
      status: "Not Required",
      contactIds: undefined,
    })
  ),

  handleAction(
    childEnrolmentPromptDismissed,
    (state, action): ChildEnrolmentPromptState => ({
      ...state,
      //status: "Dismissed",
      contactIds: state.contactIds?.filter((x) => x !== action.payload),
    })
  ),

  handleAction(
    contactUpdated,
    (state, action): ChildEnrolmentPromptState => ({
      ...state,
      contactIds: state.contactIds?.filter((x) => x !== action.payload.contact.customerContactId),
    })
  ),
]);

export default childEnrolmentReducer;
