import Alert from "@mui/lab/Alert";
import { Link } from "react-router-dom";

import { signoutRedirect } from "../../../store/modules/auth/authServices";

export const PossibleDuplicatedAccount = () => {
  async function signoutAsync() {
    await signoutRedirect().then(() => console.log("Signed out"));
  }

  return (
    <Alert severity="warning">
      It appears that you may already have a Camp Australia account. To login, please click&nbsp;
      <Link
        to="#"
        onClick={() => {
          signoutAsync();
        }}
      >
        <strong>here</strong>
      </Link>
      . If you have forgotten your login details, please contact Customer Care on 1300 105 343 for further assistance.
    </Alert>
  );
};
