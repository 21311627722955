import { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { List } from "linqts";
import { IoFlagOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";
import theme from "../../../theme";
import { ChildAgeConstraint, EntityType, SelectedChild } from "../../../types/models";
import { ChildBookingStatus, ChildServiceStatus, IRegistrationMedicalPlanAnswer } from "../../../types/types";
import { isBlockingAnswer } from "../../../utils/medicalConditionsHelper";
import { AvatarDisplay } from "../../Common/Controls/AvatarDisplay";

interface Props {
  child: SelectedChild;
  constraints: List<ChildAgeConstraint>;
  id?: string;
  onSelectionChanged?: (isAgeLess: boolean, isAgeGreater: boolean, checked: boolean) => void;
  onReasonChanged?: (reason: string) => void;
  showAddress?: boolean;
  preventDisableSelection?: boolean;
  serviceId?: number;
}

const ChildSelection = (props: Props) => {
  const [reason, setReason] = useState(props.child.reason);
  const register = useSelector((state: RootState) => state.register);

  const childAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Child && x.entityId === props.child.child.childId?.toString()
    )
  );

  var isSuspended = props.child.child.isSuspended ?? false;
  var childBookingStatus = props.child.child.childBookingStatus;
  var childServices = props.child.child.childServices;
  var isBlockedForAllServices =
    childBookingStatus != null && childBookingStatus === ChildBookingStatus.BlockedForAllServices;

  var isBlockedForService =
    childBookingStatus !== null &&
    props.serviceId !== null &&
    childBookingStatus === ChildBookingStatus.ApprovedForSpecificServices &&
    childServices !== null &&
    childServices?.find((x) => x.serviceId === props.serviceId && x.status === ChildServiceStatus.BookingsApproved) ===
      undefined;

  if (!isBlockedForAllServices && !isBlockedForService && register.isCompleted && register.children.length > 0) {
    var registrationChild = register.children.find(
      (x) => x.firstName === props.child.child.firstName && x.lastName === props.child.child.lastName
    );
    if (
      registrationChild !== undefined &&
      registrationChild.medicalPlanAnswers?.some((x: IRegistrationMedicalPlanAnswer) => {
        return isBlockingAnswer(x);
      })
    ) {
      isBlockedForAllServices = true;
    }
  }

  var ageIsLess = isAgeLessThanMin(props);
  var ageIsGreater = isAgeGreaterThanMax(props);
  props.child.reasonRequired = ageIsGreater;

  var address = props.child.child.residentialAddress;
  var preventDisableSelection = props.preventDisableSelection ?? false;

  if ((ageIsLess || isBlockedForService || isBlockedForAllServices) && props.child.isSelected) {
    if (props.onSelectionChanged !== undefined) props.onSelectionChanged(ageIsLess, ageIsGreater, false);
  }

  return (
    <Grid
      key={"child_" + props.child.child.childId}
      container
      alignItems="center"
      alignContent="flex-start"
      spacing={2}
    >
      <Grid item>
        <AvatarDisplay info={childAvatar || { index: props.child.child.avatarIndex }} size={35} />
      </Grid>
      <Grid item xs>
        {props.child.child.firstName} {props.child.child.lastName}
        {props.showAddress && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: "10pt" }}>
              {address?.streetLine1 + ", " + address?.suburb + ", " + address?.state + ", " + address?.postCode}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Checkbox
          disabled={
            !preventDisableSelection && (ageIsLess || isSuspended || isBlockedForAllServices || isBlockedForService)
          }
          id={props.id}
          checked={props.child.isSelected}
          onChange={(evt, checked) => {
            if (props.onSelectionChanged !== undefined) props.onSelectionChanged(ageIsLess, ageIsGreater, checked);
          }}
        ></Checkbox>
      </Grid>
      {!preventDisableSelection && getErrorMessage(props, ageIsLess, ageIsGreater, isSuspended, reason, setReason)}
      {!preventDisableSelection && getOnHoldMessage(props, isBlockedForAllServices, isBlockedForService)}
      {props.showAddress && (
        <Grid item xs={12}>
          <hr />
        </Grid>
      )}
    </Grid>
  );
};

function getErrorMessage(
  props: Props,
  ageIsLess: boolean,
  ageIsGreater: boolean,
  isSuspended: boolean,
  reason: string | null | undefined,
  setReason: any
): JSX.Element | string {
  if (!ageIsLess && !isSuspended && (!props.child.isSelected || !ageIsGreater)) return "";

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IoFlagOutline size={24} color={theme.palette.error.main} style={{ marginTop: 4 }} />
            </Grid>
            <Grid item xs>
              <span style={{ color: theme.palette.error.main }}>
                {props.child.child.firstName +
                  (ageIsLess
                    ? " is under the minimum age required for booking in to this session"
                    : ageIsGreater
                    ? " is over the maximum age accepted for booking into this session"
                    : " is suspended")}
              </span>
            </Grid>
          </Grid>
        </Grid>
        {ageIsGreater && (
          <>
            <Grid item xs={12}>
              <TextField
                required
                inputMode="text"
                fullWidth={true}
                placeholder="Reason for attendance"
                value={reason}
                onChange={(evt) => {
                  setReason(evt.target.value);
                }}
                onBlur={(evt) => {
                  if (props.onReasonChanged) props.onReasonChanged(evt.target.value);
                }}
              ></TextField>
            </Grid>
            {props.child.reasonRequired && !props.child.hasReason && (
              <Grid item xs={12}>
                <span style={{ color: theme.palette.error.main }}>Reason is required</span>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

function getOnHoldMessage(
  props: Props,
  isBlockedForAllServices: boolean,
  isBlockedForService: boolean
): JSX.Element | string {
  if (!isBlockedForAllServices && !isBlockedForService) return "";

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <IoFlagOutline size={24} color={theme.palette.error.main} style={{ marginTop: 4 }} />
            </Grid>
            <Grid item xs>
              <span style={{ color: theme.palette.error.main }}>
                {"Booking cannot be made for " +
                  props.child.child.firstName +
                  " at this time. You will be contacted by a member of Camp Australia within 2 business days to discuss"}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function isAgeLessThanMin(props: Props) {
  return props.constraints.Any((ct) => ct !== undefined && ct.isAgeLessThanMin(props.child));
}

function isAgeGreaterThanMax(props: Props) {
  return props.constraints.Any((ct) => ct !== undefined && ct.isAgeGreaterThanMax(props.child));
}

export default ChildSelection;
