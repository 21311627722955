import React from "react";

import Alert from "@mui/lab/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../store/hooks";
import { toggleNotificationSetting } from "../../store/modules/notification/notificationActions";
import { RootState } from "../../store/store";
import { NotificationCategory, NotificationChannel } from "../../types/types";
import LoadingDialog from "../Common/Dialogs/LoadingDialog";

export interface INotificationProps {
  channel: NotificationChannel;
  bookingOffers: boolean;
  general: boolean;
  holidayClubMarketing: boolean;
  inSessionActivities: boolean;
  industryThoughts: boolean;
}

export default function NotificationListing(props: INotificationProps) {
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const loadStatus = useSelector((state: RootState) => state.notificationSettings.status);
  const saveStatus = useSelector((state: RootState) => state.notificationSettings.savingStatus);
  const [localStatus] = React.useState("");
  const dispatch = useAppDispatch();

  const handleToggle = (category: NotificationCategory, value: boolean) => {
    customer_account_id && toggleNotificationSetting(dispatch, customer_account_id, props.channel, category, value);
  };
  return (
    <>
      {localStatus === "Errored" && (
        <Alert severity="error">An error has been encountered. Please try again later</Alert>
      )}
      <List>
        <ListItem disableGutters>
          <ListItemText id="switch-offers" primary="Booking Offers" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={(event, checked) => {
                handleToggle(NotificationCategory.BookingOffers, checked);
              }}
              checked={props.bookingOffers}
              inputProps={{ "aria-labelledby": "switch-offers" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText id="switch-general" primary="General" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={(event, checked) => {
                handleToggle(NotificationCategory.General, checked);
              }}
              checked={props.general}
              inputProps={{ "aria-labelledby": "switch-general" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText id="switch-marketing" primary="Rocketeers Marketing" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={(event, checked) => {
                handleToggle(NotificationCategory.HolidayClubMarketing, checked);
              }}
              checked={props.holidayClubMarketing}
              inputProps={{ "aria-labelledby": "switch-marketing" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText id="switch-activities" primary="In Session Activities" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={(event, checked) => {
                handleToggle(NotificationCategory.InSessionActivities, checked);
              }}
              checked={props.inSessionActivities}
              inputProps={{ "aria-labelledby": "switch-activities" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText id="switch-industry" primary="Thoughts from the industry" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={(event, checked) => {
                handleToggle(NotificationCategory.IndustryThoughts, checked);
              }}
              checked={props.industryThoughts}
              inputProps={{ "aria-labelledby": "switch-industry" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <LoadingDialog
        open={loadStatus === "Pending" || saveStatus === "Pending"}
        message={loadStatus === "Pending" ? "Loading settings" : "Saving changes"}
      />
    </>
  );
}
