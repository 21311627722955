export interface IStep {
  label?: string;
  path?: string;
}

export const contactSteps = (id: number) =>
  id === 0
    ? [
        { label: "Account Details", path: `/registration-contact-step/1/${id}` },
        { label: "Child Care Subsidy", path: `/registration-contact-step/2/${id}` },
        { label: "Authorisation", path: `/registration-contact-step/3/${id}` },
      ]
    : [
        { label: "Account Details", path: `/registration-contact-step/1/${id}` },
        { label: "Authorisation", path: `/registration-contact-step/3/${id}` },
      ];

export const childSteps = (id: number) => [
  { label: "Account Details", path: `/registration-child-step/1/${id}` },
  { label: "Immunisation", path: `/registration-child-step/2/${id}` },
  { label: "Medical Conditions", path: `/registration-child-step/3/${id}` },
  { label: "Physical Conditions", path: `/registration-child-step/6/${id}` },
  { label: "Medical Contact", path: `/registration-child-step/7/${id}` },
  { label: "Priority of Access", path: `/registration-child-step/8/${id}` },
  { label: "Authorisation", path: `/registration-child-step/9/${id}` },
];
