import { createReducer } from "deox";

import { IServiceProgramSummaryModel } from "../../../types/types";
import {
  serviceProgramSummaryError,
  serviceProgramSummaryLoading,
  serviceProgramSummarySuccess,
} from "./programStateActions";

export interface IServiceProgramSummaryState {
  serviceProgramSummary: IServiceProgramSummaryModel[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

export const initialState: IServiceProgramSummaryState = {
  serviceProgramSummary: undefined,
  isLoading: false,
  isError: false,
};

const serviceProgramSummaryReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    serviceProgramSummaryLoading,
    (state): IServiceProgramSummaryState => ({
      ...state,
      isError: false,
      isLoading: true,
      serviceProgramSummary: [],
    })
  ),

  handleAction(
    serviceProgramSummaryError,
    (state): IServiceProgramSummaryState => ({
      ...state,
      isLoading: false,
      serviceProgramSummary: [],
      isError: true,
    })
  ),
  handleAction(serviceProgramSummarySuccess, (state: IServiceProgramSummaryState, { payload }) => ({
    ...state,
    isLoading: false,
    isError: false,
    serviceProgramSummary: payload,
  })),
]);

export default serviceProgramSummaryReducer;
