import { createActionCreator } from "deox";

import { ServiceProgram } from "../../../types/models";
import { IServiceProgram, IServiceProgramSummaryModel, IServiceWithUpcomingProgram } from "../../../types/types";

export const serviceLocationsLoading = createActionCreator("serviceLocationsLoading");
export const serviceLocationsError = createActionCreator("serviceLocationsError");
export const serviceLocationsReset = createActionCreator("serviceLocationsReset");
export const serviceLocationsSuccess = createActionCreator(
  "serviceLocationsSuccess",
  (resolve) => (services: IServiceWithUpcomingProgram[]) => resolve(services)
);

export const serviceProgramsError = createActionCreator("serviceProgramsError");
export const serviceProgramsLoading = createActionCreator("serviceProgramsLoading");

export const serviceProgramsReset = createActionCreator("serviceProgramsReset");
export const serviceProgramsSuccess = createActionCreator(
  "serviceProgramsSuccess",
  (resolve) => (services: ServiceProgram[]) => resolve(services)
);

export const serviceProgramSummaryLoading = createActionCreator("serviceProgramSummaryLoading");
export const serviceProgramSummaryError = createActionCreator("serviceProgramSummaryError");
export const serviceProgramSummarySuccess = createActionCreator(
  "serviceProgramSummarySuccess",
  (resolve) => (serviceProgramSummary: IServiceProgramSummaryModel[]) => resolve(serviceProgramSummary)
);

export const serviceProgramSelect = createActionCreator(
  "serviceProgramSelect",
  (resolve) => (serviceProgram: IServiceProgram | undefined, event: String) => resolve({ serviceProgram, event })
);

export const serviceProgramView = createActionCreator(
  "serviceProgramView",
  (resolve) => (serviceProgram: IServiceProgram | undefined) => resolve({ serviceProgram })
);

export const serviceProgramBeginCheckout = createActionCreator(
  "serviceProgramBeginCheckout",
  (resolve) => (services: ServiceProgram[]) => resolve(services)
);

export const serviceProgramsView = createActionCreator(
  "serviceProgramsView",
  (resolve) => (services: ServiceProgram[]) => resolve(services)
);
