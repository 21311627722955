import { createReducer } from "deox";
import jwt_decode from "jwt-decode";

import { User } from "../../../types/types";
import { isUserRegistered } from "../../../utils/userUtils";
import { purge } from "../purge/purgeStateActions";
import { userLoading, userSuccess } from "./authStateActions";

export interface AuthState {
  user: User | null;
  isLoadingUser: boolean;
  registeredUser: boolean;
  userId: string | null;
}

const initialState: AuthState = {
  user: null,
  isLoadingUser: false,
  registeredUser: false,
  userId: null,
};

const authReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    userSuccess,
    (state, action): AuthState => ({
      ...state,
      isLoadingUser: false,
      registeredUser: isUserRegistered(action.payload),
      user: action.payload,
      userId: jwt_decode<string>(action.payload.access_token)["sub"].toString(),
    })
  ),
  handleAction(
    userLoading,
    (state): AuthState => ({
      ...state,
      registeredUser: false,
      isLoadingUser: true,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default authReducer;
