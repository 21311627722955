import dayjs from "dayjs";

import * as handlers from "../types/models";
const reviver = (key: any, value: any) => {
  if (value) {
    if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
      return dayjs(value);
    } else if (typeof value === "object" && value.__type) {
      var objectType: any = (handlers as any)[value.__type];
      var newVal = new objectType(value);
      return newVal;
    }
  }
  return value;
};

export const parseJSON = (data: string) => {
  return JSON.parse(data, reviver);
};

export const prettifyJson = (value: any) => {
  return JSON.stringify(value, null, 2);
};
