import { createReducer } from "deox";

import { LoadedSchools, LoadedSchoolsError } from "./schoolStateActions";
import { School } from "../../../types/types";

export interface SchoolState {
  schools: School[] | null;
  schoolState: string | null;
  isInitialised: boolean;
  isErrorState: boolean;
}

export const initialState: SchoolState = {
  schools: null,
  schoolState: null,
  isInitialised: false,
  isErrorState: false,
};

const bookingReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadedSchools, (state, { payload }) => ({
    ...state,
    schools: payload.schools,
    schoolState: payload.shortStateName,
    isInitialised: true,
    isErrorState: false,
  })),
  handleAction(LoadedSchoolsError, (state) => ({
    ...state,
    schools: null,
    schoolState: null,
    isInitialised: false,
    isErrorState: true,
  })),
]);

export default bookingReducer;
