import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import theme from "../../../theme";

export default function ReadOnlyFields() {
  return (
    <Tooltip
      title="Some fields are unable to be updated as changes may impact your ability to make Child Care Subsidy claims. 
  To change details in these fields, please contact our Customer Care Team via phone 1300 105 343, or email oshc@campaustralia.com.au"
    >
      <InfoOutlined fontSize="small" style={{ color: theme.palette.error.main, verticalAlign: "bottom" }} />
    </Tooltip>
  );
}
