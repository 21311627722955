import { pdfjs, Document, Page } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import React, { useEffect, useLayoutEffect, useState } from "react";

import { downloadProgramPreview } from "../../store/modules/digitalProgram/digitalProgramActions";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid/Grid";
import { useViewport } from "../../utils/hooks";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger/useScrollTrigger";
import Typography from "@mui/material/Typography/Typography";
import Stack from "@mui/material/Stack/Stack";
import IconButton from "@mui/material/IconButton/IconButton";
import { BsFilePdfFill } from "react-icons/bs";
import GetAppIcon from "@mui/icons-material/GetApp";
import saveAs, { FileSaverOptions } from "file-saver";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "black",
  color: "white",
}));
const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  pdfIcon: {
    color: "#ff0000",
  },
  appBar: {
    paddingRight: 0,
    "& .MuiAppBar-root": {
      paddingRight: "0!important",
    },
  },
}));

interface IProps {
  serviceId: number;
  programVersionId: number;
  programName: string;
  formattedName: string;
  // buffer: ArrayBuffer;
}

const options = {
  cMapUrl: "/cmaps/",
  cMapPacked: true,
};
interface Props {
  window?: () => Window;
  children: React.ReactElement;
}
function ElevationScroll(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
export default function DigitalProgramReportViewer(props: IProps) {
  const { serviceId, programVersionId, programName, formattedName } = props;

  const { classes } = useStyles();
  const user = useSelector((state: RootState) => state.auth.user);
  const customer_account_id = useSelector((state: RootState) => user?.profile.customer_account_id);
  const [numPages, setNumPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfBuffer, setPdfBuffer] = useState<Blob | undefined>();

  const { width } = useViewport();

  const isMobile = width <= 768;
  const isSmallMobile = width <= 425;
  const isDesktop = width >= 1280;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  var zoomLevel = window.devicePixelRatio || 1;

  useEffect(() => {
    setIsLoading(true);
    downloadProgramPreview(customer_account_id!, serviceId, programVersionId)
      .then((resp) => {
        if (resp) {
          setPdfBuffer(resp.data);
         
        } else {
          setPdfBuffer(undefined);
        }
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [serviceId, programVersionId]);
  return (
    <Grid container spacing={2} direction={"column"} alignContent={"center"} justifyContent={"center"} pt={3} mt={2}>
      <Grid item xs={12}>
        <ElevationScroll>
          <AppBar
            classes={{ root: classes.appBar }} sx={{ paddingRight: 0, "& .MuiAppBar-root": { paddingRight: "0!important" } }}>
            <StyledToolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Stack direction="row" spacing={1} gap={2}>
                  <BsFilePdfFill color="#ff0000" size="30" />

                  {programName}
                </Stack>
              </Typography>
              <IconButton
                size="medium"
                edge="end"
                color="inherit"
                onClick={() => {
                
                  const options: FileSaverOptions = {
                    autoBom: false,
                  };
                  
                  pdfBuffer && saveAs(pdfBuffer, formattedName, options);
                  
                }}
              >
                <GetAppIcon />
              </IconButton>
            </StyledToolbar>
          </AppBar>
        </ElevationScroll>
      </Grid>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      {pdfBuffer && (
        <Document options={options} loading="loading program..." file={pdfBuffer} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              scale={isDesktop ? 2 : isSmallMobile ? 0.4 : isMobile ? 0.5 : 1}
              devicePixelRatio={zoomLevel}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      )}
    </Grid>
  );
}
