import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import {
  IoAddOutline,
  IoCardOutline,
  IoInformationCircleOutline,
  IoLockClosedOutline,
  IoMailOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { AvatarDisplay } from "../../components/Common/Controls/AvatarDisplay";
import { CardLayout } from "../../layouts/Layouts";
import { RootState } from "../../store/store";
import theme from "../../theme";
import { EntityType } from "../../types/models";
import { getStyleColor } from "../../utils/helpers";

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      height: 60,
      fontWeight: "bold",
      backgroundColor: "white",
      color: getStyleColor("-color-accent-2"),
      justifyContent: "flex-start",
      "&:hover": {
        backgroundColor: getStyleColor("--color-primary"),
      },
    },
    endIcon: {
      position: "absolute",
      right: "1rem",
    },
    buttonLabel: {
      left: "1rem",
      position: "absolute",
    },
    paddingBottom: {
      paddingBottom: 10,
    },
    avatar: {
      backgroundColor: "white",
    },
    mainAvatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      backgroundColor: "white",
    },

    underlined: {
      textDecoration: "underline",
    },
  };
});

function AccountHome() {
  const navigate = useNavigate();

  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id) ?? "";

  const contact = useSelector((state: RootState) =>
    state.customer.customer?.contacts?.find((c) => c.customerContactId === parseInt(customer_contact_id.toString()))
  );

  const contactAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find(
      (x) => x.entityType === EntityType.Contact && x.entityId === contact?.customerContactId?.toString()
    )
  );

  var currentContactIsPrimary = contact?.isPrimary;
  var currentContactCanManageAccount = contact?.authorisation?.canManageAccount;

  const colorAccent2 = getStyleColor("--color-accent-2");

  return contact ? (
    <CardLayout id="cardAccount">
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item>
            <AvatarDisplay info={contactAvatar || { index: contact.avatarIndex }} size={80} />
          </Grid>
          <Grid item xs>
            <h1
              className={`regular h3`}
              style={{ marginTop: 0, marginBottom: 8 }}
            >{`${contact.firstName} ${contact.lastName}`}</h1>
            <Grid container direction="row" alignItems="center" spacing={1}>
              <Grid item>
                <IoPersonCircleOutline size={24} color="black" />
              </Grid>
              <Grid item>ID {contact.customerAccountId}</Grid>
            </Grid>
            {currentContactIsPrimary && (
              <Button
                variant="outlined"
                component={Link}
                id="btnEditContact"
                to={`/edit-contact/${contact.customerContactId}`}
                style={{ textTransform: "none", marginTop: theme.spacing(1) }}
              >
                Edit my details
              </Button>
            )}
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body style={{ padding: 0, minHeight: 0 }}>
        <Grid container alignItems="stretch" alignContent="stretch">
          {currentContactCanManageAccount && (
            <Grid item xs={12} md={6} style={{ padding: "2rem" }}>
              <DisplayChildren />
              <DisplayContacts />
            </Grid>
          )}
          <Grid item xs={12} md={6} style={{ backgroundColor: getStyleColor("--color-accent-4"), padding: "2rem" }}>
            <Grid container spacing={2} direction="column">
              {currentContactCanManageAccount && (
                <Grid item xs={12}>
                  <ActionButton
                    id="btnPayment"
                    Text="PAYMENT DETAILS"
                    onClick={() => navigate("/payment-method")}
                    EndIcon={<IoCardOutline size={24} color={colorAccent2} />}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ActionButton
                  id="btnPassword"
                  Text="PASSWORD"
                  onClick={() => navigate("/reset-password")}
                  EndIcon={<IoLockClosedOutline size={24} color={colorAccent2} />}
                />
              </Grid>
              <Grid item xs={12}>
                <ActionButton
                  Text="LOGIN EMAIL"
                  id="btnLoginEmail"
                  onClick={() => navigate("/change-login")}
                  EndIcon={<IoMailOutline size={24} color={colorAccent2} />}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <ActionButton
                  Text="NOTIFICATIONS"
                  id="btnNotifications"
                  onClick={() => navigate("/account/notifications")}
                  EndIcon={<IoNotificationsOutline size={24} color={colorAccent2} />}
                />
              </Grid> */}
              {currentContactCanManageAccount && (
                <Grid item xs={12}>
                  <ActionButton
                    Text="PRIVACY"
                    id="btnPrivacy"
                    onClick={() => navigate("/account/privacy")}
                    EndIcon={<IoInformationCircleOutline size={24} color={colorAccent2} />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  ) : null;
}

function DisplayContacts() {
  const { classes } = useStyles();
  const contacts = useSelector((state: RootState) => state.customer.customer?.contacts?.filter((x) => !x.isPrimary));
  const avatars = useSelector((state: RootState) =>
    state.avatars.avatars.filter((x) => x.entityType === EntityType.Contact)
  );

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <strong>Contacts</strong>
      </Grid>
      <Grid item xs={12}>
        {contacts &&
          contacts.length > 0 &&
          contacts.map((c, index) => {
            const name = c.firstName + " " + c.lastName;
            const avatar = avatars.find((x) => x.entityId === c.customerContactId?.toString());
            return (
              <Grid
                container
                className={classes.paddingBottom}
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
                direction="row"
                key={index}
              >
                <IconButton component={Link} to={`/edit-contact/${c.customerContactId}`}>
                  <AvatarDisplay info={avatar || { index: c.avatarIndex }} size={40} />
                </IconButton>
                <Button component={Link} to={`/edit-contact/${c.customerContactId}`} style={{ textTransform: "none" }}>
                  <strong>{name}</strong>
                </Button>
              </Grid>
            );
          })}
        <Grid
          container
          alignItems="center"
          className={classes.paddingBottom}
          justifyContent="flex-start"
          spacing={1}
          direction="row"
        >
          <IconButton aria-label="add" component={Link} to={"/add-contact"}>
            <Avatar className={classes.avatar} component={Paper} elevation={2}>
              <IoAddOutline size={28} color={theme.palette.primary.dark} />
            </Avatar>
          </IconButton>
          <Button component={Link} to={"/add-contact"} style={{ textTransform: "none" }}>
            <span> Add Contact</span>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
function DisplayChildren() {
  const { classes } = useStyles();
  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const avatars = useSelector((state: RootState) =>
    state.avatars.avatars.filter((x) => x.entityType === EntityType.Child)
  );
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <strong>Children</strong>
      </Grid>
      <Grid item xs={12}>
        {children &&
          children.length > 0 &&
          children.map((c, index) => {
            const name = c.firstName + " " + c.lastName;
            const avatar = avatars.find((x) => x.entityId === c.childId?.toString());
            return (
              <Grid
                container
                className={classes.paddingBottom}
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
                direction="row"
                key={index}
              >
                <IconButton component={Link} to={`/edit-child/${c.childId}`}>
                  <AvatarDisplay info={avatar || { index: c.avatarIndex }} size={40} />
                </IconButton>
                <Grid item>
                  <Grid container direction="column" alignItems="flex-start">
                    <Button component={Link} to={`/edit-child/${c.childId}`} style={{ textTransform: "none" }}>
                      <strong>{name}</strong>
                    </Button>
                    <div style={{ padding: theme.spacing(1), whiteSpace: "pre-wrap" }}>
                      {c.schoolName?.toUpperCase() ?? ""}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        <Grid
          container
          alignItems="center"
          className={classes.paddingBottom}
          justifyContent="flex-start"
          spacing={1}
          direction="row"
        >
          <IconButton aria-label="add" component={Link} to={"/add-child"}>
            <Avatar className={classes.avatar} component={Paper} elevation={2}>
              <IoAddOutline size={28} color={theme.palette.primary.dark} />
            </Avatar>
          </IconButton>
          <Grid item>
            <Grid container direction="column" alignItems="flex-start">
              <Button component={Link} to={"/add-child"} style={{ textTransform: "none" }}>
                <span>Add Child</span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ActionButton(props: any) {
  const { classes } = useStyles();
  return (
    <Button
      fullWidth
      id={props.id}
      onClick={props.onClick}
      className={classes.button}
      classes={{
        endIcon: classes.endIcon,
      }}
      variant="contained"
      endIcon={props.EndIcon}
    >
      {props.Text}
    </Button>
  );
}
export default AccountHome;
