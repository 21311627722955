import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { newUserSuccess } from "./newUserStateActions";

export interface NewUserState {
  isNewUser: boolean;
}

export const initialState: NewUserState = {
  isNewUser: false,
};

const appConfigsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    newUserSuccess,
    (state): NewUserState => ({
      ...state,
      isNewUser: true,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default appConfigsReducer;
