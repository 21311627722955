import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { List } from "linqts";

import { UpcomingCharge } from "../../types/types";
import { formatCurrency } from "../../utils/helpers";

export interface UpcomingServiceTransactionProps {
  transactions: UpcomingCharge[];
}

const UpcomingServiceTransaction = (props: UpcomingServiceTransactionProps) => {
  const serviceDescription = (charge: UpcomingCharge) => {
    return `${charge.firstName} ${charge.lastName} ( ${charge.rollName}-${
      !charge.hasAttended && charge.isMarked && charge.cancellationStatusId === 0
        ? "Absent without Cancellation"
        : charge.cancellationStatusId === 0
        ? charge.feeName ?? "Attended"
        : "Absent with Cancellation"
    })${charge.multiChildrenDiscount !== 0 ? " - Multi-Children Discount: -$" + -charge.multiChildrenDiscount : ""}`;
  };
  const arr = new List<UpcomingCharge>(props.transactions);
  const transactionsByService = arr.GroupBy((t) => t.serviceName);

  return (
    <Grid item xs={12}>
      {Object.keys(transactionsByService).map((value, index) => {
        return (
          <Grid container spacing={1} key={index}>
            <Grid item xs={12}>
              <span className="color-dark-grey">{value.toUpperCase()}</span>
            </Grid>
            {transactionsByService[value].map((value, index) => {
              return (
                <Fragment key={index}>
                  <Grid item xs={9}>
                    {serviceDescription(value)}
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justifyContent="flex-end">
                      {formatCurrency(value.grossAmount - value.discountAmount)}
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}

            <Box paddingTop={3} />
          </Grid>
        );
      })}
      <Box paddingTop={3} />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            Daily Total
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container justifyContent="flex-end">
            {formatCurrency(
              props.transactions.reduce((sum, current) => sum + (current.grossAmount - current.discountAmount), 0)
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpcomingServiceTransaction;
