import { RegistrationHeader } from "../../components/Common/Headers/Headers";
import { RegistrationPage } from "../../components/Common/Pages/Pages";
import FormAddContacts from "../../components/Forms/AddContacts/AddContacts";
import { LayoutAccent1 } from "../../layouts/Layouts";

function AddContacts() {
  return (
    <LayoutAccent1 showSideNav={false}>
      <RegistrationPage>
        <RegistrationHeader heading="Primary Information">
          <p>Please add the names of any contacts.</p>
        </RegistrationHeader>
        <FormAddContacts />
      </RegistrationPage>
    </LayoutAccent1>
  );
}

export default AddContacts;
