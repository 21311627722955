import { createActionCreator } from "deox";

import { AppStatusHttpResponse } from "../../parentApi";

export const appStatusLoaded = createActionCreator(
  "appStatusLoaded",
  (resolve) => (status: AppStatusHttpResponse | null) => resolve(status)
);

export const appStatusLoading = createActionCreator("appStatusLoading");
export const appStatusReset = createActionCreator("appStatusReset");
