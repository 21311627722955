import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { merge } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { v4 as uuid } from "uuid";

import { useAppDispatch } from "../../../store/hooks";
import * as actions from "../../../store/modules/register/registerActions";
import { RootState } from "../../../store/reducers";
import { IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { isNullOrEmpty } from "../../../utils/stringUtils";
import MedicalContactInformation from "../../Children/MedicalContactInformation";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import ChildStep7Schema from "./ChildStep7Schema";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep7({ child, id }: Props) {
  const children = useSelector((state: RootState) => state.register?.children);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  function updateChild(values: IChild) {
    actions.saveChild(dispatch, values);
    navigate(`/registration-child-step/8/${id}`);
  }

  if (child.sameMedicalContactAsFirstChild === null) {
    child.sameMedicalContactAsFirstChild = true;
  }

  var childIndex = children.indexOf(child);
  var firstChild = childIndex !== 0 && children[0].uniqueId !== child.uniqueId ? children[0] : null;

  var firstChildHasMedicalContact = false;
  if (firstChild !== null) {
    firstChildHasMedicalContact =
      !isNullOrEmpty(firstChild.medicalServiceName) &&
      !isNullOrEmpty(firstChild.doctorName) &&
      !isNullOrEmpty(firstChild.doctorPhone) &&
      !isNullOrEmpty(firstChild.doctorAddress) &&
      !isNullOrEmpty(firstChild.doctorSuburb) &&
      !isNullOrEmpty(firstChild.doctorPostcode) &&
      !isNullOrEmpty(firstChild.doctorState);

    child.sameMedicalContactAsFirstChild = child.sameMedicalContactAsFirstChild ?? firstChildHasMedicalContact;
  }

  const formikValues = {
    firstChildHasMedicalContact: firstChildHasMedicalContact,
    medicalServiceName: "",
    doctorName: "",
    doctorPhone: "",
    addressUniqueId: uuid(),
    doctorAddress: "",
    doctorSuburb: "",
    doctorPostcode: "",
    doctorState: "",
    medicareNumber: "",
  };
  const initialValues = merge(formikValues, child);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChildStep7Schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          updateChild(values);
        }, 500);
      }}
    >
      {({ handleChange, setFieldValue, handleBlur, submitForm, isSubmitting, values, touched, errors }) => (
        <Form>
          <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12} md={12}>
              <MedicalContactInformation
                classes={classes}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                errors={errors}
                firstChild={firstChild}
              />
            </Grid>
          </Grid>
          <Pager>
            <ButtonSecondary href={`/registration-child-step/6/${id}`}>
              <strong>Back</strong>
            </ButtonSecondary>
            <ButtonPrimary disabled={isSubmitting} onClick={submitForm}>
              <strong>Next</strong>
            </ButtonPrimary>
          </Pager>
          <Backdrop className={classes.backdrop} open={isSubmitting}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Form>
      )}
    </Formik>
  );
}

export default FormChildStep7;
