import { createReducer } from "deox";

import {
  SaveBookingError,
  SaveBookingFailed,
  SaveBookingInitial,
  SaveBookingLoading,
  SaveBookingSuccess,
  SaveVacBookingComplete,
} from "./bookingStateActions";
import { IErrorResponse } from "../../../types/types";

export interface SaveBookingState {
  errorResponse: IErrorResponse | null;
  isErrorState: boolean;
  isSaving: boolean;
  isSuccess: boolean;
  isComplete: boolean;
  data?: any;
}

export const InitialState: SaveBookingState = {
  errorResponse: null,
  isErrorState: false,
  isSaving: false,
  isSuccess: false,
  isComplete: false,
};

const bookingCreateReducer = createReducer(InitialState, (handleAction) => [
  handleAction(SaveBookingSuccess, (state, { payload }) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: true,
    isComplete: false,
    data: payload,
  })),
  handleAction(SaveBookingLoading, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: true,
    isSuccess: false,
    isComplete: false,
    data: undefined,
  })),
  handleAction(SaveBookingError, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
    isComplete: false,
    data: undefined,
  })),
  handleAction(SaveBookingFailed, (state, { payload }) => ({
    ...state,
    errorResponse: payload,
    isErrorState: true,
    isSaving: false,
    isSuccess: false,
    isComplete: false,
    data: undefined,
  })),
  handleAction(SaveBookingInitial, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
    isComplete: false,
    data: undefined,
  })),
  handleAction(SaveVacBookingComplete, (state, { payload }) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: true,
    isComplete: true,
    data: payload,
  })),
]);

export default bookingCreateReducer;
