import { AvatarInfo, EntityType } from "../../../types/models";
import { Contact, Document, DocumentTypes, FileResponse, IChild } from "../../../types/types";
import { blobToDataURL } from "../../../utils/helpers";
import api from "./avatarApi";
import { avatarsLoading, setAvatar } from "./avatarStateActions";
import documentApi from "./documentApi";

export const setUnregisteredAvatars = (
  dispatch: any,
  avatars: AvatarInfo[],
  contacts: Contact[],
  children: IChild[],
  documents: Document[]
) => {
  dispatch(avatarsLoading());

  var avatarDocuments = documents?.filter(
    (x) =>
      x.documentTypeId === DocumentTypes.ChildAvatarSmallSize ||
      x.documentTypeId === DocumentTypes.ContactAvatarSmallSize
  );
  children.forEach((child) => {
    var childUniqueId = child.uniqueId;
    if (childUniqueId) {
      var avatar = avatars.find((x) => x.entityType === EntityType.RegistrationChild && x.entityId === childUniqueId);
      if (avatar === undefined || avatar.isLoading) {
        var avatarInfo = {
          entityType: EntityType.RegistrationChild,
          entityId: child.uniqueId!,
          isLoading: false,
        };

        if (child.avatarIndex !== undefined && child.avatarIndex > -1) {
          dispatch(setAvatar({ ...avatarInfo, index: child.avatarIndex }));
        } else {
          var document = avatarDocuments.find((x) => x.registrationChildUniqueId === childUniqueId);
          if (document && document.uniqueId) {
            getAvatar(dispatch, document.uniqueId, childUniqueId, undefined);
          } else {
            dispatch(setAvatar({ ...avatarInfo, setDefault: true }));
          }
        }
      }
    }
  });

  contacts.forEach((contact) => {
    var contactUniqueId = contact.uniqueId;
    if (contactUniqueId) {
      var avatar = avatars.find(
        (x) => x.entityType === EntityType.RegistrationContact && x.entityId === contactUniqueId
      );
      if (avatar === undefined || avatar.isLoading) {
        var avatarInfo = {
          entityType: EntityType.RegistrationContact,
          entityId: contact.uniqueId!,
          isLoading: false,
        };

        if (contact.avatarIndex !== undefined && contact.avatarIndex > -1) {
          dispatch(setAvatar({ ...avatarInfo, index: contact.avatarIndex }));
        } else {
          var document = avatarDocuments.find((x) => x.registrationContactUniqueId === contactUniqueId);
          if (document && document.uniqueId) {
            getAvatar(dispatch, document.uniqueId, undefined, contactUniqueId);
          } else {
            dispatch(setAvatar({ ...avatarInfo, setDefault: true }));
          }
        }
      }
    }
  });
};

async function getAvatar(
  dispatch: any,
  avatarDocumentUniqueId: string,
  childUniqueId: string | undefined,
  contactUniqueId: string | undefined
) {
  try {
    var entityId = childUniqueId !== undefined ? childUniqueId : contactUniqueId!;
    var avatarInfo = {
      entityType: childUniqueId !== undefined ? EntityType.RegistrationChild : EntityType.RegistrationContact,
      entityId: entityId,
      isLoading: true,
    };

    dispatch(setAvatar(avatarInfo));

    await api.downloadDocument(avatarDocumentUniqueId).then(function (value: FileResponse | null) {
      if (value && value.status === 200) {
        return blobToDataURL(
          value.data,
          function (dataurl: string) {
            dispatch(setAvatar({ ...avatarInfo, imageDataUrl: dataurl, index: undefined, isLoading: false }));
          },
          function () {
            setDefaultAvatar();
          }
        );
      } else {
        setDefaultAvatar();
      }
    });
  } catch (err) {}

  function setDefaultAvatar() {
    dispatch(setAvatar({ ...avatarInfo, setDefault: true, isLoading: false }));
  }
}

export const setContactAvatar = (dispatch: any, customerAccountId: number, contactId: number) => {
  var avatarInfo = {
    entityType: EntityType.Contact,
    entityId: contactId.toString(),
    isLoading: true,
  };

  dispatch(setAvatar(avatarInfo));

  try {
    documentApi
      .downloadContactDocumentByType(customerAccountId, contactId, DocumentTypes.ContactAvatarSmallSize)
      .then(function (value: FileResponse | null) {
        if (value && value.status === 200) {
          return blobToDataURL(
            value.data,
            function (dataurl: string) {
              dispatch(setAvatar({ ...avatarInfo, imageDataUrl: dataurl, index: undefined, isLoading: false }));
            },
            function () {}
          );
        }
      });
  } catch (err) {
    console.log(err);
    dispatch(setAvatar({ ...avatarInfo, setDefault: true, isLoading: false }));
  }
};

export const setRegisteredContactAvatars = (
  dispatch: any,
  avatars: AvatarInfo[],
  customerAccountId: number,
  contacts: Contact[],
  documents: Document[]
) => {
  dispatch(avatarsLoading());
  var avatarDocuments = documents?.filter((x) => x.documentTypeId === DocumentTypes.ContactAvatarSmallSize);

  contacts.forEach((contact) => {
    var customerContactId = contact.customerContactId;
    if (customerContactId) {
      var avatar = avatars.find((x) => x.entityType === EntityType.Contact && x.entityId === customerContactId);
      if (avatar === undefined || avatar.isLoading) {
        var avatarInfo = {
          entityType: EntityType.Contact,
          entityId: customerContactId.toString(),
          isLoading: false,
        };

        if (contact.avatarIndex !== undefined && contact.avatarIndex > -1) {
          dispatch(setAvatar({ ...avatarInfo, index: contact.avatarIndex }));
        } else {
          var document = avatarDocuments.find((x) => x.customerContactId === customerContactId);
          if (document && document.uniqueId) {
            setContactAvatar(dispatch, customerAccountId, customerContactId);
          } else {
            dispatch(setAvatar({ ...avatarInfo, setDefault: true }));
          }
        }
      }
    }
  });
};

export const setChildAvatar = (dispatch: any, customerAccountId: number, childId: number) => {
  var avatarInfo = {
    entityType: EntityType.Child,
    entityId: childId.toString(),
    isLoading: true,
  };

  dispatch(setAvatar(avatarInfo));

  try {
    documentApi
      .downloadChildDocumentByType(customerAccountId, childId, DocumentTypes.ChildAvatarSmallSize)
      .then(function (value: FileResponse | null) {
        if (value && value.status === 200) {
          return blobToDataURL(
            value.data,
            function (dataurl: string) {
              dispatch(setAvatar({ ...avatarInfo, imageDataUrl: dataurl, index: undefined, isLoading: false }));
            },
            function () {}
          );
        }
      });
  } catch (err) {
    console.log(err);
    dispatch(setAvatar({ ...avatarInfo, setDefault: true, isLoading: false }));
  }
};

export const setRegisteredChildAvatars = (
  dispatch: any,
  avatars: AvatarInfo[],
  customerAccountId: number,
  children: IChild[],
  documents: Document[]
) => {
  dispatch(avatarsLoading());
  var avatarDocuments = documents?.filter((x) => x.documentTypeId === DocumentTypes.ChildAvatarSmallSize);

  children.forEach((child) => {
    var childId = child.childId;
    if (childId) {
      var avatar = avatars.find((x) => x.entityType === EntityType.Child && x.entityId === childId);
      if (avatar === undefined || avatar.isLoading) {
        var avatarInfo = {
          entityType: EntityType.Child,
          entityId: childId.toString(),
          isLoading: false,
        };

        if (child.avatarIndex !== undefined && child.avatarIndex > -1) {
          dispatch(setAvatar({ ...avatarInfo, index: child.avatarIndex }));
        } else {
          var document = avatarDocuments.find((x) => x.childId === childId);
          if (document && document.uniqueId) {
            setChildAvatar(dispatch, customerAccountId, childId);
          } else {
            dispatch(setAvatar({ ...avatarInfo, setDefault: true }));
          }
        }
      }
    }
  });
};
