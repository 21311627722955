import { createReducer } from "deox";

import {
  CancelBookingError,
  CancelBookingInitial,
  CancelBookingLoading,
  CancelBookingSuccess,
} from "./bookingStateActions";

export interface SaveBookingState {
  isErrorState: boolean;
  isSaving: boolean;
  isSuccess: boolean;
}

export const InitialState: SaveBookingState = {
  isErrorState: false,
  isSaving: false,
  isSuccess: false,
};

const bookingCancelReducer = createReducer(InitialState, (handleAction) => [
  handleAction(CancelBookingSuccess, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: true,
  })),
  handleAction(CancelBookingLoading, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: true,
    isSuccess: false,
  })),
  handleAction(CancelBookingError, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
  })),
  handleAction(CancelBookingInitial, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
  })),
]);

export default bookingCancelReducer;
