import { Route, Routes } from "react-router-dom";

import TermsAndConditions from "../components/Forms/TermsAndConditions/TermsAndConditions";
import AccountHome from "../pages/Account/AccountHome";
import Notifications from "../pages/Account/Notifications";
import Privacy from "../pages/Account/Privacy";
import SigninSilentOidc from "../pages/Auth/SilentRenewOidc";
import Billing from "../pages/Billing/Billing";
import PayNow from "../pages/Billing/PayNow";
import BookingComplete from "../pages/Booking/BookingComplete";
import BookingPage from "../pages/Booking/BookingPage";
import Bookings from "../pages/Booking/Bookings";
import ConfirmOSHCBooking from "../pages/Booking/ConfirmOSHCBooking";
import EditBookingConfirm from "../pages/Booking/EditBookingConfirm";
import NewOSHCBooking from "../pages/Booking/NewOSHCBooking";
import NewVACBooking from "../pages/Booking/NewVACBooking";
import NewVACBookingConfirm from "../pages/Booking/NewVACBookingConfirm";
import NewVACBookingSelection from "../pages/Booking/NewVACBookingSelection";
import ChangeEmail from "../pages/ChangeEmail/ChangeEmail";
import EditChild from "../pages/Child/EditChild";
import EditContact from "../pages/Contact/EditContact";
import Dashboard from "../pages/Dashboard/Dashboard";
import NewEventBooking from "../pages/Events/NewEventBooking";
import NewEventBookingConfirm from "../pages/Events/NewEventBookingConfirm";
import Gallery from "../pages/Gallery/Gallery";
import PaymentMethod from "../pages/PaymentMethod/PaymentMethod";
import RegistrationComplete from "../pages/Registration/RegistrationComplete";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import YourServices from "../pages/YourServices/YourServices";

function AuthenticatedRegisteredRoutes() {
  return (
    <Routes>
      <Route path="/bookings" element={<Bookings />}></Route>
      <Route path="/new-booking" element={<BookingPage />}></Route>
      <Route path="/gallery" element={<Gallery />}></Route>
      <Route path="/billing" element={<Billing />}></Route>
      <Route path="/your-services" element={<YourServices />}></Route>
      <Route path="/new-oshc" element={<NewOSHCBooking />}></Route>
      <Route path="/new-vac" element={<NewVACBooking />}></Route>
      <Route path="/confirm-oshc" element={<ConfirmOSHCBooking />}></Route>
      <Route path="/pay-now" element={<PayNow />}></Route>
      <Route path="/payment-method" element={<PaymentMethod />}></Route>
      <Route path="/account-home" element={<AccountHome />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route path="/oshc-complete" element={<BookingComplete />}></Route>
      <Route path="/change-login" element={<ChangeEmail />}></Route>
      <Route path="/account/privacy" element={<Privacy />}></Route>
      <Route path="/account/notifications" element={<Notifications />}></Route>
      <Route path="/vac-selection" element={<NewVACBookingSelection />}></Route>
      <Route path="/terms-conditions" element={<TermsAndConditions />}></Route>
      <Route path="/edit-child/:id" element={<EditChild />}></Route>
      <Route path="/add-child" element={<EditChild />}></Route>
      <Route path="/edit-contact/:id" element={<EditContact />}></Route>
      <Route path="/add-contact" element={<EditContact />}></Route>
      <Route path="/vac-confirm" element={<NewVACBookingConfirm />}></Route>
      <Route path="/vac-complete" element={<BookingComplete />}></Route>
      <Route path="/edit-booking" element={<BookingPage />}></Route>
      <Route path="/edit-confirm" element={<EditBookingConfirm />}></Route>
      <Route path="/silent-renew-oidc" element={<SigninSilentOidc />}></Route>
      <Route path="/new-event-booking" element={<NewEventBooking />}></Route>
      <Route path="/event-booking-confirm" element={<NewEventBookingConfirm />}></Route>
      <Route path="/event-booking-complete" element={<BookingComplete />}></Route>
      <Route path="/registration-complete" element={<RegistrationComplete />}></Route>
      <Route path="*" element={<Dashboard />}></Route>
      <Route path="/account/register-contacts/" element={<Dashboard />}></Route>
    </Routes>
  );
}

export default AuthenticatedRegisteredRoutes;
