import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export function getAusTimeZone() {
  const offset = dayjs().tz("Australia/Melbourne").utcOffset() / 60;
  if (offset === 11) return "AEDT";
  return "AEST";
}

export const LocalOpenHours = () => {
  return `5am - 9pm ${getAusTimeZone()} Monday – Friday`;
};

export const LocalWeekendOpenHours = () => {
  return `7am - 9pm ${getAusTimeZone()} Weekends`;
};
