import { Dayjs } from "dayjs";
import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { dueDatesError, dueDatesLoaded, dueDatesLoading } from "./paymentStateActions";

export interface BillingDueDateState {
  isError: boolean;
  isProcessing: boolean;
  isSuccess: boolean;
  dates: Dayjs[];
  year: number | undefined;
}

export const initialState: BillingDueDateState = {
  isError: false,
  isProcessing: false,
  isSuccess: false,
  dates: [],
  year: undefined,
};

const billingDueDateReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    dueDatesLoaded,
    (state, action): BillingDueDateState => ({
      ...state,
      isProcessing: false,
      isError: false,
      isSuccess: true,
      dates: action.payload.dates,
      year: action.payload.year,
    })
  ),
  handleAction(
    dueDatesLoading,
    (state): BillingDueDateState => ({
      ...state,
      isProcessing: true,
      isError: false,
      isSuccess: false,
      dates: [],
    })
  ),
  handleAction(
    dueDatesError,
    (state, action): BillingDueDateState => ({
      ...state,
      isError: true,
      isProcessing: false,
      isSuccess: false,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default billingDueDateReducer;
