import api from "./appStatusApi";
import { appStatusLoading, appStatusLoaded } from "./appStatusStateActions";
import { AppStatusHttpResponse } from "../../parentApi";

export function getAppStatus(dispatch: any) {
  dispatch(appStatusLoading());
  api.getCurrent().then((value: AppStatusHttpResponse | null) => {
    dispatch(appStatusLoaded(value));
  });
}
