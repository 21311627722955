import { createActionCreator } from "deox";

import { AccountStatement } from "../../../types/types";
export const balanceLoading = createActionCreator("balanceLoading");
export const balanceError = createActionCreator("balanceError");

export const balanceSuccess = createActionCreator(
  "balanceSuccess",
  (resolve) => (accountBalance: AccountStatement) => resolve(accountBalance)
);
