import React, { useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { CardLayout } from "../../../layouts/Layouts";
import { ChangeLogin, ChangeLoginStatus } from "../../../store/modules/changeLogin/changeLoginActions";
import { RootState } from "../../../store/store";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";
import ChangeEmailSchema from "./ChangeEmailSchema";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  primary: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: getStyleColor("--color-accent-1"),
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  secondary: {
    borderColor: "black",
    borderWidth: 2,
    borderRadius: 30,
    height: 50,
    textTransform: "none",
  },
}));

function FormChangeEmail() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const changeLoginStatus = useSelector((state: RootState) => state.changeLogin).status;
  const changeLoginError = useSelector((state: RootState) => state.changeLogin).payload;
  const [localStatus, setLocalStatus] = React.useState<ChangeLoginStatus>("Initial");
  const [localMessage, setLocalMessage] = React.useState<string>("");

  useEffect(() => {
    if (changeLoginStatus !== "Initial") {
      setLocalStatus(changeLoginStatus);
    }
  }, [changeLoginStatus]);
  useEffect(() => {
    if (changeLoginError) {
      setLocalMessage(changeLoginError.toString());
    }
  }, [changeLoginError]);

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <h1 className="h2">Change Login Email</h1>
      </CardLayout.Header>
      <CardLayout.Body>
        <Formik
          validationSchema={ChangeEmailSchema}
          initialValues={{
            newEmail: "",
            confirmEmail: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              if (customer_account_id && customer_contact_id) {
                ChangeLogin(dispatch, customer_account_id, customer_contact_id, values.newEmail);
              }
            }, 500);
          }}
        >
          {({
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            submitForm,
            isSubmitting,
            values,
            touched,
            errors,
          }) => (
            <Form className={classes.root} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name="newEmail"
                    value={values.newEmail}
                    label="New Login Email"
                    className={classes.textField}
                    fullWidth
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText error={Boolean(touched.newEmail && errors.newEmail)}>
                    {touched.newEmail && errors.newEmail ? errors.newEmail : ""}
                  </FormHelperText>

                  <TextField
                    className={classes.textField}
                    onChange={handleChange}
                    name="confirmEmail"
                    fullWidth
                    type="email"
                    onBlur={handleBlur}
                    value={values.confirmEmail}
                    label="Confirm Email"
                  />
                  <FormHelperText error={Boolean(touched.confirmEmail && errors.confirmEmail)}>
                    {touched.confirmEmail && errors.confirmEmail ? errors.confirmEmail : ""}
                  </FormHelperText>
                  <Box marginTop={1}>
                    <Alert severity="info" color="warning">
                      Please note, updating this email will require you to be automatically logged out, and to verify
                      your account via email.
                    </Alert>
                  </Box>
                  {localStatus === "Errored" && (
                    <Box marginTop={1}>
                      <Alert severity="error">{localMessage}</Alert>
                    </Box>
                  )}
                  {localStatus === "Success" && (
                    <Box marginTop={1}>
                      <Alert severity="success">Login Email has been changed</Alert>
                    </Box>
                  )}
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Box paddingTop={2} />
                      <ButtonPrimary type="submit" disabled={localStatus === "Pending"}>
                        <strong>Update</strong>
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Backdrop className={classes.backdrop} open={localStatus === "Pending"}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Form>
          )}
        </Formik>
      </CardLayout.Body>
    </CardLayout>
  );
}

export default FormChangeEmail;
