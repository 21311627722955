import { useLocation } from "react-router-dom";

import { parseJSON } from "./jsonUtil";

export function GetPageState<T>() {
  var location = useLocation();
  if (location.state) {
    return parseJSON(location.state as string) as T;
  }
  return null;
}
