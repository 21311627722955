import { Route, Routes } from "react-router-dom";

import SigninSilentOidc from "../pages/Auth/SilentRenewOidc";
import AddChildren from "../pages/Registration/AddChildren";
import AddContacts from "../pages/Registration/AddContacts";
import ChildStep1 from "../pages/Registration/ChildStep1";
import ChildStep2 from "../pages/Registration/ChildStep2";
import ChildStep3 from "../pages/Registration/ChildStep3";
import ChildStep4 from "../pages/Registration/ChildStep4";
import ChildStep5 from "../pages/Registration/ChildStep5";
import ChildStep6 from "../pages/Registration/ChildStep6";
import ChildStep7 from "../pages/Registration/ChildStep7";
import ChildStep8 from "../pages/Registration/ChildStep8";
import ChildStep9 from "../pages/Registration/ChildStep9";
import ContactStep1 from "../pages/Registration/ContactStep1";
import ContactStep2 from "../pages/Registration/ContactStep2";
import ContactStep3 from "../pages/Registration/ContactStep3";
import Introduction from "../pages/Registration/Introduction";
import Payment from "../pages/Registration/Payment";
import PendingInformation from "../pages/Registration/PendingInformation";

function AuthenticatedUnregisteredRoutes() {
  return (
    <Routes>
      <Route path="/registration-introduction" element={<Introduction />}></Route>
      <Route path="/registration-add-contacts" element={<AddContacts />}></Route>
      <Route path="/registration-add-children" element={<AddChildren />}></Route>
      <Route path="/registration-pending-information" element={<PendingInformation />}></Route>
      <Route path="/registration-contact-step/1/:id" element={<ContactStep1 />}></Route>
      <Route path="/registration-contact-step/2/:id" element={<ContactStep2 />}></Route>
      <Route path="/registration-contact-step/3/:id" element={<ContactStep3 />}></Route>
      <Route path="/registration-child-step/1/:id" element={<ChildStep1 />}></Route>
      <Route path="/registration-child-step/2/:id" element={<ChildStep2 />}></Route>
      <Route path="/registration-child-step/3/:id" element={<ChildStep3 />}></Route>
      <Route path="/registration-child-step/4/:id" element={<ChildStep4 />}></Route>
      <Route path="/registration-child-step/5/:id/:medicalConditionId?" element={<ChildStep5 />}></Route>
      <Route path="/registration-child-step/6/:id" element={<ChildStep6 />}></Route>
      <Route path="/registration-child-step/7/:id" element={<ChildStep7 />}></Route>
      <Route path="/registration-child-step/8/:id" element={<ChildStep8 />}></Route>
      <Route path="/registration-child-step/9/:id" element={<ChildStep9 />}></Route>
      <Route path="/registration-payment" element={<Payment />}></Route>
      <Route path="/silent-renew-oidc" element={<SigninSilentOidc />}></Route>

      <Route path="*" element={<Introduction />}></Route>
    </Routes>
  );
}

export default AuthenticatedUnregisteredRoutes;
