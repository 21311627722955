import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid/Grid";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import styles from "./Bookings.module.scss";
import BookNowButton from "../../components/Bookings/BookNowButton";
import { Calendar } from "../../components/Common/Calendars/Calendar";
import Spacer from "../../components/Common/Spacers/Spacers";
import RecurringBookings from "../../components/Scheduler/RecurringBookings";
import Scheduler from "../../components/Scheduler/Scheduler";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { getCommonDates } from "../../store/modules/common/commonActions";
import { RootState } from "../../store/store";
import { BookingToggleType } from "../../types/models";
import { ICommonDatesModel } from "../../types/types";
import { isContactAllowedToBook } from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";

export default function Bookings() {
  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const auth = useSelector((state: RootState) => state.auth);
  const { userId } = auth;
  const customer_contact_id = auth.user?.profile.customer_contact_id;
  const { width } = useViewport();
  const [date, setDate] = useState(dayjs());
  const [commonDates, setCommonDate] = useState<ICommonDatesModel>({
    paymentDueDates: [],
    publicHolidays: [],
  });
  const [year, setYear] = useState(date.year());

  var commonDatesResult = useQuery(
    ["CommonDates", year],
    () => getCommonDates(auth.user!.profile.customer_account_id, year),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (commonDatesResult.status === "success") {
      setCommonDate(commonDatesResult.data);
    }
  }, [commonDatesResult.data]);

  useEffect(() => {
    if (year !== date.year()) {
      setYear(date.year());
    }
  }, [date]);

  var isAllowedToBook = isContactAllowedToBook(customerAccount!, userId!, parseInt(customer_contact_id!.toString()));
  return (
    <LayoutAccent1>
      <Grid container spacing={1} p={"2rem"} pt="1rem" gap={1}>
        <Grid item xs={12} md={6}>
          <h1 className="h2">My Bookings</h1>
        </Grid>

        <Grid item xs={12}>
          {width >= 1280 && <Scheduler date={date} onDateChanged={setDate} commonDates={commonDates} />}

          {width < 1280 && (
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <BookNowButton />
              </Grid>
              <Grid item>
                <div className={styles["Dashboard-Card"]}>
                  <Calendar
                    calendarMode={BookingToggleType.Calendar}
                    allowWeekView={true}
                    isAllowedToBook={isAllowedToBook}
                    date={date}
                    onDateChanged={setDate}
                    commonDates={commonDates}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Spacer margin={10} />
        <Grid item xs={12}>
          <RecurringBookings />
        </Grid>
      </Grid>
    </LayoutAccent1>
  );
}
