import { Dayjs } from "dayjs";
import { createActionCreator } from "deox";

import { ISessionAvailability, Roll } from "../../../types/types";
import exp from "constants";
import { IRollInfo } from "../../parentApi";

export const LoadedRollsError = createActionCreator("LoadedSchoolsError");
export const LoadingRolls = createActionCreator("LoadingRolls");

export const LoadedRolls = createActionCreator(
  "LoadedRolls",
  (resolve) => (rolls: Roll[], serviceId: number) => resolve({ rolls, serviceId })
);

export const LoadedUnavailableDates = createActionCreator(
  "LoadedUnavailableDates",
  (resolve) => (dates: ISessionAvailability[]) => resolve(dates)
);
export const LoadedUnavailableDatesReset = createActionCreator("LoadedUnavailableDatesReset");
export const LoadedUnavailableDatesError = createActionCreator("LoadedUnavailableDatesError");

export const LoadingUnavailableDates = createActionCreator("LoadingUnavailableDates");

export const NextAvailableSessionDateLoaded = createActionCreator(
  "NextAvailableSessionDateLoaded",
  (resolve) => (date: Dayjs | null) => resolve(date)
);
export const NextAvailableSessionDateError = createActionCreator("NextAvailableSessionDateError");

export const SelectOshBookingStep = createActionCreator("SelectOshBookingStep", (resolve) => (step:Number|undefined) => resolve(step));
export const SelectStartDate = createActionCreator("SelectStartDate", (resolve) => (startDate:Dayjs|null|undefined) => resolve(startDate));

export const AddOshBookingSelection = createActionCreator("AddOshBookingSelection", (resolve) => (roll:Roll) => resolve(roll));
export const RemoveOshBookingSelection = createActionCreator("RemoveOshBookingSelection", (resolve) => (roll:Roll) => resolve(roll));
