import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./Dashboard.module.scss";
import NotificationAlert from "../../components/Billing/NotificationAlert";
import { ButtonPrimary } from "../../components/Common/Buttons/Buttons";
import { Calendar } from "../../components/Common/Calendars/Calendar";
import UpcomingBookings from "../../components/Common/Calendars/UpcomingBookings";
import AvailablePrograms from "../../components/Dashboard/AvailablePrograms";
import BillingSection from "../../components/Dashboard/BillingSection";
// import EventCard from "../../components/Events/EventCard";
import BookingOnHoldMessage from "../../components/Forms/BookingOnHoldMessage/BookingOnHoldMessage";
import FormTermsAndConditions from "../../components/Forms/TermsAndConditions/TermsAndConditions";
import OverDuePayment from "../../components/Overdue/Overdue";
import ChildEnrolmentPrompt from "../../components/Prompts/ChildEnrolmentPrompt";
import { LayoutAccent1 } from "../../layouts/Layouts";
import { getCommonDates } from "../../store/modules/common/commonActions";
import { RootState } from "../../store/store";
import { BookingToggleType } from "../../types/models";
import { DebtCategoryEnum, ICommonDatesModel } from "../../types/types";
import {
  contactPlansToClaimChildCareSubsidy,
  creditCardIsExpired,
  getStyleColor,
  isContactAllowedToBook,
} from "../../utils/helpers";

function Dashboard() {
  const customer_contact_id = useSelector((state: RootState) => state.auth.user?.profile.customer_contact_id);
  const customerAccount = useSelector((state: RootState) => state.customer.customer);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const { userId, user } = auth;

  const [date, setDate] = useState(dayjs());
  const [commonDates, setCommonDate] = useState<ICommonDatesModel>({
    paymentDueDates: [],
    publicHolidays: [],
  });
  const [year, setYear] = useState(date.year());

  var commonDatesResult = useQuery(
    ["CommonDates", year],
    () => getCommonDates(user!.profile.customer_account_id, year),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (commonDatesResult.status === "success") {
      setCommonDate(commonDatesResult.data);
    }
  }, [commonDatesResult.data]);

  useEffect(() => {
    if (year !== date.year()) {
      setYear(date.year());
    }
  }, [date]);

  function isAllowedToBook() {
    return isContactAllowedToBook(customerAccount!, userId!, parseInt(customer_contact_id!.toString()));
  }

  function planToClaimSubsidy() {
    return contactPlansToClaimChildCareSubsidy(customerAccount!, userId!, parseInt(customer_contact_id!.toString()));
  }

  const [ccExpired, setCCExpired] = React.useState(false);

  useEffect(() => {
    if (currentPaymentMethod) {
      setCCExpired(creditCardIsExpired(currentPaymentMethod));
    }
  }, [currentPaymentMethod]);

  const handleClickOpen = () => {
    navigate("/new-booking");
  };

  return user === null ? (
    <div>Loading...</div>
  ) : (
    <LayoutAccent1>
      <div className={styles.Dashboard}>
        <Grid container direction="row" spacing={2} pt={0}>
          <Grid item xs={12}>
            <FormTermsAndConditions />
            <BookingOnHoldMessage />
            <OverDuePayment />
            <ChildEnrolmentPrompt />
          </Grid>
          <Grid item xs={12}>
            <NotificationAlert />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={12} md={6} lg={4}>
                {/* Calendar */}
                <Grid container direction="column" spacing={2}>
                  {isAllowedToBook() && (
                    <Grid item xs={12}>
                      <Grid container justifyContent="flex-start" className={styles["Dashboard-BookNow"]}>
                        <Typography variant="body1" color={"white"} align="center">
                          Book your casual and permanent sessions
                        </Typography>
                        {!ccExpired && (
                          <ButtonPrimary
                            id="btnNewBooking"
                            onClick={handleClickOpen}
                            style={{ paddingLeft: 5, paddingRight: 5 }}
                          >
                            <strong>New Booking</strong>
                          </ButtonPrimary>
                        )}
                        {customerAccount?.debtCategoryId === DebtCategoryEnum.CreditInvalidAddress && (
                          <Chip
                            label="Cannot Book: Invalid Address"
                            style={{ backgroundColor: "red", color: "white" }}
                            variant="outlined"
                          />
                        )}
                        {ccExpired && (
                          <Chip
                            label="Cannot Book: Credit Card Expired"
                            style={{ backgroundColor: "red", color: "white" }}
                            variant="outlined"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className={styles["Dashboard-Card"]}>
                      <Calendar
                        calendarMode={BookingToggleType.Calendar}
                        isAllowedToBook={isAllowedToBook()}
                        date={date}
                        onDateChanged={setDate}
                        commonDates={commonDates}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <div className={styles["Dashboard-Card"]}>
                  <UpcomingBookings isAllowedToBook={isAllowedToBook()} />
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={3.5}>
                {/* Billing & payments */}
                <div className={styles["Dashboard-Card"]} style={{ position: "relative" }}>
                  <BillingSection />
                  {planToClaimSubsidy() && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bgcolor: getStyleColor("--color-primary"),
                        padding: "5px",
                        borderBottomLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    >
                      <Typography color={"black"} fontSize={"12px"}>
                        Child care subsidy applied
                      </Typography>
                    </Box>
                  )}
                </div>

                {window.__config.ENABLE_AVAILABLE_PROGRAMS === "true" ? (
                  <div className={styles["Dashboard-Card"]} style={{ position: "relative", marginTop: "20px" }}>
                    <AvailablePrograms />
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LayoutAccent1>
  );
}

export default Dashboard;
