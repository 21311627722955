import { createReducer } from "deox";

import { purge } from "../purge/purgeStateActions";
import { voucherSaving, voucherError, voucherSaved, voucherInit } from "./nswVoucherStateActions";
export interface NswVoucherState {
  isError: boolean;
  isSaving: boolean;
  isSaved: boolean;
  errorMessage: string;
}

export const initialState: NswVoucherState = {
  isError: false,
  isSaving: false,
  isSaved: false,
  errorMessage: "",
};

const nswVoucherReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    voucherSaved,
    (state): NswVoucherState => ({
      ...state,
      isSaving: false,
      isError: false,
      isSaved: true,
      errorMessage: "",
    })
  ),
  handleAction(
    voucherSaving,
    (state): NswVoucherState => ({
      ...state,
      isSaving: true,
      isError: false,
      isSaved: false,
      errorMessage: "",
    })
  ),
  handleAction(
    voucherError,
    (state, action): NswVoucherState => ({
      ...state,
      isError: true,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload,
    })
  ),
  handleAction(purge, () => initialState),
  handleAction(voucherInit, () => initialState),
]);

export default nswVoucherReducer;
