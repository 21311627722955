import dayjs from "dayjs";

import api from "./registerApi";
import {
  RegistrationCompletedAction,
  RegistrationErrorAction,
  RegistrationIsCompleting,
  ResetChildren,
  ResetContacts,
  ResetState,
  SaveChildAction,
  SaveContactAction,
  SetPossibleDuplicatedAccountAction,
  SetRequirePaymentConfiguration,
} from "./registerStateActions";
import history from "../../../routes/History";
import { NewPaymentMethod } from "../../../types/models";
import { Contact, IChild, User } from "../../../types/types";
import { dump } from "../../../utils/stringUtils";
import {
  CompleteRegistrationHttpRequest,
  IRegistrationHttpResponse,
  LogRegistrationEventHttpRequest,
  SaveRegistrationChildHttpRequest,
  SaveRegistrationContactHttpRequest,
} from "../../parentApi";

export const getRegistration = (dispatch: any, user: User | null) =>
  api.getRegistrationByUserId().then(
    (response: IRegistrationHttpResponse | null) => initializeState(dispatch, user, response),
    (error: any) => console.log(error)
  );

export const saveContacts = (
  dispatch: any,
  saveContacts: Contact[],
  removeContacts: Contact[],
  redirectOnSuccess?: string
) => {
  saveContacts.forEach((contact) => {
    var httpContact = new SaveRegistrationContactHttpRequest();
    httpContact.init(contact);
    api.saveContact(httpContact);
  });

  removeContacts.forEach((contact) => {
    const uniqueId = contact.uniqueId ?? "";
    if (uniqueId.length > 30) api.removeContact(uniqueId);
  });

  dispatch(ResetContacts(saveContacts));

  if (redirectOnSuccess) history.push(redirectOnSuccess);
};

export const saveChildren = (
  dispatch: any,
  saveChildren: IChild[],
  removeChildren: IChild[],
  redirectOnSuccess?: string
) => {
  saveChildren.forEach((Child) => {
    var httpChild = new SaveRegistrationChildHttpRequest();
    httpChild.init(Child);
    api.saveChild(httpChild);
  });

  removeChildren.forEach((Child) => {
    const uniqueId = Child.uniqueId ?? "";
    if (uniqueId.length > 30) api.removeChild(uniqueId);
  });

  dispatch(ResetChildren(saveChildren));
  if (redirectOnSuccess) history.push(redirectOnSuccess);
};

function initializeState(dispatch: any, user: User | null, response: IRegistrationHttpResponse | null): any {
  var primaryContact = null;

  if (response?.contacts.length === 0) {
    if (response != null && user != null) {
      var profile = user.profile;
      primaryContact = {
        uniqueId: profile.sub,
        email: profile.email,
        firstName: profile.given_name,
        lastName: profile.family_name,
        isPrimary: true,
        createdAt: dayjs().format(),
        avatarIndex: 1,
        authorisation: {
          canApproveAmbulance: true,
          canManageAccount: true,
          canMakeAndChangeBookings: true,
          canPickUpChild: true,
          consentToAdministerMedicine: true,
          consentToMedicalTreatment: true,
          consentToTakeOutsidePremises: true,
          consentForTransportation: true,
        },
      };
      return dispatch(
        ResetState(
          [primaryContact],
          response?.children ?? [],
          response?.documents ?? [],
          response?.possibleDuplicatedAccount
        )
      );
    }
  }

  return dispatch(
    ResetState(
      (response?.contacts ?? []).map((contact) => {
        return contact as Contact;
      }),
      response?.children ?? [],
      response?.documents ?? [],
      response?.possibleDuplicatedAccount ?? false
    )
  );
}

export function saveChild(dispatch: any, child: IChild) {
  try {
    if ((child?.firstName?.length ?? 0) > 0 && (child.lastName?.length ?? 0) > 0) {
      dispatch(SaveChildAction(child));
      var httpChild = new SaveRegistrationChildHttpRequest();

      httpChild.init(child);
      api
        .saveChild(httpChild)
        .then(
          (response: any) => {
            checkRequirePaymentConfiguration(dispatch);
          },
          (error: any) => {
            logSaveChildErrorEvent({
              child,
              error,
            });
          }
        )
        .catch((error: any) => {
          logSaveChildErrorEvent({
            child,
            error,
          });
        });
    }
  } catch (error) {
    logSaveChildErrorEvent({
      child,
      error,
    });
  }
}

export const saveContact = (dispatch: any, contact: Contact) => {
  if ((contact?.firstName?.length ?? 0) > 0 && (contact.lastName?.length ?? 0) > 0) {
    dispatch(SaveContactAction(contact));
    var httpContact = new SaveRegistrationContactHttpRequest();
    httpContact.init(contact);
    api.saveContact(httpContact).then(
      (response: any) => {
        console.log(response);
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
};

export const checkPossibleDuplicatedAccount = (dispatch: any) => async () => {
  try {
    await api.getPossibleDuplicatedAccount().then((response: boolean) => {
      dispatch(SetPossibleDuplicatedAccountAction(response));
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkRequirePaymentConfiguration = async (dispatch: any) => {
  try {
    await api.getRequirePaymentConfiguration().then((response: boolean) => {
      dispatch(SetRequirePaymentConfiguration(response));
    });
  } catch (error) {
    console.log(error);
  }
};

export const complete = async (dispatch: any, children: IChild[], contacts: Contact[], payment?: NewPaymentMethod) => {
  dispatch(RegistrationIsCompleting());
  try {
    var httpCompleteRegistration = new CompleteRegistrationHttpRequest();

    contacts.forEach((contact) => {
      contact.createdAt = undefined;
    });

    children.forEach((child) => {
      child.createdAt = undefined;
    });

    httpCompleteRegistration.init({ children, contacts, payment });
    api
      .completeRegistrationByUserId(httpCompleteRegistration)
      .then(
        (response: any) => {
          dispatch(RegistrationCompletedAction());
          console.log(response);
        },
        (error: any) => {
          dispatch(RegistrationErrorAction(error));
          logCompleteRegistrationErrorEvent({ children, contacts, payment, error });
        }
      )
      .catch((error: any) => {
        dispatch(RegistrationErrorAction(error));
        logCompleteRegistrationErrorEvent({ children, contacts, payment, error });
      });
  } catch (error: any) {
    dispatch(RegistrationErrorAction(error));
    logCompleteRegistrationErrorEvent({ children, contacts, payment, error });
  }
};

export function logRegistrationEvent(payLoad: any, operation: string) {
  try {
    api.logEvent(
      new LogRegistrationEventHttpRequest({
        operation: operation,
        payload: dump(payLoad),
      })
    );
  } catch (dumpEx) {}

  try {
    var operationPayload = JSON.stringify(payLoad);

    if (operationPayload === "") {
      operationPayload = payLoad.toString();
    }

    api.logEvent(
      new LogRegistrationEventHttpRequest({
        operation: operation,
        payload: operationPayload,
      })
    );
  } catch (ex) {
    console.log(ex);
  }
}

function logSaveChildErrorEvent(payLoad: any) {
  logRegistrationEvent(payLoad, "saveChildError");
}

function logCompleteRegistrationErrorEvent(payLoad: any) {
  logRegistrationEvent(payLoad, "completeRegistrationError");
}
