import { createReducer } from "deox";

import {
  serviceProgramsError,
  serviceProgramsLoading,
  serviceProgramsReset,
  serviceProgramsSuccess,
} from "./programStateActions";
import { ServiceProgram } from "../../../types/models";

export interface IServiceProgramState {
  servicePrograms: ServiceProgram[] | null;
  isLoading: boolean;
}

export const initialState: IServiceProgramState = {
  servicePrograms: null,
  isLoading: false,
};

const serviceProgramsReducer = createReducer(initialState, (handleAction) => [
  handleAction(serviceProgramsLoading, (state) => ({ ...state, isLoading: true, servicePrograms: null })),
  handleAction(serviceProgramsError, (state) => ({ ...state, isLoading: false, servicePrograms: null })),
  handleAction(serviceProgramsReset, (state) => ({ ...state, isLoading: false, servicePrograms: null })),
  handleAction(serviceProgramsSuccess, (state, { payload }) => ({
    ...state,
    isLoading: false,
    servicePrograms: payload,
  })),
]);

export default serviceProgramsReducer;
