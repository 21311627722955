import { createReducer } from "deox";

import { BookingEvent } from "../../../types/models";
import { upcomingEventsLoaded, upcomingEventsLoading, upcomingEventsLoadingError } from "./eventStateActions";

export interface IEventsState {
  bookingEvents?: BookingEvent[] | null;
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
}

export const initialState: IEventsState = {
  bookingEvents: null,
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
};

const eventsReducer = createReducer(initialState, (handleAction) => [
  handleAction(upcomingEventsLoading, (state) => ({
    ...state,
    bookingEvents: null,
    isErrorState: false,
    isInitialised: true,
    isLoading: true,
  })),
  handleAction(upcomingEventsLoaded, (state, { payload }) => ({
    ...state,
    bookingEvents: payload,
    isErrorState: false,
    isInitialised: true,
    isLoading: false,
  })),
  handleAction(upcomingEventsLoadingError, (state) => ({
    ...state,
    bookingEvents: null,
    isErrorState: true,
    isInitialised: true,
    isLoading: false,
  })),
]);

export default eventsReducer;
