import React from "react";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";

interface Props {
  id: string;
  label: string;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: any) => void;
  value?: number;
  helperText: any;
  error: any;
}

export const GenderSelector = (props: Props) => {
  const genders = useSelector((state: RootState) => state?.commonData?.data.genders);

  return (
    <TextField
      select
      id={props.id}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      helperText={props.helperText}
      error={props.error}
      variant="filled"
      fullWidth
      required
    >
      <MenuItem value="">Please select</MenuItem>
      {genders.map((gender, index) => (
        <MenuItem value={gender.genderId} key={gender.genderId}>
          {gender.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
