import { SchoolHttpResponse } from "../../parentApi";
import { LoadedSchools, LoadedSchoolsError } from ".//schoolStateActions";
import schoolApi from "./schoolApi";

export function getSchools(shortStateName: string) {
  return function (dispatch: any) {
    schoolApi.getSchoolsByStateName(shortStateName).then(
      (response: SchoolHttpResponse[] | null) =>
        response ? dispatch(LoadedSchools(response, shortStateName)) : dispatch(LoadedSchoolsError()),
      (error: any) => dispatch(LoadedSchoolsError())
    );
  };
}

export function searchSchools(pattern: string) {
  return schoolApi.search(pattern, 10).then((response: SchoolHttpResponse[] | null) => (response ? response : []));
}
