import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { IChild } from "../../../types/types";
import { getStyleColor } from "../../../utils/helpers";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";

interface Props {
  child: IChild;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
  },
  subHeading: {
    fontSize: "16px",
    color: getStyleColor("--custom-color-dark-grey"),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  selectEmpty: {
    marginBottom: theme.spacing(2),
  },
  textField: { marginBottom: theme.spacing(2) },
  container: { paddingLeft: 0 },
}));

function FormChildStep3({ child, id }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function nextPage() {
    navigate(`/registration-child-step/4/${id}`);
  }

  return (
    <>
      <Grid container className={classes.root} spacing={6}>
        <Grid item xs={12} md={12}>
          <h2 className="h3" style={{ marginTop: 0 }}>
            Medical Information
          </h2>
          <p>
            <strong>
              We will be addressing your child's medical condition/s on the next page. Please provide as much
              information as possible.
            </strong>
          </p>
          <p>Where applicable, you will be asked to upload a Medical Management Plan for your child's condition/s.</p>
          <p>
            All the details that you fill will be autosaved and you can return to upload the documents when you have
            them ready.
          </p>
        </Grid>
      </Grid>
      <Box paddingBottom={8} />
      <Pager>
        <ButtonSecondary href={`/registration-child-step/2/${id}`}>
          <strong>Back</strong>
        </ButtonSecondary>
        <ButtonPrimary onClick={nextPage}>
          <strong>Next</strong>
        </ButtonPrimary>
      </Pager>
    </>
  );
}

export default FormChildStep3;
