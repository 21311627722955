import apiPayment from "./transactionQueryApi";
import { queryError, queryInit, querySaving, querySubmitted } from "./transactionQueryStateActions";

export function initQuery() {
  return function (dispatch: any) {
    dispatch(queryInit());
  };
}
export function submitQuery(
  dispatch: any,
  customerAccountId: number,
  file: File | null,
  details: string,
  reasonId: number,
  transactions: number[],
  isUpcoming: boolean,
  billingId: number
) {
  dispatch(querySaving());
  apiPayment
    .submitBillingQuery(
      customerAccountId,
      file == null ? null : [{ data: file, fileName: file.name }],
      details,
      reasonId,
      transactions.join(";"),
      isUpcoming
    )
    .then(
      (_charges: any) => {
        dispatch(querySubmitted(billingId));
      },

      function (error: any) {
        return dispatch(queryError(billingId));
      }
    )
    .finally(function () {
      setTimeout(() => {
        dispatch(initQuery());
      }, 500);
    });
}
