import React, { createRef, useEffect, useRef } from "react";

import Alert from "@mui/lab/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Form, Formik, FormikProps, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import PaymentMethodSchema from "../../../pages/PaymentMethod/PaymentMethodSchema";
import { signinSilent } from "../../../store/modules/auth/authServices";
import { complete, logRegistrationEvent } from "../../../store/modules/register/registerActions";
import { RegistrationErrorClearAction } from "../../../store/modules/register/registerStateActions";
import { RootState } from "../../../store/reducers";
import { ChildHelper } from "../../../utils/childHelper";
import { directDebitDetails } from "../../../utils/constants";
import { ContactHelper } from "../../../utils/contactHelper";
import { getStyleColor } from "../../../utils/helpers";
import { PrimaryContactHelper } from "../../../utils/primaryContactHelper";
import { ButtonPrimary, ButtonSecondary } from "../../Common/Buttons/Buttons";
import Pager from "../../Common/Pager/Pager";
import BankAccount, { IBankAccountProps, IBankAccoutRef } from "../../Payment/BankAccount";
import CreditCard, { ICardRef, ICreditCardProps } from "../../Payment/CreditCard";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    control: {
      padding: theme.spacing(2),
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    formLegend: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    alert: {
      backgroundColor: getStyleColor("--color-primary"),
      justifyContent: "center",
      color: "black",
    },
  };
});

function FormPayment() {
  const { classes } = useStyles();
  const isProcessing = useSelector((state: RootState) => state.paymentMethod?.isSaving);
  const isSubmitted = useSelector((state: RootState) => state.paymentMethod?.isSaved);
  const failed = useSelector((state: RootState) => state.paymentMethod?.isSavingError);
  const { contacts, children, isCompleted, isRegistrationError } = useSelector((state: RootState) => state.register);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cardRef = createRef<ICardRef>();
  const bankRef = createRef<IBankAccoutRef>();
  const formRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    var firstUncompletedContact = contacts.find(
      (contact) =>
        (contact.isPrimary
          ? PrimaryContactHelper.percentCompleted(contact)
          : ContactHelper.percentCompleted(contact)) !== 100
    );
    const pendingInformation = "/registration-pending-information";
    if (firstUncompletedContact) {
      navigate(pendingInformation);
    } else {
      var firstUncompletedChild = children?.find((child) => ChildHelper.percentCompleted(child) !== 100);
      if (firstUncompletedChild) {
        navigate(pendingInformation);
      }
    }
  });

  useEffect(() => {
    if (isRegistrationError) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (formRef.current !== null) {
        formRef.current.setSubmitting(false);
      }

      if (!isSubmitted) {
        dispatch(RegistrationErrorClearAction());
      }
    }
  }, [isSubmitted, isRegistrationError]);

  useEffect(() => {
    if (isSubmitted || failed) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [isSubmitted, failed]);

  useEffect(() => {
    if (isCompleted) {
      signinSilent()
        .then(() => {
          navigate("/registration-complete");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isCompleted]);

  function savePayment(values: any): void {
    logRegistrationEvent(
      {
        children,
        contacts,
      },
      "savePayment"
    );

    complete(dispatch, children, contacts, {
      paymentTypeId: values.method === "CreditCard" ? 2 : 1,
      financialInstitution: values.account.institution,
      branch: values.account.branch,
      bsb: values.account.bsb,
      accountNumber: values.account.accountNumber,
      accountName: values.account.accountName,
      holdersName: values.card.fullName,
      cardType: values.card.cardType,
      cardNumber: values.card.cardNumber,
      expireDateMonth: values.card.expiry !== null ? parseInt(values.card.expiry.substr(0, 2)) : undefined,
      expireDateYear: values.card.expiry !== null ? parseInt(values.card.expiry.substring(3, 7)) : undefined,
      acceptedTermConditions: values.acceptedTermConditions,
    });
  }

  return (
    <Formik
      innerRef={formRef}
      validationSchema={PaymentMethodSchema}
      initialValues={{
        method: "CreditCard",
        termsAccepted: false,
        card: {
          cardType: "Mastercard",
          cardNumber: "",
          fullName: "",
          expiry: "",
          path: "card.",
        } as ICreditCardProps,
        account: {
          path: "account.",
          accountName: "",
          bsb: undefined,
          accountNumber: undefined,
          isOneOffPayment: false,
        } as IBankAccountProps,
      }}
      onSubmit={(values, { setSubmitting }) => {
        var isValid = false;
        if (cardRef != null && cardRef.current) {
          isValid = cardRef.current.validate();
        }
        if (bankRef != null && bankRef.current) {
          isValid = bankRef.current.validate();
        }

        if (isValid) {
          savePayment(values);
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({ handleChange, isSubmitting, setFieldValue, values, resetForm }) => {
        return (
          <Form noValidate>
            <Grid container className={classes.root} spacing={6}>
              <Grid item xs={12} md={6}>
                <NotificationMessage />
                <legend className={classes.formLegend}>Direct debit options</legend>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="method"
                    id="method"
                    name="method"
                    value={values.method}
                    onChange={(e) => {
                      resetForm();
                      setFieldValue("method", e.target.value);
                    }}
                  >
                    <FormControlLabel value="CreditCard" control={<Radio />} label="Credit Card" />
                    <FormControlLabel value="BankAccount" control={<Radio />} label="Bank Account" />
                  </RadioGroup>
                </FormControl>
                <Box paddingBottom={4} />
                {values.method === "CreditCard" && <CreditCard ref={cardRef} {...values.card} />}
                {values.method === "BankAccount" && <BankAccount ref={bankRef} {...values.account} />}
              </Grid>
              <Grid item xs={12} md={6}>
                <legend className={classes.formLegend}>Terms and conditions</legend>
                <div dangerouslySetInnerHTML={{ __html: directDebitDetails || "" }}></div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.termsAccepted}
                      onChange={handleChange}
                      name="termsAccepted"
                      color="primary"
                    />
                  }
                  label="I agree to the terms and conditions"
                />
                <ErrorMessage name="termsAccepted">
                  {(msg) => <FormHelperText error={true}>{msg} </FormHelperText>}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Pager>
              <ButtonSecondary type="button" href="/registration-pending-information" disabled={isProcessing}>
                <strong>Back</strong>
              </ButtonSecondary>
              <ButtonPrimary disabled={isSubmitting} type="submit">
                <strong>Next</strong>
              </ButtonPrimary>
            </Pager>
            <Backdrop className={classes.backdrop} open={isSubmitting && !isCompleted}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        );
      }}
    </Formik>
  );
}

const NotificationMessage = (props: any) => {
  const { resetForm } = useFormikContext();
  const [successLocal, setSuccessLocal] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const isSubmitted = useSelector((state: RootState) => state.paymentMethod.isSaved);
  const isError = useSelector((state: RootState) => state.paymentMethod.isSavingError);
  const isRegistrationError = useSelector((state: RootState) => state.register.isRegistrationError);
  useEffect(() => {
    if (isSubmitted) {
      setSuccessLocal(isSubmitted);
      resetForm({});
      setTimeout(() => {
        props.reset();
      }, 5000);
    }
  }, [isSubmitted]);
  useEffect(() => {
    if (isError) {
      setFailed(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isRegistrationError) {
      setFailed(isRegistrationError);
    }
  }, [isRegistrationError]);

  return successLocal ? (
    <Alert severity="success">Your payment method has been saved.</Alert>
  ) : failed ? (
    <Alert severity="error">An error ocurred while trying complete registration. Please try again.</Alert>
  ) : (
    <div></div>
  );
};

export default FormPayment;
