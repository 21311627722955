import { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { IoCalendarOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import EventSelection from "../../components/Forms/Events/EventSelection";
import { CardLayout } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import { getChildren } from "../../store/modules/child/childActions";
import { getUpcomingEventBookings, getUpcomingEvents } from "../../store/modules/events/eventsActions";
import { RootState } from "../../store/reducers";
import { Child, SelectedBookingEvent } from "../../types/models";
import { getStyleColor } from "../../utils/helpers";
import { GetPageState } from "../../utils/pageUtil";

export interface EventBookingDetails {
  eventBookings: SelectedBookingEvent[];
}

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
}));

export default function NewEventBooking() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const state = GetPageState<EventBookingDetails>();
  const customer_account_id = useSelector((state: RootState) => state.auth?.user?.profile?.customer_account_id);
  const customerChildren = useSelector((state: RootState) => state.child?.childrenByCustomer);
  const isLoadingChildren = useSelector((state: RootState) => state.child?.isLoadingChildren);
  const events = useSelector((state: RootState) => state.bookingEvents?.bookingEvents);
  const isLoadingEvents = useSelector((state: RootState) => state.bookingEvents?.isLoading);
  const isErrorState = useSelector((state: RootState) => state.bookingEvents?.isErrorState);
  const [initialValues, setInitialValues] = useState<SelectedBookingEvent[]>(
    events?.map(
      (e) =>
        new SelectedBookingEvent({
          bookingEvent: e,
          addChildren: new Array<Child>(),
          removeChildren: new Array<Child>(),
          bookedChildren: new Array<Child>(),
        })
    ) ?? []
  );

  useEffect(() => {
    if (customer_account_id) {
      if (customerChildren === null) {
        getChildren(dispatch, customer_account_id);
      }
      getUpcomingEvents(dispatch);
    }
  }, []);

  useEffect(() => {
    if (events && events.length) {
      getUpcomingEventBookings(customer_account_id!).then((response) => {
        if (response) {
          setInitialValues(
            events?.map((e) => {
              var ext = state?.eventBookings?.find((b) => b.bookingEvent.onlineSessionId === e.onlineSessionId);
              return new SelectedBookingEvent({
                bookingEvent: e,
                addChildren: ext?.addChildren ?? new Array<Child>(),
                removeChildren: ext?.removeChildren ?? new Array<Child>(),
                bookedChildren:
                  customerChildren?.filter(
                    (c) =>
                      c !== undefined &&
                      response?.findIndex(
                        (cb) =>
                          cb !== undefined &&
                          !cb.isDeleted &&
                          cb.childId === c.childId &&
                          cb.onlineSessionId === e.onlineSessionId
                      ) > -1
                  ) ?? [],
              });
            }) ?? []
          );
        }
      });
    }
  }, [events]);

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-accent-5")}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h1 className="h3">Events</h1>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end"></Grid>
          </Grid>
        </Grid>
      </CardLayout.Header>
      <CardLayout.Body>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {isErrorState ? (
              <Grid container alignItems="center" justifyContent="center" alignContent="center" direction="column">
                <Box paddingTop={3} />
                <IoCalendarOutline size={60} color="gray" />
                <p className="color-dark-grey">No events found</p>
              </Grid>
            ) : (
              initialValues?.map((ev, index) => (
                <EventSelection
                  bookingEvent={ev}
                  allChildren={customerChildren ?? []}
                  onAddChildrenChanged={(bookingEvent, children) => {
                    initialValues[index].addChildren = children;
                    setInitialValues(initialValues.splice(0));
                  }}
                  onRemoveChildrenChanged={(bookingEvent, children) => {
                    initialValues[index].removeChildren = children;
                    setInitialValues(initialValues.splice(0));
                  }}
                  key={index}
                />
              ))
            )}
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={isLoadingChildren || isLoadingEvents}>
          <CircularProgress color="primary" />
        </Backdrop>
      </CardLayout.Body>
      <CardLayout.Footer>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <ButtonSecondary
                onClick={() => {
                  navigate("/");
                }}
              >
                <strong>Back</strong>
              </ButtonSecondary>
            </Grid>
            {!isErrorState && !isLoadingEvents && (
              <Grid item>
                <ButtonPrimary
                  style={{ backgroundColor: getStyleColor("--color-accent-5") }}
                  disabled={CannotGoNext(initialValues)}
                  onClick={() => {
                    navigate("/event-booking-confirm", {
                      state: JSON.stringify({
                        eventBookings: initialValues,
                      }),
                    });
                  }}
                >
                  <strong>Next</strong>
                </ButtonPrimary>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardLayout.Footer>
    </CardLayout>
  );
}

function CannotGoNext(values: SelectedBookingEvent[]) {
  return (
    values.filter((e) => e.addChildren.length > 0).length === 0 &&
    values.filter((e) => e.removeChildren.length > 0).length === 0
  );
}
