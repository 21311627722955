import { Dayjs } from "dayjs";
import { createActionCreator } from "deox";
export interface IPaymentError {
  title: string;
  message: string;
}
export const paymentProcessing = createActionCreator("paymentProcessing");
export const paymentSuccess = createActionCreator("paymentSuccess", (resolve) => (title: string) => resolve(title));

export const paymentError = createActionCreator("paymentError", (resolve) => (error: IPaymentError) => resolve(error));
export const paymentInit = createActionCreator("paymentInit");

export const dueDatesLoading = createActionCreator("dueDatesLoading");
export const dueDatesLoaded = createActionCreator(
  "dueDatesLoaded",
  (resolve) => (dates: Dayjs[], year: number) => resolve({ dates, year })
);
export const dueDatesError = createActionCreator("dueDatesError");
