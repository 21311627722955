import dayjs from "dayjs";
import { List } from "linqts";

import { hasMinRequiredBookingsAcrossWeeks } from "./helpers";
import { OshcBookingDetails } from "../types/models";
import { BookingStatus3, IGetChangeRecurringBookingPreviewResponse, ISessionAvailability } from "../types/types";

export function getMatchingUnAvailableDates(
  state: OshcBookingDetails,
  unAvailableDates: ISessionAvailability[] | null
) {
  var dates = new Array<ISessionAvailability>();
  if (!unAvailableDates) {
    return dates;
  }
  if (state.isRecurring) {
    var recDays = new List(state.recurringDays);
    dates = new List(unAvailableDates)
      .Where(
        (rd) =>
          rd !== undefined &&
          !rd.isAvailableForRecurringBooking &&
          rd.date.isBetween(
            state.recurringStartDate as dayjs.Dayjs,
            state.recurringEndDate as dayjs.Dayjs,
            null,
            "[]"
          ) &&
          recDays.Any(
            (sess) =>
              sess !== undefined &&
              sess.roll.rollId === rd.rollId &&
              ((sess.isMonday && rd.date.isoWeekday() === 1) ||
                (sess.isTuesday && rd.date.isoWeekday() === 2) ||
                (sess.isWednesday && rd.date.isoWeekday() === 3) ||
                (sess.isThursday && rd.date.isoWeekday() === 4) ||
                (sess.isFriday && rd.date.isoWeekday() === 5))
          )
      )
      .ToArray();
  } else {
    var casualDates = new List(state.casualDates);
    dates = new List(unAvailableDates)
      .Where(
        (ud) =>
          ud !== undefined &&
          !ud.isAvailableForCasualBooking &&
          casualDates.Any((cd) => cd !== undefined && cd.roll.rollId === ud.rollId && cd.date.isSame(ud.date, "date"))
      )
      .ToArray();
  }

  return dates;
}

export function HasMinimumBookingsAcrossWeeks(
  isEditMode: boolean,

  previewResult: IGetChangeRecurringBookingPreviewResponse | null,
  unAvailableDates: ISessionAvailability[],
  formState: OshcBookingDetails
): boolean {
  var hasMinBookings = true;
  if (isEditMode) {
    var editBookingsCount =
      previewResult?.bookings.filter(
        (p) => p.status !== BookingStatus3.CancelledInNoticePeriod && p.status !== BookingStatus3.CancelledInTime
      ).length ?? 0;

    hasMinBookings =
      editBookingsCount + (previewResult?.chargedBookingsCount ?? 0) >= (previewResult?.minBookingsRequired ?? 0);
  } else {
    for (let d of formState.recurringDays) {
      if (
        !hasMinRequiredBookingsAcrossWeeks(
          formState.recurringStartDate ?? dayjs(),
          formState.recurringEndDate ?? dayjs(),
          d,
          unAvailableDates,
          formState.minBookings
        )
      ) {
        hasMinBookings = false;
        break;
      }
    }
  }

  return hasMinBookings;
}
