import { createActionCreator } from "deox";

import { IAppConfig } from "../../../types/types";

export const appConfigLoading = createActionCreator("appConfigLoading");
export const appConfigLoaded = createActionCreator("appConfigLoaded");

export const appConfigError = createActionCreator("appConfigError");

export const appConfigSuccess = createActionCreator(
  "appConfigSuccess",
  (resolve) => (configs: IAppConfig[]) => resolve(configs)
);
