import { Dayjs } from "dayjs";
import { createActionCreator } from "deox";

import { BookingInfo, Child, ServiceProgram } from "../../../types/models";
import {
  BookingPreview,
  IBookingCancellationReasonModel,
  IChild,
  IErrorResponse,
  IGetChangeRecurringBookingPreviewResponse,
  IServiceProgram,
} from "../../../types/types";

export const LoadedBookingsError = createActionCreator("LoadedBookingsError");
export const LoadingBookings = createActionCreator(
  "LoadingBookings",
  (resolve) => (from: Dayjs, to: Dayjs) => resolve({ to, from })
);
export const LoadedBookings = createActionCreator(
  "LoadedBookings",
  (resolve) => (from: Dayjs, to: Dayjs, bookings: BookingInfo[]) => resolve({ to, from, bookings })
);

export const LoadedSavedBookings = createActionCreator(
  "LoadedSavedBookings",
  (resolve) => (bookings: BookingInfo[]) => resolve({ bookings })
);

export const LoadCreateRecurringPreviewInitial = createActionCreator("LoadCreateRecurringPreviewInitial");
export const LoadCreateRecurringPreviewError = createActionCreator("LoadCreateRecurringPreviewError");
export const LoadingCreateRecurringPreview = createActionCreator("LoadingCreateRecurringPreview");

export const LoadCreateRecurringPreview = createActionCreator(
  "LoadCreateRecurringPreview",
  (resolve) => (bookingsPreview: BookingPreview[]) => resolve(bookingsPreview)
);

export const LoadSaveRecurringPreviewInitial = createActionCreator("LoadSaveRecurringPreviewInitial");
export const LoadSaveRecurringPreviewError = createActionCreator("LoadSaveRecurringPreviewError");
export const LoadingSaveRecurringPreview = createActionCreator("LoadingSaveRecurringPreview");

export const LoadSaveRecurringPreview = createActionCreator(
  "LoadSaveRecurringPreview",
  (resolve) => (bookingsPreview: IGetChangeRecurringBookingPreviewResponse) => resolve(bookingsPreview)
);

export const SaveBookingInitial = createActionCreator("SaveBookingInitial");
export const SaveBookingLoading = createActionCreator("SaveBookingLoading");
export const SaveBookingSuccess = createActionCreator(
  "SaveBookingSuccess",
  (resolve) => (reference: string, isVac: boolean) => resolve({ reference, isVac })
);
export const SaveVacBookingComplete = createActionCreator(
  "SaveVacBookingComplete",
  (resolve) =>
    (reference: string, programs: IServiceProgram[], children: IChild[], isVac: boolean = true) =>
      resolve({ reference, programs, children, isVac })
);
export const SaveBookingError = createActionCreator(
  "SaveBookingError",
  (resolve) => (errorMessage: string) => resolve(errorMessage)
);
export const SaveBookingFailed = createActionCreator(
  "SaveBookingFailed",
  (resolve) => (conflictError: IErrorResponse) => resolve(conflictError)
);

export const CancelBookingInitial = createActionCreator("CancelBookingInitial");
export const CancelBookingLoading = createActionCreator("CancelBookingLoading");
export const CancelBookingSuccess = createActionCreator("CancelBookingSuccess");
export const CancelBookingError = createActionCreator("CancelBookingError");

export const BookingCancellationReasonsInitial = createActionCreator("BookingCancellationReasonsInitial");
export const BookingCancellationReasonsLoading = createActionCreator("BookingCancellationReasonsLoading");
export const BookingCancellationReasonsSuccess = createActionCreator(
  "BookingCancellationReasonsSuccess",
  (resolve) => (reasons: IBookingCancellationReasonModel[]) => resolve(reasons)
);
export const BookingCancellationReasonsError = createActionCreator("BookingCancellationReasonsError");

export const LoadCreateCasualBookingsPreviewInitial = createActionCreator("LoadCreateCasualBookingsPreviewInitial");
export const LoadCreateCasualBookingsPreviewError = createActionCreator("LoadCreateCasualBookingsPreviewError");
export const LoadingCreateCasualBookingsPreview = createActionCreator("LoadingCreateCasualBookingsPreview");

export const LoadCreateCasualBookingsPreview = createActionCreator(
  "LoadCreateCasualBookingsPreview",
  (resolve) => (bookingsPreview: BookingPreview[]) => resolve(bookingsPreview)
);

export const ServiceProgramsSelectForBookings = createActionCreator(
  "ServiceProgramsSelectForBookings",
  (resolve) => (servicePrograms: ServiceProgram[]) => resolve(servicePrograms)
);

export const BookingScheduleCancelled = createActionCreator(
  "BookingScheduleCancelled",
  (resolve) => (scheduleId: string) => resolve(scheduleId)
);

export const BookingIndividualCancelled = createActionCreator(
  "BookingIndividualCancelled",
  (resolve) => (bookingId: string) => resolve(bookingId)
);

export const RecurrenceChange = createActionCreator(
  "RecurrenceChange",
  (resolve) => (isCasual:boolean) => resolve(isCasual)
)
