import { ChangeEventHandler } from "react";

import { makeStyles } from "tss-react/mui";

import styles from "./ToggleSwitch.module.scss";

interface Props {
  checked?: boolean;
  id?: string;
  width?: number | string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onText?: string;
  offText?: string;
}

function ToggleSwitch(props: Props) {
  const useStyles = makeStyles()((theme) => {
    return {
      primary: {
        width: props.width ?? 250,
      },
    };
  });
  const { classes } = useStyles();

  return (
    <label className={`${styles["ToggleSwitch"]} ${classes.primary}`} id={props.id}>
      <input type="checkbox" checked={props.checked} onChange={props.onChange} />
      <span className={styles["ToggleSwitch-switch"]} />
      <span className={styles["ToggleSwitch-on"]}>{props.onText ?? "ON"}</span>
      <span className={styles["ToggleSwitch-off"]}>{props.offText ?? "OFF"}</span>
    </label>
  );
}
export default ToggleSwitch;
