import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { List } from "linqts";
import { IoCheckmarkCircle, IoEllipseOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

import theme from "../../../theme";
import { EndDateOptions, SelectedRollDay, WeekDaysOptions } from "../../../types/models";
import { ISessionAvailability, Roll } from "../../../types/types";
import { hasMinRequiredBookingsAcrossWeeks } from "../../../utils/helpers";
import { useViewport } from "../../../utils/hooks";

interface Props {
  rolls: Roll[];
  unAvailableDates: ISessionAvailability[];
  rollDays: List<SelectedRollDay>;
  endDateSelectionType: EndDateOptions;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs | null;
  onEndDateSelectionTypeChanged?: (type: EndDateOptions) => void;
  onStartDateChanged?: (startDate: dayjs.Dayjs) => void;
  onEndDateChanged?: (endDate: dayjs.Dayjs | null) => void;
  onSelectedDayChanged?: (rollDay: Roll, day: WeekDaysOptions, isChecked: boolean) => void;
  minNumberOfBookings: number;
}

const RollDaySelection = (props: Props) => {
  const { width } = useViewport();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RadioGroup
          row={true}
          value={props.endDateSelectionType}
          onChange={(_ev, val) =>
            props.onEndDateSelectionTypeChanged ? props.onEndDateSelectionTypeChanged(val as EndDateOptions) : {}
          }
        >
          <FormControlLabel value="TermEnd" label="Term End" control={<Radio />} />
          <FormControlLabel value="YearEnd" label="Year End" control={<Radio />} />
          <FormControlLabel value="Custom" label="Custom" control={<Radio />} />
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignContent="space-between" alignItems="center" justifyContent="space-between">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              format="DD/MM/YYYY"
              label="Start Date"
              value={props.startDate}
              minDate={dayjs(dayjs().format("YYYY-MM-DD"))}
              onChange={(date) =>
                props.onStartDateChanged ? props.onStartDateChanged(date?.clone() ?? props.startDate) : {}
              }
              slotProps={{
                textField: {
                  id: "dpStartDate",
                  "aria-label": "change start date",
                },
              }}
            />
          </LocalizationProvider>
          {/* <KeyboardDatePicker
            disableToolbar
            format="DD/MM/YYYY"
            margin="normal"
            id="dpStartDate"
            label="Start Date"
            value={props.startDate}
            minDate={dayjs(dayjs().format("YYYY-MM-DD"))}
            onChange={(date) =>
              props.onStartDateChanged ? props.onStartDateChanged(date?.clone() ?? props.startDate) : {}
            }
            KeyboardButtonProps={{
              "aria-label": "change start date",
            }}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disabled={props.endDateSelectionType !== "Custom"}
              format="DD/MM/YYYY"
              label="End Date"
              minDate={props.startDate.add(1, "d")}
              value={props.endDate}
              // minDateMessage="End date should not be before Start date"
              onChange={(date) => (props.onEndDateChanged ? props.onEndDateChanged(date?.clone() ?? null) : {})}
              slotProps={{
                textField: {
                  id: "dpEndDate",
                  "aria-label": "change end date",
                },
              }}
            />
          </LocalizationProvider>
          {/* <KeyboardDatePicker
            disableToolbar
            disabled={props.endDateSelectionType !== "Custom"}
            format="DD/MM/YYYY"
            margin="normal"
            id="dpEndDate"
            label="End Date"
            minDate={props.startDate.add(1, "d")}
            value={props.endDate}
            minDateMessage="End date should not be before Start date"
            onChange={(date) => (props.onEndDateChanged ? props.onEndDateChanged(date?.clone() ?? null) : {})}
            KeyboardButtonProps={{
              "aria-label": "change end date",
            }}
          /> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <strong>Schedule</strong>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs>
                <Grid container alignItems="center" justifyContent="center">
                  Mon
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" justifyContent="center">
                  Tue
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" justifyContent="center">
                  Wed
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" justifyContent="center">
                  Thu
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" justifyContent="center">
                  Fri
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {props.rolls.map((roll) => {
        var rDays = props.rollDays.FirstOrDefault((r) => r?.roll.rollId === roll.rollId);
        var isMondayChecked = rDays?.isMonday === true;
        var isTuesdayChecked = rDays?.isTuesday === true;
        var isWednesdayChecked = rDays?.isWednesday === true;
        var isThursdayChecked = rDays?.isThursday === true;
        var isFridayChecked = rDays?.isFriday === true;
        var hasMinBookings = true;
        if (
          props.endDate &&
          rDays &&
          (isMondayChecked || isTuesdayChecked || isWednesdayChecked || isThursdayChecked || isFridayChecked)
        ) {
          hasMinBookings = hasMinRequiredBookingsAcrossWeeks(
            props.startDate,
            props.endDate,
            rDays,
            props.unAvailableDates,
            props.minNumberOfBookings
          );
        }
        return (
          <Grid item xs={12} key={"roll_day_" + roll.rollId}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <div>{roll.rollName}</div>
                {width >= 480 && (
                  <div>
                    {dayjs(roll.startTime).format("hh:mm a")} - {dayjs(roll.endTime).format("hh:mm a")}
                  </div>
                )}
              </Grid>
              <Grid item xs={9}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs>
                    <Grid container alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={isMondayChecked}
                        icon={<IoEllipseOutline size={32} color={theme.palette.secondary.main} />}
                        checkedIcon={<IoCheckmarkCircle size={32} />}
                        onChange={(_ev, chk) =>
                          props.onSelectedDayChanged ? props.onSelectedDayChanged(roll, "Monday", chk) : {}
                        }
                        style={{ padding: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={isTuesdayChecked}
                        icon={<IoEllipseOutline size={32} color={theme.palette.secondary.main} />}
                        checkedIcon={<IoCheckmarkCircle size={32} />}
                        onChange={(_ev, chk) =>
                          props.onSelectedDayChanged ? props.onSelectedDayChanged(roll, "Tuesday", chk) : {}
                        }
                        style={{ padding: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={isWednesdayChecked}
                        icon={<IoEllipseOutline size={32} color={theme.palette.secondary.main} />}
                        checkedIcon={<IoCheckmarkCircle size={32} />}
                        onChange={(_ev, chk) =>
                          props.onSelectedDayChanged ? props.onSelectedDayChanged(roll, "Wednesday", chk) : {}
                        }
                        style={{ padding: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={isThursdayChecked}
                        icon={<IoEllipseOutline size={32} color={theme.palette.secondary.main} />}
                        checkedIcon={<IoCheckmarkCircle size={32} />}
                        onChange={(_ev, chk) =>
                          props.onSelectedDayChanged ? props.onSelectedDayChanged(roll, "Thursday", chk) : {}
                        }
                        style={{ padding: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignItems="center" justifyContent="center">
                      <Checkbox
                        checked={isFridayChecked}
                        icon={<IoEllipseOutline size={32} color={theme.palette.secondary.main} />}
                        checkedIcon={<IoCheckmarkCircle size={32} />}
                        onChange={(_ev, chk) =>
                          props.onSelectedDayChanged ? props.onSelectedDayChanged(roll, "Friday", chk) : {}
                        }
                        style={{ padding: 0 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!hasMinBookings && (
                <Grid item xs={12}>
                  <label style={{ color: theme.palette.error.main }}>
                    {`You must make a minimum of ${props.minNumberOfBookings} bookings, recurring on the same days over ${props.minNumberOfBookings} or more weeks.`}
                  </label>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <strong>Pricing</strong>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          {props.rolls?.map((roll, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item xs={12} md>
                    <strong className="small">{roll.rollName}</strong>
                  </Grid>
                  <Grid item xs={12} md>
                    <span className="small">
                      <NumericFormat
                        displayType="text"
                        prefix="$"
                        value={roll.primaryFeeAmount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        suffix="/ Session"
                      />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span className="small">* Prices exclude available Child Care Subsidy (CCS)</span>
      </Grid>
    </Grid>
  );
};
export default RollDaySelection;
