import { createReducer } from "deox";

import { BookingPreview } from "../../../types/types";
import {
  LoadCreateCasualBookingsPreview,
  LoadCreateCasualBookingsPreviewError,
  LoadCreateCasualBookingsPreviewInitial,
  LoadingCreateCasualBookingsPreview,
} from "./bookingStateActions";

export interface BookingPreviewState {
  previewResult: BookingPreview[] | null;
  isErrorState: boolean;
  isInitialised: boolean;
  isLoading: boolean;
}

export const initialState: BookingPreviewState = {
  previewResult: null,
  isErrorState: false,
  isInitialised: false,
  isLoading: false,
};

const bookingSaveCasualPreviewReducer = createReducer(initialState, (handleAction) => [
  handleAction(LoadCreateCasualBookingsPreview, (state, { payload }) => ({
    ...state,
    previewResult: payload,
    isErrorState: false,
    isInitialised: true,
    isLoading: false,
  })),
  handleAction(LoadCreateCasualBookingsPreviewError, (state) => ({
    ...state,
    previewResult: null,
    isErrorState: true,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadCreateCasualBookingsPreviewInitial, (state) => ({
    ...state,
    previewResult: null,
    isErrorState: false,
    isInitialised: false,
    isLoading: false,
  })),
  handleAction(LoadingCreateCasualBookingsPreview, (state) => ({
    ...state,
    isErrorState: false,
    isInitialised: false,
    isLoading: true,
  })),
]);

export default bookingSaveCasualPreviewReducer;
