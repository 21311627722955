import { Typography } from "@mui/material";

interface IProps {
  isHotCancellation: boolean;
  days: number;
  fontSize?: number;
}
export default function CancellationNote(props: IProps) {
  const { isHotCancellation, days, fontSize = 10 } = props;
  return (
    <Typography variant="caption" fontSize={fontSize}>
      *
      {isHotCancellation
        ? "We noticed you only just made this booking, so there will be no charge for the cancellation."
        : "If a booking is not cancelled with at least " +
          (days > 2 ? days : days * 24) +
          " " +
          (days > 2 ? "days" : "hours") +
          "’ notice, the full session fee will be charged. \r\n"}
      {!isHotCancellation && <br />}
      {!isHotCancellation && "(Less any applicable Child Care Subsidy)"}
    </Typography>
  );
}
