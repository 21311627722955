import { Dayjs } from "dayjs";
import { createReducer } from "deox";

import { NextAvailableSessionDateError, NextAvailableSessionDateLoaded } from "./sessionStateAction";

export interface INextAvailableSessionState {
  date: Dayjs | null;
}

export const initialState: INextAvailableSessionState = {
  date: null,
};

const nextAvailableSessionReducer = createReducer(initialState, (handleAction) => [
  handleAction(NextAvailableSessionDateLoaded, (state, { payload }) => ({
    ...state,
    date: payload,
  })),
  handleAction(NextAvailableSessionDateError, (state) => ({
    ...state,
    date: null,
  })),
]);

export default nextAvailableSessionReducer;
