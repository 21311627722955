import React from "react";

import styles from "./ConfirmEmail.module.scss";
import FormConfirmEmail from "../../components/Forms/ConfirmEmail/ConfirmEmail";
import { LayoutInitialPages } from "../../layouts/Layouts";

function ConfirmEmail() {
  return (
    <LayoutInitialPages>
      <div className={styles.Home}>
        <FormConfirmEmail />
      </div>
    </LayoutInitialPages>
  );
}

export default ConfirmEmail;
