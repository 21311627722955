import { createReducer } from "deox";

import { IAppConfig } from "../../../types/types";
import { purge } from "../purge/purgeStateActions";
import { appConfigError, appConfigLoading, appConfigSuccess } from "./appConfigsStateActions";

let _defaultSurcharge = 0.635;

export interface AppConfigState {
  payload: IAppConfig[];
  isError: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  creditCardSurcharge: number | null;
}

export const initialState: AppConfigState = {
  payload: [],
  isError: false,
  isLoading: false,
  isLoaded: false,
  creditCardSurcharge: _defaultSurcharge,
};

function getcreditCardSurcharge(payload: IAppConfig[]) {
  const config = payload.find((x) => x.name === "creditCardSurcharge");
  if (config && config.value) {
    return parseFloat(config.value);
  }
  return _defaultSurcharge;
}

const appConfigsReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    appConfigSuccess,
    (state, action): AppConfigState => ({
      ...state,
      isLoading: false,
      isError: false,
      isLoaded: true,
      payload: action.payload,
      creditCardSurcharge: getcreditCardSurcharge(action.payload),
    })
  ),
  handleAction(
    appConfigLoading,
    (state): AppConfigState => ({
      ...state,
      isLoading: true,
      isError: false,
      isLoaded: false,
      creditCardSurcharge: null,
    })
  ),
  handleAction(
    appConfigError,
    (state): AppConfigState => ({
      ...state,
      isError: true,
      isLoading: false,
      isLoaded: false,
      payload: [],
      creditCardSurcharge: null,
    })
  ),
  handleAction(purge, () => initialState),
]);

export default appConfigsReducer;
