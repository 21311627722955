import { createReducer } from "deox";

import { IProgramVersionSummaryModel, IServiceProgramSummaryModel } from "../../../types/types";
import { digitalProgramsByServicesError, digitalProgramsByServicesLoading, digitalProgramsByServicesSuccess } from "./digitalProgramStateAction";


export interface IDigitalProgramState {
  serviceProgramSummaryState: IProgramsByServiceState;
}

interface IProgramsByServiceState {
  programsByService: IProgramVersionSummaryModel[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

export const initialState: IDigitalProgramState = {
  serviceProgramSummaryState: {isError: false, isLoading: false, programsByService: undefined},
};

const digitalProgramReducer = createReducer(initialState, (handleAction) => [
  handleAction(
    digitalProgramsByServicesLoading,
    (state): IDigitalProgramState => ({
      ...state,
     serviceProgramSummaryState: {
        ...state.serviceProgramSummaryState,
        isError: false,
        isLoading: true,
        programsByService: undefined,
      },
    })
  ),

  handleAction(
    digitalProgramsByServicesError,
    (state): IDigitalProgramState => ({
      ...state,
      serviceProgramSummaryState: {
        ...state.serviceProgramSummaryState,
        isLoading: false,
        programsByService: undefined,
        isError: true,
      },
    })
  ),
  handleAction(digitalProgramsByServicesSuccess, (state: IDigitalProgramState, { payload }) => ({
    ...state,
    serviceProgramSummaryState: {
      ...state.serviceProgramSummaryState,
      isLoading: false,
      isError: false,
      programsByService: payload,
    },
  })),
]);

export default digitalProgramReducer;
