import React, { useEffect } from "react";

import Alert from "@mui/lab/Alert";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

import NotificationListing from "../../components/Notifications/Notification";
import { CardLayout } from "../../layouts/Layouts";
import { useAppDispatch } from "../../store/hooks";
import { getNotificationSettings } from "../../store/modules/notification/notificationActions";
import { RootState } from "../../store/store";
import { NotificationChannel } from "../../types/types";
import { getStyleColor } from "../../utils/helpers";

export default function Notifications() {
  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const settings = useSelector((state: RootState) => state.notificationSettings.payload);
  const loadStatus = useSelector((state: RootState) => state.notificationSettings.status);
  const [localStatus, setLocalStatus] = React.useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (customer_account_id) {
      getNotificationSettings(dispatch, customer_account_id);
    }
  }, [customer_account_id]);

  useEffect(() => {
    if (loadStatus === "Errored") {
      setLocalStatus("Errored");
    }
  }, [loadStatus]);

  return (
    <CardLayout>
      <CardLayout.Header bgColor={getStyleColor("--color-primary-40")}>
        <h1 className="h2">Notifications</h1>
      </CardLayout.Header>
      <CardLayout.Body>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={3}>
              {localStatus === "Errored" && (
                <Alert severity="error">An error has been encountered. Please try again later</Alert>
              )}
              <Grid item xs={12}>
                <h2 className="h6" style={{ marginTop: 0 }}>
                  Email
                </h2>
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <NotificationListing
                  bookingOffers={(settings && settings.emailBookingOffers) ?? false}
                  general={(settings && settings.emailGeneral) ?? false}
                  holidayClubMarketing={(settings && settings.emailHolidayClubMarketing) ?? false}
                  inSessionActivities={(settings && settings.emailInSessionActivities) ?? false}
                  industryThoughts={(settings && settings.emailIndustryThoughts) ?? false}
                  channel={NotificationChannel.Email}
                ></NotificationListing>
              </Grid>
              <Grid item xs={12}>
                <h2 className="h6">SMS</h2>
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <NotificationListing
                  bookingOffers={(settings && settings.smsBookingOffers) ?? false}
                  general={(settings && settings.smsGeneral) ?? false}
                  holidayClubMarketing={(settings && settings.smsHolidayClubMarketing) ?? false}
                  inSessionActivities={(settings && settings.smsInSessionActivities) ?? false}
                  industryThoughts={(settings && settings.smsIndustryThoughts) ?? false}
                  channel={NotificationChannel.Sms}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardLayout.Body>
    </CardLayout>
  );
}
