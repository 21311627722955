import Done from "@mui/icons-material/Done";
import Info from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { RootState } from "../../../store/reducers";
import { EntityType } from "../../../types/models";
import { Contact, IChild } from "../../../types/types";
import { ChildHelper } from "../../../utils/childHelper";
import { ContactHelper } from "../../../utils/contactHelper";
import { getStyleColor } from "../../../utils/helpers";
import { PrimaryContactHelper } from "../../../utils/primaryContactHelper";
import { AvatarDisplay } from "../Controls/AvatarDisplay";
import styles from "./Timelines.module.scss";

interface RegistrationTimelineProps {
  contacts: Contact[];
  children: IChild[];
}

const useStyles = makeStyles()((theme) => ({
  progress: {
    position: "relative",
    width: 48,
    height: 48,
  },
  circularProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    color: getStyleColor("--color-primary"),
  },
  avatarDisplay: {
    position: "absolute",
    top: 3,
    left: 3,
  },
  icon: {
    color: getStyleColor("--color-primary"),
  },
}));

export const RegistrationTimeline = (props: RegistrationTimelineProps) => {
  const { children, contacts } = props;
  const { classes } = useStyles();
  const avatars = useSelector((state: RootState) => state.avatars.avatars);

  const AvatarWithProgress = (progress: number, avatar: any) => (
    <div className={classes.progress}>
      <CircularProgress
        variant="determinate"
        value={progress}
        className={classes.circularProgress}
        size={50}
        thickness={3}
      />
      <div className={classes.avatarDisplay}>{avatar}</div>
    </div>
  );

  return (
    <div className={styles["RegistrationTimeline"]}>
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item>
          <Link to={`/registration-add-contacts`}>
            {AvatarWithProgress(100, <AvatarDisplay icon={<Info style={{ color: "black" }} />} />)}
          </Link>
        </Grid>
        <Grid item>
          <Box paddingRight={2} />
        </Grid>
        <Grid item xs={9} md={4}>
          <Link to={`/registration-add-contacts`} className={styles.name}>
            Primary Information
          </Link>
        </Grid>
        <Grid item>
          <Done color="primary" className={classes.icon} />
        </Grid>
      </Grid>
      {contacts.length &&
        contacts.map((contact: Contact, index: number) => {
          const contactAvatar = avatars?.find(
            (x) => x.entityType === EntityType.RegistrationContact && x.entityId === contact.uniqueId
          );

          const percentCompleted = contact.isPrimary
            ? PrimaryContactHelper.percentCompleted(contact)
            : ContactHelper.percentCompleted(contact);
          const firstUncompletedStep =
            (contact.isPrimary
              ? PrimaryContactHelper.getFirstUncompletedStep(contact, index)
              : ContactHelper.getFirstUncompletedStep(contact, index)) ?? `/registration-contact-step/1/${index}`;
          return (
            <Grid key={contact.uniqueId}>
              <Box paddingTop={4} />
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item>
                  <Link to={firstUncompletedStep}>
                    {AvatarWithProgress(
                      percentCompleted,
                      <AvatarDisplay info={contactAvatar || { index: contact.avatarIndex }} />
                    )}
                  </Link>
                </Grid>
                <Grid item>
                  <Box paddingRight={2} />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Link to={firstUncompletedStep} className={styles.name}>
                    {contact.firstName} {contact.lastName}
                  </Link>
                  <div className={styles.role}>
                    {index === 0 ? "Primary Account" : index === 1 ? "Secondary Account" : `Contact ${index - 1}`}
                  </div>
                </Grid>
                <Grid item>{percentCompleted === 100 && <Done color="primary" className={classes.icon} />}</Grid>
              </Grid>
            </Grid>
          );
        })}
      {children.length > 0 &&
        children.map((child: IChild, index: number) => {
          const childAvatar = avatars?.find(
            (x) => x.entityType === EntityType.RegistrationChild && x.entityId === child.uniqueId
          );
          const percentCompleted = ChildHelper.percentCompleted(child);
          const firstUncompletedStep =
            ChildHelper.getFirstUncompletedStep(child, index) ?? `/registration-child-step/1/${index}`;
          return (
            <Grid key={child.uniqueId}>
              <Box paddingTop={4} />
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item>
                  <Link to={firstUncompletedStep}>
                    {AvatarWithProgress(
                      percentCompleted,
                      <AvatarDisplay info={childAvatar || { index: child.avatarIndex }} />
                    )}
                  </Link>
                </Grid>
                <Grid item>
                  <Box paddingRight={2} />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Link to={firstUncompletedStep} className={styles.name}>
                    {child.firstName} {child.lastName}
                  </Link>
                  <div className={styles.role}>{`Child ${index + 1}`}</div>
                </Grid>
                <Grid item>{percentCompleted === 100 && <Done color="primary" className={classes.icon} />}</Grid>
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
};
