import { createReducer } from "deox";

import { IErrorResponse } from "../../../types/types";
import {
  SaveBookingError,
  SaveBookingFailed,
  SaveBookingInitial,
  SaveBookingLoading,
  SaveBookingSuccess,
} from "./bookingStateActions";

export interface SaveBookingState {
  errorResponse: IErrorResponse | null;
  isErrorState: boolean;
  isSaving: boolean;
  isSuccess: boolean;
}

export const InitialState: SaveBookingState = {
  errorResponse: null,
  isErrorState: false,
  isSaving: false,
  isSuccess: false,
};

const bookingSaveRecurringReducer = createReducer(InitialState, (handleAction) => [
  handleAction(SaveBookingSuccess, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: true,
  })),
  handleAction(SaveBookingLoading, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: true,
    isSuccess: false,
  })),
  handleAction(SaveBookingError, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
  })),
  handleAction(SaveBookingFailed, (state, { payload }) => ({
    ...state,
    errorResponse: payload,
    isErrorState: true,
    isSaving: false,
    isSuccess: false,
  })),
  handleAction(SaveBookingInitial, (state) => ({
    ...state,
    errorResponse: null,
    isErrorState: false,
    isSaving: false,
    isSuccess: false,
  })),
]);

export default bookingSaveRecurringReducer;
