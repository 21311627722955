import { useCallback } from "react";

import { Alert, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import styles from "./CheckEmail.module.scss";
import emailSvg from "../../../assets/vectors/email.svg";
import { ButtonPrimary } from "../../Common/Buttons/Buttons";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  headline: {
    fontfamily: "ABC Whyte, Medium",
    fontWeight: "bold",
    fontSize: 23,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 21,
    },
  },
}));

function FormCheckEmail() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/account/forgot-password"), [history]);
  return (
    <Box
      id="topHome"
      className={styles["Home"]}
      sx={{
        borderRadius: 5,
        maxWidth: { xs: 300, sm: 550 },
        maxHeight: 700,
        mx: "auto",
        padding: "2rem",
        background: "#fff",
      }}
    >
      <Typography align="center" variant="h5" className={classes.headline}>
        Check your email
      </Typography>
      <img src={emailSvg} alt="Email" />
      <Alert variant="outlined" severity="success">
        We’ve sent a link to rest your password. Please check your Inbox and Spam Folder!
      </Alert>
      <form className={classes.root} noValidate autoComplete="off">
        <ButtonPrimary fullWidth onClick={handleOnClick}>
          <strong>Resend email</strong>
        </ButtonPrimary>
      </form>
    </Box>
  );
}

export default FormCheckEmail;
