import * as Yup from "yup";

import { isNullOrEmpty } from "../../../utils/stringUtils";

var re = /^(?=(?:.*?[0-9]){9})(?=(?:.*?[A-Za-z]){1})[A-Za-z0-9#,.\-_]{10,}$/;

const ContactStep2Schema = Yup.object().shape({
  contact: Yup.object().shape({
    planToClaimChildCareSubsidy: Yup.string().required("Required"),
    crn: Yup.string()
      .notRequired()
      .when("planToClaimChildCareSubsidy", {
        is: (val: string) => val === "true",
        then: Yup.string().required("Required").matches(re, "You must enter a valid Customer Reference Number"),
        otherwise: Yup.string().notRequired(),
      }),
  }),
  children: Yup.array().of(
    Yup.object().shape({
      crn: Yup.string().test("required", "Required", function (value) {
        if (this.options?.context?.contact.planToClaimChildCareSubsidy) {
          var message = "";

          if (value === undefined || isNullOrEmpty(value)) {
            message = "Required";
          } else {
            if (!re.test(value)) {
              message = "You must enter a valid Customer Reference Number";
            }
          }

          if (message !== "") {
            return this.createError({
              path: this.path,
              message: message,
            });
          }
        }

        return true;
      }),
    })
  ),
});

export default ContactStep2Schema;
