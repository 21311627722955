import { createReducer } from "deox";

import {
  acknowledgedAdditionalNeeds,
  childrenError,
  childrenLoading,
  childrenSuccess,
  childReset,
  childUpdated,
  childUpdateError,
  childUpdating,
} from "./childStateActions";
import { Child } from "../../../types/models";
import { ChildBookingStatus } from "../../../types/types";
import { isBlockingAnswer } from "../../../utils/medicalConditionsHelper";
import { purge } from "../purge/purgeStateActions";

export interface ChildrenState {
  childrenByCustomer: Child[] | null;
  isErrorChildren: boolean;
  isLoadingChildren: boolean;
  isUpdatingChild: boolean;
  isChildUpdated: boolean;
  isUpdateError: boolean;
}

export const initialState: ChildrenState = {
  childrenByCustomer: null,
  isErrorChildren: false,
  isLoadingChildren: false,
  isUpdatingChild: false,
  isChildUpdated: false,
  isUpdateError: false,
};

const childReducer = createReducer(initialState, (handleAction) => [
  handleAction(childrenSuccess, (state, action) => {
    let childrenByCustomer = new Array<Child>();

    // Assign child using spread to force have a Child instead ChildHttpResponse otherwise some values (ChildBookingStatus, ChildService)
    // are lost when persisted to Session Storage and this introduce a issue on browser refresh (PAC-1253)
    action.payload.forEach((x) => {
      childrenByCustomer?.push({
        ...x,
      } as Child);
    });

    return {
      ...state,
      childrenByCustomer,
      isLoadingChildren: false,
      isErrorChildren: false,
      isUpdatingChild: false,
      isChildUpdated: false,
    };
  }),
  handleAction(
    childrenLoading,
    (state): ChildrenState => ({
      ...state,
      isLoadingChildren: true,
      isErrorChildren: false,
      isUpdatingChild: false,
      isChildUpdated: false,
    })
  ),
  handleAction(
    childrenError,
    (state): ChildrenState => ({
      ...state,
      isErrorChildren: true,
      isLoadingChildren: false,
      isUpdatingChild: false,
      isChildUpdated: false,
      childrenByCustomer: null,
    })
  ),
  handleAction(childUpdating, (state, { payload }) => {
    let childrenByCustomer = [...(state?.childrenByCustomer ?? [])];

    if (payload.child.acknowledgedStatus) {
      childrenByCustomer[childrenByCustomer.findIndex((c) => c.childId === payload.child.childId)] = {
        ...payload.child,
      } as Child;
    }

    return {
      ...state,
      childrenByCustomer,
      isLoadingChildren: false,
      isErrorChildren: false,
      isUpdatingChild: true,
      isChildUpdated: false,
      isUpdateError: false,
    };
  }),
  handleAction(childUpdated, (state, { payload }) => {
    let childrenByCustomer = [...(state?.childrenByCustomer ?? [])];

    var child = { ...payload.child };
    var childIndex = childrenByCustomer.findIndex((x) => x.childId === child.childId);

    if (payload.isNew) {
      if (
        child.answers?.some((x) => {
          return isBlockingAnswer(x);
        })
      ) {
        child.childBookingStatus = ChildBookingStatus.BlockedForAllServices;
      }

      childrenByCustomer.push({ ...child } as Child); // Assign with spread (PAC-1253)
    } else {
      child.childBookingStatus = childrenByCustomer[childIndex].childBookingStatus;
      childrenByCustomer[childIndex] = { ...child } as Child; // Assign with spread (PAC-1253)
    }

    return {
      ...state,
      childrenByCustomer,
      isUpdatingChild: false,
      isChildUpdated: true,
      isUpdateError: false,
    };
  }),
  handleAction(
    childUpdateError,
    (state): ChildrenState => ({
      ...state,
      isLoadingChildren: false,
      isErrorChildren: false,
      isUpdatingChild: false,
      isChildUpdated: false,
      isUpdateError: true,
    })
  ),
  handleAction(
    childReset,
    (state): ChildrenState => ({
      ...state,
      isLoadingChildren: false,
      isErrorChildren: false,
      isUpdatingChild: false,
      isChildUpdated: false,
      isUpdateError: false,
    })
  ),
  handleAction(acknowledgedAdditionalNeeds, (state) => {
    let childrenByCustomer = [...(state?.childrenByCustomer ?? [])];

    childrenByCustomer.forEach((c) => (c.acknowledgedStatus = true));

    return {
      ...state,
      childrenByCustomer,
    };
  }),
  handleAction(purge, () => initialState),
]);

export default childReducer;
