import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup";

interface Props {
  classes: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
}

function ContactAuthorisation({ classes, handleChange, setFieldValue, values, touched, errors, handleBlur }: Props) {
  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Authentication</legend>
          <FormLabel component="legend" className={classes.formLabel}>
            This person is authorised to:
          </FormLabel>

          <AuthorisationItem
            field={values.authorisation?.canManageAccount}
            setFieldValue={setFieldValue}
            label="Manage this account"
            id={"canManageAccount"}
            fieldName={"authorisation.canManageAccount"}
            error={errors.authorisation?.canManageAccount}
            touched={touched.authorisation?.canManageAccount}
          />

          <AuthorisationItem
            field={values.authorisation?.canMakeAndChangeBookings}
            setFieldValue={setFieldValue}
            label="Make and change bookings"
            id={"canMakeAndChangeBookings"}
            fieldName={"authorisation.canMakeAndChangeBookings"}
            error={errors.authorisation?.canMakeAndChangeBookings}
            touched={touched.authorisation?.canMakeAndChangeBookings}
          />

          <AuthorisationItem
            field={values.authorisation?.consentToAdministerMedicine}
            setFieldValue={setFieldValue}
            label="Consent to administration of medication to your child"
            id={"consentToAdministerMedicine"}
            fieldName={"authorisation.consentToAdministerMedicine"}
            error={errors.authorisation?.consentToAdministerMedicine}
            touched={touched.authorisation?.consentToAdministerMedicine}
          />

          <AuthorisationItem
            field={values.authorisation?.consentToMedicalTreatment}
            setFieldValue={setFieldValue}
            label="Consent to medical treatment for your child"
            id={"consentToMedicalTreatment"}
            fieldName={"authorisation.consentToMedicalTreatment"}
            error={errors.authorisation?.consentToMedicalTreatment}
            touched={touched.authorisation?.consentToMedicalTreatment}
          />

          <AuthorisationItem
            field={values.authorisation?.canApproveAmbulance}
            setFieldValue={setFieldValue}
            label="Approve for an ambulance to collect the child"
            id={"canApproveAmbulance"}
            fieldName={"authorisation.canApproveAmbulance"}
            error={errors.authorisation?.canApproveAmbulance}
            touched={touched.authorisation?.canApproveAmbulance}
          />

          <AuthorisationItem
            field={values.authorisation?.canPickUpChild}
            setFieldValue={setFieldValue}
            label="Collect children from care service premises"
            id={"canPickUpChild"}
            fieldName={"authorisation.canPickUpChild"}
            error={errors.authorisation?.canPickUpChild}
            touched={touched.authorisation?.canPickUpChild}
          />

          <AuthorisationItem
            field={values.authorisation?.consentToTakeOutsidePremises}
            setFieldValue={setFieldValue}
            label="Provide consent for an educator to take the child outside the education and care services premises"
            id={"consentToTakeOutsidePremises"}
            fieldName={"authorisation.consentToTakeOutsidePremises"}
            error={errors.authorisation?.consentToTakeOutsidePremises}
            touched={touched.authorisation?.consentToTakeOutsidePremises}
          />

          <AuthorisationItem
            field={values.authorisation?.consentForTransportation}
            setFieldValue={setFieldValue}
            label={"Provide consent to transport the child or arrange transportation of the child"}
            id={"consentForTransportation"}
            fieldName={"authorisation.consentForTransportation"}
            error={errors.authorisation?.consentForTransportation}
            touched={touched.authorisation?.consentForTransportation}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default ContactAuthorisation;
interface IAuthItemProps {
  field: boolean | undefined;
  setFieldValue: any;
  label: string;
  id: string;
  fieldName: string;
  error: any;
  touched: any;
}
function AuthorisationItem(props: IAuthItemProps) {
  const { field, setFieldValue, label, id, fieldName, error, touched } = props;
  return (
    <FormControl error={Boolean(error)} margin="normal">
      <FormLabel required>{label}</FormLabel>
      <RadioGroup row id={id}>
        <FormControlLabel
          value="true"
          control={<Radio />}
          checked={field === true}
          label="Yes"
          onChange={() => setFieldValue(fieldName, true)}
        />
        <FormControlLabel
          value="false"
          checked={field === false}
          control={<Radio />}
          label="No"
          onChange={() => setFieldValue(fieldName, false)}
        />
      </RadioGroup>

      {error && (
        <FormHelperText error required>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
