import React from "react";

import Alert from "@mui/lab/Alert/Alert";
import Box from "@mui/material/Box/Box";
import { makeStyles } from "tss-react/mui";

import { getStyleColor } from "../../utils/helpers";
import UcomingChargesListing from "./UpcomingChargesListing";

function UpcomingCharges() {
  const useStyles = makeStyles()((theme) => {
    return {
      cssMessage: {
        borderColor: getStyleColor("--custom-color-red"),
        borderLeftStyle: "solid",
        borderLeftWidth: "5px",
        marginBottom: "10px",
      },
    };
  });

  const { classes } = useStyles();

  return (
    <>
      <h2 className="h4" style={{ marginTop: 0 }}>
        Upcoming Charges
      </h2>
      <Box className={classes.cssMessage}>
        <Alert severity="error">
          Please note that your Child Care Subsidy (CCS) will not yet be applied to upcoming charges. You can check the
          CCS amount for this period on your upcoming statement once it is issued.
        </Alert>
      </Box>
      {UcomingChargesListing()}
    </>
  );
}

export default UpcomingCharges;
