import { createActionCreator } from "deox";
export const querySaving = createActionCreator("querySaving");
export const queryError = createActionCreator(
  "queryError",
  (resolve) => (transactionId: number) => resolve(transactionId)
);
export const querySubmitted = createActionCreator(
  "querySubmitted",
  (resolve) => (transactionId: number) => resolve(transactionId)
);
export const queryInit = createActionCreator("queryInit");
