import React from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { List as LinqList } from "linqts";
import { IoCalendarOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  CancelBookingInitial,
  LoadSaveRecurringPreviewInitial,
  SaveBookingInitial,
} from "../../../store/modules/booking/bookingStateActions";
import { LoadedUnavailableDatesReset } from "../../../store/modules/session/sessionStateAction";
import { RootState } from "../../../store/store";
import { BookingInfo, EntityType, InlineBookingEditorModel } from "../../../types/models";
import { EditModelEnum } from "../../../types/types";
import dateOnlyToJson from "../../../utils/dateOnlyToJson";
import { AvatarDisplay } from "../Controls/AvatarDisplay";

dayjs.extend(dateOnlyToJson);

interface IBookingScheduleProps {
  bookings: BookingInfo[] | null;
  isAllowedToBook: boolean;
  isLoading?: boolean;
  id: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    flex: 1,
    padding: 0,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const NoBookingsFound = (props: IBookingScheduleProps) => (
  <Grid container justifyContent="center" id={props.id}>
    <Grid item>
      <Grid container alignItems="center" spacing={1} direction="column">
        <Grid item>
          <IoCalendarOutline size={60} color="gray" />
        </Grid>
        <Grid item>
          <span className="color-dark-grey">No bookings found</span>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const BookingSchedule = (props: IBookingScheduleProps) => {
  const { bookings, isLoading } = props || {};

  const children = useSelector((state: RootState) => state.child.childrenByCustomer);
  const avatars = useSelector((state: RootState) =>
    state.avatars?.avatars?.filter((x) => x.entityType === EntityType.Child)
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentBooking, setCurrentBooking] = React.useState<null | BookingInfo>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, booing: BookingInfo) => {
    setAnchorEl(event.currentTarget);
    setCurrentBooking(booing);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const bookingsByDate = new LinqList<BookingInfo>(bookings ?? [])
    .Where((x) => x?.cancellationStatusId !== 1)
    .OrderBy((b) => b.date)
    .GroupBy((g) => g.date.format("ddd DD"));

  const bookingsByDateArr = Object.keys(bookingsByDate);
  const { classes } = useStyles();

  return isLoading ? null : bookings && bookings.length > 0 ? (
    <>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {currentBooking?.isEditable && props.isAllowedToBook && (
          <MenuItem
            key="cancel"
            onClick={() => {
              var model = new InlineBookingEditorModel({
                booking: currentBooking,
                child: children?.find((c) => c.childId === currentBooking.childId)!,
                editMode: EditModelEnum.Cancel,
                cancellationReasonId: 0,
              });
              dispatch(LoadedUnavailableDatesReset());
              dispatch(LoadSaveRecurringPreviewInitial());
              dispatch(SaveBookingInitial());
              dispatch(CancelBookingInitial());
              navigate("edit-confirm", {
                state: JSON.stringify({
                  booking: model,
                }),
              });
              handleClose();
            }}
          >
            Cancel Session
          </MenuItem>
        )}
        {currentBooking?.isEditable && props.isAllowedToBook && currentBooking.isSchedule && (
          <>
            <MenuItem
              key="cancelRecurring"
              onClick={() => {
                var model = new InlineBookingEditorModel({
                  booking: currentBooking,
                  child: children?.find((c) => c.childId === currentBooking.childId)!,
                  editMode: EditModelEnum.CancelSchedule,
                  cancellationReasonId: 0,
                });
                dispatch(LoadedUnavailableDatesReset());
                dispatch(LoadSaveRecurringPreviewInitial());
                dispatch(SaveBookingInitial());
                dispatch(CancelBookingInitial());
                navigate("edit-confirm", {
                  state: JSON.stringify({
                    booking: model,
                  }),
                });
                handleClose();
              }}
            >
              Cancel Recurring
            </MenuItem>
            <MenuItem
              key="editRecurring"
              onClick={() => {
                var sameDateBookings = bookings.filter((b) => b.date.isSame(currentBooking.date, "date"));
                navigate("/edit-booking", {
                  state: JSON.stringify({
                    date: currentBooking.date,
                    selectedBooking: currentBooking,
                    bookings: sameDateBookings,
                  }),
                });
                handleClose();
              }}
            >
              Edit Recurring
            </MenuItem>
          </>
        )}
      </Menu>
      {isLoading ? null : bookingsByDateArr && bookingsByDateArr.length > 0 ? (
        <List className={classes.root} id={props.id}>
          <Divider variant="fullWidth" />
          {bookingsByDateArr.map((date, index) => {
            return (
              <li key={index} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader style={{ lineHeight: "24px" }} disableSticky={true}>
                    <strong>{date}</strong>
                  </ListSubheader>
                  {bookingsByDate[date].map((bk: BookingInfo, index) => {
                    const child = children?.find((x) => x.childId === bk.childId) ?? null;
                    const avatar = avatars.find((x) => x.entityId === child?.childId?.toString());

                    return (
                      <React.Fragment key={index}>
                        <ListItem style={{ paddingTop: 0, paddingRight: 0, alignItems: "flex-start" }}>
                          {child && (
                            <ListItemAvatar style={{ marginTop: 6 }}>
                              <AvatarDisplay info={avatar || { index: child.avatarIndex }} size={30} />
                            </ListItemAvatar>
                          )}
                          <ListItemText
                            style={{ textDecoration: bk.cancellationStatusId === 2 ? "line-through" : "none" }}
                            primary={
                              <Grid container alignItems="center">
                                {bk.scheduleId && (
                                  <RefreshOutlinedIcon color="primary" style={{ marginRight: 5, fontSize: 18 }} />
                                )}
                                {!bk.isHolidayClub &&
                                  bk.roll &&
                                  `${bk.roll.rollName} | ${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(
                                    bk.roll.endTime
                                  ).format("hh:mm a")}`}
                                {bk.isHolidayClub &&
                                  bk.program &&
                                  bk.roll &&
                                  `${bk.program.programName} | ${dayjs(bk.roll.startTime).format("hh:mm a")} - ${dayjs(
                                    bk.roll.endTime
                                  ).format("hh:mm a")}`}
                              </Grid>
                            }
                            secondary={bk.roll?.service?.serviceName?.toUpperCase()}
                          ></ListItemText>

                          {props.isAllowedToBook && bk.isEditable && (
                            <IconButton
                              aria-label="more"
                              aria-controls="actions-menu"
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, bk)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          )}
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                  <Divider variant="fullWidth" />
                </ul>
              </li>
            );
          })}
        </List>
      ) : (
        NoBookingsFound(props)
      )}
    </>
  ) : (
    NoBookingsFound(props)
  );
};

export default BookingSchedule;
