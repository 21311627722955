import React from "react";

import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

import { RootState } from "../../store/store";
import { PaymentType } from "../../types/types";
import { daysToPaymentMethodExpiration } from "../../utils/helpers";

export default function NotificationAlert() {
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);

  return (
    (currentPaymentMethod && currentPaymentMethod.paymentType === PaymentType.CreditCard && (
      <Grid container justifyContent="flex-start" direction="column">
        {daysToPaymentMethodExpiration(currentPaymentMethod) <= 0 ? (
          <Box marginTop={3}>
            <Alert severity="error">
              <AlertTitle>Credit Card has expired</AlertTitle>
              Please update your payment method.
            </Alert>
          </Box>
        ) : daysToPaymentMethodExpiration(currentPaymentMethod) < 30 ? (
          <Box marginTop={3}>
            <Alert severity="error">
              <AlertTitle>{`Credit Card expires ${currentPaymentMethod.expiryMonth}/${currentPaymentMethod.expiryYear}`}</AlertTitle>
              Please update your payment method as soon as possible.
            </Alert>
          </Box>
        ) : null}
      </Grid>
    )) ??
    (null as any)
  );
}
