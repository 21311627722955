import React, { useEffect, useState } from "react";

import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { List } from "linqts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import RocketeersLogo from "../../assets/logos/logo-rocketeers.svg";
import ChildVACBookingCard from "../../components/Bookings/ChildVACBookingCard";
import { ButtonPrimary, ButtonSecondary } from "../../components/Common/Buttons/Buttons";
import { AnimatedDialog } from "../../components/Common/Dialogs/AnimatedDialog";
import LoadingDialog from "../../components/Common/Dialogs/LoadingDialog";
import { Egg3 } from "../../components/Common/Shapes/Shapes";
import { CardLayout } from "../../layouts/Layouts";
import { createCasualBookings, getCreateCasualBookingsPreview } from "../../store/modules/booking/bookingActions";
import { SaveVacBookingComplete } from "../../store/modules/booking/bookingStateActions";
import { getUnAvailableSessionDays } from "../../store/modules/session/sessionActions";
import { RootState } from "../../store/reducers";
import theme from "../../theme";
import {
  AgeViolationReasonInfo,
  BookingDateInfo,
  ChildAgeConstraint,
  CreateCasualBookingsHttpRequest,
  SelectedChild,
  SelectedServiceProgram,
  SessionAvailability,
} from "../../types/models";
import {
  IGetCreateCasualBookingsPreviewRequest,
  IServiceDistance,
  IServiceProgram,
  ISessionAvailability,
  ISessionInfo,
} from "../../types/types";
import dateOnlyToJson from "../../utils/dateOnlyToJson";
import { creditCardIsExpired, getStyleColor } from "../../utils/helpers";
import { useViewport } from "../../utils/hooks";
import { GetPageState } from "../../utils/pageUtil";

dayjs.extend(dateOnlyToJson);
export interface VACConfirmState {
  children: SelectedChild[];
  dates: IServiceProgram[];
  serviceDistance: IServiceDistance;
}

function getMatchingUnAvailableDates(state: VACConfirmState, unAvailableDates: ISessionAvailability[] | null) {
  if (!unAvailableDates) {
    return new Array<ISessionAvailability>();
  }
  const casualDates = new List(state.dates);
  return new List(unAvailableDates)
    .Where(
      (ud) =>
        ud !== undefined &&
        !ud.isAvailableForCasualBooking &&
        casualDates.Any((cd) => cd !== undefined && cd.rollId === ud.rollId && cd.date.isSame(ud.date, "date"))
    )
    .ToArray();
}

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: 40,
    "@media screen and (min-width: 720px)": {
      height: 80,
    },
  },
}));

function NewVACBookingConfirm() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { width } = useViewport();
  const state = GetPageState<VACConfirmState>()!;
  const orderDates = new List(state.dates).OrderBy((d) => d.date);
  const from = orderDates.First().date;
  const to = orderDates.Last().date;
  const initialValue = new List<ISessionInfo>();

  const vacDates = new List(state.dates);
  const grp = vacDates.GroupBy((cd) => cd.rollId);

  const rolls = Object.keys(grp).reduce((accumulator: any, currentValue) => {
    return accumulator.Add(vacDates.First((cd) => cd !== undefined && cd.rollId === parseInt(currentValue)).session);
  }, initialValue);

  const customer_account_id = useSelector((state: RootState) => state.auth.user?.profile.customer_account_id);
  const customerAccount = useSelector((state: RootState) => state.customer);
  const unAvailableDates = useSelector((state: RootState) => state.unAvailableDates.dates);
  const saveState = useSelector((state: RootState) => state.createBooking);
  const casualPreviews = useSelector((state: RootState) => state.bookingSaveCasualPreview.previewResult);
  const [pageState, setPageState] = useState({
    hasConflict: false,
    unAvailableDates: new Array<ISessionAvailability>(),
    newlyAddedUnAvailableDates: new Array<ISessionAvailability>(),
  });
  let isSubmitting = saveState !== undefined && saveState.isSaving;

  const [ccExpired, setCCExpired] = React.useState(false);
  const currentPaymentMethod = useSelector((state: RootState) => state.paymentMethod.payload);

  useEffect(() => {
    if (customer_account_id) {
      getUnAvailableSessionDays(
        dispatch,
        customer_account_id as number,
        from as dayjs.Dayjs,
        to as dayjs.Dayjs,
        orderDates
          .Select((r) => r.rollId)
          .Distinct()
          .ToArray(),
        state.children.map((c) => c.child.childId as number)
      );
    }
  }, [customer_account_id]);

  useEffect(() => {
    if (currentPaymentMethod) {
      setCCExpired(creditCardIsExpired(currentPaymentMethod));
    }
  }, [currentPaymentMethod]);

  useEffect(() => {
    if (unAvailableDates) {
      var unvDates = getMatchingUnAvailableDates(state, unAvailableDates);
      setPageState({
        ...pageState,
        unAvailableDates: unvDates,
      });
      var avDates = state.dates
        .map((x) => new BookingDateInfo(x.rollId, x.date))
        .filter((x) => unvDates.findIndex((y) => y.rollId === x.rollId && y.date.isSame(x.date, "date")) < 0);
      if (avDates.length > 0) {
        getCreateCasualBookingsPreview(
          dispatch,
          customer_account_id as number,
          {
            childrenIds: state.children.map((c) => c.child.childId as number),
            dates: avDates,
          } as IGetCreateCasualBookingsPreviewRequest
        );
      } else {
        var newUnAvailableDates = unvDates.filter(
          (x) => state.dates.findIndex((y) => y.rollId === x.rollId && y.date.isSame(x.date, "date")) >= 0
        );
        setPageState({
          hasConflict: true,
          unAvailableDates: unvDates,
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      }
    }
  }, [unAvailableDates]);

  useEffect(() => {
    if (saveState && saveState.isErrorState) {
      if (saveState.errorResponse && saveState.errorResponse.info) {
        var updatedUnAvailableDates = saveState.errorResponse.info.map((d: any) =>
          SessionAvailability.fromJson(d)
        ) as SessionAvailability[];
        var newUnAvailableDates = new Array<SessionAvailability>();
        for (var sess of updatedUnAvailableDates) {
          // eslint-disable-next-line no-loop-func
          if (pageState.unAvailableDates.findIndex((d) => d.rollId === sess.rollId && d.date.isSame(sess.date)) < 0) {
            newUnAvailableDates.push(sess);
          }
        }
        setPageState({
          hasConflict: true,
          unAvailableDates: getMatchingUnAvailableDates(state, updatedUnAvailableDates),
          newlyAddedUnAvailableDates: newUnAvailableDates,
        });
      } else {
      }
    } else if (saveState && saveState.isSuccess) {
      if (saveState.isComplete) {
        navigate("vac-complete");
      } else {
        dispatch(
          SaveVacBookingComplete(
            saveState.data!.reference,
            state.dates,
            state.children.map((x) => x.child)
          )
        );
      }
    }
  }, [saveState]);

  const handleClickClose = () => {
    setPageState({
      ...pageState,
      hasConflict: false,
    });
  };

  var ageViolationReasons = new Array<AgeViolationReasonInfo>();
  state.children.forEach((c) => {
    var rollViolations = new List(
      state.dates.map(
        (d) =>
          new ChildAgeConstraint({ rollDate: d.date, session: { ...d.session, isHolidayClub: true, isOhsc: false } })
      )
    )
      .Where((d) => d !== undefined && d.isAgeGreaterThanMax(c))
      .Select((d) => d.session.rollId)
      .Distinct();
    rollViolations.ForEach((rId) => {
      ageViolationReasons.push(
        new AgeViolationReasonInfo(c.child.childId as number, rId as number, c.reason as string)
      );
    });
  });

  return (
    <>
      <CardLayout>
        <CardLayout.Header bgColor={getStyleColor("--rocketeers-color-accent-2")}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h1 className="h3">Confirm Booking</h1>
            </Grid>
            <Grid item>
              <Grid container alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end">
                <img src={RocketeersLogo} alt="Rocketeers logo" className={classes.logo} />
              </Grid>
            </Grid>
          </Grid>
        </CardLayout.Header>
        <CardLayout.Body>
          <Egg3
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              display: width <= 1280 ? "none" : "block",
            }}
          />
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                {state &&
                  new List(state.children)
                    .Select((c) => (
                      <ChildVACBookingCard
                        key={"ChildCard_" + c.child.childId}
                        child={c.child}
                        programs={new List(state.dates)}
                        datesUnAvailable={new List(pageState.unAvailableDates ?? [])}
                        service={state.serviceDistance.service}
                        isFriendlyFee={customerAccount.customer?.isFriendlyFeeEligible === true}
                        previews={casualPreviews ?? []}
                      />
                    ))
                    .ToArray()}

                <Grid item xs={12}>
                  <span className="small">* &nbsp;&nbsp;Prices exclude available Child Care Subsidy (CCS)</span>
                </Grid>
                {casualPreviews && casualPreviews.findIndex((cp) => cp.multiChildrenDiscount > 0) >= 0 && (
                  <Grid item xs={12}>
                    <span className="small">
                      ** Rocketeer bookings are eligible for multi children’s discount. The discount for eligible
                      bookings gets applied to the fortnightly statement
                    </span>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <ButtonSecondary
                        onClick={() => {
                          navigate("/vac-selection", {
                            state: JSON.stringify({
                              children: state.children,
                              dates: state.dates.map(
                                (d) => new SelectedServiceProgram({ program: d, isSelected: true })
                              ),
                              serviceDistance: state.serviceDistance,
                            }),
                          });
                        }}
                      >
                        <strong>Back</strong>
                      </ButtonSecondary>
                    </Grid>
                    <Grid item>
                      <ButtonPrimary
                        style={{ backgroundColor: getStyleColor("--rocketeers-color-accent-2") }}
                        disabled={CannotConfirm(isSubmitting, state, pageState.unAvailableDates ?? []) || ccExpired}
                        onClick={() => {
                          const casReq = new CreateCasualBookingsHttpRequest(
                            state.children.map((c) => c.child.childId as number),
                            state.dates.map((cd) => new BookingDateInfo(cd.rollId, cd.date)),
                            pageState.unAvailableDates.map((d) => new BookingDateInfo(d.rollId, d.date)),
                            ageViolationReasons
                          );
                          createCasualBookings(dispatch, customer_account_id as number, casReq);
                        }}
                      >
                        <strong>Confirm</strong>
                      </ButtonPrimary>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardLayout.Body>
      </CardLayout>
      <AnimatedDialog open={pageState.hasConflict} title="Sorry!" onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <strong>Some sessions are no longer available:</strong>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            {pageState.newlyAddedUnAvailableDates.map((d) => {
              return (
                <Grid item xs={12}>
                  <strong style={{ color: theme.palette.error.main }}>
                    {d.date.format("DD-MMM-YYYY")} - &nbsp;
                    {rolls?.FirstOrDefault((r: any) => r?.rollId === d.rollId).sessionType}
                  </strong>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <strong style={{ color: getStyleColor("--custom-color-dark-grey") }}>Please update your booking!</strong>
            </Grid>
          </Grid>
        </DialogContent>
      </AnimatedDialog>
      <LoadingDialog open={isSubmitting} message="Confirming booking" />
    </>
  );
}

function CannotConfirm(
  isSubmitting: boolean,
  state: VACConfirmState,
  unAvailableDates: ISessionAvailability[]
): boolean {
  const unAvDates = new List(unAvailableDates);
  const availableBookings = new List(state.dates).Where(
    (p) =>
      !unAvDates.Any(
        (ud) =>
          ud !== undefined &&
          ud?.rollId === p?.rollId &&
          ud?.date.isSame(p?.date, "date") &&
          !ud.isAvailableForCasualBooking
      )
  );

  return (
    isSubmitting ||
    unAvailableDates == null ||
    state.serviceDistance == null ||
    state.children == null ||
    state.children.length < 0 ||
    availableBookings.Count() === 0
  );
}

export default NewVACBookingConfirm;
