import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { RootState } from "../../store/store";
import { AvatarInfo } from "../../types/models";
import { ContactType } from "../../types/types";
import { isNullOrEmpty } from "../../utils/stringUtils";
import { Address } from "../Common/Controls/Address";
import { Avatar } from "../Common/Controls/Avatar";
import { GenderSelector } from "../Common/Controls/GenderSelector";
import { HomeNumberFormat, MobileNumberFormat, PhoneNumberFormat } from "../Common/Controls/NumberFormats";
import ReadOnlyFields from "../Common/Tooltip/ReadOnlyFields";

const useStyles = makeStyles()((theme) => ({
  container: { paddingLeft: 0 },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    marginBottom: theme.spacing(2),
  },
  formLegend: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: { marginBottom: theme.spacing(2) },
}));

interface Props {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  touched: any;
  values: any;
  errors: any;
  primaryContact: any;
  availableContactTypes: any;
  firstNameReadOnly?: boolean;
  lastNameReadOnly?: boolean;
  birthdayReadOnly?: boolean;
}

function BasicInformation({
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  values,
  errors,
  primaryContact,
  availableContactTypes,
  firstNameReadOnly = false,
  lastNameReadOnly = false,
  birthdayReadOnly = false,
}: Props) {
  const { states, popularLanguages } = useSelector((state: RootState) => state?.commonData?.data);
  const { classes } = useStyles();

  const contactAvatar = useSelector((state: RootState) =>
    state.avatars?.avatars?.find((x) => x.entityType === values.entityType && x.entityId === values.entityId)
  );

  if (contactAvatar) {
    contactAvatar.customerAccountId = primaryContact.customerAccountId;
  }

  return (
    <Grid container className={classes.root} spacing={6}>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>
            General Information {(firstNameReadOnly || lastNameReadOnly || birthdayReadOnly) && <ReadOnlyFields />}
          </legend>

          <Grid container>
            <Grid item xs={6} md={8}>
              <TextField
                id="firstName"
                label="First Name"
                type="text"
                value={values.firstName}
                onChange={handleChange("firstName")}
                onBlur={handleBlur("firstName")}
                helperText={touched.firstName ? errors.firstName : ""}
                error={touched.firstName && Boolean(errors.firstName)}
                fullWidth
                className={classes.textField}
                disabled={firstNameReadOnly}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Avatar
                avatarInfo={
                  contactAvatar || {
                    entityType: values.entityType,
                    entityId: values.entityId,
                    index: values.avatarIndex,
                    customerAccountId: primaryContact.customerAccountId,
                  }
                }
                onAvatarChange={(avatarInfo: AvatarInfo) => {
                  setFieldValue("avatarIndex", avatarInfo.index);
                }}
              />
            </Grid>
          </Grid>

          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            value={values.lastName}
            onChange={handleChange("lastName")}
            onBlur={handleBlur("lastName")}
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName && Boolean(errors.lastName)}
            fullWidth
            className={classes.textField}
            required
            disabled={lastNameReadOnly}
            inputProps={{
              maxLength: 100,
            }}
          />

          <FormControl className={classes.formControl} variant="filled" fullWidth required>
            <TextField
              select
              id="contactTypeId"
              label="Relationship to child(ren)"
              value={values.contactTypeId || ""}
              onChange={handleChange("contactTypeId")}
              onBlur={handleBlur("contactTypeId")}
              helperText={touched.contactTypeId ? errors.contactTypeId : ""}
              error={touched.contactTypeId && Boolean(errors.contactTypeId)}
              variant="filled"
              fullWidth
              required
            >
              <MenuItem value="">Please select</MenuItem>
              {availableContactTypes.map((contactType: ContactType) => (
                <MenuItem value={contactType.contactTypeId} key={contactType.contactTypeId}>
                  {contactType.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              format="DD/MM/YYYY"
              label="Date of Birth"
              value={values.birthday || null}
              maxDate={dayjs().subtract(15, "year")}
              onChange={(birthday) => {
                setFieldValue("birthday", birthday?.clone());
              }}
              slotProps={{
                textField: {
                  "aria-label": "change birthday date",
                  id: "dateOfBirth",
                  className: classes.textField,
                  required: true,
                  helperText: touched.birthday ? errors.birthday : "",
                  error: touched.birthday && Boolean(errors.birthday),
                },
              }}
              disabled={birthdayReadOnly}
            />
          </LocalizationProvider>
          {/* <KeyboardDatePicker
            className={classes.textField}
            format="DD/MM/YYYY"
            margin="normal"
            id="dateOfBirth"
            label="Date of Birth"
            value={values.birthday || null}
            maxDate={dayjs().subtract(15, "year")}
            onChange={(birthday) => {
              setFieldValue("birthday", birthday?.clone());
            }}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change birthday date",
            }}
            helperText={touched.birthday ? errors.birthday : ""}
            error={touched.birthday && Boolean(errors.birthday)}
            required
          /> */}
          <FormControl variant="filled" className={classes.formControl} fullWidth required>
            <GenderSelector
              id="genderId"
              label="Gender"
              value={values.genderId || ""}
              onChange={handleChange("genderId")}
              onBlur={handleBlur("genderId")}
              helperText={touched.genderId ? errors.genderId : ""}
              error={touched.genderId && Boolean(errors.genderId)}
            ></GenderSelector>
          </FormControl>
          {values.genderId === 3 && (
            <TextField
              id="otherGender"
              label="Other Gender"
              type="text"
              value={values.genderId === 3 ? values.otherGender : undefined}
              onChange={handleChange("otherGender")}
              onBlur={handleBlur("otherGender")}
              helperText={touched.otherGender ? errors.otherGender : ""}
              error={touched.otherGender && Boolean(errors.otherGender)}
              fullWidth
              className={classes.textField}
              inputProps={{
                maxLength: 100,
              }}
            />
          )}

          <TextField
            id="email"
            label="Email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            onBlur={handleBlur("email")}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            fullWidth
            className={classes.textField}
            required
            disabled={values.isPrimary}
            inputProps={{
              maxLength: 400,
            }}
          />
          <TextField
            id="mobilePhone"
            name="mobilePhone"
            label="Mobile Number"
            type="tel"
            value={values.mobilePhone}
            onChange={handleChange("mobilePhone")}
            onBlur={handleBlur("mobilePhone")}
            helperText={touched.mobilePhone ? errors.mobilePhone : ""}
            error={touched.mobilePhone && Boolean(errors.mobilePhone)}
            fullWidth
            className={classes.textField}
            required
            InputProps={{
              inputComponent: MobileNumberFormat as any,
            }}
          />
          <TextField
            id="homePhone"
            label="Home Number"
            type="tel"
            value={values.homePhone}
            onChange={handleChange("homePhone")}
            onBlur={handleBlur("homePhone")}
            helperText={touched.homePhone ? errors.homePhone : ""}
            error={touched.homePhone && Boolean(errors.homePhone)}
            fullWidth
            className={classes.textField}
            InputProps={{
              inputComponent: HomeNumberFormat as any,
            }}
          />
          <TextField
            id="workPhone"
            label="Work Number"
            type="tel"
            value={values.workPhone}
            onChange={handleChange("workPhone")}
            onBlur={handleBlur("workPhone")}
            helperText={touched.workPhone ? errors.workPhone : ""}
            error={touched.workPhone && Boolean(errors.workPhone)}
            fullWidth
            className={classes.textField}
            InputProps={{
              inputComponent: PhoneNumberFormat as any,
            }}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Residential Details</legend>

          {!values.isPrimary &&
            primaryContact.residentialAddress &&
            !isNullOrEmpty(primaryContact.residentialAddress.streetLine1) &&
            !isNullOrEmpty(primaryContact.residentialAddress.suburb) &&
            !isNullOrEmpty(primaryContact.residentialAddress.state) &&
            !isNullOrEmpty(primaryContact.residentialAddress.postCode) && (
              <Container className={classes.container}>
                <FormControlLabel
                  id="sameAddressDetailsAsPrimary"
                  name="sameAddressDetailsAsPrimary"
                  control={<Checkbox />}
                  label={`Same as ${primaryContact.firstName} ${primaryContact.lastName}`}
                  checked={values.sameAddressDetailsAsPrimary}
                  onChange={(e, checked) => {
                    if (checked) {
                      setFieldValue("residentialAddress.streetLine1", primaryContact.residentialAddress.streetLine1);
                      setFieldValue("residentialAddress.suburb", primaryContact.residentialAddress.suburb);
                      setFieldValue("residentialAddress.state", primaryContact.residentialAddress.state);
                      setFieldValue("residentialAddress.postCode", primaryContact.residentialAddress.postCode);
                      setFieldValue("sameAddressDetailsAsPrimary", true);
                    } else {
                      setFieldValue("sameAddressDetailsAsPrimary", false);
                    }
                  }}
                />
                <Box paddingBottom={3} />
              </Container>
            )}

          {!values.sameAddressDetailsAsPrimary && (
            <Address
              values={values.residentialAddress}
              streetLineFieldName={"residentialAddress.streetLine1"}
              suburbFieldName={"residentialAddress.suburb"}
              stateFieldName={"residentialAddress.state"}
              postCodeFieldName={"residentialAddress.postCode"}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              classes={classes}
              states={states}
              required={true}
            />
          )}
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <legend className={classes.formLegend}>Cultural / Religious Details</legend>
          {!values.isPrimary && (
            <Container className={classes.container}>
              <FormControlLabel
                id="sameCulturalDetailsAsPrimary"
                control={<Checkbox />}
                label={`Same as ${primaryContact.firstName} ${primaryContact.lastName}`}
                checked={values.sameCulturalDetailsAsPrimary}
                onChange={(e, checked) => {
                  if (checked) {
                    setFieldValue("mainLanguage", primaryContact.mainLanguage);
                    setFieldValue("culturalBackground", primaryContact.culturalBackground);
                    setFieldValue("sameCulturalDetailsAsPrimary", true);
                  } else {
                    setFieldValue("sameCulturalDetailsAsPrimary", false);
                  }
                }}
              />
              <Box paddingBottom={3} />
            </Container>
          )}
          {!values.sameCulturalDetailsAsPrimary && (
            <>
              <FormControl variant="filled" className={classes.formControl} fullWidth required>
                <TextField
                  select
                  id="mainLanguage"
                  label="Main language spoken"
                  value={values.mainLanguage || ""}
                  onChange={handleChange("mainLanguage")}
                  onBlur={handleBlur("mainLanguage")}
                  helperText={touched.mainLanguage ? errors.mainLanguage : ""}
                  error={touched.mainLanguage && Boolean(errors.mainLanguage)}
                  variant="filled"
                  fullWidth
                  required
                >
                  <MenuItem value="">Please select</MenuItem>
                  {popularLanguages.map((lang, index) => (
                    <MenuItem value={lang} key={index}>
                      {lang}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <TextField
                id="culturalBackground"
                label="Cultural Details"
                value={values.culturalBackground}
                onChange={handleChange("culturalBackground")}
                onBlur={handleBlur("culturalBackground")}
                helperText={touched.culturalBackground ? errors.culturalBackground : ""}
                error={touched.culturalBackground && Boolean(errors.culturalBackground)}
                fullWidth
                className={classes.textField}
                required
                inputProps={{
                  maxLength: 255,
                }}
              />
              <TextField
                id="culturalNeeds"
                label="Specify any other considerations"
                value={values.culturalNeeds}
                onChange={handleChange("culturalNeeds")}
                onBlur={handleBlur("culturalNeeds")}
                helperText={touched.culturalNeeds ? errors.culturalNeeds : ""}
                error={touched.culturalNeeds && Boolean(errors.culturalNeeds)}
                fullWidth
                multiline
                className={classes.textField}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </>
          )}
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default BasicInformation;
